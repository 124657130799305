import React from 'react'
import PropTypes from 'prop-types'

const icons = {
    rightArrow: 'M9.6001 5.24537e-07L8.7541 0.846L13.3021 5.4L9.66978e-05 5.4L9.68027e-05 6.6L13.3021 6.6L8.7541 11.154L9.6001 12L15.6001 6L9.6001 5.24537e-07Z',
    downArrow: 'M5.99986 5.60947L11.6093 0L0.390381 1.33784e-07L5.99986 5.60947Z'
}

const Icon = props => {    
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12">
            <path d={icons[props.icon]} fill={props.fill?props.fill:null}></path>
        </svg>
}

Icon.propTypes = {
    icon: PropTypes.string.isRequired,
    fill: PropTypes.string
}

export default Icon