import styled from "styled-components";

export const Button = styled.button`
  min-height: 40px;	
  width: 220px;	
  border-radius: 5px;	
  border-width: 0px;
  background-color: #4A90E2;	
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.23);
  :focus {
    outline: none;
    background-color: #6ba8ef;	
  }
  @media (max-width: 576px) {
    width: 70%;  
  }
  :disabled {
    opacity: 0.5;
  }
`;

export const Messages = styled.div`
  font-size: 20px;
  color: #666666;
  font-family: 'Nunito';	
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
`;

export const BackButton = styled.button`
  min-height: 40px;	
  width: 220px;	
  border-radius: 5px;	
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.23);
  border: 1px solid #4A90E2;
  :focus {
    outline: none;
  }
  @media (max-width: 576px) {
    width: 70%;  
  }
`;


export const Container = styled.div`
  background-color: #f2f2f2;
  height: 100vh;
  justify-content: center;
`;

export const Label = styled.p`
  font-size: 57px;
  font-weight: 300;
  line-height: 1.2;
  font-family: 'Nunito';	
  color: #3B3B3B;
  margin-bottom: 0px !important;
  @media (max-width: 576px) {
    font-size: 30px !important;    
  }
`;

export const Error = styled.div`
  color: #D0021B;	
  font-family: 'Nunito';	
  font-size: 14px;	
  line-height: 19px;
  text-align: center;
`;

export const Input = styled.input`
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color .2s ease-in-out,box-shadow .15s ease-in-out;
    width: 220px;	
    border: 1px solid #9B9B9B;	
    
    border: ${props => props.error ? '1px solid red' : '1px solid #9B9B9B'};

    border-radius: 3px;
    outline: none !important;
    ::placeholder {
      color: #636363;
      font-size: 14px;
    }
    @media (max-width: 576px) {
      width: 70%;	   
    }
`;

export const InputCheckBox = styled.input`
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    /* @media (max-width: 576px) {
      width: 70%;	   
    } */
`;

export const SubContainer = styled.div`
  width:400px;
  padding-top: 30px;
  padding-bottom: 50px;
  box-shadow: 0px 2px 10px 6px rgba(0,0,0,0.1);
  background-color: white;
  z-index: 101;
  @media (max-width: 576px) {
    width: 90%;
  }
`;

export const Links = styled.button`
  color: #4A90E2;	
  font-family: 'Nunito';	
  font-size: 14px;	
  line-height: 19px;	
  text-align: center;
  border: 0px;
  background-color: white;
  :focus {
    outline: none;
  }
`;

export const Login = styled.button`
  color: #4A90E2;	
  font-family: 'Nunito';	
  font-size: 20px;	
  line-height: 19px;	
  text-align: center;
  border: 0px;
  background-color: white;
  :focus {
    outline: none;
  }
  :disabled {
    opacity: 0.5;
  }
`;

export const InactiveWords = styled.div`
  font-family: 'Nunito';	
  font-size: 14px;	
  line-height: 19px;	
  text-align: center;
`;

export const Line = styled.div`
  box-sizing: border-box;	
  height: 1px;	
  width: 60px;	
  background-color: #D5D5D5;
  opacity: 0.8;
`;


export const Buttons = styled.button`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 200px;
  height: 40px;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 5px;
  box-shadow: 0 4px 10px 4px rgba(0,0,0,0.1);
  :focus {
    outline: none;
  }
  :disabled {
    opacity: 0.5;
  }
`;

export const Logo = styled.img`
  max-width: 110px;
  min-width: 90px;
  @media (max-width: 576px) {
    max-width: 90px;
    min-width: 90px;
  }
`;

export const LogoW = styled.img`
  max-width: 150px;
  min-width: 120px;
  margin-top: 1rem;
  @media (max-width: 576px) {
    max-width: 120px;
    min-width: 120px;
  }
`;

export const GoogleLabel = styled.div`
  color: #666666;	
  font-size: 16px;	
  font-weight: bold;
  margin-top: 1px;
  :disabled {
    opacity: 0.5;
  }
`;