import React from 'react';

const DashboardCard = (props) => {
    const { children, item, className, onNavigate, width } = props;
    let statusBar;
    switch(item.Indicator) {
        case 'Red': statusBar = '#ff0100'; break;
        case 'Green': statusBar = '#29d70a'; break;
        case 'Amber': statusBar = '#ffbf01'; break;
        default: break;
    }
    return (
        <button id='dashboardCard' type="submit" key={item.id} 
        style={{ borderBottom: `5px solid`, ...((width || width === 0) && 
            { backgroundPositionX: 'left', backgroundPositionY: 'bottom', backgroundColor: 'white',
            backgroundSize: width && `${width}% 5px`, backgroundRepeat: 'no-repeat' })
        }}
            className={`dash_item rm-button-style ${className ? className : ''} border-indicator${item.Indicator ? ` ${item.Indicator.toLowerCase()}` : ``}`}
            onClick={() => onNavigate(item)}>
            { children }
        </button>
    )
}

export default DashboardCard;