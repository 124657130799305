import { combineReducers } from 'redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import { reducer as navigationReducer, initialState as navigationInitial } from './navigation'
import { reducer as selectedModule, initialState as selectedModuleInitial } from './module'
import { reducer as systemModal, initialState as systemModalInitial } from './systemStatusModal'
import { reducer as systemModule, initialState as systemModuleInitial } from './systemModule'
import { reducer as updateModule, initialState as updateModuleInitial } from './commonModule'
import { reducer as errorModule, initialState as errorModuleInitial } from './error'
import { reducer as apartmentReducer, initialState as apartmentInitial } from './apartment'
import { reducer as dashboardReducer, initialState as dashboardInitial } from './dashboard'

export const initialState = {
  navigation: navigationInitial,
  module: selectedModuleInitial,
  modal: systemModalInitial,
  systemUpdateModule: systemModuleInitial,
  updateModule: updateModuleInitial,
  errorModule: errorModuleInitial,
  apartment: apartmentInitial,
  dashboard: dashboardInitial,
}

export const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  navigation: navigationReducer,
  module: selectedModule,
  updateModule: updateModule,
  modal: systemModal,
  systemUpdateModule: systemModule,
  errorModule,
  apartment: apartmentReducer,
  dashboard: dashboardReducer,
})
