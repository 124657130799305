import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Container, SubContainer, Login, Messages, Logo, LogoW } from "./SigninStyles";
import createHistory from "../../history";
import Loading from "../common/Loading/Loading";
import domain from "../../config/config";


class Message extends Component {
  state = {};

  login = () => {
    const { setErrorMessage } = this.props;
    setErrorMessage(null, null)
    createHistory.push('signin')
  }

  render() {
    const { error } = this.props;
    if (!error) {
      return <Loading showLogo="false" />;
    }
    return (
      <Container className="d-flex flex-column align-items-center">
        <SubContainer className="d-flex flex-column align-items-center">          
          <Logo src={require('../../assets/images/logoOnly.png')} alt="logo" />
          <LogoW src={require('../../assets/images/logoWordmarkBlack.png')} alt="logo" />
          <Messages className="text-wrap">{error}</Messages>          
          <Login className="mt-3" onClick={this.login}>Login</Login>
        </SubContainer>
        <img src={`${domain}/static/media/building.47338d1a.png`} className='col-12' alt="Logo" style={{ position: 'absolute', bottom: 0, zIndex: 100 }} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  error: state.errorModule.errorMessage
});
const mapDispatchToProps = dispatch => ({
  setErrorMessage: (message, errorType) => dispatch({ type: "ERROR_VALUE", message, errorType })
});


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
)(Message);
