import moment from 'moment';
import React, { forwardRef, useLayoutEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';


export const ContractModal = (props) => {
  const { show, onHide, contractMaster, Category, action, interval, onSubmitData } = props;
  const [startDate, setStartDate] = useState('');
  const [LastPaymentDate, setPaymentDate] = useState('');
  const [files, setFiles] = useState([]);
  const [data, setData] = useState({});
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState(false);

  useLayoutEffect(() => {
    setError(false);
    setFiles([]);
    setStartDate('');
    setPaymentDate('');
    if (action === 'Update') {
      const updatedata = {
        PaymentType: interval.PaymentType || 'None'
      }
      if (interval.PaymentType && interval.PaymentType !== 'None') {
        updatedata.PaymentTermType = data.PaymentTermType || interval.PaymentTermType;
      }
      if (data.LastPaymentDate || interval.LastPaymentDate) {
        updatedata.LastPaymentDate = data.LastPaymentDate || (interval.LastPaymentDate && interval.LastPaymentDate.toDate());
      }
      setData(updatedata);
    } else if (action === 'Renew') {
      const renewdata = {
        ServiceIntervalType: interval.ServiceIntervalType || 'Yearly',
        ServiceIntervalNum: interval.ServiceIntervalNum || '1',
        LastServicedDate: null || data.LastServicedDate || (interval.LastServicedDate && interval.LastServicedDate.toDate()),
        UpComingServiceDate: null || data.UpComingServiceDate || (interval.UpComingServiceDate && interval.UpComingServiceDate.toDate()),
        PaymentType: interval.PaymentType || 'None'
      }
      if (interval.PaymentType && interval.PaymentType !== 'None') {
        renewdata.PaymentTermType = data.PaymentTermType || interval.PaymentTermType;
      }
      if (data.LastPaymentDate || interval.LastPaymentDate) {
        renewdata.LastPaymentDate = data.LastPaymentDate || (interval.LastPaymentDate && interval.LastPaymentDate.toDate());
      }
      setData(renewdata);
    } else if (action === 'New') {
      setData({
        ContractType: 'Payable',
        ServiceTitle: '',
        Vendor: '',
        ServiceName: '',
        ServiceIntervalType: 'Yearly',
        ServiceIntervalNum: '1',
        LastServicedDate: null || data.LastServicedDate,
        UpComingServiceDate: null || data.UpComingServiceDate,
        PaymentType: 'None',
      })
    }
  }, [interval, action])

  const isDisabled = () => {
    return Object.keys(data).some(s => !data[s]);
  }

  const setContractData = (type, value) => {
    setData({...data, [`${type}`]: value})
  }

  const setContractType = (value) => {
    if (value === 'Compliance') {
      setData({...data, ContractType: value, ServiceTitle: '' });
    } else {
      setData({...data, ContractType: value });
    }
  }

  const setContractTitle = (value) => {
    const sysType = contractMaster.SystemComplianceMap.find(s => s.Name === value);
    if (sysType) {
      setData({...data, ServiceTitle: value, Vendor: sysType.Agency, ServiceName: sysType.System});
    }
  }

  const setContractTermNum = (value) => {
    let UpComingServiceDate = null;
    const LastServicedDate = data.LastServicedDate ? new Date(data.LastServicedDate) : null;
    const ServiceIntervalType = data.ServiceIntervalType;
    const ServiceIntervalNum = (+value);
    if (LastServicedDate && ServiceIntervalType === 'Yearly') {
      if (ServiceIntervalNum > 0) {
        UpComingServiceDate = new Date(new Date(LastServicedDate.setFullYear(LastServicedDate.getFullYear() + ServiceIntervalNum)).setDate(LastServicedDate.getDate() - 1));
      } else {
        UpComingServiceDate = new Date(new Date(LastServicedDate.setFullYear(LastServicedDate.getFullYear() + 2)).setDate(LastServicedDate.getDate() - 1));
      }
    } else if (LastServicedDate && ServiceIntervalType === 'Monthly') {
      if (ServiceIntervalNum > 0) {
        UpComingServiceDate = new Date(new Date(LastServicedDate.setMonth(LastServicedDate.getMonth() + ServiceIntervalNum)).setDate(LastServicedDate.getDate() - 1));
      } else {
        UpComingServiceDate = new Date(new Date(LastServicedDate.setMonth(LastServicedDate.getMonth() + 6)).setDate(LastServicedDate.getDate() - 1));
      }
    }
    setData({...data, ServiceIntervalNum, UpComingServiceDate});
  }

  const setContractTerm = (value) => {
    let UpComingServiceDate = null;
    const LastServicedDate = data.LastServicedDate ? new Date(data.LastServicedDate) : null;
    const ServiceIntervalType = value;
    const ServiceIntervalNum = (+data.ServiceIntervalNum);
    if (LastServicedDate && ServiceIntervalType === 'Yearly') {
      if (ServiceIntervalNum > 0) {
        UpComingServiceDate = new Date(new Date(LastServicedDate.setFullYear(LastServicedDate.getFullYear() + ServiceIntervalNum)).setDate(LastServicedDate.getDate() - 1));
      } else {
        UpComingServiceDate = new Date(new Date(LastServicedDate.setFullYear(LastServicedDate.getFullYear() + 2)).setDate(LastServicedDate.getDate() - 1));
      }
    } else if (LastServicedDate && ServiceIntervalType === 'Monthly') {
      if (ServiceIntervalNum > 0) {
        UpComingServiceDate = new Date(new Date(LastServicedDate.setMonth(LastServicedDate.getMonth() + ServiceIntervalNum)).setDate(LastServicedDate.getDate() - 1));
      } else {
        UpComingServiceDate = new Date(new Date(LastServicedDate.setMonth(LastServicedDate.getMonth() + 6)).setDate(LastServicedDate.getDate() - 1));
      }
    }
    setData({...data, ServiceIntervalType, UpComingServiceDate});
  }

  const setContractStartDate = (date) => {
    setStartDate(date);
    let UpComingServiceDate = null;
    const LastServicedDate = new Date(date);
    const ServiceIntervalType = data.ServiceIntervalType;
    const ServiceIntervalNum = (+data.ServiceIntervalNum);
    if (ServiceIntervalType && ServiceIntervalType === 'Yearly') {
      if (ServiceIntervalNum > 0) {
        UpComingServiceDate = new Date(new Date(LastServicedDate.setFullYear(LastServicedDate.getFullYear() + ServiceIntervalNum)).setDate(LastServicedDate.getDate() - 1));
      } else {
        UpComingServiceDate = new Date(new Date(LastServicedDate.setFullYear(LastServicedDate.getFullYear() + 2)).setDate(LastServicedDate.getDate() - 1));
      }
    } else if (ServiceIntervalType && ServiceIntervalType === 'Monthly') {
      if (ServiceIntervalNum > 0) {
        UpComingServiceDate = new Date(new Date(LastServicedDate.setMonth(LastServicedDate.getMonth() + ServiceIntervalNum)).setDate(LastServicedDate.getDate() - 1));
      } else {
        UpComingServiceDate = new Date(new Date(LastServicedDate.setMonth(LastServicedDate.getMonth() + 6)).setDate(LastServicedDate.getDate() - 1));
      }
    }
    setData({...data, LastServicedDate: date, UpComingServiceDate});
  }

  const setPaymentType = (value) => {
    if (value === 'Postpaid' || value === 'Prepaid') {
      setData({...data, PaymentType: value, PaymentTermType: ''});
    } else {
      const nData = {...data, PaymentType: value };
      delete nData.PaymentTermType;
      setPaymentDate(null);
      setData(nData);
    }
  }

  const setPaymentDateData = (date) => {
    setPaymentDate(date);
    setData({...data, LastPaymentDate: date});
  }

  const setContractFiles = (pickerfiles) => {
    setFiles([...files, ...pickerfiles]);
    setData({...data, files: [...files, ...pickerfiles]});
  }

  const removeFile = (index) => {
    const newFiles = files.filter((f, i) => index !== i);
    setFiles(newFiles);
    setData({...data, files: newFiles});
  }

  const onClickSubmitData = () => {
    if (isDisabled()) {
      setError(true);
      return;
    }
    setError(false);
    setProcessing(true);
    const docId = interval && action !== 'New' && interval.id;
    onSubmitData(data, action, docId);
    setProcessing(false);
    onHide();
  }

  const onClickCancel = () => {
    setData({});
    onHide();
  }

  return (
    <Modal className="profile service-interval-modal" size="lg" show={show} onHide={onHide}>
      <Modal.Header className="tb-modal-header pb-0" closeButton>
      </Modal.Header>
      <Modal.Body className="tb-modal-body">
        <h6 className="mb-4 font-weight-bold">
          { action === 'add' && `Add New ${Category}`}
          { action === 'renew' && `Renew ${Category}`}
          { action === 'update' && `Update ${Category}`}
        </h6>
        <form>
          <div className="row">
            <div className="col-md-6 border-right">
              <div className="form-group">
                <label className="col-form-label">Contract Type</label>
                { action === 'New' ? <div className="d-flex">
                  <div className="inputGroup">
                    <input id="payable" name="contract-type" type="radio" defaultChecked={true}
                    onChange={(e) => setContractType('Payable')}/>
                    <label htmlFor="payable">Payable</label>
                  </div>
                  <div className="inputGroup">
                    <input id="receivable" name="contract-type" type="radio"
                    onChange={(e) => setContractType('Receivable')}/>
                    <label htmlFor="receivable">Receivable</label>
                  </div>
                  <div className="inputGroup">
                    <input id="compliance" name="contract-type" type="radio"
                    onChange={(e) => setContractType('Compliance')}/>
                    <label htmlFor="compliance">Compliance</label>
                  </div>
                </div> :
                <p className="mb-0 text-black-50">{interval.ContractType}</p> }
              </div>
              <div className="form-group">
                <label className="col-form-label">Title</label>
                { action === 'New' ? 
                  data.ContractType !== 'Compliance' ? 
                    <input className={`form-control${error && data.ServiceTitle === '' ? ' has-error' : ''}`}
                      type="text"placeholder={`Title`} onChange={(e) => setContractData('ServiceTitle', e.target.value)}/> :
                    <select className={`form-control${error && data.Vendor === '' ? ' has-error' : ''}`} defaultValue=""
                      onChange={(e) => setContractTitle(e.target.value)}>
                      <option value="" disabled>Choose</option>
                      { contractMaster && contractMaster.SystemComplianceMap.map(st => (
                        <option key={`service-type-${st.Name}`} value={st.Name}>{st.Name}</option>
                      ))}
                    </select>
                   :
                  <p className="mb-0 text-black-50">{interval.ServiceTitle}</p> 
                }
              </div>
              <div className="form-group">
                <label className="col-form-label">Vendor</label>
                { action === 'New' && data.ContractType !== 'Compliance' ? <input className={`form-control${error && data.Vendor === '' ? ' has-error' : ''}`}
                  type="text" placeholder="Vendor Name" onChange={(e) => setContractData('Vendor', e.target.value)} value={data.Vendor}/> : 
                <p className="mb-0 text-black-50">{data.Vendor || interval.Vendor || 'Vendor Name'}</p> }
              </div>
              <div className="form-group">
                <label className="col-form-label">System</label>
                { action === 'New' && data.ContractType !== 'Compliance' ?  <select className={`form-control${error && data.ServiceName === '' ? ' has-error' : ''}`}
                  value={data.ServiceName} required onChange={(e) => setContractData('ServiceName', e.target.value)}>
                  <option value="" disabled>Choose</option>
                  { contractMaster && contractMaster.SystemType.map(st => (
                    <option key={`service-type-${st}`} value={st}>{st}</option>
                  ))}
                </select> :
                <p className="mb-0 text-black-50">{data.ServiceName || interval.ServiceName || 'Service Name'}</p> }
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">Contract Term</label>
                { action !== 'Update' ? <div className="d-flex align-items-end mb-1">
                  <div style={{flex: 0.2}}>
                    <select className="form-control" value={data.ServiceIntervalNum} required
                      onChange={(e) => setContractTermNum(e.target.value)}>
                      <option value="" disabled>Period</option>
                      { Array.apply(null, {length: 12}).map(Number.call, Number).map(i => (
                        <option key={`service-interval-num-${i}`} value={i+1}>{i+1}</option>
                      ))}
                    </select>
                  </div>
                  <div style={{flex: 0.8, marginLeft: '0.5rem'}}>
                    <div className="d-flex">
                      <div className="inputGroup">
                        <input id="contract-yearly" name="contract-term" type="radio" checked={data.ServiceIntervalType === 'Yearly'}
                          onChange={(e) => setContractTerm('Yearly')}/>
                        <label  htmlFor="contract-yearly">Year(s)</label>
                      </div>
                      <div className="inputGroup">
                        <input id="contract-monthly" name="contract-term" type="radio" checked={data.ServiceIntervalType === 'Monthly'}
                          onChange={(e) => setContractTerm('Monthly')}/>
                        <label htmlFor="contract-monthly">Month(s)</label>
                      </div>
                    </div>
                  </div>
                </div> : 
                <p className="mb-0 text-black-50">{`${interval.ServiceIntervalType} (${interval.ServiceIntervalNum} Terms)`}</p> }
              </div>
              <div className="form-group d-flex">
                <div className="flex-grow-1 pr-1">
                      <label className="col-form-label">Contract Start Date</label>
                  { action !== 'Update' ? <DatePicker className={`${error && data.LastServicedDate === null ? ' has-error' : ''}`} customInput={<DatepickerInput />}
                    selected={data.LastServicedDate} dateFormat="dd MMM yyyy" placeholder="Contract Start Date" onChange={date => setContractStartDate(date)} /> : 
                  <p className="mb-0 text-black-50">{interval.LastServicedDate && moment(interval.LastServicedDate.toDate()).format('DD MMM YYYY')}</p> }
                </div>
                <div className="flex-grow-1 pl-1">
                  <label className="col-form-label">Contract End Date</label>
                  {  action !== 'Update' ? <DatePicker selected={data.UpComingServiceDate || null} dateFormat="dd MMM yyyy" 
                    customInput={<DatepickerInput />} placeholder="Contract End Date" popperPlacement="bottom-end" /> : 
                  <p className="mb-0 text-black-50">{interval.UpComingServiceDate && moment(interval.UpComingServiceDate.toDate()).format('DD MMM YYYY')}</p> }
                </div>
              </div>
              <div className="form-group">
                <label className="col-form-label">Payment Type</label>
                <div className="d-flex">
                  <div className="inputGroup">
                    <input id="postpaid" name="payment-type" type="radio" checked={data.PaymentType === 'Postpaid'}
                    onChange={(e) => setPaymentType('Postpaid')} value="postpaid"/>
                    <label htmlFor="postpaid">Postpaid</label>
                  </div>
                  <div className="inputGroup">
                    <input id="prepaid" name="payment-type" type="radio" checked={data.PaymentType === 'Prepaid'}
                    onChange={(e) => setPaymentType('Prepaid')} value="prepaid"/>
                    <label htmlFor="prepaid">Prepaid</label>
                  </div>
                  <div className="inputGroup">
                    <input id="payment-none" name="payment-type" type="radio" checked={data.PaymentType === 'None'}
                    onChange={(e) => setPaymentType('None')} value="none"/>
                    <label htmlFor="payment-none">None</label>
                  </div>
                </div>
              </div>
              { (data.PaymentType === 'Postpaid' || data.PaymentType === 'Prepaid') && <div className="form-group d-flex">
                <div className="d-flex flex-column justify-content-between" style={{flex: 0.5, marginRight: '0.25rem'}}>
                  <label className="col-form-label">Payment Term</label>
                  <select className={`form-control${error && data.PaymentTermType === '' ? ' has-error' : ''}`}
                    value={data.PaymentTermType} required onChange={(e) => setContractData('PaymentTermType', e.target.value)}>
                    <option value="" disabled>Choose</option>
                    <option value="Yearly">Yearly</option>
                    <option value="Half Yearly">Half Yearly</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Bimonthly">Bimonthly</option>
                  </select>
                </div>
                {interval.ContractType === 'Receivable' ?
                  <div className="d-flex flex-column justify-content-between" style={{flex: 0.5, marginLeft: '0.25rem'}}>
                  <label className="col-form-label">Payment Recieved Till</label>
                  <DatePicker selected={data.LastPaymentDate} dateFormat="dd MMM yyyy" popperPlacement="bottom-end"
                    onChange={date => setPaymentDateData(date)} maxDate={new Date()} customInput={<DatepickerInput />} />
                  </div>
                  :
                  <div className="d-flex flex-column justify-content-between" style={{flex: 0.5, marginLeft: '0.25rem'}}>
                  <label className="col-form-label">Paid Till</label>
                  <DatePicker selected={data.LastPaymentDate} dateFormat="dd MMM yyyy" popperPlacement="bottom-end"
                    onChange={date => setPaymentDateData(date)} maxDate={new Date()} customInput={<DatepickerInput />} />
                </div>
                }
                
               
              </div>}
            </div>
          </div>
          <hr/>
          <div className="row">
            <div className="col-md-6 border-right">
              <div className="form-group">
                <label className="col-form-label">Comment</label>
                <textarea className="form-control" rows="5" placeholder="Enter your comment here"
                  onChange={(e) => setContractData('comments', e.target.value)}
                ></textarea>
              </div>              
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">Files</label>
                <div className="custom-file">
                  <input type="file" className="custom-file-input" id="addContractFile" 
                    onChange={(e) => setContractFiles(e.target.files)} accept="image/*, application/pdf"/>
                  <label className="custom-file-label" htmlFor="addContractFile">Choose File</label>
                </div>
                <ul className="file-list">
                  {files.map((file, i) => (
                    <li key={`add-file-${i+1}`}>
                      <span className="mr-2"><i className="icon-attachment"></i></span>
                      <a>{file.name}</a>
                      <a className="ml-3" onClick={() => removeFile(i)}><i className="icon-close"></i></a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="tb-modal-footer">
        <button className="btn btn-sm btn-danger" onClick={onClickCancel}>Cancel</button>
        <button className="btn btn-sm btn-primary" disabled={isProcessing} onClick={onClickSubmitData}>
          {action === 'New' && 'Create' }
          {action === 'Renew' && 'Renew' }
          {action === 'Update' && 'Update' }
        </button>
      </Modal.Footer>
    </Modal>
  )
}

const DatepickerInput = forwardRef((props, ref) => (
  <input type="text" {...props} readOnly />
));