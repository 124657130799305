import React from 'react';
import Lottie from 'react-lottie';
const animationData = require('./trackbot-loader.json');


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Loading = (props) => (
  <div
    className="d-flex justify-content-center align-items-center flex-column"
    style={{ height: props.showLogo!=='false' ? '100vh' : props.height || '80vh', width: "100%" }}
  >
    <Lottie options={defaultOptions} height={48} width={70} />
    {props.showLogo!=='false' &&
      <img alt="logo" className="logo wordmark textLogo" src={require('../../../assets/images/logoWordmarkBlack.png')}
        style={{ height: '2.5rem', width: 90, objectFit:'contain' }}
      />}    
  </div>
);

Loading.propTypes = {};

export default Loading