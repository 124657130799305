/* eslint-disable camelcase */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import * as _ from "lodash";
import Loading from "../common/Loading/Loading";
import "./StaffActivitiesDetails.scss";
import StaffActivityCard from "../Cards/StaffActivityCard/StaffActivityCard";
import createHistory from "../../history";

class StaffActivitiesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      activeSystem: 0,
      photoURL: require("../../assets/images/default_dp.svg"),
      isOnline: false,
    };
    this.componentNameRef = React.createRef();
    this.subMenuRef = React.createRef();
    this.subMenuIconRef = React.createRef();
  }

  componentDidMount() {
    const { user, location, setModule, currentApartment } = this.props;
    const { PhotoURL } = user[0];
    const ServiceArea = decodeURI(location.search.substring(1));
    const width = this.getWidth();
    this.setState({ isMobile: width < 768 });
    window.addEventListener("resize", this.reportWindowSize);
    document.addEventListener("click", this.handleClickOutside);

    if (user.length !== 0 && currentApartment) {
      setModule(ServiceArea);
      this.fetchData(currentApartment);
    }

    if (user && PhotoURL) {
      this.getPhotoURL(PhotoURL);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      user,
      clearFirestore,
      location,
      updateModule,
      taskDetails,
      currentApartment,
    } = this.props;
    if (user.length === 0) {
      clearFirestore();
      return;
    }
    const SearchString = decodeURI(location.search.substring(1));

    if (currentApartment && currentApartment !== prevProps.currentApartment) {
      this.fetchData(currentApartment);
    }
    if (taskDetails && SearchString !== prevProps.updateModule.name) {
//      this.setState({ activeSystem: 0 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.reportWindowSize);
    document.removeEventListener("click", this.handleClickOutside);
  }

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  reportWindowSize = (e) => {
    const { isMobile } = this.state;
    if (isMobile) {
      if (e.target.innerWidth > 768) {
        this.setState({ isMobile: false });
      }
    } else if (e.target.innerWidth < 769) {
      this.setState({ isMobile: true });
    }
  };

  handleClickOutside = (event) => {
    const { isModuleSidebarOpen, isMobile } = this.state;
    if (
      isMobile &&
      this.subMenuRef.current &&
      !this.subMenuRef.current.contains(event.target) &&
      !this.subMenuIconRef.current.contains(event.target) &&
      isModuleSidebarOpen
    ) {
      this.setState({ isModuleSidebarOpen: false });
    }
  };

  objectsEqual = (o1, o2) =>
    typeof o1 === "object" && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => this.objectsEqual(o1[p], o2[p]))
      : o1 === o2;

  arraysEqual = (a1 = [], a2 = []) =>
    a1.length === a2.length &&
    a1.every((o, idx) => this.objectsEqual(o, a2[idx]));

  fetchData = (currentApartment) => {
    const { taskDetails, firestore } = this.props;
    const TaskDetailsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "TaskDetails" }],
      storeAs: "TaskDetails",
    };
    if (!taskDetails || !this.taskDetailsSnapShot) {
      if (this.taskDetailsSnapShot) {
        this.taskDetailsSnapShot();
      }
      this.taskDetailsSnapShot = firestore.onSnapshot(TaskDetailsOptions);
    }
  };

  getPhotoURL = async (path) => {
    const { firebase } = this.props;
    const storage = firebase.storage();
    const url = await storage.ref().child(path).getDownloadURL();
    this.setState({ photoURL: url });
  };

  showSideNav = () => {
    setTimeout(() => this.props.openSideBar(false));
  };

  onClickSideItem = (i) => {
    this.setState({
      activeSystem: i,
      //activeComponent: i,
      ModuleName: "",
      isModuleSidebarOpen: false,
    });
    setTimeout(() => {
      const left =
        document.querySelector(
          ".system-names .list-group .list-group-item.active"
        ).offsetLeft - 50;
      this.componentNameRef.current.scrollLeft = left;
    }, 0);
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  onUpdateTaskDetail = async (taskId, value, files) => {
    const {
      user,
      firestore,
      addTaskImage,
      taskFiles: taskfiles,
      currentApartment,
    } = this.props;

    const { DisplayName } = user[0];

    firestore.update(
      {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [{ collection: "TaskDetails", doc: taskId }],
      },
      {
        TaskAction: value,
        TaskLastUpdatedBy: DisplayName,
        LastUpdatedTime: firestore.Timestamp.fromDate(new Date()),
      }
    );

    if (window.navigator.onLine && files && files.length) {
      // Firebase upload call
      const taskfileimages = {
        ...taskfiles,
        [`${taskId}`]: [...(taskfiles[taskId] || []), ...files],
      };
      this.uploadTaskFiles(taskfileimages);
    } else if (!window.navigator.isOnline && files && files.length) {
      // offline - add to store for later uplolad
      const promises = files.map((file) =>
        this.toBase64(file).then((base64) => base64)
      );

      // Wait for all Promises to complete
      Promise.all(promises)
        .then((fileUrls) => {
          // Handle results
          const taskfileimages = { ...taskfiles, [`${taskId}`]: [...fileUrls] };
          addTaskImage(taskfileimages);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  onVerifyingTask = async (taskId, value, files) => {
    const {
      user,
      firestore,
      addTaskImage,
      taskFiles: taskfiles,
      currentApartment,
    } = this.props;

    const { DisplayName } = user[0];

    firestore.update(
      {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [{ collection: "TaskDetails", doc: taskId }],
      },
      {
        ApprovalStatus: value,
        TaskLastUpdatedBy: DisplayName,
        LastUpdatedTime: firestore.Timestamp.fromDate(new Date()),
      }
    );

    if (window.navigator.onLine && files && files.length) {
      // Firebase upload call
      const taskfileimages = {
        ...taskfiles,
        [`${taskId}`]: [...(taskfiles[taskId] || []), ...files],
      };
      this.uploadTaskFiles(taskfileimages);
    } else if (!window.navigator.onLine && files && files.length) {
      // offline - add to store for later uplolad

      // Map input data to an Array of Promises
      const promises = files.map((file) =>
        this.toBase64(file).then((base64) => base64)
      );

      // Wait for all Promises to complete
      Promise.all(promises)
        .then((fileUrls) => {
          // Handle results
          // const taskfileimages = {...taskfiles,  [`${taskId}`]: [ ...(taskfiles[taskId] || []), ...fileUrls]};
          const taskfileimages = { ...taskfiles, [`${taskId}`]: [...fileUrls] };
          addTaskImage(taskfileimages);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  uploadTaskFiles = async (files) => {
    const {
      user,
      firebase,
      firestore,
      addTaskImage,
      setUploadStatus,
      currentApartment,
    } = this.props;
    if (files && Object.keys(files).length) {
      Promise.all(
        Object.keys(files).map(async (taskId) => {
          if (!files[taskId].length) return false;
          const filesPath = `${currentApartment}/TaskDetails/${taskId}`;
          await firebase.uploadFiles(filesPath, files[taskId]);
          await Promise.all(
            files[taskId].map(async (f) => {
              await firestore.update(
                {
                  collection: "apartments",
                  doc: currentApartment,
                  subcollections: [{ collection: "TaskDetails", doc: taskId }],
                },
                {
                  TaskFiles: firestore.FieldValue.arrayUnion(
                    `${filesPath}/${f.name}`
                  ),
                }
              );
            })
          );
        })
      ).then(() => {
        // Clear offline files when upload completed
        setUploadStatus(false);
        addTaskImage({});
      });
    }
  };

  getPhotoURL = async (path) => {
    const { firebase } = this.props;
    const storage = firebase.storage();
    if (path) {
      return storage.ref().child(path).getDownloadURL();
    }
  };

  getPendingCount = (name) => {
    const { taskDetails, location, updateModule } = this.props;
    const SearchString = decodeURI(window.location.search.substring(1).split(";")[0]);
    const ServiceArea = SearchString;
    const moduleTasks = taskDetails.filter(
      (t) => t.Module === name && t.ServiceArea === ServiceArea
    );
    return moduleTasks.reduce((acc, curr) => {
      if (!curr.TaskValue) acc += 1;
      return acc;
    }, 0);
  };

  getDefaultTaskCount(name) {
    const { taskDetails, location, updateModule } = this.props;
    // let accessInfo = null;
    // if(ApartmentAccessInfo.length){
    //   const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
    //   if(selectedApartment){
    //     accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
    //   }
    // }
    // const { Roles } = accessInfo || {}
    const SearchString = decodeURI(window.location.search.substring(1).split(";")[0]);
    const ServiceArea = SearchString;
    const moduleTasks = taskDetails.filter(
      (t) => t.Module === name && t.ServiceArea === ServiceArea
    );
    const defaultedTasks = [];

    if (name) {
      moduleTasks.map((task, i) => {
        if (task.TaskState.toLowerCase() === "exception") {
          defaultedTasks.push(task);
        }
      });
    }
    return defaultedTasks.length;
  }

  categoriseList(name) {
    const { taskDetails, location, updateModule, ApartmentAccessInfo, currentApartment, user } = this.props;
    let accessInfo = null;
    if(ApartmentAccessInfo.length){
      const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
      if(selectedApartment){
        accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
      }
    }
    const { Roles, is_manager, is_mc_member } = accessInfo || {}
    // const { Roles, is_manager, is_mc_member } = userAccessInfo[0];
    const SearchString = decodeURI(window.location.search.substring(1).split(";")[0]);
    const ServiceArea = SearchString;
    const moduleTasks = taskDetails.filter(
      (t) => t.Module === name && t.ServiceArea === ServiceArea
    );
    this.activeTasks = [];
    this.completedTasks = [];
    this.UpcomingTasks = [];
    this.defaultedTasks = [];
    this.approvalTasks = [];
    this.defaultedTaskCount = 0;

    if (name) {
      moduleTasks.map((task, i) => {
        if (task.TaskState.toLowerCase() === "exception") {
          this.defaultedTasks.push(task);
        } else if (task.TaskState.toLowerCase() === "completed") {
          this.completedTasks.push(task);
        } else if (task.TaskState.toLowerCase() === "active") {
          if (
            Roles && Roles.some(
              (role) =>
                task.CurrentPerformer && task.CurrentPerformer.includes(role)
            )
          ) {
            this.activeTasks.push(task);
          } else if (
            Roles && Roles.some(
              (role) =>
                task.CompletedPerformer &&
                task.CompletedPerformer.includes(role)
            )
          ) {
            this.completedTasks.push(task);
          } else if (is_manager || is_mc_member) {
            this.activeTasks.push({ ...task, readOnly: true });
          }
        }
      });
    }

    if (
      this.activeTasks.length +
      this.defaultedTasks.length +
      this.completedTasks.length
    ) {
      return true;
    }
    return false;
  }

  filterList(moduleNames, groupedTaskDetails) {
    this.moduleNames = [];
    this.groupedTaskDetails = [];
    moduleNames.map((modulename, i) => {
      this.categoriseList(modulename) && this.moduleNames.push(modulename);
    });
    this.moduleNames.map((modulename, i) => {
      this.groupedTaskDetails[modulename] = groupedTaskDetails[modulename];
    });
  }

  showMobileNavbar() {
    const { isMobile, photoURL } = this.state;

    if (isMobile) {
      return (
        <nav className="navbar-mobile">
          <a className="menu-button mr-2" onClick={this.showSideNav}>
            <i className="icon-menu" />
          </a>
          <img
            alt="logo"
            src={require("../../assets/images/logoOnly.png")}
            style={{ height: "1.65rem" }}
          />
          <div className="float-right ml-auto d-flex align-items-center">
            <img
              alt="logo"
              src={photoURL}
              style={{ height: 30, width: 30, borderRadius: 30 }}
              onClick={() => createHistory.push(`/profile`)}
            />
          </div>
        </nav>
      );
    }
  }

  getThemeColor = () => {
    const { AppartmentDetails } = this.props;
    const { isMobile } = this.state;
    if (isMobile && AppartmentDetails[0].Header_Theme_Color) {
      return AppartmentDetails[0].Header_Theme_Color;
    }
    return null;
  };

  render() {
    const {
      taskDetails,
      updateModule,
      location,
      userAccessInfo,
      taskFiles,
      firestore,
      qrData,
      currentApartment
    } = this.props;
    const { is_mc_member, is_manager } = userAccessInfo && userAccessInfo[0];
    const { activeSystem, isMobile } = this.state;
    const SearchString = decodeURI(window.location.search.substring(1).split(";")[0]);
    const ServiceArea = updateModule.name || SearchString;
    if (!taskDetails || !taskDetails.length)
      return <Loading showLogo="false" />;

    const staffTaskDetails = taskDetails.filter(
      (d) => d.ServiceArea === ServiceArea
    );

    let activeComponent = 0; //Default active accordion
    const groupedTaskDetails = _.groupBy(staffTaskDetails, (s) => s.Module);
    const moduleNames = Object.keys(groupedTaskDetails).map((c) => c);
    this.filterList(moduleNames, groupedTaskDetails);
    const activeIdx = this.moduleNames.indexOf(decodeURI(window.location.search.substring(1).split(";")[1]));

    
    if (qrData) {
      activeComponent = moduleNames.findIndex((c) => c === qrData.Component);
    }
    activeComponent = activeComponent || activeSystem || 0;
    
    const mapCondition = groupedTaskDetails && this.moduleNames && this.moduleNames[activeIdx];

    return (
      <div id="staff-activity-detail" className="system-body">
        {groupedTaskDetails ? (
          <div className="row">
            <div className="col">
              <StaffActivityCard
                {...{ groupedTaskDetails, mapCondition }}
                memberOrManager={is_manager || is_mc_member}
                onUpdateTaskDetail={this.onUpdateTaskDetail}
                user={userAccessInfo}
                {...{ isMobile }}
                onVerifyingTask={this.onVerifyingTask}
                getPhotoURL={this.getPhotoURL}
                offlineImages={taskFiles}
                firestore={firestore}
                currentApartment={currentApartment}
                activeComponent={activeComponent}
              />
            </div>
          </div>
        ) : (
          <Loading showLogo="false" />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearFirestore: () => dispatch({ type: "@@reduxFirestore/CLEAR_DATA" }),
  setModule: (name) => dispatch({ type: "UPDATE_MODULE", payload: name }),
  openSideBar: (value) => dispatch({ type: "SIDEBAR_OPEN", payload: value }),
  addTaskImage: (files) => dispatch({ type: "ADD_IMAGE", payload: files }),
  setUploadStatus: (status) =>
    dispatch({ type: "UPLOAD_STATUS", payload: status }), // TO prevent multiple upload of same file
});

const mapStateToProps = (state) => ({
  updateModule: state.updateModule,
  user: state.firestore.ordered.Users,
  taskDetails: state.firestore.ordered.TaskDetails,
  userAccessInfo: state.firestore.ordered.UserAccessInfo,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  taskFiles: state.updateModule.taskFiles,
  uploadStatus: state.updateModule.uploading,
  qrData: state.navigation.qrData,
  currentApartment: state.apartment.currentApartment,
  ApartmentAccessInfo: state.apartment.ApartmentAccessInfo
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(),
  firebaseConnect(),
  withRouter
)(StaffActivitiesDetails);
