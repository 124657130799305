import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  Button,
  Container,
  SubContainer,
  Error,
  Logo,
  LogoW,
} from "./SigninStyles";
import "react-bootstrap-typeahead/css/Typeahead.css";
const axios = require("axios");

class GoogleUser extends Component {
  state = {
    appartment: null,
    notRobot: false,
    appartmentIsEmpty: false,
    cordova: false,
  };

  componentDidMount() {
    const { firestore } = this.props;
    if (this.unsubscribeAppartments) {
      this.unsubscribeAppartments();
    }
    this.unsubscribeAppartments = firestore.onSnapshot({
      collection: "onboarding",
      storeAs: "Appartments",
    });
    if (window.hasOwnProperty("cordova")) {
      this.setState({ cordova: true });
    }
  }

  handleChange = (e) => {
    this.setState({ appartment: e[0] && e[0].Id, appartmentIsEmpty: false });
  };

  onChange = () => {
    this.setState({ notRobot: true });
  };

  onSubmit = () => {
    const { appartment } = this.state;
    const { setErrorMessage, auth, firebase } = this.props;
    console.log("-----------");
    axios
      .post(
        "https://asia-east2-trackbot-prod.cloudfunctions.net/assignUserToAptAfterSignUp",
        {
          uid: auth.uid,
          aptname: appartment,
          displayName: auth.displayName,
          phoneNo: auth.phoneNumber,
        }
      )
      .then((res) => {
        firebase.logout();
        console.log("----------", "done");
        setErrorMessage(res.data, "Show Message");
      });
  };

  render() {
    const { Appartments, error } = this.props;
    const { notRobot, appartmentIsEmpty, cordova } = this.state;
    this.appartment = [];
    if (Appartments) {
      Appartments.map((item) => {
        if (item.Name) {
          const obj = {};
          obj.Name = item.Name;
          obj.Id = item.id;
          this.appartment.push(obj);
        }
      });
    }

    return (
      <Container className="d-flex flex-column align-items-center justify-content-center">
        <SubContainer className="d-flex flex-column align-items-center">
          <Logo src={require("../../assets/images/logoOnly.png")} alt="logo" />
          <LogoW
            src={require("../../assets/images/logoWordmarkBlack.png")}
            alt="logo"
          />

          <div style={{ zIndex: 200, maxWidth: "70%" }}>
            <Typeahead
              labelKey={(option) => option.Name}
              options={this.appartment}
              placeholder="Select Property"
              bsSize="large"
              style={{
                transform: "scaleX(0.97) scaleY(0.9)",
                border: appartmentIsEmpty && "1px solid red",
              }}
              onChange={this.handleChange}
              id={0}
              disabled={!Appartments}
            />
          </div>

          {!cordova && (
            <div style={{ zIndex: 100 }}>
              <ReCAPTCHA
                sitekey="6LcSR5sUAAAAANX5dJdgAxFWgcjH4p5pM92AFx1R"
                onChange={this.onChange}
                size="normal"
                style={{ transform: "scale(0.72)", marginTop: 0 }}
              />
            </div>
          )}

          {error && <Error className="pl-4 pr-4 pt-2">{error}</Error>}

          <Button
            disabled={!notRobot && !cordova}
            onClick={this.onSubmit}
            style={{ marginTop: 25 }}
          >
            <div
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: 14,
                fontFamily: "Nunito",
              }}
            >
              Submit
            </div>
          </Button>
        </SubContainer>
        <img
          src={require("../../assets/images/building.png")}
          className="col-12"
          alt="Logo"
          style={{ position: "absolute", bottom: 0, zIndex: 100 }}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.firestore.ordered.Users,
  Appartments: state.firestore.ordered.Appartments,
  error: state.errorModule.errorMessage,
  auth: state.firebase.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setErrorMessage: (message, errorType) =>
    dispatch({ type: "ERROR_VALUE", message, errorType }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(),
  firestoreConnect()
)(GoogleUser);
