/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/button-has-type */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
import React, { useMemo, useState, useEffect } from "react";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import {
  OverlayTrigger,
  Popover,
  Modal,
  Spinner,
  Button,
  Table,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import moment from "moment";
import { connect } from "react-redux";
import * as _ from "lodash";

import styled from "styled-components";
import { useTable, useGlobalFilter } from "react-table";
// import { withRouter } from "react-router-dom";
import DetailsHeader from "../../Common/DetailsHeader/DetailsHeader";
import Icon from "../../Common/Icon/Icon";
import { BackButton, ExportButton, FilterButton } from "../../Common/Buttons";
import {
  Search,
  SearchInput,
  Thead,
  ModalThead,
  ModalTitle,
  ModalTBody,
  Divider,
  IconSearch,
  Styles,
  Image,
  DetailsModal,
  ExportFile,
} from "../styles";
import useWindowSize from "../../Hooks/useWindowSize";

import ContactCard from "../../Common/ContactCard/ContactCard";
import createHistory from "../../../../../history";

import "./TodoDetails.css";
// Create a default prop getter
const defaultPropGetter = () => ({});

const { Parser } = require('json2csv');

function ParentTable({ columns, data, getCellProps = defaultPropGetter }) {
  // Use the state and functions returned from useTable to build your UI
  const [filterInput, setFilterInput] = React.useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value || "");
  };
  // Render the UI for your table
  return (
    <>
      <div className="exe-details-header-container d-flex flex-row justify-content-between mb-3">
        <div className="d-flex flex-row align-items-center">
          <BackButton />
          <DetailsHeader label="Todo" description="Properties with managers todo nearing due or overdue" />
        </div>
        <div className="d-flex flex-row align-items-center col-4 m-0 p-0">
          {/* <ExportButton />
          <FilterButton /> */}
          <Search className="ml-4 flex-grow-1">
            <SearchInput
              type="text"
              className="w-100"
              placeholder="Search"
              value={filterInput}
              onChange={handleFilterChange}
            />
            <IconSearch className="icon-search" />
          </Search>
        </div>
      </div>
      <table {...getTableProps()} className="child-table-border-separate">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </Thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr>
                {row.cells.map((cell, i) => (
                  <td {...cell.getCellProps(i === 0 && getCellProps(cell))}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const TodoDetails = ({
  TodoDetails,
  consolidated,
  firestore,
  manager_todo_summary_ed,
  managersList,
  ConsViews,
  viewDetail,
  selectedZone,
  setManagers
}) => {
  const [todoDetails, setTodoDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apartmentId, setApartmentId] = useState(null);
  const [apartmentName, setApartmentName] = useState(null);
  const [issue, setIssue] = useState(null);
  const [statusLabel, setStatusLabel] = useState(null);
  const [exportCSV, setExportCSV] = React.useState("");

  useEffect(() => {
    if (apartmentId && consolidated && consolidated.length) {
      setIsLoading(true);
      setIssue(null);
      firestore
        .get({
          collection: "consolidated",
          doc: consolidated[0].id,
          subcollections: [
            {
              collection: "ManagersToDO_ED",
              doc: apartmentId,
              subcollections: [{ collection: "todo_details_ed" }],
            },
          ],
          storeAs: "todo_details_ed",
        })
        .then(async (querySnapshot) => {
          setIsLoading(false);
          let issues = [];
          querySnapshot.forEach((doc) => {
            issues = [...issues, { ...doc.data() }];
          });
          // console.log(issues)
          const issueGroup = _.chain(issues).groupBy("Indicator").value();
          // console.log('issueGroup :',issueGroup)
          let issueGroupList = [];

          // eslint-disable-next-line no-prototype-builtins
          if (issueGroup.hasOwnProperty("Red")) {
            issueGroupList = [...issueGroupList, ...issueGroup.Red];
          }
          // eslint-disable-next-line no-prototype-builtins
          if (issueGroup.hasOwnProperty("Amber")) {
            issueGroupList = [...issueGroupList, ...issueGroup.Amber];
          }
          // console.log('issueGroupList :',issueGroupList)
          setIssue(issueGroupList);

          if(!issueGroupList.length){
            return false
          }
          const fileDownload = [...issueGroupList];
          const exportList = fileDownload.map(item => ({
              ...item,
              CreationDate: item.CreationDate.toDate().toDateString(),
              DueBy: item.CreationDate.toDate().toDateString(),
              DueDate: item.CreationDate
              ? moment().diff(
                  moment(item.CreationDate.toDate()).format(),
                  "days"
                )
              : 0
            }))

          const fields = [{
            label: 'Title',
            value: 'Title'
          },{
            label: 'Created On',
            value: 'CreationDate'
          },{
            label: 'Due By',
            value: 'DueBy'
          },{
            label: 'Assigned To',
            value: 'AssignedTo'
          },{
            label: 'Over Due By',
            value: 'DueDate'
          }];
          

          const json2csvParser = new Parser({fields});
          const csv = json2csvParser.parse(exportList);

          window.URL = window.webkitURL || window.URL;
          const platform = window.navigator.platform
          const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
          const contentType = windowsPlatforms.indexOf(platform) !== -1 ? 'application/vnd.ms-excel':'text/csv';
          const csvFile = new Blob([csv], {type: contentType});
          const csvFileExport = window.URL.createObjectURL(csvFile)

          setExportCSV(csvFileExport)
          // setIssue(issues);
        })
        .catch(() => setIsLoading(false));
    }
  }, [apartmentId]);

  useEffect(() => {
    async function fetchManagers() {
      let users = [];
      setIsLoading(true);
      firestore
        .get({
          collection: "Users",
          where: [["is_cons_user", "==", true]
            , ["is_approved", "==", true]],
          storeAs: "ManagersList",
        })
        .then(async (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            users = [...users, { id: doc.id, ...doc.data() }];
          });

          await Promise.all(
            (users || []).map(async (user) =>
              firestore
                .get({
                  collection: "Users",
                  doc: user.id,
                  subcollections: [
                    {
                      collection: "ConsViews",
                      where: [["ConsViewId", "==", ConsViews[0].ConsViewId]],
                    },
                  ],
                  storeAs: "ManagersListZone",
                })
                .then(async (doc) => {
                  if (doc.empty) {
                    return false;
                  }

                  let managers = {};
                  doc.forEach((doc) => {
                    // console.log(doc.data());
                    managers = { UID: doc.id, ...doc.data() };
                  });
                  return { ...user, ...managers };
                })
            )
          )
            .then((prop) => {
              setIsLoading(false);
              const filterEmptyManagerList = prop.filter((manager) => manager);
              setManagers(filterEmptyManagerList);
            })
            .catch(() => setIsLoading(false));
        });
    }
    if (!managersList.length && !isLoading) {

      fetchManagers();
    }
    if (!consolidated) {

      firestore.get({
        collection: "consolidated",
        where: [["ConsViewId", "==", ConsViews[0].ConsViewId]],
        storeAs: "consolidated",
      });
    }
  }, [consolidated])

  useEffect(() => {
    if (consolidated && consolidated.length) {
      const {StatusToLabel : Label} = consolidated[0]
          let StatusToLabel = ''
          if(Label.hasOwnProperty("CommunityIncidents_ED")){
             StatusToLabel = Label.CommunityIncidents_ED
          } else if(Label.hasOwnProperty("Default")){
              StatusToLabel = Label.Default
          }
      setStatusLabel(StatusToLabel);
    }
    let condition = [["StatusIndicator", "in", ["Red", "Amber"]]];
    if (selectedZone && selectedZone !== "All") {
      condition = [...condition, ["Zone", "==", selectedZone]];
    }
    if (!consolidated || !consolidated.length) {
      return;
    }
    firestore
      .get({
        collection: "consolidated",
        doc: consolidated[0].id,
        subcollections: [
          {
            collection: "ManagersToDO_ED",
            where: condition,
          },
        ],
        storeAs: "TodoDetails",
      })
      .then((querySnapshot) => {
        let todosDetails = [];
        const { Level: currentUserLevel } = ConsViews[0];

        querySnapshot.forEach((doc) => {
          const managers = managersList.filter(
            ({ Zones, Level }) =>
              Zones.includes(doc.data().Zone) &&
              Level > currentUserLevel &&
              Level <= currentUserLevel + 2
          );

          todosDetails = [
            ...todosDetails,
            {
              id: doc.id,
              ...doc.data(),
              managers,
            },
          ];
        });

        const todoDetails = _.chain(todosDetails)
          .groupBy("StatusIndicator")
          .value();

        if (Object.keys(todoDetails).length) {
          // eslint-disable-next-line no-prototype-builtins
          if (!todoDetails.hasOwnProperty("Red")) {
            todoDetails.Red = [];
          }
          // eslint-disable-next-line no-prototype-builtins
          if (!todoDetails.hasOwnProperty("Amber")) {
            todoDetails.Amber = [];
          }
          const statusRed = JSON.parse(JSON.stringify(todoDetails.Red));
          const statusAmber = JSON.parse(JSON.stringify(todoDetails.Amber));
          setTodoDetails([...statusRed, ...statusAmber]);
          // console.log([...statusRed, ...statusAmber])
        }else{
          setTodoDetails([])
        }
      });
  }, [selectedZone, consolidated, managersList]);

  const { width: WindowWidth } = useWindowSize();
  const columns = useMemo(
    () => [
      {
        Header: "Property Name",
        accessor: "ApartmentName", // accessor is the "key" in the data
      },
      {
        Header: "Contact Person",
        accessor: "managers",
        Cell: ({ cell }) => {
          const managers = cell.row.original.managers;

          const { DisplayName, Role, PhoneNo } = managers[0] || {};
          if (!managers.length) return "";
          return (
            <div className="row">
              <ContactCard
                name={DisplayName}
                userRole={Role}
                contact={PhoneNo}
                hideUnderline
              />
              <OverlayTrigger
                rootClose
                trigger="click"
                // key="bottom"
                placement="right"
                overlay={
                  <Popover
                    // id="popover-positioned-bottom"
                    style={{ maxWidth: "350px" }}
                  >
                    <Popover.Content>
                      {managers.map((manager) => {
                        const { DisplayName, Role, PhoneNo } = manager;
                        return (
                          <ContactCard
                            key={PhoneNo}
                            name={DisplayName}
                            userRole={Role}
                            contact={PhoneNo}
                          />
                        );
                      })}
                    </Popover.Content>
                  </Popover>
                }
              >
                <button style={{ background: "none", border: "none" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                  >
                    <path
                      d="M1 1L4 4L7 1"
                      stroke="#B5B5B5"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </OverlayTrigger>
            </div>
          );
        },
      },
      {
        Header: "Todo's",
        accessor: "TotalOverDueToDo",
        Cell: ({ cell }) => (
          <div className="row justify-content-center">
            <div
              className="d-inline mr-1"
              onClick={() => {
                setShow(true);
                setApartmentId(cell.row.original.id);
                setApartmentName(cell.row.original.ApartmentName);
              }}
            >
              <u style={{ color: "#558DCA" }}>
                {cell.row.original.TotalOverDueToDo
                  ? cell.row.original.TotalOverDueToDo
                  : null}
              </u>
            </div>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => {
          const status =
            cell.row.original.StatusIndicator && statusLabel
              ? statusLabel[cell.row.original.StatusIndicator]
              : "";

          return (
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div
                // className={"status"}
                style={{
                  width: "119px",
                  height: "30px",
                  borderRadius: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    cell.row.original.StatusIndicator === "Red"
                      ? "#FF8D8D"
                      : "#FFCB7E",
                }}
              >
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: "12px",
                    color: "#FFFFFF",
                    textTransform: "uppercase",
                  }}
                >
                  {status}
                </span>
              </div>
              <button
                type="button"
                onClick={() => {
                  viewDetail(
                    "/adhoc",
                    cell.row.original.Apt_Id
                  );
                  // createHistory("/dashboard/system_health");
                }}
                style={{ border: "none", background: "none" }}
              >
                <Icon icon="rightArrow" fill="#558DCA" />
              </button>
            </div>
          );
        },
      },
    ],
    [statusLabel]
  );
  const data = useMemo(() => todoDetails, [todoDetails]);

  return (
    <div className="d-flex flex-row flex-wrap m-4">
      <div className="col-lg-12 col-md-12 col-sm-12">
        {WindowWidth <= 768 ? (
          <div style={{ marginTop: "1.25rem" }}>Todo Details</div>
        ) : null}

        <Styles>
          {!data.length ? (
            <>
              <div className="d-flex flex-row align-items-center">
                <BackButton />
                <DetailsHeader
                  label="Todo"
                  description="Properties with managers todo nearing due or overdue"
                />
              </div>
              <div className="d-flex justify-content-center mt-5">
                <span className="mt-5">No Issues to Display</span>
              </div>
            </>
          ) : (
            <ParentTable
              columns={columns}
              data={data}
              getCellProps={(cell, i) => ({
              style: {
                borderLeft:
                  cell.row.original.StatusIndicator === "Red"
                      ? "8px solid #FF8D8D"
                      : "8px solid #FFCB7E",
                    
                width: "20%",
              },
        })}
              // getCellProps={(cell) => ({
              //   style: {
              //     borderLeft:
              //       cell.row.original.StatusIndicator === "Red"
              //         ? "8px solid #FF8D8D"
              //         : "8px solid #FFCB7E",
              //   },
              // })}
            />
          )}
        </Styles>
      </div>
      <DetailsModal
        show={show}
        size="lg"
        onHide={() => {
          setShow(false);
          setApartmentId(null);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {isLoading ? (
          <div className="row justify-content-center">
            <Spinner
              animation="grow"
              variant="dark"
              className="align-items-center"
            />
          </div>
        ) : (
          <>
            <ModalHeader>
              <div className="d-flex justify-content-between align-items-center w-100">
                <ModalTitle>Todo Details - {apartmentName}</ModalTitle>
                <a href={exportCSV} download={`${apartmentName}.csv`}><ExportFile /></a>
                <Image
                  onClick={() => {
                    setShow(false);
                    setApartmentId(null);
                  }}
                />
              </div>
            </ModalHeader>

            <Modal.Body>
              <div className="tableFixHead">
                <table style={{
                    borderCollapse: 'separate',
                    borderSpacing: '0 2px',
                    // display: 'block',
                    // overflowX: 'auto',
                    // // whiteSpace: 'nowrap'
                  }}>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Created</th>
                      <th>Due By</th>
                      <th>Assigned To</th>
                      <th>Over Due By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(issue || []).map((item) => {
                      const dueByCount = item.CreationDate ?moment().diff(
                        moment(item.CreationDate.toDate()).format(),
                        "days"
                      ):0

                      return (
                        <tr key={item.ToDoId}>
                          <td style={{
                            borderLeft:
                              item.Indicator === "Red"
                                ? "8px solid #FF8D8D"
                                : "8px solid #FFCB7E",
                          }}>{item.Title}</td>
                          <td>{item.CreationDate?item.CreationDate.toDate().toDateString():null}</td>
                          <td>{item.DueDate?item.DueDate.toDate().toDateString():null}</td>
                          <td>{item.AssignedTo}</td>
                          <td>
                            {_.isNumber(dueByCount) && dueByCount < 0
                              ? ""
                              : `${dueByCount} Days`}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              {/* <Table className="mb-0">
                <ModalThead>
                  <tr>
                    <th>ToDo</th>
                    <th>Created</th>
                    <th>Due By</th>
                    <th>Assigned To</th>
                    <th>Over Due By</th>
                  </tr>
                </ModalThead>
                <ModalTBody>
                  {(issue || []).map((item) => {
                    const dueByCount = moment().diff(
                      moment(item.DueDate.toDate()).format(),
                      "days"
                    );

                    return (
                      <tr key={item.ToDoId}>
                        <td>{item.ToDoId}</td>
                        <td>{item.CreationDate.toDate().toDateString()}</td>
                        <td>{item.DueDate.toDate().toDateString()}</td>
                        <td>{item.AssignedTo}</td>
                        <td>
                          {_.isNumber(dueByCount) && dueByCount < 0
                            ? ""
                            : `${dueByCount} Days`}
                        </td>
                      </tr>
                    );
                  })}
                </ModalTBody>
              </Table> */}
            </Modal.Body>
          </>
        )}
      </DetailsModal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setManagers: (manegers) =>
  dispatch({ type: "SET_MANAGERS_LIST", payload: manegers }),
  viewDetail: (route, apartment) =>
    dispatch({
      type: "REDIRECT_DASHBOARD_TO_ROUTE",
      payload: { route, apartment },
    }),
});
export default compose(
  connect(
    ({
      dashboard,
      apartment,
      firestore: {
        ordered: {
          TodoDetails,
          consolidated,
          manager_todo_summary_ed,
          ConsViews,
        },
      },
    }) => ({
      TodoDetails,
      consolidated,
      manager_todo_summary_ed,
      managersList: apartment.managersList,
      ConsViews,
      selectedZone: dashboard.selectedZone,
    }),
    mapDispatchToProps
  ),
  firestoreConnect()
)(TodoDetails);
