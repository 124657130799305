import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as _ from "lodash";
import CustomDateView from "../Cards/StaffActivities/CustomDateView";
import Loading from "../common/Loading/Loading";
import SearchBarFilter from "../common/SearchBarFilter";
import AttendanceHistory from "../Cards/AttendanceList/AttendanceHistoryCard";
import AttendanceNow from "../Cards/AttendanceList/AttendanceNowCard";
import "./Attendance.css";
import "react-datepicker/dist/react-datepicker.css";

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      activetab: 0,
      date: new Date().setDate(new Date().getDate() - 1),
      month: moment().format("MMMM"),
      year: moment().format("YYYY"),
      searchValue: "",
      employeeSort: null,
      focusSearch: false,
      serviceArea: "All",
      photoURL: require("../../assets/images/default_dp.svg"),
    };
    this.componentNameRef = React.createRef();
    this.subMenuRef = React.createRef();
    this.subMenuIconRef = React.createRef();
  }

  componentDidMount() {
    const { user, location, currentApartment } = this.props;
    const ServiceName = decodeURI(location.search.substring(1));
    this.setState({ activetab: ServiceName === "Monthly" ? 1 : 0 });

    const width = this.getWidth();
    this.setState({ isMobile: width < 768 });
    window.addEventListener("resize", this.reportWindowSize);
    document.addEventListener("click", this.handleClickOutside);

    if (user.length !== 0 && currentApartment) {
      this.fetchAttendanceNow(currentApartment);
    }
  }

  componentDidUpdate(prevProps) {
    const { currentApartment } = this.props;
    if (currentApartment && currentApartment !== prevProps.currentApartment) {
      this.fetchAttendanceNow(currentApartment, true);
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.reportWindowSize);
    document.removeEventListener("click", this.handleClickOutside);
  };

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  reportWindowSize = (e) => {
    const { isMobile } = this.state;
    if (isMobile) {
      if (e.target.innerWidth > 768) {
        this.setState({ isMobile: false });
      }
    } else if (e.target.innerWidth < 769) {
      this.setState({ isMobile: true });
    }
  };

  handleClickOutside = (event) => {
    const { isComponentSidebarOpen, isMobile } = this.state;
    if (
      isMobile &&
      this.subMenuRef.current &&
      !this.subMenuRef.current.contains(event.target) &&
      !this.subMenuIconRef.current.contains(event.target) &&
      isComponentSidebarOpen
    ) {
      this.setState({ isComponentSidebarOpen: false });
    }
  };

  fetchAttendanceNow = (currentApartment) => {
    const { firestore, attendanceNow } = this.props;

    const DailyAttendanceNowOptions = {
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [{ collection: 'DailyAttendanceNow' }],
      storeAs: 'DailyAttendanceNow',
    };

    if (!attendanceNow) {
      if (this.unsubscribeDailyAttendanceNow) {
        this.unsubscribeDailyAttendanceNow();
      }
      this.unsubscribeDailyAttendanceNow = firestore.onSnapshot(
        DailyAttendanceNowOptions
      );
    }
  }


  fetchAttendanceHistorical = () => {
    const { firestore, user, attendanceHistorical, currentApartment } = this.props;
    //const { currentApartment } = user[0];

    const DailyAttendanceHistoricalOptions = {
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [{ collection: 'DailyAttendanceHistorical' }],
      storeAs: 'DailyAttendanceHistorical',
    };

    if (!attendanceHistorical) {
      if (this.unsubscribeDailyAttendanceHistorical) {
        this.unsubscribeDailyAttendanceHistorical();
      }
      this.unsubscribeDailyAttendanceHistorical = firestore.onSnapshot(
        DailyAttendanceHistoricalOptions
      );
    }
  };

  getPhotoURL = async (path) => {
    const { firebase } = this.props;
    const storage = firebase.storage();
    if (path) {
      return storage.ref().child(path).getDownloadURL();
    }
  };

  filterAttendance = (data) => {
    const { searchValue } = this.state;
    return data.filter(
      (value) =>
        (value.Name &&
          value.Name.substring(0, searchValue.length).toLowerCase() ===
            searchValue.toLowerCase()) ||
        (value.ServiceArea &&
          value.ServiceSubArea.substring(
            0,
            searchValue.length
          ).toLowerCase() === searchValue.toLowerCase()) ||
        (value.Status &&
          value.Status.substring(0, searchValue.length).toLowerCase() ===
            searchValue.toLowerCase())
    );
  };

  sortAttendance = (data) => {
    const { employeeSort } = this.state;
    return data.sort((a, b) => {
      if (employeeSort || employeeSort === null) {
        return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase());
      }
      return b.Name.toLowerCase().localeCompare(a.Name.toLowerCase());
    });
  };

  onTextChange = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  changeDate = async (add, month) => {
    const { date } = this.state;
    let newDate;
    if (add) {
      if (month) {
        newDate = new Date(moment(date).add(1, "month"));
      } else {
        newDate = new Date(moment(date).add(1, "day"));
      }
      this.setState({
        date: newDate,
        year: moment(newDate).format("YYYY"),
        month: moment(newDate).format("MMMM"),
      });
      return;
    }
    if (month) {
      newDate = new Date(moment(date).subtract(1, "month"));
    } else {
      newDate = new Date(moment(date).subtract(1, "day"));
    }
    this.setState({
      date: newDate,
      year: moment(newDate).format("YYYY"),
      month: moment(newDate).format("MMMM"),
    });
  };

  showSideNav = () => {
    setTimeout(() => this.props.openSideBar(false));
  };

  onClickSideItem = (tab) => {
    this.setState({
      activetab: tab,
      ModuleName: "",
      isModuleSidebarOpen: false,
      serviceArea: "All",
    });
  };

  getThemeColor = () => {
    const { AppartmentDetails } = this.props;
    const { isMobile } = this.state;
    if (isMobile && AppartmentDetails[0].Header_Theme_Color) {
      return AppartmentDetails[0].Header_Theme_Color;
    }
    return null;
  };

  showComponentSideBar() {
    const { isModuleSidebarOpen, activetab, isMobile } = this.state;
    if (!isMobile) return null;

    return (
      <div
        className={["component-sidebar", isModuleSidebarOpen && "open"].join(
          " "
        )}
        ref={this.subMenuRef}
        style={{ marginTop: 15 }}
      >
        <a
          className="close"
          onClick={() => this.setState({ isModuleSidebarOpen: false })}
        >
          <i className="icon-close" style={{ fontSize: "0.75rem" }} />
        </a>
        <ul className="list-group">
          <a
            className={`list-group-item d-flex justify-content-between align-items-center${
              activetab === 0 ? ` active` : ``
            }`}
            onClick={() => this.onClickSideItem(0)}
          >
            Now
          </a>
          <a
            className={`list-group-item d-flex justify-content-between align-items-center${
              activetab === 1 ? ` active` : ``
            }`}
            onClick={() => this.onClickSideItem(1)}
          >
            Last 30 days
          </a>
        </ul>
      </div>
    );
  }

  renderAttendanceNow = () => {
    const { attendanceNow } = this.props
    const { serviceArea } = this.state;
    if (!attendanceNow) return <Loading showLogo="false" />

    let attendance = this.filterAttendance(attendanceNow);
    attendance = this.sortAttendance(attendance);
    if (serviceArea !== 'All') attendance = attendance.filter(v => v.ServiceArea === serviceArea)

    return (<AttendanceNow attendance={attendance} getPhotoURL={this.getPhotoURL} />)
  }

  renderAttendanceHistorical = () => {
    const { attendanceHistorical } = this.props;
    const { serviceArea, date } = this.state;
    if (!attendanceHistorical) return <Loading showLogo="false" />

    let attendance = attendanceHistorical.filter(attnd => (attnd.AttendanceDate && moment(attnd.AttendanceDate.toDate()).format('DDMMYY') === moment(date).format('DDMMYY')));
    attendance = this.filterAttendance(attendance);
    attendance = this.sortAttendance(attendance);
    if (serviceArea !== 'All') attendance = attendance.filter(v => v.ServiceArea === serviceArea)

    return (<AttendanceHistory attendance={attendance} getPhotoURL={this.getPhotoURL} />)
  }

  render() {
    const { isMobile, activetab, focusSearch, searchValue, date } = this.state;
    const { monthPicker } = this.props;

    return (
      <div id="staff-attendance">
        {this.showComponentSideBar()}
        <div
          className="system-header d-flex justify-content-between align-items-end"
          style={{ backgroundColor: this.getThemeColor() }}
        >
          <div className={`${isMobile ? "d-flex flex-column" : ""}`}>
            <h4 className="order-2">Attendance</h4>
            <p className="text-muted mb-0 mr-2">
              <span>Staff Activities</span> {!isMobile && `/ Attendance`}
            </p>
          </div>
          <div className={`d-flex${isMobile ? ` flex-row-reverse` : ``}`}>
            { !isMobile && <SearchBarFilter
              focusSearch={focusSearch} onTextChange={this.onTextChange}
              onFocus={() => this.setState({ focusSearch: true })} onBlur={() => this.setState({ focusSearch: false })}
              nameSort={searchValue} placeholder="Enter Name, Department or Status"
            /> }
            { activetab === 1 && <div className="d-inline-flex">
              <DatePicker className="custom-date-picker" minDate={new Date().setDate(new Date().getDate() - 31)} maxDate={new Date().setDate(new Date().getDate() - 1)}
                onChange={(d) => { this.setState({ date: d, year: moment(d).format('YYYY'), month: moment(d).format('MMMM'), serviceArea: 'All' }) }}
                selected={date} peekNextMonth showMonthDropdown showYearDropdown popperPlacement="top-end"
                customInput={<CustomDateView monthPicker={monthPicker} />} showMonthYearPicker={monthPicker}
              />
                   
                <span className="p-1" style={{ marginLeft: -20, height: 20 }}>
                  <i className="icon-calendar calendar" />
                </span>
              </div>
            }
          </div>
        </div>
        <div className="row system-body">
          <div
            className="col-md-3 system-names d-flex d-md-block"
            style={{ backgroundColor: this.getThemeColor() }}
          >
            {isMobile && (
              <a
                className="menu d-flex align-items-center"
                onClick={() => this.setState({ isModuleSidebarOpen: true })}
                ref={this.subMenuIconRef}
              >
                <i className="icon-hamburger" />
              </a>
            )}
            <ul className="list-group" ref={this.componentNameRef}>
              <a
                className={`list-group-item d-flex justify-content-between align-items-center${
                  activetab === 0 ? ` active` : ``
                }`}
                onClick={() =>
                  this.setState({ activetab: 0, serviceArea: "All" })
                }
              >
                Now
              </a>
              <a className={`list-group-item d-flex justify-content-between align-items-center${activetab === 1 ? ` active` : ``}`}
                onClick={() => { this.setState({ activetab: 1, serviceArea: 'All' });this.fetchAttendanceHistorical() }}>
                Last 30 days
              </a>
            </ul>
          </div>
          <div className="col">
            {isMobile && (
              <div className="d-flex justify-content-end">
                <SearchBarFilter
                  focusSearch={focusSearch}
                  onTextChange={this.onTextChange}
                  onFocus={() => this.setState({ focusSearch: true })}
                  onBlur={() => this.setState({ focusSearch: false })}
                  nameSort={searchValue}
                  placeholder="Enter Name, Department or Status"
                />
              </div>
            )}
            { activetab === 0 ? this.renderAttendanceNow() : this.renderAttendanceHistorical() }
          </div>
        </div>
      </div>
    );
  }
}

Attendance.defaultProps = {
  user: [{ Email: "", currentApartment: "" }],
};

const mapStateToProps = (state) => ({
  user: state.firestore.ordered.Users,
  attendanceNow: state.firestore.ordered.DailyAttendanceNow,
  attendanceHistorical: state.firestore.ordered.DailyAttendanceHistorical,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  currentApartment: state.apartment.currentApartment,
});

const mapDispatchToProps = (dispatch) => ({
  openSideBar: (value) => dispatch({ type: "SIDEBAR_OPEN", payload: value }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(),
  firebaseConnect(),
  withRouter
)(Attendance);
