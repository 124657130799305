import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import * as _ from "lodash";
import "./SystemHealth.css";
import moment from "moment";
import Loading from "../common/Loading/Loading";
import SystemHealthCard from "../Cards/SystemHealthCard/SystemHealthCard";
import SystemUpdateCard from "../Cards/SystemUpdateCard/SystemUpdateCard";
import createHistory from "../../history";
import ContactsInfo from "../Cards/ContactsInfo/ContactsInfo";

class SystemHealth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      activeSystem: 0,
      loading: false,
      EquipmentName: "",
      ComponentName: "",
      isHistoryOpen: false,
      historyLength: 3,
      activeGeneralSystem: 0,
      isSidepaneOpen: false,
      isInspected: false,
      qrDataRecieved: false,
      photoURL: require("../../assets/images/default_dp.svg"),
    };
    this.componentNameRef = React.createRef();
    this.subMenuRef = React.createRef();
    this.subMenuIconRef = React.createRef();
  }

  objectsEqual = (o1, o2) =>
    typeof o1 === "object" && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => this.objectsEqual(o1[p], o2[p]))
      : o1 === o2;

  componentDidMount() {
    const { user, location, setModule, currentApartment} = this.props;
    const { PhotoURL } = user[0];
    const ServiceName = decodeURI(location.search.substring(1));
    const width = this.getWidth();
    this.setState({ isMobile: width < 768 });
    window.addEventListener("resize", this.reportWindowSize);
    document.addEventListener("click", this.handleClickOutside);
    if (user.length !== 0 && currentApartment) {
      setModule(ServiceName);
      this.fetchData(currentApartment);
    }

    if (user && PhotoURL) {
      this.getPhotoURL(PhotoURL);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      systemEquipmentDetails,
      updateModule,
      qrData,
      toggleQrModal,
      currentApartment,
    } = this.props;
    if (currentApartment && currentApartment !== prevProps.currentApartment) {
      this.fetchData(currentApartment);
    }
    if (
      systemEquipmentDetails &&
      updateModule.name != prevProps.updateModule.name
    ) {
      toggleQrModal(false);
      this.setState({
        activeSystem: 0,
        isHistoryOpen: false,
        isSidepaneOpen: false,
        isInspected: false,
      });
    }
    if (
      systemEquipmentDetails &&
      qrData &&
      Object.keys(qrData).length &&
      !this.objectsEqual(prevProps.qrData, qrData)
    ) {
      if (qrData.qrType === "Device")
        this.openHistory(qrData.Device, qrData.Component);
    }
  }

  componentWillUnmount() {
    const { toggleQrModal } = this.props;
    toggleQrModal(false);
    window.removeEventListener("resize", this.reportWindowSize);
    document.removeEventListener("click", this.handleClickOutside);
  }

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  reportWindowSize = (e) => {
    const { isMobile } = this.state;
    if (isMobile) {
      if (e.target.innerWidth > 768) {
        this.setState({ isMobile: false });
      }
    } else if (e.target.innerWidth < 769) {
      this.setState({ isMobile: true });
    }
  };

  handleClickOutside = (event) => {
    const { isComponentSidebarOpen, isMobile } = this.state;
    if (
      isMobile &&
      this.subMenuRef.current &&
      !this.subMenuRef.current.contains(event.target) &&
      !this.subMenuIconRef.current.contains(event.target) &&
      isComponentSidebarOpen
    ) {
      this.setState({ isComponentSidebarOpen: false });
    }
  };

  fetchData = (currentApartment) => {
    const {
      firestore,
      systemDetails,
      systemEquipmentDetails,
      systemComponentDetails,
      contactInfo,
    } = this.props;
    const SystemDetailsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "SystemDetails" }],
      storeAs: "SystemDetails",
    };
    const SystemEquipmentDetailsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "SystemEquipmentDetails" }],
      storeAs: "SystemEquipmentDetails",
    };
    const SystemComponentDetailsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "SystemComponentDetails" }],
      storeAs: "SystemComponentDetails",
    };
    const ContactInfoOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "ContactInfo" }],
      storeAs: "ContactInfo",
    };
    if (!systemDetails) {
      if (this.unsubscribeSystemDetailsOptions) {
        this.unsubscribeSystemDetailsOptions();
      }
      this.unsubscribeSystemDetailsOptions = firestore.onSnapshot(
        SystemDetailsOptions
      );
    }
    if (!systemEquipmentDetails) {
      if (this.unsubscribeSystemEquipmentDetailsOptions) {
        this.unsubscribeSystemEquipmentDetailsOptions();
      }
      this.unsubscribeSystemDetailsOptions = firestore.onSnapshot(
        SystemEquipmentDetailsOptions
      );
    }
    if (!systemComponentDetails) {
      if (this.unsubscribeSystemComponentDetailsOptions) {
        this.unsubscribeSystemComponentDetailsOptions();
      }
      this.unsubscribeSystemComponentDetailsOptions = firestore.onSnapshot(
        SystemComponentDetailsOptions
      );
    }
    if (!contactInfo) {
      if (this.unsubscribeContactInfoOptions) {
        this.unsubscribeContactInfoOptions();
      }
      this.unsubscribeContactInfoOptions = firestore.onSnapshot(
        ContactInfoOptions
      );
    }
  };

  onSaveContactCard = (contactInfo, id) => {
    const { firestore, location, currentApartment } = this.props;
    const ServiceName = decodeURI(location.search.substring(1));

    if (id) {
      firestore.update(
        {
          collection: "apartments",
          doc: currentApartment,
          subcollections: [{ collection: "ContactInfo", doc: id }],
        },
        { ...contactInfo, ServiceName }
      );
    } else {
      firestore.add(
        {
          collection: "apartments",
          doc: currentApartment,
          subcollections: [{ collection: "ContactInfo" }],
        },
        { ...contactInfo, ServiceName }
      );
    }
  };

  onDeleteContact = (id) => {
    const { firestore, currentApartment } = this.props;
    firestore.delete({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "ContactInfo", doc: id }],
    });
  };

  getEqpContacts(ComponentName, contacts) {
    const { location } = this.props;
    const ServiceName = decodeURI(location.search.substring(1));
    return (
      contacts &&
      contacts.filter(
        (c) =>
          c.ServiceName === ServiceName &&
          c.ComponentName === ComponentName &&
          c.Type === "Component"
      )
    );
  }

  getPhotoURL = async (path) => {
    const { firebase } = this.props;
    const storage = firebase.storage();
    const url = await storage.ref().child(path).getDownloadURL();
    this.setState({ photoURL: url });
  };

  showSideNav = () => {
    setTimeout(() => this.props.openSideBar(false));
  };

  getIndicator(name) {
    const { systemEquipmentDetails, location } = this.props;
    const ServiceName = decodeURI(location.search.substring(1));
    const readings = systemEquipmentDetails.filter(
      (s) => s.ComponentName === name && s.ServiceName === ServiceName
    );
    const red = readings.filter((s) => s.Indicator === "Red");
    const amber = readings.filter((s) => s.Indicator === "Amber");

    let indicator;
    if (red && red.length) {
      indicator = { color: "red", count: red.length };
    } else if (amber && amber.length) {
      indicator = { color: "amber", count: amber.length };
    }

    return indicator;
  }

  openHistory = async (EquipmentName, ComponentName) => {
    const { location, updateModule, firestore, currentApartment } = this.props;
    const SearchString = decodeURI(location.search.substring(1));
    const ServiceName = SearchString || updateModule.name;
    this.setState({
      isHistoryOpen: true,
      EquipmentName,
      ComponentName,
      isHistoryLoading: true,
      qrDataRecieved: true,
    });

    console.log(ServiceName, EquipmentName, ComponentName);
    if (this.unsubscribeServiceIntervalDetails) {
      this.unsubscribeServiceIntervalDetails();
    }
    this.unsubscribeServiceIntervalDetails = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [
        {
          collection: "ServiceIntervalDetails",
          where: [
            ["ServiceName", "==", ServiceName],
            ["ComponentName", "==", ComponentName],
            ["EquipmentName", "==", EquipmentName],
          ],
        },
      ],
      storeAs: "ServiceIntervalDetails",
    });
    if (this.unsubscribeSystemEquipmentInfo) {
      this.unsubscribeSystemEquipmentInfo();
    }
    this.unsubscribeSystemEquipmentInfo = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [
        {
          collection: "SystemEquipmentInfo",
          where: [
            ["ServiceName", "==", ServiceName],
            ["ComponentName", "==", ComponentName],
            ["EquipmentName", "==", EquipmentName],
          ],
        },
      ],
      storeAs: "SystemEquipmentInfo",
    });
    await firestore.get({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [
        {
          collection: "HistoricalSysEqpDetails",
          where: [
            ["ServiceName", "==", ServiceName],
            ["ComponentName", "==", ComponentName],
            ["EquipmentName", "==", EquipmentName],
          ],
        },
      ],
      orderBy: ["UpdatedTime", "desc"],
      storeAs: "HistoricalSysEqpDetails",
    });

    this.setState({ isHistoryLoading: false });
  };

  closeHistory = () => {
    setTimeout(() => {
      this.setState({
        isHistoryOpen: false,
        EquipmentName: "",
        ComponentName: "",
        isHistoryLoading: false,
        historyLength: 3,
      });
    }, 500);
  };

  toggleFullhistory = () => {
    const { historicalSysEqpDetails } = this.props;
    const { historyLength } = this.state;
    this.setState({
      historyLength: historyLength === 3 ? historicalSysEqpDetails.length : 3,
    });
  };

  onClickSideItem = (i) => {
    this.setState({
      activeSystem: i,
      isHistoryOpen: false,
      EquipmentName: "",
      ComponentName: "",
      historyLength: 3,
      isComponentSidebarOpen: false,
    });
    setTimeout(() => {
      const left =
        document.querySelector(
          ".system-names .list-group .list-group-item.active"
        ).offsetLeft - 50;
      this.componentNameRef.current.scrollLeft = left;
    }, 0);
  };

  showMobileNavbar() {
    const { user } = this.props;
    const { isMobile, photoURL } = this.state;

    if (isMobile) {
      return (
        <nav className="navbar-mobile">
          <a className="menu-button mr-2" onClick={this.showSideNav}>
            <i className="icon-menu" />
          </a>
          <img
            alt="logo"
            src={require("../../assets/images/logoOnly.png")}
            style={{ height: "1.65rem" }}
          />
          <div className="float-right ml-auto d-flex align-items-center">
            <img
              alt="logo"
              src={photoURL}
              style={{ height: 30, width: 30, borderRadius: 30 }}
              onClick={() => createHistory.push(`/profile`)}
            />
          </div>
        </nav>
      );
    }
  }

  showComponentSideBar() {
    const { isComponentSidebarOpen, activeSystem } = this.state;
    const { systemEquipmentDetails, location } = this.props;
    const ServiceName = decodeURI(location.search.substring(1));

    const _systemEqpDetails =
      systemEquipmentDetails &&
      systemEquipmentDetails.filter((e) => e.ServiceName === ServiceName);
    const sysEqpDetails = _.groupBy(_systemEqpDetails, (s) => s.ComponentName);
    const componentNames = Object.keys(sysEqpDetails).map((c) => c);

    return (
      <div
        className={["component-sidebar", isComponentSidebarOpen && "open"].join(
          " "
        )}
        ref={this.subMenuRef}
      >
        <a
          className="close"
          onClick={() => this.setState({ isComponentSidebarOpen: false })}
        >
          <i className="icon-close" />
        </a>
        <ul className="list-group">
          {componentNames.map((component, i) => (
            <a
              className={`list-group-item d-flex justify-content-between align-items-center${
                activeSystem === i ? ` active` : ``
              }`}
              key={i}
              onClick={() => this.onClickSideItem(i)}
            >
              {component}
              {this.getIndicator(component) && (
                <span
                  className={`badge badge-secondary badge-pill ml-2 badge-indicator ${
                    this.getIndicator(component).color
                  }`}
                >
                  {this.getIndicator(component).count}
                </span>
              )}
            </a>
          ))}
        </ul>
      </div>
    );
  }

  showGeneralService = async () => {
    const {
      user,
      firestore,
      location,
      updateModule,
      currentApartment,
    } = this.props;
    const SearchString = decodeURI(location.search.substring(1));
    const ServiceName = updateModule.name || SearchString;
    this.setState({ isSidepaneOpen: true, generalServiceLoading: true });
    const GeneralServiceIntervalDetails = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [
        {
          collection: "ServiceIntervalDetails",
          where: ["ServiceName", "==", ServiceName],
        },
      ],
      storeAs: "GeneralServiceIntervalDetails",
    };
    await firestore.get(GeneralServiceIntervalDetails);
    if (this.unsubscribeGeneralServiceIntervalDetails) {
      this.unsubscribeGeneralServiceIntervalDetails();
    }
    this.unsubscribeGeneralServiceIntervalDetails = firestore.onSnapshot(
      GeneralServiceIntervalDetails
    );
    this.setState({ generalServiceLoading: false });
  };

  onToggleCollapseGeneral = (docId) => {
    const { generalServiceIntervalDetails } = this.props;
    const { activeGeneralSystem } = this.state;
    const newIndex = generalServiceIntervalDetails.findIndex(
      (s) => s.id === docId
    );
    if (activeGeneralSystem === newIndex)
      this.setState({ activeGeneralSystem: -1 });
    else if (newIndex > -1) this.setState({ activeGeneralSystem: newIndex });
  };

  onInspected = async () => {
    const {
      user,
      location,
      firestore,
      systemDetails,
      currentApartment,
    } = this.props;
    const { DisplayName } = user[0];
    const ServiceName = decodeURI(location.search.substring(1));
    const systemCard =
      systemDetails && systemDetails.filter((e) => e.Name === ServiceName)[0];

    await firestore.update(
      {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [
          {
            collection: "SystemDetails",
            doc: systemCard.id,
            subcollections: [
              { collection: "system_details_internal", doc: systemCard.id },
            ],
          },
        ],
      },
      {
        _InspectedTime: firestore.Timestamp.fromDate(new Date()),
        _InspectedBy: DisplayName,
      }
    );
    this.setState({ isInspected: true });
  };

  generalService() {
    const {
      isSidepaneOpen,
      generalServiceLoading,
      activeGeneralSystem,
    } = this.state;
    const { generalServiceIntervalDetails, contactInfo } = this.props;
    return (
      <SlidingPane
        className="system-sliding-pane"
        overlayClassName="some-custom-overlay-class"
        isOpen={isSidepaneOpen}
        onRequestClose={() => {
          this.setState({ isSidepaneOpen: false });
        }}
      >
        <>
          <h5>
            Service Summary
            <button
              className="close"
              onClick={() => {
                this.setState({ isSidepaneOpen: false });
              }}
            >
              <i className="icon-close" />
            </button>
          </h5>
          <hr />
          <div className="system-card">
            {!generalServiceLoading &&
            generalServiceIntervalDetails &&
            generalServiceIntervalDetails.length ? (
              <>
                {generalServiceIntervalDetails.map((service, i) => (
                  <SystemUpdateCard
                    key={i}
                    srvInterlDetail={service}
                    sysServiceCollapse={activeGeneralSystem !== i}
                    toggleCollpase={this.onToggleCollapseGeneral}
                    contactInfo={contactInfo}
                    deleteContact={this.onDeleteContact}
                    onSaveContactCard={this.onSaveContactCard}
                  />
                ))}
              </>
            ) : !generalServiceLoading &&
              generalServiceIntervalDetails &&
              !generalServiceIntervalDetails.length ? (
              <p>
                <small>No service systems found!</small>
              </p>
            ) : (
              <Loading height="15rem" showLogo="false" />
            )}
          </div>
        </>
      </SlidingPane>
    );
  }

  performAudit = async () => {
    const {location, firestore, user, systemDetails, currentApartment } = this.props;
    const serviceName = decodeURI(location.search.substring(1));
    const systemCard = systemDetails && systemDetails.filter(e => e.Name === serviceName)[0];
    const { DisplayName } = user[0];
    await firestore.update({
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [{
        collection: 'SystemDetails',
        doc: systemCard.id,
        subcollections: [{ collection: 'system_details_internal', doc: systemCard.id }]
      }]
    }, { 
        _IsAuditing : "Yes",
        _InspectedTime: firestore.Timestamp.fromDate(new Date()),
        _InspectedBy: DisplayName 
      });

    await firestore.update({
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [{
        collection: 'SystemDetails',
        doc: systemCard.id,
      }]
    }, { InspectionDue : false });
  }

  getThemeColor=()=>{
    const {AppartmentDetails } = this.props;
    const {isMobile  } = this.state;
    if(isMobile && AppartmentDetails[0].Header_Theme_Color)
    {
      return AppartmentDetails[0].Header_Theme_Color
    }
    return null;
  };

  /*getModuleLastUpdate = (LastInspectedTime) => {
    console.log('LastInspectedTime = ', moment(LastInspectedTime.Date).format('D MMM YYYY'), ', LastInspectedTime = ', LastInspectedTime);
    //console.log(' LastInspectedTime = ', LastInspectedTime.Date)
    //const period = moment(new Date()).diff(moment(LastInspectedTime.seconds), 'seconds');
    //console.log('period = ', period);
    //return moment(period*1000).format('D MMM YYYY');
    return moment(LastInspectedTime.Date).format('D MMM YYYY');
  }*/

  render() {
    const {
      location,
      systemEquipmentDetails,
      systemDetails,
      qrData,
      contactInfo,
    } = this.props;
    const {
      activeSystem,
      loading,
      isMobile,
      isHistoryOpen,
      EquipmentName,
      ComponentName,
      isHistoryLoading,
      historyLength,
      isInspected,
    } = this.state;
    const ServiceName = decodeURI(location.search.substring(1));
    //console.log(' ServiceName = ', ServiceName);
    let activeComponent;
    if (!systemEquipmentDetails || !systemEquipmentDetails.length) return (<Loading showLogo="false" />)

    const systemCard = systemDetails && systemDetails.filter(e => e.Name === ServiceName)[0];
    //console.log(' systemCard.Name = ', systemCard.Name, ', systemCard.LastInspectedTime = ', systemCard.LastInspectedTime, ', systemCard.LastUpdatedBy = ', systemCard.LastUpdatedBy);
    const _systemEqpDetails = systemEquipmentDetails && systemEquipmentDetails.filter(e => e.ServiceName === ServiceName);
    const sysEqpDetails = _.groupBy(_systemEqpDetails, s => s.ComponentName);
    const componentNames = Object.keys(sysEqpDetails).map(c => c);
    const inspectionValues = (systemCard && systemCard.ValueDictionary) || [];
    const eqpContacts = this.getEqpContacts(componentNames[activeSystem], contactInfo);

    if (qrData) {
      activeComponent = componentNames.findIndex((c) => c === qrData.Component);
    }
    activeComponent = activeComponent || activeSystem || 0;

    const mapCondition = sysEqpDetails && componentNames && componentNames[activeComponent];

    return (
      <div id="system-health">
        {this.showComponentSideBar()}
        <div
          className="system-header d-flex justify-content-between align-items-center"
          style={{ backgroundColor: this.getThemeColor() }}
        >
          <div
            className={`${
              isMobile ? "d-flex flex-column overflow-hidden" : ""
            }`}
          >
            <h4 className="order-2">{ServiceName}</h4>
            <p className="text-muted mb-0 mr-2"><span>System Health</span> {!isMobile && `/ ${ServiceName}`}</p>
              {isMobile && 
                <span className="subtitle order-3">
                  {systemCard.InspectionOverDue 
                    ? `Audit Over Due: ${systemCard.InspectionOverDue} Days`
                    : `Last Audited: ${systemCard.LastInspectedTimeStr ? systemCard.LastInspectedTimeStr : ''}`
                  }
                </span>}
          </div>
          <div className="d-flex">
            {!isMobile && 
              <p className="subtitle mb-0 mr-3 mt-2" style={{ fontSize: 14 }}>
                {systemCard.InspectionOverDue 
                    ? `Audit Over Due: ${systemCard.InspectionOverDue} Days`
                    : `Last Audited: ${systemCard.LastInspectedTimeStr ? systemCard.LastInspectedTimeStr : ''}`
                  }
              </p>}
            {
            systemCard.InspectionDue ? 
              <button className="btn border-indicator badge-indicator amber btn-sm mr-2" onClick={() => this.performAudit()}>Audit</button> 
              : systemCard.UnderAudit? <button className="btn border-indicator badge-indicator amber btn-sm mr-2" >Under Audit</button>: null
            }
            <button className="btn btn-primary btn-icon" onClick={() => this.showGeneralService()}>
              <i className="icon-screw-driver" />
            </button>
          </div>
        </div>
        {sysEqpDetails ? (
          <div className="row system-body">
            {this.generalService()}
            <div
              className="col-md-4 system-names d-flex d-md-block"
              style={{ backgroundColor: this.getThemeColor() }}
            >
              {isMobile && (
                <a
                  className="menu d-flex align-items-center"
                  onClick={() =>
                    this.setState({ isComponentSidebarOpen: true })
                  }
                  ref={this.subMenuIconRef}
                >
                  <i className="icon-hamburger" />
                </a>
              )}
              <ul className="list-group" ref={this.componentNameRef}>
                {componentNames.map((component, i) => (
                  <a
                    className={`list-group-item d-flex justify-content-between align-items-center${
                      activeComponent === i ? ` active` : ``
                    }`}
                    key={i}
                    onClick={() =>
                      this.setState({
                        activeSystem: i,
                        isHistoryOpen: false,
                        EquipmentName: "",
                        ComponentName: "",
                        historyLength: 3,
                      })
                    }
                  >
                    {component}
                    {this.getIndicator(component) && (
                      <span
                        className={`badge badge-secondary badge-pill ml-2 badge-indicator ${
                          this.getIndicator(component).color
                        }`}
                      >
                        {this.getIndicator(component).count}
                      </span>
                    )}
                  </a>
                ))}
              </ul>
            </div>
            <div className="col">
              <SystemHealthCard
                {...{
                  EquipmentName,
                  ComponentName,
                  isHistoryLoading,
                  historyLength,
                  mapCondition,
                  sysEqpDetails,
                  inspectionValues,
                  isHistoryOpen,
                  contactInfo,
                  isMobile
                }}
                openHistory={this.openHistory}
                closeHistory={this.closeHistory}
                toggleFullhistory={this.toggleFullhistory}
                deleteContact={this.onDeleteContact}
                onSaveContactCard={this.onSaveContactCard}
              />
              <h6 className="mt-4 font-weight-bold">
                <span
                  className="mr-2"
                  style={{ marginBottom: "-3px", color: "#558dca" }}
                >
                  <i className="icon-info" />
                </span>
                Contacts
              </h6>
              <ContactsInfo
                contacts={eqpContacts}
                Type="Component"
                ComponentName={componentNames[activeSystem]}
                deleteContact={this.onDeleteContact}
                limit={5}
                onSaveContactCard={this.onSaveContactCard}
              />
            </div>
          </div>
        ) : (
          <Loading showLogo="false" />
        )}
      </div>
    );
  }
}

SystemHealth.defaultProps = {
  user: [{ Email: "" }],
};

const mapStateToProps = (state) => ({
  email: state.firebase.auth.email,
  user: state.firestore.ordered.Users,
  systemDetails: state.firestore.ordered.SystemDetails,
  systemEquipmentDetails: state.firestore.ordered.SystemEquipmentDetails,
  systemComponentDetails: state.firestore.ordered.SystemComponentDetails,
  contactInfo: state.firestore.ordered.ContactInfo,
  historicalSysEqpDetails: state.firestore.ordered.HistoricalSysEqpDetails,
  generalServiceIntervalDetails:
    state.firestore.ordered.GeneralServiceIntervalDetails,
  updateModule: state.updateModule,
  qrData: state.navigation.qrData,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  currentApartment: state.apartment.currentApartment,
});

const mapDispatchToProps = (dispatch) => ({
  setModule: (name) => dispatch({ type: "UPDATE_MODULE", payload: name }),
  openSideBar: (value) => dispatch({ type: "SIDEBAR_OPEN", payload: value }),
  toggleQrModal: (val, data) =>
    dispatch({ type: "TOGGLE_QR_MODAL", payload: { val, data } }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(),
  firebaseConnect(),
  withRouter
)(SystemHealth);
