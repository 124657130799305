import styled from "styled-components";

export const HeaderLeft = styled.span`
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Nunito';
`;

export const HeaderRight = styled.button`
  color: #4A90E2;
  font-size: 15px;
  cursor: "pointer";
  border-width: 0px;
  outline: none !important;
  border-radius: 5px;
  font-family: 'Nunito';
  background-color: transparent;
  @media (max-width: 576px) { 
    font-size: 11px;    
    margin-left: 3px;
  }
`;

export const GreenDot = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background-color: #79d140;
`;

export const AmberDot = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background-color: #ffbf00;
`;

export const RedDot = styled.div`
  height: 14px;
  width: 14px;
  border-radius: 7px;
  background-color: red;
`;

export const Subheading = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-family: 'Nunito';
  line-height: 19px;
`;

export const Name = styled.span`
  font-size: 10px;
  font-weight: 500;
  font-family: 'Nunito';
`;

export const Value = styled.span`
  font-size: 13px;
  font-weight: 400;
  font-family: 'Nunito';
`;
export const ValueInput = styled.input`
  height: 30px;
  width: 200px;
  font-size: 13px;
  font-weight: 400;
  border : 0px;
  padding-left: 5px;
  outline: none !important;
  font-family: 'Nunito';
  ::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
`;

export const Container = styled.div`
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: white;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 20px grey;
  padding-bottom: 30px;

  @media (max-width: 576px) { 
    padding: 15px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const PlusMinus = styled.button`
  height: 25px;
  width: 25px;
  border : 0px;
  font-weight: bold;
  cursor: "pointer";
  outline: none !important;
  background-color: grey;
  color: white;
  border-radius: 15px;
  font-size: 15px;
`;


export const InputContainer = styled.div`
    border : 1px solid #c2c2c2;
    border-radius: 5px;
    overflow:hidden;
`;

export const DropDown = styled.select`
    width: 200px;
    height: 30px;
    border : 1px solid #c2c2c2;
    border-radius: 5px;
    overflow:hidden;
    font-size: 13px;
    padding-left: 10px;
    outline: none !important;
    font-family: 'Nunito';
    cursor: pointer;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
`;