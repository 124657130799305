import React from "react";
import Executive from "../Executive/Executive";
import StaffDetails from "../Executive/Components/Details/StaffDetails/StaffDetails";
import Properties from "../Executive/Components/Details/Properties/Properties";
import Tasks from "../Executive/Components/Details/Tasks/Tasks";
import SystemHealthDetails from "../Executive/Components/Details/SystemHealth/SystemHealthDetails";
import ManpowerDetails from "../Executive/Components/Details/ManpowerDetails/ManpowerDetails";
import InventoryDetails from "../Executive/Components/Details/InventoryDetails/InventoryDetails";
import AuditingDetails from "../Executive/Components/Details/AuditingDetails/AuditingDetails";
import IncidentDetails from "../Executive/Components/Details/IncidentDetails/IncidentDetails";
import ComplianceDetails from "../Executive/Components/Details/ComplianceDetails/ComplianceDetails";
import TodoDetails from "../Executive/Components/Details/TodoDetails/TodoDetails";
import AdminSettings from "../Executive/Components/Details/AdminSettings/AdminSettings";
import ServicesIntervalDetails from "../Executive/Components/Details/ServicesInterval/ServicesIntervalDetails";
import Profile from "../Profile/Profile";

const executiveRoutes = [
  {
    id: 0,
    path: "/executive",
    exact: true,
    main: () => <Executive />,
  },
  {
    id: 1,
    path: "/executive/properties",
    exact: true,
    main: () => <Properties />,
  },
  {
    id: 2,
    path: "/executive/tasks",
    exact: true,
    main: () => <Tasks />,
  },
  {
    id: 3,
    path: "/executive/widget/staff",
    exact: true,
    main: () => <StaffDetails />,
  },
  {
    id: 4,
    path: "/executive/widget/system-health",
    exact: true,
    main: () => <SystemHealthDetails />,
  },
  {
    id: 5,
    path: "/executive/widget/manpower",
    exact: true,
    main: () => <ManpowerDetails />,
  },
  {
    id: 6,
    path: "/executive/widget/inventory",
    exact: true,
    main: () => <InventoryDetails />,
  },
  {
    id: 7,
    path: "/executive/widget/auditing",
    exact: true,
    main: () => <AuditingDetails />,
  },
  {
    id: 8,
    path: "/executive/widget/incident",
    exact: true,
    main: () => <IncidentDetails />,
  },
  {
    id: 9,
    path: "/executive/widget/compliance",
    exact: true,
    main: () => <ComplianceDetails />,
  },
  {
    id: 10,
    path: "/executive/widget/services-interval",
    exact: true,
    main: () => <ServicesIntervalDetails />,
  },
  {
    id: 11,
    path: "/executive/widget/todo",
    exact: true,
    main: () => <TodoDetails />,
  },
  {
    id: 13,
    path: "/executive/settings",
    // exact: true,
    main: () => <AdminSettings />,
  },
  {
    id: 14,
    path: "/executive/profile",
    exact: true,
    main: () => <Profile />
  },
];

export default executiveRoutes;
