import React, { Component } from 'react';
import { Graph } from '../../common/Graph/Graph';
import Loading from '../../common/Loading/Loading';
import { Collapse } from 'react-bootstrap';

export class GraphCard extends Component {
  constructor(props) {
    super(props);
    this.state = { settings: null, isMobile: false, isCollapsed: false };
    this.graphCardRef = React.createRef();
  }

  componentDidMount() {
    const { settings } = this.props;
    const width = this.getWidth();
    window.addEventListener('resize', this.reportWindowSize);
    this.setState({ graphSettings: settings[0], isMobile: width < 768 });
  }

  componentDidUpdate(prevProps) {
    const { settings } = this.props;
    if ((!this.arraysEqual(prevProps.settings, settings))) {
      this.setState({ graphSettings: settings[0] })
    }
  }

  getWidth = () => Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  ) 

  reportWindowSize = (e) => {
    const { isMobile } = this.state
    if (isMobile) {
      if (e.target.innerWidth > 768) {
        this.setState({ showButton: false, isMobile: false });
      }
    }
    else if (e.target.innerWidth < 769) {
      this.setState({ showButton: true, isMobile: true });
    }
  }

  objectsEqual = (o1, o2) => {
    return ( typeof o1 === 'object' && Object.keys(o1).length > 0 
      ? Object.keys(o1).length === Object.keys(o2).length 
          && Object.keys(o1).every(p => this.objectsEqual(o1[p], o2[p]))
      : o1 === o2 );
  }

  arraysEqual = (a1 = [], a2 = []) =>  a1.length === a2.length && a1.every((o, idx) => this.objectsEqual(o, a2[idx]));

  toggleCollapse = () => {
    const { onClickCollapse } = this.props;
    onClickCollapse('graphs', 'AnalyticsGraph');
  }
  
  onClickSideItem = (i) => {
    const { settings } = this.props;
    this.setState({graphSettings: settings[i], isGraphSidebarOpen: false});
    setTimeout(() => {
      const left = document.querySelector('.menu-list .btn-tab.active').offsetLeft - 50;
      this.graphCardRef.current.scrollLeft = left;
    }, 100)
  }

  showGraphTypes() {
    const { settings } = this.props;
    const { isGraphSidebarOpen, graphSettings } = this.state;

    return (
      <div className={['graph-sidebar', isGraphSidebarOpen && 'open'].join(' ')}>
        <a className="close" onClick={() => this.setState({isGraphSidebarOpen: false})}><i className="icon-close"></i></a>
        <ul className="list-group">
          { settings && settings.length && settings.map((type, i) => 
            <a className={`list-group-item d-flex justify-content-between align-items-center${graphSettings && (graphSettings.GraphID === type.GraphID )? ` active`: ``}`} 
              key={i} onClick={() => this.onClickSideItem(i)}>{type.DisplayName}
            </a> )
          }
        </ul>
      </div>
    );
  }
  
  render() {
    const { historyReadings, settings, isCollapsed } = this.props;
    const { graphSettings, isMobile } = this.state;
    
    return (
      <div className="reading-card">
        <div className="card">
          <div className="card-header border-0" onClick={this.toggleCollapse}>
            <h5>Analytics</h5>
            { isMobile && <span className={['icon', isCollapsed ? 'open' : ''].join(' ')}><i className="icon-chevron-down"></i></span> }
          </div>
          <Collapse in={!isMobile || isCollapsed}>
          <div>
            <div className="card-body px-0 pt-0">
              { this.showGraphTypes() }
              <div className="d-flex px-3 mb-3">
                { settings && settings.length > 1 && <a className="menu d-flex align-items-center" onClick={() => this.setState({isGraphSidebarOpen: true})}><i className="icon-hamburger"></i></a>}
                <div className="menu-list" ref={this.graphCardRef}>
                  { settings && settings.length && settings.map((s, i) => (
                    <button className={['btn', 'btn-sm', 'btn-tab', graphSettings && graphSettings.DisplayName === s.DisplayName ? 'active' : ''].join(' ')} key={i}
                      onClick={() => this.setState({graphSettings: settings[i]})}>{s.DisplayName}</button>)
                  ) }
                </div>
              </div>
              { historyReadings && graphSettings ? <Graph settings={graphSettings} data={historyReadings} /> : <Loading height="5rem" showLogo="false" />}
            </div>
          </div>
          </Collapse>
        </div>
      </div>
    )
  }
}

export default GraphCard
