import React, { Component } from 'react';
import { ResponsiveContainer, AreaChart, XAxis, YAxis, Legend, Tooltip, ReferenceLine, Area } from 'recharts';
import moment from 'moment';

export class Graph extends Component {

  render() {
    const { data, settings } = this.props;

    const y1AxisValues = settings && settings['Y1-Axis-Values'] || [];
    const y2AxisValues = settings && settings['Y2-Axis-Values'] || [];
    const y1AxisColors = settings && settings['Y1-Axis-Colors'] || [];
    const y2AxisColors = settings && settings['Y2-Axis-Colors'] || [];
    const y1AxisLegends = settings && settings['Y1-Axis-Legends'] || [];
    const y2AxisLegends = settings && settings['Y2-Axis-Legends'] || [];
    const y1AxisThreshold = +settings['Y1-Axis-Threshold'] || undefined;
    const y2AxisThreshold = settings['Y2-Axis-Threshold'] || undefined;
    let graphData = data && data.filter(h => y1AxisValues.includes(h.DocumentName) || y2AxisValues.includes(h.DocumentName));

    graphData = graphData.reduce((acc, curr) => {
      const currPos = acc.findIndex(a => a.day === moment(curr.UpdatedTime.toDate()).format('DD MMM'));
      if (currPos > -1) acc[currPos][curr.DocumentName] = curr.ParamValue;
      else acc.push({ day: moment(curr.UpdatedTime.toDate()).format('DD MMM'), [`${curr.DocumentName}`]: curr.ParamValue });
      return acc;
    }, []);

    return (
      <ResponsiveContainer width="100%" height={250}>
        <AreaChart data={graphData} margin={{ top: 0, right: y2AxisValues.length ? 0 : 30, left: y1AxisValues.length ? 0 : 30, bottom: 20 }}>

          <defs>
            { y1AxisColors.map((color, i) => (<linearGradient id={`y1-${color}-${i}`} key={`y1Lg-${i}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.8}/>
              <stop offset="95%" stopColor={color} stopOpacity={0}/>
            </linearGradient>)
            ) }
            { y2AxisColors.map((color, i) => (<linearGradient id={`y2-${color}-${i}`} key={`y2Lg-${i}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color} stopOpacity={0.8}/>
              <stop offset="95%" stopColor={color} stopOpacity={0}/>
            </linearGradient>)
            ) }
          </defs>

          <XAxis dataKey="day" tickLine={false} tick={{ fontSize: 8, fill: '#1d3650' }} 
            stroke="#ebeef2" label={{ value: settings['X-Axis'], position: 'bottom', fontSize: 8, }} />

          { y1AxisValues.length && <YAxis tick={{ fontSize: 8, fill: '#1d3650' }} stroke="#ebeef2"
            domain={[+settings['Y1-Axis-Min'], +settings['Y1-Axis-Max']]} yAxisId="1"
            ticks={y1AxisThreshold ? [+settings['Y1-Axis-Min'], y1AxisThreshold, +settings['Y1-Axis-Max']] : [+settings['Y1-Axis-Min'], +settings['Y1-Axis-Max']] }
            label={{ value: settings['Y1-Axis'], position: 'center', fontSize: 8, angle: -90}} />}
          { y1AxisValues.length && y1AxisThreshold && <ReferenceLine yAxisId="1" y={y1AxisThreshold} stroke="green" strokeDasharray="3 3" /> }

          { y2AxisValues.length && <YAxis tick={{ fontSize: 8, fill: '#1d3650' }} stroke="#ebeef2" yAxisId="2"
              domain={[+settings['Y2-Axis-Min'], +settings['Y2-Axis-Max']]} orientation="right"
              ticks={y2AxisThreshold ? [+settings['Y2-Axis-Min'], y2AxisThreshold, +settings['Y2-Axis-Max']] : [+settings['Y2-Axis-Min'], +settings['Y2-Axis-Max']] }
              label={{ value: settings['Y2-Axis'], position: 'center', fontSize: 8, angle: -90}} />
          }
          { y2AxisValues.length && y2AxisThreshold && <ReferenceLine yAxisId="2" y={y2AxisThreshold} stroke="green" strokeDasharray="3 3" /> }
          
          { y1AxisValues.map((value, i) => (
            <Area name={y1AxisLegends[i]} yAxisId="1" type="monotone" dataKey={value} stroke={y1AxisColors[i]} fillOpacity={1} fill={`url(#y1-${y1AxisColors[i]}-${i})`} key={`y1Area-${i}`} />
          )) }
          { y2AxisValues.length && y2AxisValues.map((value, i) => (
            <Area name={y2AxisLegends[i]} yAxisId="2" type="monotone" dataKey={value} stroke={y2AxisColors[i]} fillOpacity={1} fill={`url(#y2-${y2AxisColors[i]}-${i})`} key={`y2Area-${i}`} />
          )) }

          <Tooltip />
          <Legend verticalAlign="top" align="right" iconType="circle" layout="horizontal" iconSize={10} margin={{top: 0, right: 20, left: 20, bottom: 0}} />

        </AreaChart>
      </ResponsiveContainer>
    )
  }
}

export default Graph
