import { Modal } from "react-bootstrap";
import styled from "styled-components";
import closeModla from "../../../../assets/images/modal_close.png";
import exportFile from "../../../../assets/images/export.svg";

export const Search = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0.4rem;
`;

export const SearchInput = styled.input`
  border: 0;
  outline: none;
  ::placeholder {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #b6b6b6;
  }
`;

export const Thead = styled.thead`

  th{
    background-color: rgba(85, 141, 202, 0.1);
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #434343;
  }
`;

export const ModalThead = styled.thead`
  tr {
    th {
      font-family: Inter;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #434343;
      background: rgba(85, 141, 202, 0.2);
      padding: 1rem;
    }
  }
`;
export const ModalTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #373737;
`;

export const ModalTBody = styled.tbody`
  tr,
  td {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #4f4f4f;
    padding: 1rem;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e8e8e8;
`;

export const Styles = styled.div`
  display: block;
  max-width: 100%;
  table {
    font-family: Inter;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    background-color: #ffffff;

    th,
    td {
      border-bottom: 1px solid #e8e8e8;
      font-size: 0.875rem;
      line-height: 1.0625rem;
    }
    th {
      text-transform: uppercase;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #2d2d2d;
      padding: 1rem 1.5rem;
    }
    td {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #4f4f4f;
      padding: 1.25rem;
      padding-left: 1rem;
    }
  }
`;

export const StylesGroup = styled.div`
  display: block;
  max-width: 100%;
  overflow : scroll;
  table {
    font-family: Inter;
    width: 100%;
    border-spacing: 0;
    border-collapse: separate;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    background-color: #ffffff;

    th,
    td {
      border-bottom: 1px solid #e8e8e8;
      font-size: 0.875rem;
      line-height: 1.0625rem;
    }
    th {
      text-transform: uppercase;
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #2d2d2d;
      padding: 1rem 1.5rem;
    }
    td {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #4f4f4f;
      /* padding: ; */
      /* padding-left: 1rem; */
    }
  }
`;

export const IconSearch = styled.i`
  color: #b6b6b6;
`;

export const Image = styled.div`
  background-image: url(${closeModla});
  width: 28px;
  height: 28px;
  position: absolute;
  right: -10px;
  top: -15px;
  border-radius: 20px;
  background-color: white;
`;

export const ExportFile = styled.button`
  background-image: url(${exportFile});
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  padding:16px;
  border-radius: 3px;
  border: 1px solid #E4E4E4;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.06);
`;

export const SubRowTRow = styled.tr`
  background: #558dca;
  opacity: 0.7;
  td {
    font-family: Inter;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #ffffff !important;
    padding: 12px 24px !important;
  }
`;

export const ChildTableStyle = styled.table`
  td {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #4f4f4f;
    padding: 10px !important;
  }
`;
export const DetailsModal = styled(Modal)`
  .modal-90w{
    max-width:80%;
    @media (max-width: 767px) {
      max-width: 100%
    }
  }
  .modal-body{
    max-height: 85vh
  }
  .modal-header{
    padding:1.5rem;
  }
  .modal-content{
     margin-top: 0;
   }
`;

// export const InfoModal = styled(Modal)`

//    .nish {
//     margin-top: 100px;
//     width: 30vw;    /* Occupy the 90% of the screen width */
//     max-width: 30vw;
//     height: 30vw;    /* Occupy the 90% of the screen width */
//     max-height: 30vw;
//    }
//    .modal-body{
//      max-height: 45vh
//    }
//    .modal-header{
//      padding:1.5rem;
//    }
// `;

export const InfoModal = styled(Modal)`
  .modal-90w{
    max-width:20%;
    @media (max-width: 767px) {
      max-width: 25%
    }
  }
  .modal-body{
    
    max-height: 45vh;
    overflow: auto;
  }
  .modal-header{
    padding:1.5rem;
  }
  
`;