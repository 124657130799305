import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import debounce from 'lodash/debounce';
import createHistory from "../../history";
import {
    Name,
    Container,
    ValueInput,
    DropDown,
} from "../Cards/DailyReadingCards/DailyCardsStyle";
import './DailyReadingUpdate.css';
import '../../App.css';

class UpdateScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            goback: false,
            show: false
        };
        this.onChange = debounce(this.onChangeValue.bind(this), 1);
        this.data = null;
        this.updatedData = [];
    }

    componentDidMount = () => {
        const { module, dailyReadingCards } = this.props;
        if (!module.moduleName) {
            createHistory.goBack()
        }
        else {
            this.setState({
                show: true
            })
        }
        if (dailyReadingCards) {
            dailyReadingCards.map(cards => {
                if (cards.Name === module.moduleName) {
                    this.cardName = cards.id
                }
            })
        }
    }

    onChangeValue = (e, item, paramValue, index) => {
        const { value } = e.target
        this.updateField(value, item, paramValue, index)
    };

    updateField = (value, item, paramValue, index) => {
        const findIndex = this.updatedData.findIndex((data) => data.item === item && data.index === index)
        if (findIndex === -1 || this.updatedData.length === 0) {
            this.updatedData.push({ value, item, paramValue, index })
        }
        else {
            this.updatedData[findIndex].value = value;
        }
    }

    saveChanges = async () => {
        this.setState({ saving: true });
        const { firestore, currentApartment, user } = this.props;
        createHistory.goBack();
        this.updatedData.map(val => {
            firestore
                .update(
                    {
                        collection: 'apartments',
                        doc: currentApartment,
                        subcollections: [
                            { collection: 'DailyReadings', doc: this.data[val.item][val.index].id }
                        ]
                    },
                    val.paramValue ? { ParamValue: val.value } :
                        { ParamValueRaw: val.value }
                );
        })

        firestore
            .update(
                {
                    collection: 'apartments',
                    doc: currentApartment,
                    subcollections: [
                        { collection: 'DailyReadingCards', doc: this.cardName }
                    ]
                },
                { LastUpdatedBy: user[0].DisplayName }
            );

        this.setState({ goback: true });
        setTimeout(() => {
        }, 400);
    }

    incrementButtons = (data, item, index, add, param) => {
        const { module } = this.props;
        const readingData = module.module;
        const incrementValue = readingData[item][index].incrementBy ? readingData[item][index].incrementBy : 1
        const value = param ? 'ParamValue' : 'ParamValueRaw';
        return (
            <button type="submit" className="buttonrmstyle"
                onClick={() => {
                    document.getElementById(data.id).value = (parseFloat(readingData[item][index][value], 10) + parseFloat(add ? incrementValue : -incrementValue)).toFixed(incrementValue >= 1 ? 0 : 2);
                    this.updateField((parseFloat(readingData[item][index][value], 10) + parseFloat(add ? incrementValue : -incrementValue)).toFixed(incrementValue >= 1 ? 0 : 2), item, param, index)
                    readingData[item][index][value] = (parseFloat(readingData[item][index][value], 10) + parseFloat(add ? incrementValue : -incrementValue)).toFixed(incrementValue >= 1 ? 0 : 2).toString();
                }}
                onMouseDown={() => {
                    this[`${data.id}${add ? 'time_plus' : 'time_minus'}`] = setTimeout(() => {
                        this[`${data.id}${add ? 'interval_plus' : 'interval_minus'}`] = setInterval(() => {
                            document.getElementById(data.id).value = (parseFloat(readingData[item][index][value], 10) + parseFloat(add ? incrementValue : -incrementValue)).toFixed(incrementValue >= 1 ? 0 : 2);
                            this.updateField((parseFloat(readingData[item][index][value], 10) + parseFloat(add ? incrementValue : -incrementValue)).toFixed(incrementValue >= 1 ? 0 : 2), item, param, index)
                            readingData[item][index][value] = (parseFloat(readingData[item][index][value], 10) + parseFloat(add ? incrementValue : -incrementValue)).toFixed(incrementValue >= 1 ? 0 : 2).toString();
                        }, 100)
                    }, 800);
                }}
                onMouseUp={() => {
                    if (this[`${data.id}${add ? 'time_plus' : 'time_minus'}`]) { clearInterval(this[`${data.id}${add ? 'time_plus' : 'time_minus'}`]) }
                    if (this[`${data.id}${add ? 'interval_plus' : 'interval_minus'}`]) { clearInterval(this[`${data.id}${add ? 'interval_plus' : 'interval_minus'}`]) }
                }}
            >
                <img alt="right" src={add ? require('../../assets/images/plus.png') : require('../../assets/images/minus.png')} style={{ width: 25, height: 25 }} />
            </button>
        )
    }

    render() {
        const { module } = this.props;
        const { saving, show } = this.state;
        const readingData = module.module;
        this.data = readingData;
        console.log('readingData', readingData)

        return (
            show ?
                (<div className="col-12 p-0" style={{ height: '100%' }}>
                    <div className="d-flex align-items-center nav_container" >
                        <button type="submit" className="buttonrmstyle" onClick={() => createHistory.goBack()}>
                            <img className="back-arrow" alt="back" src={require('../../assets/images/left-arrow.png')} />
                        </button>
                        <span className="heading">
                            {module.moduleName}
                        </span>

                    </div>
                    <div className="d-flex justify-content-center align-items-center flex-column mt-2 mb-5 p-0">
                        <Container className="col-11 mb-2 d-flex flex-wrap justify-content-center">
                            {readingData &&
                                Object.keys(readingData).map(item => (
                                    <div className="mb-5 d-flex flex-column align-items-center" key={Math.random()}>
                                        <div className="subheading">{item}</div>
                                        <div className="d-flex flex-wrap justify-content-center">
                                            {readingData[item].map((data, index) => {
                                                console.log('itemmm!@!@', data)
                                                return (
                                                    <div key={Math.random()} className="mr-3">
                                                        <Name className="mt-2">{data.ParamNameUpd}</Name>
                                                        <div className="d-flex align-items-center mt-2">                                                            
                                                            {data.updateWidget === "NUMERIC" && (
                                                                // <InputContainer>
                                                                <>
                                                                    {this.incrementButtons(data, item, index, false, true)}
                                                                    <input
                                                                        className="numeric_input"
                                                                        name={data.ParamNameUpd}
                                                                        defaultValue={data.ParamValue}
                                                                        disabled={!data.IsUpdatable}
                                                                        id={data.id}
                                                                        type="number"
                                                                        pattern="\d*"
                                                                        onKeyDown={(evt) => (evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault()}
                                                                        onChange={e => {
                                                                            readingData[item][index].ParamValue = e.target.value
                                                                            e.persist();
                                                                            this.onChange(e, item, true, index);
                                                                        }}
                                                                    />
                                                                    {this.incrementButtons(data, item, index, true, true)}
                                                                </>
                                                            )}

                                                            {data.updateWidget === "DROPDOWN" && (
                                                                <div>
                                                                    <DropDown disabled={!data.IsUpdatable} onChange={(e) => this.updateField(e.target.value, item, true, index)} defaultValue={data.ParamValue}>
                                                                        {data.ValueDictionary.map(value => (
                                                                            <option key={value} value={value} >{value}</option>
                                                                        ))}
                                                                    </DropDown>
                                                                    <img alt="down" src={require('../../assets/images/down.png')} style={{ width: 10, height: 10, marginLeft: -20, pointerEvents: 'none' }} />
                                                                </div>
                                                            )}

                                                            {data.updateWidget === "TEXT" && (
                                                                <ValueInput
                                                                    name={data.ParamNameUpd}
                                                                    defaultValue={data.ParamValue}
                                                                    disabled={!data.IsUpdatable}
                                                                    onChange={e => {
                                                                        e.persist();
                                                                        this.onChange(e, item, true, index);
                                                                    }}
                                                                />
                                                            )}
                                                            {data.updateWidget === "PERCENTAGE" && (
                                                                <>
                                                                    {this.incrementButtons(data, item, index, false, true)}
                                                                    <input
                                                                        className="numeric_input"
                                                                        id={data.id}
                                                                        min={0}
                                                                        max={100}
                                                                        name={data.ParamNameUpd}
                                                                        defaultValue={data.ParamValue}
                                                                        disabled={!data.IsUpdatable}
                                                                        onChange={e => {
                                                                            e.persist();
                                                                            const event = e;
                                                                            if (e.target.value > 100) { event.target.value = 100 }
                                                                            if (e.target.value < 0) { event.target.value = 0 }
                                                                            this.onChange(event, item, true, index);
                                                                        }}
                                                                    />
                                                                    {this.incrementButtons(data, item, index, true, true)}
                                                                </>
                                                            )}
                                                            {data.updateWidget === "NUMPERCENTAGE" && (
                                                                <>
                                                                    {this.incrementButtons(data, item, index, false, false)}
                                                                    <input
                                                                        className="numeric_input"
                                                                        id={data.id}
                                                                        name={data.ParamNameUpd}
                                                                        defaultValue={data.ParamValueRaw}
                                                                        disabled={!data.IsUpdatable}
                                                                        type="number"
                                                                        pattern="\d*"
                                                                        onKeyDown={(evt) => (evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault()}
                                                                        onChange={e => {
                                                                            e.persist();
                                                                            this.onChange(e, item, false, index);
                                                                        }}
                                                                    />
                                                                    {this.incrementButtons(data, item, index, true, false)}
                                                                </>
                                                            )}
                                                            {data.updateWidget === "READINGS" && (
                                                                <>
                                                                    {this.incrementButtons(data, item, index, false, false)}
                                                                    <input
                                                                        className="numeric_input"
                                                                        id={data.id}
                                                                        type="number"
                                                                        pattern="\d*"
                                                                        onKeyDown={(evt) => (evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault()}
                                                                        name={data.ParamNameUpd}
                                                                        defaultValue={data.ParamValueRaw}
                                                                        disabled={!data.IsUpdatable}
                                                                        onChange={e => {
                                                                            e.persist();
                                                                            this.onChange(e, item, false, index);
                                                                        }}
                                                                    />
                                                                    {this.incrementButtons(data, item, index, true, false)}
                                                                </>
                                                            )}

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                    </div>
                                ))}
                            <div className="d-flex justify-content-center mb-5" style={{ width: '100%' }}>
                                <button className="cancelButton" type="submit"
                                    onClick={() => {
                                        this.setState({ goback: true });                                        
                                        createHistory.goBack();                                        
                                    }}> Cancel</button>
                                <button className="saveButton" type="submit" onClick={this.saveChanges}> {!saving ? 'Save' : (
                                    <span
                                        className="spinner-border spinner-border-sm text-light"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}</button>
                            </div>
                        </Container>
                    </div>
                </div>)
                :
                <div />
        )
    }
}

const mapStateToProps = state => ({
    module: state.module,
    currentApartment: state.firestore.ordered.Users ? state.apartment.currentApartment : '',
    user: state.firestore.ordered.Users,
    dailyReadingCards: state.firestore.ordered.DailyReadingCards,
});

export default compose(
    connect(mapStateToProps),
    firestoreConnect()
)(UpdateScreen);