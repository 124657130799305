import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect, firestoreConnect } from "react-redux-firebase";
import ReCAPTCHA from "react-google-recaptcha";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  Button,
  Container,
  SubContainer,
  Links,
  InactiveWords,
  Error,
  Logo,
  LogoW,
} from "./SigninStyles";
import createHistory from "../../history";

const axios = require("axios");

class Appartment extends Component {
  state = {
    appartment: null,
    managedBy: null,
    consolidatedViewId: null,
    notRobot: false,
    appartmentIsEmpty: false,
    cordova: false,
  };

  componentDidMount() {
    const { firestore } = this.props;
    const apartmentCollection = {
      collection: "onboarding",
      storeAs: "Appartments",
    };
    apartmentCollection.where = ["IsActive", "==", true] ;
    if (this.unsubscribeAppartments) {
      this.unsubscribeAppartments();
    }
    this.unsubscribeAppartments = firestore.onSnapshot(apartmentCollection);
    if (window.hasOwnProperty("cordova")) {
      this.setState({ cordova: true });
    }
  }

  handleChange = (e) => {
    console.log(e[0]);
    this.setState({
      appartment: e[0] && e[0].Id,
      managedBy: e[0] && e[0].Managed_By,
      consolidatedViewId: e[0] && e[0].consolidatedViewId,
      appartmentIsEmpty: false,
    });
  };

  onChange = () => {
    this.setState({
      notRobot: true,
    });
  };

  navigate = () => {
    const { appartment, managedBy, consolidatedViewId } = this.state;
    const { setErrorMessage } = this.props;
    setErrorMessage(null, null);
    if (appartment || (appartment && appartment.length)) {
      createHistory.push("/signup2", {
        appartment,
        managedBy,
        consolidatedViewId,
      });
    } else {
      this.setState({
        appartmentIsEmpty: true,
      });
    }
  };

  writeUserData = (uid, username, mobile) => {
    const { appartment } = this.state;
    const { setErrorMessage } = this.props;

    axios
      .post(
        "https://asia-east2-trackbot-prod.cloudfunctions.net/assignUserToAptAfterSignUp",
        {
          uid: uid,
          aptname: appartment,
          displayName: username,
          phoneNo: mobile,
        }
      )
      .then((res) => {
        setErrorMessage(res.data, "Show Message");
      });
  };

  login = () => {
    const { setErrorMessage } = this.props;
    createHistory.push("/signin");
    setErrorMessage(null);
  };

  google = () => {
    const { firebase, setErrorMessage, clearFirestore } = this.props;
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const { user } = result;
        clearFirestore();
        firebase.logout();
        setErrorMessage("", "Show Message");
        this.writeUserData(user.uid, user.displayName, user.phoneNumber);
      });
  };

  render() {
    const { Appartments, error } = this.props;
    const { notRobot, appartmentIsEmpty, cordova } = this.state;
    this.appartment = [];
    if (Appartments) {
      Appartments.map((item) => {
        if (item.Name) {
          const obj = {};
          obj.Name = item.Name;
          obj.Id = item.id;
          obj.Managed_By = item.ManagedByIds;
          obj.consolidatedViewId = item.Consolidated_View_Id;
          this.appartment.push(obj);
        }
      });
    }

    return (
      <Container className="d-flex flex-column align-items-center justify-content-center">
        <SubContainer className="d-flex flex-column align-items-center">
          <Logo src={require("../../assets/images/logoOnly.png")} alt="logo" />
          <LogoW
            src={require("../../assets/images/logoWordmarkBlack.png")}
            alt="logo"
          />

          <div style={{ zIndex: 200, maxWidth: "70%" }}>
            <AsyncTypeahead
              isLoading={false}
              minLength={1}
              labelKey={(option) => option.Name}
              options={this.appartment}
              placeholder="Select Property"
              bsSize="large"
              style={{
                transform: "scaleX(0.97) scaleY(0.9)",
                border: appartmentIsEmpty && "1px solid red",
              }}
              onChange={this.handleChange}
              id={0}
              disabled={!Appartments}
              onSearch={() => {}}
            />
          </div>

          {!cordova && (
            <div style={{ zIndex: 100 }}>
              <ReCAPTCHA
                sitekey="6LcSR5sUAAAAANX5dJdgAxFWgcjH4p5pM92AFx1R"
                onChange={this.onChange}
                size="normal"
                style={{ transform: "scale(0.72)", marginTop: 0 }}
              />
            </div>
          )}

          {error && <Error className="pl-4 pr-4 pt-2">{error}</Error>}

          <Button
            disabled={!notRobot && !cordova}
            onClick={this.navigate}
            style={{ marginTop: 25 }}
          >
            <div
              style={{
                color: "white",
                fontWeight: "bold",
                fontSize: 14,
                fontFamily: "Nunito",
              }}
            >
              Sign up with Email
            </div>
          </Button>
          <div className="d-flex mt-4 jus">
            <InactiveWords>
              Already a User?<Links onClick={this.login}>Login Here</Links>
            </InactiveWords>
          </div>
        </SubContainer>
        <img
          src={require("../../assets/images/building.png")}
          className="col-12"
          alt="Logo"
          style={{ position: "absolute", bottom: 0, zIndex: 100 }}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  Appartments: state.firestore.ordered.Appartments,
  error: state.errorModule.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  clearFirestore: () => dispatch({ type: "@@reduxFirestore/CLEAR_DATA" }),
  setErrorMessage: (message, errorType) =>
    dispatch({ type: "ERROR_VALUE", message, errorType }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect(),
  firestoreConnect()
)(Appartment);
