/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable react/no-multi-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
import React from "react";
import { Modal, Button, Table, Dropdown, Spinner } from "react-bootstrap";
import * as _ from "lodash";
import Select from "react-select";
import { useTable, useGlobalFilter } from "react-table";

import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import {
  Header,
  Property,
  Styles,
  Title,
  ProperyCount,
  AddZone,
  ButtonText,
  Search,
  SearchInput,
  // ModalTitle,
  ModalLabel,
  ModalInput,
  ModalClose,
  ContinueContainer,
  Continue,
  customStyles,
} from "./styles";
import "./reactTags.css";
import { ModalThead, ModalTitle, ModalTBody, Divider, Image } from "../styles";

const Zones = ({
  zonesList = [],
  firestore,
  levelInfo,
  managersList,
  firebase,
  setManagers,
  ConsViews,
}) => {
  const [filterInput, setFilterInput] = React.useState("");
  const [data, setData] = React.useState([]);
  const [show, setShow] = React.useState(false);

  const [selectedZoneManager, setSelectedZoneManager] = React.useState([]);
  const [managers, setManager] = React.useState([]);
  const [zoneName, setZoneName] = React.useState("");
  const [selectedZoneName, setSelectedZoneName] = React.useState("");
  const [showProperties, setShowProperties] = React.useState(false);
  const [properties, setProperties] = React.useState([]);
  const [tag, setTag] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [consViewId, setConsViewId] = React.useState(null);
  const [zoneError, setZoneError] = React.useState(null);
  const [isAddingZone, setIsAddingZone] = React.useState(false);
  // console.log("managersList", managersList);
  const fetchManagers = () => {
    let users = [];

    if (!levelInfo) {
      return false;
    }

    firestore
      .get({
        collection: "Users",
        where: ["is_cons_user", "==", true],
        storeAs: "ManagersList",
      })
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          users = [...users, { id: doc.id, ...doc.data() }];
        });

        await Promise.all(
          (users || []).map(async (user) => {
            return firestore
              .get({
                collection: "Users",
                doc: user.id,
                subcollections: [
                  {
                    collection: "ConsViews",
                    where: [["ConsViewId", "==", levelInfo.ConsViewId]],
                  },
                ],
                storeAs: "ManagersListZone",
              })
              .then(async (doc) => {
                if (doc.empty) {
                  return false;
                }

                let managers = {};
                doc.forEach((doc) => {
                  managers = { UID: doc.id, ...doc.data() };
                });
                return { ...user, ...managers };
              });
          })
        )
          .then((prop) => {
            const filterEmptyManagerList = prop.filter((manager) => manager);
            setManagers(filterEmptyManagerList);
          })
          .catch(() => setIsLoading(false));
      });
  };

  React.useEffect(() => {
    fetchManagers();
  }, []);

  React.useEffect(() => {
    function fetchZoneManager() {
      if (!levelInfo) return false;
      const filteredRoleBasedOnMaxLevel = managersList.filter(
        ({ Level, Email }) => {
          return Level < levelInfo.MaxLevel && Email !== levelInfo.Email;
        }
      );

      const zoneManager = filteredRoleBasedOnMaxLevel.map(
        ({ Email, DisplayName }) => ({
          value: Email,
          label: `${DisplayName} (${Email})`,
        })
      );
      setManager(zoneManager);
    }
    fetchZoneManager();
  }, [levelInfo, managersList]);

  React.useEffect(() => {
    async function fetchZone() {
      let zoneLists = [];
      if (!zonesList.length || !levelInfo) {
        return false;
      }
      // console.log("levelInfo :", levelInfo);
      const viewID = levelInfo.ConsViewId;
      // const cons_id_match = levelInfo.ConsViewId.match(/^[^_]+(?=_)/g); // Get string before first underscore
      // if (cons_id_match) {
      //   const number = Number(cons_id_match);
      //   if (!isNaN(number)) {
      //     viewID = levelInfo.ConsViewId.replace(/^[^_]*_/, "");
      //   }
      // }
      // const ConsViewId = levelInfo
      //   ? levelInfo.ConsViewId.replace(/^[^_]*_/, "")
      //   : null;

      if (!viewID) return;
      setConsViewId(viewID);
      setIsLoading(true);
      const listZones = zonesList.filter((zone) => zone !== "All");
      firestore
        .get({
          collection: "onboarding",
          orderBy: `ManagedByIds.${viewID}`,
          storeAs: "PropertiesList_ED",
        })
        .then(async (querySnapshot) => {
          if (querySnapshot.empty) {
            // console.log("querySnapshot.empty");
            return;
          }

          querySnapshot.forEach((doc) => {
            zoneLists = [...zoneLists, { ...doc.data() }];
          });

          // console.log("zoneLists *****************************:", zoneLists);

          const final = listZones.map((zone) => {
            const zoneProperties = zoneLists.filter(({ ZoneMap }) => {
              return ZoneMap[viewID] === zone;
            });

            if (zoneProperties) {
              return {
                zone_name: zone,
                no_properties: zoneProperties,
                no_associated_users: managersList.filter(
                  ({ Zones }) => Zones.includes("All") || Zones.includes(zone)
                ),
              };
            }
          });

          setData(final);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));

      // ).then(async () => {
      // const groupedZone = _.chain(zoneLists)
      //   .groupBy("Zone")
      //   .map((item) => {
      //     return {
      //       zone_name: item[0].Zone,
      //       no_properties: item[0].ApartmentName ? item : [],
      //     };
      //   })
      //   .value();
      // const zones = groupedZone.map((item) => {
      //   return {
      //     ...item,
      //     no_associated_users: managersList.filter(({ Zones }) =>
      //       Zones.includes(item.zone_name)
      //     ),
      //   };
      // });
      // setData(zones);
      // setIsLoading(false);
      // });
      // await Promise.all(
      //   (listZones || []).map(async (zone) => {
      //     return firestore
      //       .get({
      //         collection: "consolidated",
      //         doc: viewID,
      //         subcollections: [
      //           {
      //             collection: "PropertiesList_ED",
      //             where: ["Zone", "==", zone],
      //           },
      //         ],
      //         storeAs: "PropertiesList_ED",
      //       })
      //       .then(async (querySnapshot) => {
      //         if (querySnapshot.empty) {
      //           zoneLists = [...zoneLists, { Zone: zone }];
      //         }
      //         querySnapshot.forEach((doc) => {
      //           zoneLists = [...zoneLists, { ...doc.data() }];
      //         });
      //       })
      //       .catch(() => setIsLoading(false));
      //   })
      // ).then(async () => {
      //   const groupedZone = _.chain(zoneLists)
      //     .groupBy("Zone")
      //     .map((item) => {
      //       return {
      //         zone_name: item[0].Zone,
      //         no_properties: item[0].ApartmentName ? item : [],
      //       };
      //     })
      //     .value();

      //   const zones = groupedZone.map((item) => {
      //     return {
      //       ...item,
      //       no_associated_users: managersList.filter(({ Zones }) =>
      //         Zones.includes(item.zone_name)
      //       ),
      //     };
      //   });
      //   setData(zones);
      //   setIsLoading(false);
      // });
    }
    fetchZone();
  }, [zonesList, managersList]);

  const handleClose = () => {
    setSelectedZoneManager([]);
    setZoneName("");
    setShow(false);
  };

  const addZone = () => {
    if (!zoneName) {
      setZoneError("Please enter a zone name");
      return false;
    }
    setIsAddingZone(true);
    const zonesRef = firestore.collection("consolidated").doc(consViewId);

    zonesRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          if (doc.data().Zones.includes(zoneName)) {
            setZoneError("Zone already exist !");
            return false;
          }
          zonesRef.update({
            Zones: firebase.firestore.FieldValue.arrayUnion(zoneName),
          });
          setShow(false);
        }
      })
      .catch((error) => {
        // console.log("Error getting document:", error);
        setIsAddingZone(false);
      });

    const usersEmail = selectedZoneManager.map(({ value }) => value);

    let users = [];
    const usersRef = firestore
      .collection("Users")
      .where("Email", "in", usersEmail);
    usersRef
      .get()
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          users = [...users, doc.data().UID];
        });

        await Promise.all(
          users.map(async (user) => {
            return firestore
              .get(
                {
                  collection: "Users",
                  doc: user,
                  subcollections: [
                    {
                      collection: "ConsViews",
                    },
                  ],
                  storeAs: "UsersZone",
                },
                { Zones: firebase.firestore.FieldValue.arrayUnion(zoneName) }
              )
              .then(async (querySnapshot) => {
                setIsAddingZone(false);
                querySnapshot.forEach((doc) => {
                  doc.ref.update({
                    Zones: firebase.firestore.FieldValue.arrayUnion(zoneName),
                  });
                });
                fetchManagers();
              })
              .catch(() => {
                setIsAddingZone(false);
                setShow(false);
              });
          })
        );
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });

    setTimeout(() => {
      setIsAddingZone(false);
    }, 1000);
    setShow(false);
  };

  const deleteZone = async (zone, consViewId) => {
    if (!consViewId) return;

    const zonesRef = firestore.collection("consolidated").doc(consViewId);
    zonesRef.update({
      Zones: firebase.firestore.FieldValue.arrayRemove(zone),
    });
  };
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value] = React.useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-group">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));
  const columns = React.useMemo(
    () => [
      {
        Header: "Zone Name",
        accessor: "zone_name", // accessor is the "key" in the data
      },
      {
        Header: "No of Properties",
        accessor: "no_properties",
        Cell: ({
          cell: {
            row: {
              original: { no_properties, zone_name },
            },
          },
        }) => {
          return (
            <Property>
              <ProperyCount
                onClick={() => {
                  if (!no_properties.length) return;
                  setProperties(no_properties);
                  setShowProperties(true);
                  setTag("no_properties");
                  setSelectedZoneName(zone_name)
                }}
              >
                {no_properties.length}
              </ProperyCount>
            </Property>
          );
        },
      },
      {
        Header: "No of Associated Users",
        accessor: "no_associated_users",
        Cell: ({
          cell: {
            row: {
              original: { no_associated_users, zone_name },
            },
          },
        }) => {
          return (
            <Property>
              <ProperyCount
                onClick={() => {
                  if (!no_associated_users.length) return;
                  setProperties(no_associated_users);
                  setShowProperties(true);
                  setTag("users");
                  setSelectedZoneName(zone_name)
                }}
              >
                {no_associated_users.length}
              </ProperyCount>
            </Property>
          );
        },
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({
          cell: {
            row: {
              original: { no_associated_users, no_properties, zone_name },
            },
          },
        }) => {
          // if (no_associated_users.length || no_properties.length) {
          //   return "";
          // }
          return (
            <Dropdown
              drop="left"
              title={
                no_associated_users.length || no_properties.length
                  ? "You can't delete zone"
                  : ""
              }
            >
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                ...
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item
                  disabled={no_associated_users.length || no_properties.length}
                  className="text-right"
                  onClick={() => deleteZone(zone_name, consViewId)}
                  eventKey="2"
                  title="Hello World!"
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    [consViewId]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter);

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value || "");
  };
  if (!data.length) {
    return <div className="text-secondary mt-5">Loading...</div>;
  }
  return (
    <>
      <Styles>
        <Header className="row">
          <Title className="col-md col-12 p-0">Zones List</Title>
          <div className="flex-md-row d-flex flex-column col-md-5 col-12">
            <Search className="mt-2 mt-md-0 flex-grow-1 mr-md-3">
              <SearchInput
                type="text"
                className="w-100 "
                value={filterInput}
                onChange={handleFilterChange}
                placeholder="Search"
              />
              <i className="icon-search" />
            </Search>
            <AddZone onClick={() => setShow(true)} className="mt-2 mt-md-0">
              <ButtonText>
                <i className="icon-plus" /> Add Zone
              </ButtonText>
            </AddZone>
          </div>
        </Header>
        <div className="zone-table">

          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} style={{ padding: 20 }}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Styles>
      <Modal
        show={show}
        onHide={handleClose}
        onShow={() => {
          setSelectedZoneManager([]);
          setZoneName("");
        }}
      >
        <Modal.Header>
          <ModalTitle>Add a Zone</ModalTitle>
          <ModalClose onClick={handleClose} className="rounded-circle">
            X
          </ModalClose>
        </Modal.Header>
        <Modal.Body>
          <>
            <h6 className="text-secondary">{zoneError}</h6>
            <ModalLabel>Enter Zone Name</ModalLabel>

            <ModalInput
              value={zoneName}
              onChange={(e) => setZoneName(e.target.value)}
              className="w-100 p-1 mt-1"
              type="text"
              placeholder="Zone Name"
            />
            <ModalLabel className="mt-4 mb-1">Choose Zone Manager</ModalLabel>
            <Select
              styles={customStyles}
              placeholder="Zone Manager"
              defaultValue={selectedZoneManager}
              onChange={setSelectedZoneManager}
              options={managers}
              isMulti
              closeMenuOnSelect={false}
            />
          </>
        </Modal.Body>
        <Modal.Footer>
          <ContinueContainer
            onClick={() => {
              if (!isAddingZone) {
                addZone();
              }
            }}
          >
            {isAddingZone ? (
              <Spinner animation="border" size="sm" />
            ) : (
                <Continue>Save</Continue>
              )}
          </ContinueContainer>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showProperties}
        size="lg"
        onHide={() => {
          setTag(null);
          setShowProperties(false);
        }}
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div className="row justify-content-between align-items-center mt-3 ml-3 mr-3">
          <ModalTitle>
            {tag !== "users" ? <><h6>Properties <span className='h6'>({selectedZoneName})</span></h6></> : <><h6>Associated Users ({selectedZoneName})</h6></>}
          </ModalTitle>
          <Image
            onClick={() => {
              setShowProperties(false);
            }}
          />
        </div>
        {/* <Divider className="divider mt-3 mb-2" /> */}
        <Modal.Body
          style={{ "max-height": "calc(100vh - 210px)", "overflow-y": "auto", "padding": "0px" }}
        >
          <>
            <Table>
              <ModalThead>
                {tag === "users" ? (
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone No</th>
                    <th>Role</th>
                  </tr>
                ) : (
                    <tr>
                      <th>Apartment Name</th>
                      {/* <th>Zone</th> */}
                      {/* <th>ZoneIndicator</th> */}
                      <th>Address</th>
                    </tr>
                  )}
              </ModalThead>
              <tbody>
                {tag === "users"
                  ? (properties || []).map((item, index) => {
                    const { DisplayName, PhoneNo, Email, Role } = item || {};
                    return (
                      <tr key={index}>
                        <td>{DisplayName}</td>
                        <td>{Email}</td>
                        <td>{PhoneNo}</td>
                        <td>{Role}</td>
                      </tr>
                    );
                  })
                  : (properties || []).map((item, index) => {
                    {/* console.log(item)
                        let Address1 = "";
                      let Address2 = "";
                      if (item.Address) {
                        Address1 = item.Address.Address1;
                        Address2 = item.Address.Address2;
                      } */}

                    return (
                      <tr key={index}>
                        <td>{item.Name}</td>
                        {/* <td>{item.ZoneMap[levelInfo.ConsViewId]}</td> */}
                        {/* <td>{item.ZoneIndicator}</td> */}
                        <td>{item.Addr_1} {item.Addr_2} {item.Addr_3}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  // Apartment List with managers
  setManagers: (manegers) =>
    dispatch({ type: "SET_MANAGERS_LIST", payload: manegers }),
});
export default compose(
  firestoreConnect(({ levelInfo }) => {
    if (!levelInfo) {
      return [];
    }
    // console.log(levelInfo.ConsViewId);
    const viewID = levelInfo.ConsViewId;
    // const cons_id_match = levelInfo.ConsViewId.match(/^[^_]+(?=_)/g); // Get string before first underscore
    // if (cons_id_match) {
    //   const number = Number(cons_id_match);
    //   if (!isNaN(number)) {
    //     viewID = levelInfo.ConsViewId.replace(/^[^_]*_/, "");
    //   }
    // }
    // console.log("viewId --", levelInfo.ConsViewId);
    // console.log("viewID --", viewID);
    if (!viewID) return [];
    return [
      {
        collection: "consolidated",
        doc: viewID,
        storeAs: "Consolidated",
      },
    ];
  }),
  connect(
    ({
      apartment,
      firestore: {
        ordered: { Consolidated, ConsViews },
      },
    }) => {
      return {
        zonesList: Consolidated ? Consolidated[0].Zones : [],
        managersList: apartment.managersList,
        ConsViews,
      };
    },
    mapDispatchToProps
  )
)(Zones);
