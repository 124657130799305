/* eslint-disable camelcase */
import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";

import Button, { ViewAll } from "../../Common/WidgetHeader/Button/Button";
import createHistory from "../../../../../history";
import WidgetHeader from "../../Common/WidgetHeader/WidgetHeader";
// import VBarChart from "../../Common/Charts/VBarChart/VBarChart";
import CommunityIncidents from "./Incidents/CommunityIncidents";
import ResidentsIncident from "./Incidents/ResidentsIncident";

const classNames = require("classnames");

const Incidents = (props) => {
  const {
    classValue,
    editMode,
    toggle,
    show = false,
    isLoading,
    order,
  } = props;
  const [key, setKey] = useState("system");
  const [isVisible, setIsVisible] = useState(show);
  React.useEffect(() => {
    toggle("Incidents", show);
  }, []);
  const handleChange = () => {
    setIsVisible(!isVisible);

    toggle("Incidents", !isVisible);
  };

  return (
    <>
      <div className={!editMode ? classValue : "edit-dashed"}>
        <div className={classNames("widget", { "full-h": !editMode })}>
          {editMode && (
            <span style={{ marginLeft: 12, marginTop: 5 }}>#{order}</span>
          )}
          <div className="widget-header-container d-flex flex-row justify-content-between">
            <WidgetHeader
              label="Incidents"
              description="Properties with ageing incidents"
              disabled={false}
            />
            {!editMode ? (
              <ViewAll onClick={() => createHistory.push(`/executive/widget/incident`)}>View All</ViewAll>
              
            ) : (
              <Switch
                checked={isVisible}
                color="primary"
                onChange={handleChange}
              />
            )}
          </div>
          {isLoading ? (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <div className="text-secondary ">Loading...</div>
            </div>
          ) : null}
          {!editMode && !isLoading ? (
            <div className={classNames({ disabled: editMode })}>
              <Tabs
                id="incidents-tab"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="system" title="Community Incidents">
                  <CommunityIncidents />
                </Tab>
                <Tab eventKey="residents" title="Residents Incidents">
                  <ResidentsIncident />
                </Tab>
              </Tabs>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default Incidents;
