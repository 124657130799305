import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import * as moment from 'moment';
import { Decimal } from 'decimal.js';

export class NumberInputWidget extends Component {
  state = { value: '', isNullValue: false };

  componentDidMount() {
    const { reading } = this.props;
    this.setState({value: reading.ParamValueRaw || reading.ParamValue});
  }
  
  componentDidUpdate(prevProps) {
    const { reading, edit } = this.props;
    const { reading: prevReading, edit: prevEdit } = prevProps;
    if ((reading && (reading.id !== prevReading.id)) || (edit && (prevEdit !== edit))) {
      this.setState({value: reading.ParamValueRaw || reading.ParamValue, isNullValue: false});
    }
  }

  convertToString = (val) => {
    const { reading } = this.props;
    if (val === null || val === undefined) return '';
    return parseFloat((+val).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: reading.DecimalPrecision || 1,
      style: 'decimal'
    }).replace(/,/g, ''));
  }
  
  increment = () => {
    const { reading, onChange, isButtonDisabled } = this.props
    const { value, isNullValue } = this.state;
    const max = reading.MaxValue ? reading.MaxValue : 999999;
    if(value < max){
      const num = new Decimal(value); const incrementor = new Decimal(reading.incrementBy || 1);
      const val = num.plus(incrementor).toString();
      this.setState({value: this.convertToString(val), isNullValue: false});
      isButtonDisabled(false, reading.id);
      onChange({ id: reading.id, [`${reading.hasOwnProperty('ParamValueRaw') ? `ParamValueRaw` : `ParamValue`}`]: this.convertToString(val) }, isNullValue);
    }
  }

  decrement = () => {
    const { reading, onChange, isButtonDisabled } = this.props
    const { value, isNullValue } = this.state;
    const min = reading.MinValue ? reading.MinValue : 0;
    
    if(value > min){
      const num = new Decimal(value); const incrementor = new Decimal(reading.incrementBy || 1);
      const val = num.sub(incrementor).toString();
      this.setState({value: this.convertToString(val), isNullValue: false});
      isButtonDisabled(false, reading.id);
      onChange({ id: reading.id, [`${reading.hasOwnProperty('ParamValueRaw') ? `ParamValueRaw` : `ParamValue`}`]: this.convertToString(val) }, isNullValue);
    }
  }

  onChange = (e) => {
    if(e.target.value.slice(-1) === '.' && (e.target.value.match(/\./g)).length > 1) {
      this.setState({value:e.target.value.substring(0, e.target.value.length - 1)})
    }
  }

  onKeyDown = (e) => {
    const { reading } = this.props;
    const min = reading.MinValue ? reading.MinValue : 0;
    const max = reading.MaxValue ? reading.MaxValue : 999999;
    const nextVal = e.target.value + e.key;

    let count = 0;
    if(nextVal && e.key === '.' ) count = (nextVal.match(/\./g)).length;
    if(count>1){
      e.preventDefault();
      return false;
    }
    
    if (nextVal > max || nextVal < min) {
      e.preventDefault();
      return false;
    } 
  }

  editValue = (val) => {
    const {reading, isButtonDisabled} = this.props;
    if ((val === null || val === '') && val !== 0) {
      this.setState({ isNullValue: true,value:'' })
      isButtonDisabled(true, reading.id);
    } else {
      this.setState({ isNullValue: false,value:val })
      isButtonDisabled(false, reading.id);
    }
  }

  onChangeVal = (val) => {
    const { reading, onChange } = this.props;
    const {isNullValue} = this.state;
    if (val !== null && val !== '') {
      this.setState({value: this.convertToString(val), isNullValue: false});
    }
    onChange({ id: reading.id, [`${reading.hasOwnProperty('ParamValueRaw') ? `ParamValueRaw` : `ParamValue`}`]: this.convertToString(val) }, isNullValue);
  }

  getLastUpdate = (value) => {
    let lastUpdateDate = new Date(value.toDate());
    const period = moment(new Date()).diff(moment(lastUpdateDate), 'months');
    if (period < 1) lastUpdateDate = moment(lastUpdateDate).fromNow();
    else lastUpdateDate = moment(lastUpdateDate).format('D MMM YYYY');
    return lastUpdateDate;
  }

  render() {
    const { reading, edit, isMobile, openHistory  } = this.props;
    const { value, isNullValue } = this.state;

    return (
      <p className="mb-2">
        <span className={['mr-0 badge-indicator', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')} />
        <div className="d-inline-block overflow-hidden ml-2 mr-1">
          <span title={!edit ? reading.ParamName : reading.ParamNameUpd} className="d-block overflow-text" style={{color:'black'}}>{ !edit ? reading.ParamName : reading.ParamNameUpd }</span>
          <span className="d-block" style={{fontSize: isMobile ? 9 : 11, marginTop: -4}}>{ this.getLastUpdate(reading.LastUpdatedDate) }</span>
        </div>
        { reading.HasLocalHistory && <a onClick={openHistory} className="text-muted"><i className="icon-history ml-1" /></a> }
        { !edit || reading.UpdateLocked ? 
          <span className={['float-right', 'ml-auto', 'text-indicator', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')}>
            { reading.ParamUnitLeftAlign && <span className="mr-1">{reading.ParamUnit}</span> } 
            { reading.ParamValue }
            { !reading.ParamUnitLeftAlign && <span className="ml-1">{reading.ParamUnit}</span> }
            { reading.UpdateLocked && <a className="text-muted"><i className="icon-lock ml-3" /></a> }
          </span> :
          <span className="ml-auto edit-body">
            <span className="helper" onClick={this.decrement}>-</span>
            <MaskedInput className={['text-indicator', 'border-indicatorr', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')}
              mask={s => Array.from(s).map(() => /(-)|[0-9]|(\.)/)} guide={false} inputMode="decimal" value={value} 
              onBlur={e => this.onChangeVal(e.target.value)} 
              onChange={this.onChange}
              size={(value.length > 6) ? (value.length + 1 > 15 ? 15 : value.length + 1) : 7} 
              onKeyDown={this.onKeyDown}
              onKeyUp={e=>this.editValue(e.target.value)}
              style={{borderBottomColor:isNullValue && 'red'}} />
            <span className="helper" onClick={this.increment}>+</span>
          </span> 
        }
      </p>
    )
  }
}

export default NumberInputWidget
