/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState } from "react";
// import * as _ from "lodash";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import Switch from "@material-ui/core/Switch";
import VennDiagram from "../../Common/Charts/VennDiagram/VennDiagram";
import Button from "../../Common/WidgetHeader/Button/Button";
import WidgetHeader from "../../Common/WidgetHeader/WidgetHeader";

import createHistory from "../../../../../history";

const classNames = require("classnames");

const AuditingStatus = (props) => {
  const {
    classValue,
    editMode,
    toggle,
    show = false,
    isLoading,
    order,
  } = props;
  const [isVisible, setIsVisible] = useState(show);
  React.useEffect(() => {
    toggle("Auditing Status", show);
  }, []);
  const handleChange = () => {
    setIsVisible(!isVisible);
    toggle("Auditing Status", !isVisible);
  };

  return (
    <>
      <div className={!editMode ? classValue : "edit-dashed"}>
        <div className={classNames("widget", { "full-h": !editMode })}>
          {editMode && (
            <span style={{ marginLeft: 12, marginTop: 5 }}>#{order}</span>
          )}
          <div className="widget-header-container d-flex flex-row justify-content-between">
            <WidgetHeader
              label="Auditing Status"
              description="Description to be defined"
              disabled={false}
            />
            {!editMode ? (
              <Button
                label="View All"
                click={() => {
                  if (!isLoading) {
                    createHistory.push(`/executive/widget/auditing`);
                  }
                }}
              />
            ) : (
              <Switch
                checked={isVisible}
                color="primary"
                onChange={handleChange}
              />
            )}
          </div>
          {isLoading ? (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <div className="text-secondary ">Loading...</div>
            </div>
          ) : null}
          {!editMode && !isLoading ? (
            <div className={classNames({ disabled: editMode })}>
              <VennDiagram />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default compose(
  connect(
    ({
      dashboard,
      firestore: {
        ordered: { consolidated, AuditingStatus_ED },
      },
    }) => ({
      consolidated,
      AuditingStatus_ED,
      selectedZone: dashboard.selectedZone,
    })
  ),
  firestoreConnect()
)(AuditingStatus);
