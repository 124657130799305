import React from 'react'

function Question2({ id, categories = [], className, onAnswer }) {
  return (
    <div id={id} data-key="TicketCategory" data-required="true" className={["p-3 h-100  d-flex flex-column justify-content-center q-block", className].join(' ')}>
      <p className="mb-3 question">Ticket Category ?</p>
      <div className="d-flex flex-wrap tf-radio-group">
        {
          categories.map(cat => (
            <div key={cat} className="tf-radio-btn">
              <input id={cat} type="radio" value={cat} name={id} onChange={(e) => onAnswer(e.target.value)} />
              <label htmlFor={cat}>{cat}</label>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Question2
