import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from "react-router-dom";
import createHistory from "../../history";
import './SystemStatusUpdate.css';
import lastUpdatedTime from '../common/Timeago';
import SystemUpdateModal from './SystemUpdateModal';
import SearchBarFilter from '../common/SearchBarFilter';
import Loading from "../common/Loading/Loading";
import '../../App.css';

class UpdateScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focusSearch: false,
            searchValue: ''
        }
    }

    componentDidMount = () => {
        const { systemUpdateModule } = this.props;
        if (!systemUpdateModule.name || systemUpdateModule.name === 'NA') {
            createHistory.replace('/system')
        }
    }

    getIndicator = (color) => {
        switch (color) {
            case "Green":
                return <div className="mr-2 sysgreen" />;
            case "Red":
                return <div className="mr-2 sysred" />;
            case "Amber":
                return <div className="mr-2 sysamber" />;
            default:
                break;
        }
    };

    onTextChange = (e) => {
        this.setState({ searchValue: e.target.value })
    }

    filterData = () => {
        const { searchValue } = this.state;
        const fileList = this.SystemEquipmentDetails.filter((file) => {
            if (file.EquipmentName !== null) {
                if (file.EquipmentName.toLowerCase().includes(searchValue.toLowerCase())) {
                    return file;
                }
            }
        });
        this.SystemEquipmentDetails = fileList;
    }


    render() {
        const { SystemEquipmentDetails, systemUpdateModule, showModal } = this.props;
        const { searchValue, focusSearch } = this.state;
        if (systemUpdateModule.name === 'NA') {
            return <Loading showLogo="false" />
        }
        this.components = [];
        if (SystemEquipmentDetails) {
            this.SystemEquipmentDetails = [...SystemEquipmentDetails];
            this.filterData()
            this.SystemEquipmentDetails.forEach(item => {
                if (item.ServiceName === systemUpdateModule.name) {
                    const index = this.components.findIndex((a) => a === item.ComponentName)
                    if (index === -1) { this.components.push(item.ComponentName) }
                }
            })
        }
        return (
            <div style={{ width: '100%' }}>
                <SystemUpdateModal />
                <div className="d-flex flex-column col-12 align-items-center p-0">
                    <div className="d-flex align-items-center nav_container" >
                        <button type="submit" className="buttonrmstyle" onClick={() => createHistory.goBack()}>
                            <img className="back-arrow" alt="back" src={require('../../assets/images/left-arrow.png')} />
                        </button>
                        <span className="heading">
                            {systemUpdateModule.name}
                        </span>
                    </div>
                    <div className="col-11">
                        <SearchBarFilter
                            focusSearch={focusSearch}
                            onTextChange={this.onTextChange}
                            onFocus={() => this.setState({ focusSearch: true })}
                            onBlur={() => this.setState({ focusSearch: false })}
                            nameSort={searchValue}
                            placeholder="Enter Equipment Name"
                        />
                    </div>
                    <div className='d-flex col-11 flex-wrap mb-5 p-0'>
                        {this.components.length !== 0 && this.components.map(item => (
                            <div className="ComponentCard">
                                <div className="ComponentHead">{item}</div>
                                <div className="d-flex flex-wrap">
                                    {this.SystemEquipmentDetails.map(data => (
                                        item === data.ComponentName && (
                                            <div className="mr-5 p-2 pt-4">
                                                <div className="EquipmentItem">{data.EquipmentName}</div>
                                                <div className="d-flex justify-content-center ">
                                                    {this.getIndicator(data.Indicator)}
                                                    <div className="EquipmentStatus mt-1">{data.EquipmentStatus}</div>
                                                    <button type='submit' className="pencilButton" onClick={() => showModal(systemUpdateModule.name, data.EquipmentName, data.EquipmentStatus, data.id)}>
                                                        <img className="pencil" alt="back" src={require('../../assets/images/pencil.png')} />
                                                    </button>
                                                </div>
                                                <div className="LastUpdatedDate mt-2">{lastUpdatedTime(data.LastUpdatedDate)}</div>
                                            </div>
                                        )
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    SystemEquipmentDetails: state.firestore.ordered.SystemEquipmentDetails,
    systemUpdateModule: state.systemUpdateModule,
});

const mapDispatchToProps = dispatch => ({
    showModal: (moduleName, equipment, condition, document) => dispatch({
        type: "SHOW_MODAL",
        moduleName,
        equipment,
        condition,
        document
    })
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(), withRouter
)(UpdateScreen);