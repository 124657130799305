import React, { Component } from 'react'
import moment from 'moment';

export class DropdownInputWidget extends Component {
  state = { value: '' };

  componentDidMount() {
    const { reading } = this.props;
    this.setState({value: reading.ParamValueRaw || reading.ParamValue});
  }
  
  
  componentDidUpdate(prevProps) {
    const { reading, edit } = this.props;
    const { reading: prevReading, edit: prevEdit } = prevProps;
    if ((reading && (reading.id !== prevReading.id)) || (edit && (prevEdit !== edit))) {
      this.setState({value: reading.ParamValueRaw || reading.ParamValue});
    }
  }

  onChangeVal = (val) => {
    const { reading, onChange } = this.props
    this.setState({value: val});
    onChange({ id: reading.id, [`${reading.hasOwnProperty('ParamValueRaw') ? `ParamValueRaw` : `ParamValue`}`]: val });
  }

  getLastUpdate = (value) => {
    let lastUpdateDate = new Date(value.toDate());
    const period = moment(new Date()).diff(moment(lastUpdateDate), 'months');
    if (period < 1) lastUpdateDate = moment(lastUpdateDate).fromNow();
    else lastUpdateDate = moment(lastUpdateDate).format('D MMM YYYY');
    return lastUpdateDate;
  }

  render() {
    const { reading, edit, isMobile, openHistory } = this.props;
    const { value } = this.state;
    
    return (
      <p className="mb-2">
        <span className={['badge-indicator mr-0', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')}></span>
        <div className="d-inline-block overflow-hidden ml-2 mr-1">
          <span title={!edit ? reading.ParamName : reading.ParamNameUpd} className="d-block overflow-text" style={{color:'black'}}>{ !edit ? reading.ParamName : reading.ParamNameUpd }</span>
          <span className="d-block" style={{fontSize: isMobile ? 9 : 11, marginTop: -4}}>{ this.getLastUpdate(reading.LastUpdatedDate) }</span>
        </div>
        { reading.HasLocalHistory && <a onClick={openHistory} className="text-muted"><i className="icon-history ml-1" /></a> }
        { !edit || reading.UpdateLocked ? 
          <span className={['float-right', 'ml-auto', 'text-indicator', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')}>
            {reading.ParamValue}
            { reading.UpdateLocked && <a className="text-muted"><i className="icon-lock ml-3"></i></a> }
          </span> :
          <select className={['edit-body', 'ml-auto', 'text-indicator', 'border-indicatorr', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')} 
            onChange={e => this.onChangeVal(e.target.value)} defaultValue={value}>
            { reading.ValueDictionary.map(v => (
              <option value={v} key={v}>{v}</option>
            )) }
          </select>
        }
        
      </p>
    )
  }
}

export default DropdownInputWidget
