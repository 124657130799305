import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import store from './redux/store'
import * as serviceWorker from "./serviceWorker";

const startApp = () => {
    ReactDOM.render(<App />, document.getElementById("root"));
    }

if(!window.cordova) {
    startApp()
  } else {
    document.addEventListener('deviceready', ()=>{
      if(window.cordova)
      { 
      window.StatusBar.hide()
      startApp();
      }
    }, false)
  }
window.store = store;
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
