import React from "react";

export const BackButton = () => {
  return (
    <button
      style={{ background: "none", border: "none" }}
      onClick={() => window.history.back()}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 23 23"
        fill="none"
      >
        <path
          d="M20.6667 11.3334H2"
          stroke="#3A3A3A"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3333 20.6667L2 11.3333L11.3333 2"
          stroke="#3A3A3A"
          strokeWidth="3"
          strokeLinejoin="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export const ExportButton = () => {
  return (
    <button className="btn-white">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9.75 15.75H19.6275L17.6925 17.6925L18.75 18.75L22.5 15L18.75 11.25L17.6925 12.3075L19.6275 14.25H9.75V15.75Z"
          fill="#BEBEBE"
        />
        <path
          d="M16.5 10.5V7.50001C16.5006 7.40131 16.4817 7.30346 16.4443 7.21208C16.407 7.1207 16.352 7.03759 16.2825 6.96751L11.0325 1.71751C10.9624 1.648 10.8793 1.59301 10.7879 1.55568C10.6966 1.51836 10.5987 1.49944 10.5 1.50001H3C2.60218 1.50001 2.22064 1.65805 1.93934 1.93935C1.65804 2.22066 1.5 2.60219 1.5 3.00001V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H15C15.3978 22.5 15.7794 22.342 16.0607 22.0607C16.342 21.7794 16.5 21.3978 16.5 21V19.5H15V21H3V3.00001H9V7.50001C9 7.89784 9.15804 8.27937 9.43934 8.56067C9.72064 8.84198 10.1022 9.00001 10.5 9.00001H15V10.5H16.5ZM10.5 7.50001V3.30751L14.6925 7.50001H10.5Z"
          fill="#BEBEBE"
        />
      </svg>
    </button>
  );
};

export const FilterButton = () => {
  return (
    <button className="ml-4 btn-white">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M10.5 18H7.5C7.10218 18 6.72064 17.842 6.43934 17.5607C6.15804 17.2794 6 16.8978 6 16.5V10.8075L0.4425 5.25C0.160809 4.96999 0.00167459 4.58968 0 4.1925V1.5C0 1.10218 0.158035 0.720644 0.43934 0.43934C0.720644 0.158035 1.10218 0 1.5 0H16.5C16.8978 0 17.2794 0.158035 17.5607 0.43934C17.842 0.720644 18 1.10218 18 1.5V4.1925C17.9983 4.58968 17.8392 4.96999 17.5575 5.25L12 10.8075V16.5C12 16.8978 11.842 17.2794 11.5607 17.5607C11.2794 17.842 10.8978 18 10.5 18ZM1.5 1.5V4.1925L7.5 10.1925V16.5H10.5V10.1925L16.5 4.1925V1.5H1.5Z"
          fill="#BEBEBE"
        />
      </svg>
    </button>
  );
};
