/* eslint-disable react/no-this-in-sfc */
import React, { useRef, useEffect, useState } from 'react';
import reactScroll from 'react-scroll';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';

import Question1 from './Question1';
import Question2 from './Question2';
import DynamicQuestion from './DynamicQuestion';
import RequestorName from './RequestorName';
import IssueDescription from './IssueDescription';
import RequestorPhoneNo from './RequestorPhoneNo';
import TicketTitle from './TicketTitle'; 
import TicketFileUpload from './TicketFileUpload';

import './TypeForm.scss';

const scroller = reactScroll.scroller;

const TypeForm = (props) => {

  const containerRef = useRef();

  const buildingMapDetail = useRef();
  const ticketCategory = useRef();

  const [buildingMap, setBuildingMap] = useState({});
  const [answers, setAnswers] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({})

  const fetchBuildingData = () => {
    const { firestore, user, buildingMapDetails, currentApartment } = props;
    
		// if (!buildingMapDetails) {
      if(buildingMapDetail && buildingMapDetail.current){
        buildingMapDetail.current()
      }
			buildingMapDetail.current = firestore.onSnapshot({
				collection: 'apartments',
				doc: currentApartment,
				subcollections: [{
					collection: 'BuildingMapDetails'
				}],
				storeAs: `buildingMapDetails`
			});
		// }
  }

  const fetchBuildingMap = async () => {
    const { firestore, user, currentApartment } = props;
    
    // if(!props.buildingMap) {
      await firestore.get({
        collection: 'apartments',
        doc: currentApartment,
        storeAs: `buildingMap`
      });
    // }
    fetchBuildingData();
  }

  const fetchTicketCategory = async () => {
    const { firestore, user, ticketCategories, currentApartment } = props;
    
    // if(!ticketCategories) {
      
      // console.log('ticketCategory :',this.ticketCategory)
      if(ticketCategory && ticketCategory.current){
        ticketCategory.current()
      }
      ticketCategory.current = firestore.onSnapshot({
        collection: 'apartments',
        doc: currentApartment,
        subcollections: [{
          collection: 'ResidentServices',
          where: ['Scope', '==', 'Trackbot App'],
        }],
        storeAs: `ticketCategories`
      });
    // }
  }

  useEffect(() => {
    fetchBuildingMap()
    fetchTicketCategory()
  }, []);

  useEffect(() => {
    if(props.buildingMap) {
      const bMap = {};
      const BuildingMap = props.buildingMap[0].BuildingMap;
      for (let index = 0; index < BuildingMap.length; index++) {
        const b = BuildingMap[index];
        const key = Object.keys(b)[0];
        bMap[key] = {
          question: b[key],
          filters: BuildingMap.slice(0, index).map(dd => Object.keys(dd)[0])
        }
      }
      setBuildingMap(bMap);
    }
  }, [props.buildingMap]);

 
  const back = () => {
    const nodes = Array.from(containerRef.current.childNodes);
    const nodeIndex = nodes.findIndex(node => node.classList.contains('active'));

    if (nodeIndex > -1 && nodeIndex - 1 >= 0) {
      const prevNode = nodes[nodeIndex - 1];
      nodes[nodeIndex].classList.remove('active')
      prevNode.classList.add('active');

      nodes[nodeIndex].style.transform = 'translateY(100vh)';
      nodes[nodeIndex].style.opacity = 0;

      prevNode.style.transform = 'translateY(0)';
      prevNode.style.opacity = 1;


      // const id = String(prevNode.id);
      // goto(id);
    }
  }

  const next = (buttonClick = false) => {
    const nodes = Array.from(containerRef.current.childNodes);
    const nodeIndex = nodes.findIndex(node => node.classList.contains('active'));

    const key = nodes[nodeIndex].dataset.key;
    if(nodes[nodeIndex].dataset.required === 'true' && !answers[key] && buttonClick) {
      return
    }

    if (nodeIndex > -1 && nodeIndex + 1 < nodes.length) {
      const nextNode = nodes[nodeIndex + 1];
      nodes[nodeIndex].classList.remove('active')
      nextNode.classList.add('active');

      // const id = String(nextNode.id);
      // const input = document.querySelector(`#${id} .tf-input`)
      // console.log('##########################333', input);
      // if(input) {
      //   // input.setAttribute('autofocus' , 'true');
      //   input.focus();
      // }

      nodes[nodeIndex].style.transform = 'translateY(-100vh)';
      nodes[nodeIndex].style.opacity = 0;

      nextNode.style.transform = 'translateY(0)';
      nextNode.style.opacity = 1;

   
      // goto(id);
    }
  }

  const onAnswer = (name, value, input=false) => {
    setAnswers({ ...answers, [name]: value })
    if(!input)next();
  }

  const goto = (questionId) => {
    scroller.scrollTo(questionId, {
      duration: 500,
      delay: 100,
      smooth: true,
      containerId: 'scroll-container',
    })
  }

  const getFilteredBuildingData = (filter) => {
    if(props.buildingMapDetails){
      return props.buildingMapDetails.filter(d => filter.every(f => d[f] === answers[f]))
    }
    return [];
  }
  
  const saveHandler = async () => {
    const { firestore, firebase, user, close, offlineTicketFiles, cacheTicketFiles, currentApartment } = props;
    const { DisplayName } = user[0];
    const Files = answers.Files;
    delete answers.Files;
    const data = {
      ...answers,
      TicketCategory: answers.TicketCategory,
      Title: answers.Title,
      Source: 'Trackbot App',
      CreationDate: firestore.Timestamp.fromDate(new Date()),
			CreatedBy: DisplayName,
      Status: 'New',
      Priority: 'Low',
      IncidentType: answers.IncidentType,
      HouseNo: answers.UnitNo ? answers.UnitNo : '',
      LastUpdatedDate: firestore.Timestamp.fromDate(new Date()),
    }
    if(answers.Description) data.Description = answers.Description;
    if(answers.ResidentName) data.ResidentName = answers.ResidentName;
    if(answers.ResidentPhoneNo) data.ResidentPhoneNo = answers.ResidentPhoneNo;


    const ticketId = new Date().getTime().toString();
    close();
    await firestore
    .collection("apartments")
    .doc(currentApartment)
    .collection('IncidentReports')
    .doc(ticketId)
    .set(data);

    if(Files && Files.length) {
      const filePath = `${currentApartment}/IncidentReports/${ticketId}`;

      const uploadedFiles = Array.from(Files).map(file => ({
        File: file,
        FileName: file.name,
        UploadedDate: new Date(),
        FilePath: filePath,
        UploadedBy: DisplayName
      }))

      if(window.navigator.onLine) {
        await firebase.uploadFiles(filePath, Files);

        uploadedFiles.map(file => {

          delete file.File;

          firestore
          .collection("apartments")
          .doc(currentApartment)
          .collection('IncidentReports')
          .doc(ticketId)
          .collection('Files')
          .add(file)
        })
      }else {
        // files storeed in cache if offline
        const ticketFiles =  {
          ticketId,
          Files: uploadedFiles
        }
        cacheTicketFiles([...offlineTicketFiles, ticketFiles])
      }
    }
  }


  const categories = props.ticketCategories && props.ticketCategories
  .filter(c => c.IncidentType === answers.IncidentType)
  .map(cat => cat.TicketCategory)
  console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', selectedCategory);
  return (
    <div className="typeform-container container">
      <div ref={containerRef} id="scroll-container" className="typeform-body">
        <Question1 id="question-1" className="active" next={next} 
          onAnswer={(value) => onAnswer('IncidentType', value)}
        />
        <Question2 id="question-2" next={next} categories={categories} 
          onAnswer={(value) => {
            setSelectedCategory(props.ticketCategories.find(c => c.TicketCategory === value));
            setTimeout(()=>{
              onAnswer('TicketCategory', value);
            }, 0)
          }}
        />
        {
          selectedCategory.AskHouseNo && Object.keys(buildingMap).map((key, i) => (
            <DynamicQuestion
              key={i + key}
              question={buildingMap[key].question}
              buildingData={getFilteredBuildingData(buildingMap[key].filters)}
              onAnswer={(value) => onAnswer(key, value)}
              answer={answers[key]}
              questionNo={i+3}
              id={key}
            />
          ))
        }
        {
          selectedCategory.AskTitle &&
          <TicketTitle
            questionNo={Object.keys(buildingMap).length + 3}
            id="question-3"
            isRequired={selectedCategory.ReqTitle}
            onAnswer={(value) => onAnswer('Title', value, true)}
            next={next}
          />
        }
        {
          selectedCategory.AskDesc &&
          <IssueDescription questionNo={Object.keys(buildingMap).length + 4} 
            id="question-4" 
            onAnswer={(value) => onAnswer('Description', value, true)}
            next={next}
          />
        }
        {
          selectedCategory.AskReqName &&
          <RequestorName
            questionNo={Object.keys(buildingMap).length + 5}
            id="question-5"
            onAnswer={(value) => onAnswer('ResidentName', value, true)}
            isRequired={selectedCategory.ReqReqName}
            next={next}
          />
        }
        {
          selectedCategory.AskReqPhone &&
          <RequestorPhoneNo
            questionNo={Object.keys(buildingMap).length + 6}
            id="question-6" onAnswer={(value) => onAnswer('ResidentPhoneNo', value, true)}
            next={next}
            isRequired={selectedCategory.ReqReqPhone}
            
          />
        }
        <TicketFileUpload 
          questionNo={Object.keys(buildingMap).length + 7}
          id="question-7"
          onAnswer={(files) => onAnswer('Files', files, true)}
          onSave={saveHandler}
        />

      </div>
      <div className="typeform-footer d-flex p-3">
        <div className="ml-auto">
          <button 
            className="btn btn-sm btn-light rounded-0 mr-3 rotate"
            onClick={back}
          >
            <i className="icon-chevron-down" style={{ fontSize: '.65rem'}} />
          </button>
          <button 
            className="btn btn-sm btn-light rounded-0"
            onClick={()=>next(true)}
          >
            <i className="icon-chevron-down" style={{ fontSize: '.65rem'}} />
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
	user: state.firestore.ordered.Users && state.firestore.ordered.Users,
  buildingMap: state.firestore.ordered.buildingMap,
  buildingMapDetails: state.firestore.ordered.buildingMapDetails,
  ticketCategories: state.firestore.ordered.ticketCategories,
  offlineTicketFiles: state.updateModule.offlineTicketFiles,
  currentApartment: state.apartment.currentApartment,
})

const mapDispatchToProps = dispatch => ({
	cacheTicketFiles: (files) => dispatch({ type: 'ADD_OFFLINE_TICKET_FILES', payload: files })
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect(),
	firebaseConnect(),
)(TypeForm);
