import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "./SystemStatusUpdate.css";
import CustomDateView from "../Cards/StaffActivities/CustomDateView";
import lastUpdatedTime from "../common/Timeago";

const moment = require("moment");

class UpdateModal extends Component {
  constructor() {
    super();
    this.state = {
      saving: false,
      selected: null,
      serviceDate: null,
      otherService: "",
      serviceUnits: "",
    };
    this.dropdownValues = [];
  }

  updateEquipmentStatus = () => {
    this.setState({ saving: true });
    const { firestore, currentApartment, modal, hideModal, user } = this.props;
    const { selected } = this.state;
    if (selected) {
      firestore.update(
        {
          collection: "apartments",
          doc: currentApartment,
          subcollections: [
            { collection: "SystemEquipmentDetails", doc: modal.document },
          ],
        },
        { EquipmentStatus: selected, LastUpdatedBy: user[0].DisplayName }
      );
    }
    this.setState({ saving: false });
    hideModal();
  };

  updateServiceStatus = () => {
    this.setState({ saving: true });
    const {
      firestore,
      currentApartment,
      serviceData,
      hideModal,
      user,
    } = this.props;
    const { serviceUnits, serviceDate, otherService } = this.state;
    this.error = false;
    if (serviceData.IntervalType === "DATE-UNITS") {
      if (!serviceUnits) {
        this.setState({ serviceUnitsError: true });
        this.error = true;
      }
      if (!serviceDate) {
        this.setState({ serviceDateError: true });
        this.error = true;
      }
      if (
        !serviceData.CurrentServicedUnits ||
        serviceUnits <= parseInt(serviceData.CurrentServicedUnits, 10)
      ) {
        this.error = true;
        this.unitError = true;
        this.setState({ serviceUnitsError: true });
      }
    }
    if (serviceData.IntervalType === "DATE") {
      if (!serviceDate) {
        this.setState({ serviceDateError: true });
        this.error = true;
      }
    }
    if (serviceData.IntervalType === "UNITS") {
      if (!serviceUnits) {
        this.setState({ serviceUnitsError: true });
        this.error = true;
      }
      if (
        !serviceData.CurrentServicedUnits ||
        serviceUnits <= parseInt(serviceData.CurrentServicedUnits, 10)
      ) {
        this.error = true;
        this.unitError = true;
        this.setState({ serviceUnitsError: true });
      }
    }

    if (!this.error) {
      firestore.update(
        {
          collection: "apartments",
          doc: currentApartment,
          subcollections: [
            { collection: "ServiceIntervalDetails", doc: serviceData.id },
          ],
        },
        {
          CurrentServiceNotes: otherService,
          CurrentServicedDate: serviceDate,
          CurrentServicedUnits: serviceUnits,
          LastUpdatedBy: user[0].DisplayName,
          LastUpdatedTime: new Date(),
        }
      );
    }
    this.setState({ saving: false });
    if (!this.error) {
      this.setState({
        serviceDate: null,
        otherService: "",
        serviceUnits: "",
      });
      hideModal();
    }
  };

  OnServiceDayChange = (day) => {
    this.setState({ serviceDate: day });
  };

  handleChange = (e) => {
    this.unitError = false;
    this.setState({
      [e.target.name]: e.target.value,
      serviceUnitsError: false,
      serviceDateError: false,
    });
  };

  fetchHistory = () => {
    const { firestore, currentApartment, serviceData } = this.props;
    this.loadCompleted = false;
    this.setState({});
    setTimeout(() => {
      if (this.unsubscribeServiceIntervalHistory) {
        this.unsubscribeServiceIntervalHistory();
      }
      this.unsubscribeServiceIntervalHistory = firestore.onSnapshot({
        collection: "apartments",
        doc: currentApartment,
        subcollections: [
          {
            collection: "ServiceIntervalDetails",
            doc: serviceData.id,
            subcollections: [{ collection: "ServiceIntervalHistory" }],
          },
        ],
        storeAs: "ServiceIntervalHistory",
      });
      this.loadCompleted = true;
    }, 1);
  };

  toggleHistory = () => {
    if (document.getElementById("history").classList.contains("expand")) {
      document.getElementById("history").classList.remove("expand");
    } else {
      this.fetchHistory();
      document.getElementById("history").classList.add("expand");
    }
    this.setState({});
  };

  getDate = (data) => {
    const { firestore } = this.props;
    return new firestore.Timestamp(data.seconds, data.nanoseconds).toDate();
  };

  render() {
    const {
      saving,
      serviceDate,
      otherService,
      serviceUnits,
      serviceUnitsError,
      serviceDateError,
    } = this.state;
    const {
      modal,
      hideModal,
      SystemDetails,
      doubleSide,
      ServiceIntervalHistory,
      serviceData,
    } = this.props;
    if (SystemDetails) {
      SystemDetails.forEach((item) => {
        if (item.Name === modal.moduleName) {
          this.dropdownValues = item.ValueDictionary;
        }
      });
    }
    let currServiceDate;
    if (serviceData) {
      currServiceDate = new Date(serviceData.CurrentServicedDate.toDate());
    }

    if (doubleSide && serviceData)
      return (
        <Modal
          show={modal.show}
          onHide={hideModal}
          id="SystemServies"
          size="lg"
        >
          <div>
            <div className="d-flex col flex-wrap p-0">
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5 modalFirstHalf">
                <div className="modalHeader mt-3 mb-3">
                  New Maintainence Details
                </div>
                <div className="pr-4 rightBorder">
                  <div className="LastUpdatedDate mt-2">Service</div>
                  <div className="EquipmentStatus">
                    {serviceData.ServiceTitle}
                  </div>

                  <div className="LastUpdatedDate mt-2">
                    Other Services (If Any)
                  </div>
                  <div className="serviceEdit">
                    <input
                      className="buttonrmstyle pl-2"
                      name="otherService"
                      value={otherService}
                      onChange={this.handleChange}
                    />
                  </div>

                  {(serviceData.IntervalType === "DATE-UNITS" ||
                    serviceData.IntervalType === "DATE") && (
                    <div className="serviceEdit">
                      <div className="LastUpdatedDate mt-2 d-flex align-items-center">
                        New Serviced Date
                      </div>
                      <div
                        id="Service"
                        className="d-flex align-items-center justify-content-between"
                      >
                        <DatePicker
                          name="serviceDate"
                          className="buttonrmstyle EquipmentStatus"
                          selected={serviceDate && serviceDate}
                          minDate={currServiceDate.setDate(
                            currServiceDate.getDate() + 1
                          )}
                          onChange={this.OnServiceDayChange}
                          style={{ fontSize: 10 }}
                          dateFormat="d MMMM, yyyy"
                          showYearDropdown
                          customInput={
                            <CustomDateView small monthPicker={false} />
                          }
                        />
                        <img
                          src={require("../../assets/images/calendar.png")}
                          alt="cal"
                          style={{
                            height: 14,
                            width: 14,
                            zIndex: 100,
                            opacity: 0.9,
                          }}
                        />
                      </div>
                      {serviceDateError && (
                        <div
                          className="float-right"
                          style={{
                            color: "red",
                            width: 0,
                            height: 0,
                            marginTop: -20,
                            marginRight: -10,
                          }}
                        >
                          *
                        </div>
                      )}
                    </div>
                  )}
                  {(serviceData.IntervalType === "DATE-UNITS" ||
                    serviceData.IntervalType === "UNITS") && (
                    <div>
                      <div className="LastUpdatedDate mt-2">
                        New Serviced Unit Reading
                      </div>
                      <div className="d-flex align-content-center">
                        <div className="serviceEdit">
                          <input
                            className="buttonrmstyle EquipmentStatus pl-2"
                            type="number"
                            pattern="\d*"
                            name="serviceUnits"
                            value={serviceUnits}
                            onChange={this.handleChange}
                          />
                        </div>
                        {serviceUnitsError && (
                          <div
                            className="float-right"
                            style={{
                              color: "red",
                              width: 0,
                              height: 0,
                              marginLeft: 10,
                            }}
                          >
                            *
                          </div>
                        )}
                      </div>
                      {serviceData.CurrentServicedUnits && this.unitError && (
                        <div style={{ color: "red", fontSize: 9 }}>
                          {" "}
                          value must be greater than{" "}
                          {serviceData.CurrentServicedUnits}{" "}
                        </div>
                      )}
                    </div>
                  )}

                  <div
                    className="d-flex justify-content-center mb-3"
                    style={{ marginLeft: 10, marginTop: 28 }}
                  >
                    <button
                      type="submit"
                      className="cancelButton"
                      onClick={hideModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="saveButton"
                      onClick={this.updateServiceStatus}
                    >
                      {!saving ? (
                        "Save"
                      ) : (
                        <span
                          className="spinner-border spinner-border-sm text-light"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 12 col-md-7 col-lg-7 col-xl-7 modalSecondHalf mb-3">
                <div className="modalHeader mt-3 mb-3">
                  Device Service Details
                </div>
                <div className="col d-flex flex-wrap p-0">
                  <div className="col-sm-12 col-md-7 p-0 pr-4">
                    <div className="LastUpdatedDate mt-2">Last Serviced</div>
                    <div className="EquipmentStatus">
                      {serviceData.LastServiced}
                    </div>
                    <div className="LastUpdatedDate mt-2">Upcoming Service</div>
                    <div className="EquipmentStatus">
                      {serviceData.UpcomingServiceOn}
                    </div>
                    <div className="LastUpdatedDate mt-2">
                      Service Intervals
                    </div>
                    <div className="EquipmentStatus">
                      {serviceData.ServiceIntervals}
                    </div>
                    <div className="LastUpdatedDate mt-2">
                      Service Contact No
                    </div>
                    <div className="EquipmentStatus">
                      {serviceData.ServiceContactName}
                    </div>
                    <div className="EquipmentStatus">
                      {serviceData.ServiceContactNo}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5 p-0">
                    <div className="LastUpdatedDate mt-2">Device Name</div>
                    <div className="EquipmentStatus">
                      {serviceData.EquipmentName}
                    </div>
                    <div className="LastUpdatedDate mt-2">Type</div>
                    <div className="EquipmentStatus">
                      {serviceData.EquipmentType}
                    </div>
                    <div className="LastUpdatedDate mt-2">Commissioned On</div>
                    <div className="EquipmentStatus">
                      {serviceData.CommissionedOn}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <button
                  type="button"
                  className="buttonrmstyle p-0 historyButton"
                  onClick={this.toggleHistory}
                >
                  {document.getElementById("history") &&
                  document
                    .getElementById("history")
                    .classList.contains("expand")
                    ? "Hide History"
                    : "View History"}
                </button>
              </div>
              <div className="col-12" id="history">
                {!ServiceIntervalHistory || !this.loadCompleted ? (
                  <div className="d-flex col-12 align-items-center justify-content-center">
                    <span
                      className="spinner-border spinner-border-sm text-dark"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  ServiceIntervalHistory.map((data, index) => (
                    <div
                      className="d-flex flex-column comments"
                      style={{
                        borderBottom:
                          index !== ServiceIntervalHistory.length - 1
                            ? "2px solid #D5D5D5"
                            : 0,
                      }}
                    >
                      <div className="d-flex flex-wrap">
                        <div className="commentHeading mr-3">
                          {data.UpdatedBy}
                        </div>
                        <div className="d-flex flex-wrap">
                          <div className="commentHeading mr-2">
                            ({lastUpdatedTime(data.ServicedDate)})
                          </div>
                        </div>
                      </div>
                      <div className="AdTaskCommonStyle mt-2">
                        {data.ServiceNotes}
                      </div>
                    </div>
                  ))
                )}
                {ServiceIntervalHistory &&
                  ServiceIntervalHistory.length === 0 &&
                  this.loadCompleted && (
                    <div className="AdTaskCommonStyle mt-2">
                      No history found.
                    </div>
                  )}
              </div>
            </div>
          </div>
        </Modal>
      );
    return (
      <Modal
        show={modal.show}
        onHide={hideModal}
        id="SystemStatus"
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <div className="modalHeader">Select Device Status</div>
        <div className="modalSubhead">{modal.equipment}</div>
        <div>
          <select
            className="modalDropdown"
            onChange={(e) => this.setState({ selected: e.target.value })}
            defaultValue={modal.condition}
          >
            {this.dropdownValues.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
          <img
            alt="down"
            src={require("../../assets/images/down.png")}
            style={{
              width: 10,
              height: 10,
              marginLeft: -20,
              pointerEvents: "none",
            }}
          />
        </div>
        <div className="d-flex" style={{ marginLeft: 10, marginTop: 28 }}>
          <button type="submit" className="cancelButton" onClick={hideModal}>
            Cancel
          </button>
          <button
            type="submit"
            className="saveButton"
            onClick={this.updateEquipmentStatus}
          >
            {!saving ? (
              "Save"
            ) : (
              <span
                className="spinner-border spinner-border-sm text-light"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  currentApartment:
    state.firestore.ordered.Users && state.apartment.currentApartment,
  SystemDetails: state.firestore.ordered.SystemDetails,
  ServiceIntervalHistory: state.firestore.ordered.ServiceIntervalHistory,
  ServiceIntervalDetails: state.firestore.ordered.ServiceIntervalDetails,
  modal: state.modal,
  user: state.firestore.ordered.Users,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () =>
    dispatch({
      type: "HIDE_MODAL",
    }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect()
)(UpdateModal);
