/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import "./Settings.css";
import UserListCard from "../Cards/UserListCard/UserListCard";
import StaffListCard from "../Cards/StaffListCard/StaffListCard";
import Loading from "../common/Loading/Loading";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      activeSystem: "user-profiles",
      photoURL: require("../../assets/images/default_dp.svg"),
    };
    this.componentNameRef = React.createRef();
    this.subMenuRef = React.createRef();
    this.subMenuIconRef = React.createRef();
  }

  componentDidMount = () => {
    const { user, currentApartment } = this.props;
    const { PhotoURL } = user[0];
    const width = this.getWidth();
    this.setState({ isMobile: width < 768 });
    window.addEventListener("resize", this.reportWindowSize);
    document.addEventListener("click", this.handleClickOutside);
    if (user.length !== 0 && currentApartment) {
      this.fetchData(currentApartment);
    }

    if (user && PhotoURL) {
      this.setPhotoURL(PhotoURL);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { user, currentApartment } = this.props;
    if (user.length == 0) return;

    if (currentApartment !== prevProps.currentApartment) {
      this.fetchData(currentApartment);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.reportWindowSize);
    document.removeEventListener("click", this.handleClickOutside);
  }

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  reportWindowSize = (e) => {
    const { isMobile } = this.state;
    if (isMobile) {
      if (e.target.innerWidth > 768) {
        this.setState({ isMobile: false });
      }
    } else if (e.target.innerWidth < 769) {
      this.setState({ isMobile: true });
    }
  };

  handleClickOutside = (event) => {
    const { isModuleSidebarOpen, isMobile } = this.state;
    if (
      isMobile &&
      this.subMenuRef.current &&
      !this.subMenuRef.current.contains(event.target) &&
      !this.subMenuIconRef.current.contains(event.target) &&
      isModuleSidebarOpen
    ) {
      this.setState({ isModuleSidebarOpen: false });
    }
  };

  setPhotoURL = async (path) => {
    const { firebase } = this.props;
    const storage = firebase.storage();
    const url = await storage.ref().child(path).getDownloadURL();
    this.setState({ photoURL: url });
  };

  fetchData = async (currentApartment) => {
    const { firestore, ApartmentAccessInfo, user } = this.props;
    
    let accessInfo = null;
        if(ApartmentAccessInfo.length){
          const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
         if(selectedApartment){
           accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
         }
        }
        const { Roles, is_mc_member, is_manager } = accessInfo || {}

    // const { is_manager } = userAccessInfo && userAccessInfo[0];
    const subCollection = { collection: "Roles" };
    if (is_manager) subCollection.where = ["IsMCRole", "==", false];

    await firestore.get({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [subCollection],
      storeAs: "AllRoles",
    });
    if (this.unsubscribeAllUsers) {
      this.unsubscribeAllUsers();
    }
    this.unsubscribeAllUsers = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "Users" }],
      storeAs: "AllUsers",
    });
    if (this.unsubscribeAllStaffs) {
      this.unsubscribeAllStaffs();
    }
    this.unsubscribeAllStaffs = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "AttendanceMaster" }],
      storeAs: "AllStaffs",
    });
    await firestore.get({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [
        { collection: "StaffActivitiesCard", doc: "001_Attendance" },
      ],
      storeAs: "ServiceAreas",
    });
  };

  onUpdateUser = (Id, data) => {
    const { user, firestore, currentApartment } = this.props;
    const { DisplayName } = user[0];

    firestore.update(
      {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [{ collection: "Users", doc: Id }],
      },
      { ...data, is_approved: true, LastUpdatedBy: DisplayName }
    );
  };

  onUpdateStaff = (Id, data) => {
    const { user, firestore, currentApartment } = this.props;
    const { DisplayName } = user[0];

    firestore.update(
      {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [{ collection: "AttendanceMaster", doc: Id }],
      },
      { ...data, IsUserReady: true, LastUpdatedBy: DisplayName }
    );
  };

  onDeleteUser = (Id) => {
    const { user, firestore, currentApartment } = this.props;
    const { DisplayName } = user[0];

    firestore.update(
      {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [{ collection: "Users", doc: Id }],
      },
      { is_deleted: true, is_approved: false, LastUpdatedBy: DisplayName }
    );
  };

  onDeleteStaff = (Id) => {
    const { user, firestore, currentApartment } = this.props;
    const { DisplayName } = user[0];

    firestore.update(
      {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [{ collection: "AttendanceMaster", doc: Id }],
      },
      { is_deleted: true, IsUserReady: false, LastUpdatedBy: DisplayName }
    );
  };

  onSelectPhoto = async (file, Id) => {
    const { firestore, firebase, currentApartment } = this.props;
    const filesPath = `${currentApartment}/Staffs/${Id}`;
    await firebase.uploadFile(filesPath, file);
    firestore
      .update(
        {
          collection: "apartments",
          doc: currentApartment,
          subcollections: [{ collection: "AttendanceMaster", doc: Id }],
        },
        { PhotoURL: `${filesPath}/${file.name}` }
      )
      .then(() => {
        this.setState({});
      });
  };

  getPhotoURL = async (path) => {
    const { firebase } = this.props;
    const storage = firebase.storage();
    if (path) {
      return storage.ref().child(path).getDownloadURL();
    }
  };

  onClickSideItem = (profile) => {
    this.setState({
      activeSystem: profile,
      ModuleName: "",
      isModuleSidebarOpen: false,
    });
    setTimeout(() => {
      const left =
        document.querySelector(
          ".profile-types .list-group .list-group-item.active"
        ).offsetLeft - 50;
      this.componentNameRef.current.scrollLeft = left;
    }, 0);
  };

  showComponentSideBar() {
    const { isModuleSidebarOpen, activeSystem } = this.state;

    return (
      <div
        className={["component-sidebar", isModuleSidebarOpen && "open"].join(
          " "
        )}
        ref={this.subMenuRef}
      >
        <a
          className="close"
          onClick={() => this.setState({ isModuleSidebarOpen: false })}
        >
          <i className="icon-close" />
        </a>
        <ul className="list-group">
          <a
            className={`list-group-item d-flex justify-content-between align-items-center${
              activeSystem === "user-profiles" ? ` active` : ``
            }`}
            onClick={() => this.onClickSideItem("user-profiles")}
          >
            Trackbot Users
          </a>
          <a
            className={`list-group-item d-flex justify-content-between align-items-center${
              activeSystem === "staff-profiles" ? ` active` : ``
            }`}
            onClick={() => this.onClickSideItem("staff-profiles")}
          >
            Attendance Profiles
          </a>
        </ul>
      </div>
    );
  }

  showSideNav = () => {
    setTimeout(() => this.props.openSideBar(false));
  };

  getThemeColor = () => {
    const { AppartmentDetails } = this.props;
    const { isMobile } = this.state;
    if (isMobile && AppartmentDetails[0].Header_Theme_Color) {
      return AppartmentDetails[0].Header_Theme_Color;
    }
    return null;
  };

  render() {
    const {
      allUsers,
      allRoles,
      userAccessInfo,
      allStaffs,
      serviceAreas,
      ApartmentAccessInfo,
      currentApartment,
      user
    } = this.props;

    let accessInfo = null;
    if(ApartmentAccessInfo.length){
      const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
      if(selectedApartment){
        accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
      }
    }
    const { is_mc_member, is_manager } = accessInfo || {}
    const { isMobile, activeSystem } = this.state;
    // const { is_mc_member, is_manager } = userAccessInfo && userAccessInfo[0];
    const activeStaffs = (allStaffs || []).filter((s) => !s.is_deleted);
    // console.log((allUsers || []).filter((user) => !user.is_cons_user));
    return (
      <div id="settings">
        {this.showComponentSideBar()}
        <div
          className="settings-header d-flex justify-content-between align-items-center"
          style={{ backgroundColor: this.getThemeColor() }}
        >
          <div className={`${isMobile ? "d-flex flex-column" : ""}`}>
            <h4 className="order-2">Settings</h4>
          </div>
          <div className="d-flex" />
        </div>
        <div className="row settings-body">
          <div
            className="col-md-3 profile-types d-flex d-md-block"
            style={{ backgroundColor: this.getThemeColor() }}
          >
            {isMobile && (
              <a
                className="menu d-flex align-items-center"
                onClick={() => this.setState({ isModuleSidebarOpen: true })}
                ref={this.subMenuIconRef}
              >
                <i className="icon-hamburger" />
              </a>
            )}
            <ul className="list-group" ref={this.componentNameRef}>
              <a
                className={`list-group-item d-flex justify-content-between align-items-center${
                  activeSystem === "user-profiles" ? ` active` : ``
                }`}
                onClick={() =>
                  this.setState({
                    activeSystem: "user-profiles",
                    ModuleName: "",
                  })
                }
              >
                Trackbot Users
              </a>
              <a
                className={`list-group-item d-flex justify-content-between align-items-center${
                  activeSystem === "staff-profiles" ? ` active` : ``
                }`}
                onClick={() =>
                  this.setState({
                    activeSystem: "staff-profiles",
                    ModuleName: "",
                  })
                }
              >
                Attendance Profiles
              </a>
            </ul>
          </div>

          <div className="col" style={{ marginTop: isMobile && "5rem" }}>
            {!allUsers || !allUsers.length ? (
              <Loading showLogo="false" />
            ) : (
              allUsers &&
              activeSystem === "user-profiles" && (
                <UserListCard
                  users={allUsers}
                  roles={allRoles}
                  isMobile={isMobile}
                  isMcMember={is_mc_member}
                  isManager={is_manager}
                  onUpdateUser={this.onUpdateUser}
                  onDelete={this.onDeleteUser}
                  getPhotoURL={this.getPhotoURL}
                />
              )
            )}

            {activeStaffs && serviceAreas ? (
              activeStaffs.length && activeSystem === "staff-profiles" ? (
                <StaffListCard
                  staffs={activeStaffs}
                  isMobile={isMobile}
                  services={serviceAreas[0].ServiceSubAreas}
                  isMcMember={is_mc_member}
                  isManager={is_manager}
                  onUpdateStaff={this.onUpdateStaff}
                  onDelete={this.onDeleteStaff}
                  onSelectPhoto={this.onSelectPhoto}
                  getPhotoURL={this.getPhotoURL}
                />
              ) : (
                activeSystem === "staff-profiles" && (
                  <p className="text-center">No attendance profiles found</p>
                )
              )
            ) : (
              <Loading showLogo="false" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Settings.defaultProps = {
  user: [{ Email: "" }],
};

const mapDispatchToProps = (dispatch) => ({
  openSideBar: (value) => dispatch({ type: "SIDEBAR_OPEN", payload: value }),
});

const mapStateToProps = (state) => ({
  user: state.firestore.ordered.Users,
  allUsers: state.firestore.ordered.AllUsers
    ? state.firestore.ordered.AllUsers.filter((user) => !user.is_cons_user)
    : [],
  allRoles: state.firestore.ordered.AllRoles,
  // userAccessInfo: state.firestore.ordered.UserAccessInfo,
  allStaffs: state.firestore.ordered.AllStaffs,
  serviceAreas: state.firestore.ordered.ServiceAreas,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  currentApartment: state.apartment.currentApartment,
  ApartmentAccessInfo: state.apartment.ApartmentAccessInfo
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect()
)(Settings);
