import React from 'react'
import './WidgetHeader.css'

const classNames = require('classnames')
const WidgetHeader = ({label, description, disabled}) => {
    return (
        <div className="widget-header">
            <div className={classNames('widget-label', {'disabled': disabled})}>{label}</div>
            <div className="widget-description">{description}</div>            
        </div>
    )
}

export default WidgetHeader;