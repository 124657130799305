import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Loading from '../../common/Loading/Loading';
import * as moment from 'moment';
import * as _ from 'lodash';

export default class ServiceIntervalList extends Component {
  state = {
    showDetails: false,
    sysHistoryLoading: false,
    interval: null
  };

  onHide = () => {
    this.setState({showDetails: false});
  }

  onClickRow = async (interval) => {
    const { getInteravalHistory } = this.props;
    this.setState({showDetails: true, sysHistoryLoading: true, intervalId: interval.id});
    await getInteravalHistory(interval.id);
    this.setState({sysHistoryLoading: false});
  }

  render() {
    const { 
      serviceIntervals, onRenew, onUpdate, onCancel,
      contractIntervalHistory, contractIntervalComments, contractIntervalFiles 
    } = this.props;
    const { showDetails, sysHistoryLoading, intervalId } = this.state;
    const interval = serviceIntervals.find(i => i.id === intervalId);
    

    return (
      <>
      { !showDetails ?
      <ListContracts serviceIntervals={serviceIntervals} onClickRow={this.onClickRow} /> :
      <DetailsView {...{interval, contractIntervalHistory, contractIntervalComments, contractIntervalFiles, sysHistoryLoading}}
        clickBack={() => this.setState({showDetails: false, intervalId: null})} {...{onRenew, onUpdate, onCancel}} />
      }
      </>
    )
  }
}

export const ListContracts = (props) => {
  const { serviceIntervals, onClickRow, Category } = props;
  let serviceIntervalsGrouped = _.groupBy(serviceIntervals, s => s.ServiceName);
  if (Category === 'Contracts') {
    serviceIntervalsGrouped = _.groupBy(serviceIntervals, s => s.ContractType);
  }
  const componentNames = Object.keys(serviceIntervalsGrouped).map(c => c);

  return (
    <>
    { componentNames.map(ServiceName => (
      <div className="tabular-interval" key={`tabular-key-${ServiceName}`} >
        <h6>{ServiceName}</h6>
        <table className="table">
          <thead>
            <tr>
              <th width="35%"></th>
              <th width={ Category !== 'Intervals' ? '15%' : '22%' }>{ Category !== 'Intervals' ? 'Vendor' : 'Interval' }</th>
              <th width={ Category !== 'Intervals' ? '15%' : '22%' }>Validity Ends</th>
              <th width="15%">{ Category !== 'Intervals' && 'Contract Terms' }</th>
              <th width="15%"></th>
            </tr>
          </thead>
          <tbody>
            { serviceIntervalsGrouped[ServiceName].map(interval => (
              <tr className="interval-row" key={`table-row-${interval.id}`} role="presentation" onClick={() => onClickRow(interval)}>
                <td className={`border-indicator ${interval.Indicator && interval.Indicator.toLowerCase()}`}>{interval.ServiceTitle}</td>
                <td data-header={ Category !== 'Intervals' ? 'Vendor' : 'Interval' }>
                  { Category !== 'Intervals' ? interval.Vendor : interval.ServiceInterval }
                </td>
                <td data-header="Validity Ends">{moment(interval.UpComingServiceDate.toDate()).startOf('day').fromNow()}</td>
                { Category !== 'Intervals' && <td data-header="Contract Terms">{ interval.ServiceIntervalType }</td> }
                { Category === 'Intervals' && <td data-header=""></td> }
                <td className="font-weight-bold">
                  <span className={`badge badge-pill badge-indicator text-white ${interval.Indicator && interval.Indicator.toLowerCase()}`}>{interval.Status}</span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ))}
    </>
  )
}

export const DetailsView = (props) => {
  const { 
    contractIntervalHistory, contractIntervalComments, contractIntervalFiles, getPhotoURL, Category,
    sysHistoryLoading, interval, clickBack, onRenew, onUpdate, onCancel, onComment, isMobile
  } = props;
  const [historyLength, sethistoryLength] = useState(3);
  const [commentText, setText] = useState('');
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const getFileUrls = async () => {
      const fileWithUrls = await Promise.all((contractIntervalFiles || []).map(async f => {
        const url = await getPhotoURL(f.FilePath);
        return {
          id: f.id,
          src: url,
          name: f.DisplayFileName,
        }
      }));
      setFiles(fileWithUrls);
    }

    if(contractIntervalFiles && contractIntervalFiles.length){
      getFileUrls();
    }
  }, [contractIntervalFiles]);

  useEffect(() => {}, [interval])

  const submitComment = (e) => {
    e.preventDefault();
    onComment(interval.id, commentText);
    setText('');
  }

  return (
    <Card className="border-0 interval-detail">
      { isMobile && <Card.Header>
        <span className={`ml-1 badge text-white badge-indicator ${interval.Indicator && interval.Indicator.toLowerCase()}`}>
          {interval.Status}
        </span>
        {interval.Status !== 'Cancelled' && <button className="btn btn-sm btn-outline-secondary mr-2 ml-auto" onClick={() => onUpdate(interval.id)}>Update</button>}
        {interval.Status !== 'Cancelled' && Category !== 'Intervals' && <button className="btn btn-sm btn-success mr-2" onClick={() => onRenew(interval.id)}>Renew</button>}
        {interval.Status !== 'Cancelled' && <button className="btn btn-sm btn-outline-danger mr-2" onClick={() => onCancel(interval.id)}>Terminate</button>}
      </Card.Header> }
      { interval && <Card.Body>
        <div className="mb-md-3 mb-2 d-flex align-items-center">
          <button className="btn btn-sm btn-light btn-back mr-2" onClick={clickBack}><i className="icon-arrow-left"></i></button>
          <h6 className="font-weight-bold mr-1">{interval.ServiceTitle}</h6>
          { !isMobile && <span className={`ml-1 badge badge-pill text-white badge-indicator ${interval.Indicator && interval.Indicator.toLowerCase()}`}>
            {interval.Status}
          </span>}
          {interval.Status !== 'Cancelled' && !isMobile &&
            <button className="btn btn-sm btn-outline-secondary mr-2 ml-auto" onClick={() => onUpdate(interval.id)}>Update</button>}
          {interval.Status !== 'Cancelled' && Category !== 'Intervals' && !isMobile && 
            <button className="btn btn-sm btn-success mr-2" onClick={() => onRenew(interval.id)}>Renew</button>}
          {interval.Status !== 'Cancelled' && Category !== 'Intervals' && !isMobile &&
            <button className="btn btn-sm btn-outline-danger mr-2" onClick={() => onCancel(interval.id)}>Terminate</button>}
        </div>
        <hr/>
        <div className="row">
          <div className="col-md-7 border-right">
            <div className="row mb-1">
              { Category !== 'Intervals' ? <div className="form-group col-6">
                <label className="col-form-label">Vendor</label>
                <p className="mb-0">{interval.Vendor}</p>
              </div> : <div className="form-group col-6">
                <label className="col-form-label">Interval</label>
                <p className="mb-0">{interval.ServiceInterval}</p>
              </div> }
              <div className="form-group col-6">
                <label className="col-form-label">System</label>
                <p className="mb-0">{interval.ServiceName}</p>
              </div>
            </div>
            <div className="row mb-1">
              <div className="form-group col-6">
                <label className="col-form-label">Contract Start Date</label>
                <p className="mb-0">{interval.LastServicedDate ? moment(interval.LastServicedDate.toDate()).format('D MMM YYYY') : ''}</p>
              </div>
              <div className="form-group col-6">
                <label className="col-form-label">Contract End Date</label>
                <p className="mb-0">{interval.UpComingServiceDate ? moment(interval.UpComingServiceDate.toDate()).format('D MMM YYYY') : ''}</p>
              </div>
              { Category !== 'Intervals' && <div className="form-group col-6">
                <label className="col-form-label">Contract Term</label>
                <p className="mb-0">{interval.ServiceInterval}</p>
              </div> }
              { Category === 'Intervals' && interval.IntervalType === 'DATE-UNITS' && interval.CurrentRunningUnit &&
              <div className="form-group col-6">
                <label className="col-form-label">Current Reading</label>
                <p className="mb-0">{interval.CurrentRunningUnit}</p>
              </div> }
              { Category !== 'Intervals' && <>
              <div className="form-group col-6">
                <label className="col-form-label">Payment Term</label>
                <p className="mb-0">{interval.PaymentTermType}</p>
              </div>
              { interval.LastPaymentDate && interval.ContractType === 'Receivable' && <div className="form-group col-6">
                <label className="col-form-label">Payment Recieved Till</label>
                <p className="mb-0">{moment(interval.LastPaymentDate.toDate()).format('D MMM YYYY')}</p>
              </div> }
              { interval.LastPaymentDate && interval.ContractType !== 'Receivable' && <div className="form-group col-6">
                <label className="col-form-label">Paid Till</label>
                <p className="mb-0">{moment(interval.LastPaymentDate.toDate()).format('D MMM YYYY')}</p>
              </div> }
              { interval.UpComingPaymentDate && <div className="form-group col-6">
                <label className="col-form-label">Next Payment Date</label>
                <p className="mb-0">{moment(interval.UpComingPaymentDate.toDate()).format('D MMM YYYY')}</p>
              </div> }  </> }           
            </div>
          
            { !!files.length && <><p className="service-data font-weight-bold mt-4 mb-2">Files</p>
            <ul className="file-list">
            { files.map(file => (
              <li key={file.id}>
                <span className="mr-2"><i className="icon-attachment"></i></span>
                <a key={file.id} target="_blank" href={file.src}>{file.name}</a>
              </li>
            ))}
            </ul></> }
          </div>
          <div className="col-md-5 system-card">
            <div className="service-body">
              { !sysHistoryLoading && contractIntervalComments && contractIntervalHistory ? <>
                {/* Service Comments */}
                <p className="service-data font-weight-bold mb-2">Comments</p>
                { contractIntervalComments && contractIntervalComments.map(comment => (
                  <div className="interval-comment" key={`interval-comment-${comment.id}`}>
                    <p className="comment-author">{comment.CommentBy}</p>
                    <p className="comment-text">{comment.Comments}</p>
                    <p className="comment-time">{moment(comment.CommentDate.toDate()).format('D/M/YYYY h:mm a')}</p>
                  </div>
                ))}
                <form className="d-flex align-items-end mt-3 comment-form" onSubmit={submitComment}>
                  <textarea className="form-control" rows="2" placeholder="Enter your comment here"
                    value={commentText} onChange={(e) => setText(e.target.value)}></textarea>
                  <button type="submit" className="btn btn-sm btn-outline-info" disabled={!commentText}>Add</button>
                </form>
                
                {/* Service History */}
                <p className="service-data font-weight-bold mt-4 mb-2">History</p>
                { contractIntervalHistory && contractIntervalHistory.length ? <>
                <ul className="timeline new">
                  { contractIntervalHistory.slice(0, 1).map(history => (
                    <li data-date={moment(history.UpdatedTime.toDate()).format('D MMM YYYY')} key={history.id}>
                      <span className={['badge-indicator', history.Indicator && history.Indicator.toLowerCase()].join(' ')}></span>
                      <span>{history.Action}</span>
                    </li>
                  ))}
                </ul>
                { contractIntervalHistory.slice(1, 3).length ? 
                <div className="history-block">
                  <ul className="timeline">
                    { contractIntervalHistory.slice(1, historyLength).map(history => (
                      <li data-date={moment(history.UpdatedTime.toDate()).format('D MMM YYYY')} key={history.id}>
                        <span className={['badge-indicator', history.Indicator && history.Indicator.toLowerCase()].join(' ')}></span>
                        { history.AdHocTaskRefDoc ?
                          <Link to={'adhoc/task?'+history.AdHocTaskRefDoc} >{history.Action}</Link> : 
                          <span>{history.Action}</span>
                        }
                      </li>
                    ))}
                  </ul>
                  { contractIntervalHistory.slice(3, contractIntervalHistory.length).length ? 
                  <a className="helper" onClick={() => sethistoryLength(historyLength === 3 ? historyLength : 3)}>
                    <u>{`${historyLength === 3 ? 'show' : 'hide'} full history`}</u>
                  </a> : '' }
                </div> : '' } </>  : 
                <p className="text-secondary"><small>No history</small></p> }
              </> : <Loading height="6rem" showLogo="false" /> }
            </div>
          </div>
        </div>
      </Card.Body> }
    </Card>
  )
}
