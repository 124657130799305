/* eslint-disable camelcase */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/no-multi-comp */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as moment from "moment";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { actionTypes } from "redux-firestore";
import { withRouter } from "react-router-dom";
import NavBar from "../common/Navbar/NavBar";
import lastUpdatedTime from "../common/Timeago";
import Loading from "../common/Loading/Loading";
import createHistory from "../../history";
import "./Dashboard.css";
import DashboardCard from "./DashboardCard";
import ReactSlider from "../common/ReactSlider/ReactSlider";
import {
  SectionHeader,
  SectionDesc,
  ViewAll,
  SectionName,
  DashboardSection,
} from "./DashboardCardStyle";
import Dropdown from "../common/Dropdown/Dropdown";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photoURL: require("../../assets/images/default_dp.svg"),
      apartmentList: [],
    };
  }

  componentDidMount = () => {
    const { user, currentApartment, firestore } = this.props;
    const { PhotoURL } = user[0];
    this.slider = React.createRef();
    this.systemSlider = React.createRef();
    this.activitiesSlider = React.createRef();
    const width = this.getWidth();
    if (width > 768) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    if (this.container) {
      this.setState({ attWidth: this.container.offsetWidth });
    }
    window.addEventListener("resize", this.reportWindowSize);
    firestore
      .collection("Users")
      .doc(user[0].id)
      .collection("AssignedApartments")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState((prevState) => ({
            apartmentList: [...prevState.apartmentList, doc.data()],
          }));
        });
      });
    this.setState({});
    if (user.length !== 0) {
      if (currentApartment) {
        this.fetchData(currentApartment);
      }
    }

    if (user && PhotoURL) {
      this.getPhotoURL(PhotoURL);
    }
  };

  componentDidUpdate = (prevProps) => {
    const { user, setErrorMessage, clearFirestore, firebase, ApartmentAccessInfo } = this.props;
    const { attWidth } = this.state;
    if (user.length !== 0) {
      const { currentApartment } = this.props;
      if (!attWidth) {
        if (this.container) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ attWidth: this.container.offsetWidth });
        }
      }
      if (prevProps.user.length !== 0) {
        if (
          currentApartment &&
          currentApartment !== prevProps.currentApartment
        ) {
          this.fetchData(currentApartment);
        }
      }
      if(ApartmentAccessInfo && ApartmentAccessInfo !== prevProps.ApartmentAccessInfo){
        this.fetchData(currentApartment);
      }
      
    } else {
      setErrorMessage("Please signup against an Apartment.", "Not Registered");
      clearFirestore();
      firebase.logout();
    }
  };

  getWidth = () =>
    Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );

  reportWindowSize = (e) => {
    if (this.isMobile) {
      if (e.target.innerWidth > 768) {
        this.isMobile = false;
        this.setState({});
      }
    } else if (e.target.innerWidth <= 768) {
      this.isMobile = true;
      this.setState({});
    }
  };

  componentWillUnmount = () => {
    if (this.unsubscribeDailyReadingCardOptions) {
      this.unsubscribeDailyReadingCardOptions();
    }
    if (this.unsubscribeSystemDetailsOptions) {
      this.unsubscribeSystemDetailsOptions();
    }
    if (this.unsubscribeStaffActivitiesOptions) {
      this.unsubscribeStaffActivitiesOptions();
    }
    window.removeEventListener("resize", this.reportWindowSize);
  };

  onNavigate = (item) => {
    const { setModule } = this.props;
    console.log(setModule);
    createHistory.push(`${item.Routing}?${encodeURI(item.Name)}`);
    setModule(item.Name);
  };

  getPhotoURL = async (path) => {
    const { firebase } = this.props;
    const storage = firebase.storage();
    const url = await storage.ref().child(path).getDownloadURL();
    this.setState({ photoURL: url });
  };

  getModuleLastUpdate = (readingValues) => {
    let lastUpdateDate = readingValues && readingValues.toDate();
    const period = moment(new Date()).diff(moment(lastUpdateDate), "months");
    if (period < 1) lastUpdateDate = moment(lastUpdateDate).fromNow();
    else lastUpdateDate = moment(lastUpdateDate).format("D MMM YYYY");
    return lastUpdateDate;
  };

 
  renderReadingCards = () => {
      const { dailyReadingCards, AppartmentDetails } = this.props;
      // const status_bar;
      // switch(color) {

      // }
      // console.log('state is', this.props)

      if (AppartmentDetails[0].has_daily_readings) {
        if (!dailyReadingCards) {
            return <Loading showLogo="false" />
        }
        if (dailyReadingCards.length > 0) {
          return (
            <DashboardSection id='dashboard_daily_reading'>
              <SectionHeader>
                  <SectionName className='mb-0'>Daily Log</SectionName>
                  <div className='d-flex align-items-center justify-content-between justify-content-md-start'>
                      <SectionDesc className='mb-0'>Check Listed Items Status</SectionDesc>
                      <ViewAll onClick={() => createHistory.push(`/dashboard/daily_log`)} className={`${this.isMobile ? 'd-mobile' : ''}`}>View All</ViewAll>
                  </div>
              </SectionHeader>
              <div className="daily_readings">
                {
                  this.isMobile ?
                    dailyReadingCards.map((item, i) => (
                      <DashboardCard item={item} key={i} className='py-md-4 pt-3 py-3' onNavigate={(this.onNavigate)}>
                        {/* <img alt="logo" src={require('../../assets/images/water-bottle.png')} className="icon" /> */}
                        <span className="icon"><i className={['icon-default-daily', `icon-${  item.IconName}`].join(' ')} /></span>
                        <span className="label">{item.Name}</span>
                        <span className='last_updated'>{this.getModuleLastUpdate(item.LastUpdatedTime)}</span>
                        {item.IndicatorInfo && <span className="last_updated" style={{ position: 'absolute', marginTop: 69 }}>{item.IndicatorInfo}</span>}
                      </DashboardCard>
                    ))
                    :
                    <ReactSlider myRef={this.slider}>
                      {
                        dailyReadingCards.map((item, i) => (
                            <DashboardCard item={item} key={i} className='py-md-4 pt-3 py-3' onNavigate={this.onNavigate}>
                                {/* <img alt="logo" src={require('../../assets/images/water-bottle.png')} className="icon" /> */}
                                <span className="icon"><i className={['icon-default-daily', `icon-${  item.IconName}`].join(' ')} /></span>
                                <span className="label">{item.Name}</span>
                                <span className='last_updated'>{this.getModuleLastUpdate(item.LastUpdatedTime)}</span>
                                {item.IndicatorInfo && <span className="last_updated" style={{ position: 'absolute', marginTop: 100 }}>{item.IndicatorInfo}</span>}
                            </DashboardCard>
                        ))
                      }
                    </ReactSlider>
              }
            </div>
          </DashboardSection>
        )
      }
    }
  }

  renderSystemStatus = () => {
    const { systemDetails, AppartmentDetails } = this.props;
    const systemHealth = systemDetails && [...systemDetails]
    if (AppartmentDetails[0].has_system_status) {
      if (!systemHealth) {
        return <Loading showLogo="false" />
      }
      if (systemHealth.length > 0) {
        return (
          <DashboardSection id='dashboard_system_status'>
            <SectionHeader>
                <SectionName className='mb-0'>System Health</SectionName>
                <div className='d-flex align-items-center justify-content-between justify-content-md-start'>
                    <SectionDesc className='mb-0'>Check Status and Send Notifications</SectionDesc>
                    <ViewAll onClick={() => createHistory.push(`/dashboard/system_health`)} className={`${this.isMobile ? 'd-mobile' : ''}`}>View All</ViewAll>
                </div>
            </SectionHeader>
            <div className="system_statuses">
              {
                this.isMobile ?
                  systemHealth.map((item, i) => (
                    <DashboardCard item={item} key={i} onNavigate={this.onNavigate}>
                        <span className="label">{item.DisplayName || item.Name}</span>
                        {item.InspectionDue ? <i className="icon-audit" /> : null}
                        <div className='d-flex flex-column align-items-start'>
                            <span className="status">{item.Status}</span>
                            <span className='last_updated'>{this.getModuleLastUpdate(item.LastUpdatedTime)}</span>
                        </div>
                        <div className={this.isMobile ? 'd-none' : 'd-block updatedBy'}>
                            <span>{item.LastUpdatedBy}</span>
                        </div>
                    </DashboardCard>
                  )) :
                  <ReactSlider myRef={this.systemSlider}>
                    {
                      systemHealth.map((item, index) => 
                        // if(index < 3) {
                        (
                          <DashboardCard item={item} key={index} onNavigate={this.onNavigate}>
                              <span className="label">{item.DisplayName || item.Name}</span>
                              {item.InspectionDue ? <i className="icon-audit" /> : null}
                              <div className='d-flex flex-column align-items-start'>
                                  <span className="status">{item.Status}</span>
                                  <span className='last_updated'>{this.getModuleLastUpdate(item.LastUpdatedTime)}</span>
                              </div>
                              <div className={this.isMobile ? 'd-none' : 'd-block updatedBy'}>
                                  <span>{item.LastUpdatedBy}</span>
                              </div>
                          </DashboardCard>
                        )
                        // }
                      )
                    }
                  </ReactSlider>
              }
            </div>
          </DashboardSection>
        )
      }
    }
  }

  renderStaffActivities = () => {
    const { user, staffActivities, AppartmentDetails, ApartmentAccessInfo, currentApartment } = this.props;

    let accessInfo = null;
    if(ApartmentAccessInfo.length){
      const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
      if(selectedApartment){
        accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
      }
    }
    const { Roles, is_mc_member, is_manager } = accessInfo || {Roles:[]}
    // const { Roles, is_mc_member, is_manager } = userAccessInfo && userAccessInfo[0];
    const { attWidth } = this.state;
    if (AppartmentDetails[0].has_staff_activities) {
      if (!staffActivities) {
          return <Loading showLogo="false" />
      }
      if (staffActivities.length > 0) {
        return (
          <DashboardSection id='dashboard_staff_activities' >
            <SectionHeader>
                <SectionName className='mb-0'>Staff Activities</SectionName>
                <div className='d-flex align-items-center justify-content-between justify-content-md-start'>
                    <SectionDesc className='mb-0'>Check Status and Make Actions</SectionDesc>
                    <ViewAll onClick={() => createHistory.push(`/dashboard/staff_activities`)} className={`${this.isMobile ? 'd-mobile' : ''}`}>View All</ViewAll>
                </div>
            </SectionHeader>
            <div className="d-flex attendance-row">
              {
                staffActivities.map((item, i) => {                                    
                  if (item.CardType === 'Attendance') {
                    const width = Math.ceil((100 / item['All Staff'].Total) * item['All Staff'].Available)
                    return (
                      <DashboardCard item={item} key={i} width={width || 0} onNavigate={this.onNavigate} className='w-100 mr-0 border-bottom-0 p-0 d-flex flex-column justify-content-center my-2 linear-border'>
                          {/* <div className='d-flex justify-content-between'> */}
                          <div className='d-flex flex-column align-items-start'>
                              <div className="label" style={{ color: '#a2a2a2' }}>{item.Name}</div>
                              <div className="status" style={{ fontSize: 20, fontWeight: 'bold' }}>{item['All Staff'].Available}/{item['All Staff'].Total}</div>
                          </div>

                          {/* </div> */}
                          <span className='mr-4 px-0' style={{ backgroundColor: 'transparent', border: 'none', position: 'absolute', right: 0 }} onClick={this.previousReading.bind(this)}>
                              <img alt="logo" src={require('../../assets/images/next-arrow.png')} className="arrow-next" style={{ height: 15, width: 21 }} />
                          </span>
                          {/* <div className='slider' style={{ height: 5, backgroundColor: 'red', width: width || 0 }}/> */}
                      </DashboardCard>
                    )
                  }
                })
              }
            </div>
            <div className="task-row">
              {
                  this.isMobile ?
                      staffActivities.map((item, i) => {
                          if (item.CardType === 'Incidents') {
                              let route; let count = 0;
                              if (is_manager || is_mc_member) {
                                  count = (item.OpenTickets && item.OpenTickets.Default) || 0;
                                  route = (item.Routing && item.Routing.Default) || '#';
                              } else if ((Roles|| []).some(role => item.Roles && item.Roles.includes(role))) {
                                  const index = Roles.findIndex(r => Object.keys(item.Routing || {}).includes(r));
                                  if (index >= 0) {
                                      route = item.Routing[Roles[index]];
                                      count = Object.keys(item.OpenTickets || {}).reduce((cnt, key) => {
                                          if (Roles.includes(key)) cnt += item.OpenTickets[key];
                                          return cnt;
                                      }, 0);
                                  } else {
                                      route = item.Routing.Default;
                                      count = item.OpenTickets.Default
                                  }
                              } else {
                                  route = (item.Routing && item.Routing.Default) || '#';
                                  count = Object.keys(item.OpenTickets || {}).reduce((cnt, key) => {
                                      if (key !== 'Default') cnt += item.OpenTickets[key];
                                      return cnt;
                                  }, 0);
                              }
                              return (
                                  <DashboardCard item={item} key={i} onNavigate={() => createHistory.push(route)}>
                                      <div className='tasks w-100'>
                                          <span title={item.Name} className="label text-truncate" style={{ color: '#494949' }}>{item.Name}
                                              <span className="text-primary float-right"><i className={`icon-${item.IconName}`} /></span>
                                          </span>
                                          <div className="text-left">
                                              <span className="total d-block text-left mt-4">{count} Open Tickets</span>
                                              <span className="completed d-block text-left" />
                                              <span className="completed d-block text-left" />
                                          </div>
                                      </div>
                                  </DashboardCard>
                              )
                          }
                          if (item.CardType === 'Tasks') {
                              return (
                                  <DashboardCard item={item} key={i} onNavigate={this.onNavigate}>
                                      <div className='tasks w-100'>
                                          <span title={item.Name} className="label text-truncate" style={{ color: '#494949' }}>{item.Name}
                                              <span className="text-primary float-right"><i className={`icon-${item.IconName}`} /></span>
                                          </span>
                                          <div className="text-left">
                                              <span className="total d-block text-left mt-4">{item['Total Tasks']} Tasks</span>
                                              <span className="completed d-block text-left">{item.Completed} Completed</span>
                                              <span className="completed d-block text-left" style={{fontSize:'0.7rem', color:'#ff6a6a'}}>{item.Rejected ? item.Rejected : 0} Defaulted</span>
                                          </div>
                                      </div>
                                  </DashboardCard>
                              )
                          }
                      })
                      :
                      <ReactSlider myRef={this.activitiesSlider}>
                          {
                              staffActivities.map((item, i) => {
                                  if (item.CardType === 'Incidents') {
                                      let route; let count = 0;
                                      if (is_manager || is_mc_member) {
                                          count = (item.OpenTickets && item.OpenTickets.Default) || 0;
                                          route = (item.Routing && item.Routing.Default) || '#';
                                      } else if ((Roles|| []).some(role => item.Roles && item.Roles.includes(role))) {
                                          const index = Roles.findIndex(r => Object.keys(item.Routing || {}).includes(r));
                                          if (index >= 0) {
                                              route = item.Routing[Roles[index]];
                                              count = Object.keys(item.OpenTickets || {}).reduce((cnt, key) => {
                                                  if (Roles.includes(key)) cnt += item.OpenTickets[key];
                                                  return cnt;
                                              }, 0);
                                          } else {
                                              route = item.Routing.Default;
                                              count = item.OpenTickets.Default
                                          }
                                      } else {
                                          route = (item.Routing && item.Routing.Default) || '#';
                                          count = Object.keys(item.OpenTickets || {}).reduce((cnt, key) => {
                                              if (key !== 'Default') cnt += item.OpenTickets[key];
                                              return cnt;
                                          }, 0);
                                      }
                                      return (
                                          <DashboardCard item={item} key={i} onNavigate={() => createHistory.push(route)}>
                                              <div className='tasks w-100' style={{height: '115px'}}>
                                                  <span title={item.Name} className="label text-truncate" style={{ color: '#494949' }}>{item.Name}
                                                      <span className="text-primary float-right"><i className={`icon-${item.IconName || 'default-daily'}`} /></span>
                                                  </span>
                                                  <div className="text-left">
                                                      <span className="total d-block text-left mt-4">{count} Open Tickets</span>
                                                  </div>
                                              </div>
                                          </DashboardCard>
                                      )
                                  }
                                  if (item.CardType === 'Tasks') {
                                      return (
                                          <DashboardCard item={item} key={i} onNavigate={this.onNavigate}>
                                              <div className='tasks w-100' style={{height: '115px'}}>
                                                  <span title={item.Name} className="label text-truncate" style={{ color: '#494949' }}>{item.Name}
                                                      <span className="text-primary float-right"><i className={`icon-${item.IconName}`} /></span>
                                                  </span>
                                                  <div className="text-left">
                                                      <span className="total d-block text-left mt-4">{item['Total Tasks']} Tasks</span>
                                                      <span className="completed d-block text-left">{item.Completed} Completed</span>
                                                      <span className="completed d-block text-left" style={{fontSize:'0.75rem', color:'#ff6a6a'}}>{item.Rejected ? item.Rejected : 0} Defaulted</span>
                                                  </div>
                                              </div>
                                          </DashboardCard>
                                      )
                                  }
                              })
                          }
                      </ReactSlider>
              }
                        </div>
                    </DashboardSection>
                )
            }
        }
  };

  

  

  showSideNav = () => {
    setTimeout(() => this.props.openSideBar(false));
  };

  handleApartmentChange = (apt) => {
    const {
      setCurrentApartment,
      clearApartmentData,
      currentApartment,
    } = this.props;
    if (apt !== currentApartment) {
      clearApartmentData();
      createHistory.push("/");
      setCurrentApartment(apt);
    }
  };

  showMobileNav = () => {
    const { user, AppartmentDetails } = this.props;
    const { apartmentList } = this.state;
    if (this.isMobile) {
      return (
        <div
          id="dashboard-nav"
          style={{
            backgroundColor: AppartmentDetails[0].Header_Theme_Color
              ? AppartmentDetails[0].Header_Theme_Color
              : null,
          }}
        >
          <img
            alt="logo"
            style={{ height: "2.4rem" }}
            className="py-2"
            src={
              AppartmentDetails[0].Header_Logo_Text
                ? AppartmentDetails[0].Header_Logo_Text
                : require("../../assets/images/logo-wordmark.svg")
            }
          />
          <div className="d-flex postion-relative">
            <div className="navbar-nav d-flex justify-content-center">
              <h6 className="building_name mt-1 mb-0">
                {AppartmentDetails[0].Name}
              </h6>
            </div>

            {/* Show Apartment Switching Dropdown component only when more than 1 AssignedApartments */}
            {user[0].AssignedApartments.length > 1 && (
              <div className="navbar-nav d-flex justify-content-center mobile-drpdwn">
                <Dropdown
                  data={apartmentList}
                  onChange={this.handleApartmentChange}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  fetchData = (currentApartment) => {
    const { firestore, AppartmentDetails, ApartmentAccessInfo, user } = this.props;

    let accessInfo = null;
    if(ApartmentAccessInfo.length){
      const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
      if(selectedApartment){
        accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
      }
    }
    
    const { Roles, has_all_access } = accessInfo || {}


    if (AppartmentDetails && AppartmentDetails[0].has_daily_readings) {
      const DailyReadingCardOptions = {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [{ collection: "DailyReadingCards" }],
        storeAs: "DailyReadingCards",
      };
      if (!has_all_access && Roles && Roles.length) {
        console.log('Roles 1:',Roles)
        DailyReadingCardOptions.where = [
          ["Roles", "array-contains-any", Roles],
        ];
      }
      
      if (this.unsubscribeDailyReadingCardOptions) {
        this.unsubscribeDailyReadingCardOptions();
      }
      this.unsubscribeDailyReadingCardOptions = firestore.onSnapshot(
        DailyReadingCardOptions
      );
    }

    if (AppartmentDetails && AppartmentDetails[0].has_system_status) {
      const SystemDetailsOptions = {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [{ collection: "SystemDetails" }],
        storeAs: "SystemDetails",
      };
      if (!has_all_access && Roles && Roles.length) {
        console.log('Roles 2:',Roles)
        SystemDetailsOptions.where = [
          ["Roles", "array-contains-any", Roles],
        ];
      }
      if (this.unsubscribeSystemDetailsOptions) {
        this.unsubscribeSystemDetailsOptions();
      }
      this.unsubscribeSystemDetailsOptions = firestore.onSnapshot(
        SystemDetailsOptions
      );
    }

    if (AppartmentDetails && AppartmentDetails[0].has_staff_activities) {
      const StaffActivitiesOptions = {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [{ collection: "StaffActivitiesCard" }],
        storeAs: "StaffActivities",
      };

      if (!has_all_access && Roles && Roles.length) {
        console.log('Roles 3:',Roles)
        StaffActivitiesOptions.where = [
          ["Roles", "array-contains-any", Roles],
        ];
      }
      if (this.unsubscribeStaffActivitiesOptions) {
        this.unsubscribeStaffActivitiesOptions();
      }
      this.unsubscribeStaffActivitiesOptions = firestore.onSnapshot(
        StaffActivitiesOptions
      );
    }
  };

  nextReading() {
    this.slider.current.slickNext();
  }

  nextStatusCard() {
    this.systemSlider.current.slickNext();
  }

  previousReading() {
    this.slider.current.slickPrev();
  }

  previousStatusCard() {
    this.systemSlider.current.slickPrev();
  }

  render() {
    return (
      <div className="dashboard" id="dashboard">
        {this.showMobileNav()}
        <div className="dashboard_body">
          {this.renderReadingCards()}
          {this.renderSystemStatus()}
          {this.renderStaffActivities()}
        </div>
      </div>
    );
  }
}

Dashboard.defaultProps = {
  user: [{ Email: "", is_approved: true }],
};

const mapDispatchToProps = (dispatch) => ({
  clearFirestore: () => dispatch({ type: "@@reduxFirestore/CLEAR_DATA" }),
  clearApartmentData: () =>
    dispatch({
      type: actionTypes.CLEAR_DATA,
      preserve: {
        data: ["Users", "UserAccessInfo", "MenuSettings"],
        ordered: ["Users", "UserAccessInfo", "MenuSettings"],
      },
    }),
  setErrorMessage: (message) => dispatch({ type: "ERROR_VALUE", message }),
  openSideBar: (value) => dispatch({ type: "SIDEBAR_OPEN", payload: value }),
  toggleQrModal: (val) =>
    dispatch({ type: "TOGGLE_QR_MODAL", payload: { val } }),
  toggleNotifyModal: (val) =>
    dispatch({ type: "TOGGLE_NOTIFY_MODAL", payload: val }),
  setModule: (name) => dispatch({ type: "UPDATE_MODULE", payload: name }),
  setCurrentApartment: (apt) =>
    dispatch({ type: "SET_APARTMENT", payload: apt }),
});

const mapStateToProps = (state) => ({
  user: state.firestore.ordered.Users,
  email: state.firebase.auth.email,
  dailyReadingCards: state.firestore.ordered.DailyReadingCards,
  staffActivities: state.firestore.ordered.StaffActivities,
  systemDetails: state.firestore.ordered.SystemDetails,
  // userAccessInfo: state.firestore.ordered.UserAccessInfo,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  currentApartment: state.apartment.currentApartment,
  ApartmentAccessInfo: state.apartment.ApartmentAccessInfo
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    if (!props.email) return [];
    return [
      {
        collection: "Users",
        where: [["Email", "==", props.email]],
      },
    ];
  }),
  firebaseConnect(),
  withRouter
)(Dashboard);
