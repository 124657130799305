/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-template */
/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/button-has-type */
import React, { useMemo, useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { useTable, useGlobalFilter } from "react-table";
import {
  OverlayTrigger,
  Popover,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import * as _ from "lodash";
import ModalHeader from "react-bootstrap/ModalHeader";
import DetailsHeader from "../../Common/DetailsHeader/DetailsHeader";
import Icon from "../../Common/Icon/Icon";
import ContactCard from "../../Common/ContactCard/ContactCard";
// import image from "../../../../../assets/images/avatar.png";
import { BackButton } from "../../Common/Buttons";
import {
  Search,
  SearchInput,
  Thead,
  ModalThead,
  ModalTitle,
  ModalTBody,
  DetailsModal,
  IconSearch,
  Styles,
  Image,
} from "../styles";
import useWindowSize from "../../Hooks/useWindowSize";
import "./AuditingDetails.css";
// Create a default prop getter
const defaultPropGetter = () => ({});

function ParentTable({ columns, data, getCellProps = defaultPropGetter }) {
  const [filterInput, setFilterInput] = React.useState("");
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter
  );

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value || "");
  };
  // Render the UI for your table
  return (
    <>
      <div className="exe-details-header-container d-flex flex-row justify-content-between mb-3">
        <div className="d-flex flex-row align-items-center">
          <BackButton />
          <DetailsHeader
            label="Auditing Status"
            description="Description to be defined"
          />
        </div>
        <div className="d-flex flex-row align-items-center col-4 m-0 p-0">
          <Search className="ml-4 flex-grow-1">
            <SearchInput
              type="text"
              className="w-100"
              placeholder="Search"
              value={filterInput}
              onChange={handleFilterChange}
            />
            <IconSearch className="icon-search" />
          </Search>
        </div>
      </div>
      <table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </Thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr>
                {row.cells.map((cell, i) => (
                  <td {...cell.getCellProps(i === 0 && getCellProps(cell))}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

const AuditingDetails = ({
  consolidated,
  ConsViews,
  managersList,
  firestore,
  selectedZone,
  viewDetail,
}) => {
  const { width: WindowWidth } = useWindowSize();
  const [auditingDetails, setAuditingDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apartmentId, setApartmentId] = useState(null);
  const [apartmentName, setApartmentName] = useState(null);
  const [issue, setIssue] = useState(null);
  useEffect(() => {
    if (apartmentId && consolidated && consolidated.length) {
      setIsLoading(true);
      setIssue(null);
      firestore
        .get({
          collection: "consolidated",
          doc: consolidated[0].id,
          subcollections: [
            {
              collection: "AuditingStatus_ED",
              doc: apartmentId,
              subcollections: [{ collection: "auditing_status_ed" }],
            },
          ],
          storeAs: "auditing_status_ed",
        })
        .then(async (querySnapshot) => {
          setIsLoading(false);
          let issues = [];
          querySnapshot.forEach((doc) => {
            issues = [...issues, { ...doc.data() }];
          });
          setIssue(issues);
        })
        .catch(() => setIsLoading(false));
    }
  }, [apartmentId]);
  useEffect(() => {
    let condition = [];
    if (selectedZone && selectedZone !== "All") {
      condition = [...condition, ["Zone", "==", selectedZone]];
    }
    if (!consolidated || !consolidated.length) {
      return;
    }
    firestore
      .get({
        collection: "consolidated",
        doc: consolidated[0].id,
        subcollections: [
          {
            collection: "AuditingStatus_ED",
            where: condition,
            // where: [["StatusIndicator", "in", ["Red", "Amber"]]],
          },
        ],
        storeAs: "AuditingStatus_ED",
      })
      .then((querySnapshot) => {
        let auditingDetail = [];
        const { Level: currentUserLevel } = ConsViews[0];
        querySnapshot.forEach((doc) => {
          const managers = managersList.filter(
            ({ Zones, Level }) =>
              Zones.includes(doc.data().Zone) &&
              Level > currentUserLevel &&
              Level <= currentUserLevel + 2
          );

          auditingDetail = [
            ...auditingDetail,
            {
              id: doc.id,
              ...doc.data(),
              managers,
            },
          ];
        });

        const details = _.chain(auditingDetail)
          .groupBy("StatusIndicator")
          .value();

        if (Object.keys(details).length) {
          // eslint-disable-next-line no-prototype-builtins
          if (!details.hasOwnProperty("Red")) {
            details.Red = [];
          }
          // eslint-disable-next-line no-prototype-builtins
          if (!details.hasOwnProperty("Amber")) {
            details.Amber = [];
          }
          // eslint-disable-next-line no-prototype-builtins
          if (!details.hasOwnProperty("Green")) {
            details.Green = [];
          }
          const statusRed = JSON.parse(JSON.stringify(details.Red));
          const statusAmber = JSON.parse(JSON.stringify(details.Amber));
          const statusGreen = JSON.parse(JSON.stringify(details.Green));
          setAuditingDetails([...statusRed, ...statusAmber, ...statusGreen]);
        }
      });
  }, [selectedZone]);
  const columns = useMemo(
    () => [
      {
        Header: "Property Name",
        accessor: "ApartmentName", // accessor is the "key" in the data
      },
      {
        Header: "Contact Person",
        accessor: "managers",
        Cell: ({ cell }) => {
          const managers = cell.row.original.managers;

          const { DisplayName, Role, PhoneNo } = managers[0] || {};
          if (!managers.length) return "";

          return (
            <div className="row">
              <ContactCard
                name={DisplayName}
                userRole={Role}
                contact={PhoneNo}
                hideUnderline
              />
              <OverlayTrigger
                rootClose
                trigger="click"
                // key="bottom"
                placement="right"
                overlay={
                  <Popover
                    // id="popover-positioned-bottom"
                    style={{ maxWidth: "350px" }}
                  >
                    <Popover.Content>
                      {managers.map((manager) => {
                        const { DisplayName, Role, PhoneNo } = manager;
                        return (
                          <ContactCard
                            key={PhoneNo}
                            name={DisplayName}
                            userRole={Role}
                            contact={PhoneNo}
                          />
                        );
                      })}
                    </Popover.Content>
                  </Popover>
                }
              >
                <button style={{ background: "none", border: "none" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                  >
                    <path
                      d="M1 1L4 4L7 1"
                      stroke="#B5B5B5"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </OverlayTrigger>
            </div>
          );
        },
      },
      {
        Header: "Audits Completed",
        accessor: "CompletedAuditItems",
        Cell: ({ cell }) => (
          <div className="row justify-content-center">
            <div
              className="d-inline mr-1"
              onClick={() => {
                setShow(true);
                setApartmentId(cell.row.original.id);
                setApartmentName(cell.row.original.ApartmentName);
              }}
            >
              <u style={{ color: "#558DCA" }}>
                {cell.row.original.CompletedAuditItems}/
                {cell.row.original.TotalAuditItems}
              </u>
            </div>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => (
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div
              className={"status-" + cell.row.original.StatusIndicator}
              style={{
                width: "119px",
                height: "30px",
                borderRadius: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: "12px",
                  color: "#FFFFFF",
                  textTransform: "uppercase",
                }}
              >
                {cell.row.original.StatusIndicator === "Green"
                  ? "completed"
                  : cell.row.original.StatusIndicator === "Amber"
                  ? "in progress"
                  : "not started"}
              </span>
            </div>
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => {
                viewDetail(
                  "dashboard/staff_activities",
                  cell.row.original.Apt_Id
                );
              }}
            >
              <Icon icon="rightArrow" fill="#558DCA" />
            </button>
          </div>
        ),
      },
    ],
    []
  );
  const data = useMemo(() => auditingDetails, [auditingDetails]);

  return (
    <div className="d-flex flex-row flex-wrap m-4">
      <div className="col-lg-12 col-md-12 col-sm-12">
        {WindowWidth <= 768 ? (
          <div style={{ marginTop: "1.25rem" }}>Auditing Details</div>
        ) : null}

        <Styles>
          {!data.length ? (
            <div className="d-flex justify-content-center mt-5">
              <span className="mt-5">No Issues to Display</span>
            </div>
          ) : (
            <ParentTable
              columns={columns}
              data={data}
              getCellProps={(cell) => ({
                style: {
                  borderLeft:
                    cell.row.original.StatusIndicator === "Green"
                      ? "8px solid #01D09F"
                      : cell.row.original.StatusIndicator === "Amber"
                      ? "8px solid #FFDB1C"
                      : "8px solid #FF8D8D",
                },
              })}
            />
          )}
        </Styles>
      </div>
      <DetailsModal
        show={show}
        size="lg"
        onHide={() => {
          setShow(false);
          setApartmentId(null);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Issue Details
          </Modal.Title>
        </Modal>
        {isLoading ? (
          <div className="row justify-content-center">
            <Spinner
              animation="grow"
              variant="dark"
              className="align-items-center"
            />
          </div>
        ) : (
          <>
            <ModalHeader>
              <div className="d-flex justify-content-between align-items-center w-100">
                <ModalTitle>Auditing Details - {apartmentName}</ModalTitle>
                <Image
                  onClick={() => {
                    setShow(false);
                    setApartmentId(null);
                  }}
                />
              </div>
            </ModalHeader>

            <Modal.Body>
              <div className="tableFixHead">
                <table>
                  <thead>
                    <tr>
                      <th>Audit Item</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(issue || []).map((item, index) => (
                      <tr key={index}>
                        <td>{item.AuditItem}</td>
                        <td>
                          {item.StartTime
                            ? item.StartTime.toDate().toDateString()
                            : "Nil"}
                        </td>
                        <td>
                          {item.EndTime
                            ? item.EndTime.toDate().toDateString()
                            : "Nil"}
                        </td>
                        <td>{item.Status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <Table className="mb-0">
                <ModalThead>
                  <tr>
                    <th>Audit Item</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Status</th>
                  </tr>
                </ModalThead>
                <ModalTBody>
                  {(issue || []).map((item, index) => (
                    <tr key={index}>
                      <td>{item.AuditItem}</td>
                      <td>
                        {item.StartTime
                          ? item.StartTime.toDate().toDateString()
                          : "Nil"}
                      </td>
                      <td>
                        {item.EndTime
                          ? item.EndTime.toDate().toDateString()
                          : "Nil"}
                      </td>
                      <td>{item.Status}</td>
                    </tr>
                  ))}
                </ModalTBody>
              </Table> */}
            </Modal.Body>
          </>
        )}
      </DetailsModal>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  viewDetail: (route, apartment) =>
    dispatch({
      type: "REDIRECT_DASHBOARD_TO_ROUTE",
      payload: { route, apartment },
    }),
});
export default compose(
  connect(
    ({
      dashboard,
      apartment,
      firestore: {
        ordered: { consolidated, ConsViews },
      },
    }) => ({
      consolidated,
      managersList: apartment.managersList,
      ConsViews,
      selectedZone: dashboard.selectedZone,
    }),
    mapDispatchToProps
  ),
  firestoreConnect()
)(AuditingDetails);
