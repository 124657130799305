import React from 'react'

function TicketTitle({ id, className, questionNo, next, onAnswer, isRequired }) {

  const onChange = (e) => {
    onAnswer(e.target.value)
  }

  return (
    <div id={id} data-key="Title" data-required={isRequired ? 'true': 'false'} className={["p-3 h-100 d-flex flex-column justify-content-center q-block", className].join(' ')}>
      <p className="mb-3 question">Ticket Title ?</p>
      <div className="">
        <input
          placeholder="Title"
          onChange={onChange}
          className="form-control tf-input"
          onKeyPress={(e) => { 
            if(e.which === 13 ) {
                console.log('value', e.target.value);
                if(isRequired && !e.target.value) return
                next();
            }
          }}
        />
      </div>
    </div>
  )
}

export default TicketTitle;