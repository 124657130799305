import styled from "styled-components";

export const DashboardItem = styled.button`
display: 'flex',
flexDirection: 'column',
justifyContent: 'space-between',
alignItems: 'center',
borderRadius: '5px',
boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.03)',
marginRight: '2.5rem',
backgroundColor: '#ffffff',
width: '256px'`;

export const SectionName = styled.h5`
color: #3b3b3b;
font-weight: bold;
font-family: 'Nunito';
@media (max-width: 768px) { 
  font-size: 1rem
}
`;

export const SectionDesc = styled.h6`
color: #a2a2a2;
font-family: 'Nunito';
font-size: 0.875rem;
@media (max-width: 768px) { 
  font-size: 0.75rem
}
`;

export const ViewAll = styled.a`
background-color: transparent;
text-decoration: underline !important;
border: none;
font-size: 0.75rem;
color: #494949 !important;
font-family: 'Nunito';
margin-left: 0.75rem;
transition: all ease 400ms;
cursor: pointer;
@media (max-width: 768px) { 
  font-size: 0.5625rem;
  text-decoration: none !important;
}
`;

export const SectionHeader = styled.div`
margin-right: 2.5rem;
margin-left: 2.5rem;
@media (max-width: 768px) { 
  margin-left: 1rem;
  margin-right: 1rem;
}
`;
export const DashboardSection = styled.div`
  margin-bottom: 2rem;
  @media (max-width: 768px) { 
    margin-bottom: 2rem;
  }
`;