import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import moment from "moment";
import Loading from "../../common/Loading/Loading";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import ContactsInfo from "../ContactsInfo/ContactsInfo";

class SystemUpdateCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editSysService: false,
      sysHistoryLoading: false,
      sysHistoryCollapse: true,
      editServiceInfo: false,
      formValues: {},
      updateErrors: {},
    };
  }

  showEditModeService = (e) => {
    e.stopPropagation();
    this.setState({ editSysService: true });
  };

  setFormValue = (type, value) => {
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [`${type}`]: value } });
  };

  checkErrors = (sysInterval) => {
    const { formValues } = this.state;
    const errors = {};
    if (!formValues.CurrentServiceNotes) {
      errors["CurrentServiceNotes"] = "Service notes is required";
    }
    if (
      sysInterval.IntervalType === "DATE" ||
      sysInterval.IntervalType === "DATE-UNITS"
    ) {
      if (!formValues.LastServicedDate) {
        errors["LastServicedDate"] = "Please choose a new service date";
      } else if (isNaN(new Date(formValues.LastServicedDate).getTime())) {
        errors["LastServicedDate"] = "Please choose a valid date";
      } else if (
        new Date(formValues.LastServicedDate) <=
        sysInterval.LastServicedDate.toDate()
      ) {
        errors["LastServicedDate"] =
          "New service date should be greater than last service date";
      }
    }
    if (
      sysInterval.IntervalType === "UNITS" ||
      sysInterval.IntervalType === "DATE-UNITS"
    ) {
      if (!formValues.LastServicedUnits) {
        errors["LastServicedUnits"] = "Please enter a new service unit reading";
      } else if (isNaN(formValues.LastServicedUnits)) {
        errors["LastServicedUnits"] =
          "Please enter a valid service unit reading";
      } else if (
        formValues.LastServicedUnits <= sysInterval.LastServicedUnits
      ) {
        errors["LastServicedUnits"] =
          "New service unit should be greater than last service unit reading";
      }
    }

    return errors;
  };

  showServiceHistory = async (docId) => {
    const { firestore, currentApartment } = this.props;
    this.setState({ sysHistoryLoading: true, sysHistoryCollapse: false });
    if (this.unsubscribeGeneralIntervalHistory) {
      this.unsubscribeGeneralIntervalHistory();
    }
    this.unsubscribeGeneralIntervalHistory = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [
        {
          collection: "ServiceIntervalDetails",
          doc: docId,
          subcollections: [{ collection: "ServiceIntervalHistory" }],
        },
      ],
      orderBy: ["ServicedDate", "desc"],
      limit: 25,
      storeAs: "GeneralIntervalHistory",
    });
    setTimeout(() => this.setState({ sysHistoryLoading: false }), 2000);
  };

  save = (sysInterval) => {
    const { formValues } = this.state;
    const { user, firestore, currentApartment } = this.props;
    const { DisplayName } = user[0];
    const errors = this.checkErrors(sysInterval);

    if (Object.keys(errors).length) {
      this.setState({ updateErrors: errors });
      return false;
    }
    if (formValues.LastServicedDate)
      formValues.LastServicedDate = formValues.LastServicedDate;

    firestore.update(
      {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [
          { collection: "ServiceIntervalDetails", doc: sysInterval.id },
        ],
      },
      { ...formValues, LastUpdatedBy: DisplayName }
    );

    this.setState({ editSysService: false });
  };

  getSrvcContacts(ServiceTitle, contacts) {
    const { location } = this.props;
    const ServiceName = decodeURI(location.search.substring(1));
    console.log(ServiceName, ServiceTitle);
    return (
      contacts &&
      contacts.filter(
        (c) =>
          c.ServiceName === ServiceName &&
          c.ServiceTitle === ServiceTitle &&
          c.Type === "ServiceInterval"
      )
    );
  }

  getIndicator(sys) {
    let indicator;
    if (sys.Indicator === "Red") {
      indicator = "red";
    } else if (sys.Indicator === "Amber") {
      indicator = "amber";
    }

    return indicator;
  }

  serviceInfo = {};

  onClickEditServiceInfo(serviceInfo) {
    this.serviceInfo = serviceInfo;
    this.setState({ editServiceInfo: true });
  }

  onSaveServiceInfo(docId) {
    const { firestore, currentApartment } = this.props;
    firestore.update(
      {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [{ collection: "ServiceIntervalDetails", doc: docId }],
      },
      { ServiceInfo: this.serviceInfo }
    );
    this.setState({ editServiceInfo: false });
  }

  onChangeServiceInfo(value, key) {
    this.serviceInfo = { ...this.serviceInfo, [`${key}`]: value };
  }

  render() {
    const {
      editSysService,
      updateErrors,
      formValues,
      sysHistoryCollapse,
      sysHistoryLoading,
      editServiceInfo,
    } = this.state;
    const {
      sysServiceCollapse,
      srvInterlDetail,
      toggleCollpase,
      generalIntervalHistory,
      key,
      contactInfo,
      onDeleteContact,
      onSaveContactCard,
    } = this.props;

    const srvcContacts = this.getSrvcContacts(
      srvInterlDetail.ServiceTitle,
      contactInfo
    );

    return (
      <div className="service-body">
        <h6 className="mb-0" onClick={() => toggleCollpase(srvInterlDetail.id)}>
          {srvInterlDetail.ServiceTitle}
          {this.getIndicator(srvInterlDetail) && (
            <span
              className={`badge badge-secondary badge-pill ml-2 badge-indicator ${this.getIndicator(
                srvInterlDetail
              )}`}
            ></span>
          )}
          {
            <span
              className={`icon ml-auto float-right${
                !sysServiceCollapse ? " open" : ""
              }`}
            >
              <i className="icon-chevron-down"></i>
            </span>
          }
          {!sysServiceCollapse && !editSysService && (
            <a
              className="service-link ml-auto mr-3 float-right"
              onClick={this.showEditModeService}
            >
              Update
            </a>
          )}
        </h6>
        <Collapse in={!sysServiceCollapse}>
          <div className="pt-2">
            {!editSysService ? (
              <>
                <div className="row">
                  <div className="col-6 service-group">
                    <label className="service-label">Last Serviced</label>
                    <p className="service-data">
                      {srvInterlDetail.LastServiced}
                    </p>
                  </div>
                  <div className="col-6 service-group">
                    <label className="service-label">Upcoming Service</label>
                    <p className="service-data">
                      {srvInterlDetail.UpComingService}
                    </p>
                  </div>
                  {srvInterlDetail.IntervalType === "DATE-UNITS" &&
                    srvInterlDetail.CurrentRunningUnit && (
                      <div className="col-6 service-group">
                        <label className="service-label">Current Reading</label>
                        <p className="service-data">
                          {srvInterlDetail.CurrentRunningUnit}
                        </p>
                      </div>
                    )}
                  <div className="col-6 service-group">
                    <label className="service-label">Service Intervals</label>
                    <p className="service-data">
                      {srvInterlDetail.ServiceInterval}
                    </p>
                  </div>
                  <div className="col-6 service-group">
                    <label className="service-label">Device Name</label>
                    <p className="service-data">
                      {srvInterlDetail.EquipmentName || "NA"}
                    </p>
                  </div>
                  <div className="col-6 service-group">
                    <label className="service-label">Type</label>
                    <p className="service-data">
                      {srvInterlDetail.EquipmentType || "NA"}
                    </p>
                  </div>
                  <div className="col-6 service-group">
                    <label className="service-label">Commisioned On</label>
                    <p className="service-data">
                      {srvInterlDetail.CommissionedOn || "-"}
                    </p>
                  </div>
                </div>
                {srvInterlDetail.ServiceInfo && (
                  <>
                    <h6 className="mb-0 mt-4 d-flex">Info</h6>
                    <ContactsInfo
                      contacts={srvcContacts}
                      Type={"ServiceInterval"}
                      ServiceTitle={srvInterlDetail.ServiceTitle}
                      limit={5}
                      deleteContact={onDeleteContact}
                      frenabled={true}
                      onSaveContactCard={onSaveContactCard}
                    />
                  </>
                )}
                {editServiceInfo && (
                  <div className="form-group d-flex justify-content-end border-0 py-2">
                    <button
                      className="btn btn-sm btn-light mr-2"
                      onClick={() => this.setState({ editServiceInfo: false })}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => this.onSaveServiceInfo(srvInterlDetail.id)}
                    >
                      Save
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="row">
                <div className="col-12 service-group">
                  <label className="service-label">Service Notes</label>
                  <input
                    type="text"
                    ref
                    className={`service-input${
                      updateErrors.CurrentServiceNotes ? " error" : ""
                    }`}
                    placeholder="Enter service notes here"
                    onChange={(e) =>
                      this.setFormValue("CurrentServiceNotes", e.target.value)
                    }
                  />
                  {updateErrors.CurrentServiceNotes && (
                    <span className="helper text-danger text-left mt-0">
                      {updateErrors.CurrentServiceNotes}
                    </span>
                  )}
                </div>
                {(srvInterlDetail.IntervalType === "DATE" ||
                  srvInterlDetail.IntervalType === "DATE-UNITS") && (
                  <div className="col-12 service-group">
                    <label className="service-label">Service Date</label>
                    <DatePicker
                      className={`service-input${
                        updateErrors.LastServicedDate ? " error" : ""
                      }`}
                      selected={formValues.LastServicedDate}
                      dateFormat="dd MMM yyyy"
                      placeholderText="Choose new service date"
                      onChange={(date) =>
                        this.setFormValue("LastServicedDate", date)
                      }
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      minDate={srvInterlDetail.LastServicedDate.toDate()}
                      maxDate={new Date()}
                    />
                    {updateErrors.LastServicedDate && (
                      <span className="helper text-danger text-left mt-0">
                        {updateErrors.LastServicedDate}
                      </span>
                    )}
                  </div>
                )}
                {(srvInterlDetail.IntervalType === "UNITS" ||
                  srvInterlDetail.IntervalType === "DATE-UNITS") && (
                  <div className="col-12 service-group">
                    <label className="service-label">
                      New Service Unit Reading
                    </label>
                    <MaskedInput
                      className={`service-input${
                        updateErrors.LastServicedUnits ? " error" : ""
                      }`}
                      mask={(s) => Array.from(s).map(() => /([0-9]|\.)/i)}
                      guide={false}
                      inputMode="decimal"
                      onChange={(e) =>
                        this.setFormValue("LastServicedUnits", e.target.value)
                      }
                      placeholder="Enter new service unit reading"
                    />
                    {updateErrors.LastServicedUnits && (
                      <span className="helper text-danger text-left mt-0">
                        {updateErrors.LastServicedUnits}
                      </span>
                    )}
                  </div>
                )}
                <div className="col-12 service-group d-flex justify-content-end mt-3">
                  <button
                    className="btn btn-sm btn-light mr-2"
                    onClick={() => this.setState({ editSysService: false })}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => this.save(srvInterlDetail)}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
            {sysHistoryCollapse && (
              <a
                className="helper d-block mx-auto"
                onClick={() => this.showServiceHistory(srvInterlDetail.id)}
              >
                <u>Show service history</u>
              </a>
            )}
            <Collapse in={!sysHistoryCollapse}>
              <div>
                {!sysHistoryLoading && generalIntervalHistory ? (
                  <>
                    <hr />
                    <p className="service-data font-weight-bold mb-2">
                      Service History
                    </p>
                    {generalIntervalHistory.length &&
                      generalIntervalHistory.map((intervalHistory, i) => (
                        <p className="interval-history" key={i}>
                          {intervalHistory.ServiceNotes}
                          <span className="float-right ml-auto">
                            {moment(
                              intervalHistory.ServicedDate.toDate()
                            ).format("D MMM YYYY , h.mm A")}
                          </span>
                        </p>
                      ))}
                  </>
                ) : (
                  <Loading height="2rem" showLogo="false" />
                )}
              </div>
            </Collapse>
          </div>
        </Collapse>
        <hr />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.firestore.ordered.Users,
  generalIntervalHistory: state.firestore.ordered.GeneralIntervalHistory,
  currentApartment: state.apartment.currentApartment,
});

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(),
  withRouter
)(SystemUpdateCard);
