/* eslint-disable camelcase */
import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { withRouter, NavLink } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import "./sidebar.css";
import { actionTypes } from "redux-firestore";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      show: false,
      active: 0,
      resetPopOver: true,
      menuSettings: [],
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const { user, currentApartment } = this.props;
    const width = this.getWidth();
    if (width > 768) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }

    window.addEventListener("resize", this.reportWindowSize);
    document.addEventListener("click", this.handleClickOutside);

    if (!user.length) return;

    if (currentApartment) {
      this.fetchData(currentApartment);
    }
  }

  componentDidUpdate = (prevProps) => {
    const { user, MenuSettings, currentApartment, ApartmentAccessInfo } = this.props;
    if (user.length) {
      if (
        currentApartment &&
        prevProps.user.length &&
        currentApartment !== prevProps.currentApartment
      ) {
        this.fetchData(currentApartment);
      }
    }
    if(ApartmentAccessInfo.length !== prevProps.ApartmentAccessInfo.length){
      this.fetchData(currentApartment);
    }

    if (MenuSettings !== prevProps.MenuSettings) {
      this.setState({ menuSettings: MenuSettings });
    }
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  fetchData = (currentApartment) => {
    const { firestore, ApartmentAccessInfo, user } = this.props;

    let accessInfo = null;
    if(!ApartmentAccessInfo.length){
      return false
    }
    const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
     if(selectedApartment){
       accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
     }
    const { Roles, has_all_access } = accessInfo || {}

  //  console.log(Roles)
  //  console.log(currentApartment)
    // const { Roles, has_all_access } = userAccessInfo && userAccessInfo[0];
    const collection = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "MenuLevelSettings" }],
      storeAs: "MenuSettings",
    };
    if (!has_all_access && Roles && Roles.length) {
      collection.where = ["Roles", "array-contains-any", Roles];
    }

    if (this.unsubscribeMenuSettings) {
      this.unsubscribeMenuSettings();
    }
    this.unsubscribeMenuSettings = firestore.onSnapshot(collection);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.reportWindowSize);
  };

  reportWindowSize = (e) => {
    if (this.isMobile) {
      if (e.target.innerWidth > 768) {
        this.isMobile = false;
        this.setState({});
      }
    } else if (e.target.innerWidth <= 768) {
      this.isMobile = true;
      this.setState({});
    }
  };

  getWidth = () =>
    Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );

  toggleSidebar = (val) => {
    this.setState({
      open: val,
    });
  };

  isActive = (path, match, location) => !!(match || path === location.pathname);

  navigate = (value, data) => {
    const {
      setValue,
      history,
      sideBarOpen,
      openSideBar,
      setModule,
    } = this.props;
    if (this.isMobile) openSideBar(!sideBarOpen);
    this.setState({
      active: value,
      switchBottomBar: false,
    });
    if (data) setModule(data.MenuServiceRef);
  };

  handleClickOutside = (event) => {
    const { sideBarOpen, openSideBar } = this.props;
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target) &&
      !sideBarOpen &&
      this.isMobile
    ) {
      openSideBar(true);
    }
  };

  logOut = async () => {
    const {
      clearFirestore,
      firebase,
      resetCurrentApartment,
      resetDashboard,
    } = this.props;
    await clearFirestore();
    await firebase.logout();
    resetCurrentApartment();
    resetDashboard();
  };

  getMenuChildren = (settings) => {
    const menus = settings.filter((m) => m.Level === 1);
    return menus.map((menu) => {
      const children = [];

      for (const each of settings) {
        if (menu.MenuName === each.ParentName) {
          children.push(each);
        }
      }
      if (children && children.length) {
        return { ...menu, children };
      }
      return { ...menu };
    });
  };

  handleSidebarExpand = () => {
    const { sideBarOpen } = this.props;
    this.props.openSideBar(!sideBarOpen);
  };

  toggleSubMenu = (item) => {
    const copy = this.state.menuSettings.map((set, si) => {
      let opened;
      if (item && item.id === set.id) {
        opened = !set.opened;
      } else {
        opened = false;
      }
      return { ...set, opened };
    });
    this.setState({ menuSettings: copy });
  };

  renderItems = () => {
    const { open, active, menuSettings } = this.state;
    const MenuLevelSettings = this.getMenuChildren(menuSettings);

    const { history, currentDashboard, is_cons_user } = this.props;
    /* commented out skip settings route if is_cons_user 
    const routesSkip = ['/settings']   // routes which has no access for is_cons_user
    
    let menuLevelSettings = [...MenuLevelSettings]
    if(is_cons_user){
      menuLevelSettings = MenuLevelSettings.filter(route=> !routesSkip.includes(route.MenuRouting) )
    }
    */
    return (
      <div className="menu-items">
        <ul className="nav flex-column mt-3 pt-2">
          {currentDashboard === "local" &&
          MenuLevelSettings &&
          MenuLevelSettings.map((item, index) => (
              <li className="nav-item" key={item.id}>
                {!item.children || !item.children.length ? (
                  <NavLink
                    className={[
                      "nav-link",
                      item.MenuRouting === history.location.pathname
                        ? "active-nav"
                        : "",
                    ].join(" ")}
                    to={item.MenuRouting}
                    exact
                    onClick={() => {
                      this.navigate(index, item);
                      this.toggleSubMenu();
                    }}
                  >
                    <i className={`icon-${item.IconName}`} />
                    <span className="nav-text">{item.MenuName}</span>
                  </NavLink>
                ) : (
                  <>
                    <span
                      className={["nav-link", item.opened ? "opened" : ""].join(
                        " "
                      )}
                      role="button"
                      onClick={() => this.toggleSubMenu(item)}
                    >
                      <i className={`icon-${item.IconName}`} />
                      <span className="nav-text">
                        {item.MenuName}
                        <i
                          className="icon-chevron-down d-flex justify-content-end align-items-center ml-auto mr-0"
                          style={{ fontSize: 5 }}
                        />
                      </span>
                    </span>
                    <Collapse in={item.opened}>
                      <ul className="nav flex-column sub-menu">
                        {item.children &&
                          item.children.map((subitem) => (
                            <li className="nav-item" key={subitem.id}>
                              <NavLink
                                className={[
                                  "nav-link text-truncate",
                                  `${subitem.MenuRouting}?${encodeURI(
                                    subitem.MenuServiceRef
                                  )}` ===
                                  `${history.location.pathname}${history.location.search}`
                                    ? "active-nav"
                                    : "",
                                ].join(" ")}
                                to={`${subitem.MenuRouting}?${encodeURI(
                                  subitem.MenuServiceRef
                                )}`}
                                activeClassName="active-nav"
                                exact
                                onClick={() => {
                                  this.navigate(index, subitem);
                                }}
                              >
                                <i
                                  className={`icon-default-daily icon-${subitem.IconName}`}
                                />
                                <span className="nav-text">
                                  {subitem.MenuName}
                                </span>
                              </NavLink>
                            </li>
                          ))}
                      </ul>
                    </Collapse>
                  </>
                )}
              </li>
            ))}
          {currentDashboard === "executive" && this.renderExecutiveMenus()}
        </ul>
      </div>
    );
  };

  renderExecutiveMenus = () => {
    const { ConsViews = [], currentPathname } = this.props;
    const { is_cons_admin: isConsAdmin } = ConsViews[0] || {};
    return (
      <>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            className={[
              "nav-link",
              currentPathname === "/executive" ? "active-nav" : "",
            ].join(" ")}
            to="/executive/widget"
            activeClassName="active-nav"
            onClick={() => {
              this.navigate(1);
            }}
          >
            <span className="nav-text">Dashboard</span>
          </NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink
            className={[
              "nav-link",
              currentPathname === "/executive/properties" ? "active-nav" : "",
            ].join(" ")}
            to="/executive/properties"
            exact
            onClick={() => {
              this.navigate(2);
            }}
          >
            <span className="nav-text">Properties</span>
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className={[
              "nav-link",
              currentPathname === "/executive/tasks" ? "active-nav" : "",
            ].join(" ")}
            to="/executive/tasks"
            exact
            onClick={() => {
              this.navigate(3);
            }}
          >
            <span className="nav-text">Tasks</span>
          </NavLink>
        </li> */}
        {isConsAdmin && (
          <li className="nav-item">
            <NavLink
              className={[
                "nav-link",
                currentPathname === "/executive/settings/zones"
                  ? "active-nav"
                  : "",
              ].join(" ")}
              to="/executive/settings"
              activeClassName="active-nav"
              onClick={() => {
                this.navigate(4);
              }}
            >
              <span className="nav-text">Settings</span>
            </NavLink>
          </li>
        )}
      </>
    );
  };

  renderFooterItems = () => (
    <ul className="nav flex-column pb-3 footer">
      <li className="nav-item">
        <a className="nav-link" onClick={this.logOut}>
          <i className="icon-logout" />
          <span className="nav-text">Logout</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          href="https://trackbot.in/"
          target="_blank"
          className="nav-link tracbot-nav-link align-items-center"
        >
          <img
            alt="logo"
            className="logo"
            src={require("../../../assets/images/logoOnly.png")}
          />
          <span className="my-0 nav-text align-items-center">
            <span className="pt-1">TrackBot</span>
            <div className="url-arrow-container d-flex align-items-center ml-auto mr-0">
              <i className="url-arrow icon-arrow-right " />
            </div>
          </span>
        </a>
      </li>
    </ul>
  );

  getThemeColor = () => {
    const { AppartmentDetails, consolidated, currentDashboard } = this.props;
    
    if (AppartmentDetails && AppartmentDetails[0].Header_Theme_Color) {

      if(currentDashboard === "executive" && consolidated){
        return consolidated[0].Header_Theme_Color
      }
      return AppartmentDetails[0].Header_Theme_Color;
    }
    return null;
  };

  render() {
    const { MenuSettings, sideBarOpen } = this.props;
    if (!MenuSettings) return null;

    return (
      <div
        id="sidebar"
        ref={this.wrapperRef}
        className={[
          !sideBarOpen ? "open" : "",
          this.isMobile ? "mobile" : "",
        ].join(" ")}
        style={{ backgroundColor: this.getThemeColor() }}
      >
        {this.isMobile ? (
          <a
            type="presentation"
            className="mobile-back"
            onClick={this.handleSidebarExpand}
          >
            <i className="icon-arrow-left" />
          </a>
        ) : (
          <button
            type="presentation"
            className="expand-switch"
            id="back_icon"
            onClick={this.handleSidebarExpand}
          >
            <i className="icon-chevron-right" />
          </button>
        )}
        {MenuSettings && this.renderItems()}
        {this.renderFooterItems()}
      </div>
    );
  }
}

Sidebar.defaultProps = {
  user: [{ Email: "" }],
};

const mapStateToProps = (state) => ({
  ConsViews: state.firestore.ordered.ConsViews,
  user: state.firestore.ordered.Users,
  // userAccessInfo: state.firestore.ordered.UserAccessInfo,
  MenuSettings: state.firestore.ordered.MenuSettings,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  sideBarOpen: state.navigation.sideBarOpen,
  currentApartment: state.apartment.currentApartment,
  currentDashboard: state.dashboard.currentDashboard,
  consolidated: state.firestore.ordered.consolidated,
  ApartmentAccessInfo: state.apartment.ApartmentAccessInfo,
  // eslint-disable-next-line no-prototype-builtins
  is_cons_user: state.firestore.ordered.ConsViews && state.firestore.ordered.ConsViews[0] 
    ? state.firestore.ordered.ConsViews[0].is_cons_user 
    : null,
});

const mapDispatchToProps = (dispatch) => ({
  clearFirestore: () => dispatch({ type: actionTypes.CLEAR_DATA }),
  resetCurrentApartment: () => dispatch({ type: "RESET_APARTMENT" }), // reset apartment to initial state
  resetDashboard: () => dispatch({ type: "RESET_DASHBOARD" }),
  setModule: (name) => dispatch({ type: "UPDATE_MODULE", payload: name }),
  openSideBar: (value) => dispatch({ type: "SIDEBAR_OPEN", payload: value }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(),
  withRouter
)(Sidebar);
