import React, { Component } from 'react'
import * as moment from 'moment';

export class TextInputWidget extends Component {
  constructor(props) {
    super(props);
    const { reading } = props;
    this.state = { value: reading.ParamValueRaw || reading.ParamValue };
  }

  onChangeVal = (val) => {
    const { reading, onChange } = this.props
    this.setState({value: val});
    onChange({ id: reading.id, [`${reading.hasOwnProperty('ParamValueRaw') ? `ParamValueRaw` : `ParamValue`}`]: val });
  }

  getLastUpdate = (value) => {
    let lastUpdateDate = new Date(value.toDate());
    const period = moment(new Date()).diff(moment(lastUpdateDate), 'months');
    if (period < 1) lastUpdateDate = moment(lastUpdateDate).fromNow();
    else lastUpdateDate = moment(lastUpdateDate).format('D MMM YYYY');
    return lastUpdateDate;
  }

  render() {
    const { reading, edit, isMobile } = this.props;
    const { value } = this.state;

    return (
      <p className="mb-2">
        <span className={['badge-indicator', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')}></span>
        <span style={{color:'black'}}>{ !edit ? reading.ParamName : reading.ParamNameUpd }</span>
        <span style={{fontSize: isMobile ? 9 : 11, marginLeft:7, marginTop: isMobile ? 2 : 3}}>{ this.getLastUpdate(reading.LastUpdatedDate) }</span>
        { !edit || reading.UpdateLocked ? 
          <span className={['float-right', 'mb-0', 'ml-auto', 'text-indicator', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')}>
            {reading.ParamValue}
            { !reading.UpdateLocked && <i className="icon-lock ml-2"></i> }
          </span> :
          <span className="ml-auto edit-body">
            <input className={['text-indicator', 'border-indicator', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')} size="7" value={value} onChange={e => this.onChangeVal(e.target.value)} />
          </span>
        }
      </p>
    )
  }
}

export default TextInputWidget
