import React, { useEffect, useState } from 'react';
import { Modal, Carousel } from 'react-bootstrap';

const PhotoCarousel = ({ isImageModalOpen, onHide, images = [], activeIndex }) => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(()=> {
        setIndex(activeIndex);
    }, [activeIndex])

    return (
        <Modal centered show={isImageModalOpen} onHide={onHide}>
            <Modal.Header className="tb-modal-header pt-2 pb-0" closeButton></Modal.Header>
            <Modal.Body className="pt-2 pb-3">
            <Carousel activeIndex={index} onSelect={handleSelect} indicators={false} interval={false}
                prevIcon={<i className="icon-chevron-right" />}
                nextIcon={<i className="icon-chevron-right rotate-180" />}>
            { images.map((img, id) => (
                <Carousel.Item key={`task_crousel_${id}`}>
                <img className="img-fluid" src={img} alt=""/>
                </Carousel.Item>
            ))}
            </Carousel>
            </Modal.Body>
        </Modal>
    )
}

export default PhotoCarousel;
