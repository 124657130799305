/* eslint-disable camelcase */
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import DatePicker from "react-datepicker";
import './Adhoc.css';
import Loading from '../common/Loading/Loading';
import createHistory from "../../history";

const moment = require('moment');

class AdHocTasks extends Component {
  constructor() {
    super();
    this.state = {
      active: 0,      
      searchValue: '',
      filterSearchValue: '',
      isPaneOpen: false,
      startDay: null,
      endDay: null,
      files: null,
      loadFile: false,
      photoURL: require('../../assets/images/default_dp.svg')
    }
    this.groupBy = 'TaskCreatedDate'
    this.Title = null;
    this.taskType = null;
    this.Description = null;
    this.subMenuRef = React.createRef();
    this.subMenuIconRef = React.createRef();
  }

  componentDidMount = () => {
    const { user } = this.props;
    const { PhotoURL } = user[0];
    
    this.slider = React.createRef();
    this.systemSlider = React.createRef();
    this.activitiesSlider = React.createRef();       
    const width = this.getWidth();
    if (width > 768) {
      this.isMobile = false
    }
    else {
      this.isMobile = true
    }
    if (this.container) {
      this.setState({ attWidth: this.container.offsetWidth })
    }
    window.addEventListener('resize', this.reportWindowSize);
    document.addEventListener('click', this.handleClickOutside);
    this.setState({})

    if (user && PhotoURL) {
      this.getPhotoURL(PhotoURL);
    }
  }

  handleClickOutside = event => {
    const { isModuleSidebarOpen } = this.state;
    if (this.subMenuRef.current && !this.subMenuRef.current.contains(event.target)
      && !this.subMenuIconRef.current.contains(event.target) && isModuleSidebarOpen && this.isMobile) {
      this.setState({ isModuleSidebarOpen: false })
    }
  }

  getWidth = () => Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  )

  componentDidUpdate = () => {
    const { user, setErrorMessage, clearFirestore, firebase } = this.props;
    const { attWidth } = this.state;
    if (user.length !== 0) {      
      if (!attWidth) {
        if (this.container) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ attWidth: this.container.offsetWidth })
        }
      }      
    }
    else {
      setErrorMessage('Please signup against an Apartment.', 'Not Registered')
      clearFirestore();
      firebase.logout();
    }
  }

  reportWindowSize = (e) => {
    if (this.isMobile) {
      if (e.target.innerWidth > 768) {
        this.isMobile = false;
        this.setState({})
      }
    }
    else if (e.target.innerWidth <= 768) {
      this.isMobile = true;
      this.setState({})
    }
  }  

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.reportWindowSize);
    document.removeEventListener('click', this.handleClickOutside);
  }

  getPhotoURL = async path => {
    const { firebase } = this.props;
    const storage = firebase.storage();
    const url = await storage.ref().child(path).getDownloadURL();
    this.setState({ photoURL: url });
  }

  setCard = (index) => {
    const border = this.data[index].data.CardColor.toLowerCase();
    return `adhocCard border-indicator ${border}`
  }

  switchTasks = (index) => {
    const { active } = this.state;
    const condition = this.data[index].data.CurrentStatus === 'Completed'
      || this.data[index].data.CurrentStatus === 'Abandoned';
    if (active === 0 && !condition) {
      return true
    }
    if (active === 1 && condition) {
      return true
    }
    return false
  }

  onTextChange = (e) => {
    this.setState({ searchValue: e.target.value })
  }

  filterData = () => {
    const { searchValue, filterSearchValue } = this.state;
    const { location } = this.props;
    const ServiceName = decodeURI(location.search.substring(1)) || '';
    for (let i = 0; i < this.finalData.length; i += 1) {
      const value = this.finalData[i].data;
      const keys = this.finalData[i].key;
      if (((value.TaskType && value.TaskType.substring(0, searchValue.length).toLowerCase() === searchValue.toLowerCase())
        || (value.TaskTitle && value.TaskTitle.substring(0, searchValue.length).toLowerCase() === searchValue.toLowerCase())
        || (value.CurrentStatus && value.CurrentStatus.substring(0, searchValue.length).toLowerCase() === searchValue.toLowerCase()))
        && (value.CurrentStatus && value.CurrentStatus.substring(0, filterSearchValue.length).toLowerCase() === filterSearchValue.toLowerCase()
        && (value.TaskType === ServiceName || ServiceName === '') )
      ) {
        const data = {};
        data.data = value
        data.key = keys
        this.data.push(data);
        this.progressData = this.data.filter(e=>e.data.CurrentStatus!=='Abandoned'&&e.data.CurrentStatus!=='Completed'&&(e.data.TaskType===ServiceName||ServiceName===''))
        this.completedData = this.data.filter(e=>(e.data.CurrentStatus==='Abandoned'||e.data.CurrentStatus === 'Completed')&&(e.data.TaskType===ServiceName||ServiceName===''))
      }
    }
  }

  cutTitles = (data, length) => {
    if (data) {
      if (data.length > length) {
        return `${data.substring(0, length)}...`
      }
      return data
    }
    return null;
  }

  onGroupBy = async (e) => {
    this.groupBy = e.target.value;
    this.setState({})
  }

  compare = (a, b) => {
    if (a[this.groupBy] < b[this.groupBy]) {
      return -1;
    }
    if (a[this.groupBy] > b[this.groupBy]) {
      return 1;
    }
    return 0;
  }

  onTaskTypeChange = (e) => { this.taskType = e.target.value }

  OnStartDayChange = (startDay) => { this.setState({ startDay }) }

  OnEndDayChange = (endDay) => { this.setState({ endDay }) }

  fileToArray = (fileList) => Array.prototype.slice.call(fileList)

  removeItem = (index) => {
    const { files } = this.state;
    const _files = files;
    _files.splice(index, 1)
    this.setState({ files: _files });
  }

  selectFile = (e) => {
    this.setState({ loadFile: true });
    const { files } = this.state;
    const newFiles = this.fileToArray(e.target.files);
    if (!files) {
      this.setState({ files: newFiles });
      this.setState({ loadFile: false });
      return;
    }
    const joined = this.fileToArray(files).concat(this.fileToArray(newFiles));
    this.setState({
      files: joined,      
    });
  }

  onCancel = () => {    
    this.setState({ isPaneOpen: false, loadFile: false })
    this.clearData()    
  }

  uploadData = async (status, index) => {
    const { firestore, currentApartment, user, firebase } = this.props;
    const { DisplayName, UID } = user[0];
    const { startDay, endDay, files } = this.state;
    if (startDay && endDay && this.Title) {
      this.setState({ saving: true, buttonPressed: index })

      await firestore.collection("apartments").doc(currentApartment).collection('AdHocTaskManager').add({}).then(async doc => {
        if (files) {
          const filesPath = `${currentApartment}/Ad-Hoc-Tasks/${doc.id}`;
          await firebase.uploadFiles(filesPath, files).then();
        }
        await firestore.collection("apartments").doc(currentApartment).collection('AdHocTaskManager').doc(doc.id).update({
          TaskTitle: this.Title,
          TaskDescription: this.Description,
          TaskStartDate: new Date(startDay),
          TaskEndDate: new Date(endDay),
          TaskCreatedBy: DisplayName,
          TaskLastUpdatedBy: DisplayName,
          TaskCreatedDate: new Date(),
          TaskType: this.taskType,
          UserAction: status,
          TaskLastUpdatedByUID: UID,          
        });
        this.setState({ saving: false, buttonPressed: -1 });
        this.clearData();        
        this.setState({ isPaneOpen: false, loadFile: false })
      })
    }    
  }

  clearData = () => {
    this.taskType = null
    this.dropdownValues = []
    this.Title = null;
    this.Description = null;    
    this.setState({
      startDay: null,
      endDay: null,
      files: null,
    })
  }

  showSideNav = () => {
    setTimeout(() => this.props.openSideBar(false));
  }

  getThemeColor = () => {
    const {AppartmentColor } = this.props;
    if(this.isMobile && AppartmentColor ) {
      return AppartmentColor
    }
    return null
  }

  showMobileNav = () => {
    const { filterSearchValue, location } = this.props;
    const { active } = this.state;
    const ServiceName = decodeURI(location.search.substring(1));

    if (this.isMobile) {
      return (
        <>
          <div id="dashboard-nav" className="d-flex flex-column w-100" style={{ position: 'fixed',backgroundColor:this.getThemeColor() }}>
            <div className="d-flex justify-content-between w-100 align-items-center">
              <div className="div">
                <p className="text-muted mb-0" style={{lineHeight: 1, fontSize: 14}}>To Do</p>
                <div className='adhocDiv'>{ServiceName || 'All'}</div>
              </div>
              {this.finalData.length ?
                <div className="d-flex pr-1" style={{ paddingTop: 3 }}>
                  <div>
                    <button type="submit" className="d-flex justify-content-center align-items-center"
                      style={{ borderRadius: 20, backgroundColor: 'transparent', border: 'solid 1px #999999', width: 28, height: 28, marginBottom: -30 }}>
                      <i className="icon-sort" style={{ fontSize: 12, color: '#999999' }} />
                    </button>
                    <select className="adhocDropdown" onChange={this.onGroupBy}>
                      <option value="" disabled selected hidden>Sort By</option>
                      <option value="TaskCreatedDate" >Created Date</option>
                      <option value="TaskStartDate" >Start Date</option>
                      <option value="TaskEndDate" >End Date</option>
                    </select>
                  </div>
                  <div>
                    <button type="submit" className="d-flex justify-content-center align-items-center p-1"
                      style={{ borderRadius: 20, backgroundColor: 'transparent', border: 'solid 1px #999999', width: 28, height: 28, marginBottom: -30 }}>
                      <i className="icon-filter" style={{ fontSize: 12, color: '#999999' }} />
                    </button>
                    <select className="adhocDropdown" onChange={(e) => this.setState({ filterSearchValue: e.target.value })} value={filterSearchValue}>
                      <option value="0" selected hidden />
                      <option value="" >All</option>
                      {this.masterdata && this.masterdata.AllStatus.map(item => (
                        <option key={Math.random()} value={item} >{item}</option>
                      ))}
                    </select>
                  </div>
                  <button className='d-flex justify-content-center align-items-center' type="submit"
                    onClick={() => this.setState({ isPaneOpen: true })}
                    style={{
                      backgroundColor: '#558dca',
                      border: 0, outline: 'none',                      
                      marginLeft: 10,
                      width: 28, height: 28, borderRadius: 20
                    }}                  
                  >
                    <img alt="task" src={require('../../assets/images/addTask.png')} style={{ width: 17, height: 12, objectFit:'contain' }} />
                  </button>
                </div> : null}
            </div>
            <div className="d-flex align-items-baseline" style={{ marginTop: -5 }}>
              <a className="menu d-flex align-items-center pl-0" style={{ padding: '0.75rem' }}
                onClick={() => this.setState({ isModuleSidebarOpen: true })} ref={this.subMenuIconRef}
              ><i className="icon-hamburger" style={{ fontSize: '0.75rem' }} /></a>
              <ul className="d-flex mb-0 pl-0">
                <a className={`d-flex justify-content-between align-items-center${active === 0 ? ` active` : ``}`}
                  style={{ fontSize: '0.875rem', padding: '0.75rem', color: active === 1 && '#838383', fontWeight: active === 0 && 'bold' }}
                  onClick={() => this.setState({ active: 0, filterSearchValue: '' })}
                >In Progress
                </a>
                <a className={`d-flex justify-content-between align-items-center${active === 1 ? ` active` : ``}`}
                  style={{ fontSize: '0.875rem', padding: '0.75rem', color: active === 0 && '#838383', fontWeight: active === 1 && 'bold' }}
                  onClick={() => this.setState({ active: 1, filterSearchValue: '' })}
                >Completed
                </a>
              </ul>
            </div>
          </div>
        </>
      );
    }
  }

  onClickSideItem = (tab) => {
    this.setState({ active: tab, ModuleName: '', isModuleSidebarOpen: false, filterSearchValue: '' });
  }

  showComponentSideBar() {
    const { isModuleSidebarOpen, active } = this.state;
    if (this.isMobile) {
      return (
        <div className={['component-sidebar', isModuleSidebarOpen && 'open'].join(' ')}
          ref={this.subMenuRef} style={{ marginTop: 10 }}>
          <a className="closeMenu" onClick={() => this.setState({ isModuleSidebarOpen: false })}><i className="icon-close" /></a>
          <ul className="list-group">
            <a className={`list-group-item d-flex justify-content-between align-items-center${active === 0 ? ` active` : ``}`}
              onClick={() => this.onClickSideItem(0)}>In Progress
                    </a>
            <a className={`list-group-item d-flex justify-content-between align-items-center${active === 1 ? ` active` : ``}`}
              onClick={() => this.onClickSideItem(1)}>Completed
                    </a>
          </ul>
        </div>
      )
    }
  }

  render() {
    const { active, filterSearchValue, isPaneOpen, startDay, endDay, files, buttonPressed } = this.state;
    const { user, AdHocTaskManager, location, ApartmentAccessInfo, currentApartment } = this.props;

    let accessInfo = null;
    if(ApartmentAccessInfo.length){
      const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
     if(selectedApartment){
       accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
       console.log(' accessInfo = ', accessInfo);
     }
    }
    const { has_ad_hoc_task_access } = accessInfo || {}


    const ServiceName = decodeURI(location.search.substring(1));
    let addTask = true;
    let dropDown;
    if (AdHocTaskManager) {
      dropDown = AdHocTaskManager._master_data.TaskTypes;
    }
    // if (UserAccessInfo && user) {
    //   addTask = !UserAccessInfo[user[0].id].has_ad_hoc_task_access;
    // }
    if (accessInfo) {
      addTask = has_ad_hoc_task_access;
      console.log('Task addition = ', addTask);
      //alert(addTask);
    }
    else {
      return (
        <Loading showLogo="false" />
      )
    }
    
    this.finalData = [];
    if (AdHocTaskManager)
      Object.keys(AdHocTaskManager).map(async (key) => {
        const item = {}
        if (key !== '_master_data' && AdHocTaskManager[key].CurrentStatus) {
          item.data = AdHocTaskManager[key]
          item.key = key
          this.finalData.push(item)
        }
        if (key === '_master_data') {
          this.masterdata = AdHocTaskManager[key]
        }
      })

    this.data = [];
    this.filterData();
    this.data.sort((a, b) => (a.data.TaskTitle || '').localeCompare(b.data.TaskTitle));
    return (
      <div id="adhocTask" style={{ margin: 15, marginTop: 37, }}>
        {this.showMobileNav()}
        {this.showComponentSideBar()}        
        {!this.isMobile ?
          <div className="d-flex justify-content-between align-items-center flex-wrap mb-2 pr-5 ml-3" >
            <div className="">
              <div className="activeAdhocButton d-flex align-items-center">
                <a style={{fontSize: 11, marginRight: 8, cursor: 'pointer'}} onClick={() => createHistory.replace('/adhoc')}><i className="icon-arrow-left" /></a> 
                {ServiceName || 'All'}
              </div>
              <p className="text-muted mb-0">
                <u>To Do</u> / {ServiceName || 'All'}
              </p>
            </div>
            {this.finalData.length ?
              <div className="d-flex justify-content-between ml-auto">
                <div className="mr-3">
                  <button type="submit" className="d-flex justify-content-center align-items-center p-1"
                    style={{ borderRadius: 3, backgroundColor: '#fafafa', cursor: 'pointer', border: 'solid 1px #999999', width: 35, height: 35, marginBottom: -34 }}>
                    <img alt="sort" src={require('../../assets/images/sort.svg')} style={{ width: 17, height: 12 }} />
                  </button>
                  <select className="adhocDropdown mb-2" onChange={this.onGroupBy}>
                    <option value="" disabled selected hidden>Sort By</option>
                    <option value="TaskCreatedDate" >Created Date</option>
                    <option value="TaskStartDate" >Start Date</option>
                    <option value="TaskEndDate" >End Date</option>
                  </select>
                </div>

                <div>
                  <button type="submit" className="d-flex justify-content-center align-items-center p-1"
                    style={{ borderRadius: 3, backgroundColor: '#fafafa', cursor: 'pointer', border: 'solid 1px #999999', width: 35, height: 35, marginBottom: -34 }}>
                    <img alt="filter" src={require('../../assets/images/filter.svg')} style={{ width: 17, height: 12 }} />
                  </button>
                  <select className="adhocDropdown mb-2" onChange={(e) => this.setState({ filterSearchValue: e.target.value })} value={filterSearchValue}>
                    <option value="0" disabled selected hidden />
                    <option value="" >All</option>
                    {this.masterdata && this.masterdata.AllStatus.map(item => (
                      <option key={Math.random()} value={item} >{item}</option>
                    ))}
                  </select>
                </div>
                <button className='ml-3' type="submit"
                  onClick={() => this.setState({ isPaneOpen: true })}
                  style={{ backgroundColor: '#558dca', border: 0, padding: 0, outline: 'none', width: 120, height: 35, borderRadius: 3 }} disabled={addTask}>
                  <div className="addTaskButton" >
                    <img alt="task" src={require('../../assets/images/addTask.svg')}
                      style={{ width: 15, height: 12, marginTop: -2, marginRight: 3 }}
                    />
                    ADD A TASK
                </div>
                </button>
              </div>
            : null}
          </div>
          : null}

        <div id="taskList" className="row ml-0 mr-0" style={{ marginTop: this.isMobile && '10rem', flexWrap:'nowrap' }}>
          {!this.isMobile &&
            <div className="col-md-3 col-3 d-flex d-md-block mr-2" style={{ marginTop: 10, minWidth: 155 }}>
              <ul className="list-group" ref={this.componentNameRef}>
                <a className={`list-group-item d-flex justify-content-between align-items-center${active === 0 ? ` active` : ``}`}
                  onClick={() => this.setState({ active: 0, filterSearchValue: '' })}
                >In Progress
                </a>
                <a className={`list-group-item d-flex justify-content-between align-items-center${active === 1 ? ` active` : ``}`}
                  onClick={() => this.setState({ active: 1, filterSearchValue: '' })}
                >Completed
                </a>
              </ul>
            </div>}
          {this.data.length && ((this.progressData.length !==0 && active === 0) || (this.completedData.length !==0 && active === 1))  ?
          <div className={`listCards ${this.isMobile ? 'col-12' : 'col-9'}`} style={{ marginTop: 10 }}>
            {
              this.data.map((item, index) => (
                this.switchTasks(index) && item.data.TaskTitle && (
                  item.data.CurrentStatus ?
                    <button type="submit" className={this.setCard(index)}
                      onClick={() => createHistory.push(`/adhoc/task?${item.key}`)}
                      style={{ borderBottom: item.data.CardColor === 'Amber' ? '5px solid #ffbf00' : `5px solid ${item.data.CardColor}` }}>
                      <div className="d-fle flex-column justify-content-between">
                        <div className="d-flex flex-column align-items-start">
                          <div className="d-flex justify-content-between w-100">
                            <div className="adhocCurrentStatus py-1"
                              style={{ color: item.data.CardColor === 'Amber' ? '#ffbf00' : item.data.CardColor }}>
                              {item.data.CurrentStatus.toUpperCase()}
                            </div>
                          </div>
                          <div className="adhocTaskTitle mt-2">{this.cutTitles(item.data.TaskTitle, 20)}</div>
                          <div className="adhocTaskType mb-0" style={{ height: 20 }}>{item.data.TaskType}</div>
                        </div>
                        <div className="d-flex text-left adhocTaskDescription">
                          {this.cutTitles(item.data.TaskDescription, 25)}
                        </div>
                        <div className="adhocTaskDate d-flex justify-content-between mt-2">
                          <div className="d-flex align-items-center w-100">
                            <img style={{ marginRight: 8, height: 13 }} alt="time" src={require('../../assets/images/hourglassstart.png')} />
                            <div style={{ marginTop: 2, fontSize: 12, color: "#9d9d9d" }}>{moment(item.data.TaskStartDate.toDate()).format('DD MMM YYYY')}</div>
                          </div>
                          <div className="d-flex align-items-center w-100">
                            <img style={{ marginRight: 8, height: 13 }} alt="time" src={require('../../assets/images/hourglassend.png')} />
                            <div style={{ marginTop: 2, fontSize: 12, color: "#9d9d9d" }}>{moment(item.data.TaskEndDate.toDate()).format('DD MMM YYYY')}</div>
                          </div>
                        </div>
                      </div>
                    </button>
                    : null
                )))}
          </div> : null}

          {!this.data.length
            || (active === 0 && (filterSearchValue === 'Abandoned' || filterSearchValue === 'Complete'))
            || (filterSearchValue && active === 1 && (filterSearchValue !== 'Abandoned' && filterSearchValue !== 'Complete'))
            || (this.progressData.length ===0 && active === 0) || (this.completedData.length ===0 && active === 1) 
            ?
            <div className="noTasksDiv d-flex justify-content-center align-items-center flex-column w-100">
              <img src={require('../../assets/images/noTasks.png')} alt="no-tasks" />
              <div className="noTasks">No Tasks Added</div>
              <div className="noTaskDesc">You haven’t added any tasks here</div>
              {!this.finalData.length ?
                <button type="submit"
                  onClick={() => this.setState({ isPaneOpen: true })}
                  style={{ backgroundColor: '#558dca', border: 0, padding: 0, outline: 'none', width: 120, height: 35, borderRadius: 3, marginTop: 30 }} disabled={addTask}>
                  <div className="addTaskButton" >
                    <img alt="task" src={require('../../assets/images/addTask.svg')}
                      style={{ width: 15, height: 12, marginTop: -2, marginRight: 3 }}
                    />
                    ADD A TASK
                </div>
                </button>
                : null}
            </div>
            : null}
        </div>

        <SlidingPane
          className='some-custom-class'
          overlayClassName='some-custom-overlay-class'
          isOpen={isPaneOpen}
          width='400px'
          onRequestClose={() => { this.setState({ isPaneOpen: false, loadFile: false }); }}>
          <div className="d-flex justify-content-between">
            <div className="addTaskHeading">Add a New Task</div>
            <button className="close" onClick={() => this.setState({ isPaneOpen: false, loadFile: false })} type="submit">
              <img src={require('../../assets/images/close.png')} alt="cal" style={{ height: 16, width: 16 }} />
            </button>
          </div>
          <div className="addTaskSubHeadings addTaskTitle mt-3">Title</div>
          <input className="addTaskInputs" onChange={(e) => { this.Title = e.target.value }} />

          <div className="addTaskSubHeadings mt-3">Task Type</div>
          <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            {AdHocTaskManager &&
              <select id="Adhoc" className="modalDropdown" onChange={this.onTaskTypeChange}                
                style={{ width: 325, borderBottom: '1px solid #D5D5D5' }}
              >
                <option value="" selected disabled hidden>Choose Any</option>
                {dropDown.map(value => (
                  <option key={value} value={value} >{value}</option>
                ))}
              </select>
            }
          </div>

          <div className="addTaskSubHeadings mt-3">Description</div>
          <input className="addTaskInputs" onChange={(e) => { this.Description = e.target.value }} />

          <div>
            <div className="addTaskSubHeadings mt-3">Start Date</div>
            <DatePicker
              className="addTaskCalendarDatePicker"
              placeholderText="Select Start Date"              
              showMonthDropdown
              showYearDropdown
              selected={startDay}
              maxDate={endDay && endDay}
              onChange={this.OnStartDayChange}
              dropdownMode="select"
              popperPlacement="top"            
            />
          </div>
          <div>
            <div className="addTaskSubHeadings mt-3">End Date </div>
            <DatePicker
              className="addTaskCalendarDatePicker"
              placeholderText="Select End Date"
              selected={endDay}
              minDate={startDay && startDay}
              onChange={this.OnEndDayChange}
              popperPlacement="top"
              popperModifiers={{
                flip: {
                  behavior: ["top"] // don't allow it to flip to be above
                },
                preventOverflow: {
                  enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                  enabled: false // turn off since needs preventOverflow to be enabled
                }
              }
              }
            />
          </div>

          {files && files.map((item, index) => (
            <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
              <div className="fileItems">{item.name}</div>
              <button type="submit" style={{ color: 'red', border: 0, backgroundColor: 'transparent' }} onClick={() => this.removeItem(index)}>
                <img src={require('../../assets/images/close.png')} alt="cal" style={{ height: 17, width: 17 }} />
              </button>
            </div>
          ))}          
          <div className="mt-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
            <div className="cabinet">
              <input
                type="file"
                name="avatar"
                accept="*/*"
                onChange={this.selectFile}
                multiple
              />
            </div>
            <div className="addFileDiv d-flex align-items-center justify-content-center" style={{ marginLeft: -162 }} >
              <img src={require('../../assets/images/upload.png')} alt="upload" className="addFile" style={{objectFit:'contain'}} />
            </div>
            <div className="uploadFile d-flex align-items-center justify-content-center">{(files && files.length !== 0) ? 'Upload Another File' : 'Upload File'}</div>
          </div>

          <div className="d-flex justify-content-between">

            {AdHocTaskManager && AdHocTaskManager._master_data.NextState.map((item, index) => (
              <div>
                {item.ButtonName === 'Submit' ?
                  <button
                    type="submit"
                    id="cancel"
                    className="cancel mr-2"
                    onClick={this.onCancel}
                    style={{ color: '#3B3B3B', backgroundColor: 'white' }}>
                    Cancel
                  </button>
                  : null
                }
                <button
                  type="submit"
                  className={item.ButtonName === 'Submit' ? 'save' : 'saveDraft pl-0'}
                  onClick={() => this.uploadData(item.ButtonName, index)}
                  style={{ border: 0, borderRadius: 3 }}>
                  {buttonPressed !== index ?                     
                    item.ButtonName
                    : (
                      <span
                        className="spinner-border spinner-border-sm text-light"
                        role="status"
                        aria-hidden="true"
                      />
                    )
                  }
                </button>
              </div>
            ))}
          </div>
          <br />
        </SlidingPane>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  // UserAccessInfo: state.firestore.data.UserAccessInfo,
  AdHocTaskManager: state.firestore.data.AdHocTaskManager,
  currentApartment: state.firestore.ordered.Users && state.apartment.currentApartment,
  user: state.firestore.ordered.Users,
  // UserRoles: state.firestore.ordered.UserAccessInfo[0].Roles,
  AppartmentColor: state.firestore.ordered.AppartmentDetails[0].Header_Theme_Color,
  ApartmentAccessInfo: state.apartment.ApartmentAccessInfo
});

const mapDispatchToProps = dispatch => ({
  showModal: () => dispatch({
    type: "SHOW_MODAL",
  }),
  openSideBar: (value) => dispatch({ type: 'SIDEBAR_OPEN', payload: value })
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(), firebaseConnect(),
  withRouter
)(AdHocTasks);
