/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import ChartArea from "../../Common/Charts/ChartArea/ChartArea";
import Button, { ViewAll } from "../../Common/WidgetHeader/Button/Button";
import WidgetHeader from "../../Common/WidgetHeader/WidgetHeader";
import createHistory from "../../../../../history";
// import createHistory from "../../../../../history";
const classNames = require("classnames");

const ManpowerIssues = (props) => {
  const { classValue, editMode, toggle, show = false, order, consolidated, isLoading, firestore, manpower_issues_summary_ed, selectedZone } = props;
  const [isVisible, setIsVisible] = useState(show);
  const [config, setConfig] = useState(null);
  const [manpowerSummary, setManpowerSummary] = useState([]);
  React.useEffect(() => {
    toggle("Manpower Issues", show);
  }, []);
  const handleChange = () => {
    setIsVisible(!isVisible);
    toggle("Manpower Issues", !isVisible);
  };

  React.useEffect(() => {
    if (consolidated && consolidated.length && !isLoading)
      // Fetch config like Label for graph
      firestore
        .get({
          collection: "consolidated",
          doc: consolidated[0].id,
          storeAs: "manpower_issues_summary_ed",
        })
        .then((doc) => {
          const {StatusToLabel : Label} = doc.data()
          let StatusToLabel = ''                              
          if(Label.hasOwnProperty("ManpowerIssues_ED")){ 
             StatusToLabel = Label.ManpowerIssues_ED
          } else if(Label.hasOwnProperty("Default")){
              StatusToLabel = Label.Default
          }
          // console.log('StatusToLabel :',StatusToLabel)
          setConfig({ StatusToLabel, route: "/executive/widget/manpower" });
        });
  }, [consolidated, isLoading]);


  React.useEffect(() => {
    // Last 7 Days from yesterday
    const dateTo = moment().toDate();
    const dateFrom = moment().subtract(7, "d").toDate();
    const dateToString = moment();
    const dateFromString = moment().subtract(7, "d");
    const todos = [];
    while (dateFromString.isBefore(dateToString)) {
      todos.push({ date: dateFromString.format("DD/MM") });
      dateFromString.add(1, "days");
    }
    if (manpower_issues_summary_ed && consolidated && consolidated.length) {
      firestore
        .get({
          collection: "consolidated",
          doc: consolidated[0].id,
          subcollections: [
            {
              collection: "ManpowerIssues_ED",
              doc: "manpower_issues_summary_ed",
              subcollections: [
                {
                  collection: "manpower_issues_summary_details_ed", 
                  where: [
                    ["Date", ">=", dateFrom],
                    ["Date", "<=", dateTo],
                  ],
                },
              ],
            },
          ],
          storeAs: "man_summary_details_ed",
        })
        .then((querySnapshot) => {
          
          // const {
          //   StatusToLabel: { Amber, Red },
          // } = managers_todo_summary_ed[0] || {
          //   StatusToLabel: { Amber: "N/A", Red: "N/A" },
          // };
          // debugger;
          const {StatusToLabel : Label} = manpower_issues_summary_ed[0] || {}
          let StatusToLabel = ''
          if(Label && Label.hasOwnProperty("ManpowerIssues_ED")){
             StatusToLabel = Label.ManpowerIssues_ED
          }else if(Label && Label.hasOwnProperty("Default")){
              StatusToLabel = Label.Default
          }
          const { Amber, Red } = StatusToLabel || {Amber: "N/A", Red: "N/A"}
          querySnapshot.forEach((doc) => {
            
            const findIndex = todos.findIndex(
              ({ date }) =>
                date === moment(doc.data().Date.toDate()).format("DD/MM")
            );
            if (selectedZone && selectedZone !== "All") {
              todos[findIndex] = {
                date: moment(doc.data().Date.toDate()).format("DD/MM"),
                [Amber]:
                  doc.data().ZoneIssues && doc.data().ZoneIssues[selectedZone]
                    ? doc.data().ZoneIssues[selectedZone].Amber
                    : 0,
                [Red]:
                  doc.data().ZoneIssues && doc.data().ZoneIssues[selectedZone]
                    ? doc.data().ZoneIssues[selectedZone].Red
                    : 0,
              };
            }

            if (!selectedZone || selectedZone === "All") {
              const { ZoneIssues } = doc.data();
              const zoneAll = Object.keys(ZoneIssues || {}).map((key) => ({
                  Amber: ZoneIssues[key].Amber,
                  Red: ZoneIssues[key].Red,
                }));

              const Ambers = zoneAll
                .map((n) => n.Amber)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
              const Reds = zoneAll
                .map((n) => n.Red)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

              todos[findIndex] = {
                date: moment(doc.data().Date.toDate()).format("DD/MM"),
                [Amber]: Ambers,
                [Red]: Reds,
              };
            }
          });

          setManpowerSummary(todos);
        });
    }
  }, [manpower_issues_summary_ed, selectedZone]);

  return (
    <>
      <div
        className={classNames({
          [classValue]: !editMode,
          "edit-dashed": editMode,
        })}
      >
        <div className={classNames("widget", { "full-h": !editMode })}>
          {editMode && (
            <span style={{ marginLeft: 12, marginTop: 5 }}>#{order}</span>
          )}
          <div className="widget-header-container d-flex flex-row justify-content-between">
            <WidgetHeader
              label="Manpower Issues"
              description="Properties having attendance shortage"
              disabled={false}
            />
            {!editMode ? (
             
              <ViewAll onClick={() => {
                if (isLoading) {
                    return false;
                  }
                  createHistory.push(`/executive/widget/manpower`);
              }}>View All</ViewAll>
            ) : (
              <Switch
                checked={isVisible}
                color="primary"
                onChange={handleChange}
              />
            )}
          </div>
          {!editMode ? (
            <div className={classNames({ disabled: editMode })}>
              <ChartArea config={config} data={manpowerSummary}/>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default compose(
  connect(
    ({
      dashboard,
      firestore: {
        ordered: { consolidated, ManpowerIssues_ED, manpower_issues_summary_ed },
      },
    }) => ({
      consolidated,
      ManpowerIssues_ED,
      manpower_issues_summary_ed,
      selectedZone: dashboard.selectedZone,
    })
  ),
  firestoreConnect()
)(ManpowerIssues);
