import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Collapse } from "react-bootstrap";
import moment from "moment";
import Loading from "../../common/Loading/Loading";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import DeviceInfo from "../DeviceInfo/DeviceInfo";

class SystemHistoryCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      healthCollpase: false,
      sysServiceCollapse: true,
      sysHistoryLoading: false,
      sysHistoryCollapse: true,
      editSysService: false,
      formValues: {},
      updateErrors: {},
    };
  }

  componentDidMount() {
    const width = this.getWidth();
    setTimeout(() => this.setState({ isMobile: width < 768 }), 500);
    window.addEventListener("resize", this.reportWindowSize);
  }

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  reportWindowSize = (e) => {
    const { isMobile } = this.state;
    if (isMobile) {
      if (e.target.innerWidth > 768) {
        this.setState({ isMobile: false });
      }
    } else if (e.target.innerWidth < 769) {
      this.setState({ isMobile: true });
    }
  };

  showServiceHistory = async (docId) => {
    const { firestore, currentApartment } = this.props;
    this.setState({ sysHistoryLoading: true, sysHistoryCollapse: false });
    if (this.unsubscribeServiceIntervalHistory) {
      this.unsubscribeServiceIntervalHistory();
    }
    this.unsubscribeServiceIntervalHistory = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [
        {
          collection: "ServiceIntervalDetails",
          doc: docId,
          subcollections: [{ collection: "ServiceIntervalHistory" }],
        },
      ],
      orderBy: ["ServicedDate", "desc"],
      limit: 25,
      storeAs: "ServiceIntervalHistory",
    });
    this.setState({ sysHistoryLoading: false });
  };

  toggleServiceCollapse = (e) => {
    const { isMobile, sysServiceCollapse } = this.state;
    if (isMobile) this.setState({ sysServiceCollapse: !sysServiceCollapse });
  };

  showEditModeService = (e) => {
    e.stopPropagation();
    this.setState({ editSysService: true });
  };

  setFormValue = (type, value) => {
    const { formValues } = this.state;
    this.setState({ formValues: { ...formValues, [`${type}`]: value } });
  };

  checkErrors = (sysInterval) => {
    const { formValues } = this.state;
    const errors = {};
    if (!formValues.CurrentServiceNotes) {
      errors["CurrentServiceNotes"] = "Service notes is required";
    }
    if (
      sysInterval.IntervalType === "DATE" ||
      sysInterval.IntervalType === "DATE-UNITS"
    ) {
      if (!formValues.LastServicedDate) {
        errors["LastServicedDate"] = "Please choose a new service date";
      } else if (isNaN(new Date(formValues.LastServicedDate).getTime())) {
        errors["LastServicedDate"] = "Please choose a valid date";
      } else if (
        new Date(formValues.LastServicedDate) <=
        sysInterval.LastServicedDate.toDate()
      ) {
        errors["LastServicedDate"] =
          "New service date should be greater than last service date";
      }
    }
    if (
      sysInterval.IntervalType === "UNITS" ||
      sysInterval.IntervalType === "DATE-UNITS"
    ) {
      if (!formValues.LastServicedUnits) {
        errors["LastServicedUnits"] = "Please enter a new service unit reading";
      } else if (isNaN(formValues.LastServicedUnits)) {
        errors["LastServicedUnits"] =
          "Please enter a valid service unit reading";
      } else if (
        formValues.LastServicedUnits <= sysInterval.LastServicedUnits
      ) {
        errors["LastServicedUnits"] =
          "New service unit should be greater than last service unit reading";
      }
    }

    return errors;
  };

  save = (sysInterval) => {
    const { formValues } = this.state;
    const { user, firestore, currentApartment } = this.props;
    const { DisplayName } = user[0];
    const errors = this.checkErrors(sysInterval);

    if (Object.keys(errors).length) {
      this.setState({ updateErrors: errors });
      return false;
    }
    if (formValues.LastServicedDate)
      formValues.LastServicedDate = formValues.LastServicedDate;

    firestore.update(
      {
        collection: "apartments",
        doc: currentApartment,
        subcollections: [
          { collection: "ServiceIntervalDetails", doc: sysInterval.id },
        ],
      },
      { ...formValues, LastUpdatedBy: DisplayName }
    );

    this.setState({ editSysService: false });
    console.log(formValues);
  };

  onSaveDeviceInfo = (deviceInfo, id) => {
    const { firestore, location, currentApartment } = this.props;
    const ServiceName = decodeURI(location.search.substring(1));

    if (id) {
      firestore.update(
        {
          collection: "apartments",
          doc: currentApartment,
          subcollections: [{ collection: "SystemEquipmentInfo", doc: id }],
        },
        { ...deviceInfo, ServiceName }
      );
    } else {
      firestore.add(
        {
          collection: "apartments",
          doc: currentApartment,
          subcollections: [{ collection: "SystemEquipmentInfo" }],
        },
        { ...deviceInfo, ServiceName }
      );
    }
  };

  onDeleteDeviceInfo = (id) => {
    const { firestore, currentApartment } = this.props;
    firestore.delete({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "SystemEquipmentInfo", doc: id }],
    });
  };

  render() {
    const {
      historicalSysEqpDetails,
      serviceIntervalDetails,
      EquipmentName,
      ComponentName,
      isHistoryLoading,
      historyLength,
      closeHistory,
      toggleFullhistory,
      serviceIntervalHistory,
      mapCondition,
      sysEqpDetails,
      systemEquipmentInfo,
    } = this.props;
    const srvInterlDetail = serviceIntervalDetails && serviceIntervalDetails[0];
    const {
      isMobile,
      healthCollpase,
      sysServiceCollapse,
      updateErrors,
      sysHistoryCollapse,
      sysHistoryLoading,
      editSysService,
      formValues,
    } = this.state;
    const _detail = (sysEqpDetails[mapCondition] || []).filter(
      (s) => s.EquipmentName === EquipmentName
    );
    const deviceInfoDict = (_detail[0] || {}).DeviceInfoDict;
    const deviceInfoDictMap = (_detail[0] || {}).DeviceInfoDictMap;
    return (
      <>
      <div className="header">
        <h5>{EquipmentName}</h5>
        <button className="close" onClick={closeHistory}><i className="icon-close"></i></button>
      </div>
      <div className="body">
        { isHistoryLoading ? <Loading height="5rem" showLogo="false" /> : 
        <div className="service-body">
          <h6 onClick={() => isMobile ? this.setState({healthCollpase: !healthCollpase}) : ''}>Health History
            { isMobile && <span className={`icon ml-auto float-right${!healthCollpase ? ' open': ''}`}><i className="icon-chevron-down"></i></span>}
          </h6>
          
          <Collapse in={!isMobile || !healthCollpase}>
          <div>
            { historicalSysEqpDetails && historicalSysEqpDetails.length ? <>
            <ul className="timeline new">
              { historicalSysEqpDetails.slice(0, 1).map((history, i) => (
                <li data-date={'(since ' + moment(history.UpdatedTime.toDate()).format('D MMM YYYY') +') now'} key={i}>
                  <span className={['badge-indicator', history.Indicator && history.Indicator.toLowerCase()].join(' ')}></span>
                  { history.AdHocTaskRefDoc ?
                    <Link to={history.AdHocTaskRefDoc} >{history.EquipmentStatus}</Link> : 
                    <span>{history.EquipmentStatus}</span>
                  }
                </li>
              ))}
            </ul>
            { historicalSysEqpDetails.slice(1, 3).length ? 
            <div className="history-block">
              <ul className="timeline">
                { historicalSysEqpDetails.slice(1, historyLength).map((history, i) => (
                  <li data-date={moment(history.UpdatedTime.toDate()).format('D MMM YYYY')} key={i}>
                    <span className={['badge-indicator', history.Indicator && history.Indicator.toLowerCase()].join(' ')}></span>
                    { history.AdHocTaskRefDoc ?
                      <Link to={history.AdHocTaskRefDoc} >{history.EquipmentStatus}</Link> : 
                      <span>{history.EquipmentStatus}</span>
                    }
                  </li>
                ))}
              </ul>
              { historicalSysEqpDetails.slice(3, historicalSysEqpDetails.length).length ? 
              <a className="helper" onClick={toggleFullhistory}>
                <u>{`${historyLength === 3 ? 'show' : 'hide'} full history`}</u>
              </a> : '' }
            </div> : '' } </>  : 
            <p className="text-secondary"><small>No history</small></p> }

            { deviceInfoDict && deviceInfoDict.length && <div className="mt-4">
              <h6 className="m-0">Info</h6>
              <DeviceInfo infoDict={deviceInfoDict} deviceInfo={systemEquipmentInfo} 
                EquipmentName={EquipmentName} ComponentName={ComponentName}
                deleteInfo={this.onDeleteDeviceInfo} infoDictMap={deviceInfoDictMap} limit={10}
                onSaveDeviceCard={this.onSaveDeviceInfo} />
            </div>}
          </div>
          </Collapse>
              {srvInterlDetail && (
                <>
                  <hr />
                  <h6 className="mb-0" onClick={this.toggleServiceCollapse}>
                    System Service
                    {isMobile && (
                      <span
                        className={`icon ml-auto float-right${
                          !sysServiceCollapse ? " open" : ""
                        }`}
                      >
                        <i className="icon-chevron-down"></i>
                      </span>
                    )}
                    {(!isMobile || !sysServiceCollapse) && !editSysService && (
                      <a
                        className="service-link ml-auto mr-3 float-right"
                        onClick={this.showEditModeService}
                      >
                        Update
                      </a>
                    )}
                  </h6>
                  <Collapse in={!isMobile || !sysServiceCollapse}>
                    <div className="pt-2">
                      {!editSysService ? (
                        <div className="row">
                          <div className="col-6 service-group">
                            <label className="service-label">
                              Last Serviced
                            </label>
                            <p className="service-data">
                              {srvInterlDetail.LastServiced}
                            </p>
                          </div>
                          <div className="col-6 service-group">
                            <label className="service-label">
                              Upcoming Service
                            </label>
                            <p className="service-data">
                              {srvInterlDetail.UpComingService}
                            </p>
                          </div>
                          <div className="col-6 service-group">
                            <label className="service-label">
                              Service Intervals
                            </label>
                            <p className="service-data">
                              {srvInterlDetail.ServiceInterval}
                            </p>
                          </div>
                          <div className="col-6 service-group">
                            <label className="service-label">
                              Service Contact No
                            </label>
                            <p className="service-data">
                              {srvInterlDetail.ServiceContactName} -{" "}
                              {srvInterlDetail.ServiceContactNo}
                            </p>
                          </div>
                          <div className="col-6 service-group">
                            <label className="service-label">Device Name</label>
                            <p className="service-data">
                              {srvInterlDetail.EquipmentName || "NA"}
                            </p>
                          </div>
                          <div className="col-6 service-group">
                            <label className="service-label">Type</label>
                            <p className="service-data">
                              {srvInterlDetail.EquipmentType || "NA"}
                            </p>
                          </div>
                          <div className="col-6 service-group">
                            <label className="service-label">
                              Commisioned On
                            </label>
                            <p className="service-data">
                              {srvInterlDetail.CommissionedOn || "-"}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-12 service-group">
                            <label className="service-label">
                              Service Notes
                            </label>
                            <input
                              type="text"
                              ref
                              className={`service-input${
                                updateErrors.CurrentServiceNotes ? " error" : ""
                              }`}
                              placeholder="Enter service notes here"
                              onChange={(e) =>
                                this.setFormValue(
                                  "CurrentServiceNotes",
                                  e.target.value
                                )
                              }
                            />
                            {updateErrors.CurrentServiceNotes && (
                              <span className="helper text-danger text-left mt-0">
                                {updateErrors.CurrentServiceNotes}
                              </span>
                            )}
                          </div>
                          {(srvInterlDetail.IntervalType === "DATE" ||
                            srvInterlDetail.IntervalType === "DATE-UNITS") && (
                            <div className="col-12 service-group">
                              <label className="service-label">
                                Service Date
                              </label>
                              <DatePicker
                                className={`service-input${
                                  updateErrors.LastServicedDate ? " error" : ""
                                }`}
                                selected={formValues.LastServicedDate}
                                dateFormat="dd MMM yyyy"
                                placeholderText="Choose new service date"
                                onChange={(date) =>
                                  this.setFormValue("LastServicedDate", date)
                                }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                minDate={srvInterlDetail.LastServicedDate.toDate()}
                                maxDate={new Date()}
                              />
                              {updateErrors.LastServicedDate && (
                                <span className="helper text-danger text-left mt-0">
                                  {updateErrors.LastServicedDate}
                                </span>
                              )}
                            </div>
                          )}
                          {(srvInterlDetail.IntervalType === "UNITS" ||
                            srvInterlDetail.IntervalType === "DATE-UNITS") && (
                            <div className="col-12 service-group">
                              <label className="service-label">
                                New Service Unit Reading
                              </label>
                              <MaskedInput
                                className={`service-input${
                                  updateErrors.LastServicedUnits ? " error" : ""
                                }`}
                                mask={(s) =>
                                  Array.from(s).map(() => /([0-9]|\.)/i)
                                }
                                guide={false}
                                inputMode="decimal"
                                onChange={(e) =>
                                  this.setFormValue(
                                    "LastServicedUnits",
                                    e.target.value
                                  )
                                }
                                placeholder="Enter new service unit reading"
                              />
                              {updateErrors.LastServicedUnits && (
                                <span className="helper text-danger text-left mt-0">
                                  {updateErrors.LastServicedUnits}
                                </span>
                              )}
                            </div>
                          )}
                          <div className="col-12 service-group d-flex justify-content-end mt-3">
                            <button
                              className="btn btn-sm btn-light mr-2"
                              onClick={() =>
                                this.setState({ editSysService: false })
                              }
                            >
                              Cancel
                            </button>
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() => this.save(srvInterlDetail)}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      )}
                      {sysHistoryCollapse && (
                        <a
                          className="helper d-block mx-auto"
                          onClick={() =>
                            this.showServiceHistory(srvInterlDetail.id)
                          }
                        >
                          <u>Show service history</u>
                        </a>
                      )}
                      <Collapse in={!sysHistoryCollapse}>
                        <div>
                          {!sysHistoryLoading && serviceIntervalHistory ? (
                            <>
                              <hr />
                              <p className="service-data font-weight-bold mb-2">
                                Service History
                              </p>
                              {serviceIntervalHistory.length &&
                                serviceIntervalHistory.map(
                                  (intervalHistory, i) => (
                                    <p className="interval-history" key={i}>
                                      {intervalHistory.ServiceNotes}
                                      <span className="float-right ml-auto">
                                        {moment(
                                          intervalHistory.ServicedDate.toDate()
                                        ).format("D MMM YYYY")}
                                      </span>
                                    </p>
                                  )
                                )}
                            </>
                          ) : (
                            <Loading height="2rem" showLogo="false" />
                          )}
                        </div>
                      </Collapse>
                    </div>
                  </Collapse>
                </>
              )}
            </div>
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.firestore.ordered.Users,
  systemEquipmentInfo: state.firestore.ordered.SystemEquipmentInfo,
  historicalSysEqpDetails: state.firestore.ordered.HistoricalSysEqpDetails,
  serviceIntervalDetails: state.firestore.ordered.ServiceIntervalDetails,
  serviceIntervalHistory: state.firestore.ordered.ServiceIntervalHistory,
  currentApartment: state.apartment.currentApartment,
});

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect(),
  withRouter
)(SystemHistoryCard);
