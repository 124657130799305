import React from 'react'

export default function SearchBarFilter(props) {
    const { focusSearch, onTextChange, nameSort, onFocus, onBlur, placeholder, className } = props;
    return (
        <div className={`d-flex align-items-center ${className ? className : 'mb-md-2 mt-md-0 mt-2 mr-md-0 mr-2'}`}>
            <input
                className={` ${focusSearch ? 'filter-input-active' : 'filter-input'} `}
                onChange={onTextChange}
                placeholder={placeholder}
                defaultValue={nameSort}
                onFocus={onFocus}
                onBlur={nameSort === '' && onBlur}
                onKeyDown={e => { if (e.keyCode === 13) e.target.blur() }}
            />
            <div className={` ${focusSearch ? 'search-icon-active' : 'search-icon'} `}>
                <i className="icon-search"></i>
            </div>
        </div>
    )
}
