import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import firebase from "firebase";
import createHistory from "../../history";
import Loading from "../common/Loading/Loading";
import lastUpdatedTime from "../common/Timeago";
import AdhocEditTaskModal from "./AdhocEditTaskModal";
import "../../App.css";

const moment = require("moment");

class SingleTask extends Component {
  constructor() {
    super();
    this.state = {
      item: null,
      comment: "",
      key: null,
      showlessComments: true,
      buttonPressed: -1,
      files: null,
      loadFile: false,
      editDetails: false,
      endDay: new Date(),
      btnClicked: false,
    };
    this.comments = [];
    this.Description = null;
    this.reloaded = false;
    this.newFiles = [];
  }

  componentDidMount = () => {
    const { location, AdHocTaskManager } = this.props;
    let nKey = location.search.substring(1);
    this.getKeyFromQuery(location);
    this.history = createHistory.listen((loc) => {
      this.getKeyFromQuery(loc);
      nKey = location.search.substring(1);
    });
    AdHocTaskManager.map((data) => {
      if (data.id === nKey) {
        this.setState({ endDay: data.TaskEndDate.toDate() });
      }
    });
  };

  componentDidUpdate(prevProps) {
    const { user, currentApartment } = this.props;
    if (user.length !== 0) {
      if (prevProps.user.length !== 0) {
        if (
          currentApartment &&
          currentApartment !== prevProps.currentApartment
        ) {
          this.fetchCommentsAndFiles();
        }
      } else if (currentApartment) {
        this.fetchCommentsAndFiles();
      }
    }
    if (prevProps.FileList) {
      if (this.props.FileList.length === prevProps.FileList.length + 1) {
        this.setState({ loadFile: false });
      }
    }
  }

  componentWillUnmount() {
    this.history();
  }

  selectFile = async (e) => {
    this.setState({ loadFile: true });
    const { key } = this.state;
    const { user, firebase, currentApartment } = this.props;
    this.newFiles = this.fileToArray(e.target.files);
    const filesPath = `${currentApartment}/Ad-Hoc-Tasks/${key}`;
    await firebase.uploadFiles(filesPath, e.target.files);
  };

  fileToArray = (fileList) => Array.prototype.slice.call(fileList);

  updateData = (status, index) => {
    const { firestore, user, currentApartment } = this.props;
    const { key } = this.state;
    const { DisplayName, UID } = user[0];
    this.setState({ buttonPressed: index });
    firestore
      .collection("apartments")
      .doc(currentApartment)
      .collection("AdHocTaskManager")
      .doc(key)
      .update({
        UserAction: status,
        TaskLastUpdatedBy: DisplayName,
        TaskLastUpdatedByUID: UID,
      })
      .then(() => {
        this.setState({ buttonPressed: -1 });
        window.history.back();
      });
  };

  getKeyFromQuery = (location) => {
    this.load = false;
    const nKey = location.search.substring(1);
    this.setState({ key: nKey }, () => {
      this.fetchCommentsAndFiles();
    });
  };

  fetchCommentsAndFiles = async () => {
    const { firestore, currentApartment } = this.props;
    const { key } = this.state;
    if (key && currentApartment !== "") {
      if (this.unsubscribeTaskComments) {
        this.unsubscribeTaskComments();
      }
      this.unsubscribeTaskComments = firestore.onSnapshot({
        collection: "apartments",
        doc: currentApartment,
        subcollections: [
          {
            collection: "AdHocTaskManager",
            doc: key,
            subcollections: [{ collection: "TaskComments" }],
          },
        ],
        storeAs: "TaskComments",
      });
      if (this.unsubscribeFileList) {
        this.unsubscribeFileList();
      }
      this.unsubscribeFileList = firestore.onSnapshot({
        collection: "apartments",
        doc: currentApartment,
        subcollections: [
          {
            collection: "AdHocTaskManager",
            doc: key,
            subcollections: [{ collection: "FileList" }],
          },
        ],
        storeAs: "FileList",
      });
      this.load = true;
    }
  };

  uploadComment = async () => {
    const { firestore, user, currentApartment } = this.props;
    const { key, comment } = this.state;
    const { DisplayName } = user[0];
    await firestore
      .collection("apartments")
      .doc(currentApartment)
      .collection("AdHocTaskManager")
      .doc(key)
      .collection("TaskComments")
      .add({
        Comment: comment,
        CommentBy: DisplayName,
        CommentDate: new Date(),
      });
    this.setState({ comment: "" });
  };

  onCommenting = (e) => {
    this.setState({ comment: e.target.value });
  };

  removeDuplicate = () => {
    const obj = {};
    for (let i = 0; i < this.comments.length; i += 1) {
      obj[this.comments[i].id] = this.comments[i];
    }
    this.comments = [];
    Object.keys(obj).map((key) => {
      this.comments.push(obj[key]);
    });
  };

  getDate = (data) => {
    const { firestore } = this.props;
    return new firestore.Timestamp(data.seconds, data.nanoseconds).toDate();
  };

  setButtoncondition = (data) => {
    const { currentApartment, ApartmentAccessInfo, user } = this.props;
    let accessInfo = null;
    if(ApartmentAccessInfo.length){
      const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
      if(selectedApartment){
        accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
      }
    }
    if (accessInfo) {
      const isManager = accessInfo.is_manager;
      const isMember = accessInfo.is_mc_member;
      if (data.NextPerformer === "MC" && isMember) return true;
      if (data.NextPerformer === "EM" && isManager) return true;
    }
    return false;
  };

  removeFileFromFirestore = async (file) => {
    const { firestore, user, currentApartment } = this.props;
    const { key } = this.state;
    await firestore
      .collection("apartments")
      .doc(currentApartment)
      .collection("AdHocTaskManager")
      .doc(key)
      .collection("FileList")
      .doc(file.id)
      .delete();
  };

  openFile = (file) => {
    const storage = firebase.storage();
    storage
      .ref()
      .child(file.FilePath)
      .getDownloadURL()
      .then((url) => {
        window.open(url, "_blank");
      });
  };

  OnEndDayChange = (endDate) => {
    this.setState({ endDay: endDate });
  };

  uploadData = async (status, data) => {
    this.setState({ btnClicked: true });
    const { firestore, user, currentApartment } = this.props;
    const { endDay } = this.state;

    await firestore
      .collection("apartments")
      .doc(currentApartment)
      .collection("AdHocTaskManager")
      .doc(data.id)
      .update({
        TaskDescription: this.Description
          ? this.Description
          : data.TaskDescription,
        TaskEndDate: new Date(endDay),
        TaskLastUpdatedBy: user[0].DisplayName,
        UserAction: status,
        TaskLastUpdatedByUID: user[0].UID,
      })
      .then(() => {
        this.setState({ editDetails: false, btnClicked: false });
      });
  };

  render() {
    const {
      comment,
      key,
      showlessComments,
      buttonPressed,
      loadFile,
      editDetails,
      endDay,
      btnClicked,
    } = this.state;
    const { TaskComments, AdHocTaskManager, FileList } = this.props;
    if (!key || !TaskComments || !AdHocTaskManager || !this.load || !FileList) {
      return <Loading showLogo="false" />;
    }
    let item;
    AdHocTaskManager.map((data) => {
      if (data.id === key) {
        item = data;
      }
    });

    this.comments = [...TaskComments];
    if (TaskComments) this.removeDuplicate();
    this.comments.sort(
      (a, b) =>
        new Date(b.CommentDate.toDate()) - new Date(a.CommentDate.toDate())
    );
    if (showlessComments) {
      this.comments.length = 5;
    }
    return (
      <div>
        <AdhocEditTaskModal data={item} FileList={FileList} />
        <div className="d-flex flex-column col-12 align-items-center taskDetails">
          <div className="adhocTaskDetailCard d-flex flex-column col-12 flex-wrap row">
            <div className="d-flex align-items-center taskDetailHeading border-bottom pb-2">
              <button
                type="submit"
                className="buttonrmstyle"
                onClick={() => window.history.back()}
                style={{ paddingLeft: 24 }}
              >
                <i className="icon-arrow-left" style={{ fontSize: 11 }} />
              </button>
              <div className="heading mr-auto" style={{ marginLeft: 7 }}>
                {item.TaskTitle}
              </div>
              {!editDetails &&
                item.CurrentStatus !== "Completed" &&
                item.CurrentStatus !== "Abandobned" && (
                  <button
                    type="submit"
                    className="buttonrmstyle pr-4 editButton"
                    onClick={() => this.setState({ editDetails: true })}
                  >
                    <i className="icon-pencil" />
                  </button>
                )}
            </div>
            <div className="rowDiv">
              <div className="column1">
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  <div className="flex-wrap w-100">
                    <div
                      className="AdTaskTitle mr-4 mb-2"
                      style={{ fontSize: 15, color: "#9d9d9d" }}
                    >
                      {item.TaskType}
                    </div>
                    {!editDetails && (
                      <div
                        className="AdTaskCommonStyle mb-2"
                        style={{ fontSize: 14, color: "#3b3b3b" }}
                      >
                        {item.TaskDescription.split(". ").join(".\n")}
                      </div>
                    )}
                    {editDetails && (
                      <div>
                        <textarea
                          className="adhocTextarea mt-2"
                          onChange={(e) => {
                            this.Description = e.target.value;
                          }}
                          defaultValue={item.TaskDescription}
                          style={{ paddingRight: 10 }}
                        />
                      </div>
                    )}
                    <div className="d-flex flex-wrap mb-2 pt-2 timeDiv">
                      <div className="d-flex">
                        <img
                          style={{ marginRight: 8, objectFit: "contain" }}
                          alt="back"
                          src={require("../../assets/images/hourglassstart.png")}
                        />
                        <div className="AdTaskTime" style={{ marginRight: 40 }}>
                          {moment(this.getDate(item.TaskStartDate)).format(
                            "DD MMMM YYYY"
                          )}
                        </div>
                      </div>
                      <div className="d-flex" id="editTask">
                        <img
                          style={{ marginRight: 8, objectFit: "contain" }}
                          alt="back"
                          src={require("../../assets/images/hourglassend.png")}
                        />
                        {!editDetails && (
                          <div className="AdTaskTime">
                            {moment(this.getDate(item.TaskEndDate)).format(
                              "DD MMMM YYYY"
                            )}
                          </div>
                        )}
                        {editDetails && (
                          <DatePicker
                            className="CalendarDatePicker"
                            selected={endDay}
                            onChange={this.OnEndDayChange}
                            style={{ fontSize: 10 }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {editDetails && (
                    <div className="w-100 d-flex justify-content-end">
                      <button
                        type="submit"
                        className="buttonrmstyle mb-2"
                        onClick={() => this.setState({ editDetails: false })}
                      >
                        <div
                          className="btn btn-light"
                          style={{ borderColor: "#007bff" }}
                        >
                          Cancel
                        </div>
                      </button>
                      <button
                        type="submit"
                        className="buttonrmstyle mb-2"
                        onClick={() => this.uploadData("Save", item)}
                      >
                        <div
                          className={`btn btn-primary d-flex ${
                            btnClicked ? "disabled" : ""
                          }`}
                        >
                          <div>Save</div>
                          {btnClicked && (
                            <div
                              className="spinner-border"
                              role="status"
                              style={{
                                width: 15,
                                height: 15,
                                marginTop: 5,
                                marginLeft: 5,
                              }}
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                        </div>
                      </button>
                    </div>
                  )}
                </div>
                <div
                  className="d-flex align-items-center justify-content-between flex-wrap"
                  style={{ marginTop: 12 }}
                >
                  <div
                    className="adhocCurrentStatus mb-2 py-1"
                    style={{
                      backgroundColor: item.CardBGColorCode,
                      color: item.CardColor,
                      padding: "5px 7px",
                    }}
                  >
                    {item.CurrentStatus.toUpperCase()}
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap mt-1">
                  <div className="d-flex align-items-center flex-wrap">
                    {item.NextState.map(
                      (button, index) =>
                        this.setButtoncondition(item) && (
                          <button
                            type="submit"
                            className={
                              index < item.NextState.length - 1
                                ? "mr-3 rm-button-style adTaskButtons mb-2"
                                : "rm-button-style adTaskButtons mb-2"
                            }
                            style={{
                              backgroundColor: button.ButtonBGColorCode,
                              color: button.ButtonFontColorCode,
                            }}
                            onClick={() =>
                              this.updateData(button.ButtonName, index)
                            }
                          >
                            {buttonPressed !== index ? (
                              button.ButtonName
                            ) : (
                              <span
                                className="spinner-border spinner-border-sm text-light"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                        )
                    )}
                  </div>
                </div>
              </div>
              <div className="column2">
                <div
                  style={{
                    borderBottom: "solid 1px #e0e0e0",
                    paddingBottom: 21,
                  }}
                >
                  <div
                    className="row justify-content-between align-items-center"
                    style={{ marginBottom: 15, marginLeft: 0, marginRight: 0 }}
                  >
                    <div className="AdTaskAttachments">Attachments</div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div className="cabinet">
                        <input
                          type="file"
                          name="avatar"
                          accept="*/*"
                          onChange={this.selectFile}
                          multiple
                        />
                      </div>
                      <div
                        className="addFileDiv d-flex align-items-center justify-content-center"
                        style={{ marginLeft: -162 }}
                      >
                        <img
                          src={require("../../assets/images/upload.png")}
                          alt="cal"
                          className="addFile"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                      <div className="uploadFile d-flex align-items-center justify-content-center">
                        Upload File
                      </div>
                    </div>
                  </div>
                  {loadFile ? (
                    <div>
                      {this.newFiles.map((file) => (
                        <div className="d-flex">
                          <div
                            className="attachedFilesLabel"
                            style={{ fontSize: 12 }}
                          >
                            {file.name}
                          </div>
                          <div
                            className="spinner-border spinLabel"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {FileList.map((file) => (
                    <File
                      key={file.id}
                      file={file}
                      openFile={this.openFile}
                      removeFileFromFirestore={this.removeFileFromFirestore}
                    />
                  ))}
                </div>

                <div className="commentTitle">Comments</div>
                <textarea
                  className="adhocTextarea mt-2"
                  style={{ zIndex: 1 }}
                  placeholder="Enter comment here"
                  onChange={this.onCommenting}
                  value={comment}
                />
                <div className="position-relative" style={{ zIndex: 100 }}>
                  <button
                    type="submit"
                    className="position-absolute submitButton"
                    style={{
                      top: -44,
                      right: 11,
                      backgroundColor:
                        comment.length === 0 ? "#D5D5D5" : "#4a90e2",
                    }}
                    onClick={this.uploadComment}
                    disabled={comment.length === 0}
                  >
                    Submit
                  </button>
                </div>
                <div className="mt-3">
                  {this.comments.map((data) => (
                    <div key={data.id} className="d-flex flex-column comments">
                      <div className="d-flex flex-wrap">
                        <div className="commentedBy mr-3">{data.CommentBy}</div>
                      </div>
                      <div
                        className="AdTaskCommonStyle"
                        style={{ fontSize: 12, color: "#1a1818" }}
                      >
                        {data.Comment}
                      </div>
                      <div className="d-flex flex-wrap ml-auto">
                        <div className="commentHeading mr-2">
                          {moment(this.getDate(data.CommentDate)).format(
                            "DD MMMM YYYY"
                          )}
                        </div>
                        <div className="commentHeading mr-2">
                          ({lastUpdatedTime(data.CommentDate)})
                        </div>
                      </div>
                    </div>
                  ))}
                  {TaskComments && TaskComments.length > 5 && (
                    <button
                      className="rm-button-style morecomment-btn w-100"
                      type="submit"
                      onClick={() =>
                        this.setState({ showlessComments: !showlessComments })
                      }
                    >
                      {`${showlessComments ? "Show more" : "Show less"}`}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const File = ({ file, openFile, removeFileFromFirestore }) => {
  const [showConfirm, setConfirm] = useState(false);

  return (
    <div style={{ maxWidth: 230 }} className="d-flex">
      <button
        type="submit"
        style={{ fontSize: 12 }}
        className="rm-button-style attachedFilesLabel p-0 text-left"
        onClick={() => openFile(file)}
      >
        {file.DisplayFileName}
      </button>
      <button
        type="submit"
        style={{
          color: "red",
          border: 0,
          backgroundColor: "transparent",
          outline: "none",
        }}
        onClick={() => setConfirm(true)}
      >
        <img
          src={require("../../assets/images/close.png")}
          alt="cal"
          style={{ height: 7, width: 7 }}
        />
      </button>
      <Modal centered show={showConfirm} onHide={() => setConfirm(false)}>
        <Modal.Header className="tb-modal-header" closeButton>
          <Modal.Title className="tb-modal-title" as="h6">
            Confirm
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="tb-modal-body">
          {file && (
            <p>
              <small>
                Do you really want to delete {file.DisplayFileName} ?
              </small>
            </p>
          )}
        </Modal.Body>
        <Modal.Footer className="tb-modal-footer">
          <Button size="sm" variant="light" onClick={() => setConfirm(false)}>
            No
          </Button>
          {file && (
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                setConfirm(false);
                removeFileFromFirestore(file);
              }}
            >
              Yes
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

SingleTask.defaultProps = {
  user: [{ Email: "" }],
};

const mapStateToProps = (state) => ({
  user: state.firestore.ordered.Users,
  TaskComments: state.firestore.ordered.TaskComments,
  // UserAccessInfo: state.firestore.ordered.UserAccessInfo,
  AdHocTaskManager: state.firestore.ordered.AdHocTaskManager,
  FileList: state.firestore.ordered.FileList,
  BucketDetails: state.firestore.ordered.BucketDetails,
  currentApartment: state.apartment.currentApartment,
  ApartmentAccessInfo: state.apartment.ApartmentAccessInfo
});

const mapDispatchToProps = (dispatch) => ({
  showModal: () =>
    dispatch({
      type: "SHOW_MODAL",
    }),
});

// export default  withRouter(SingleTask)
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(),
  firebaseConnect(),
  withRouter
)(SingleTask);
