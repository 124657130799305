import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div style={{ padding: 70 }}>
    <div className="row">
      <div className="span12">
        <div className="hero-unit center">
          <h1>
            Page Not Found{" "}
            <small>
              <font face="Tahoma" color="red">
                Error 404
              </font>
            </small>
          </h1>
          <br />

          <Link to="/" className="btn btn-large btn-info">
            <i className="icon-home icon-white" /> Take Me Home
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default NotFound;
