exports.priority = {
    High: {
        color: 'red',
        background: '#fdd',
        order: 1,
    },
    Medium: {
        color: '#ffbf00',
        background: '#ffe492',
        order: 2,
    },
    Low: {
        color: 'green',
        background: '#bafaba',
        order: 3,
    }
}