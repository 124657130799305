export const initialState = { currentApartment: null, managersList: [], ApartmentAccessInfo: [] };

export function reducer(state = initialState, action) {
  if (action.type === "SET_APARTMENT") {
    localStorage.setItem(
      "tb-state",
      JSON.stringify({ apartment: { currentApartment: action.payload } })
    );
    return { ...state, currentApartment: action.payload };
  }
  if (action.type === "RESET_APARTMENT") {
    localStorage.removeItem("tb-state");
    return initialState;
  }
  if (action.type === "SET_APARTMENT_ACCESS_INFO") {
    return { ...state, ApartmentAccessInfo: action.payload };
  }
  if (action.type === "RESET_APARTMENT") {
    localStorage.removeItem("tb-state");
    return initialState;
  }
  if (action.type === "SET_MANAGERS_LIST") {
    return {
      ...state,
      managersList: action.payload,
    };
  }
  return state;
}
