/* eslint-disable camelcase */
import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { Accordion, Collapse, Card, Modal, Carousel } from 'react-bootstrap';
import SlidingPane from 'react-sliding-pane';
import * as moment from 'moment';
import DatePicker from 'react-datepicker';
import Loading from '../common/Loading/Loading';
import createHistory from "../../history";

class GroupedTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayKey: 0,            
      filterSearchValue: '',
      isPaneOpen: false,
      startDay: null,
      endDay: null,
      files: null,
      loadFile: false,
    }
    this.subMenuRef = React.createRef();
    this.subMenuIconRef = React.createRef();
  }

  componentDidMount() {    
    this.slider = React.createRef();
    this.systemSlider = React.createRef();
    this.activitiesSlider = React.createRef();
    const width = this.getWidth();
    this.setState({ isMobile: width < 768 })
    window.addEventListener('resize', this.reportWindowSize);
    document.addEventListener('click', this.handleClickOutside);
  }

  componentDidUpdate() {
    const { user, clearFirestore } = this.props;
    if (user.length === 0) { clearFirestore();  } 
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.reportWindowSize);
    document.removeEventListener('click', this.handleClickOutside);
  }

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    )
  };

  reportWindowSize = (e) => {
    const { isMobile } = this.state
    if (isMobile) {
      if (e.target.innerWidth > 768) {
        this.setState({ isMobile: false });
      }
    } else if (e.target.innerWidth < 769) {
      this.setState({ isMobile: true });
    }
  }

  handleClickOutside = event => {
    const { isModuleSidebarOpen, isMobile } = this.state;
    if (isMobile && this.subMenuRef.current && !this.subMenuRef.current.contains(event.target)
      && !this.subMenuIconRef.current.contains(event.target) && isModuleSidebarOpen) {
      this.setState({ isModuleSidebarOpen: false })
    }
  }  

  getThemeColor = () => {
    const {AppartmentColor } = this.props;
    const { isMobile } = this.state;
    if(isMobile && AppartmentColor ) {
      return AppartmentColor
    }
    return null
  }

  onTaskTypeChange = (e) => { this.taskType = e.target.value }

  OnStartDayChange = (startDay) => { this.setState({ startDay }) }

  OnEndDayChange = (endDay) => { this.setState({ endDay }) }

  fileToArray = (fileList) => Array.prototype.slice.call(fileList)

  removeItem = (index) => {
    const { files } = this.state;
    const _files = files;
    _files.splice(index, 1)
    this.setState({ files: _files });
  }

  selectFile = (e) => {
    this.setState({ loadFile: true });
    const { files } = this.state;
    const newFiles = this.fileToArray(e.target.files);
    if (!files) {
      this.setState({ files: newFiles });
      this.setState({ loadFile: false });
      return;
    }
    const joined = this.fileToArray(files).concat(this.fileToArray(newFiles));
    this.setState({
      files: joined,
    });
  }

  onCancel = () => {
    this.setState({ isPaneOpen: false, loadFile: false })
    this.clearData()
  }

  uploadData = async (status, index) => {
    const { firestore, user, firebase, currentApartment } = this.props;
    const { DisplayName, UID } = user[0];
    const { startDay, endDay, files } = this.state;
    if (startDay && endDay && this.Title) {
      this.setState({ saving: true, buttonPressed: index })

      await firestore.collection("apartments").doc(currentApartment).collection('AdHocTaskManager').add({}).then(async doc => {
        if (files) {
          const filesPath = `${currentApartment}/Ad-Hoc-Tasks/${doc.id}`;
          await firebase.uploadFiles(filesPath, files).then();
        }
        await firestore.collection("apartments").doc(currentApartment).collection('AdHocTaskManager').doc(doc.id).update({
          TaskTitle: this.Title,
          TaskDescription: this.Description,
          TaskStartDate: new Date(startDay),
          TaskEndDate: new Date(endDay),
          TaskCreatedBy: DisplayName,
          TaskLastUpdatedBy: DisplayName,
          TaskCreatedDate: new Date(),
          TaskType: this.taskType,
          UserAction: status,
          TaskLastUpdatedByUID: UID,
        });
        this.setState({ saving: false, buttonPressed: -1 });
        this.clearData();
        this.setState({ isPaneOpen: false, loadFile: false })
      })
    }
  }

  addTaskModal = () => {
    const { isPaneOpen, startDay, endDay, files, buttonPressed } = this.state;
    const { AdHocTaskManager } = this.props;
    let dropDown;
    if (AdHocTaskManager) {
      dropDown = AdHocTaskManager._master_data.TaskTypes;
    }

    return (
      <SlidingPane
        className='some-custom-class'
        overlayClassName='some-custom-overlay-class'
        isOpen={isPaneOpen}
        width='400px'
        onRequestClose={() => { this.setState({ isPaneOpen: false, loadFile: false }); }}>
        <div className="d-flex justify-content-between">
          <div className="addTaskHeading">Add a New Task</div>
          <button className="close" onClick={() => this.setState({ isPaneOpen: false, loadFile: false })} type="submit">
            <img src={require('../../assets/images/close.png')} alt="cal" style={{ height: 16, width: 16 }} />
          </button>
        </div>
        <div className="addTaskSubHeadings addTaskTitle mt-3">Title</div>
        <input className="addTaskInputs" onChange={(e) => { this.Title = e.target.value }} />

        <div className="addTaskSubHeadings mt-3">Task Type</div>
        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
          {AdHocTaskManager &&
            <select id="Adhoc" className="modalDropdown" onChange={this.onTaskTypeChange}
              style={{ width: 325, borderBottom: '1px solid #D5D5D5' }} >
              <option value="" selected disabled hidden>Choose Any</option>
              {dropDown.map(value => (
                <option key={value} value={value} >{value}</option>
              ))}
            </select>
          }
        </div>

        <div className="addTaskSubHeadings mt-3">Description</div>
        <input className="addTaskInputs" onChange={(e) => { this.Description = e.target.value }} />
        <div>
          <div className="addTaskSubHeadings mt-3">Start Date</div>
          <DatePicker className="addTaskCalendarDatePicker" placeholderText="Select Start Date" showMonthDropdown
            showYearDropdown selected={startDay} maxDate={endDay && endDay} onChange={this.OnStartDayChange}
            dropdownMode="select" popperPlacement="top" />
        </div>
        <div>
          <div className="addTaskSubHeadings mt-3">End Date </div>
          <DatePicker className="addTaskCalendarDatePicker" placeholderText="Select End Date" selected={endDay}
            minDate={startDay && startDay} onChange={this.OnEndDayChange} popperPlacement="top" popperModifiers={{
              flip: { behavior: ["top"]  }, preventOverflow: { enabled: false }, hide: { enabled: false } }} />
        </div>
        {files && files.map((item, index) => (
          <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
            <div className="fileItems">{item.name}</div>
            <button type="submit" style={{ color: 'red', border: 0, backgroundColor: 'transparent' }} onClick={() => this.removeItem(index)}>
              <img src={require('../../assets/images/close.png')} alt="cal" style={{ height: 17, width: 17 }} />
            </button>
          </div>
        ))}        
        <div className="mt-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
          <div className="cabinet">
            <input type="file" name="avatar" accept="*/*" onChange={this.selectFile} multiple />
          </div>
          <div className="addFileDiv d-flex align-items-center justify-content-center" style={{ marginLeft: -162 }} >
            <img src={require('../../assets/images/upload.png')} alt="upload" className="addFile" style={{objectFit:'contain'}} />
          </div>
          <div className="uploadFile d-flex align-items-center justify-content-center">{(files && files.length !== 0) ? 'Upload Another File' : 'Upload File'}</div>
        </div>
        <div className="d-flex justify-content-between">
          {AdHocTaskManager && AdHocTaskManager._master_data.NextState.map((item, index) => (
            <div key={`tasknexttype${index}`}>
              {item.ButtonName === 'Submit' ?
                <button type="submit" id="cancel" className="cancel mr-2" onClick={this.onCancel} style={{ color: '#3B3B3B', backgroundColor: 'white' }}>
                  Cancel
                </button>
                : null
              }
              <button type="submit" className={item.ButtonName === 'Submit' ? 'save' : 'saveDraft pl-0'}
                onClick={() => this.uploadData(item.ButtonName, index)} style={{ border: 0, borderRadius: 3 }}>
                {buttonPressed !== index ? item.ButtonName : (
                  <span className="spinner-border spinner-border-sm text-light" role="status" />
                )}
              </button>
            </div>
          ))}
        </div>
        <br />
      </SlidingPane>
    )
  }

  showMobileNav = (taskgroup) => {
    const { displayKey, isMobile, isCompletedInc } = this.state;

    if (!isMobile) {
      return null;
    }
    return (
      <div id="dashboard-nav" className="d-flex flex-column w-100" style={{ position: 'fixed', backgroundColor:this.getThemeColor() }}>
        <div className="d-flex justify-content-between w-100 align-items-center">
          <div className='adhocDiv'>To Do</div>
          {Object.keys(taskgroup).length ?
            <div className="d-flex pr-1" style={{ paddingTop: 3 }}>
              <label className="custom-checkbox font-sm ml-auto" htmlFor="completed">
                <input type="checkbox" id="completed" name="completed" defaultChecked={isCompletedInc} 
                  onChange={() => this.setState({isCompletedInc: !isCompletedInc})} />
                <label htmlFor="completed">Completed</label>
              </label>
              <button className='d-flex justify-content-center align-items-center' type="submit"
                onClick={() => this.setState({ isPaneOpen: true })} style={{ 
                  backgroundColor: '#558dca', border: 0, outline: 'none', 
                  marginLeft: 10, width: 28, height: 28, borderRadius: 20 
                }}>
                <img alt="task" src={require('../../assets/images/addTask.png')} style={{ width: 17, height: 12, objectFit:'contain' }} />
              </button>
            </div> : null}
        </div>
        {/* <div className="d-flex align-items-baseline" style={{ marginTop: 5 }}>
          <a className="menu d-flex align-items-center pl-0" style={{ padding: '0.75rem' }}
            onClick={() => this.setState({ isModuleSidebarOpen: true })} ref={this.subMenuIconRef}
          ><i className="icon-hamburger" style={{ fontSize: '0.75rem' }} /></a>
          <ul className="d-flex mb-0 pl-0" style={{whiteSpace: 'nowrap', overflowX: 'auto'}}>
            <a className={`d-flex justify-content-between align-items-center${displayKey === '' ? ` displayKey` : ``}`}
              style={{ fontSize: '0.875rem', padding: '0.75rem', color: displayKey === '' ? '#fff' : '#838383', fontWeight: displayKey === '' && 'bold' }}
              onClick={() => this.onClickComponentName('')}
            >All
            </a>
            { Object.keys(taskgroup).sort((a, b) => a.localeCompare(b)).map(taskType => (
              <a key={`mobile-nav-${taskType}`} className={`d-flex justify-content-between align-items-center${displayKey === taskType ? ` displayKey` : ``}`}
                style={{ fontSize: '0.875rem', padding: '0.75rem', color: displayKey === taskType ? '#fff' : '#838383', fontWeight: displayKey === taskType && 'bold' }}
                onClick={() => this.onClickComponentName(taskType)}
              >{taskType}
              </a>
            ))}
          </ul>
        </div> */}
      </div>
    );
  }

  groupedData = (taskslist, filter) => taskslist.reduce((acc, task) => {
    const taskType = task.TaskType;
    
    if (!acc[taskType]) acc[taskType] = { };
    if (!acc[taskType].total) acc[taskType].total = 0;
    if (!acc[taskType].tasks) acc[taskType].tasks = [];

    if (filter || (task.CurrentStatus !== 'Abandoned' && task.CurrentStatus !== 'Completed')) {

      const curr = {
        ...(acc[taskType]),
        total: (acc[taskType].total || 0) + 1,
        tasks: [...acc[taskType].tasks, task],
        date: (acc[taskType].date || new Date(0)) < task.TaskLastUpdatedDate.toDate() ? task.TaskLastUpdatedDate.toDate() : acc[taskType].date
      };
      acc[taskType] = curr;
    }
    return acc;
  }, { })

  render() {
    const { displayKey, isMobile, isCompletedInc } = this.state;
    const { user, AdHocTaskManager, IconSet, ApartmentAccessInfo, currentApartment } = this.props;

    let accessInfo = null;
    if (ApartmentAccessInfo.length) {
      const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
      if (selectedApartment) {
        accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
      }
    }
    const { has_ad_hoc_task_access } = accessInfo || {}

    let addTask = true; 
    let dropDown;
    const IconMap = IconSet && IconSet[0] && IconSet[0].IconMap;
    if (AdHocTaskManager) { dropDown = (AdHocTaskManager._master_data || {}).TaskTypes; }
    // if (UserAccessInfo && user && AdHocTaskManager) { 
    //   addTask = !UserAccessInfo[user[0].id].has_ad_hoc_task_access; 
    if (accessInfo) {
      addTask = !has_ad_hoc_task_access
      console.log(' addTask = ', addTask);
    } else {
      return ( <Loading showLogo="false" /> )
    }

    const taskslist = [];
    Object.keys(AdHocTaskManager).map((key) => {
      const item = AdHocTaskManager[key];
      if (key !== '_master_data' && item.CurrentStatus) {
        taskslist.push({...item, id: key});
      }
    });
    const taskgroup = this.groupedData(taskslist, isCompletedInc);
    console.log(taskgroup);

    return (
      <div id="adhocTask" style={{ margin: 15, marginTop: 37, }}>
        { this.showMobileNav(taskgroup) }
        
        { !isMobile &&
        <div className="d-flex justify-content-between align-items-center flex-wrap mb-2 pr-5 pr-md-0" >
          <h4 className="activeAdhocButton ml-3">To Do</h4>
          <label className="custom-checkbox ml-auto" htmlFor="completed">
            <input type="checkbox" id="completed" name="completed" defaultChecked={isCompletedInc} 
              onChange={() => this.setState({isCompletedInc: !isCompletedInc})} />
            <label htmlFor="completed">Include Completed</label>
          </label>
          <button className='ml-3' type="submit" onClick={() => this.setState({ isPaneOpen: true })}
            style={{ backgroundColor: '#558dca', border: 0, padding: 0, outline: 'none', width: 120, height: 35, borderRadius: 3 }} disabled={addTask}>
            <div className="addTaskButton" >
              <img alt="task" src={require('../../assets/images/addTask.svg')}
                style={{ width: 15, height: 12, marginTop: -2, marginRight: 3 }} />
                ADD A TASK
            </div>
          </button>
        </div> }
        <div id="taskList" className="row ml-0 mr-0" style={{ marginTop: isMobile && '7.5rem', flexWrap:'nowrap' }}>
          { Object.keys(taskgroup).length ? <div className={`pr-md-0 col-12`} style={{ marginTop: 10 }}>
            <Accordion activeKey={displayKey}>
              { Object.keys(taskgroup).sort((a, b) => a.localeCompare(b)).map((taskType, i) => (<Card key={`taskgroup-${i}`}>
                <Accordion.Toggle as={Card.Header} eventKey={i} 
                  onClick={() => this.setState({displayKey: displayKey !== i ? i : '99'})}>
                  <TaskHeader active={displayKey === i} title={taskType} count={taskgroup[taskType].total} lastUpdated={taskgroup[taskType].date} />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={i}>
                  <Card.Body>
                  <table>
                    <tbody>
                      { taskgroup[taskType].tasks && taskgroup[taskType].tasks.map((task, idx) => (
                      <tr key={`task-item-${idx}`}>
                        <td className="taskTitle">{task.TaskTitle}</td>
                        <td>{task.TaskDescription}</td>
                        <td style={{ color: task.CardColor === 'Amber' ? '#ffbf00' : task.CardColor }}>
                          <span className="adhocCurrentStatus">{task.CurrentStatus.toUpperCase()}</span>
                        </td>
                        <td>
                          <button className="btn btn-sm btn-outline-primary d-flex align-items-center"
                            onClick={() => createHistory.push(`/adhoc/task?${task.id}`)}
                          >View Details</button>
                        </td>
                      </tr>
                      )) }
                    </tbody>
                  </table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card> ))}
            </Accordion>
            {/* <div className={`listCards${isMobile ? ` grouped-cards pr-1` : ``}`}>
            { Object.keys(taskgroup).sort((a, b) => a.localeCompare(b)).map(taskType => (<Fragment key={`card-${  taskType}`}>
              { (displayKey === '' || taskType === displayKey) && <a className="adhocCard d-flex" 
                onClick={() => createHistory.push(`/taskmanager?${taskType}`)}
                style={{minHeight: 160}}>                
                  <div className="details">
                    <div className="d-flex justify-content-between">
                      <p className="tasktype text-left align-self-start mt-1 mb-sm-3 mb-1">{taskType}</p>
                      {isMobile && <span className="ml-auto card-main-icon">
                        { IconMap && <i className={`icon-default-daily icon-${IconMap[taskType]}`} /> }
                      </span>}
                    </div>
                    <h4 className="adhocTaskTitle mb-1">{taskgroup[taskType].total} Active Tasks</h4>
                    { !isMobile && <div className="d-flex flex-wrap">
                    { taskgroup[taskType].statuses && Object.keys(taskgroup[taskType].statuses).map(taskStatus => (
                      <p key={`${taskType  }-${  taskStatus}`}  className={[
                        `pt-0 text-left font-weight-bold text-color adhocTaskDescription mb-0 mr-3`,
                        `${taskgroup[taskType].statuses[taskStatus].color.toLowerCase()}`
                      ].join(' ')}>
                        {taskgroup[taskType].statuses[taskStatus].count} {taskStatus}
                      </p>
                    ))}
                    </div>}
                  
                    { isMobile && taskgroup[taskType].date && <span className="lastdate mt-2">
                      <img className="hourglass" alt="time" src={require('../../assets/images/hourglassend.png')} />
                      <span>{moment(taskgroup[taskType].date).format('DD MMM YYYY')}</span>
                    </span> }
                  </div>
                  {!isMobile && <div className="ml-auto d-flex flex-column align-items-end">
                    { IconMap && <span className="card-main-icon"><i className={`icon-default-daily icon-${IconMap[taskType]}`} /></span> }
                    { taskgroup[taskType].date && <span className="lastdate mt-auto">
                      <img className="hourglass" alt="time" src={require('../../assets/images/hourglassend.png')} />
                      <span>{moment(taskgroup[taskType].date).format('DD MMM YYYY')}</span>
                    </span> }
                  </div>}                
              </a> }
            </Fragment>))}
            </div> */}
          </div> : <p>Add a new Task</p> }
        </div>
        { this.addTaskModal() }
      </div>
    )
  }
}

const TaskHeader = ({active, title, count, lastUpdated}) => (
  <div className="cardHeader d-flex align-items-center">
    <i className={`icon-chevron-down${active ? ` open` : ``}`}/> {title}
    <span className="headerCount ml-2">({count})</span>
    <div className="d-inline-flex align-items-center ml-auto">
      <img style={{ marginRight: 8, height: 13 }} alt="time" src={require('../../assets/images/hourglassstart.png')} />
      <div style={{ marginTop: 2, fontSize: 12, color: "#9d9d9d" }}>{moment(lastUpdated).format('DD MMM YYYY')}</div>
    </div>
  </div>
)

const mapStateToProps = state => ({
  // UserAccessInfo: state.firestore.data.UserAccessInfo,
  AdHocTaskManager: state.firestore.data.AdHocTaskManager,
  user: state.firestore.ordered.Users,
  // UserRoles: state.firestore.ordered.UserAccessInfo[0].Roles,
  AppartmentColor: state.firestore.ordered.AppartmentDetails[0].Header_Theme_Color,
  IconSet: state.firestore.ordered.IconMap,
  currentApartment: state.firestore.ordered.Users && state.apartment.currentApartment,
  ApartmentAccessInfo: state.apartment.ApartmentAccessInfo
});

const mapDispatchToProps = dispatch => ({
  showModal: () => dispatch({ type: "SHOW_MODAL" }),
  openSideBar: (value) => dispatch({ type: 'SIDEBAR_OPEN', payload: value })
});

export default  compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(), firebaseConnect(),
  withRouter
)(GroupedTask)