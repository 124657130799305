import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Avatar from '../../common/Avatar/Avatar';
import Select from '../../common/Select/Select';

import './AssignTicketModal.scss';

const AssignTicketModal = ({ show, onHide, assignees, value, onAssign }) => {
    const options = assignees && assignees.map(assignee => ({ 
        label: (
            <div className="d-flex align-items-center py-1">
                <Avatar name={assignee.DisplayName}/>
                <span className="ml-3">{assignee.DisplayName}</span>
            </div>
        ),
        value: JSON.stringify(assignee),
        id: assignee.DisplayName
    }));

    const [assignee, setAssignee] = useState(null);

    let selectedOption = options && options.find(o => o.id === value);
    if(assignee) {
        selectedOption = options.find(o => o.id === assignee.DisplayName);
    }

    const changeHandler = (option) => {
        setAssignee(JSON.parse(option.value))
    }

    return (
        <Modal
			show={show}
            onHide={onHide}
            onExited={() => setAssignee(null)}
            className="assign-modal"
            centered
		>
            <Modal.Header closeButton>
				Assign Ticket
			</Modal.Header>
            <Modal.Body className="p-4">
                <Select
                    options={options}
                    placeholder="Select assignee"
                    className="mb-5"
                    value={selectedOption || null}
                    height={48}
                    onChange={changeHandler}
                />
                <div className="d-flex ">
                    <button
                        className="btn btn-sm rounded-0 btn-primary ml-auto"
                        onClick={() => onAssign(assignee)}
                        disabled={!assignee}
                    >
                        Assign
                    </button>

                </div>
			</Modal.Body>
        </Modal>
    )
}

export default AssignTicketModal;
