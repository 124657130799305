import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';

import * as _ from 'lodash';

import './IncidentSummary.scss';
import { withRouter, Link } from 'react-router-dom';
import AddIncidentModal from '../Incidents/AddIncidentModal/AddIncidentModal';

const today = new Date();

export class IncidentSummary extends Component {

	state = {
		modalVisibility: false
	}

	componentDidMount() {
		const { user, currentApartment } = this.props;
		
		document.getElementById('main-block').scrollTo(0,0)
		
		this.fetchData(currentApartment)
		window.addEventListener('online', this.onLineTrigger)
	}

	componentWillUnmount(){
		window.removeEventListener('online', this.onLineTrigger)
	}

	onLineTrigger = async () => {
		const { offlineTicketFiles, user, firestore, firebase, cacheTicketFiles, currentApartment } = this.props;
		
		if(offlineTicketFiles && offlineTicketFiles.length){
			await Promise.all(offlineTicketFiles.map(async t => {
				await Promise.all(
					t.Files.map(async f => {
						await firebase.uploadFiles(f.filePath, f.File);
						delete f.File
						firestore
						.collection("apartments")
						.doc(currentApartment)
						.collection('IncidentReports')
						.doc(t.ticketId)
						.collection('Files')
						.add(f)
					})
				)
			}))
			cacheTicketFiles([]);
		}
	}

	componentDidUpdate(prevProps) {
		const { currentApartment } = this.props;
		
		if(currentApartment !== prevProps.currentApartment){
			this.fetchData(currentApartment);	
		}
	}

	fetchData(currentApartment) {
        const { firestore, incidents } = this.props;
        // if(!incidents) {
			if (this.incidents) {
				this.incidents();
			}
			//  console.log('**********',currentApartment )
			  this.incidents = firestore.onSnapshot({
                collection: 'apartments',
                doc: currentApartment,
                subcollections: [{
                    collection: 'IncidentReports', where: [
                        ['IsArchived', '==', false],
                    ]
                }],
                storeAs: `incidents`
            });
        // }
	}

	myTicketsBtnClickHandler = () => {
		const { history } = this.props;
		history.push('/incidents?myIncidents=true')
	}

	allTicketsBtnClickHandler = () => {
		const { history } = this.props;
		history.push('/incidents')
	}

	ageTicketClickHandler = (age) => {
		const { history } = this.props;
		history.push(`/incidents?ageFilter=${age}`)
	}

	addIncidentBtnHandler = () => {
		this.setState({ modalVisibility: true });
	}

	render() {
		
		const { incidents } = this.props;
		const { modalVisibility } = this.state;

		let openResidentTicket = 0;
		let openSystemTicket = 0;
		let closedResidentTicket = 0;
		let closedSystemTicket = 0;

		incidents && incidents.forEach(incident => {
			if(incident.IsActive === true && incident.IncidentType === 'Residents') openResidentTicket += 1; 
			if(incident.IsActive === true && incident.IncidentType === 'System') openSystemTicket += 1;

			if(incident.IsActive === false && incident.IncidentType === 'Residents') closedResidentTicket += 1;
			if(incident.IsActive === false && incident.IncidentType === 'System') closedSystemTicket += 1;
		})
	
		const tenPlusDays = incidents && incidents.filter(incident => 
			incident.IsActive === true && 
			incident.CreationDate.toDate().getTime() <= new Date().setDate(today.getDate()-10)
		).length;

		const fiveToTenDays = incidents && incidents.filter(incident => 
			incident.IsActive === true && 
			incident.CreationDate.toDate().getTime() <= new Date().setDate(today.getDate()-5) &&
			incident.CreationDate.toDate().getTime() > new Date().setDate(today.getDate()-10)
		).length;

		const twoToFiveDays = incidents && incidents.filter(incident => 
			incident.IsActive === true && 
			incident.CreationDate.toDate().getTime() <= new Date().setDate(today.getDate()-2) &&
			incident.CreationDate.toDate().getTime() > new Date().setDate(today.getDate()-5)
		).length;

		const belowTwoDays = incidents && incidents.filter(incident => 
			incident.IsActive === true && 
			incident.CreationDate.toDate().getTime() > new Date().setDate(today.getDate()-2)
		).length;

		
		return (
			<div id="incident-summary">
				<div className="d-flex justify-content-between align-items-start mb-4">
					<h1 className="h4 font-weight-700">
						Tickets's Summary
						<div className="font-wight-400 font-13 mt-2 d-md-block d-none">			
							<Link className="text-secondary" to="/dashboard">Tickets</Link>			
						</div>
					</h1>
					<button
						className="btn btn-primary rounded-0 py-2 add-incident-btn"
						onClick={this.addIncidentBtnHandler}
					>
						<span className="d-md-inline d-none">Add a Ticket</span>
						<i className="icon-plus d-md-none d-inline" />
					</button>
				</div>
				<div className="row no-gutters mb-3">
					<div className="col-6">
						<span className="d-block mb-1">Open Tickets (All)</span>
						<div className="border border-dark p-2">
							<p className="mb-1">
								<span className="h5 font-weight-700">{openResidentTicket}</span>
								<span className="text-secondary">&nbsp;Residents</span>
							</p>
							<p className="mb-0">
								<span className="h5 font-weight-700">{openSystemTicket}</span>
								<span className="text-secondary">&nbsp;Community</span>
							</p>
						</div>
					</div>
					<div className="col-6">
						<span className="d-block mb-1">Closed Today (All)</span>
						<div className="border border-dark border-left-0 p-2">
							<p className="mb-1">
								<span className="h5 font-weight-700">{closedResidentTicket}</span>
								<span className="text-secondary">&nbsp;Residents</span>
							</p>
							<p className="mb-0">
								<span className="h5 font-weight-700">{closedSystemTicket}</span>
								<span className="text-secondary">&nbsp;Community</span>
							</p>
						</div>
					</div>
				</div>
				<div className="row mb-3">
					<div className="col-md-3 col-6 mb-3">
						<div className="count-card p-2" style={{ backgroundColor: '#ff6a6a' }} onClick={() => this.ageTicketClickHandler('ten-plus')}>
							<span className="small font-weight-600 text-white">10+ Days</span>
							<span className="d-block count h5 font-weight-bold m-0">{tenPlusDays || 0}</span>
						</div>
					</div>
					<div className="col-md-3 col-6 mb-3">
						<div className="count-card p-2" style={{ backgroundColor: '#14d5ff' }} onClick={() => this.ageTicketClickHandler('five-ten')}>
							<span className="small font-weight-600 text-white">5-10 Days</span>
							<span className="d-block count h5 font-weight-bold m-0">{fiveToTenDays || 0}</span>
						</div>
					</div>
					<div className="col-md-3 col-6 mb-3">
						<div className="count-card p-2" style={{ backgroundColor: '#ffbf00' }} onClick={() => this.ageTicketClickHandler('two-five')}>
							<span className="small font-weight-600 text-white">2-5 Days</span>
							<span className="d-block count h5 font-weight-bold m-0">{twoToFiveDays || 0}</span>
						</div>
					</div>
					<div className="col-md-3 col-6 mb-3">
						<div className="count-card p-2" style={{ backgroundColor: 'green' }} onClick={() => this.ageTicketClickHandler('below-two')}>
							<span className="small font-weight-600 text-white">Below 2 Days</span>
							<span className="d-block count h5 font-weight-bold m-0">{belowTwoDays || 0}</span>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6 col-12 mb-4">
						<button
							className="btn btn-primary btn-block rounded-0 d-flex justify-content-between align-items-center py-3"
							onClick={this.allTicketsBtnClickHandler}
						>
							<span>All Tickets</span>
							<i className="icon-arrow-right" />
							
						</button>
					</div>
					<div className="col-md-6 col-12 mb-4">
						<button
							className="btn btn-primary btn-block rounded-0 d-flex justify-content-between align-items-center py-3"
							onClick={this.myTicketsBtnClickHandler}
						>
							<span>My Tickets</span>
							<i className="icon-arrow-right" />
						</button>
					</div>
				</div>
				<AddIncidentModal
					show={modalVisibility}
					onHide={() => this.setState({ modalVisibility: false })}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.firestore.ordered.Users && state.firestore.ordered.Users,
	incidents: state.firestore.ordered.incidents,
	offlineTicketFiles: state.updateModule.offlineTicketFiles,
	currentApartment: state.apartment.currentApartment,
})

const mapDispatchToProps = dispatch => ({
	cacheTicketFiles: (files) => dispatch({ type: 'ADD_OFFLINE_TICKET_FILES', payload: files })
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect(),
	firebaseConnect(),
	withRouter
)(IncidentSummary)
