import React, { Component, useState, useEffect, useContext } from "react";
import { Accordion, Collapse, Card, Modal, Carousel } from "react-bootstrap";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import Compress from "compress.js";
import Loading from "../../common/Loading/Loading";
import "react-html5-camera-photo/build/css/index.css";
import AccordionContext from "react-bootstrap/AccordionContext";
import { ActivityDetailModal } from "../../ActivityDetailModal/ActivityDetailModal";
import SlidingPane from "react-sliding-pane";

export class StaffActivityCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
    };
    this.activeTasks = [];
    this.completedTasks = [];
    this.defaultedTasks = [];
  }

  categoriseList() {
    this.manager = false;
    const { user, mapCondition, groupedTaskDetails, memberOrManager } =
      this.props;
    const { Roles } = user[0];

    this.activeTasks = [];
    this.completedTasks = [];
    this.defaultedTasks = [];

    if (mapCondition) {
      groupedTaskDetails[mapCondition].map((task, i) => {
        if (task.TaskState.toLowerCase() === "exception") {
          this.defaultedTasks.push(task);
        } else if (task.TaskState.toLowerCase() === "completed") {
          this.completedTasks.push(task);
        } else if (task.TaskState.toLowerCase() === "active") {
          if (
            Roles.some(
              (role) =>
                task.CurrentPerformer && task.CurrentPerformer.includes(role)
            )
          ) {
            this.activeTasks.push(task);
          } else if (
            Roles.some(
              (role) =>
                task.CompletedPerformer &&
                task.CompletedPerformer.includes(role)
            )
          ) {
            this.completedTasks.push({ ...task, TaskState: "COMPLETED" });
          } else if (memberOrManager) {
            this.activeTasks.push({ ...task, readOnly: true });
          }
        }
      });
    }

    this.completedTasks.sort(
      (a, b) =>
        new Date(b.LastUpdatedTime && b.LastUpdatedTime.toDate()) -
        new Date(a.LastUpdatedTime && a.LastUpdatedTime.toDate())
    );
    this.defaultedTasks.sort(
      (a, b) =>
        new Date(b.LastUpdatedTime && b.LastUpdatedTime.toDate()) -
        new Date(a.LastUpdatedTime && a.LastUpdatedTime.toDate())
    );
    this.activeTasks.sort(
      (a, b) =>
        new Date(a.TaskDateTime && a.TaskDateTime.toDate()) -
        new Date(b.TaskDateTime && b.TaskDateTime.toDate())
    );
  }

  getTabName(idx) {
    const keyList = ["active", "completed", "defaulted"];
    return keyList[idx];
  }

  render() {
    const {
      groupedTaskDetails,
      mapCondition,
      memberOrManager,
      user,
      isMobile,
      onVerifyingTask,
      onUpdateTaskDetail,
      getPhotoURL,
      offlineImages = [],
      firestore,
      currentApartment,
      activeComponent
    } = this.props;
    const { activeKey } = this.state;
    const { Roles, DisplayName } = user[0];
    if (!groupedTaskDetails || !Object.keys(groupedTaskDetails).length)
      return <h5 className="mt-3 font-weight-bold">No activities</h5>;
    if (!mapCondition) return <Loading showLogo="false" />;
    this.categoriseList();

    const accordian = [
      {
        title: "Active Tasks",
        tasks: this.activeTasks,
        color: "#14D5FF",
        key: "0",
      },
      {
        title: "Completed Tasks",
        tasks: this.completedTasks,
        color: "#73D58E",
        key: "1",
      },
      {
        title: "Defaulted Tasks",
        tasks: this.defaultedTasks,
        color: "#FF6A6A",
        key: "2",
      },
    ];
    const filteredAccordian = accordian.filter(
      (taskgroup) => taskgroup.tasks.length
    );
    const displayKey = activeKey || filteredAccordian[0].key;
    const taskgroup = accordian[this.state.activeKey];

    return (
      <>
        <div className={"tab-head d-flex p-0 justify-content-between"}>
          {accordian.map((taskgroup, i) => (
            <div
              className={`item p-3 cursor-pointer ${this.getTabName(taskgroup.key)} ${
                this.state.activeKey == taskgroup.key ? "selected" : ""
              } `}
              key={i}
              style={{ borderBottomColor: taskgroup.color }}
              onClick={() => this.setState({ activeKey: taskgroup.key })}
            >
              <div className="d-flex">
                <span className="item-count">{taskgroup.tasks.length}</span>
                <i className="item-state ml-auto"></i>
              </div>
              <div className="mt-1">{taskgroup.title}</div>
            </div>
          ))}
        </div>
        <div className="tab-content mt-3">
          <Accordion defaultActiveKey={activeComponent || 0}>
            {taskgroup.tasks &&
              taskgroup.tasks.map((task, idx) => (
                <TaskItem
                  isMobile={isMobile}
                  task={task}
                  idx={idx}
                  key={`content-${task.id}`}
                  memberOrManager={memberOrManager}
                  Roles={Roles}
                  userDisplayName={DisplayName}
                  onVerifyingTask={onVerifyingTask}
                  onUpdateTaskDetail={onUpdateTaskDetail}
                  getPhotoURL={getPhotoURL}
                  offlineImages={offlineImages[task.id]}
                  firestore={firestore}
                  currentApartment={currentApartment}
                />
              ))}
          </Accordion>
          {taskgroup.tasks && taskgroup.tasks.length === 0 && (
            <div className="m-5 text-center">No Tasks</div>
          )}
        </div>
      </>
    );
  }
}

const IconToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext);

  const isCurrentEventKey = eventKey === currentEventKey;

  return (
    <span
      className={`icon-chevron-down ${isCurrentEventKey ? "open" : ""}`}
    ></span>
  );
};

const TaskItem = ({
  task,
  isMobile,
  memberOrManager,
  onUpdateTaskDetail,
  onVerifyingTask,
  getPhotoURL,
  Roles,
  idx,
  offlineImages = [],
  userDisplayName,
  firestore,
  currentApartment,
}) => {
  const [selected, setSelected] = useState(false);
  const [taskAction, setTaskAction] = useState(task.TaskAction);
  const [approvalStatus, setApprovalStatus] = useState(task.ApprovalStatus);
  const [taskfiles, setTaskfiles] = useState([]);
  const [cameraView, openCameraView] = useState(false);
  const [isWarningModalOpen, showWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState(
    "Please use our mobile app to use this feature"
  );
  const [taskimages, setTaskImage] = useState(offlineImages);
  const [taskIcon, setTaskIcon] = useState([]);
  const [comments, setComments] = useState([]);
  const [isOpenSidePane, setIsOpenSidePane] = useState(false);

  useEffect(() => {
    async function getTaskFileUrls() {
      const urls = await Promise.all(
        (task.TaskFiles || []).map((f) => getPhotoURL(f))
      );
      setTaskImage(urls);
    }
    if (task.TaskFiles) {
      getTaskFileUrls();
    }
  }, [task.TaskFiles]);

  useEffect(() => {
    async function getTaskFileUrls() {
      const urls = await Promise.all(
        [task.TaskIconLink].map((f) => getPhotoURL(f))
      );
      setTaskIcon(urls);
    }
    if (task.TaskIconLink) {
      getTaskFileUrls();
    }
  }, [task.TaskIconLink]);

  useEffect(() => {
    const clone = [...offlineImages];
    let fileObject = [];
    Promise.all(
      clone.map(async (f) => {
        const blob = await fetch(f).then((res) => res.blob());
        fileObject = [
          ...fileObject,
          new File([blob], `${new Date().getTime()}`, {
            type: "image/jpeg",
          }),
        ];
      })
    ).then(() => {
      setTaskfiles(fileObject);
      setTaskImage(offlineImages);
    });
  }, [offlineImages.length]);

  useEffect(() => {
    if (selected) {
      setTimeout(() => {
        setSelected(false);
      }, 1000);
    }
  }, [task]);

  useEffect(() => {
    getComments();
  }, [task, isOpenSidePane]);

  const onVerification = (id, value) => {
    setSelected(true);
    setApprovalStatus(value);
    onVerifyingTask(id, value, taskfiles);
  };

  const onChangeTaskValue = (id, value) => {
    setSelected(true);
    setTaskAction(value);
    onUpdateTaskDetail(id, value, taskfiles);
  };

  const onClickCamera = (e) => {
    if (!window.cordova && !isMobile) {
      setWarningMsg("Please use our mobile app to use this feature");
      showWarningModal(true);
      return;
    }
    if (window.cordova) {
      navigator.camera.getPicture(
        async (uri) => {
          const url = `data:image/jpeg;base64,${uri}`;
          onConfirm(url);
        },
        (err) => {
          setWarningMsg(err);
          showWarningModal(true);
        },
        { destinationType: window.Camera.DestinationType.DATA_URL }
      );
    } else {
      openCameraView(true);
    }
  };

  const uploadComment = async (currentComment) => {
    const data = {
      Comment: currentComment,
      CommentBy: userDisplayName,
      CommentDate: new Date(),
    };
    await firestore
      .collection("apartments")
      .doc(currentApartment)
      .collection("TaskDetails")
      .doc(task.id)
      .collection("Comments")
      .add(data);
  };

  const getComments = async () => {
    const comments = [];
    await firestore
      .collection("apartments")
      .doc(currentApartment)
      .collection("TaskDetails")
      .doc(task.id)
      .collection("Comments")
      .get()
      .then((data) => {
        data.forEach((doc) => {
          comments.push(doc.data());
        });
      });
    setComments(comments);
  };

  const openSidePane = async (e) => {
    setIsOpenSidePane(true);
  };

  const closeSidePane = () => {
    setIsOpenSidePane(false);
  };

  const onConfirm = async (image) => {
    const url = image;
    let blob = await fetch(url).then((res) => res.blob());
    let file = new File([blob], task.TaskName, { type: "image/jpeg" });
    const compress = new Compress();
    const data = await compress.compress([file], { size: 1, resize: false });
    blob = await fetch(data[0].prefix + data[0].data).then((res) => res.blob());
    file = new File([blob], `${new Date().getTime()}`, { type: "image/jpeg" });
    openCameraView(false);
    setTaskfiles((taskfiles) => [...taskfiles, file]);
    setTaskImage((taskimages) => [
      ...taskimages,
      data[0].prefix + data[0].data,
    ]);
  };

  return (
    <Card key={idx} className="mb-2 system-card">
      <Accordion.Toggle as={Card.Header} eventKey={idx} className="p-3 d-flex">
        <div className="d-flex align-items-center item-highlights">
          <span
            className="name mr-2"
            style={{
              color: task.Indicator === "Red" && "#FF8989",
              backgroundColor:
                (task.Indicator === "Amber" && "#FFE974") ||
                (task.Indicator === "Blue" && "#d7f2ff"),
            }}
          >
            {task.TaskName}
          </span>
          <span
            className={
              task.TaskState === "EXCEPTION"
                ? "mr-2 close-time close-time-red"
                : "mr-2 close-time close-time-gray"
            }
          >
            {task.TaskSchedule}
          </span>
        </div>
        <div className="ml-auto d-flex align-items-center">
          {task.TaskState === "ACTIVE" && (
            <span className="performer mr-3">
              {new Date(task.TaskDateTime.seconds * 1000).toLocaleTimeString(
                "en-IN",
                { hour: "2-digit", minute: "2-digit" }
              )}
            </span>
          )}
          {/*task.TaskState === "COMPLETED" && (
            <span className="performer mr-3">
              Performed by {task.TaskLastUpdatedBy || "n/a"} at{" "}
              {new Date(task.LastUpdatedTime.seconds * 1000).toLocaleTimeString(
                "en-IN",
                { hour: "2-digit", minute: "2-digit" }
              )}
            </span>
              )*/}
          {/* {task.TaskState === "EXCEPTION" && (
            <span className="performer mr-3">
              {task.TaskAudits && task.TaskAudits[0].Action} at{" "}
              {new Date(
                task.TaskAudits &&
                  task.TaskAudits[0].ActionTime &&
                  task.TaskAudits[0].ActionTime.seconds * 1000
              ).toLocaleTimeString("en-IN", { hour: '2-digit', minute: '2-digit' })}
            </span>
          )} */}
          <IconToggle eventKey={idx}></IconToggle>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={idx}>
        <Card.Body className="pt-0 pb-2 p-0">
          <div className="d-flex pl-3 pr-3 mb-3 align-items-center all-actions">
            <div className="mr-3">
              {task.TaskState === "EXCEPTION" ? (
                <>
                  <button disabled className="btn-approve mr-2">
                    OK
                  </button>
                  <button disabled className="btn-reject">
                    Not OK
                  </button>
                </>
              ) : task.TaskState === "COMPLETED" ? (
                <>
                  {task.TaskAction === "Ok" && (
                    <span className="label-approve mr-2">Ok</span>
                  )}
                  {task.TaskAction === "Not Ok" && (
                    <span className="label-reject">Not Ok</span>
                  )}
                  {task.ApprovalStatus === "Approved" && (
                    <span className="label-approve">Approved</span>
                  )}
                  {task.ApprovalStatus === "Rejected" && (
                    <span className="label-reject">Rejected</span>
                  )}
                </>
              ) : !task.readOnly ? (
                task.TaskState === "ACTIVE" && task.IsVerificationTask ? (
                  <>
                    <button
                      className="btn-approve mr-2"
                      disabled={
                        (task.IsPhotoReq &&
                          !(task.TaskFiles || []).length &&
                          !taskfiles.length) ||
                        taskAction === "Ok" ||
                        taskAction === "Not Ok"
                      }
                      onClick={() => onChangeTaskValue(task.id, "Ok")}
                    >
                      OK
                    </button>
                    <button
                      className="btn-reject"
                      disabled={
                        (task.IsPhotoReq &&
                          !(task.TaskFiles || []).length &&
                          !taskfiles.length) ||
                        taskAction === "Ok" ||
                        taskAction === "Not Ok" ||
                        comments.length === 0
                      }
                      onClick={() => onChangeTaskValue(task.id, "Not Ok")}
                    >
                      Not OK
                    </button>
                  </>
                ) : task.TaskState === "ACTIVE" && task.IsApprovalTask ? (
                  <>
                    <button
                      className="btn-approve mr-2"
                      disabled={
                        approvalStatus === "Approved" ||
                        (approvalStatus === "Rejected"
                          ? userDisplayName === task.TaskLastUpdatedBy
                          : false)
                      }
                      onClick={() => onVerification(task.id, "Approved")}
                    >
                      Approve
                    </button>
                    <button
                      className="btn-reject"
                      disabled={
                        approvalStatus === "Approved" ||
                        (approvalStatus === "Rejected"
                          ? userDisplayName === task.TaskLastUpdatedBy
                          : false) ||
                        comments.length === 0
                      }
                      onClick={() => onVerification(task.id, "Rejected")}
                    >
                      Reject
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn-approve mr-2"
                      disabled={
                        task.IsPhotoReq &&
                        !(task.TaskFiles || []).length &&
                        !taskfiles.length
                      }
                      onClick={() => onChangeTaskValue(task.id, "Ok")}
                    >
                      OK
                    </button>
                    <button
                      className="btn-reject"
                      disabled={
                        (task.IsPhotoReq &&
                          !(task.TaskFiles || []).length &&
                          !taskfiles.length) ||
                        comments.length === 0
                      }
                      onClick={() => onChangeTaskValue(task.id, "Not Ok")}
                    >
                      Not OK
                    </button>
                  </>
                )
              ) : (
                <></>
              )}
            </div>
            <TaskImages
              {...{ task, getPhotoURL }}
              files={task.TaskFiles}
              images={taskimages}
            />
            <div className="line-break d-md-none"></div>
            <span className="ml-auto pl-3"></span>
            {task.TaskState === "ACTIVE" && (
                <i
                  className={`icon-comments mr-3 ml-1 ${comments.length > 0 ? 'active-comments' : ''}`}
                  onClick={(e) => openSidePane(e)}
                />
            )}
            {task.TaskIconLink && (
              <span className="mr-3">
                <TaskImages
                  {...{ task }}
                  files={task.TaskIconLink}
                  images={taskIcon}
                />
              </span>
            )}
            {task.IsPhotoTask &&
              taskfiles.length < 4 &&
              (task.TaskState.toLowerCase() === "active" &&
              Roles.some(
                (role) =>
                  task.CurrentPerformer && task.CurrentPerformer.includes(role)
              ) ? (
                <button
                  disabled={
                    taskAction === "Ok" ||
                    taskAction === "Not Ok" ||
                    approvalStatus === "Approved" ||
                    (approvalStatus === "Rejected"
                      ? userDisplayName === task.TaskLastUpdatedBy
                      : false)
                  }
                  className="icon-camera mr-3"
                  onClick={(e) => onClickCamera(e)}
                ></button>
              ) : (
                <i className="icon-images mr-3" />
              ))}
            {cameraView &&
              task.IsPhotoTask &&
              (task.TaskState.toLowerCase() === "active" &&
              Roles.some(
                (role) =>
                  task.CurrentPerformer && task.CurrentPerformer.includes(role)
              ) ? (
                <TaskCamera
                  onCancel={() => openCameraView(false)}
                  onConfirm={onConfirm}
                />
              ) : null)}
            <i className="open-detail" onClick={(e) => openSidePane(e)}></i>
          </div>
          <Modal
            centered
            show={isWarningModalOpen}
            onHide={() => showWarningModal(false)}
          >
            <Modal.Header
              className="tb-modal-header pt-2 pb-0"
              closeButton
            ></Modal.Header>
            <Modal.Body className="pt-2 pb-0">{warningMsg}</Modal.Body>
            <Modal.Footer className="tb-modal-footer pt-2 pb-3">
              <button
                className="btn btn-sm btn-danger"
                onClick={() => showWarningModal(false)}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      </Accordion.Collapse>
      <SlidingPane
        isOpen={isOpenSidePane}
        onRequestClose={() => closeSidePane()}
      >
        <ActivityDetailModal
          closeCallback={() => closeSidePane()}
          uploadComment={uploadComment}
          history={task.TaskAudits}
          comments={comments}
        ></ActivityDetailModal>
      </SlidingPane>
    </Card>
  );
};

const TaskCamera = ({ onCancel, onConfirm }) => {
  const [preview, showPreview] = useState(false);
  const [prwImage, setPrwImage] = useState(null);

  useEffect(() => {
    showPreview(false);
  }, []);

  const capture = (imgSrc) => {
    setPrwImage(imgSrc);
    showPreview(true);
  };

  const onApprove = async () => {
    onConfirm(prwImage);
    showPreview(true);
  };

  const onReject = () => {
    onCancel();
    showPreview(true);
  };

  return (
    <div className="tb-camera">
      {!preview && (
        <button className="btn btn-sm btn-close" onClick={() => onCancel()}>
          <i className="icon-close" />
        </button>
      )}
      {!preview ? (
        <Camera
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          idealResolution={{ width: 640, height: 480 }}
          imageType={IMAGE_TYPES.JPG}
          onTakePhoto={(dataUri) => {
            capture(dataUri);
          }}
          isSilentMode
          isMaxResolution
          isImageMirror={false}
          sizeFactor={1}
          isFullscreen
        />
      ) : (
        <img src={prwImage} alt="" />
      )}
      {preview && (
        <>
          <button
            className="btn btn-sm btn-close btn-cancel"
            onClick={() => onReject()}
          >
            <i className="icon-close" />
          </button>
          <button
            className="btn btn-sm btn-close btn-confirm"
            onClick={() => onApprove()}
          >
            <i className="icon-tick" />
          </button>
        </>
      )}
    </div>
  );
};

const TaskImages = ({ task, files = [], images = [], getPhotoURL }) => {
  const [thumbnails, setThumbnails] = useState([]);
  const [isImageModalOpen, showImageModal] = useState(false);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setThumbnails([...images]);
  }, [images.length]);

  const onClickImage = (id) => {
    setIndex(id);
    showImageModal(true);
  };

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <div className="task-images-wrapper">
        {thumbnails.map((img, id) => (
          <a
            key={`task_image_${task.id}_${id}`}
            onClick={() => onClickImage(id)}
          >
            <img className="task-image" src={img} alt="" />
          </a>
        ))}
      </div>
      <Modal
        centered
        show={isImageModalOpen}
        onHide={() => showImageModal(false)}
      >
        <Modal.Header
          className="tb-modal-header pt-2 pb-0"
          closeButton
        ></Modal.Header>
        <Modal.Body className="pt-2 pb-3">
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            indicators={false}
            interval={false}
            prevIcon={<i className="icon-chevron-right" />}
            nextIcon={<i className="icon-chevron-right rotate-180" />}
          >
            {thumbnails.map((img, id) => (
              <Carousel.Item key={`task_crousel_${task.id}_${id}`}>
                <img className="img-fluid" src={img} alt="" />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StaffActivityCard;
