import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { Button, Container, SubContainer, Input, BackButton, Logo, LogoW } from "./SigninStyles";
import createHistory from "../../history";

class ForgetPass extends Component {
  state = {
    email: "",
    message: { type: '', text: '' }
  };

  onSubmit = () => {
    const { firebase } = this.props;
    const { email } = this.state;
    if (!email) return false;

    firebase
      .resetPassword(email)
      .then((data) => {
        console.log('reset email sent to' + email);
        this.setState({message: {type: 'success', text: 'Please check your email and click on the provided link to reset your password'}});
      })
      .catch((error) => {
        console.log('error in sending reset password link', error);
        this.setState({message: {type: 'danger', text: error.message}});
      })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { nextRoute, auth } = this.props;
    if (!isLoaded(auth)) {
      return null;
    }
    if (!isEmpty(auth)) {
      return (
        <Redirect
          to={{
            pathname: nextRoute
          }}
        />
      );
    }
    const { email, message } = this.state;

    return (
      <Container className="d-flex flex-column align-items-center justify-content-center">
        <SubContainer className="d-flex flex-column align-items-center">          
          <Logo src={require('../../assets/images/logoOnly.png')} alt="logo" />
          <LogoW src={require('../../assets/images/logoWordmarkBlack.png')} alt="logo" />
          <Input
            id="email"
            name="email"
            value={email}
            onChange={this.handleChange}
            placeholder="Email Address"
            className="mb-4"
            style={{ marginTop: 30 }}
          />
          <Button
            onClick={this.onSubmit}
            style={{ marginTop: 30 }}
          >
            <div style={{ color: 'white', fontWeight: 'bold', fontSize: 14, fontFamily: 'Nunito' }}>Send Reset Link</div>
          </Button>
          <BackButton onClick={() => createHistory.goBack()} className="mt-3" style={{ marginBottom: 60 }}>
            <div style={{ color: '#3B3B3B', fontSize: 14, fontFamily: 'Nunito' }}>Back</div>
          </BackButton>
          <p style={{width: 220}} className={`text-${message.type}`}><small>{message.text}</small></p>
        </SubContainer>
        <img src={require('../../assets/images/building.png')} className='col-12' alt="Logo" style={{ position: 'absolute', bottom: 0, zIndex: 100 }} />
      </Container>
    );
  }
}

ForgetPass.propTypes = {
  auth: PropTypes.object.isRequired,
  authError: PropTypes.object.isRequired,
  firebase: PropTypes.shape({
    login: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  authError: state.firebase.authError,
  nextRoute: state.navigation.nextRoute
});

export default compose(
  connect(
    mapStateToProps,
  ),
  firebaseConnect()
)(ForgetPass);
