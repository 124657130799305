/* eslint-disable arrow-body-style */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-template */
/* eslint-disable react/no-array-index-key */
import React from "react";
import * as _ from "lodash";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
} from "recharts";

const NotAxisTickButLabel = (props) => {
  return (
    <g transform={"translate(" + props.x + "," + props.y + ")"}>
      <text
        x={0}
        y={0}
        dy={0}
        fontFamily="Inter"
        fontSize="16px"
        fontWeight={500}
        textAnchor="end"
        fill="#434343"
      >
        {props.payload.value}
      </text>
    </g>
  );
};

const HBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        width={900}
        height={400}
        layout="vertical"
        data={data}
        margin={{ top: 20, right: 25, left: 90, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <XAxis
          allowDecimals={false}
          type="number"
          tick={<NotAxisTickButLabel />}
          tickLine={false}
          tickMargin={12}
          axisLine={{ stroke: "#DADADA", strokeDasharray: 2 }}
          tickCount={5}
        />
        <YAxis
          type="category"
          dataKey="System"
          tick={<NotAxisTickButLabel />}
          tickLine={false}
          tickMargin={12}
          axisLine={{ stroke: "#DADADA", strokeDasharray: 2 }}
        />
        <Tooltip />
        <Bar stackId="a" dataKey="Normal" barSize={6} radius={[10, 0, 0, 10]}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill="#01D09F" />
          ))}
        </Bar>
        <Bar stackId="a" dataKey="Warning" barSize={6}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill="#FFDB1C" />
          ))}
        </Bar>
        <Bar stackId="a" dataKey="Critical" barSize={6} radius={[0, 10, 10, 0]}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill="#FF8D8D" />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HBarChart;
