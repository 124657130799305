/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
/* eslint-disable arrow-body-style */
import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { Navbar, NavItem, Link, Heading, SubHeading } from "./styles";

import Zones from "./Zones";
import Users from "./Users";
import useWindowSize from "../../Hooks/useWindowSize";

const AdminSettings = ({ match, ConsViews, firestore, auth }) => {
  const { width: WindowWidth } = useWindowSize();
  const { Level, ConsViewId } = ConsViews || {};
  const [levelInfo, setLevelInfo] = React.useState(null);
  React.useEffect(() => {
    if (!auth.uid) return;
    function fetchConsolidated(viewId) {
      // let viewID = viewId;
      // const cons_id_match = viewId.match(/^[^_]+(?=_)/g); // Get string before first underscore
      // if (cons_id_match) {
      //   const number = Number(cons_id_match);
      //   if (!isNaN(number)) {
      //     viewID = viewId.replace(/^[^_]*_/, "");
      //   }
      // }
      // console.log("viewId :", viewID);
      firestore
        .get({
          collection: "consolidated",
          doc: viewId, // Remove text upto first underscore (_)
        })
        .then((doc) => {
          setLevelInfo({
            Level,
            MaxLevel: doc.data().MaxLevel,
            ConsViewId,
            UID: auth.uid,
            Zones: doc.data().Zones,
            Email: auth.email,
          });
        });
    }
    // Retrieve Consolidated
    fetchConsolidated(ConsViewId);
  }, [ConsViewId, auth]);

  return (
    <div className="d-flex flex-row flex-wrap m-4">
      <div className="col-lg-12 col-md-12 col-sm-12">
        {WindowWidth <= 768 ? (
          <div style={{ marginTop: "1.25rem" }}>Admin Settings</div>
        ) : null}
        <div style={{ margin: "15px 0px" }}>
          <Heading>Admin Settings</Heading>
          <SubHeading>Manage Zones, Properties and Users</SubHeading>
        </div>
        <Navbar>
          <ul className="nav">
            <NavItem className="nav-item">
              <Link
                className="nav-link"
                activeClassName="active"
                to={`${match.url}/zones`}
              >
                Zones
              </Link>
            </NavItem>

            <NavItem className="nav-item">
              <Link
                className="nav-link"
                activeClassName="active"
                to={`${match.url}/users`}
              >
                Users
              </Link>
            </NavItem>
          </ul>
        </Navbar>
        <Switch>
          <Route
            path={`${match.path}/zones`}
            render={() => <Zones levelInfo={levelInfo} />}
          />

          <Route
            exact
            path={`${match.path}/users`}
            render={() => <Users levelInfo={levelInfo} />}
          />
          <Redirect to={`${match.path}/zones`} />
        </Switch>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  ConsViews: state.firestore.ordered.ConsViews[0],
  auth: state.firebase.auth,
});
export default compose(
  withRouter,
  connect(mapStateToProps),
  firestoreConnect()
)(AdminSettings);
