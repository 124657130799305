import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import ReactSwipeButton from 'react-swipe-button';
import SystemHistoryCard from '../SystemHistoryCard/SystemHistoryCard';

class SystemHealthCard extends Component {

  updateSystemStatus = (docId, value) => {
    const { firestore, user, currentApartment } = this.props;
    const { DisplayName } = user[0];
    firestore.update({
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [{ collection: 'SystemEquipmentDetails', doc: docId }]
    }, { EquipmentStatus: value, LastUpdatedBy: DisplayName });
  }

  getCmpContacts(EquipmentName, ComponentName, contacts) {
    const { location } = this.props;
    const ServiceName = decodeURI(location.search.substring(1));
    return contacts && contacts.filter(c => 
      c.ServiceName === ServiceName && 
      c.EquipmentName === EquipmentName && 
      c.ComponentName === ComponentName &&
      c.Type === 'Component'
    );
  }

  showHistory = () => {
    const { 
      EquipmentName, ComponentName, isHistoryLoading, historyLength, 
      closeHistory, toggleFullhistory, mapCondition, sysEqpDetails,
      deleteContact, onSaveContactCard, contactInfo
    } = this.props;

    const cmpContacts = this.getCmpContacts(EquipmentName, ComponentName, contactInfo);
    return (
      <SystemHistoryCard {
        ...{EquipmentName, ComponentName, isHistoryLoading, 
        historyLength, closeHistory, toggleFullhistory, cmpContacts,
        mapCondition, sysEqpDetails, deleteContact, onSaveContactCard
      }}/>
    )
  }

  async markAllAudited(readings){
    const { firestore, user, location, systemDetails, currentApartment} = this.props;
    
    const ServiceName = decodeURI(location.search.substring(1));
    const systemCard = systemDetails && systemDetails.filter(e => e.Name === ServiceName)[0];
    const ComponentName = readings[0].ComponentName;

    await firestore.get({
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [{
        collection: 'SystemDetails',
        doc: systemCard.id,
        subcollections: [{ 
            collection: 'system_component_details'
        }],
      }],
      storeAs: 'SystemComponentDetails'
    })

    const updateId = this.props.systemComponentDetails.filter(e => e.ComponentName === ComponentName)[0].id;

    await firestore.update({
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [{
        collection: 'SystemDetails',
        doc: systemCard.id,
        subcollections: [{ 
          collection: 'system_component_details', doc: updateId 
        }],
      }]
    }, { AuditStatus : "Audited" });

  }

  isAnyAudit(equipments){
    const idx = equipments.findIndex(e => e.EquipmentStatus === 'Under Audit');
    if(idx > -1){
      return true;
    }
    return false;
  }

  render() {
    const { mapCondition, sysEqpDetails, inspectionValues, isHistoryOpen, openHistory, isMobile } = this.props;
    return (
      <div className="card system-card border-0 mt-4 mt-md-0">
        <div className={`history-box ${isHistoryOpen && `open`}`}>
          {this.showHistory()}
        </div>
        <div className="card-body">
          { mapCondition && sysEqpDetails[mapCondition].map((readings, i) => (
            <div className="form-group d-flex justify-content-between align-items-center" key={i}>
              <p className="system-status">
                <span className="helper">{readings.EquipmentName}</span>
                <label className="col-form-label">
                  <span className={['badge-indicator', readings.Indicator && readings.Indicator.toLowerCase()].join(' ')} />
                  <select value={readings.EquipmentStatus} onChange={(e) => this.updateSystemStatus(readings.id, e.target.value)}>
                    { (readings.ValueDictionary || []).map((value, i) => 
                      <option key={i} value={value} disabled={value === 'Under Audit'}>{value}</option>) 
                    }
                  </select>
                </label>
              </p>
              <button className="btn btn-sm btn-outline-primary d-flex align-items-center"
                onClick={() => openHistory(readings.EquipmentName, readings.ComponentName)}>
                <span><i className="icon-history" /></span>
                <span className="d-none d-md-inline ml-2">History</span>
              </button>
            </div>
          ))
          }
          {mapCondition && this.isAnyAudit(sysEqpDetails[mapCondition]) ? 
            <div className="bulk-audit-action">
              {!isMobile
                  ? <button className="btn btn-success btn-bulk-update" onClick={(e) => this.markAllAudited(sysEqpDetails[mapCondition])}>Mark All As Audited</button> 
                  : <ReactSwipeButton 
                    text='Mark All As Audited'
                    color='#28a745'
                    text_unlocked='Audited'
                    onSuccess={(e) => this.markAllAudited(sysEqpDetails[mapCondition])}
                  /> 
              }
            </div>     
          : null }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.firestore.ordered.Users,
  systemEquipmentDetails: state.firestore.ordered.SystemEquipmentDetails,
  historicalSysEqpDetails: state.firestore.ordered.HistoricalSysEqpDetails,
  serviceIntervalDetails: state.firestore.ordered.ServiceIntervalDetails,
  systemDetails: state.firestore.ordered.SystemDetails,
  systemComponentDetails: state.firestore.ordered.SystemComponentDetails,
  currentApartment: state.apartment.currentApartment,
});

export default compose(
  connect(mapStateToProps, null), 
  firestoreConnect(),
  withRouter
  )(SystemHealthCard);

