import React from 'react'
import { BackButton } from '../../Common/Buttons'
import DetailsHeader from '../../Common/DetailsHeader/DetailsHeader'
import useWindowSize from '../../Hooks/useWindowSize'
import image from './properties.png'

const Properties = () => {
    const { width: WindowWidth } = useWindowSize()
    return (
        <div className="d-flex flex-row flex-wrap m-4">
            <div className="col-lg-12 col-md-12 col-sm-12">
                {WindowWidth<=768?<div style={{marginTop: "1.25rem"}}>Properties</div>:null}
                <div className="exe-details-header-container d-flex flex-row justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                        <BackButton />
                        <DetailsHeader label="Properties" description="Description to be defined" />                                                                                                                                                                        
                    </div>                                    
                </div>
                <img src={image} alt="property" style={{width: "100%", marginTop: "10px"}}/>                
            </div>
        </div>
    )
}

export default Properties