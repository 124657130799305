import React from 'react';
import './Avatar.scss';

const Avatar = ({ name, imgUrl, onClick, className,  size = 35 }) => (
    <div className={`d-inline-block trak-bot-avatar rounded-circle ${className || ''}`} onClick={onClick} style={{ height: size, width: size }}>
        {
            imgUrl ? 
            <img src={imgUrl} alt="avtar" /> : 
            <span className="initial">{name && name.charAt(0)}</span>
        }
    </div>
)

export default Avatar
