import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import DOMPurify from "dompurify";
import * as _ from "lodash";
import Loading from "../common/Loading/Loading";
import createHistory from "../../history";
import "./Reports.css";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      activeSystem: 0,
      photoURL: require("../../assets/images/default_dp.svg"),
    };
    this.componentNameRef = React.createRef();
    this.subMenuRef = React.createRef();
    this.subMenuIconRef = React.createRef();
  }

  componentDidMount() {
    const { user, currentApartment } = this.props;
    const { PhotoURL } = user[0];

    const width = this.getWidth();
    this.setState({ isMobile: width < 768 });
    window.addEventListener("resize", this.reportWindowSize);
    document.addEventListener("click", this.handleClickOutside);
    if (user.length !== 0 && currentApartment) {
      this.fetchData(currentApartment);
    }
    if (user && PhotoURL) {
      this.getPhotoURL(PhotoURL);
    }
  }
  componentDidUpdate(prevProps) {
    const { currentApartment } = this.props;
    if (currentApartment && prevProps.currentApartment !== currentApartment) {
      this.fetchData(currentApartment);
    }
  }
  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  reportWindowSize = (e) => {
    const { isMobile } = this.state;
    if (isMobile) {
      if (e.target.innerWidth > 768) {
        this.setState({ isMobile: false });
      }
    } else if (e.target.innerWidth < 769) {
      this.setState({ isMobile: true });
    }
  };

  handleClickOutside = (event) => {
    const { isModuleSidebarOpen, isMobile } = this.state;
    if (
      isMobile &&
      this.subMenuRef.current &&
      !this.subMenuRef.current.contains(event.target) &&
      !this.subMenuIconRef.current.contains(event.target) &&
      isModuleSidebarOpen
    ) {
      this.setState({ isModuleSidebarOpen: false });
    }
  };

  fetchData = (currentApartment) => {
    const { reports, firestore } = this.props;
    const ReportsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "Reports" }],
      storeAs: "Reports",
    };
    if (!reports || !this.ReportsSnapShot) {
      if (this.ReportsSnapShot) {
        this.ReportsSnapShot();
      }
      this.ReportsSnapShot = firestore.onSnapshot(ReportsOptions);
    }
  };

  getPhotoURL = async (path) => {
    const { firebase } = this.props;
    const storage = firebase.storage();
    const url = await storage.ref().child(path).getDownloadURL();
    this.setState({ photoURL: url });
  };

  showSideNav = () => {
    setTimeout(() => this.props.openSideBar(false));
  };

  onClickSideItem = (i) => {
    this.setState({
      activeSystem: i,
      ModuleName: "",
      isModuleSidebarOpen: false,
    });
    setTimeout(() => {
      const left =
        document.querySelector(
          ".system-names .list-group .list-group-item.active"
        ).offsetLeft - 50;
      this.componentNameRef.current.scrollLeft = left;
    }, 0);
  };

  showComponentSideBar() {
    const { isModuleSidebarOpen, activeSystem } = this.state;
    const { reports } = this.props;

    return (
      <div
        className={["component-sidebar", isModuleSidebarOpen && "open"].join(
          " "
        )}
        ref={this.subMenuRef}
      >
        <a
          className="close"
          onClick={() => this.setState({ isModuleSidebarOpen: false })}
        >
          <i className="icon-close" />
        </a>
        <ul className="list-group">
          {reports &&
            reports.map((module, i) => (
              <a
                className={`list-group-item d-flex justify-content-between align-items-center${
                  activeSystem === i ? ` active` : ``
                }`}
                key={i}
                onClick={() => this.onClickSideItem(i)}
              >
                {module.Name}
              </a>
            ))}
        </ul>
      </div>
    );
  }

  showMobileNavbar() {
    const { user } = this.props;
    const { isMobile, photoURL } = this.state;
    const { avatarUrl } = user[0];

    if (isMobile) {
      return (
        <nav className="navbar-mobile">
          <a className="menu-button mr-2" onClick={this.showSideNav}>
            <i className="icon-menu" />
          </a>
          <img
            alt="logo"
            src={require("../../assets/images/logoOnly.png")}
            style={{ height: "1.65rem" }}
          />
          <div className="float-right ml-auto d-flex align-items-center">
            <img
              alt="logo"
              src={photoURL}
              style={{ height: 30, width: 30, borderRadius: 30 }}
              onClick={() => createHistory.push(`/profile`)}
            />
          </div>
        </nav>
      );
    }
  }

  getThemeColor = () => {
    const { AppartmentDetails } = this.props;
    const { isMobile } = this.state;
    if (isMobile && AppartmentDetails[0].Header_Theme_Color) {
      return AppartmentDetails[0].Header_Theme_Color;
    }
    return null;
  };

  render() {
    const { reports } = this.props;
    const { activeSystem, isMobile } = this.state;
    return (
      <div id="reports">
        {this.showComponentSideBar()}
        <div
          className="system-header d-flex justify-content-between align-items-center"
          style={{ backgroundColor: this.getThemeColor() }}
        >
          <div className={`${isMobile ? "d-flex flex-column" : ""}`}>
            <h4 className="order-2">Reports</h4>
          </div>
          <div className="d-flex">
            {/* { isMobile && <span>{taskDetails.length} tasks</span>} */}
          </div>
        </div>
        {reports ? (
          <div className="row system-body mobile">
            <div
              className="col-md-3 system-names d-flex d-md-block"
              style={{ backgroundColor: this.getThemeColor() }}
            >
              {isMobile && (
                <a
                  className="menu d-flex align-items-center"
                  onClick={() => this.setState({ isModuleSidebarOpen: true })}
                  ref={this.subMenuIconRef}
                >
                  <i className="icon-hamburger" />
                </a>
              )}
              <ul className="list-group" ref={this.componentNameRef}>
                {reports.map((module, i) => (
                  <a
                    className={`list-group-item d-flex justify-content-between align-items-center${
                      activeSystem === i ? ` active` : ``
                    }`}
                    key={i}
                    onClick={() =>
                      this.setState({ activeSystem: i, ModuleName: "" })
                    }
                  >
                    {module.Name}
                  </a>
                ))}
              </ul>
            </div>
            <div className="col">
              <div className="card system-card border-0 pt-4 pt-md-0 mt-4 mt-md-0">
                {!isMobile ? (
                  reports[activeSystem].Web_iframe ? (
                    <div
                      className="card-body"
                      dangerouslySetInnerHTML={{
                        __html: reports[activeSystem].Web_iframe,
                      }}
                    />
                  ) : (
                    <div className="card-body">
                      The report is not currently availbale for web
                    </div>
                  )
                ) : reports[activeSystem].Mobile_iframe ? (
                  <div
                    id="mobile-iframe"
                    className="card-body"
                    dangerouslySetInnerHTML={{
                      __html: reports[activeSystem].Mobile_iframe,
                    }}
                  />
                ) : (
                  <div className="card-body">
                    The report is not currently availbale for mobile
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <Loading showLogo="false" />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openSideBar: (value) => dispatch({ type: "SIDEBAR_OPEN", payload: value }),
});

const mapStateToProps = (state) => ({
  user: state.firestore.ordered.Users,
  reports: state.firestore.ordered.Reports,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  currentApartment: state.apartment.currentApartment,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect()
)(Reports);
