/* eslint-disable prefer-template */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Legend,
} from "recharts";
// import moment from "moment";

import createHistory from "../../../../../../history";

// const rawData = [
//   {
//     date: "17/11",
//     Red: 1,
//     Amber: 5,
//   },
//   {
//     date: "18/11",
//     Red: 5,
//     Amber: 5,
//   },
//   {
//     date: "19/11",
//     Amber: 6,
//   },
//   {
//     date: "20/11",
//     Red: 0,
//     Amber: 0,
//   },
//   {
//     date: "21/11",
//     Red: 0,
//     Amber: 5,
//   },
//   {
//     date: "22/11",
//     Red: 9,
//     Amber: 5,
//   },
//   {
//     date: "23/11",
//     Red: 1,
//     Amber: 1,
//   },
// ];

const NotAxisTickButLabel = (props) => (
    <g transform={"translate(" + props.x + "," + props.y + ")"}>
      <text
        x={0}
        y={0}
        dy={0}
        fontFamily="Inter"
        fontSize="12px"
        fontWeight={500}
        textAnchor="middle"
        fill="#888888"
      >
        {props.payload.value}
      </text>
    </g>
  );
const VBarChart = ({ height, data, config }) => {
  const { StatusToLabel } = config || {Amber: "N/A", Red: "N/A"};
  return (
    <ResponsiveContainer width="100%" height={height}>
      <BarChart
        width={450}
        height={height}
        data={data}
        margin={{ top: 15, right: 20, left: 10, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        {/* <XAxis dataKey="date" /> */}
        <XAxis
          dataKey="date"
          tickLine={false}
          tick={<NotAxisTickButLabel />}
          tickMargin={12}
          axisLine={{ stroke: "#DADADA", strokeDasharray: 2 }}
        />
        <YAxis
          tickLine={false}
          tick={<NotAxisTickButLabel />}
          tickMargin={12}
          axisLine={{ stroke: "#DADADA", strokeDasharray: 2 }}
        />
        <Tooltip />
        <Legend
          verticalAlign="top"
          iconSize={16}
          height={50}
          // margin={{ top: 100, left: 0, right: 0, bottom: 100 }}
        />
        <Bar
          onClick={() => createHistory.push(config.route)}
          dataKey={StatusToLabel ? StatusToLabel.Red : ""}
          stackId="todo"
          barSize={20}
          // label={{ fill: "#FF0000", fontSize: 10 }}
          fill="#FF0000"
          label={false}
        />
        <Bar
          onClick={() => createHistory.push(config.route)}
          // createHistory.push(
          //   `${config.route}?date=${moment(e.date, "DD/MM").format(
          //     "DD/MM/YYYY"
          //   )}`
          // )
          // }
          dataKey={StatusToLabel ? StatusToLabel.Amber : ""}
          stackId="todo"
          barSize={20}
          // label={{ fill: "#FFBF00", fontSize: 10 }}
          label={false}
          fill="#FFBF00"
        />
        {/* <Bar dataKey="Closed" barSize={10} fill="#01D09F" radius={[10, 10, 0, 0]} />                 */}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default VBarChart;
