import React from "react";
import "./ContactCard.css";
// import image from "../../../../../assets/images/avatar.png";
const classNames = require("classnames");

const ContactCard = ({ name, userRole, contact, hideUnderline }) => {
  return (
    <div
      className={classNames("d-flex", { "p-1": !hideUnderline })}
      style={!hideUnderline ? { borderBottom: "solid 1px #eaedf3" } : null}
    >
      {/* <img
            className="rounded-circle"
            style={{ objectFit: 'cover' }}
            src={image}
            height="38px"
            width="38px"
            alt=""
            /> */}
      <div className="pl-1">
        <p
          className="mb-0"
          style={{ fontFamily: "Inter", fontWeight: 600, fontSize: "14px" }}
        >
          {name}
        </p>
        <p
          className="mb-0 text-secondary"
          style={{ fontFamily: "Inter", fontSize: "12px" }}
        >
          {userRole}, {contact}
        </p>
      </div>
    </div>
  );
};

export default ContactCard;
