import React, { Component } from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components';

export default class SearchComponent extends Component {
  state = {
    activeSearch: false,
    inputValue: ''
  }

  inputFocus = utilizeFocus()

  static propTypes = {
    query: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    query: '',
    placeholder: '',
    onChange: () => { }
  } 

  handleBlur = (e) => {
    if (!e.target.value) this.setState({activeSearch: false});
  }

  openSearchBar = () => {
    this.inputFocus.setFocus();
    this.setState({activeSearch: true});
  }

  onChange = (e) => {
    const { onChange } = this.props;
    this.setState({inputValue: e.target.value});
    onChange(e.target.value);
  }

  clearInput = (e) => {
    const { onChange } = this.props;
    this.setState({inputValue: ''});
    onChange('');
    this.inputFocus.setFocus();
  }

  render() {
    const { query, placeholder } = this.props;
    const { activeSearch, inputValue } = this.state;

    return (
      <div className="search-component" style={{position: 'relative'}}>
        <SearchInput type="text" 
          value={query || inputValue} 
          placeholder={placeholder}
          onChange={this.onChange}
          active={activeSearch ? true : false}
          onBlur={this.handleBlur}
          ref={this.inputFocus.ref}
          
        />
        <Button onClick={this.openSearchBar} primary active={activeSearch ? true : false}>
          <i className="icon-search"></i>
        </Button>
        <Button onClick={this.clearInput} danger small active={inputValue ? false : true}>
          <i className="icon-close"></i>
        </Button>
      </div>
    )
  }
}

const utilizeFocus = () => {
  const ref = React.createRef();
  const setFocus = () => {ref.current &&  ref.current.focus()};

  return {setFocus, ref} 
}

const SearchInput = styled.input`
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid #558dca;
  background: transparent;
  font-size: 0.85rem;
  min-width: 250px;
  transform: ${props =>  props.active ? `rotateY(0)` : `rotateY(90deg)`};
  opacity: ${props => props.active ? `1` : `0`};
  transform-origin: right;
  transition: all ease 450ms;
  @media (max-width: 768px) { 
    min-width: 120px;
    max-width: 120px;
    font-size: 0.75rem;
    margin-top: 0;
  }
`;

const Button  = styled.button`
  background-color: transparent;
  position: absolute;
  right: 0;
  top: ${props => props.danger ? `0.375rem` : `0.25rem`};
  border: 0;
  box-shadow: none !important;
  outline: 0 !important;
  padding: 0;
  color: ${props => props.danger ? `#ff6a6a` : `#558dca`};
  font-size: ${props => props.small ? `0.625rem` : `0.85rem`};
  transform: ${props =>  props.active ? `scale(0)` : `scale(1)`};
  opacity: ${props => props.active ? `0` : `1`};
  transform-origin: center;
  transition: all ease 300ms;
`;