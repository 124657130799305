import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import QrReader from 'react-qr-reader';
import createHistory from '../../../history';
import CordovaQRScanner from '../CordovaQRScanner/CordovaQRScanner'
import './QRScanner.css';

class QRScanner extends Component {
  state = {
    result: 'No result'
  }

  handleScan = result => {
    const { toggleQrModal, currentApartment } = this.props;    
    if (!result) return false;
    let data = result.split('~');
    const apartmentName = data[0];
    if (currentApartment !== apartmentName) return false;

    data.splice(0, 1);
    data = data.reduce((acc, curr) => {
      const prop = curr.split(':');
      acc[`${prop[0]}`] = prop[1];
      return acc;
    }, {});

    createHistory.push(`${data.Routing}?${data.System}`);
    toggleQrModal(false, data);
    console.log(data);
  }
  
  handleError = err => {
    console.error(err)
  }

  render() {
    const { isQrModalOpen, toggleQrModal } = this.props;

    return (
      <Modal className="d-flex" 
        dialogClassName="qr-modal"
        show={isQrModalOpen}
        backdrop={window.cordova ? false : 'static'}
        size="lg">
        <Modal.Body style={{backgroundColor: window.cordova ? 'transparent' : null}}>
          <button onClick={() => toggleQrModal(false)} className="close"><i className="icon-close" /></button>
          {!window.cordova? <QrReader
            delay={300}
            onError={this.handleError}
            onLoad={(data) => console.log(data)}
            onScan={this.handleScan}
            showViewFinder
            className="qr-scanner"
            style={{ width: '100%', margin: 'auto', alignSelf: 'center' }}
          />
          : <CordovaQRScanner />}
        </Modal.Body>
      </Modal>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  toggleQrModal: (val, data) => dispatch({ type: "TOGGLE_QR_MODAL", payload: {val, data} })
})

const mapStateToProps = state => ({
  user: state.firestore.ordered.Users,
  isQrModalOpen: state.navigation.isQrModalOpen,
  currentApartment: state.apartment.currentApartment,
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(QRScanner);
