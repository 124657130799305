import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import createHistory from '../../../history';
import './CordovaQRScanner.css'

 class CordovaQRScanner extends Component {

    componentDidMount = () => {
        const { toggleQrModal, currentApartment } = this.props
        if (window.cordova) {
          window.QRScanner.prepare((err, status) => {
            if (err) return false;
            if (status.authorized) {
              document.getElementById('root').style.backgroundColor =  'transparent';
              document.getElementById('root').style.visibility =  'hidden';
              window.QRScanner.show(() => {
                window.QRScanner.scan((docErr, result) => {
                  if (docErr) return false;
                  if (!result) return false;                    
                    let data = result.split('~');
                    const apartmentName = data[0];
                    if (currentApartment !== apartmentName) return false;

                    data.splice(0, 1);
                    data = data.reduce((acc, curr) => {
                    const prop = curr.split(':');
                    acc[`${prop[0]}`] = prop[1];
                    return acc;
                    }, {});

                  createHistory.push(`${data.Routing}?${data.System}`);
                  toggleQrModal(false);
                  window.QRScanner.destroy();
                  document.getElementById('root').style.visibility =  'visible';
                  document.getElementById('root').style.backgroundColor =  '#eff3fb';
                });
              });
            }
          });
          return false;
        }
      };
     
    goBack=()=>{
      const {toggleQrModal} = this.props
      document.getElementById('root').style.visibility =  'visible';
      document.getElementById('root').style.backgroundColor =  '#eff3fb';
      toggleQrModal(false);
      // window.QRScanner.destroy();
    }  
    
    render() {
        return (
            <>
        <nav className="navbar py-3" style={{ padding: '1rem 1.25rem', boxShadow: 'none' }}>
            <div className="d-flex" style={{ marginTop: 4, }}>
                <a className="mr-4" onClick={this.goBack}>
                    <i className="icon-chevron-right" style={{ color: 'white', fontSize: 18 }} />
                </a>
            <div className="navbar-nav mr-auto d-flex justify-content-center">
              <h6 className='building_name mb-0'>QR Scanner</h6>
            </div>   
        </div>
        </nav>
        <div className="d-flex vh-100 justify-content-center align-items-center">
            <div className="scanner-container" />
        </div>
        </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
  toggleQrModal: (val) => dispatch({ type: "TOGGLE_QR_MODAL", payload: { val } })
})

const mapStateToProps = state => ({
  user: state.firestore.ordered.Users,
  currentApartment: state.apartment.currentApartment,
})
  
export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(CordovaQRScanner);
  
