import React, { Component } from 'react';
import { useState } from 'react';
import { Accordion, Card, Modal } from 'react-bootstrap';
import moment from 'moment';
import * as _ from 'lodash';
import StaffDetails from './StaffDetails';

export default class AttendanceNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0,
      activeStaffTab: 0
    }
  }

  sortStaffs(list) {
    return  list.sort((a, b) => {
      return b.PunchInTime && a.PunchInTime && b.PunchInTime.toDate().getTime() - a.PunchInTime.toDate().getTime()
    }).sort((a, b) => {
      return (a.Name || '').localeCompare(b.Name);
    }).sort((a, b) => {
      const colors = { green: 'a', grey: 'b', red: 'c' };

      return colors[a.Indicator.toLowerCase()].localeCompare(colors[b.Indicator.toLowerCase()])
    });
  }

  getOnlineCount(data, type) {
    const { total, online } = Object.keys(data[type]).reduce((acc, employeeId) => {
      acc.total = acc.total + 1;
      if (data[type][employeeId][0] && data[type][employeeId][0].IsUserOnline) {
        acc.online = acc.online + 1;
      }
      return acc;
    }, { total: 0, online: 0 });

    return { total, online };    
  }

  render() {
    const { attendance, getPhotoURL } = this.props;
    const { activeKey, activeStaffTab } = this.state;

    let staffs = attendance.filter(a => (activeStaffTab === 0 || (activeStaffTab === 1 && a.IsUserOnline) || activeStaffTab === 2 && !a.IsUserOnline))
    staffs = this.sortStaffs(staffs);
    let grouped = _.groupBy(staffs, 'ServiceArea');
    grouped = Object.keys(grouped).reduce((acc, serviceName) => {
      acc[serviceName] = _(grouped[serviceName]).groupBy('EmployeeDeviceId').sortBy(group => grouped[serviceName].indexOf(group[0])).value();
      return acc;
    }, { });

    const tableHeads = [
      {key: 'PunchInTime', value: 'In Time'}, {key: 'PunchOutTime', value: 'Out Time'}, 
      {key: 'HoursSpend', value: 'Hours Spend'}, {key: 'Status', value: 'Status'}
    ];

    return (
      <div className="card system-card border-0 mt-2 mt-md-0">
        <div className="tab-headers sticky-top">
          <button onClick={() => this.setState({activeStaffTab: 0})}
            className={`border-indicator primary${activeStaffTab === 0 ? ` active` : ``}`}>
            All Staff
          </button>
          <button onClick={() => this.setState({activeStaffTab: 1})}
            className={`border-indicator success${activeStaffTab === 1 ? ` active` : ``}`}>
            Online Staff
          </button>
          <button onClick={() => this.setState({activeStaffTab: 2})}
            className={`border-indicator danger${activeStaffTab === 2 ? ` active` : ``}`}>
            Offline Staff
          </button>
        </div>
        <div className="card-body py-2">
          { attendance && attendance.length ? <>
          <table className="table">
            <thead>
              <tr>
              <th width="40%">Employee</th>
              { tableHeads.map((head, key) => (
              <th key={key}>{head.value}</th>
              )) }
              </tr>
            </thead>
          </table>
          <Accordion activeKey={activeKey}>
          { Object.keys(grouped).sort((a, b) => a.localeCompare(b)).map((attd, i) => (
            <Card key={'attendance-now-'+attd.replace(/\s/, '-').toLowerCase()}>
              <Accordion.Toggle as={Card.Header} eventKey={i}
                onClick={() => this.setState({activeKey: activeKey !== i ? i : '99'})}>
                <i className={`icon-chevron-down${activeKey === i ? ` open` : ``}`}></i>
                <span className="font-weight-bold ml-2">{attd}</span>
                { activeStaffTab === 0 && 
                  <small className="ml-1 text-secondary">
                    ({this.getOnlineCount(grouped, attd).online} of {this.getOnlineCount(grouped, attd).total})
                  </small> }
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={i}>
                <Card.Body>
                  <table className="table">
                    <tbody>
                    { Object.keys(grouped[attd]).map(employeeId => (
                      <StaffRow key={grouped[attd][employeeId][0].id} {...{tableHeads, getPhotoURL}} staffGroup={grouped[attd][employeeId]} />
                    ))}
                    </tbody>
                  </table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )) }
          </Accordion>
          { !Object.keys(grouped).length && <p className="mb-0">No staffs data here...</p> }
          </> : 
          <div className="py-5">
            <img src={require('../../../assets/images/attendanceEmpty.png')} className="mx-auto mb-3" width="200" alt=""/>
            <p className="text-center">No Attendance data here</p>
          </div>
          }
        </div>
      </div>
    )
  }
}

const StaffRow = ({staffGroup, tableHeads, getPhotoURL}) => {
  const [isModalOpen, toggleModal] = useState(false);

  const getDiffDays = (day1, day2) => {
    if (!day1 || !day2) return 0;

    const date1 = day1.toDate();
    const date2 = day2.toDate();
    const diffDays = Math.abs(date2.getDate() - date1.getDate());
    return diffDays;
  }

  return (
    <>
    <tr>
      <td width="40%">
        <StaffDetails staff={staffGroup[0]} staffPhoto={staffGroup[0].ImageURL} getPhotoURL={getPhotoURL}/>
      </td>
      { tableHeads.map((head, i) => (
      <td key={i} data-attr={head.key !== 'Status' ? head.value : ''} 
        className={head.key === 'HoursSpend' ? `text-color ${staffGroup[0].Indicator.toLowerCase()}` : ``}>

        {staffGroup[0][head.key] && staffGroup[0][head.key].seconds ? 
        moment(staffGroup[0][head.key].toDate()).format('h:mm a') : (staffGroup[0][head.key] || '-')}
        
        {/* Show +<diff> if attendance date and punch out date is different */}
        { head.key === 'PunchOutTime' && getDiffDays(staffGroup[0].AttendanceDate, staffGroup[0].PunchOutTime) > 0 && 
        <small className="text-danger ml-2">+{getDiffDays(staffGroup[0].AttendanceDate, staffGroup[0].PunchOutTime)}d</small> }
        
        {/* Show alert icon if multiple swipe in */}
        { head.key === 'Status' && staffGroup.length > 1 && 
        <a className="cursor-pointer ml-2 text-warning" onClick={() => toggleModal(true)}>
          <i className="icon-2x icon-alert"></i>
        </a> }

      </td>
      )) }
    </tr>
    <Modal centered show={isModalOpen} id="staff-attendance" onHide={() => toggleModal(false)}>
      <Modal.Header className="tb-modal-header pt-3 pb-0 px-4" closeButton>
        <StaffDetails staff={staffGroup[0]} staffPhoto={staffGroup[0].ImageURL} getPhotoURL={getPhotoURL}/>
      </Modal.Header>
      <Modal.Body className="tb-modal-body pt-2 pb-0 px-4">
        <table className="table">
          <thead>
            <tr>
              { tableHeads.map((head, key) => (
              <th key={key}>{head.value}</th>
              )) }
            </tr>
          </thead>
          <tbody>
          { staffGroup.map(emplAttd => (
            <tr id={`detail-${emplAttd.id}`} key={`detail-${emplAttd.id}`}>
              { tableHeads.map((head, i) => (
              <td key={i} data-attr={head.value}
                className={(head.key === 'DaysException' || head.key === 'HoursSpend') ? `text-color ${emplAttd.Indicator.toLowerCase()}` : ``}>
                {emplAttd[head.key] && emplAttd[head.key].seconds ? 
                moment(emplAttd[head.key].toDate()).format('h:mm a') : (emplAttd[head.key] || '-')}
              </td>
              )) }
            </tr>
          ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer className="tb-modal-footer pt-2 pb-3">
        <button className="btn btn-sm btn-primary" onClick={() => toggleModal(false)}>Close</button>
      </Modal.Footer>
    </Modal>
    </>
  )
}
