import React from 'react'
import styled from "styled-components";
import './Button.css'

const Button = ({label, click}) => (        
        <button className="widget-button" onClick={()=> click()}>View All</button>        
    )


export const ViewAll = styled.a`
background-color: transparent;
text-decoration: underline !important;
border: none;
font-size: 1rem;
color: #494949 !important;
font-family: 'Nunito';
margin-left: 0.75rem;
transition: all ease 400ms;
cursor: pointer;
white-space: nowrap;
@media (max-width: 768px) { 
  font-size: 0.5625rem;
  text-decoration: none !important;
}
`;

export default Button