import React, { Component, useState } from 'react';
import './ContactsInfo.css';
import { useEffect } from 'react';

export default class ContactsInfo extends Component {

  constructor(props) {
    super(props);
  }

  onSaveContactCard = (contactInfo, id) => {
    const { onSaveContactCard, Type, EquipmentName, ComponentName, ServiceTitle } = this.props;
    let contact = { ...contactInfo, Type };
    if (Type === 'Component') {
      contact = { ...contact, ComponentName };
    } else if (Type === 'Equipment') {
      contact = { ...contact, EquipmentName, ComponentName };
    } else if (Type === 'ServiceInterval') {
      contact = { ...contact, ServiceTitle };
    }
    onSaveContactCard(contact, id);
  }
  
  render() {
    const { contacts, deleteContact, frenabled, limit } = this.props;
    return (
      <div id="contact-info">
        <div className="contacts-wrapper" style={frenabled ? { gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))' } : {}}>          
          {contacts && contacts.map((contact, i) => (
            <ContactCard key={`contact-${Math.random()}`} contact={contact} deleteContact={deleteContact}
            onSaveContactCard={(c, id) => this.onSaveContactCard(c, id)} />
          ))}
          { !!(!limit || (contacts || []).length < limit) && <AddContactCard onSaveContactCard={(c) => this.onSaveContactCard(c)}/> }

        </div>
      </div>
    )
  }
}

const ContactCard = ({contact, onSaveContactCard, deleteContact}) => {
  const [edit, toggleEditMode] = useState(false);
  const [contactInfo, setContactInfo] = useState({});

  useEffect(() => {
    let c = { Name: contact.Name };
    if (contact.Email) c.Email = contact.Email;
    if (contact.Phone) c.Phone = contact.Phone; 
    if (contact.Others) c.Others = contact.Others;
    setContactInfo(c);
  }, [contact])

  const onChangeValue = (key, value) => {
    setContactInfo({...contactInfo, [`${key}`]: value});
  }
  
  const onClickSave = (contactInfo) => {
    console.log(contactInfo, contact.id)
    onSaveContactCard(contactInfo, contact.id);
    toggleEditMode(false);
  }

  return (
    <div className="card rounded-lg border-0 contact-card">
      <div className="card-body align-items-start px-4">
        { !edit && <a className="edit-button" onClick={() => toggleEditMode(true)}><i className="icon-pencil"></i></a>}
        <div className="info-group">
          <span className="helper-icon mr-2"><i className="icon-name"></i></span>
          <span className="helper mr-2">Name:</span>
          { !edit ? 
            <span>{contact.Name}</span> : 
            <input type="text" defaultValue={contact.Name}  onChange={(e) => onChangeValue('Name', e.target.value)}/> 
          }
        </div>
        <div className="info-group">
          <span className="helper-icon mr-2"><i className="icon-mail"></i></span>
          <span className="helper mr-2">Email:</span>
          { !edit ? 
            <span><a href={`mailto:${contact.Email}`}>{contact.Email}</a></span> : 
            <input type="email" defaultValue={contact.Email}  onChange={(e) => onChangeValue('Email', e.target.value)}/> 
          }
        </div>
        <div className="info-group">
          <span className="helper-icon mr-2"><i className="icon-phone"></i></span>
          <span className="helper mr-2">Phone:</span>
          { !edit ? 
            contact.Phone && contact.Phone.split(',').map((phone, i, ps) => (
              <span key={`${phone}-${i}`}><a href={`tel:${phone.trim()}`}>{phone.trim()}</a>&nbsp;{ps.length - 1 === i ? '' : ','}&nbsp;</span>
            )) : 
            <input type="text" defaultValue={contact.Phone}  onChange={(e) => onChangeValue('Phone', e.target.value)}/> 
          }
        </div>
        <div className="info-group">
          <span className="helper-icon mr-2"><i className="icon-tag"></i></span>
          <span className="helper mr-2">Others:</span>
          { !edit ? 
            <span>{contact.Others}</span> : 
            <input type="text" defaultValue={contact.Others}  onChange={(e) => onChangeValue('Others', e.target.value)}/> 
          }
        </div>
        { edit && <div className="d-flex justify-content-end w-100 mt-3">
          <button className="btn btn-sm btn-danger mr-auto" onClick={() => deleteContact(contact.id)}>Delete</button>
          <button className="btn btn-sm btn-light mr-2" onClick={() => toggleEditMode(false)}>Cancel</button>
          <button className="btn btn-sm btn-primary" disabled={!contactInfo.Name || (!contactInfo.Email && !contactInfo.Phone)}
            onClick={() => onClickSave(contactInfo)}>Save</button>
        </div> }
      </div>
    </div>
  )
}

const AddContactCard = ({onSaveContactCard}) => {
  const [addNew, toggleAdd] = useState(false);
  const [contactInfo, setContactInfo] = useState({});

  const onChangeValue = (key, value) => {
    setContactInfo({...contactInfo, [`${key}`]: value});
  }
  
  const onClickSave = (contactInfo) => {
    onSaveContactCard(contactInfo)
    toggleAdd(false);
  }

  return (
    <>
    { !addNew ?
    <div className="d-flex flex-column align-items-center align-self-center py-5">
      <button className="btn btn-sm btn-primary" onClick={() => toggleAdd(true)}>
        <i className="icon-plus"></i> Add
      </button>
      <p className="mt-2">Add new Contact</p>
    </div> :
    <div className="card rounded-lg border-0 contact-card">
      <div className="card-body align-items-start px-4">
        <div className="info-group">
          <span className="helper-icon mr-2"><i className="icon-name"></i></span>
          <span className="helper mr-2">Name:</span>
          <input type="text" onChange={(e) => onChangeValue('Name', e.target.value)}/>
        </div>
        <div className="info-group">
          <span className="helper-icon mr-2"><i className="icon-mail"></i></span>
          <span className="helper mr-2">Email:</span>
          <input type="email" onChange={(e) => onChangeValue('Email', e.target.value)}/>
        </div>
        <div className="info-group">
          <span className="helper-icon mr-2"><i className="icon-phone"></i></span>
          <span className="helper mr-2">Phone:</span>
          <input type="text" onChange={(e) => onChangeValue('Phone', e.target.value)}/>
        </div>
        <div className="info-group">
          <span className="helper-icon mr-2"><i className="icon-tag"></i></span>
          <span className="helper mr-2">Others:</span>
          <input type="text" onChange={(e) => onChangeValue('Others', e.target.value)}/>
        </div>
        <div className="d-flex justify-content-end w-100 mt-3">
          <button className="btn btn-sm btn-light mr-2" onClick={() => toggleAdd(false)}>Cancel</button>
          <button className="btn btn-sm btn-primary" disabled={!contactInfo.Name || (!contactInfo.Email && !contactInfo.Phone)}
            onClick={() => onClickSave(contactInfo)}>Save</button>
        </div>
      </div>
    </div> }
    </>
  )
} 
