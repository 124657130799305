import React from 'react'

const ProgressBar = ({bgcolor, completed}) => {
    const containerStyles = {
        height: 8,
        width: '100%',
        backgroundColor: "#EDEDED",
        borderRadius: 4,        
    }
    
    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: 'inherit',    
    }

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}></div>
        </div>
    )
}

export default ProgressBar