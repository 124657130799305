/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import * as _ from "lodash";

import Switch from "@material-ui/core/Switch";
import HBarChart from "../../Common/Charts/HBarChart/HBarChart";
import Button, { ViewAll } from "../../Common/WidgetHeader/Button/Button";
import WidgetHeader from "../../Common/WidgetHeader/WidgetHeader";
import createHistory from "../../../../../history";

const classNames = require("classnames");

const SystemHealth = (props) => {
  const {
    classValue,
    editMode,
    firestore,
    consolidated,
    SystemHealth_ED,
    selectedZone,
    toggle,
    show = false,
    isLoading,
    order,
  } = props;
  const [isVisible, setIsVisible] = useState(show);
  const [systemHealthData, setSystemHealthData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const systems = consolidated[0].Systems;
      let condition = [["System", "in", systems]];
      if (selectedZone && selectedZone !== "All") {
        condition = [...condition, ["Zone", "==", selectedZone]];
      }
      firestore.get({
        collection: "consolidated",
        doc: consolidated[0].id,
        subcollections: [
          {
            collection: "SystemHealth_ED",
            where: condition,
          },
        ],
        storeAs: "SystemHealth_ED",
      });
    };
    if (consolidated && consolidated.length) fetchData();
  }, [consolidated, selectedZone]);

  useEffect(() => {
    if (SystemHealth_ED && SystemHealth_ED.length) {
      const data = _.chain(SystemHealth_ED)
        .groupBy("System")
        .map((item) => ({
          System: item[0].System,
          Normal: item.filter((_item) => _item.StatusIndicator === "Green")
            .length,
          Warning: item.filter((_item) => _item.StatusIndicator === "Amber")
            .length,
          Critical: item.filter((_item) => _item.StatusIndicator === "Red")
            .length,
        }))
        .value();

      setSystemHealthData(data);
    }
  }, [SystemHealth_ED]);

  const handleChange = () => {
    setIsVisible(!isVisible);
    toggle("System Health", !isVisible);
  };
  useEffect(() => {
    toggle("System Health", show);
  }, []);

  return (
    <>
      <div
        className={classNames({
          [classValue]: !editMode,
          "edit-dashed": editMode,
        })}
      >
        <div className={classNames("widget", { "full-h": !editMode })}>
          {editMode && (
            <span style={{ marginLeft: 12, marginTop: 5 }}>#{order}</span>
          )}
          <div className="widget-header-container d-flex flex-row justify-content-between">
            <WidgetHeader
              label="System Health"
              description="Overall system health status of managed properties"
              disabled={false}
            />
            {!editMode ? (
              <ViewAll onClick={() => createHistory.push(`/executive/widget/system-health`)}>View All</ViewAll>
            ) : (
              <Switch
                checked={isVisible}
                color="primary"
                onChange={handleChange}
              />
            )}
          </div>
          {isLoading ? (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <div className="text-secondary ">Loading...</div>
            </div>
          ) : null}
          {!editMode && !isLoading ? (
            <div className={classNames({ disabled: editMode })}>
              <HBarChart data={systemHealthData} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default compose(
  connect(
    ({
      dashboard,
      firestore: {
        ordered: { consolidated, SystemHealth_ED },
      },
    }) => ({
      consolidated,
      SystemHealth_ED,
      selectedZone: dashboard.selectedZone,
    })
  ),
  firestoreConnect()
)(SystemHealth);
