import React from 'react';

const DynamicQuestion = (props) => {
    const { question, id, questionNo, className, buildingData = [], onAnswer, answer } = props;
    const values = new Set();
    buildingData.forEach(bd => values.add(bd[id]))
    return (
        <div id={id} data-key={id} data-required="true" className={["p-3 h-100 d-flex flex-column justify-content-center q-block", className].join(' ')}>
        <p className="mb-3 question">{question}</p>
        <div className="d-flex flex-wrap tf-radio-group">
        {
            Array.from(values).sort().map(value => (
                <div className="tf-radio-btn">
                    <input id={`${id}-${value}`} value={value} type="radio" checked={answer==value} name={id} onChange={(e) => onAnswer(e.target.value)} />
                    <label htmlFor={`${id}-${value}`}>{value}</label>
                </div>
            ))
        }
        </div>
    </div>
    )
}

export default DynamicQuestion;
