import React, { Component } from "react";
import SlidingPane from "react-sliding-pane";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { Collapse } from "react-bootstrap";
import * as moment from "moment";
import "./Notification.css";

class Notification extends Component {
  state = {
    viewAllAlerts: false,
    viewAllSuggestions: false,
  };

  componentDidMount() {
    const { currentApartment } = this.props;

    if (currentApartment) {
      this.fetchData(currentApartment);
    }
  }

  componentDidUpdate(prevProps) {
    const { currentApartment } = this.props;

    if (currentApartment !== prevProps.currentApartment) {
      this.fetchData(currentApartment);
    }
  }

  fetchData(currentApartment) {
    const { firestore } = this.props;
    if (this.unsubscribeAlertsAndSuggestions) {
      this.unsubscribeAlertsAndSuggestions();
    }
    this.unsubscribeAlertsAndSuggestions = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "AlertsAndSuggestions" }],
      storeAs: "AlertsAndSuggestions",
      orderBy: ["CreatedTime", "desc"],
    });
  }

  getCreatedTime(date) {
    if (!date) return "-";
    let createdDate = date.toDate();
    const period = moment(new Date()).diff(moment(createdDate), "days");
    if (period < 1) createdDate = moment(createdDate).fromNow();
    else if (period == 1) createdDate = "Yesterday";
    else createdDate = moment(createdDate).format("ddd, D MMM");
    return createdDate;
  }

  render() {
    const {
      isNotifyOpen,
      toggleNotifyModal,
      alertsAndSuggestions,
      user,
    } = this.props;
    const { viewAllAlerts, viewAllSuggestions } = this.state;
    const { UID: userUid } = user[0];
    let alerts =
      alertsAndSuggestions &&
      alertsAndSuggestions.filter((as) => as.CardType === "Alerts");
    let suggetions =
      alertsAndSuggestions &&
      alertsAndSuggestions.filter((as) => as.CardType === "Suggestions");
    alerts =
      alerts &&
      alerts.filter((a) => a.TargetUser === "All" || a.TargetUser === userUid);
    suggetions =
      suggetions &&
      suggetions.filter(
        (a) => a.TargetUser === "All" || a.TargetUser === userUid
      );

    return (
      <SlidingPane
        className="system-sliding-pane notifications"
        isOpen={isNotifyOpen}
        onRequestClose={() => {
          toggleNotifyModal(false);
        }}
      >
        <h5>
          Notifications
          <button
            className="close"
            onClick={() => {
              toggleNotifyModal(false);
            }}
          >
            <i className="icon-close" />
          </button>
        </h5>

        {alerts && (
          <>
            <h6>Alerts ( {alerts.length} )</h6>
            {alerts.slice(0, 4).map((item, key) => (
              <div
                className={`notification${
                  item.Indicator ? ` ${item.Indicator.toLowerCase()}` : ``
                }`}
                key={key}
              >
                <p className="name">{item.Name}</p>
                <p className="time">{this.getCreatedTime(item.CreatedTime)}</p>
              </div>
            ))}
            {alerts.length > 4 && (
              <>
                <Collapse in={viewAllAlerts}>
                  <div>
                    {alerts.slice(4, alerts.length - 1).map((item, key) => (
                      <div
                        className={`notification${
                          item.Indicator
                            ? ` ${item.Indicator.toLowerCase()}`
                            : ``
                        }`}
                        key={key}
                      >
                        <p className="name">{item.Name}</p>
                        <p className="time">
                          {this.getCreatedTime(item.CreatedTime)}
                        </p>
                      </div>
                    ))}
                  </div>
                </Collapse>
                <a
                  onClick={() =>
                    this.setState({ viewAllAlerts: !viewAllAlerts })
                  }
                  className="view-all-link"
                >
                  {!viewAllAlerts ? "View all" : "Show less"}
                </a>
              </>
            )}
          </>
        )}
        {suggetions && (
          <>
            <h6>Smart Suggestions ( {suggetions.length} )</h6>
            {suggetions.slice(0, 4).map((item, key) => (
              <div
                className={`notification${
                  item.Indicator ? ` ${item.Indicator.toLowerCase()}` : ``
                }`}
                key={key}
              >
                <p className="name">{item.Name}</p>
                <p className="time">{this.getCreatedTime(item.CreatedTime)}</p>
              </div>
            ))}
            {suggetions.length > 4 && (
              <>
                <Collapse in={viewAllSuggestions}>
                  <div>
                    {suggetions
                      .slice(4, suggetions.length - 1)
                      .map((item, key) => (
                        <div
                          className={`notification${
                            item.Indicator
                              ? ` ${item.Indicator.toLowerCase()}`
                              : ``
                          }`}
                          key={key}
                        >
                          <p className="name">{item.Name}</p>
                          <p className="time">
                            {this.getCreatedTime(item.CreatedTime)}
                          </p>
                        </div>
                      ))}
                  </div>
                </Collapse>
                <a
                  onClick={() =>
                    this.setState({ viewAllSuggestions: !viewAllSuggestions })
                  }
                  className="view-all-link"
                >
                  {!viewAllSuggestions ? "View all" : "Show less"}
                </a>
              </>
            )}
          </>
        )}
      </SlidingPane>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleNotifyModal: (val) =>
    dispatch({ type: "TOGGLE_NOTIFY_MODAL", payload: val }),
});

const mapStateToProps = (state) => ({
  user: state.firestore.ordered.Users,
  isNotifyOpen: state.navigation.isNotifyOpen,
  alertsAndSuggestions: state.firestore.ordered.AlertsAndSuggestions,
  currentApartment: state.apartment.currentApartment,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect()
)(Notification);
