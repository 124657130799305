/* eslint-disable no-return-assign */
/* eslint-disable no-plusplus */
import React, { useEffect, useState, useRef } from "react";
import * as d3 from "d3";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import * as _ from "lodash";

const VennDiagram = ({ consolidated, firestore, selectedZone }) => {
  const [graph, setGraph] = useState([]);
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    if (consolidated && consolidated.length) {
      let condition = [];
      if (selectedZone && selectedZone !== "All") {
        condition = [...condition, ["Zone", "==", selectedZone]];
      }
      firestore
        .get({
          collection: "consolidated",
          doc: consolidated[0].id,
          subcollections: [
            {
              collection: "AuditingStatus_ED",
              where: condition,
            },
          ],
          storeAs: "AuditingStatus_ED",
        })
        .then((querySnapshot) => {
          let auditingStatus = [];

          querySnapshot.forEach((doc) => {
            auditingStatus = [
              ...auditingStatus,
              {
                id: doc.id,
                ...doc.data(),
              },
            ];
          });

          const auditingStatusDetails = _.chain(auditingStatus)
            .groupBy("StatusIndicator")
            .map((item) => {
              return {
                name: item[0].StatusIndicator.toLowerCase(),
                count: item.length,
              };
            })
            .value();

          const total = auditingStatusDetails.reduce(
            (prev, next) => prev + next.count,
            0
          );
          const graphValue = auditingStatusDetails.map(({ name, count }) => ({
            name,
            color:
              name === "green"
                ? "#01D09F"
                : name === "red"
                ? "#FF8D8D"
                : "#FFCB7E",
            count,
            radius: (count * 200) / total,
          }));

          graphValue.sort((a, b) => b.count - a.count);
          if (isMounted.current) {
            setGraph(graphValue);
          }
        });
    }
    return () => {
      isMounted.current = false;
    };
  }, [consolidated, selectedZone]);

  useEffect(() => {
    if (graph.length) {
      const width = 490;
      const height = 400;
      const margin = { top: 50, right: 50, bottom: 50, left: 50 };

      const innerWidth = width - margin.left - margin.right;
      const innerHeight = height - margin.top - margin.bottom;
      const position = [
        { cx: 100, cy: 100 },
        { cx: 10, cy: 100 },
        { cx: 40, cy: 150 },
      ];
      const vennContainer = d3
        .select("div#auditing_venndgm")
        .selectAll("svg")
        .data([1])
        .enter()
        .append("svg")
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 ${width} ${height}`)
        .classed("svg-content", true);
      const g = vennContainer
        .append("g")
        .attr(
          "transform",
          `translate(${innerWidth / 2.5}, ${innerHeight / 2.5})`
        );

      for (let index = 0; index < graph.length; index++) {
        const { count, radius, color } = graph[index];

        g.append("circle")
          .attr("r", radius)
          .attr("cx", position[index].cx)
          .attr("cy", position[index].cy)
          .attr("fill", color);
        g.append("text")
          .attr("x", position[index].cx)
          .attr("y", position[index].cy)

          .attr("dominant-baseline", "central")
          .attr("text-anchor", "middle")
          .attr("font-family", "Inter")
          .attr("font-size", "20px")
          .attr("font-weight", "bold")
          .attr("fill", "#FFFFFF")
          .text(count);
        // setTest(2);
      }
    }
  }, [graph]);

  return (
    <>
      <div
        id="auditing_venndgm"
        className="svg-container"
        key={Math.floor(Math.random() * Math.floor(1000))}
      />
      {graph.length ? (
        <div className="row justify-content-center mb-5">
          <div className="p-1 m-2">
            <div className="row align-items-center">
              <div
                className="mr-1"
                style={{ width: 15, height: 10, backgroundColor: "#01D09F" }}
              />
              <span style={{ fontSize: 12 }}>Completed</span>
            </div>
          </div>
          <div className="p-1 ml-4 mr-4 m-2">
            <div className="row align-items-center">
              <div
                className="mr-1"
                style={{ width: 15, height: 10, backgroundColor: "#FF8D8D" }}
              />
              <span style={{ fontSize: 12 }}>Not Started</span>
            </div>
          </div>
          <div className="p-1 m-2">
            <div className="row align-items-center">
              <div
                className="mr-1"
                style={{ width: 15, height: 10, backgroundColor: "#FFCB7E" }}
              />
              <span style={{ fontSize: 12 }}>Inprogress</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

// export default ;
export default compose(
  connect(
    ({
      dashboard,
      firestore: {
        ordered: { consolidated, ConsViews },
      },
    }) => ({
      consolidated,
      ConsViews,
      selectedZone: dashboard.selectedZone,
    })
  ),
  firestoreConnect()
)(VennDiagram);
