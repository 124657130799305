/* eslint-disable no-undef */
/* eslint-disable no-alert */
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import './Profile.css'

class Profile extends Component {
    constructor() {
        super();
        this.state = {
            name: null,
            phone: null,
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            updating: false,
            editProfile: false,
            btnClicked: false,
            path: null,
            file: null,
            photoURL: 'https://heightstitle.com/wp-content/uploads/2014/12/user-dummy-pic.png',
            photoURLnavbar: require('../../assets/images/default_dp.svg'),
            assignedApts: [],
            defaultApt: null
        };
        this.apartmentList = [];
        this.displayName = null;
        this.phone = null;        
        this.newFiles = [];
        this.checked = false;
    }

    componentDidMount() {
        const { user, firestore } = this.props;
        const { PhotoURL } = user;
        const width = this.getWidth();
        if (width > 768) {
            this.isMobile = false
        }
        else {
            this.isMobile = true
        }
        window.addEventListener('resize', this.reportWindowSize);
        this.setState({})        
        firestore.collection("Users").doc(user.id).collection("AssignedApartments").get()
            .then(querySnapshot => {
                querySnapshot.forEach(doc => {                                
                    this.setState(prevState => ({
                        assignedApts: [...prevState.assignedApts, doc.data()]
                    }))
                });
            });        
        this.setState({
            defaultApt: this.getApartmentName(user.DefaultApartment)
        })          
        if (user && PhotoURL) {
            this.getPhotoURL(PhotoURL);
        }
    }   

    getWidth = () => Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    )

    reportWindowSize = (e) => {
        if (this.isMobile) {
            if (e.target.innerWidth > 768) {
                this.isMobile = false;
                this.setState({})
            }
        }
        else if (e.target.innerWidth < 768) {
            this.isMobile = true;
            this.setState({})
        }
    }

    onKeyDown = (e, last, value) => {
        if (e.key === 'Enter') {
            if (last) {
                this.confirm.blur();
                this.changePassword();
            } else {
                value.focus();
            }
        }
    }

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    reauthenticate = (currentPassword) => {
        const { firebase } = this.props;
        const user = firebase.auth().currentUser;
        const cred = firebase.auth.EmailAuthProvider.credential(
            user.email, currentPassword);
        return user.reauthenticateWithCredential(cred);
    }

    changePassword = () => {
        const { firebase } = this.props;
        const { currentPassword, newPassword, confirmPassword } = this.state;
        if (currentPassword && newPassword && newPassword === confirmPassword) {
            this.setState({ updating: true });
            this.reauthenticate(currentPassword).then(() => {
                const user = firebase.auth().currentUser;
                user.updatePassword(newPassword).then(() => {
                    this.setState({
                        updating: false,
                        currentPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                    });
                    alert('Successfully updated');
                }).catch((error) => { this.setState({ updating: false }); alert(JSON.stringify(error.message)); });
            }).catch((error) => { this.setState({ updating: false }); alert(JSON.stringify(error.message)); });
        }
    }

    uploadData = async () => {
        this.setState({ btnClicked: true });
        const { path, file, defaultApt } = this.state;
        const { firestore, user, firebase } = this.props;
        const filesPath = `Users/${user.UID}`;

        if (path) await firebase.uploadFile(filesPath, file);
        await firestore.collection("Users").doc(user.id).update({
            DisplayName: this.displayName ? this.displayName : user.DisplayName,
            PhoneNo: this.phone ? this.phone : user.PhoneNo,
            DefaultApartment: defaultApt || user.DefaultApartment,
            PhotoURL: path ? `${filesPath}/${file.name}` : user.PhotoURL ? user.PhotoURL : null
        })
        await firestore.update({
            collection: 'apartments',
            doc: user.DefaultApartment,
            subcollections: [{ collection: 'Users', doc: user.UID }]
        }, {
            DisplayName: this.displayName ? this.displayName : user.DisplayName,
            PhoneNo: this.phone ? this.phone : user.PhoneNo,
            DefaultApartment: defaultApt || user.DefaultApartment,
            PhotoURL: path ? `${filesPath}/${file.name}` : user.PhotoURL ? user.PhotoURL : null
        }).then(() => {
            this.setState({ editProfile: false, btnClicked: false, photoURLnavbar: path })
            this.getPhotoURL(path || (user.PhotoURL ? user.PhotoURL : null))
        })
    }

    selectFile = async (e) => {        
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (e) => {
            this.setState({ path: e.target.result })
        }
        this.setState({ file: e.target.files[0] });
    }

    getPhotoURL = async path => {
        const { firebase } = this.props;
        const storage = firebase.storage();
        const url = path ? await storage.ref().child(path).getDownloadURL() : 'https://heightstitle.com/wp-content/uploads/2014/12/user-dummy-pic.png';
        this.setState({ photoURL: url, photoURLnavbar: url });
    }

    showSideNav = () => {
        setTimeout(() => this.props.openSideBar(false));
    }

    getThemeColor=()=>{
        const {AppartmentDetails } = this.props;
          if(this.isMobile && AppartmentDetails[0].Header_Theme_Color)
          {
            return AppartmentDetails[0].Header_Theme_Color
          }
          return null
        }

    showMobileNav = () => {        
        if (this.isMobile) {
            return (
                <>                    
                    <div id="dashboard-nav" className="d-flex flex-column w-100" style={{ position: 'fixed', backgroundColor: this.getThemeColor() }}>
                        <div className="d-flex justify-content-between w-100 align-items-center">
                            <div className='adhocDiv'>Profile</div>
                        </div>
                    </div>
                </>
            )
        }
    }

    enableEmailNotification = async () => {
        this.checked = !this.checked;
        const { firestore, user } = this.props;
        await firestore.collection("Users").doc(user.id).update({
            EmailNotification: this.checked
        })
    }

    getApartmentName = (key) => {
        const {assignedApts} = this.state      
        const apt = assignedApts.find(a => a.AptId == key);        
        return ((apt && apt.ApartmentName) || key) ;
    }

    render() {
        const { user, ApartmentAccessInfo, currentApartment } = this.props;

        let accessInfo = null;
        if(ApartmentAccessInfo.length){
          const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
         if(selectedApartment){
           accessInfo = selectedApartment.users.find(({Email}) => Email === user.Email)
         }
        }
        const { Roles } = accessInfo || {Roles:[]}
        
        const { updating, editProfile, btnClicked, path, photoURL, assignedApts, defaultApt } = this.state;
        this.checked = user.EmailNotification;        
        return (
            <div id="profile">
                {this.showMobileNav()}
                <div className="d-flex flex-column align-items-center justify-content-center mt-4">
                    {!this.isMobile && <div className="profile-heading col-11">Profile</div>}
                    <div className="row w-100 col-11 p-0 profileDiv">
                        <div className={`d-flex flex-column align-items-center userDiv ${this.isMobile ? 'col-12' : 'col-4'}`}
                            style={{ borderBottom: editProfile && 0, paddingTop: !this.isMobile && editProfile && 31 }}>
                            {!editProfile &&
                                <button type="submit" className="buttonrmstyle ml-auto editButton"
                                    style={{ marginTop: 5, marginRight: -10 }}
                                    onClick={() => this.setState({ editProfile: true })}>
                                    <i className="icon-pencil" />
                                </button>}
                            <img className={`user_image ${editProfile && 'editImage'}`} alt="user" src={path || photoURL}
                                style={{ borderRadius: 50, opacity: editProfile && 0.43, objectFit: 'cover' }} />
                            {!editProfile ?
                                <>
                                    <p className="mb-1 userName">{user.DisplayName}</p>
                                    <p className="mb-0 userMail"><a href={`mailto:${user.Email}`}>{user.Email}</a></p>
                                    <p className="mb-0 userPhone"><a href={`tel:${user.PhoneNo}`}>{user.PhoneNo}</a></p>
                                </>
                                :
                                <>
                                    <input
                                        type="file"
                                        name="avatar"
                                        accept="image/*"
                                        onChange={this.selectFile}
                                        style={{ height: 60, width: 60, marginTop: -80, marginLeft: 0, marginBottom: 20, zIndex: 1 }}
                                    />
                                    <img className="" alt="user" src={require('../../assets/images/image.png')}
                                        style={{ position: 'absolute', height: 50, width: 50, marginTop: this.isMobile ? -25 : 25, cursor: 'pointer', objectFit:'contain' }} />
                                    <label className={`label mb-0 w-100 ${this.isMobile && 'mt-3'}`}>Name</label>
                                    <input className="editName pl-0 w-100" name="name" defaultValue={user.DisplayName} onChange={(e) => { this.displayName = e.target.value }} onBlur={this.saveName} />
                                    <label className={`label mb-0 w-100 ${this.isMobile ? 'mt-4' : 'mt-3'}`}>Email</label>
                                    <div className="d-flex w-100 emailDiv">
                                        <input className="editEmail pl-0 w-100" name="email" defaultValue={user.Email} disabled />
                                        <div className="ml-auto"><i className="icon-lock ml-3" /></div>
                                    </div>
                                    <label className={`label mb-0 w-100 ${this.isMobile ? 'mt-4' : 'mt-3'}`}>Phone</label>
                                    <input className="editPhone pl-0 w-100" name="phone" defaultValue={user.PhoneNo} onChange={(e) => { this.phone = e.target.value }} onBlur={this.savePhone} />
                                </>
                            }
                        </div>
                        <div className={`${this.isMobile ? 'col-12 p-0' : 'col-8 pl-4'}`}>
                            <div className={`userDetailsDiv ${editProfile && 'd-flex flex-column justify-content-between'}`} style={{ minHeight: editProfile && !this.isMobile && 285 }}>
                                <div className={`userDetails ${editProfile && this.isMobile ? 'pt-0' : 'p-4'}`}
                                    style={{ paddingLeft: editProfile && 16, paddingRight: editProfile && 16, borderBottom: editProfile && 0 }}>
                                    <div className={`dataHead ${editProfile && 'myRolesLabel'}`}>My Roles</div>
                                    <div className={`dataValues d-flex ${editProfile && 'editMyRoles'}`}>
                                        {Roles.map((role, index) => (
                                            <>
                                                {index !== 0 && <span>&nbsp;,&nbsp;</span>}
                                                <span>{role}</span>
                                            </>
                                        ))}
                                        {editProfile &&
                                            <div className="ml-auto"><i className="icon-lock ml-3" /></div>}
                                    </div>
                                    <div className={`dataHead mt-2 ${editProfile && 'myApartmentsLabel'}`}>My Apartments</div>
                                    <div className={`dataValues d-flex ${editProfile && 'editMyApartments'}`}>                                                                                
                                        {assignedApts.map((item, index) => (                                                                    
                                            <>
                                                {index !== 0 && <span>,&nbsp;</span>}                                                
                                                <span>{item.ApartmentName}</span>
                                            </>
                                        ))}  
                                        {editProfile &&
                                            <div className="ml-auto"><i className="icon-lock ml-3" /></div>}
                                    </div>
                                    {user.AssignedApartments.length > 1 &&
                                        <div>
                                            <div className={`dataHead mt-2 ${editProfile && 'defaultApartmentLabel'}`}>Default Apartment</div>
                                            {!editProfile ?
                                                <div className="dataValues">{this.getApartmentName(user.DefaultApartment)}</div>
                                                :
                                                <select id="apartmentList" className="modalDropdown w-100"
                                                    onChange={e => this.setState({defaultApt: e.target.value})}
                                                    defaultValue={defaultApt}
                                                    style={{
                                                        // width: 325, 
                                                        borderBottom: '1px solid #D5D5D5'
                                                    }}
                                                >
                                                    <option value="" selected disabled hidden>Choose Any</option>
                                                    {assignedApts.map(item => (
                                                        <option key={item.AptId} value={item.AptId} >{item.ApartmentName}</option>
                                                    ))}
                                                </select>
                                            }
                                        </div>

                                    }
                                </div>
                                {!editProfile ?
                                    <div className="userNotifications px-4 py-3">
                                        <div className={`appNotifications d-flex mt-2 ${this.isMobile && 'justify-content-between'}`}>
                                            <div className="dataHead" style={{ marginRight: 20 }}>Email Notifications</div>
                                            <div id="toggleButton">
                                                <label className="switch">
                                                    <input type="checkbox" onChange={this.enableEmailNotification} defaultChecked={this.checked} />
                                                    <span className={`slider round ${this.isMobile && 'ml-auto'}`} />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className={`w-100 d-flex justify-content-end ${this.isMobile && 'mt-3'}`}
                                        style={{ paddingRight: this.isMobile ? 10 : 17 }}>
                                        <button type="submit" className="buttonrmstyle mb-2"
                                            onClick={() => this.setState({ editProfile: false, path: null })} >
                                            <div className="btn btn-light"
                                                style={{ borderColor: '#979797', color: '#979797', height: 32, width: 80, fontSize: 12, borderRadius: 3 }}
                                            >CANCEL</div>
                                        </button>
                                        <button type="submit" className="buttonrmstyle mb-2"
                                            onClick={() => this.uploadData()}
                                        >
                                            <div className={`btn btn-primary d-flex justify-content-center ${btnClicked ? "disabled" : ""}`}
                                                style={{ borderColor: '#558dca', height: 32, width: 80, fontSize: 12, borderRadius: 3, backgroundColor: '#558dca' }}>
                                                <div>SAVE</div>
                                                {btnClicked &&
                                                    <div className="spinner-border" role="status"
                                                        style={{ width: 15, height: 15, marginTop: 2, marginLeft: 5 }}>
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </div>
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openSideBar: (value) => dispatch({ type: 'SIDEBAR_OPEN', payload: value })
});

const mapStateToProps = state => ({
    user: state.firestore.ordered.Users[0],
    // UserRoles: state.firestore.ordered.UserAccessInfo[0].Roles,
    AppartmentDetails: state.firestore.ordered.AppartmentDetails,
    ApartmentAccessInfo: state.apartment.ApartmentAccessInfo,
    currentApartment: state.apartment.currentApartment,

});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(),
    firebaseConnect()
)(Profile);