/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable lines-between-class-members */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { actionTypes } from "redux-firestore";
import createHistory from "../../../history";
import "./Navbar.css";
import Dropdown from "../Dropdown/Dropdown";
// import Dashboard from "../../InitialScreen/Dashboard";
class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      active: 0,
      routes: [
        { label: "Daily Readings", route: "/dashboard" },
        { label: "System Status", route: "/system" },
        { label: "Staff Activities", route: "/staff" },
      ],
      photoURL: require("../../../assets/images/defaultDp.png"),
      displayBackButton: false,
      apartmentList: [],
      zoneList: [],
    };
  }

  componentDidMount() {
    const { location, user, history, firestore, currentDashboard } = this.props;
    const { zoneList } = this.state;
    
    if (!zoneList.length && currentDashboard === "executive") {
      this.fetchZones();
    }
    // if()
    const { routes } = this.state;
    const { pathname } = location;
    const { PhotoURL } = user;
    const route = pathname.split("/");
    const finalRoute = `/${route[1]}`;

    const width = this.getWidth();
    if (width > 768) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    window.addEventListener("resize", this.reportWindowSize);
    firestore
      .collection("Users")
      .doc(user.id)
      .collection("AssignedApartments")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState((prevState) => ({
            apartmentList: [...prevState.apartmentList, doc.data()],
          }));
        });
      });
    routes.map((item, index) => {
      if (finalRoute === item.route) {
        this.setState({
          active: index,
        });
      }
    });

    if (user && PhotoURL) {
      this.getPhotoURL(PhotoURL);
    }
    if (history.location.pathname.substring(1) !== "dashboard") {
      this.setState({ displayBackButton: true });
    }

    history.listen(() => {
      if (history.location.pathname.substring(1) !== "dashboard") {
        this.setState({ displayBackButton: true });
      } else {
        this.setState({ displayBackButton: false });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { user, currentApartment, currentDashboard } = this.props;
    const { PhotoURL } = user;
    if (user && PhotoURL) {
      this.getPhotoURL(PhotoURL);
    }
    if (
      user &&
      currentApartment &&
      currentApartment !== prevProps.currentApartment
    ) {
      this.fetchData(currentApartment);
    }
    if (
      user &&
      currentDashboard === "executive" &&
      currentDashboard !== prevProps.currentDashboard
    ) {
      this.fetchZones();
    }
  }
  fetchZones = async () => {
    const { firestore, ConsViews, setSelectedZone } = this.props;
    const zonesRef = firestore.collection("consolidated");
    if(!!ConsViews && ConsViews.Zones.length && !ConsViews.Zones.includes('All')){
      
      let zoneList = [];
      ConsViews.Zones.map((zone) => {
          zoneList = [...zoneList, { AptId: zone, ApartmentName: zone }];
      });

       // this.setState({ zoneList });
    this.setState({ zoneList });
    setSelectedZone(zoneList[0].ApartmentName);
      
    }else{
      const snapshot = await zonesRef
      .where("ConsViewId", "==", ConsViews.ConsViewId)
      .get();
    let zoneList = [];
    snapshot.forEach((doc, i) => {
      const { Zones } = doc.data();
      Zones.map((zone) => {
        zoneList = [...zoneList, { AptId: zone, ApartmentName: zone }];
      });
    });
    zoneList.forEach((item, i) => {
      if (item.ApartmentName === "All") {
        zoneList.splice(i, 1);
        zoneList.unshift(item);
      }
    });
    // this.setState({ zoneList });
    this.setState({ zoneList });
    setSelectedZone(zoneList[0].ApartmentName);
    }
    
  };
  fetchData = (currentApartment) => {
    const { firestore } = this.props;
    if (this.unsubscribeAppartmentDetails) {
      this.unsubscribeAppartmentDetails();
    }
    this.unsubscribeAppartmentDetails = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      storeAs: "AppartmentDetails",
    });
  };
  reportWindowSize = (e) => {
    if (this.isMobile) {
      if (e.target.innerWidth > 768) {
        this.isMobile = false;
        this.setState({});
      }
    } else if (e.target.innerWidth < 768) {
      this.isMobile = true;
      this.setState({});
    }
  };

  getWidth = () =>
    Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );

  getPhotoURL = async (path) => {
    const { firebase } = this.props;
    const { photoURL } = this.state;
    const storage = firebase.storage();
    const url = await storage.ref().child(path).getDownloadURL();
    if (url !== photoURL) {
      this.setState({ photoURL: url });
    }
  };

  setRoute = (route, index) => {
    const { active } = this.state;
    if (active !== index) {
      this.setState({
        active: index,
      });
      createHistory.push(route);
    }
  };

  setStyle = (index) => {
    const { active } = this.state;
    if (index === active) {
      return "navbarLabelActive";
    }
    return "navbarLabel";
  };

  renderProfile = () => {
    const {
      user,
      toggleNotifyModal,
      toggleQrModal,
      alertsAndSuggestions,
      currentDashboard,
      ConsViews,
    } = this.props;
    const { photoURL } = this.state;
    if (user.length !== 0) {
      const { DisplayName } = user.length !== 0 && user;
      return (
        <div className="profile_logo d-flex align-items-center">
          {currentDashboard === "local" &&
          ConsViews &&
          ConsViews.is_cons_user === true ? (
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => this.handleDashboardChange("executive")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <path
                  d="M22.75 5.25V8.75H5.25V5.25H22.75ZM22.75 3.5H5.25C4.78587 3.5 4.34075 3.68437 4.01256 4.01256C3.68437 4.34075 3.5 4.78587 3.5 5.25V8.75C3.5 9.21413 3.68437 9.65925 4.01256 9.98744C4.34075 10.3156 4.78587 10.5 5.25 10.5H22.75C23.2141 10.5 23.6592 10.3156 23.9874 9.98744C24.3156 9.65925 24.5 9.21413 24.5 8.75V5.25C24.5 4.78587 24.3156 4.34075 23.9874 4.01256C23.6592 3.68437 23.2141 3.5 22.75 3.5Z"
                  fill="white"
                />
                <path
                  d="M8.75 14V22.75H5.25V14H8.75ZM8.75 12.25H5.25C4.78587 12.25 4.34075 12.4344 4.01256 12.7626C3.68437 13.0908 3.5 13.5359 3.5 14V22.75C3.5 23.2141 3.68437 23.6592 4.01256 23.9874C4.34075 24.3156 4.78587 24.5 5.25 24.5H8.75C9.21413 24.5 9.65925 24.3156 9.98744 23.9874C10.3156 23.6592 10.5 23.2141 10.5 22.75V14C10.5 13.5359 10.3156 13.0908 9.98744 12.7626C9.65925 12.4344 9.21413 12.25 8.75 12.25Z"
                  fill="white"
                />
                <path
                  d="M22.75 14V22.75H14V14H22.75ZM22.75 12.25H14C13.5359 12.25 13.0908 12.4344 12.7626 12.7626C12.4344 13.0908 12.25 13.5359 12.25 14V22.75C12.25 23.2141 12.4344 23.6592 12.7626 23.9874C13.0908 24.3156 13.5359 24.5 14 24.5H22.75C23.2141 24.5 23.6592 24.3156 23.9874 23.9874C24.3156 23.6592 24.5 23.2141 24.5 22.75V14C24.5 13.5359 24.3156 13.0908 23.9874 12.7626C23.6592 12.4344 23.2141 12.25 22.75 12.25Z"
                  fill="white"
                />
              </svg>
            </button>
          ) : currentDashboard === "executive" ? (
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => this.handleDashboardChange("local")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 22V12H15V22"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          ) : null}
          {currentDashboard === "local" && (
            <a
              onClick={() => toggleNotifyModal(true)}
              style={{ marginTop: !this.isMobile && 3 }}
            >
              <span
                className="menu-icons mr-4 cursor-pointer"
                style={{
                  color: "white",
                  fontSize: this.isMobile ? "1.5rem" : "1.2rem",
                }}
              >
                <i className="icon-notification" />
              </span>
            </a>
          )}
          {currentDashboard === "local" &&
          alertsAndSuggestions &&
          alertsAndSuggestions.length ? (
            <span
              className="badge-indicator red"
              style={{
                height: "0.5rem",
                width: "0.5rem",
                borderRadius: "50%",
                marginTop: this.isMobile ? -17 : -9,
                marginLeft: -30,
                marginRight: 25,
              }}
            />
          ) : null}
          {this.isMobile && (
            <a onClick={() => toggleQrModal(true)}>
              <span
                className="menu-icons mr-4"
                style={{ fontSize: "1.5rem", color: "white" }}
              >
                <i className="icon-qr-code" />
              </span>
            </a>
          )}
          <div
            className="d-flex align-items-center"
            onClick={() => {
              if(currentDashboard === "local"){
                createHistory.push(`/profile`)
              } else {
                createHistory.push(`/executive/profile`)
              }
              }}
            style={{ cursor: "pointer" }}
          >
            {!this.isMobile && <div className="mr-3">{DisplayName}</div>}
            <img
              alt="logo"
              src={photoURL}
              style={{
                height: this.isMobile ? 30 : 48,
                width: this.isMobile ? 30 : 48,
                borderRadius: 30,
                marginRight: !this.isMobile && 40,
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      );
    }
  };

  showSideNav = () => {
    setTimeout(() => this.props.openSideBar(false));
  };
  handleDashboardChange = (dashboard) => {
    const { toggleDashboard } = this.props;
    createHistory.push("/");
    toggleDashboard(dashboard);
  };
  handleApartmentChange = (apt) => {
    const {
      setCurrentApartment,
      clearFirestore,
      currentApartment,
    } = this.props;
    if (apt !== currentApartment) {
      clearFirestore();
      createHistory.push("/");
      setCurrentApartment(apt);
    }
  };
  handleZoneChange = (zone) => {
    const { setSelectedZone } = this.props;
    setSelectedZone(zone);
  };
  renderSwitchingOption = () => {
    const { apartmentList, zoneList } = this.state;
    const {
      AppartmentDetails,
      user,
      currentDashboard,
      selectedZone,
    } = this.props;
    if (currentDashboard === "local") {
      return (
        <div className="navbar-nav mr-auto d-flex flex-row justify-content-center align-items-center position-relative">
          <h6 className="building_name mt-1 mb-0">
            {AppartmentDetails[0].Name}{" "}
          </h6>
          {user.AssignedApartments.length > 1 && (
            <Dropdown
              data={apartmentList}
              onChange={this.handleApartmentChange}
            />
          )}
        </div>
      );
    } 

      if(!zoneList.length){
        return null
      }
    
      return (
        <div className="navbar-nav mr-auto d-flex flex-row justify-content-center align-items-center position-relative">
          <h6 className="building_name mt-1 mb-0">
            Current Zone{" "}
            <span className="ml-2" style={{ color: "#558DCA" }}>
              {selectedZone}
            </span>
          </h6>
          <Dropdown data={zoneList} onChange={this.handleZoneChange} />
        </div>
      );
    
  };
  render() {
    const { displayBackButton } = this.state;
    const { AppartmentDetails, currentDashboard, consolidated } = this.props;

    if (!AppartmentDetails) {
      return null;
    }
    let backgroundColor = AppartmentDetails[0].Header_Theme_Color
    ? AppartmentDetails[0].Header_Theme_Color
    : null;

    let Header_Logo = AppartmentDetails[0].Header_Logo?AppartmentDetails[0].Header_Logo:null
    let Header_Logo_Text = AppartmentDetails[0].Header_Logo_Text?AppartmentDetails[0].Header_Logo_Text:null
     if(currentDashboard === "executive" && consolidated){
       backgroundColor = consolidated[0].Header_Theme_Color;
       Header_Logo =  consolidated[0].Header_Logo;
       // eslint-disable-next-line prefer-destructuring
       Header_Logo_Text = consolidated[0].Header_Logo_Text;
       
     }
    return (
      <nav
        className={`navbar ${!this.isMobile && "py-2"}`}
        style={{
          padding: this.isMobile && "1rem 1.25rem",
          boxShadow: this.isMobile && "none",
          backgroundColor
        }}
      >
        <div
          className={`${!this.isMobile && "d-flex"}`}
          style={{ marginTop: this.isMobile && 4 }}
        >
          {this.isMobile && (
            <>
              {displayBackButton && (
                <a className="mr-3" onClick={() => window.history.back()}>
                  <i
                    className="icon-chevron-right"
                    style={{ color: "white", fontSize: 18 }}
                  />
                </a>
              )}
              <a className="menu-button mr-2" onClick={this.showSideNav}>
                <i className="icon-menu" style={{ color: "white" }} />
              </a>
            </>
          )}
          <a
            className="nav-brand"
            style={{
              marginTop: "0.375rem",
              marginBottom: "0.625rem",
              marginLeft: !this.isMobile && "1.5rem",
              marginRight: !this.isMobile && "1.5rem",
              borderRight: !this.isMobile && "1px solid #555555",
            }}
          >
            <img
              alt="logo"
              className="logo cursor-pointer"
              src={
                Header_Logo || require("../../../assets/images/logoOnly.png")
              }
              style={{
                height: this.isMobile ? "1.65rem" : "2.5rem",
                paddingBottom: this.isMobile && 0,
                paddingRight: this.isMobile && 0,
                paddingTop: this.isMobile && 0,
                marginTop: this.isMobile && -13,
              }}
              onClick={() => createHistory.push(`/dashboard`)}
            />
            {!this.isMobile && (
              <img
                alt="logo"
                className="logo wordmark cursor-pointer"
                src={
                  Header_Logo_Text || require("../../../assets/images/logo-wordmark.svg")
                }
                onClick={() => createHistory.push(`/dashboard`)}
              />
            )}
          </a>
          {!this.isMobile && currentDashboard && this.renderSwitchingOption()}
        </div>
        {this.renderProfile()}
      </nav>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearFirestore: () =>
    dispatch({
      type: actionTypes.CLEAR_DATA,
      preserve: {
        data: ["Users", "UserAccessInfo", "MenuSettings"],
        ordered: ["Users", "UserAccessInfo", "MenuSettings"],
      },
    }),
  toggleNotifyModal: (val) =>
    dispatch({ type: "TOGGLE_NOTIFY_MODAL", payload: val }),
  openSideBar: (value) => dispatch({ type: "SIDEBAR_OPEN", payload: value }),
  toggleQrModal: (val) =>
    dispatch({ type: "TOGGLE_QR_MODAL", payload: { val } }),
  setCurrentApartment: (apt) =>
    dispatch({ type: "SET_APARTMENT", payload: apt }),
  toggleDashboard: (val) => dispatch({ type: "SET_DASHBOARD", payload: val }),
  setSelectedZone: (val) =>
    dispatch({ type: "SET_SELECTED_ZONE", payload: val }),
});

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  user: state.firestore.ordered.Users[0],
  alertsAndSuggestions: state.firestore.ordered.AlertsAndSuggestions,
  currentApartment: state.apartment.currentApartment,
  currentDashboard: state.dashboard.currentDashboard,
  selectedZone: state.dashboard.selectedZone,
  ConsViews: state.firestore.ordered.ConsViews
    ? state.firestore.ordered.ConsViews[0]
    : [],
    consolidated: state.firestore.ordered.consolidated
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(),
  firebaseConnect(),
  withRouter
)(NavBar);
