/* eslint-disable prefer-template */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/button-has-type */
import React, { useMemo } from "react";
import styled from "styled-components";
import { useTable } from "react-table";
import { OverlayTrigger, Popover } from "react-bootstrap";
import DetailsHeader from "../../Common/DetailsHeader/DetailsHeader";
import Icon from "../../Common/Icon/Icon";

import ContactCard from "../../Common/ContactCard/ContactCard";
import image from "../../../../../assets/images/avatar.png";
import { BackButton, ExportButton, FilterButton } from "../../Common/Buttons";
import { Search, SearchInput } from "../styles";
import useWindowSize from "../../Hooks/useWindowSize";
import ProgressBar from "../../Common/ProgressBar/ProgressBar";

const inventorys = [
  {
    firstName: "Test Data",
    lastName: "Test Name",
    progress: 10,
    age: 12,
    status: "poor",
  },
  {
    firstName: "Test Data",
    lastName: "Test Name",
    progress: 52,
    age: 12,
    status: "poor",
  },
  {
    firstName: "Test Data",
    lastName: "Test Name",
    progress: 30,
    age: 12,
    status: "poor",
  },
  {
    firstName: "Test Data",
    lastName: "Test Name",
    progress: 87,
    age: 12,
    status: "medium",
  },
  {
    firstName: "Test Data",
    lastName: "Test Name",
    progress: 44,
    age: 12,
    status: "medium",
  },
  {
    firstName: "Test Data",
    lastName: "Test Name",
    progress: 59,
    age: 12,
    status: "good",
  },
  {
    firstName: "Test Data",
    lastName: "Test Name",
    progress: 67,
    age: 12,
    status: "good",
  },
  {
    firstName: "Test Data",
    lastName: "Test Name",
    progress: 24,
    age: 12,
    status: "good",
  },
  {
    firstName: "Test Data",
    lastName: "Test Name",
    progress: 74,
    age: 12,
    status: "good",
  },
  {
    firstName: "Test Data",
    lastName: "Test Name",
    progress: 28,
    age: 12,
    status: "good",
  },
];
const Styles = styled.div`
  display: block;
  max-width: 100%;
  table {
    font-family: Inter;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    background-color: #ffffff;
    th,
    td {
      border-bottom: 1px solid #e8e8e8;
      font-size: 14px;
      line-height: 17px;
    }
    th {
      text-transform: uppercase;
      font-weight: 600;
      color: #b6b6b6;
      padding: 22px 24px;
    }
    td {
      padding: 12px 24px;
    }
  }
`;

// Create a default prop getter
const defaultPropGetter = () => ({});

function Table({ columns, data, getRowProps = defaultPropGetter }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const InventoryDetails = () => {
  const { width: WindowWidth } = useWindowSize();
  const columns = useMemo(
    () => [
      {
        Header: "Property Name",
        accessor: "firstName", // accessor is the "key" in the data
      },
      {
        Header: "Contact Person",
        accessor: "lastName",
        Cell: ({ cell }) => (
          <div className="d-flex flex-row align-items-center">
            <img src={image} alt="avatar" />
            &nbsp;&nbsp;{cell.row.values.lastName}
            <OverlayTrigger
              trigger="click"
              key={"bottom"}
              placement={"bottom"}
              overlay={
                <Popover
                  id={`popover-positioned-bottom`}
                  style={{ maxWidth: "350px" }}
                >
                  <ContactCard />
                  <ContactCard />
                </Popover>
              }
            >
              <button style={{ background: "none", border: "none" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                >
                  <path
                    d="M1 1L4 4L7 1"
                    stroke="#B5B5B5"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </OverlayTrigger>
          </div>
        ),
      },
      {
        Header: "Progress",
        accessor: "progress",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <ProgressBar
            bgcolor={
              original.status === "good"
                ? "#01D09F"
                : original.status === "medium"
                ? "#FFDB1C"
                : "#FF8D8D"
            }
            completed={original.progress}
          />
        ),
      },
      {
        Header: "Details",
        accessor: "age",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => (
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div
              className={"status-" + cell.row.values.status}
              style={{
                width: "119px",
                height: "30px",
                borderRadius: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: "12px",
                  color: "#FFFFFF",
                  textTransform: "uppercase",
                }}
              >
                {cell.row.values.status}
              </span>
            </div>
            <button style={{ border: "none", background: "none" }}>
              <Icon icon="rightArrow" fill="#558DCA" />
            </button>
          </div>
        ),
      },
    ],
    []
  );
  const data = useMemo(() => inventorys, []);
  return (
    <div className="d-flex flex-row flex-wrap m-4">
      <div className="col-lg-12 col-md-12 col-sm-12">
        {WindowWidth <= 768 ? (
          <div style={{ marginTop: "1.25rem" }}>Inventory Details</div>
        ) : null}
        <div className="exe-details-header-container d-flex flex-row justify-content-between">
          <div className="d-flex flex-row align-items-center">
            <BackButton />
            <DetailsHeader
              label="Inventory Status"
              description="Description to be defined"
            />
          </div>
          <div className="d-flex flex-row align-items-center col-5 m-0 p-0">
            <ExportButton />
            <FilterButton />
            <Search className="ml-4 flex-grow-1">
              <SearchInput type="text" className="w-100" placeholder="Search" />
              <i className="icon-search" />
            </Search>
          </div>
        </div>
        <Styles>
          {!data.length ? (
            <div className="d-flex justify-content-center mt-5">
              <span className="mt-5">No Issues to Display</span>
            </div>
          ) : (
            <Table
              columns={columns}
              data={data}
              getRowProps={(row) => ({
                style: {
                  borderLeft:
                    row.original.status === "good"
                      ? "8px solid #01D09F"
                      : row.original.status === "medium"
                      ? "8px solid #FFDB1C"
                      : "8px solid #FF8D8D",
                },
              })}
            />
          )}
        </Styles>
      </div>
    </div>
  );
};

export default InventoryDetails;
