import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const StaffDetails = ({staff, staffPhoto, getPhotoURL}) => {
  const [photoURL, setPhotoURL] = useState(null);
  const [detailView, setDetailView] = useState(false);

  const getProfileURL = async () => {
    const url = await getPhotoURL(staffPhoto);
    setPhotoURL(url);
  }

  useEffect(() => {
    getProfileURL()
  }, [staffPhoto]);

  return (
    <div className="d-flex">
      <span className="user-image cursor-pointer" onClick={() => setDetailView(true)}>
        { staffPhoto && photoURL ? <img alt="logo" src={photoURL}
          className="staffProfileLogo" style={{ height: 50, width: 50, borderRadius: '50%', objectFit: 'cover' }} /> : 
        staff.AltText }
      </span>
      <span className="d-inline-flex flex-column justify-content-center">
        <span>
          <span className={`badge-indicator ${staff.Indicator.toLowerCase()}`}></span>
          {staff.Name}
        </span>
        <span className="helper"> 
          {staff.ServiceSubArea}
          {staff.Phone && <a className="text-success ml-1 d-inline-flex align-items-center cursor-pointer" href={`tel:${staff.Phone}`}>
            <i className="icon-phone"></i>
          </a>}
        </span>
      </span>

      <StaffDetailsModal show={detailView} onHide={() => setDetailView(false)} {...{staff, photoURL}} />
    </div>
  )
}

const StaffDetailsModal = (props) => {
  const { staff, photoURL } = props;
  const { show, onHide } = props;


  return (
    <Modal className="profile" centered show={show} onHide={onHide}>
      <Modal.Header className="tb-modal-header pt-2 pb-0" closeButton>
      </Modal.Header>
      <Modal.Body className="tb-modal-body pb-3">
        <div className="profile-img d-flex justify-content-center">
          {staff.ImageURL && photoURL ?
            <img alt="logo" src={photoURL}
              className="staffProfileLogo" style={{ height: 100, width: 100, borderRadius: '50%', objectFit: 'cover' }} />
            :
            <div className="d-flex align-items-center justify-content-center altText" style={{ height: 100, width: 100, fontSize: '2rem' }}>{staff.AltText}</div>
          }
        </div>
        <h6 className="font-weight-bold mt-3 mb-2 text-center">{staff.Name} (ID: {staff.EmployeeDeviceId})</h6>
        <div className="row">
          {staff.Gender && <div className="col-md-12 text-center mb-2">
            <p>{staff.Gender}</p>
          </div>}
          {staff.Email && <div className="col-md-12 text-center mb-2">
            <p>
              <span className="helper mr-2"><i className="icon-mail"></i></span>
              <a href={`mailto:${staff.Email}`}>{staff.Email}</a>
            </p>
          </div>}
          {staff.Phone && <div className="col-md-12 text-center mb-2">
            <p>
              <span className="helper mr-2"><i className="icon-phone"></i></span>
              <a href={`tel:${staff.Phone}`}>{staff.Phone}</a>
            </p>
          </div>}
          {staff.ServiceArea && <div className="col-md-12 text-center mb-2">
            <p>{staff.ServiceArea}{`${staff.ServiceSubArea ? ` - ${staff.ServiceSubArea}` : ``}`}</p>
          </div>}
        </div>
      </Modal.Body>
      <Modal.Footer className="tb-modal-footer">
        <Button size="sm" variant="light" onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default StaffDetails