/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable dot-notation */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

// import SortableList from "./Components/Sortable/Sortable";
import StaffActivities from "./Components/Widgets/StaffActivities/StaffActivities";
import SystemHealth from "./Components/Widgets/SystemHealth/SystemHealth";
import AuditingStatus from "./Components/Widgets/AuditingStatus/AuditingStatus";
import InventoryStatus from "./Components/Widgets/InventoryStatus/InventoryStatus";
import ManpowerIssues from "./Components/Widgets/ManpowerIssues/ManpowerIssues";
import Incidents from "./Components/Widgets/Incidents/Incidents";
import Compliance from "./Components/Widgets/Compliance/Compliance";
import Todo from "./Components/Widgets/ToDo/ToDo";
import "./Executive.css";
import ico from "../../assets/images/blue-dash.svg";

import useWindowSize from "./Components/Hooks/useWindowSize";

const arrayMove = require("array-move");

const SortableItem = SortableElement(({ value, sortIndex }) => 
  // console.log("value :", { ...value, props: { ...value.props } });
   (
    <div style={{ width: 100, height: 100 }} className="col-6 mb-3">
      {/* <span>{sortIndex + 1}</span> */}
      {/* <div>{value}</div> */}
      {{ ...value, props: { ...value.props, order: sortIndex + 1 } }}
    </div>
  )
);
const SortableList = SortableContainer(({ items }) => (
  <div className="container">
    <div className="row">
      {items.map((value, index) => (
        <SortableItem
          key={`item-${value}_${index}`}
          index={index}
          sortIndex={index}
          value={value}
        />
      ))}
    </div>
  </div>
));
const getWidget = (
  cardName,
  editMode = false,
  isLoading,
  toggle = () => {},
  show
) => {
  switch (cardName) {
    case "System Health":
      return (
        <SystemHealth
          isLoading={isLoading}
          key={cardName}
          classValue="col-lg-8 col-md-12 col-sm-12"
          editMode={editMode}
          toggle={toggle}
          show={show}
        />
      );

    case "Auditing Status":
      return (
        <AuditingStatus
          isLoading={isLoading}
          key={cardName}
          classValue="col-lg-4 col-md-12 col-sm-12"
          editMode={editMode}
          toggle={toggle}
          show={show}
        />
      );

    case "Contracts and Services":
      return (
        <Compliance
          isLoading={isLoading}
          key={cardName}
          classValue="col-lg-8 col-md-12 col-sm-12"
          editMode={editMode}
          toggle={toggle}
          show={show}
        />
      );

    case "ToDo":
      return (
        <Todo
          isLoading={isLoading}
          key={cardName}
          classValue="col-lg-4 col-md-12 col-sm-12"
          editMode={editMode}
          toggle={toggle}
          show={show}
        />
      );

    case "Incidents":
      return (
        <Incidents
          isLoading={isLoading}
          key={cardName}
          classValue="col-lg-4 col-md-12 col-sm-12"
          editMode={editMode}
          toggle={toggle}
          show={show}
        />
      );

    case "Inventory Status":
      return (
        <InventoryStatus
          isLoading={isLoading}
          key={cardName}
          classValue="col-lg-4 col-md-12 col-sm-12"
          editMode={editMode}
          toggle={toggle}
          show={show}
        />
      );

    case "Manpower Issues":
      return (
        <ManpowerIssues
          isLoading={isLoading}
          key={cardName}
          classValue="col-lg-4 col-md-12 col-sm-12"
          editMode={editMode}
          toggle={toggle}
          show={show}
        />
      );

    case "Staff Activities":
      return (
        <StaffActivities
          isLoading={isLoading}
          key={cardName}
          classValue="col-lg-12 col-md-12 col-sm-12"
          editMode={editMode}
          toggle={toggle}
          show={show}
        />
      );

    default:
      return <div key={cardName} />;
  }
};

const Executive = ({
  firestore,
  ConsViews,
  consolidated,
  auth,
  setManagers,
  setCardStatus,
  resetCardStatus,
  cardStatus,
  managersList
}) => {
  const [editMode, setEditMode] = useState(false);
  const [cancelEditMode, setCancelEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewPreference, setviewPreference] = useState([]);
  const [backupView, setBackupView] = useState([]);

  const { width: WindowWidth } = useWindowSize();
  const [items, setItems] = useState([]);
  useEffect(() => {
    async function fetchManagers() {
      let users = [];
      setIsLoading(true);
      firestore
        .get({
          collection: "Users",
          where: [["is_cons_user", "==", true]
          ,["is_approved", "==", true]],
          storeAs: "ManagersList",
        })
        .then(async (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            users = [...users, { id: doc.id, ...doc.data() }];
          });

          await Promise.all(
            (users || []).map(async (user) =>
              firestore
                .get({
                  collection: "Users",
                  doc: user.id,
                  subcollections: [
                    {
                      collection: "ConsViews",
                      where: [["ConsViewId", "==", ConsViews[0].ConsViewId]],
                    },
                  ],
                  storeAs: "ManagersListZone",
                })
                .then(async (doc) => {
                  if (doc.empty) {
                    return false;
                  }

                  let managers = {};
                  doc.forEach((doc) => {
                    // console.log(doc.data());
                    managers = { UID: doc.id, ...doc.data() };
                  });
                  return { ...user, ...managers };
                })
            )
          )
            .then((prop) => {
              setIsLoading(false);
              const filterEmptyManagerList = prop.filter((manager) => manager);
              setManagers(filterEmptyManagerList);
            })
            .catch(() => setIsLoading(false));
        });
    }

    if (consolidated && consolidated.length && !managersList.length) {
      fetchManagers();
    }
  }, [consolidated]);

  useEffect(() => {
    firestore.get({
      collection: "consolidated",
      where: [["ConsViewId", "==", ConsViews[0].ConsViewId]],
      storeAs: "consolidated",
    });
  }, [ConsViews]);

  const toggle = (card, status) => {
    setCardStatus(card, status);
  };
  useEffect(() => {
    // Check card order from User -> ConsView -> CardPref
    // if not found then list with default order in Consolidated

    if (ConsViews && Object.keys(ConsViews[0].CardPref || {}).length) {
      const cardPreference = [];
      Object.keys(ConsViews[0].CardPref).map((key) => {
        cardPreference.push({ key, order: ConsViews[0].CardPref[key] });
      });

      const sorted = cardPreference
        .filter(({ order }) => order !== 0)
        .sort((a, b) => a.order - b.order)
        .map(({ key }) => key);

      setviewPreference(sorted);

      const editItems = cardPreference
        .sort((a, b) => a.order - b.order)
        .map(({ key, order }) => ({
          key,
          order,
        }));

      const _editItemsDisabled = editItems.filter(({ order }) => order === 0);
      const _editItems = editItems.filter(({ order }) => order !== 0);
      const editViewItems = [..._editItems, ..._editItemsDisabled];

      const editView = editViewItems.map(({ key, order }) =>
        getWidget(key, true, false, toggle, !!order)
      );

      setItems(editView);
      setBackupView(editView);
    } else if (consolidated && consolidated[0].DefaultCardOrder) {
      // Default Order
      setviewPreference(consolidated[0].DefaultCardOrder);
      const editView = consolidated[0].DefaultCardOrder.map((card) =>
        getWidget(card, true, false,toggle,true)
      );
      setItems(editView);
      setBackupView(editView);
    }
  }, [JSON.stringify(consolidated)]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItems((items) => arrayMove(items, oldIndex, newIndex));
  };

  useEffect(() => {
    if (!editMode && items.length && !cancelEditMode) {
      // console.log("Saving..");
      const newCardStatus = Object.keys(cardStatus).map((key) => ({
        card: key,
        status: cardStatus[key],
      }));
      const newSorted = items.map(({ key }, index) => {
        const found = newCardStatus.find(({ card }) => card === key);
        return {
          card: key,
          order: found && !found.status ? 0 : index + 1,
          status: found ? found.status : true,
        };
      });

      const _newSortedDisabled = newSorted.filter(({ order }) => order === 0);
      const _newSorted = newSorted
        .filter(({ order }) => order !== 0)
        .map((item, index) => ({ ...item, order: index + 1 }));

      const editViewItems = [..._newSorted, ..._newSortedDisabled];
      const setView = _newSorted.map(({ card }) => card);

      setviewPreference(setView);

      let cardPreference = {};
      editViewItems.map(({ card, order }) => {
        cardPreference = { ...cardPreference, [card]: order };
        return cardPreference;
      });

      if (Object.keys(cardPreference).length) {
        firestore
          .update(
            {
              collection: "Users",
              doc: auth.uid,
              subcollections: [
                {
                  collection: "ConsViews",
                  doc: ConsViews[0].id,
                },
              ],
            },
            {
              CardPref: cardPreference,
            }
          )
          .then(() => {})
          .catch((err) => console.log(err));
      }
    }
  }, [editMode]);

  const renderDashboardView = (preference, editMode) => {
    if (preference && preference.length) {
      return (
        <>{preference.map((card) => getWidget(card, editMode, isLoading))}</>
      );
    }
  };

  return (
    <div className="d-flex flex-row flex-wrap m-4">
      <div className="col-lg-12 col-md-12 col-sm-12">
        {WindowWidth <= 768 ? (
          <div style={{ marginTop: "1.25rem" }}>Dashboard</div>
        ) : null}
        <div className="d-flex flex-row justify-content-between">
          <div style={{ margin: "15px 0px" }}>
            <div className="dashboard-header">Dashboard</div>
            {/* <div className="dashboard-sub-header">
              South Zone, 18 Properties
            </div> */}
          </div>
          <div
            className="align-items-center d-flex"
            style={{ margin: "15px 0px" }}
          >
            {editMode ? (
              <button
                className="cancelButton"
                onClick={() => {
                  setCancelEditMode(true);
                  setEditMode(false);
                }}
              >
                Cancel
              </button>
            ) : null}
            <button
              className="d-none d-md-block align-items-center  layout-mode-button"
              style={{
                background: !editMode ? "#cce4ff" : "#558DCA",
                color: editMode ? "#fff" : "#558dca",
              }}
              onClick={() => {
                if (!editMode) {
                  setItems(backupView);
                  setCancelEditMode(false);
                  resetCardStatus();
                }
                setEditMode(!editMode);
              }}
            >
              <img src={ico} className="mr-2" alt="edit" />
              {editMode ? "Save Layout" : "Edit Layout"}
            </button>
          </div>
        </div>
      </div>
      {editMode ? (
        <SortableList items={items} onSortEnd={onSortEnd} axis="xy" />
      ) : (
        renderDashboardView(viewPreference)
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  // Apartment List with managers
  setManagers: (manegers) =>
    dispatch({ type: "SET_MANAGERS_LIST", payload: manegers }),
  setCardStatus: (card, status) =>
    dispatch({ type: "SET_CARD_STATUS", payload: { card, status } }),
  resetCardStatus: () => dispatch({ type: "RESET_CARD_STATUS" }),
});
export default compose(
  connect(
    ({
      dashboard,
      firebase,
      apartment,
      firestore,
      firestore: {
        ordered: { ConsViews, consolidated },
      },
    }) => ({
      auth: firebase.auth,
      firestore,
      ConsViews,
      consolidated,
      managersList: apartment.managersList,
      cardStatus: dashboard.cardStatus,
    }),
    mapDispatchToProps
  ),
  firestoreConnect()
)(Executive);
