/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Tabs, Tab, Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';
import qs from 'query-string';
import * as _ from 'lodash';

import { withRouter, Link } from 'react-router-dom';
import AddIncidentModal from './AddIncidentModal/AddIncidentModal';
import Avatar from '../common/Avatar/Avatar';
import SearchBarFilter from '../common/SearchBarFilter';
import TicketDetails from '../TicketDetails/TicketDetails';
import AssignTicketModal from './AssignTicketModal/AssignTicketModal';
import Constant from "./constant";

import HomeIcon from '../../assets/images/home_alt_outline.svg';
import './incidents.scss';

const TicketStatuses = ['New', 'In Progress', 'On Hold', 'Completed'];
const today = new Date();

const Tag = ({ text, onRemove, className }) => (
	<div className={['tb-tag d-inline-block bg-white font-13', className].join(' ')}>
		<span className="d-flex align-items-center">
			<span className="">{text}</span>
			<i className="icon-close-circle ml-4 cursor-pointer font-16" onClick={onRemove} />
		</span>
	</div>
)



export class Incidents extends Component {

	state = {
		modalVisibility: false,
		assignModalVisibility: false,
		activeTab: 'Residents',
		status: '',
		searchValue: '',
		focusSearch: false,
		selectedTicket: null,
		assigningTicket: null,
		isPrivilgedUser: false,
		selectedStatus: [],
		selectedAssignee: null,
		ageFilter: '',
	}

	componentDidMount() {
		const { user, location, currentApartment, ApartmentAccessInfo } = this.props;
		let accessInfo = null;
        if(ApartmentAccessInfo.length){
          const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
         if(selectedApartment){
           accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
         }
        }
        const { is_mc_member, is_manager } = accessInfo || {}


		// const { is_manager, is_mc_member } = userAccessInfo[0];
		this.setState({ isPrivilgedUser: is_manager || is_mc_member })
		
		this.fetchData(currentApartment);
		document.getElementById('main-block').scrollTo(0,0)
		if(location.search) {
			const { ageFilter } = qs.parse(location.search);
			this.setState({ ageFilter })
		}
		window.addEventListener('online', this.onLineTrigger)
	}

	// componentDidUpdate(prevProps, prevState) {
	// 	const { currentApartment } = this.props;
	// 	if(currentApartment !== prevProps.currentApartment){
	// 		this.fetchData(currentApartment);	
	// 	}
		
	// }

	componentWillUnmount(){
		window.removeEventListener('online', this.onLineTrigger)
	}

	onLineTrigger = async () => {
		const { offlineTicketFiles, user, firestore, firebase, cacheTicketFiles, currentApartment } = this.props;
		if(offlineTicketFiles && offlineTicketFiles.length){
			await Promise.all(offlineTicketFiles.map(async t => {
				await Promise.all(
					t.Files.map(async f => {
						await firebase.uploadFiles(f.filePath, f.File);
						delete f.File
						firestore
						.collection("apartments")
						.doc(currentApartment)
						.collection('IncidentReports')
						.doc(t.ticketId)
						.collection('Files')
						.add(f)
					})
				)
			}))
			cacheTicketFiles([]);
		}
	}

	

	componentDidUpdate(prevProps) {
		const { location, incidents, currentApartment } = this.props;
		const { ageFilter, ticket: ticketid } = qs.parse(location.search);
		if(currentApartment !== prevProps.currentApartment){
			this.fetchData(currentApartment);	
		}
		if(location.search !== prevProps.location.search) {
			this.setState({ ageFilter });
		}
		if (ticketid && incidents && incidents.length !== (prevProps.incidents || []).length) {
			const ticket = incidents.find(i => i.id === ticketid);
			this.setState({ selectedTicket: ticket, assigningTicket: ticket });
		}
		
	}

	fetchData(currentApartment) {
		const { firestore, incidents } = this.props;
		// if (!incidents) {
			console.log('currentApartment :',currentApartment)
			if (this.incidents) {
				this.incidents();
			}
			 
			  this.incidents = firestore.onSnapshot({
				collection: 'apartments',
				doc: currentApartment,
				subcollections: [{
					collection: 'IncidentReports', where: [
						['IsArchived', '==', false],
					]
				}],
				storeAs: `incidents`
			});
		// }
	}

	ageFilterChange = (e) => {
		const { location: { search } } = this.props;
		const queryParams = qs.parse(search);
		e.target.value ? queryParams.ageFilter = e.target.value : delete queryParams.ageFilter;
		this.props.history.push({
			pathname: '/incidents',
			search: qs.stringify(queryParams),
		})
		this.setState({ ageFilter: e.target.value, selectedStatus: [] })
	}

	addIncidentBtnHandler = () => {
		this.setState({ modalVisibility: true });
	}

	onCloseModal = () => {
		this.setState({ modalVisibility: false, selectedAssignee: null });
	}

	onTabChange = (eventKey) => {
		this.setState({ activeTab: eventKey,selectedStatus: [] })
	}

	showCompletedTicketChangeHandler = () => {
		this.setState(prevState => ({ showCompletedTickets: !prevState.showCompletedTickets }))
	}

	onStatusChange = (e) => {
		const { selectedStatus } = this.state;
		if(e.target.checked) {
			selectedStatus.push(e.target.value)
			this.setState({ selectedStatus })
			return
		}
		this.setState({ selectedStatus: selectedStatus.filter(s => s !== e.target.value) })
	}

	onSearch = (e) => {
		this.setState({ searchValue: e.target.value });
	}

	searchTickets = (tickets) => {
		const { searchValue } = this.state;
		return tickets.filter(ticket => (
			(ticket.Title && ticket.Title.substring(0, searchValue.length).toLowerCase() === searchValue.toLowerCase()) ||
			(ticket.TicketNo && ticket.TicketNo.substring(0, searchValue.length).toLowerCase() === searchValue.toLowerCase()) ||
			(ticket.HouseNo && ticket.HouseNo.substring(0, searchValue.length).toLowerCase() === searchValue.toLowerCase())
		));
	}

	onClickTicket = (ticket) => {
		this.setState({ selectedTicket: ticket, assigningTicket: ticket });
	}

	backClickHandler = () => {
		this.setState({ selectedTicket: null, assigningTicket: null });
	}

	assignModalTrigger = async (event, ticket, assignee = '') => {
		event.stopPropagation();
		this.setState({ assignModalVisibility: true })
		const { firestore, user, currentApartment } = this.props;
		const { isPrivilgedUser } = this.state;
		this.setState({ assigningTicket: ticket });
		if(ticket.PerformerRoles && ticket.PerformerRoles.length && isPrivilgedUser) {
			await firestore.get({
				collection: 'apartments',
				doc: currentApartment,
				subcollections: [{
					collection: 'Users', where: [
						['Roles', 'array-contains-any', ticket.PerformerRoles],
					]
				}],
				storeAs: `assigneeList`
			});
			if(assignee) {
				this.setState({ selectedAssignee: assignee });
				return
			}
			this.setState({ selectedAssignee: null });
		}
	}

	assignHandler = async (assignee) => {
		const { assigningTicket } = this.state;
		const { firestore, user, currentApartment } = this.props;
		const { DisplayName } = user[0];
		
		const updateDoc = {
			IsAssigned: true,
			AssignedTo: assignee.DisplayName,
			AssignedDate: firestore.Timestamp.fromDate(new Date()),
			LastUpdatedDate: firestore.Timestamp.fromDate(new Date()),
			LastUpdatedBy: DisplayName,
		};
		
		await firestore.update({
			collection: 'apartments',
			doc: currentApartment,
			subcollections: [{collection: 'IncidentReports', doc: assigningTicket.id }]
		}, updateDoc)
		this.update();
		this.setState({ assignModalVisibility: false });
	}

	changePriority = async (priority, ticket) => {
		const { firestore, user, currentApartment } = this.props;
		const { DisplayName } = user[0];
		
		const updateDoc = {
			Priority: priority,
			LastUpdatedDate: firestore.Timestamp.fromDate(new Date()),
			LastUpdatedBy: DisplayName,
		};
		
		await firestore.update({
			collection: 'apartments',
			doc: currentApartment,
			subcollections: [{collection: 'IncidentReports', doc: ticket.id }]
		}, updateDoc)

		this.update();
	}

	changeCategory = async (category, ticket) => {
		const { firestore, user, currentApartment } = this.props;
		const { DisplayName } = user[0];
		
		const updateDoc = {
			TicketCategory: category,
			LastUpdatedDate: firestore.Timestamp.fromDate(new Date()),
			LastUpdatedBy: DisplayName,
		};
		
		await firestore.update({
			collection: 'apartments',
			doc: currentApartment,
			subcollections: [{collection: 'IncidentReports', doc: ticket.id }]
		}, updateDoc)

		this.update();
	}

	update = () => {
		const { selectedTicket } = this.state;
		const { incidents } = this.props
		if(selectedTicket) {
			const updatedTicket = incidents.find(ticket => ticket.id === selectedTicket.id);
			this.setState({ selectedTicket: updatedTicket });
		}
	}

	sortTicket = (tickets) => {
		const sortedtickets = tickets && tickets
		.sort((a, b) => new Date(a.CreationDate.toDate()).getTime() - new Date(b.CreationDate.toDate()).getTime())
		.sort((a, b) => Constant.priority[a.Priority].order - Constant.priority[b.Priority].order)
		.sort((a, b) => b.IsActive - a.IsActive )
		return sortedtickets;
	}

	render() {
		const { modalVisibility, activeTab, status, selectedStatus, focusSearch, searchValue, ageFilter, selectedTicket, selectedAssignee, assignModalVisibility, isPrivilgedUser } = this.state;
		const { incidents, location: { search }, assigneeList, user, ApartmentAccessInfo, currentApartment } = this.props;
		let accessInfo = null;
        if(ApartmentAccessInfo.length){
          const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
         if(selectedApartment){
           accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
         }
        }
        const { Roles } = accessInfo || {}
		const { myIncidents } = qs.parse(search);
		const { DisplayName } = user[0];
	
		let tickets = incidents || [];
		let totalCount = tickets.length;
		if ((myIncidents === 'true' || !isPrivilgedUser) && accessInfo && DisplayName) {
			// const { Roles } = userAccessInfo[0];
			tickets = tickets && tickets.filter(incident => incident.PerformerRoles.some(role => Roles.includes(role)) && (!incident.IsAssigned || (incident.IsAssigned && incident.AssignedTo == DisplayName) ))
			totalCount = tickets.length;
		}

		if(ageFilter === 'ten-plus') {
			tickets = tickets.filter(incident => 
				incident.IsActive === true && 
				incident.CreationDate.toDate().getTime() <= new Date().setDate(today.getDate()-10))
		}else if(ageFilter === 'five-ten') {
			tickets = tickets.filter(incident => 
				incident.IsActive === true && 
				incident.CreationDate.toDate().getTime() <= new Date().setDate(today.getDate()-5) &&
				incident.CreationDate.toDate().getTime() > new Date().setDate(today.getDate()-10)
			)
		}else if(ageFilter === 'two-five') {
			tickets = tickets.filter(incident => 
				incident.IsActive === true && 
				incident.CreationDate.toDate().getTime() <= new Date().setDate(today.getDate()-2) &&
				incident.CreationDate.toDate().getTime() > new Date().setDate(today.getDate()-5)
			)
		}else if(ageFilter === 'below-two') {
			tickets = tickets.filter(incident => 
				incident.IsActive === true && 
				incident.CreationDate.toDate().getTime() > new Date().setDate(today.getDate()-2)
			)
		}

		let residentsTicketCount = 0;
		let communityTicketCount = 0;
		tickets && tickets.forEach(ticket => {
			ticket.IncidentType === 'Residents' ? residentsTicketCount += 1 : communityTicketCount += 1;
		})

		tickets = tickets && tickets.filter(incident =>
			incident.IncidentType === activeTab
			// incident.IsActive === true
		);


		let newTicketsCount = 0;
		let inProgressTicketsCount = 0;
		let onHoldTicketsCount = 0;
		let completedTicketsCount = 0;

		tickets && tickets.forEach(ticket => {
			if (ticket.Status === 'New') newTicketsCount += 1;
			if (ticket.Status === 'In Progress') inProgressTicketsCount += 1;
			if (ticket.Status === 'On Hold') onHoldTicketsCount += 1;
			if (ticket.Status === 'Completed') completedTicketsCount += 1;
		})

		if (selectedStatus && selectedStatus.length) {
			tickets = tickets.filter(ticket => selectedStatus.includes(ticket.Status));
		}
		tickets = this.searchTickets(tickets)

		const TicketStatus = (
		// 	<div className="row">
		// 		{
		// 			TicketStatuses.map(status => (
		// 				<div className="col-md-3 col-6 mb-3">
		// 					<div className="status-radio-btn">
		// 						<input type="checkbox" checked={selectedStatus.includes(status)} id={status} name="incidentStatus" onChange={this.onStatusChange} value={status} />
		// 						<label htmlFor={status}>
		// 							<span className="d-block h5 m-0 font-weght-bold">{newTicketsCount}</span>
		// 							<span className="font-weight-500">{status}</span>
		// 						</label>
		// 					</div>
		// 				</div>
		// 			))
		// 		}
		// 	</div>
		// ) 
			<div className="row status-track">
				<div className="col-md-3 col-6 mb-3">
					<div className="status-radio-btn">
						<input type="checkbox" checked={selectedStatus.includes('New')} id="New" name="incidentStatus" onChange={this.onStatusChange} value="New" />
						<label htmlFor="New" style={{ backgroundColor: '#ff6a6a'}}>
							<span className="">New</span>
							<span className="d-block h5 m-0 count" style={{ color: '#ff6a6a' }}>
								{newTicketsCount}
							</span>
						</label>
					</div>
				</div>
				<div className="col-md-3 col-6 mb-3">
					<div className="status-radio-btn">
						<input type="checkbox" checked={selectedStatus.includes('In Progress')} id="In Progress" name="incidentStatus" onChange={this.onStatusChange} value="In Progress" />
						<label htmlFor="In Progress" style={{ backgroundColor: '#14d5ff'}}>
							<span className="">In Progress</span>
							<span className="d-block h5 m-0 count" style={{ color: '#14d5ff' }}>
								{inProgressTicketsCount}
							</span>
						</label>
					</div>
				</div>
				<div className="col-md-3 col-6 mb-3">
					<div className="status-radio-btn">
						<input type="checkbox" checked={selectedStatus.includes('On Hold')} id="On Hold" name="incidentStatus" onChange={this.onStatusChange} value="On Hold" />
						<label htmlFor="On Hold" style={{ backgroundColor: '#ffbf00'}}>
							<span className="">On Hold</span>
							<span className="d-block h5 m-0 count" style={{ color: '#ffbf00' }}>
								{onHoldTicketsCount}
							</span>
						</label>
					</div>
				</div>
				<div className="col-md-3 col-6 mb-3">
					<div className="status-radio-btn">
						<input type="checkbox" checked={selectedStatus.includes('Completed')} id="Completed" name="incidentStatus" onChange={this.onStatusChange} value="Completed" />
						<label htmlFor="Completed" style={{ backgroundColor: 'green'}}>
							<span className="">Completed Today</span>
							<span className="d-block h5 m-0 count" style={{ color: 'green' }}>
								{completedTicketsCount}
							</span>
						</label>
					</div>
				</div>
			</div>
		);

		const SelectedTags = (
			<div className="mb-3 tag-track">
				{
					selectedStatus.map(s=> (
						<Tag key={s} text={s} className="mr-2" onRemove={()=> {
							this.setState({ selectedStatus: selectedStatus.filter(st=> st !== s ) })
						}} />
					))
				}
				{
					(selectedStatus.length > 0) &&
					<button 
						className="btn btn-link text-danger text-underline p-0 pl-1"
						onClick={() => this.setState({ selectedStatus: [] })}
					>
						Clear all
					</button>
				}
			</div>
		)

		const categories = _.groupBy(tickets, 'TicketCategory');
		const CategoriesList = Object.keys(categories).map(key => {
			const sorted =  this.sortTicket(categories[key])
			return (
				<div className="mb-3 category" key={key}>
					<Accordion.Toggle as={Card.Header} eventKey={key}>
						<div className="d-flex align-items-center justify-content-between">
							<div className="d-flex align-items-center">
								<h6 className="mb-0 font-weight-700">{key}</h6>
								<span className="inline-block ml-1 font-weight-700">({categories[key].length})</span>
							</div>
							<i className="icon-chevron-down font-weight-700 text-dark mr-3 show" />
						</div>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey={key}>
						<table className="table incidents-table mb-0">
							<thead>
								<tr>
									<th width="10%">Ticket No</th>
									<th width="23%">Title</th>
									<th width="20%">Created Date</th>
									<th width="14%">Priority</th>
									<th width="22%">Assignee</th>
									<th width="10%">Status</th>
								</tr>
							</thead>
							<tbody>
								{
									sorted.map(incident => (
										<tr key={incident.id} style={{ borderTopColor: Constant.priority[incident.Priority].color }} onClick={() => this.onClickTicket(incident)}>
											<td width="10%" className="text-truncate">{incident.TicketNo}</td>
											<td width="23%" className="text-truncate">
												{incident.Title}
												{
													incident.HouseNo &&
													<span className="text-secondary small d-md-block d-none">
														<img src={HomeIcon} className="mr-1 mb-1" />
														{incident.HouseNo}
													</span>
												}
											</td>
											<td width="20%" className="text-truncate">{moment(new Date(incident.CreationDate.toDate())).format('DD MMM YYYY, hh:mm A')}</td>
											<td width="14%" className="text-truncate">
												{
													!isPrivilgedUser ?
													<span
														className="d-inline-block priority"
														style={{
															color: Constant.priority[incident.Priority].color,
															borderColor: Constant.priority[incident.Priority].color
														}}
													>
														{incident.Priority}
													</span>
													: (
														<select className="form-control priority-select" 
															data-color={incident.Indicator}
															onClick={(e) => e.stopPropagation()}
															style={{
																color: Constant.priority[incident.Priority].color,
																borderColor: Constant.priority[incident.Priority].color
															}}
															value={incident.Priority}
															onChange={(e) => this.changePriority(e.target.value, incident)}
														>
															<option value="High">High</option>
															<option value="Medium">Medium</option>
															<option value="Low">Low</option>
														</select>
													)
												}
											</td>
											<td width="22%" >
												{
													incident.IsAssigned && incident.AssignedTo &&
													<div className="text-truncate">
														<Avatar name={incident.AssignedTo} size={28} onClick={(e) =>{
															this.assignModalTrigger(e,incident,incident.AssignedTo)
														}} />
														<span className="mx-2">{incident.AssignedTo}</span>
													</div>
												}
												{
													!incident.IsAssigned &&
													<button
														style={{ height: 28, width: 28, padding: '2px 6px', fontSize: '90%' }}
														title="Click to add"
														className="btn btn-outline-primary rounded-circle btn-sm"
														onClick={(e) => this.assignModalTrigger(e, incident)}
														disabled={!isPrivilgedUser}
													>
														<FontAwesomeIcon icon={faUserPlus} />
													</button>
												}
											</td>
											<td width="10%" className="text-truncate">
												<span className="d-block status">{incident.Status}</span>
												{
													incident.Status === 'On Hold' && incident.OnHoldReason &&
													<span className="d-md-block d-none text-secondary text-truncate font-italic small">
														{incident.OnHoldReason}
													</span>
												}
												<span className="d-md-none d-block">
													<i className="icon-dashboard_home mr-2"/>
													{incident.HouseNo}
												</span>
											</td>
										</tr>
									))
								}

							</tbody>
						</table>
					</Accordion.Collapse>
				</div>
			)
		});


		return (
			<>
				{
					!selectedTicket &&
					<div id="incidents">
						<div className="d-flex justify-content-between align-items-start mb-2 incident-header">
							<h1 className="h4 font-weight-bold mb-0">
								Tickets ({totalCount})
								<div className="font-wight-400 font-13 mt-2  d-md-block d-none">
									{isPrivilgedUser ?
									<>
										<Link to="/incident-summary" className="text-secondary">Ticket's summary / </Link>
										<span className="text-secondary">Tickets</span>
									</>		:
									<Link to="/dashboard">Tickets</Link>
									}
								</div>
							</h1>
							<button
								className="btn btn-primary rounded-0 py-2 add-incident-btn"
								onClick={this.addIncidentBtnHandler}
							>
								<span className="d-md-inline d-none">Add a Ticket</span>
								<i className="icon-plus d-md-none d-inline" />
          					</button>
						</div>
						<div className="px-md-0 px-3">
							<div className="d-flex flex-wrap align-items-baseline justify-content-end mt-3">
								<SearchBarFilter
									focusSearch={focusSearch}
									onTextChange={this.onSearch}
									onFocus={() => this.setState({ focusSearch: true })}
									onBlur={() => this.setState({ focusSearch: false })}
									nameSort={searchValue}
									placeholder="Enter Title, Ticket No or House No"
								/>
								<select className="form-control ml-3 age-filter" value={ageFilter} onChange={this.ageFilterChange}>
									<option value="">Age Filter: All</option>
									<option value="ten-plus">Age Filter: 10+ Days</option>
									<option value="five-ten">Age Filter: 5-10 Days</option>
									<option value="two-five">Age Filter: 2-5 Days</option>
									<option value="below-two">Age Filter: Below 2 Days</option>
								</select>
							</div>
							<Tabs className="incident-tab" defaultActiveKey={activeTab} onSelect={this.onTabChange}>
								<Tab eventKey="Residents" title={`Residents (${residentsTicketCount})`}>
									<Accordion color="" className="mt-3">
										{TicketStatus}
										{SelectedTags}
										{CategoriesList}
									</Accordion>
								</Tab>
								<Tab eventKey="System" title={`Community (${communityTicketCount})`}>
									<Accordion color="" className="mt-4">
										{TicketStatus}
										{SelectedTags}
										{CategoriesList}
									</Accordion>
								</Tab>
							</Tabs>
						</div>
						<AddIncidentModal
							show={modalVisibility}
							onHide={this.onCloseModal}
						/>
					</div>
				}
				{
					selectedTicket &&
					<TicketDetails
						ticket={selectedTicket}
						isPrivilgedUser={isPrivilgedUser}
						onBackClick={this.backClickHandler}
						triggerAssign={this.assignModalTrigger}
						update={this.update}
						onChangePriority={this.changePriority}
						onChangeCategory={this.changeCategory}
					/>
				}
				<AssignTicketModal
					show={assignModalVisibility}
					onHide={()=> this.setState({ assignModalVisibility: false, assigningTicket: null })}
					assignees={assigneeList}
					value={selectedAssignee}
					onAssign={this.assignHandler}
				/>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.firestore.ordered.Users && state.firestore.ordered.Users,
	incidents: state.firestore.ordered.incidents,
	// userAccessInfo: state.firestore.ordered.UserAccessInfo,
	ticketDetails: state.firestore.ordered.ticketDetails,
	assigneeList: state.firestore.ordered.assigneeList,
	offlineTicketFiles: state.updateModule.offlineTicketFiles,
	currentApartment: state.apartment.currentApartment,
	ApartmentAccessInfo: state.apartment.ApartmentAccessInfo
})

const mapDispatchToProps = dispatch => ({
	cacheTicketFiles: (files) => dispatch({ type: 'ADD_OFFLINE_TICKET_FILES', payload: files })
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect(),
	firebaseConnect(),
	withRouter
)(Incidents)
