import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AddTaskModal.scss";
import * as _ from "lodash";
import Select from "react-select";

export function AddTaskModal({
  callback,
  formData,
  defaults,
  updateFormCb,
  handleSubmitCb,
}) {
  const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  const [formVal, setForm] = useState(defaults);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const form = {
      ServiceArea: formVal.ServiceArea,
      ServiceModule: formVal.ServiceModule,
      TaskName: formVal.TaskName,
      IsPhotoTask: formVal.IsPhotoTask,
      IsPhotoReq: formVal.IsPhotoReq,
      IsVerificationTask: formVal.IsVerificationTask,
      ApprovalLevels: formVal.ApprovalLevels,
      IsRecurringTask: formVal.IsRecurringTask,
      QryParamSchedule: formVal.IsSheduledTask
        ? formVal.Schedules === "Hourly"
          ? "HOURLY"
          : "DAILY"
        : "AdHoc",
      Schedule: formVal.IsSheduledTask ? formVal.Schedule : "",
      NextInvocationDays: formVal.IsSheduledTask
        ? formVal.Schedule.match(/\d+/)
          ? parseInt(formVal.Schedule.match(/\d+/)[0])
          : formVal.Schedules !== "Hourly"
          ? 1
          : 0
        : 0,
      AllPerformers: getPerformers([
        ...formVal.AllPerformers,
        ...formVal.Level_2,
        ...formVal.Level_3,
      ]),
      ActivityStartDate: formVal.ActivityStart,
      ActivityStartTime: `${formVal.ActivityStart.getHours()}:${formVal.ActivityStart.getMinutes()}`,
      AptId: formVal.AptId,
      Level_1: getPerformers(formVal.AllPerformers),
      Level_2: formVal.ApprovalLevels > 1 ? getPerformers(formVal.Level_2) : [],
      Level_3: formVal.ApprovalLevels > 2 ? getPerformers(formVal.Level_3) : [],
      GracePeriod: formVal.GracePeriod,
      IsNewTask: formVal.IsNewTask,
    };
    handleSubmitCb(form);
  };

  const isSaveDisabled = () => {
    const {
      ServiceArea,
      ServiceModule,
      TaskName,
      ApprovalLevels,
      Level_2,
      Level_3,
      AllPerformers,
      IsSheduledTask,
      Schedule,
      Schedules,
    } = formVal;
    if (!ServiceArea) {
      return true;
    }
    if (!ServiceModule) {
      return true;
    }
    if (!TaskName) {
      return true;
    }
    if (ApprovalLevels > 1 && (!Level_2 || !Level_2.length)) {
      return true;
    }
    if (ApprovalLevels > 2 && (!Level_3 || !Level_3.length)) {
      return true;
    }
    if (!AllPerformers || !AllPerformers.length) {
      return true;
    }
    if (IsSheduledTask && (!Schedule || !Schedules)) {
      return true;
    }
    return false;
  };

  const getPerformers = (arr) => {
    const performers = arr.map((item) => {
      return item.value;
    });

    return _.uniq(performers);
  };

  useEffect(() => {
    updateFormCb(formVal);
  }, [formVal]);

  const onFieldChange = (value, name) => {
    if (name === "IsPhotoReq" && value) {
      setForm({
        ...formVal,
        IsPhotoReq: true,
        IsPhotoTask: true,
      });
    } else {
      setForm({
        ...formVal,
        [name]: value,
      });
    }
  };

  const resetSchedule = (value, name) => {
    setForm({
      ...formVal,
      [name]: value,
      Schedule: "",
    });
  };

  const getField = (name) => {
    let field = _.find(formData.UIFields, (obj) => {
      return obj.Name === name;
    });
    return field;
  };

  const createOptions = (options) => {
    const opts = (options || []).map((item) => {
      return {
        value: item,
        label: item,
      };
    });

    return opts;
  };

  return (
    <div className="add-task-modal">
      <div className="d-flex justify-content-between">
        <div className="addTaskHeading">Add Task</div>
        <button className="close" onClick={() => callback()} type="submit">
          <img
            src={require("../../assets/images/close.png")}
            alt="cal"
            style={{ height: 16, width: 16 }}
          />
        </button>
      </div>
      <>
        <div className="addTaskSubHeadings mt-4">Service Area</div>
        <select
          className="modalDropdown w-100"
          onChange={(e) => onFieldChange(e.target.value, "ServiceArea")}
          value={formVal["ServiceArea"]}
        >
          {formData["ServiceArea"].map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
        <div className="addTaskSubHeadings mt-4">Service Module</div>
        <select
          className="modalDropdown w-100"
          value={formVal["ServiceModule"]}
          onChange={(e) => onFieldChange(e.target.value, "ServiceModule")}
        >
          <option></option>
          {formVal["ServiceArea"] &&
            formData["ServiceActivity"][formVal["ServiceArea"]] &&
            formData["ServiceActivity"][formVal["ServiceArea"]].map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
        </select>
        <div className="addTaskSubHeadings mt-4">Task Name</div>
        <input
          className="addTaskInputs w-100"
          value={formVal["TaskName"]}
          maxLength={getField("Task Name").Length}
          onChange={(e) => onFieldChange(e.target.value, "TaskName")}
        />
        <div className="d-flex mt-4 align-items-center">
          <div className="addTaskSubHeadings">Is Photo Task</div>
          <input
            className="ml-auto mr-5"
            checked={formVal["IsPhotoTask"]}
            type="checkbox"
            onChange={(e) =>
              onFieldChange(!formVal["IsPhotoTask"], "IsPhotoTask")
            }
          />
        </div>
        <div className="d-flex mt-4 align-items-center">
          <div className="addTaskSubHeadings">Is Photo Mandatory</div>
          <input
            className="ml-auto mr-5"
            checked={formVal["IsPhotoReq"]}
            type="checkbox"
            onChange={(e) =>
              onFieldChange(!formVal["IsPhotoReq"], "IsPhotoReq")
            }
          />
        </div>
        <div className="d-flex mt-4 align-items-center">
          <div className="addTaskSubHeadings">Is Verification Task</div>
          <input
            className="ml-auto mr-5"
            checked={formVal["IsVerificationTask"]}
            type="checkbox"
            onChange={(e) =>
              onFieldChange(
                !formVal["IsVerificationTask"],
                "IsVerificationTask"
              )
            }
          />
        </div>
        <div className="addTaskSubHeadings mt-4">Approval Level</div>
        <input
          className="addTaskInputs w-100"
          value={formVal["ApprovalLevels"]}
          type="number"
          min="1"
          max={getField("Is Approval Task").MaxValue}
          onChange={(e) => onFieldChange(e.target.value, "ApprovalLevels")}
        />
        {formVal["ApprovalLevels"] > 1 && (
          <>
            <div className="addTaskSubHeadings mt-4">Level 2 Approvers</div>
            <Select
              placeholder="Level 2 Approvers"
              value={formVal["Level_2"]}
              isMulti={true}
              onChange={(options) => onFieldChange(options, "Level_2")}
              options={createOptions(
                formVal["ServiceArea"] &&
                  formData["Performers"][formVal["ServiceArea"]] &&
                  formData["Performers"][formVal["ServiceArea"]]
              )}
            />
          </>
        )}
        {formVal["ApprovalLevels"] > 2 && (
          <>
            <div className="addTaskSubHeadings mt-4">Level 3 Approvers</div>
            <Select
              placeholder="Level 2 Approvers"
              value={formVal["Level_3"]}
              isMulti={true}
              onChange={(options) => onFieldChange(options, "Level_3")}
              options={createOptions(
                formVal["ServiceArea"] &&
                  formData["Performers"][formVal["ServiceArea"]] &&
                  formData["Performers"][formVal["ServiceArea"]]
              )}
            />
          </>
        )}
        <div className="d-flex mt-4 align-items-center">
          <div className="addTaskSubHeadings">Schedule Task</div>
          <input
            className="ml-auto mr-5"
            checked={formVal["IsSheduledTask"]}
            type="checkbox"
            onChange={(e) =>
              onFieldChange(!formVal["IsSheduledTask"], "IsSheduledTask")
            }
          />
        </div>
        {formVal.IsSheduledTask && (
          <>
            <div className="addTaskSubHeadings mt-4">Task Schedule</div>
            <select
              className="modalDropdown w-100"
              value={formVal["Schedules"]}
              onChange={(e) => {
                resetSchedule(e.target.value, "Schedules");
              }}
            >
              {formData.Schedules.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
            <select
              className="modalDropdown mt-4 w-100"
              value={formVal["Schedule"]}
              onChange={(e) => onFieldChange(e.target.value, "Schedule")}
            >
              <option></option>
              {formData.SchedulesList[formVal["Schedules"]].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </>
        )}
        <div className="addTaskSubHeadings mt-4">Activity Start</div>
        <DatePicker
          selected={formVal["ActivityStart"]}
          className="addTaskCalendarDatePicker w-336"
          placeholderText="DD MMM YYYY hh:mm aa"
          value={formVal["ActivityStart"]}
          popperPlacement="top"
          minDate={tomorrow}
          showTimeSelect
          dateFormat="dd MMM yyyy hh:mm aa"
          popperModifiers={{
            flip: { behavior: ["top"] },
            preventOverflow: { enabled: false },
            hide: { enabled: false },
          }}
          onChange={(time) => onFieldChange(time, "ActivityStart")}
        />
        <div className="addTaskSubHeadings mt-4">Performers</div>
        <Select
          placeholder="All Performers"
          value={formVal["AllPerformers"]}
          isMulti={true}
          onChange={(options) => onFieldChange(options, "AllPerformers")}
          options={createOptions(
            formVal["ServiceArea"] &&
              formData["Performers"][formVal["ServiceArea"]] &&
              formData["Performers"][formVal["ServiceArea"]]
          )}
        />
      </>
      <div className="text-right">
        <button
          className="btn btn-primary mt-5"
          onClick={handleSubmit}
          disabled={isSaveDisabled()}
        >
          Save
        </button>
      </div>
    </div>
  );
}
