/* eslint-disable no-shadow */
import React, { useState, useRef, useEffect, useMemo } from "react";
import * as _ from "lodash";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import Switch from "@material-ui/core/Switch";

import { Tabs, Tab } from "react-bootstrap";
import Donut from "../../Common/Charts/Donut/Donut";
import WidgetHeader from "../../Common/WidgetHeader/WidgetHeader";
import Button, { ViewAll } from "../../Common/WidgetHeader/Button/Button";
import createHistory from "../../../../../history";
import '../widget.css'
import ReactSlider from "../../../../common/ReactSlider/ReactSlider";

const classNames = require("classnames");

const Compliance = ({
  classValue,
  editMode,
  consolidated,
  firestore,
  selectedZone,
  toggle,
  show = false,
  isLoading,
  order,
}) => {
  const [key, setKey] = useState("compliance");
  const [title, setTitle] = useState("Compliance and Contracts");
  const [subTitle, setSubTitle] = useState("Compliance and Contracts renewal status of managed properties");
  const [serviceInterval, setServiceInterval] = useState([]);
  const [compliance, setCompliance] = useState([]);
  const [isVisible, setIsVisible] = useState(show);
  const sliderCompliance = useRef(null);
  const sliderIntervals = useRef(null);
  const handleChange = () => {
    setIsVisible(!isVisible);
    toggle("Contracts and Services", !isVisible);
  };
  useEffect(() => {
    toggle("Contracts and Services", show);
  }, []);
  useEffect(() => {
    if (consolidated && consolidated.length) {
      // Fetch config like Label for graph
      const { ServiceIntervals } = consolidated[0];
      let condition = [["System", "in", ServiceIntervals]];
      if (selectedZone && selectedZone !== "All") {
        condition = [...condition, ["Zone", "==", selectedZone]];
      }
      firestore
        .get({
          collection: "consolidated",
          doc: consolidated[0].id,
          subcollections: [
            {
              collection: "ServiceIntervals_ED",
              where: condition,
            },
          ],
          storeAs: "ServiceIntervals_ED",
        })
        .then((querySnapshot) => {
          let serviceIntervals = [];

          querySnapshot.forEach((doc) => {
            serviceIntervals = [...serviceIntervals, { ...doc.data() }];
          });
          // console.log("service :", serviceIntervals);
          const groupService = _.chain(serviceIntervals)
            .groupBy("System")
            .map((item) => {
              const Red = item.filter(
                ({ StatusIndicator }) => StatusIndicator === "Red"
              ).length;
              const Amber = item.filter(
                ({ StatusIndicator }) => StatusIndicator === "Amber"
              ).length;
              const Green = item.filter(
                ({ StatusIndicator }) => StatusIndicator === "Green"
              ).length;
              const total = Red + Amber + Green;
              const redRatio = (Red * 100) / total;
              const amberRatio = (Amber * 100) / total;
              const greenRatio = (Green * 100) / total;
              return [
                {
                  name: item[0].System,
                  percent: redRatio,
                  color: "#FF8D8D",
                  status: "Overdue",
                },
                {
                  name: item[0].System,
                  percent: amberRatio,
                  color: "#FFDB1C",
                  status: "Nearing Due",
                },
                {
                  name: item[0].System,
                  percent: greenRatio,
                  color: "#01D09F",
                  status: "No Overdue",
                },
              ];
            })

            .value();
          setServiceInterval(groupService);
        });

      const { Contracts } = consolidated[0];
      let conditionContracts = [["System", "in", Contracts]];
      if (selectedZone && selectedZone !== "All") {
        conditionContracts = [
          ...conditionContracts,
          ["Zone", "==", selectedZone],
        ];
      }
      firestore
        .get({
          collection: "consolidated",
          doc: consolidated[0].id,
          subcollections: [
            {
              collection: "Contracts_ED",
              where: conditionContracts,
            },
          ],
          storeAs: "Contracts_ED",
        })
        .then((querySnapshot) => {
          let contracts = [];

          querySnapshot.forEach((doc) => {
            contracts = [...contracts, { ...doc.data() }];
          });
          // console.log("**********conditionContracts************",conditionContracts);
          // console.log("**********************");
          // console.log("Compliant :", contracts);
          const groupService = _.chain(contracts)
            .groupBy("System")
            .map((item) => {
              const Red = item.filter(
                ({ StatusIndicator }) => StatusIndicator === "Red"
              ).length;
              const Amber = item.filter(
                ({ StatusIndicator }) => StatusIndicator === "Amber"
              ).length;
              const Green = item.filter(
                ({ StatusIndicator }) => StatusIndicator === "Green"
              ).length;
              const total = Red + Amber + Green;

              // console.log('Red :',Red)
              // console.log('Amber :',Amber)
              // console.log('Green :',Green)

              // console.log('total :',total)

              const redRatio = (Red * 100) / total;
              const amberRatio = (Amber * 100) / total;
              const greenRatio = (Green * 100) / total;
              return [
                {
                  name: item[0].System,
                  percent: redRatio || "",
                  color: "#FF8D8D",
                  status: "Not Compliant",
                },
                {
                  name: item[0].System,
                  percent: amberRatio || "",
                  color: "#FFDB1C",
                  status: "Contracts Overdue",
                },
                {
                  name: item[0].System,
                  percent: greenRatio || "",
                  color: "#01D09F",
                  status: "No Overdue",
                },
              ];
            })

            .value();
            // console.log(groupService)
          setCompliance(groupService);
        });
    }
  }, [consolidated, selectedZone]);
  const dataCompliance = useMemo(() => compliance, [compliance]);
  const dataServiceInterval = useMemo(() => serviceInterval, [serviceInterval]);
  return (
    <>
      <div className={!editMode ? classValue : "edit-dashed"}>
        <div className={classNames("widget", { "full-h": !editMode })}>
          {editMode && (
            <span style={{ marginLeft: 12, marginTop: 5 }}>#{order}</span>
          )}
          <div className="widget-header-container d-flex flex-row justify-content-between">
            <WidgetHeader
              label={title}
              description={subTitle}
              disabled={false}
            />
            {!editMode ? (
              <ViewAll onClick={() => {
                if (isLoading) {
                    return false;
                  }

                  if (key === "compliance") {
                    createHistory.push(`/executive/widget/compliance`);
                    return false;
                  }
                  createHistory.push(`/executive/widget/services-interval`);
              }}>View All</ViewAll>
              /* <Button
                label="View All"
                click={() => {
                  if (isLoading) {
                    return false;
                  }

                  if (key === "compliance") {
                    createHistory.push(`/executive/widget/compliance`);
                    return false;
                  }
                  createHistory.push(`/executive/widget/services-interval`);
                }}
              /> */
            ) : (
              <Switch
                checked={isVisible}
                color="primary"
                onChange={handleChange}
              />
            )}
          </div>
          {isLoading ? (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <div className="text-secondary ">Loading...</div>
            </div>
          ) : null}
          {!editMode && !isLoading ? (
            <div className={classNames({ disabled: editMode })}>
              <Tabs
                id="compliance"
                activeKey={key}
                onSelect={(k) => {
                  setKey(k);
                  if (k === "compliance") {
                    setTitle("Compliance and Contracts");
                    setSubTitle('Compliance and Contracts renewal status of managed properties')
                  } else {
                    setTitle("Services Intervals");
                    setSubTitle('Routine services nearing or overdue of managed properties')
                  }
                }}
              >
                <Tab eventKey="compliance" title="Compliance and Contracts">
                <div className='mt-0 mt-md-4'>
                  <ReactSlider ref={sliderCompliance}>
                    {dataCompliance.map((service) => (
                      <Donut
                        key={service[0].name}
                        data={service}
                        label={service[0].name}
                        size="small"
                      />
                    ))}
                    <div />
                  </ReactSlider>
                  </div>
                  <div className='legend'>
                      <div className='row justify-content-center mt-md-3 mb-md-2'>
                      <div className="col-12 col-md-2">
                      <div className="d-flex align-items-center justify-content-center w-100 legend-text">
                       <div  className='boxDanger' />
                       Not Compliant
                       </div>
                       </div>
                        <div className="col-12 col-md-3">
                         <div className="d-flex align-items-center justify-content-center w-100 legend-text">
                           <div  className='boxWarning' /> Contracts Overdue</div>
                         </div>
                        <div className="col-12 col-md-2">
                          <div className="d-flex align-items-center justify-content-center w-100 legend-text" >
                           <div  className='boxNormal' /> No Overdue</div>
                        </div>
                      </div>
                    </div>
                  {/* <div className='legend'>
                      <div className='row justify-content-center'>
                      <div className="col-12 col-md-3">
                      <div className="d-flex align-items-center justify-content-center w-100 legend-text">
                       <div  className='boxDanger' />
                       Not Compliant
                       </div>
                       </div>
                        <div className="col-12 col-md-3 mb-1 mb-md-0 mt-1 mt-md-0">
                         <div className="d-flex align-items-center justify-content-center w-100 legend-text">
                           <div  className='boxWarning' /> Contracts Overdue</div>
                         </div>
                        <div className="col-12 mt-md-1 mb-1">
                          <div className="d-flex align-items-center justify-content-center w-100 legend-text" >
                           <div  className='boxNormal' /> No Overdue</div>
                        </div>
                      </div>
                    </div> */}
                    
                </Tab>

                <Tab eventKey="services_intervals" title="Services Intervals">
                <div className='mt-md-4'>
                  <ReactSlider ref={sliderIntervals}>
                    {dataServiceInterval.map((service) => (
                      <Donut
                        key={service[0].name}
                        data={service}
                        label={service[0].name}
                        size="small"
                      />
                    ))}
                    <div />
                  </ReactSlider>
                  </div>
                  <div className='legend'>
                      <div className='row justify-content-center mt-md-3 mb-md-2'>
                      <div className="col-12 col-md-2">
                      <div className="d-flex align-items-center justify-content-center w-100 legend-text">
                       <div  className='boxDanger' />
                       Overdue
                       </div>
                       </div>
                        <div className="col-12 col-md-3">
                         <div className="d-flex align-items-center justify-content-center w-100 legend-text">
                           <div  className='boxWarning' /> Nearing Due</div>
                         </div>
                        <div className="col-12 col-md-2">
                          <div className="d-flex align-items-center justify-content-center w-100 legend-text" >
                           <div  className='boxNormal' /> No Overdue</div>
                        </div>
                      </div>
                    </div>
                  {/* <div className='legend'>
                      <div className='row justify-content-center'>
                      <div className="col-12 col-md-3">
                      <div className="d-flex align-items-center justify-content-center w-100 legend-text">
                       <div  className='boxDanger' />
                       Overdue
                       </div>
                       </div>
                        <div className="col-12 col-md-3 mb-1 mb-md-0 mt-1 mt-md-0">
                         <div className="d-flex align-items-center justify-content-center w-100 legend-text">
                           <div  className='boxWarning' /> Nearing Due</div>
                         </div>
                        <div className="col-12 mt-md-1 mb-1">
                          <div className="d-flex align-items-center justify-content-center w-100 legend-text" >
                           <div  className='boxNormal' /> No Overdue</div>
                        </div>
                      </div>
                    </div> */}
                </Tab>
              </Tabs>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default compose(
  connect(({ dashboard, firestore: { ordered: { consolidated } } }) => ({
    consolidated,
    selectedZone: dashboard.selectedZone,
  })),
  firestoreConnect()
)(Compliance);
