import React from "react";
// import "./ZoneCard.css";
// import image from "../../../../../assets/images/avatar.png";
const classNames = require("classnames");

const ZoneCard = ({ zone, hideUnderline }) => {
  return <li className="list-group-item">{zone}</li>;
};

export default ZoneCard;

/* <div
      className={classNames("d-flex")}
      style={!hideUnderline ? { borderBottom: "solid 1px #eaedf3" } : null}
    >
      <div className="">
        <p className="p-2" style={{ fontFamily: "Inter", fontSize: "14px" }}>
          {zone}
        </p>
      </div>
    </div> */
