import React, { forwardRef, useLayoutEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import DatePicker from 'react-datepicker';

export const IntervalModal = (props) => {
  const { show, onHide, contractMaster, Category, action, interval, onSubmitData } = props;
  const [files, setFiles] = useState([]);
  const [data, setData] = useState({});
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState(false);

  useLayoutEffect(() => {
    setError(false);
    setFiles([]);
    setData({
      CurrentServiceNotes: '' || data.CurrentServiceNotes,
      LastServicedDate: null || data.LastServicedDate,
      LastServicedUnits: '' || data.LastServicedUnits,
    });
  }, [interval])

  const isDisabled = () => {
    return Object.keys(data).some(s => !data[s]);
  }

  const setContractData = (type, value) => {
    setData({...data, [`${type}`]: value})
  }

  const setContractFiles = (pickerfiles) => {
    setFiles([...files, ...pickerfiles]);
    setData({...data, files: [...files, ...pickerfiles]});
  }

  const removeFile = (index) => {
    const newFiles = files.filter((f, i) => index !== i);
    setFiles(newFiles);
    setData({...data, files: newFiles});
  }

  const onClickSubmitData = () => {
    if (isDisabled()) {
      setError(true);
      return;
    }
    setError(false);
    setProcessing(true);
    const docId = interval && interval.id;
    onSubmitData(data, action, docId);
    setProcessing(false);
    onHide();
  }

  const onClickCancel = () => {
    setData({});
    onHide();
  }

  return (
    <Modal className="profile service-interval-modal" size="lg" show={show} onHide={onHide}>
      <Modal.Header className="tb-modal-header pb-0" closeButton>
      </Modal.Header>
      <Modal.Body className="tb-modal-body">
        <h6 className="mb-4 font-weight-bold">{`Update ${Category}`}</h6>
        <form>
          <div className="row">
            <div className="col-md-6 border-right">
              <div className="form-group">
                <label className="col-form-label">Title</label>
                <p className="mb-0 text-black-50">{interval.ServiceTitle}</p>
              </div>
              <div className="form-group">
                <label className="col-form-label">System</label>
                <p className="mb-0 text-black-50">{interval.ServiceName}</p>
              </div>
              { interval.IntervalType === 'DATE-UNITS' && interval.CurrentRunningUnit && <div className="form-group">
                <label className="col-form-label">Current Reading</label>
                <p className="mb-0 text-black-50">{interval.CurrentRunningUnit}</p>
              </div> }
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">Service Notes</label>
                <input className={`form-control${error && data.CurrentServiceNotes === '' ? ' has-error' : ''}`}
                  type="text" placeholder="Enter service notes here" onChange={(e) => setContractData('CurrentServiceNotes', e.target.value)}/>
              </div>
              { (interval.IntervalType === 'DATE' || interval.IntervalType === 'DATE-UNITS') && <div className="form-group">
                <label className="col-form-label">Service Date</label>
                <DatePicker className={`${error && data.LastServicedDate === null ? ' has-error' : ''}`} customInput={<DatepickerInput />}
                  selected={data.LastServicedDate} dateFormat="dd MMM yyyy" placeholderText="Choose new service date" peekNextMonth
                  showMonthDropdown showYearDropdown dropdownMode="select" onChange={date => setContractData('LastServicedDate', date)} 
                  minDate={interval.LastServicedDate.toDate()} maxDate={new Date()} />
              </div> }
              { (interval.IntervalType === 'UNITS' || interval.IntervalType === 'DATE-UNITS') && <div className="form-group">
                <label className="col-form-label">New Service Unit Reading</label>
                <MaskedInput className={`${error && data.LastServicedUnits === null ? ' has-error' : ''}`} 
                  mask={s => Array.from(s).map(() => /([0-9]|\.)/i)} onChange={e => setContractData('LastServicedUnits', e.target.value)} 
                  guide={false}  inputMode="decimal" placeholder="Enter new service unit reading" />
              </div> }
            </div>
          </div>
          <hr/>
          <div className="row">
            <div className="col-md-6 border-right">
              <div className="form-group">
                <label className="col-form-label">Comment</label>
                <textarea className="form-control" rows="5" placeholder="Enter your comment here"
                  onChange={(e) => setContractData('comments', e.target.value)}
                ></textarea>
              </div>              
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="col-form-label">Files</label>
                <div className="custom-file">
                  <input type="file" className="custom-file-input" id="addContractFile" 
                    onChange={(e) => setContractFiles(e.target.files)} accept="image/*, application/pdf"/>
                  <label className="custom-file-label" htmlFor="addContractFile">Choose File</label>
                </div>
                <ul className="file-list">
                  {files.map((file, i) => (
                    <li key={`add-file-${i+1}`}>
                      <span className="mr-2"><i className="icon-attachment"></i></span>
                      <a>{file.name}</a>
                      <a className="ml-3" onClick={() => removeFile(i)}><i className="icon-close"></i></a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer className="tb-modal-footer">
        <button className="btn btn-sm btn-danger" onClick={onClickCancel}>Cancel</button>
        <button className="btn btn-sm btn-primary" disabled={isProcessing} onClick={onClickSubmitData}>Update</button>
      </Modal.Footer>
    </Modal>
  )
}

const DatepickerInput = forwardRef((props, ref) => (
  <input type="text" {...props} readOnly />
));