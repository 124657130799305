/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from "react";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
} from "recharts";
import { isNumber } from "lodash";
import * as d3 from "d3";
import "./Donut.css";

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      fontWeight="700"
      fontSize={12}
    >
      {isNumber(percent) ? `${percent.toFixed(0)}%` : null}
    </text>
  );
};

const renderLegendText = (value, entry) => {
  const { payload } = entry;
  return <span style={{ fontSize: 10 }}>{payload.status}</span>;
};

const Donut = ({ data, label, size = "large" }) => {
  const config = {
    small: { width: 230, height: 350, cx: 100, cy: 90, innerRadius: 45 },
    large: { width: 300, height: 350, cx: 130, cy: 150, innerRadius: 55 },
  };
  const _data = useMemo(() => data, [data]);
  return (
    <>
      <div
        className={size === "small" ? "text-center mt-1 mt-md-3 mb-2 label-donut" : "text-center label-donut"}
      >
        {label}
      </div>
      <PieChart
        margin={{ right: 0, left: 0, bottom: 50, top: 10 }}
        className="container "
        width={config[size].width}
        height={config[size].height}
      >
        {/* <Legend
          formatter={renderLegendText}
          layout="horizontal"
          verticalAlign={size === "small" ? "bottom" : "top"}
          align="center"
        /> */}
        <Pie
          data={_data}
          dataKey="percent"
          labelLine={false}
          label={renderCustomizedLabel}
          cx={config[size].cx}
          cy={config[size].cy}
          innerRadius={config[size].innerRadius}
        >
          {data.map((entry, index) => (
            <Cell key={entry.color} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </>
  );
};

export default Donut;
