import React from 'react'
import './DetailsHeader.css'

const DetailsHeader = ({label, description}) => {
    return (
        <div className="details-header">
            <div className="details-label">{label}</div>
            <div className="details-description">{description}</div>            
        </div>
    )
}

export default DetailsHeader