import React from 'react';
import { Modal } from 'react-bootstrap';

import TypeForm from '../TypeForm/TypeForm';

import './AddIncidents.scss';
import Logo from '../../../assets/images/logoOnly.png';

const AddIncidentModal = ({ show, onHide }) => {
	return (
		<Modal
			show={show}
			onHide={onHide}
			className="full-modal"
		>
			<Modal.Header className="h5 m-0 border-0 px-4 font-weight-600 add-incident-header" closeButton>
				<div className="d-flex align-items-center">
					<img className="mr-4" src={Logo} style={{ height: 30 }} />
					<span>Create Ticket</span>
				</div>
			</Modal.Header>
			<Modal.Body className="p-0">
				<TypeForm close={onHide} />
			</Modal.Body>
		</Modal>
	)
}

export default AddIncidentModal
