import React, { Component } from 'react';
import { Collapse } from 'react-bootstrap';
import PercentageInputWidget from '../../common/PercentageInputWidget/PercentageInputWidget';
import NumberInputWidget from '../../common/NumberInputWidget/NumberInputWidget';
import DropdownInputWidget from '../../common/DropdownInputWidget/DropdownInputWidget';
import ReadingsInputWidget from '../../common/ReadingsInputWidget/ReadingsInputWidget';
import TransientInputWidget from '../../common/TransientInputWidget/TransientInputWidget';
import HourReadingWidget from '../../common/HourReadingsWidget/HourReadingWidget';
import TextInputWidget from '../../common/TextInputWidget/TextInputWidget';
import TimeInputWidget from '../../common/TimeWidget/TimeWidget';
import DRLocalHostoryComponent from '../DRLocalHistory/DRLocalHistory';
import * as moment from 'moment';
import './ReadingCard.css'

export class ReadingCard extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      editCard: false,
      values: {},
      isMobile: false,
      isSaveDisabled: false,
      isHistoryOpen: false,
      isHistoryLoading: false,
    };
    this.readingsList = [];
  }

  componentDidMount = () => {
    const { readings } = this.props;
    this.readingsList = readings;
    this.setState({values: readings.reduce((acc, rdng) => {
      if (rdng.UpdateLocked) return acc;
      
      acc[rdng.id] = { id: rdng.id };
      if (rdng.ParamValueRaw || rdng.ParamValueRaw === 0) acc[rdng.id].ParamValueRaw = rdng.ParamValueRaw;
      else acc[rdng.id].ParamValue = rdng.ParamValue;
      
      return acc;
    }, {})});
  }

  componentDidUpdate = (prevProps) => {
    const { readings, activeCard, drHistory } = this.props;
    const { localDrHistory } = this.state;
    if( this.props.moduleName !== prevProps.moduleName){
      this.readingsList = readings;
      this.setState({editCard: false, isSaveDisabled: false})
    }
    if (!this.props.activeCard && prevProps.activeCard) { 
      this.setState({editCard: false}) 
    }
    if (!this.arraysEqual(drHistory, prevProps.drHistory) || (!localDrHistory && drHistory && drHistory.length)) {
      this.setState({localDrHistory: drHistory});
    }
  }

  objectsEqual = (o1, o2) => (typeof o1 === 'object' && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length
      && Object.keys(o1).every(p => this.objectsEqual(o1[p], o2[p]))
      : o1 === o2)

  arraysEqual = (a1 = [], a2 = []) => a1.length === a2.length && a1.every((o, idx) => this.objectsEqual(o, a2[idx]));

  getModuleColor = () => {
    const { readings } = this.props;
    const redReadings = readings.filter(r => r.Indicator && r.Indicator.toLowerCase() === 'red');
    const amberReadings = readings.filter(r => r.Indicator && r.Indicator.toLowerCase() === 'amber');
    const greenReadings = readings.filter(r => r.Indicator && r.Indicator.toLowerCase() === 'green');

    return ( (redReadings && redReadings.length && redReadings[0].Indicator) || 
      (amberReadings && amberReadings.length && amberReadings[0].Indicator) || 
      (greenReadings && greenReadings.length && greenReadings[0].Indicator) ) || 'Green';
  }

  getModuleLastUpdate = () => {
    let { readings } = this.props;
    const readingValues = [...readings].sort((a, b) => new Date(b.LastUpdatedDate.toDate()) - new Date(a.LastUpdatedDate.toDate()));
    let lastUpdateDate = readingValues[0].LastUpdatedDate && readingValues[0].LastUpdatedDate.toDate();
    const period = moment(new Date()).diff(moment(lastUpdateDate), 'months');
    if (period < 1) lastUpdateDate = moment(lastUpdateDate).fromNow();
    else lastUpdateDate = moment(lastUpdateDate).format('D MMM YYYY');
    return lastUpdateDate;
  }

  toggleCollapse = () => {
    const { moduleName, onClickCollapse } = this.props;
    onClickCollapse('readings', moduleName);
  }

  updateValues = (update) => {
    const { values } = this.state;
    this.setState({ values: { ...values, [`${update.id}`]: update } });
  }

  disableSaveButton = (disableButton, id) =>{
    if(id){
      const arr = this.readingsList.map(r => {
        let newItem = []
        if(r.id === id){
          newItem =  Object.assign({}, r, { isSaveDisabled: r.id === id && disableButton });
        } else{
          newItem = Object.assign({}, r, { });
        }
        return newItem;
      })
      this.readingsList = arr;
    }
    const invalidCount = this.readingsList.filter(r=>r.isSaveDisabled === true).length
    this.setState({isSaveDisabled: invalidCount!==0 ? true : false})
  }

  save = () => {
    const { onUpdate, readingCard } = this.props;
    const { values } = this.state;
    this.setState({editCard: false, isSaveDisabled: false});
    const data = Object.keys(values).map(docId => {
      const doc = { id: docId };
      if (values[docId].ParamValueRaw || values[docId].ParamValueRaw === 0) {
        doc.ParamValueRaw = values[docId].ParamValueRaw;
      } else {
        doc.ParamValue = values[docId].ParamValue;
      }
      return doc;
    });
    onUpdate(readingCard.id, data);
  }

  saveTransientSingle = (values) => {
    const { onUpdate, readingCard } = this.props;
    onUpdate(readingCard.id, [values]);
  }

  openHistory = async (reading) => {
    const { getReadingHistory } = this.props;
    this.setState({isHistoryOpen: true, drhReading: reading, isHistoryLoading: true});
    await getReadingHistory(reading.id);
    this.setState({isHistoryLoading: false});
  }

  onSavedrHistory = (rid, doc) => {
    const { savedrHistory } = this.props;
    const { localDrHistory } = this.state;
    const index = localDrHistory.findIndex(h => h.id === doc.id);
    if ( index > -1) {
      let updatedDoc = { ...localDrHistory[index], ...doc, IsEditable: false };
      this.setState({localDrHistory: [ ...localDrHistory.slice(0, index), updatedDoc, ...localDrHistory.slice(index + 1)]});
      savedrHistory(rid, doc);
    }
  }

  render() {
    const { readings, activeCard, isMobile, drHistory, savedrHistory } = this.props;
    const { editCard, isSaveDisabled, isHistoryOpen, isHistoryLoading, drhReading, localDrHistory } = this.state;

    const isAnySingleEdit = readings && readings.some(r => r.IsSingleEdit);

    return ( 
      <div className="reading-card">
        <div className="card">
          <div className={`card-header border-indicator ${this.getModuleColor().toLowerCase()}`} onClick={isMobile ? this.toggleCollapse : null}>
            <h5>{readings[0].ModuleName}</h5>            
            <p className="mb-0 text-muted">
              {this.getModuleLastUpdate()}
              { !isAnySingleEdit && !editCard && <span className="edit-icon float-right ml-auto text-muted d-none d-md-inline-block cursor-pointer" onClick={() => this.setState({editCard: !!!editCard}) }><i className="icon-pencil"></i></span> }
            </p>
            { !isAnySingleEdit && activeCard && !editCard && <span className="float-right ml-auto text-muted d-inline-block d-md-none" onClick={(e) =>{e.stopPropagation(); this.setState({editCard: !!!editCard})} }><i className="icon-pencil"></i></span>}
            { isMobile && <span className={['icon', activeCard ? 'open' : ''].join(' ')}><i className="icon-chevron-down"></i></span> }
          </div>
          <Collapse in={!isMobile || activeCard} >
          <div>
            <div className="card-body">
              {readings.map((r, i) => {
                if (r.updateWidget === 'PERCENTAGE' || r.updateWidget === 'NUMPERCENTAGE') {
                  return (<PercentageInputWidget reading={r} key={i} edit={editCard} onChange={this.updateValues}
                    isMobile={isMobile} isButtonDisabled={this.disableSaveButton} openHistory={() => this.openHistory(r)} />)
                } else if (r.updateWidget === 'NUMERIC') {
                  return (<NumberInputWidget reading={r} key={i} edit={editCard} onChange={this.updateValues}
                    isMobile={isMobile} isButtonDisabled={this.disableSaveButton} openHistory={() => this.openHistory(r)} />)
                } else if (r.updateWidget === 'DROPDOWN') {
                  return (<DropdownInputWidget reading={r} key={i} edit={editCard} onChange={this.updateValues}
                    isMobile={isMobile} openHistory={() => this.openHistory(r)} />)
                } else if (r.updateWidget === 'READINGS') {
                  return (<ReadingsInputWidget reading={r} key={i} edit={editCard} onChange={this.updateValues}
                    isButtonDisabled={this.disableSaveButton} openHistory={() => this.openHistory(r)} />)
                } else if (r.updateWidget === 'TRANSIENT') {
                  return (<TransientInputWidget reading={r} key={i} edit={editCard} onChange={this.updateValues} openHistory={() => this.openHistory(r)}
                    saveTransient={this.saveTransientSingle} isMobile={isMobile} isButtonDisabled={this.disableSaveButton} />)
                } else if (r.updateWidget === 'HOURREADINGS') {
                  return (<HourReadingWidget reading={r} key={i} edit={editCard} onChange={this.updateValues}
                    isMobile={isMobile} isButtonDisabled={this.disableSaveButton} openHistory={() => this.openHistory(r)} />)
                } else if (r.updateWidget === 'TIME') {
                  return (<TimeInputWidget reading={r} key={i} edit={editCard} onChange={this.updateValues}
                    isMobile={isMobile} isButtonDisabled={this.disableSaveButton} openHistory={() => this.openHistory(r)} />)
                } else { 
                  return (<TextInputWidget reading={r} key={i} edit={editCard} onChange={this.updateValues}
                    isMobile={isMobile} openHistory={() => this.openHistory(r)} />)
                }
              })}
              { editCard && <div className="d-flex justify-content-end mt-3">
                <button className="btn btn-sm btn-light mr-2" onClick={() => this.setState({editCard: false, isSaveDisabled: false}) }>Cancel</button>
                <button className="btn btn-sm btn-primary" onClick={this.save}
                disabled={isSaveDisabled}>Save</button>
              </div> }
            </div>
          </div>
          </Collapse>
        </div>
        <DRLocalHostoryComponent drhShow={isHistoryOpen} hidedrHistory={() => this.setState({isHistoryOpen: false})} 
          {...{drhReading, drHistory: localDrHistory}} drhLoading={isHistoryLoading} savedrHistory={this.onSavedrHistory} />
      </div>
    )
  }
}