import React, { Component, useState, useEffect } from 'react';
import './DeviceInfo.css';

export default class DeviceInfo extends Component {

  onAddDeviceCard = (deviceInfo, id) => {
    const { EquipmentName, ComponentName, onSaveDeviceCard } = this.props
    if (Object.values(deviceInfo) < 1) return false;

    let info = { ...deviceInfo, EquipmentName, ComponentName };
    onSaveDeviceCard(info, id);
  }

  render() {
    const { infoDict, deviceInfo, limit, deleteInfo, infoDictMap } = this.props;
    return (
      <div id="device-info">
        { (deviceInfo || []).map(info => (
          <DeviceInfoCard key={info.id} { ...{ infoDict, info, deleteInfo, infoDictMap } }
            onSaveDeviceCard={this.onAddDeviceCard} />
        ))}
        { !!(!limit || (deviceInfo || []).length < limit) && <AddDeviceInfoCard { ...{ infoDict, infoDictMap } }
          onSaveDeviceCard={this.onAddDeviceCard} /> }
      </div>
    )
  }
}

const DeviceInfoCard = ({infoDict, info, onSaveDeviceCard, deleteInfo, infoDictMap}) => {
  const [edit, toggleEditMode] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState({ });
  let newinfo = { };

  useEffect(() => {
    let d = { };
    infoDict.map(key => d[key] = info[key]);
    setDeviceInfo(d);
  }, [info])

  const onChangeValue = (key, value) => {
    newinfo = {...newinfo, [`${key}`]: value};
  }

  const onClickSave = () => {
    onSaveDeviceCard(newinfo, info.id);
    toggleEditMode(false);
  }

  const onClickEdit = () => {
    newinfo = deviceInfo;
    toggleEditMode(true);
  }

  const findInType = (item) => {
    const type = ( infoDictMap === undefined || infoDictMap === null) ? "text" : (infoDictMap[item] === undefined ? "text" : infoDictMap[item]);
    return type;
  };

  const getWarrantyText = (item) => {
    var warrantyStatus = "";
    if (deviceInfo[item] !== undefined) {
      var warrantyDate = new Date(deviceInfo[item]);
      var today = new Date();
      if (warrantyDate >= today) {
        warrantyStatus = <span className="helper mr-2" style={{color:'green'}}>Under Warranty</span>
      } else if (warrantyDate < today) {
        warrantyStatus = <span className="helper mr-2" style={{color:'red'}}>Warranty Expired</span>
      }
    }
    return warrantyStatus;
  };
  const checkIfHasWarranty = (infoDict) => {
    var res = "";
    infoDict.map((dict, i) => {
      if(findInType(dict) === "date" && dict.toUpperCase().includes('WARRANTY')) {
        res = getWarrantyText(dict);
      }
    });
    return res;
  };
  return (
    <div className="device">
      {
          <>{checkIfHasWarranty(infoDict)}<a className="edit-button" onClick={() => onClickEdit()}><i className="icon-pencil"></i></a>{'\n'}</>
      }
      <div className="row">
        { (infoDict || []).map((dict, i) => (
          <div key={ 'dict_' + Math.random() } className="col-6 info-group">
            <p className="text-truncate mb-0">
              <span className="helper-icon mr-2"><i className="icon-tag"></i></span>
              <span className="helper mr-2">{dict}:</span>
            </p>
            
            { !edit ? 
              (<span>{info[dict]}</span>) :

              (findInType(dict) === "text" ?
              <input type="text" defaultValue={deviceInfo[dict]}  onChange={(e) => onChangeValue(dict, e.target.value)}/>  
                
              : findInType(dict) === "date" &&
              <input type="date" defaultValue={deviceInfo[dict]}  onChange={(e) => onChangeValue(dict, e.target.value)}/> 
              )
              
            }
          </div>
        ))}
      </div>
      { edit && <div className="d-flex justify-content-end mt-2 pt-1">
        <button className="btn btn-sm btn-danger mr-auto" onClick={() => deleteInfo(info.id)}>Delete</button>
        <button className="btn btn-sm btn-light mr-2" onClick={() => toggleEditMode(false)}>Cancel</button>
        <button className="btn btn-sm btn-primary" disabled={Object.values(deviceInfo).length < 1} onClick={onClickSave}>Save</button>
      </div> }
    </div>
  )
}

const AddDeviceInfoCard = ({infoDict, infoDictMap, onSaveDeviceCard}) => {
  let newInfo = {};
  
  const [addNew, toggleAdd] = useState(false);

  const onChangeValue = (key, value) => {
    newInfo = {...newInfo, [`${key}`]: value};
  }
  
  const onClickSave = () => {
    onSaveDeviceCard(newInfo)
    toggleAdd(false);
  }
  const findInType = (item) => {
    const type = ( infoDictMap === undefined || infoDictMap === null) ? "text" : (infoDictMap[item] === undefined ? "text" : infoDictMap[item]);
    return type;
  };

  return (
    <div className="mt-3">
      { !addNew ?
      <div className="d-flex flex-column align-items-center">
        <button className="btn btn-sm btn-primary" onClick={() => toggleAdd(true)}>
          <small><i className="icon-plus"></i> Add</small>
        </button>
        <p className="my-0"><small>Add new Info</small></p>
      </div> :
      <div className="device">
        <div className="row">
          { (infoDict || []).map((dict, i) => (
            <div key={ 'dict_' + Math.random() } className="col-6 info-group">
              <p className="text-truncate mb-0">
                <span className="helper-icon mr-2"><i className="icon-tag"></i></span>
                <span className="helper mr-2">{dict}: </span>
              </p>
              {findInType(dict) === "text" &&
               <input type="text" onChange={(e) => onChangeValue(dict, e.target.value)}/> 
              }  
              {findInType(dict) === "date" &&
               <input type="date" onChange={(e) => onChangeValue(dict, e.target.value)}/> 
              }  
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end mt-2">
          <button className="btn btn-sm btn-light mr-2" onClick={() => toggleAdd(false)}>Cancel</button>
          <button className="btn btn-sm btn-primary" onClick={onClickSave}>Save</button>
        </div>
      </div> }
    </div>
  )
}
