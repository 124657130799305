import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import createHistory from "../../../history";
import Loading from "../../common/Loading/Loading";
import formatAMPM from "../../common/FormatTime";
import "./StaffActivitites.css";
import "../../../App.css";

const moment = require("moment");

class TaskDeatailPage extends Component {
  constructor() {
    super();
    this.load = false;
  }

  componentWillMount() {
    const { user, currentApartment } = this.props;
    if (user && currentApartment) this.fetchData(currentApartment);
  }

  componentDidUpdate = (prevProps) => {
    const { user, currentApartment } = this.props;
    if (user.length !== 0) {
      if (prevProps.user.length !== 0) {
        if (
          currentApartment &&
          currentApartment !== prevProps.currentApartment
        ) {
          this.fetchData(currentApartment);
        }
      } else if (currentApartment) {
        this.fetchData(currentApartment);
      }
    }
  };

  fetchData = (currentApartment) => {
    const { firestore } = this.props;
    if (this.unsubscribeTaskDetails) {
      this.unsubscribeTaskDetails();
    }
    this.unsubscribeTaskDetails = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "TaskDetails" }],
      storeAs: "TaskDetails",
    });
  };

  updateValue = (item, value) => {
    const { user, firestore, currentApartment } = this.props;
    firestore
      .collection("apartments")
      .doc(currentApartment)
      .collection("TaskDetails")
      .doc(item.id)
      .update({
        TaskValue: value,
        TaskLastUpdatedBy: user[0].DisplayName,
        LastUpdatedTime: new Date(),
      });
  };

  render() {
    const {
      TaskDetails,
      location,
      systemUpdateModule,
      ApartmentAccessInfo,
      currentApartment,
      user
    } = this.props;

    let accessInfo = null;
    if(ApartmentAccessInfo.length){
      const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
      if(selectedApartment){
        accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
      }
    }

    let name;
    if (systemUpdateModule.name) {
      name = systemUpdateModule.name;
    } else {
      name = location.state.name;
    }
    if (!TaskDetails || !accessInfo) {
      return <Loading showLogo="false" />;
    }

    if (systemUpdateModule.name === "NA") {
      return <Loading showLogo="false" />;
    }

    const data = [];
    TaskDetails.map((tas) => {
      if (tas.CardType === "Tasks" && tas.ServiceArea === name) {
        if (!data.includes(tas.Module)) {
          data.push(tas.Module);
        }
      }
    });

    return (
      <div className="d-flex flex-column col-12 align-items-center p-0">
        <div className="d-flex align-items-center nav_container">
          <button
            type="submit"
            className="buttonrmstyle"
            onClick={() => createHistory.goBack()}
          >
            <img
              className="back-arrow"
              alt="back"
              src={require("../../../assets/images/left-arrow.png")}
            />
          </button>
          <span className="heading">{name}</span>
        </div>
        <div className="d-flex col-11 flex-wrap mb-5 p-0">
          {TaskDetails.map(
            (detail) =>
              detail.ServiceArea === name &&
              detail.CardType === "Attendance" && (
                <div className="DetailComponentCard">
                  <div className="ComponentHead">{detail.Module}</div>
                  <div className="StaffMapContainerTasks">
                    {detail.Category.map(
                      (value) =>
                        detail[value] && (
                          <div key={Math.random()} className="StaffSingleItem">
                            <div className="StaffCategory">{value}</div>
                            <div className="d-flex flex-row ">
                              <div className="StaffActive">
                                {detail[value].Available}
                              </div>
                              <div className="StaffPassive mt-2">{`/${detail[value].Total}`}</div>
                            </div>
                          </div>
                        )
                    )}
                  </div>
                </div>
              )
          )}
          {data.map((mod) => (
            <div className="DetailComponentCard">
              <div className="ComponentHead">{mod}</div>
              <div className="d-flex flex-wrap mt-4 justify-content-around">
                {TaskDetails.map(
                  (detail) =>
                    detail.ServiceArea === name &&
                    detail.CardType === "Tasks" &&
                    detail.Module === mod && (
                      <div
                        className="col-sm-12 col-md-5 mb-1 justify-content-center d-flex flex-column"
                        style={{ minHeight: 45 }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className="taskDetailTaskName mr-2"
                            style={{
                              color:
                                detail.Indicator === "Amber"
                                  ? "#F5A623"
                                  : detail.Indicator,
                            }}
                          >
                            {detail.TaskName}
                          </div>
                          {!detail.TaskValue ? (
                            <button
                              type="submit"
                              className="circleCheckBox p-0"
                              onClick={() => this.updateValue(detail, true)}
                              style={{
                                border: `2px solid ${
                                  detail.Indicator === "Amber"
                                    ? "#F5A623"
                                    : detail.Indicator
                                }`,
                              }}
                            />
                          ) : (
                            <button
                              type="submit"
                              className="buttonrmstyle p-0"
                              onClick={() => this.updateValue(detail, false)}
                              disabled={
                                !(
                                  accessInfo.is_manager ||
                                  accessInfo.is_mc_member
                                )
                              }
                            >
                              <img
                                alt="tick"
                                src={require("../../../assets/images/green-tick.png")}
                                className="greenTick"
                              />
                            </button>
                          )}
                        </div>
                        <div style={{ minHeight: 15 }} className="d-flex">
                          {detail.TaskLastUpdatedBy && (
                            <div className="taskDetailTaskNameSub mr-4">
                              {`By ${detail.TaskLastUpdatedBy} on ${moment(
                                detail.LastUpdatedTime.toDate()
                              ).format("DD MMM")} ${formatAMPM(
                                detail.LastUpdatedTime.toDate()
                              )}`}
                            </div>
                          )}
                        </div>
                      </div>
                    )
                )}
                <div className="col-sm-12 col-md-5 mb-1 justify-content-center d-flex flex-column" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

TaskDeatailPage.defaultProps = {
  user: [{ Email: "" }],
  // UserAccessInfo: [{ is_manager: false, is_mc_member: false }],
};

const mapStateToProps = (state) => ({
  user: state.firestore.ordered.Users,
  TaskDetails: state.firestore.ordered.TaskDetails,
  systemUpdateModule: state.systemUpdateModule,
  // UserAccessInfo: state.firestore.ordered.UserAccessInfo,
  currentApartment: state.apartment.currentApartment,
  ApartmentAccessInfo: state.apartment.ApartmentAccessInfo
});

export default compose(
  connect(mapStateToProps),
  firestoreConnect(),
  firebaseConnect(),
  withRouter
)(TaskDeatailPage);
