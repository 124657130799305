import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';


function IssueCommentModal({ show, onHide, issue, addComment }) {

    const [comment, setComment] = useState('');
    useEffect(() => {
        if(issue && issue.Comment) {
            setComment(issue.Comment)
        }
    }, [issue])

    return (
        <Modal centered show={show} size="sm" onHide={()=> {setComment(''); onHide()}}>
            <Modal.Header className="tb-modal-header pt-2 pb-0" closeButton></Modal.Header>
            <Modal.Body className="px-lg-4 px-3 py-3">
                {
                    issue && issue.IsOpen ?
                    <>
                        <input
                            className="form-control font-12"
                            placeholder="Comment"
                            value={comment || ''}
                            onChange={(e) => setComment(e.target.value)}
                            // onBlur={(e) => addComment(e.target.value)}
                            // onKeyPress={(e) => { if(e.which === 13 ) addComment(e.target.value) }}
                        />
                        <button className="btn btn-sm btn-primary mt-3 ml-auto float-right"
                            disabled={!comment}
                            onClick={(e) => addComment(comment)}>Submit
                        </button>
                    </>
                    : <p className="text-secondry small m-0">{comment ? comment : 'No comment'}</p>
                }
            </Modal.Body>
        </Modal>
    )
}

export default IssueCommentModal
