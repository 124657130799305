import React from 'react'

function Question1({ id, next, className, onAnswer }) {

  const onChange = (e) => {
    onAnswer(e.target.value)
  }

  return (
    <div id={id} data-key="IncidentType" data-required="true" className={["p-3 h-100 d-flex flex-column justify-content-center q-block", className].join(' ')}
    style={{ transform: ' translateY(0)',  opacity: 1}}>
      <p className="mb-3 h5 question">Ticket Type ?</p>
      <div className="d-flex tf-radio-group">
        <div className="tf-radio-btn">
          <input id="residents" type="radio" value="Residents" name="incident_happened" onChange={onChange} />
          <label htmlFor="residents">Residents</label>
        </div>
        <div className="tf-radio-btn">
          <input id="common-area" type="radio" value="System" name="incident_happened" onChange={onChange} />
          <label htmlFor="common-area">Community</label>
        </div>
      </div>
    </div>
  )
}

export default Question1;
