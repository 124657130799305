import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import Switch from "react-switch";
import createHistory from "../../../history";
import './SystemStatusCard.css'
import lastUpdatedTime from '../../common/Timeago';

class SystemStatusCard extends Component {
  constructor() {
    super()
    this.state = {
      checked: false
    }
  }

  setStyle = () => {

  }

  getIndicator = (color) => {
    switch (color) {
      case "Green":
        return <div className='sysGreen' />;
      case "Red":
        return <div className='sysRed' />;
      case "Amber":
        return <div className='sysAmber' />;
      default:
        break;
    }
  };

  handleChange = (event) => {
    const { firestore, user, data, currentApartment } = this.props;
    const { checked } = this.state
    if (!checked) {
      // update here
      firestore.update({
        collection: 'apartments',
        doc: currentApartment,
        subcollections: [
          {
            collection: 'SystemDetails',
            doc: data.id,
            subcollections: [
              {
                collection: 'system_details_internal',
                doc: data.id
              }
            ]
          }
        ]
      },
        {
          _InspectedBy: user[0].DisplayName,
          _InspectedTime: new Date(),
        })
    }
    this.setState({ checked: true });
    event.stopPropagation();
    event.preventDefault();
  }

  render() {
    const { data, setModuleName } = this.props
    console.log('datadata', data)
    const { checked } = this.state
    return (
      <button type="submit" className="card d-flex flex-column justify-content-around ml-3 mr-3"
        onClick={() => {
          setModuleName(data.Name);
          createHistory.push('/system/update', { name: data.Name });
        }}>
        <div className="sysName">{data.Name}</div>
        <button type="submit" className="buttonrmstyle" style={{ position: 'absolute', right: 10, top: 10 }} onClick={this.handleChange}>
          <Switch
            checked={checked}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#4A90E2"
            width={30}
            height={15}
            id={data.Name}
            draggable={false}
            onChange={() => { }}
          />
        </button>
        <div className="d-flex align-items-center" style={{ marginBottom: 5 }}>
          {this.getIndicator(data.Indicator)}
          <div className="sysStatus ml-2">{data.Status}</div>
        </div>
        <div className="d-flex align-items-center">
          <div className="sysBottom mr-2">{lastUpdatedTime(data.LastUpdatedTime)}</div>
          <div className="sysBottom">{data.LastUpdatedBy}</div>
        </div>
      </button>
    )
  }
}

const mapStateToProps = state => ({
  user: state.firestore.ordered.Users,
  currentApartment: state.apartment.currentApartment,
});

const mapDispatchToProps = dispatch => ({
  setModuleName: (name) => dispatch({
    type: "SYSTEM_UPDATE",
    name
  })
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect()
)(SystemStatusCard);
