import React, { Component } from 'react';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import { Decimal } from 'decimal.js';

export class ReadingsInputWidget extends Component {
  state = { value: '', isError: false };

  componentDidMount() {
    const { reading } = this.props;
    this.setState({value: reading.ParamValueRaw || reading.ParamValue});
  }
  
  componentDidUpdate(prevProps) {
    const { reading, edit } = this.props;
    const { reading: prevReading, edit: prevEdit } = prevProps;
    if ((reading && (reading.id !== prevReading.id)) || (edit && (prevEdit !== edit))) {
      this.setState({value: reading.ParamValueRaw || reading.ParamValue, isError: false});
    }
  }

  convertToString = (val) => {
    const { reading } = this.props;
    if (val === null || val === undefined) return '';
    return parseFloat((+val).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: reading.DecimalPrecision || 1,
      style: 'decimal'
    }).replace(/,/g, ''));
  }

  getParamName(name) {
    const match = name.match(/(.+)\s(\(.+\))/);
    if (match && match.length) return match[1];
    return name;
  }

  getHelperText(name) {
    const match = name.match(/(.+)\s(\(.+\))/);
    if (match && match.length) return match[2];
    return '';
  }

  increment = () => {
    const { reading, onChange, isButtonDisabled } = this.props
    const { value, isError } = this.state;
    let min = reading.MinValue ? reading.MinValue : 0;
    let max = reading.MaxValue ? reading.MaxValue : 999999;
    if (reading.prevDayReading) {
      min = new Decimal(reading.prevDayReading).toNumber();
    }
    if (reading.MaxValueRange) {
      max = new Decimal(reading.ParamValueRaw).add(new Decimal(reading.MaxValueRange)).toNumber();
    }
    if (isNaN(value)) {
      return true;
    }

    const num = new Decimal(value); 
    const incrementor = new Decimal(reading.incrementBy || 1);
    const val = num.plus(incrementor).toNumber();
    if (val >= min && val <= max) {
      this.setState({value: this.convertToString(val), isError: false});
      isButtonDisabled(false, reading.id);
      onChange({ id: reading.id, [`${reading.hasOwnProperty('ParamValueRaw') ? `ParamValueRaw` : `ParamValue`}`]: this.convertToString(val) }, isError);
    } else if(val < min) {
      this.setState({value: this.convertToString(val), isError: true});
      isButtonDisabled(true, reading.id);
    }
  }

  decrement = () => {
    const { reading, onChange, isButtonDisabled } = this.props
    const { value, isError } = this.state;
    let min = reading.MinValue ? reading.MinValue : 0;
    let max = reading.MaxValue ? reading.MaxValue : 999999;
    if (reading.prevDayReading) {
      min = new Decimal(reading.prevDayReading).toNumber();
    }
    if (reading.MaxValueRange) {
      max = new Decimal(reading.ParamValueRaw).add(new Decimal(reading.MaxValueRange)).toNumber();
    }
    if (isNaN(value)) {
      return true;
    }
    
    const num = new Decimal(value); 
    const incrementor = new Decimal(reading.incrementBy || 1);
    const val = num.sub(incrementor).toNumber();
    if (val <= max && val >= min) {
      this.setState({value: this.convertToString(val), isError: false});
      isButtonDisabled(false, reading.id);
      onChange({ id: reading.id, [`${reading.hasOwnProperty('ParamValueRaw') ? `ParamValueRaw` : `ParamValue`}`]: this.convertToString(val) }, isError);
    } else if (val > max) {
      this.setState({value: this.convertToString(val), isError: true});
      isButtonDisabled(true, reading.id);
    }
  }

  onChange = (e) => {
    if(e.target.value.slice(-1) === '.' && (e.target.value.match(/\./g)).length > 1) {
      this.setState({value:e.target.value.substring(0, e.target.value.length - 1)})
    }
  }

  editValue = (val) => {
    const {reading, isButtonDisabled, onChange} = this.props;
    let min = reading.MinValue ? reading.MinValue : 0;
    let max = reading.MaxValue ? reading.MaxValue : 999999;
    if (reading.prevDayReading) {
      min = new Decimal(reading.prevDayReading).toNumber();
    }
    if (reading.MaxValueRange) {
      max = new Decimal(reading.ParamValueRaw).add(new Decimal(reading.MaxValueRange)).toNumber();
    }

    if ((val === null || val === '' || val > max || val < min || isNaN(val)) && val !== 0) {
      this.setState({ isError: true, value: val });
      isButtonDisabled(true, reading.id);
    } else {
      this.setState({ isError: false, value: val });
      isButtonDisabled(false, reading.id);
      onChange({ id: reading.id, [`${reading.hasOwnProperty('ParamValueRaw') ? `ParamValueRaw` : `ParamValue`}`]: this.convertToString(val) }, false);
    }
  }

  getLastUpdate = (value) => {
    let lastUpdateDate = new Date(value.toDate());
    const period = moment(new Date()).diff(moment(lastUpdateDate), 'months');
    if (period < 1) lastUpdateDate = moment(lastUpdateDate).fromNow();
    else lastUpdateDate = moment(lastUpdateDate).format('D MMM YYYY');
    return lastUpdateDate;
  }

  render() {
    const { reading, edit, openHistory } = this.props;
    const { value, isError } = this.state;

    return (
      <>
      <div className={['d-flex', edit ? 'mb-2': ''].join(' ')}>
        <span className={['badge-indicator', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')} />
        <span style={{color:'black'}}>{ !edit ? this.getParamName(reading.ParamName) : reading.ParamNameUpd }</span>
        { reading.HasLocalHistory && <a onClick={openHistory} className="text-muted"><i className="icon-history ml-1" /></a> }
        {/* <span style={{fontSize:11, marginLeft:7, marginTop:3}}>{ this.getLastUpdate(reading.LastUpdatedDate) }</span> */}
        { !edit || reading.UpdateLocked ? 
          <span className={['float-right', 'mb-0', 'ml-auto', 'text-indicator', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')}>
            { reading.ParamUnitLeftAlign && reading.ParamUnit } { reading.ParamValue } { !reading.ParamUnitLeftAlign && reading.ParamUnit }
            { reading.UpdateLocked && <a className="text-muted"><i className="icon-lock ml-3" /></a> }
          </span> :
          <span className="ml-auto edit-body">
            <span className="helper" onClick={this.decrement}>-</span>
            <MaskedInput className={['text-indicator', 'border-indicatorr', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')}
              mask={s => Array.from(s).map(() =>/((-)|[0-9]|\.)/i)} guide={false} inputMode="decimal" value={value} 
              size={(value.length > 6) ? (value.length + 1 > 12 ? 12 : value.length + 1) : 7} 
              onKeyUp={e => this.editValue(e.target.value)} style={{borderBottomColor: isError && 'red'}} />
            <span className="helper" onClick={this.increment}>+</span>
          </span>
        }
      </div>
      { !edit && <div className="mb-2 sub">
      <span className="text-dark mb-0"><small>{this.getHelperText(reading.ParamName)}</small></span>
        <div className="d-flex justify-content-between align-items-center">
          <span className="mb-0 mr-2 d-flex flex-column">
            <span className="text-right">{reading.prevDayReading}</span>
            <small>{reading.prevDayReadingTime && moment(reading.prevDayReadingTime.toDate()).format('D MMM')}</small>
          </span>
          <span className="icon-converter">
            <i className="path1" />
            <i className="path2" />
          </span>
          <span className="mb-0 ml-2 d-flex flex-column">
            <span className="text-dark text-right">{reading.ParamValueRaw}</span>
            <small>{reading.LastUpdatedDate && moment(reading.LastUpdatedDate.toDate()).format('D MMM')}</small>
          </span>
        </div>
      </div> }
      </>
    )
  }
}

export default ReadingsInputWidget
