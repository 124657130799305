import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Accordion, Dropdown, Card } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faComment } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment';

import Avatar from '../common/Avatar/Avatar';
import Constant from '../Incidents/constant';
import TaskImages from './TaskImages';

import FileIcon from '../../assets/images/file_blank_outline.svg';
import HomeIcon from '../../assets/images/home_alt_outline.svg';
import './TicketDetails.scss';
import PhotoCarousel from '../PhotoCarousel/PhotoCarousel';
import IssueCommentModal from './IssueCommentModal';

const Comment = ({ comment }) => (
    <div className="comment">
        <div className="mb-2">
            <Avatar name={comment.CommentBy} size={30} />
            <span className="font-weight-600 ml-2">{comment.CommentBy}</span>
        </div>
        <p className="font-13 mb-1">{comment.Comment}</p>
        <span className="small text-secondary">
            {moment(new Date(comment.CommentDate.toDate())).format('DD MMMM YYYY')} &nbsp;
            ({moment(new Date(comment.CommentDate.toDate())).fromNow()})
        </span>
    </div>
);

class TicketDetails extends Component {

    top = React.createRef();

    fileUpload = React.createRef();

    state = {
        comment: '',
        historyLength: 3,
        fileUrls: [],
        carouselImages: [],
        activeCarouselIndex: 0,
        showPhotoCrousel: false,
        showIssueComment: false,
        selectedIssue: null,
    }

    componentDidMount() {
        const { user, ticket, isPrivilgedUser, currentApartment } = this.props;
        
        document.getElementById('main-block').scrollTo(0,0)
        this.fetchData(currentApartment, ticket.id);
        this.fetchOnHoldReasons(currentApartment);
        if(isPrivilgedUser) {
            this.fetchCategories(currentApartment);
        }
        if(ticket && ticket.HasSubTask) {
            this.fetchSubTasks(currentApartment, ticket.id)
        }
    }

    componentWillUnmount(){
        if(this.unsubscribeComment) this.unsubscribeComment();
        if(this.unsubscribeFiles) this.unsubscribeFiles();
        if(this.unsubscribeHistory) this.unsubscribeHistory();
        if(this.unsubscribeSubtasks) this.unsubscribeSubtasks();
    }

    async fetchSubTasks(currentApartment, ticket) {
        const { firestore } = this.props;
        this.unsubscribeSubtasks = firestore.onSnapshot({
            collection: 'apartments',
            doc: currentApartment,
            subcollections: [{
                collection: 'IncidentReports',
                doc: ticket,
                subcollections: [{
                    collection: 'TicketSubTasks'
                },]
            }],
            // orderBy: ['CommentDate', 'desc'],
            storeAs: `ticketSubTasks`
        });
    }

    addIssueComment = (comment) => {
        const { user, firestore, ticket, currentApartment } = this.props;
        const { selectedIssue } = this.state
        const updateDoc = {
            Comment: comment
        }
        if(comment) {
            firestore.update({
                collection: 'apartments',
                doc: currentApartment,
                subcollections: [{
                    collection: 'IncidentReports',
                    doc: ticket.id,
                    subcollections: [{
                        collection: 'TicketSubTasks',
                        doc: selectedIssue.id,
                    },]
                }],
            }, updateDoc);
            this.setState({ showIssueComment: false })
        }

    }

    async componentDidUpdate(prevProps) {
        const { ticket, currentApartment } = this.props;
        if(this.props.ticketFiles !== prevProps.ticketFiles) {
            const urls = await Promise.all((this.props.ticketFiles).map(f => this.getPhotoURL(`${f.FilePath  }/${f.FileName}`)));
            this.setState({ fileUrls: urls })
        }
        if(currentApartment !== prevProps.currentApartment && ticket && ticket.id){
            this.fetchData(currentApartment, ticket.id);
        }
    }

    getPhotoURL = async path => {
        const { firebase } = this.props;
        const storage = firebase.storage();
        const url = await storage.ref().child(path).getDownloadURL();
        return url;
    }

    fetchData(currentApartment, ticket) {
        const { firestore } = this.props;
        
        this.unsubscribeComment = firestore.onSnapshot({
            collection: 'apartments',
            doc: currentApartment,
            subcollections: [{
                collection: 'IncidentReports',
                doc: ticket,
                subcollections: [{
                    collection: 'Comments'
                },]
            }],
            orderBy: ['CommentDate', 'desc'],
            storeAs: `ticketComments`
        });

        this.unsubscribeFiles = firestore.onSnapshot({
            collection: 'apartments',
            doc: currentApartment,
            subcollections: [{
                collection: 'IncidentReports',
                doc: ticket,
                subcollections: [{
                    collection: 'Files'
                },]
            }],
            storeAs: `ticketFiles`
        });

        this.unsubscribeHistory = firestore.onSnapshot({
            collection: 'apartments',
            doc: currentApartment,
            subcollections: [{
                collection: 'IncidentReports',
                doc: ticket,
                subcollections: [{
                    collection: 'TicketActions'
                },]
            }],
            orderBy: ['UpdatedTime', 'desc'],
            storeAs: `ticketHistory`
        });
    }

    fetchCategories(currentApartment) {
        // const { firestore, residentServices } = this.props;
        // if(!residentServices) {
        //     firestore.onSnapshot({
        //         collection: 'apartments',
        //         doc: DefaultApartment,
        //         subcollections: [{
        //             collection: 'ResidentServices',
        //             doc: '_resident_services',
        //         }],
        //         storeAs: `residentServices`
        //     });
        // }

        
        const { firestore, ticketCategories } = this.props;
        if(!ticketCategories) {
            firestore.onSnapshot({
                collection: 'apartments',
                doc: currentApartment,
                subcollections: [{
                    collection: 'ResidentServices',
                    where: ['Scope', '==', 'Trackbot App'],
                }],
                storeAs: `ticketCategories`
            });
        }
          
    }

    fetchOnHoldReasons(currentApartment){
        const { firestore, residentServices } = this.props;
        if(!residentServices) {
            firestore.onSnapshot({
                collection: 'apartments',
                doc: currentApartment,
                subcollections: [{
                    collection: 'ResidentServices',
                    doc: '_resident_tickets_onhold_reasons',
                }],
                storeAs: `onHoldReasons`
            });
        }
    }

    actionHandler = async (action, reason = '') => {
        const { firestore, user, ticket, isPrivilgedUser, update, currentApartment} = this.props;
        const { DisplayName } = user[0];
        
        const updateDoc = {
            LastUpdatedDate: firestore.Timestamp.fromDate(new Date()),
            LastUpdatedBy: DisplayName,
        };

        if(action === 'Start') {
            updateDoc.Status = 'In Progress';
            if(!ticket.IsAssigned) {
                updateDoc.IsAssigned = true;
                updateDoc.AssignedTo = DisplayName;
                updateDoc.AssignedDate = firestore.Timestamp.fromDate(new Date());
            }
        }
        if(action === 'Hold') {
            updateDoc.Status = 'On Hold';
            updateDoc.OnHoldReason = reason;
        }
        if(action === 'Resume') updateDoc.Status = 'In Progress';
        if(action === 'Complete') updateDoc.Status = 'Completed';

        await firestore.update({
			collection: 'apartments',
			doc: currentApartment,
			subcollections: [{collection: 'IncidentReports', doc: ticket.id }]
        }, updateDoc)
        if(update) update();
    }

    uploadFileHandler = async (e) => {
        const { files } = e.target;
        const { firestore, firebase, user, ticket, currentApartment } = this.props;
        const { DisplayName } = user[0];
        
        const fileName = `${moment(new Date()).format('DMMMYYYY')}-${files[0].name}`;
        const filePath = `${currentApartment}/IncidentReports/${ticket.id}`
        await firebase.uploadFile(filePath, files[0])

        await firestore
        .collection("apartments")
        .doc(currentApartment)
        .collection('IncidentReports')
        .doc(ticket.id)
        .collection('Files')
        .add({ 
            DisplayFileName: fileName,
            FileName: files[0].name,
            UploadedDate: new Date(),
            FilePath: filePath,
            UploadedBy: DisplayName
        })

    }

    uploadComment = async () => {
        const { firestore, user, ticket, currentApartment } = this.props;
        const { comment } = this.state;
        const { DisplayName } = user[0];
        await firestore
            .collection("apartments")
            .doc(currentApartment)
            .collection('IncidentReports')
            .doc(ticket.id)
            .collection('Comments')
            .add({
                Comment: comment,
                CommentBy: DisplayName,
                CommentDate: new Date()
            })
        this.setState({ comment: '' })
    }

    toggleFullhistory = () => {
        const { ticketHistory } = this.props;
        const { historyLength } = this.state;
        this.setState({ historyLength: historyLength === 3 ? ticketHistory.length : 3 })
    }

    updateSubTask = async (subTaskId) => {
        const { firestore, user, ticket, currentApartment } = this.props;
        const { DisplayName } = user[0];
        
        const updateDoc = {
            LastUpdatedDate: firestore.Timestamp.fromDate(new Date()),
            LastUpdatedBy: DisplayName,
            IsOpen: false,
        };

        await firestore.update({
			collection: 'apartments',
			doc: currentApartment,
			subcollections: [{
                collection: 'IncidentReports',
                doc: ticket.id,
                subcollections: [{
                    collection: 'TicketSubTasks',
                    doc: subTaskId
                }]
            }]
        }, updateDoc)
    }

    showCarousel = (images, activeIndex) => {
        this.setState({
            carouselImages: images,
            activeCarouselIndex: activeIndex,
            showPhotoCrousel: true
        })
    }

    render() {
        const { ticket, onBackClick, triggerAssign, ticketComments, isPrivilgedUser, onHoldReasons, ticketSubTasks, user, userAccessInfo, onChangePriority, onChangeCategory, ticketCategories, ticketHistory, ApartmentAccessInfo, currentApartment } = this.props;
        const { comment, historyLength, fileUrls, carouselImages, showPhotoCrousel, activeCarouselIndex, showIssueComment, selectedIssue } = this.state;

        let accessInfo = null;
        if(ApartmentAccessInfo.length){
          const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
         if(selectedApartment){
           accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
         }
        }
        const { Roles } = accessInfo || {}
        const {  DisplayName } = user[0];
        // const { Roles } = userAccessInfo[0];

        const isPerformer = ticket.PerformerRoles.some(role => Roles.includes(role))

        const Comments = ticketComments && ticketComments.map(comment => (
            <div key={comment.id} className="py-3 border-bottom">
                <Comment comment={comment} />
            </div>
        ));

        const CategoryOptions = ticketCategories && ticketCategories
        .filter(c => c.IncidentType === ticket.IncidentType)
        .map(cat => (
            <option value={cat.TicketCategory}>{cat.TicketCategory}</option>
        ))
        const holdReasons = onHoldReasons && onHoldReasons[0].OnHoldReasons && onHoldReasons[0].OnHoldReasons.map(reason => (
            <Dropdown.Item key={reason}  eventKey={reason}>{reason}</Dropdown.Item>
        ))
        
        const activeSubTasks = ticketSubTasks && ticketSubTasks.filter(t => t.IsOpen === true);
        const completedSubTasks = ticketSubTasks && ticketSubTasks.filter(t => t.IsOpen === false);

        const Header = (
            <div>
                <h1 className="font-weight-700 h5 mb-0">{ticket.Title}</h1>
                <span className="d-inline-block text-secondary mr-4">
                    <img src={FileIcon} className="mr-2 mb-1" />
                    {ticket.TicketNo}
                </span>
                {
                    ticket.HouseNo &&
                    <span className="d-inline-block text-secondary">
                        <img src={HomeIcon} className="mr-2 mb-1" />
                        {ticket.HouseNo}
                    </span>
                }
            </div>
        )

        return (
            <div id="ticket-details" className="bg-white" ref={this.top}>
                <div className="d-flex align-items-center flex-wrap border-bottom justify-content-between px-3 py-4">
                    <div className="d-flex align-items-center">
                        <i className="icon-arrow-left font-weight-600 mr-4 cursor-pointer" onClick={onBackClick} />
                        <div className="d-md-block d-none">
                            {Header}
                        </div>
                    </div>
                    <div className="">
                        {
                            ticket.Status === 'New' && ((ticket.IsAssigned && ticket.AssignedTo === DisplayName) || (!ticket.IsAssigned && isPerformer) || isPrivilgedUser) &&
                            <button 
                                className="btn btn-outline-primary rounded-0 font-weight-700"
                                onClick={() => this.actionHandler('Start')}
                            >
                                Start
                            </button>
                        }
                        {
                            ticket.Status === 'In Progress' && ((ticket.IsAssigned && ticket.AssignedTo === DisplayName) || (!ticket.IsAssigned && isPerformer) || isPrivilgedUser) &&                           
                            // <button 
                            //     className="btn btn-outline-primary rounded-0 font-weight-700"
                            //     onClick={() => this.actionHandler('Hold')}
                            // >
                            //     Hold
                            // </button>
                            <Dropdown
                                size="sm"
                                className="d-inline-block"
                                onSelect={(reason)=> {
                                    this.actionHandler('Hold', reason)
                                }}
                            >
                                <Dropdown.Toggle className="rounded-0 font-weight-700" variant="outline-primary">
                                    Hold
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {holdReasons}
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                        {
                            ticket.Status === 'On Hold' && ((ticket.IsAssigned && ticket.AssignedTo === DisplayName) || (!ticket.IsAssigned && isPerformer) || isPrivilgedUser) &&                           
                            <button 
                                className="btn btn-outline-primary rounded-0 font-weight-700"
                                onClick={() => this.actionHandler('Resume')}
                            >
                                Start
                            </button>
                        }
                        {
                            ticket.Status !== 'New' && ticket.Status !== 'Completed' && ((ticket.IsAssigned && ticket.AssignedTo === DisplayName) || (!ticket.IsAssigned && isPerformer) || isPrivilgedUser) &&
                            <button 
                                className="btn btn-outline-primary rounded-0 font-weight-700 ml-2"
                                onClick={() => this.actionHandler('Complete')}
                            >
                                Complete
                            </button>
                        }
                        {   ticket.Status === 'Completed' &&
                            <span className="d-inline-block text-success font-weight-600">Ticket closed</span>
                        }
                            
                        
                    </div>
                </div>
                <div className="px-3 pt-3">
                    <div className="d-md-none d-block mb-3">
                        {Header}
                    </div>
                    <div className="d-flex">
                        {
                            !isPrivilgedUser ?
                            <span 
                                className="text-uppercase mr-3 rounded p-2 font-weight-bold font-13"
                                style={{
                                    backgroundColor: Constant.priority[ticket.Priority] ? Constant.priority[ticket.Priority].background : '', 
                                    color: Constant.priority[ticket.Priority].color
                                }}
                            >
                                {ticket.Priority}
                            </span>
                            : (
                                <select className="form-control priority-select mr-3"
                                    onClick={(e) => e.stopPropagation()}
                                    style={{
                                        backgroundColor: Constant.priority[ticket.Priority] ? Constant.priority[ticket.Priority].background : '', 
                                        color: Constant.priority[ticket.Priority].color
                                    }}
                                    value={ticket.Priority}
                                    onChange={(e) => onChangePriority(e.target.value, ticket)}
                                >
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                </select>
                            )
                        }
                        {
                            (isPrivilgedUser && !ticket.IsAssigned) && (ticket.Source === 'Trackbot App' || ticket.Source === 'Chatbot')  ?
                            (
                                <select className="form-control priority-select mr-3"
                                    onClick={(e) => e.stopPropagation()}
                                    style={{ backgroundColor: '#e9e9e9' }}
                                    value={ticket.TicketCategory}
                                    onChange={(e) => onChangeCategory(e.target.value, ticket)}
                                >
                                    {CategoryOptions}
                                </select>
                            )
                            : 
                            <span className="d-inline-block text-uppercase rounded p-2 font-weight-700 text-dark font-13" style={{ backgroundColor: '#e9e9e9' }}>{ticket.TicketCategory}</span>
                        }
                    
                    </div>
                    <p className="py-3 border-bottom m-0">{ticket.Description}</p>
                </div>
                <div className="d-flex justify-content-between align-items-center border-bottom mx-3 py-3">
                    <div>
                        <span className="d-block h6 m-0 font-weight-bold text-success">
                            {ticket.Status}
                        </span>
                        {
                            ticket.Status === 'On Hold' && ticket.OnHoldReason &&
                            <span className="d-block text-secondary small font-italic">{ticket.OnHoldReason}</span>
                        }
                    </div>
                    <div>
                        {
                            ticket.IsAssigned &&
                            <>     
                                <span className="mr-2 font-14 font-weight-500">{ticket.AssignedTo}</span>
                                <Avatar name={ticket.AssignedTo} className="cursor-pointer" size={40} onClick={(e) => {
                                    triggerAssign(e, ticket, ticket.AssignedTo)
                                }} />
                            </>
                        }
                        {
                            !ticket.IsAssigned &&
                            <button
                                style={{height: 35, width: 35, padding: '2px 6px'}}
                                title="Click to add"
                                className="btn btn-outline-primary rounded-circle btn-sm"
                                onClick={(e) => triggerAssign(e, ticket)}
                                disabled={!isPrivilgedUser}
                            >
                                <FontAwesomeIcon  icon={faUserPlus} />
                            </button>
                        }
                    </div>
                </div>
                {
                    ticket.HasSubTask &&
                    <div className="mx-3 py-3 border-bottom">
                        <h2 className="h6 mb-3 font-weight-bold">Issues</h2>
                        {
                            ticketSubTasks && (ticketSubTasks.length === 0) &&
                            <p className="font-weight-600 font-12 m-0">No Issues.</p>
                        }
                        {
                            ticketSubTasks && (ticketSubTasks.length >0) &&
                            <div className="sub-tasks">
                                <Accordion>
                                    <div className="sub-task">
                                        <Accordion.Toggle className="px-2" as={Card.Header} eventKey="active-tasks">
                                            <div className="d-flex align-items-center cursor-pointer">
                                                <i className="icon-chevron-down font-weight-700 text-dark mr-3 show" />
                                                <div className="d-flex align-items-center">
                                                    <h6 className="mb-0 font-weight-700">Open Items</h6>
                                                    <span className="inline-block ml-1 font-weight-700 text-secondary">({activeSubTasks && activeSubTasks.length})</span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="active-tasks">
                                            <div className="px-lg-4 px-2 py-2">
                                                {
                                                    activeSubTasks && activeSubTasks
                                                    .map(t => (
                                                        <div className="mb-2">
                                                            <div className="d-flex flex-wrap position-relative">
                                                                <p className="mb-1">{t.Title}</p>
                                                                <div className="ml-auto">
                                                                    <span className="small pl-2 pr-3">
                                                                        <em>{t.LastUpdatedBy}@{moment(t.LastUpdatedDate.toDate()).format('DD MMM YYYY')}</em>
                                                                    </span>
                                                                    <input id={t.id} type="checkbox" className="cbx" onChange={() => this.updateSubTask(t.id)} />
                                                                    <label htmlFor={t.id} className="check">
                                                                        <svg width="18px" height="18px" viewBox="0 0 20 20"><circle r="9" cx="10" cy="10"/><polyline points="1 10 7 15 17 4" /></svg>
                                                                    </label>
                                                                    <FontAwesomeIcon                 
                                                                        style={{ paddingTop: 1, color: '#cbcfd7' }}
                                                                        className="ml-2 cursor-pointer"icon={faComment}
                                                                        onClick={() => this.setState({ selectedIssue: t, showIssueComment: true })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <TaskImages
                                                                imgUrls={t.TaskFiles || []}
                                                                onClickImage={(files, index) => this.showCarousel(files, index)}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                    <div className="sub-task">
                                        <Accordion.Toggle className="px-2" as={Card.Header} eventKey="completed-task">
                                            <div className="d-flex align-items-center cursor-pointer">
                                                <i className="icon-chevron-down font-weight-700 text-dark mr-3 show" />
                                                <div className="d-flex align-items-center">
                                                    <h6 className="mb-0 font-weight-700">Closed Items</h6>
                                                    <span className="inline-block ml-1 font-weight-700 text-secondary">({completedSubTasks && completedSubTasks.length})</span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="completed-task">
                                            <div className="px-lg-4 px-2 py-2">
                                                {
                                                    completedSubTasks && completedSubTasks
                                                    .map(t => (
                                                        <div className="mb-2">
                                                            <div className="d-flex flex-wrap">
                                                                <p className="text-truncate mb-1">{t.Title}</p>
                                                                <div className="ml-auto">
                                                                    <span className="small px-2">
                                                                        <em>{t.LastUpdatedBy}@{moment(t.LastUpdatedDate.toDate()).format('DD MMM YYYY')}</em>
                                                                    </span>
                                                                    <span style={{fontSize: 12, marginLeft: 12, color: '#49de33'}}><i className="icon-tick" /></span>
                                                                    <FontAwesomeIcon                 
                                                                        style={{ paddingTop: 1, color: '#cbcfd7' }}
                                                                        className="ml-2 cursor-pointer"icon={faComment}
                                                                        onClick={() => this.setState({ selectedIssue: t, showIssueComment: true })}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <TaskImages imgUrls={t.TaskFiles || []} 
                                                                onClickImage={(files, index) => this.showCarousel(files, index)}
                                                            />
                                                        </div>
                                                        
                                                    ))
                                                }
                                            </div>
                                        </Accordion.Collapse>
                                    </div>
                                </Accordion>
                            </div>
                        }
                    </div>
                }
                <div className="mx-3 py-3 border-bottom">
                    <h2 className="h6 mb-3 font-weight-bold">Files</h2>
                    <div className="files">
                        {
                            fileUrls.length < 5 &&
                            <div className="rounded d-inline-block mr-2 cursor-pointer" style={{ width: 100, height: 100, border: '3px solid #558dca' }} onClick={() => this.fileUpload.current.click()}>
                                <div style={{ width: 100, height: 100 }} className="d-flex align-items-center justify-content-center">
                                    <i className="icon-plus text-primary h1" />
                                </div>
                                <input type="file" accept="image/*" onChange={this.uploadFileHandler} className="d-none" ref={this.fileUpload} />
                            </div>
                        }
                        {
                            fileUrls.map((file, i) => (
                                <div key={file} style={{ width: 100, height: 100 }} onClick={()=> this.showCarousel(fileUrls, i)} className="file mr-2">
                                    <img src={file} style={{ width: 100, height: 100, objectFit: 'cover' }} />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="mx-3 py-3">
                    <h2 className="h6 mb-0 font-weight-bold">Comments</h2>
                    <textarea className="adhocTextarea mt-2" style={{ zIndex: 1 }} placeholder="Add Comment" 
                        onChange={(e) => this.setState({ comment: e.target.value })} 
                        value={comment}
                    />
                    <div className="position-relative" style={{ zIndex: 100 }}>
                        <button type="submit"
                            className="position-absolute submitButton"
                            style={{ top: -44, right: 11, backgroundColor: comment.length === 0 ? '#D5D5D5' : '#4a90e2' }}
                            onClick={this.uploadComment}
                            disabled={comment.length === 0}>
                            Submit
                        </button>
                    </div>
                    {Comments}
                    {
                        (Comments && Comments.length === 0) &&
                        <p className="font-weight-600 font-12 m-0 py-3 border-bottom">No Comments</p>
                    }
                </div>
                <div className="mx-3 py-3">
                    <h2 className="h6 mb-3 font-weight-bold">History</h2>
                    <div>
                        { ticketHistory && ticketHistory.length ? 
                        <>
                            <ul className="timeline new">
                                { ticketHistory.slice(0, 1).map((history, i) => (
                                    <li data-date={`${moment(history.UpdatedTime.toDate()).format('D MMM YYYY')}`} key={i} data-by={history.UpdatedBy}>
                                    <span className={['badge-indicator', history.Indicator && history.Indicator.toLowerCase()].join(' ')} />
                                    <span>{history.Action}</span>
                                    <em className="small ml-2">({history.UpdatedBy})</em>
                                    </li>
                                ))}
                            </ul>
                            { ticketHistory.slice(1, 3).length ? 
                            <div className="history-block text-center">
                                <ul className="timeline">
                                    { ticketHistory.slice(1, historyLength).map((history, i) => (
                                    <li data-date={moment(history.UpdatedTime.toDate()).format('D MMM YYYY')} key={i}>
                                        <span className={['badge-indicator', history.Indicator && history.Indicator.toLowerCase()].join(' ')} />
                                        <span>{history.Action}</span>
                                        <em className="small ml-2">({history.UpdatedBy})</em>
                                    </li>
                                    ))}
                                </ul>
                                { 
                                    ticketHistory.slice(3, ticketHistory.length).length ? 
                                    <a className="helper d-inline-block mt-3 cursor-pointer small" onClick={this.toggleFullhistory}>
                                        <u>{`${historyLength === 3 ? 'show' : 'hide'} full history`}</u>
                                    </a> : '' 
                                }
                            </div> : '' } 
                        </>  : 
                        <p className="text-secondary"><small>No history</small></p> }
                    </div>
                </div>     
                <PhotoCarousel
                    isImageModalOpen={showPhotoCrousel}
                    images={carouselImages}
                    onHide={() => this.setState({ showPhotoCrousel: false })}
                    activeIndex={activeCarouselIndex}
                />
                <IssueCommentModal
                    show={showIssueComment}
                    issue={selectedIssue}
                    onHide={()=>this.setState({ showIssueComment: false})}
                    addComment={this.addIssueComment}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
	user: state.firestore.ordered.Users && state.firestore.ordered.Users,
    ticketComments: state.firestore.ordered.ticketComments,
    ticketFiles: state.firestore.ordered.ticketFiles,
    // userAccessInfo: state.firestore.ordered.UserAccessInfo,
    // residentServices: state.firestore.ordered.residentServices,
    ticketHistory: state.firestore.ordered.ticketHistory,
    onHoldReasons: state.firestore.ordered.onHoldReasons,
    ticketCategories: state.firestore.ordered.ticketCategories,
    ticketSubTasks: state.firestore.ordered.ticketSubTasks,
    currentApartment: state.apartment.currentApartment,
    ApartmentAccessInfo: state.apartment.ApartmentAccessInfo
})

const mapDispatchToProps = {

}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect(),
	firebaseConnect(),
	// withRouter
)(TicketDetails)

// export default TicketDetails
