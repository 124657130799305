export const initialState = { show: false, moduleName: null, equipment: null, condition: null, document: null }

export function reducer(state = initialState, action) {
  if (action.type === "SHOW_MODAL") {
    return { ...state, show: true, moduleName: action.moduleName, equipment: action.equipment, condition: action.condition, document: action.document }
  }
  if (action.type === "HIDE_MODAL") {
    return { ...state, show: false, moduleName: null, equipment: null, condition: null, document: null }
  }
  return state
}