/* eslint-disable camelcase */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import * as _ from "lodash";
import { AddTaskModal } from "../AddTaskModal/AddTaskModal";
import "./StaffActivities.scss";
import createHistory from "../../history";
import StaffActivityList from "../StaffActivityList/StaffActivityList";
import StaffActivitiesDetails from "../StaffActivitiesDetails/StaffActivitiesDetails";
import SlidingPane from "react-sliding-pane";
import { Modal } from "react-bootstrap";
import { createBrowserHistory } from 'history';

const initialFormVal = {
  ServiceArea: "",
  ServiceModule: "",
  TaskName: "",
  IsPhotoTask: false,
  IsPhotoReq: false,
  IsVerificationTask: false,
  ApprovalLevels: 1,
  IsRecurringTask: false,
  Schedules: "Daily",
  Schedule: "",
  AptId: "",
  Level_1: [],
  Level_2: [],
  Level_3: [],
  GracePeriod: 0,
  IsNewTask: true,
  ActivityStart: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
  AllPerformers: [],
  IsSheduledTask: false
};

class StaffActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      activeSystem: 0,
      photoURL: require("../../assets/images/default_dp.svg"),
      isNotifModalOpen: false,
      isOnline: false,
      serviceArea: null,
      activityDetail: null,
      isOpenSidePane: false,
      formData: null,
      formVal: initialFormVal,
    };
    this.componentNameRef = React.createRef();
    this.subMenuRef = React.createRef();
    this.subMenuIconRef = React.createRef();
  }

  componentDidMount() {
    const { location } = this.props;
    const width = this.getWidth();
    this.setState({ isMobile: width < 768 });
    window.addEventListener("resize", this.reportWindowSize);
    document.addEventListener("click", this.handleClickOutside);
    const SearchString = decodeURI(window.location.search.substring(1));
    const serviceArea = SearchString.split(";")[0];
    const activityDetail = SearchString.split(";")[1];
    this.setState({ serviceArea, activityDetail });
    this.props.firestore
      .collection("apartments")
      .doc(this.props.currentApartment)
      .collection("DailyTaskAdditionConfig")
      .get()
      .then((collection) => {
        collection.forEach((doc) => {
          if (doc.id) {
            this.setState({ formData: doc.data() });
          }
        });
      });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.reportWindowSize);
    document.removeEventListener("click", this.handleClickOutside);
  }

  openSidePane() {
    this.setState({
      isOpenSidePane: true,
    });
  }

  closeSidePane() {
    this.setState({
      isOpenSidePane: false,
    });
  }

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  showSideNav = () => {
    setTimeout(() => this.props.openSideBar(false));
  };

  navigate = (activityDetail) => {
    const history = createBrowserHistory();
    const { location } = this.props;
    const searchString = decodeURI(location.search.substring(1));
    history.push(`/staff/task?${searchString};${activityDetail}`);
    this.setState({ activityDetail });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const SearchString = decodeURI(window.location.search.substring(1));
    const serviceArea = SearchString.split(";")[0];
    const activityDetail = SearchString.split(";")[1];
    this.setState({ serviceArea, activityDetail });
  }

  goBack = () => {
    const history = createBrowserHistory();
    history.goBack();
    this.setState({ activityDetail: null });
  };

  hasRole = () => {
    const { userAccessInfo } = this.props;
    return (
      userAccessInfo &&
      (userAccessInfo[0].is_manager || userAccessInfo[0].is_mc_member)
    );
  };

  getFormVal = (val) => {
    this.setState({
      formVal: val,
    });
  };

  addTask = (val) => {
    this.props.firestore
      .collection("apartments")
      .doc(this.props.currentApartment)
      .collection("DailyTasksMaster")
      .add(val)
      .then(() => {
        this.setState({
          isNotifModalOpen: true,
          formVal: initialFormVal,
        });
        this.closeSidePane();
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

  render() {
    const { isMobile } = this.state;
    return (
      <div id="staff-activities">
        <div className="system-header d-flex justify-content-between align-items-center">
          {!this.state.activityDetail ? (
            <div className={`${isMobile ? "d-flex flex-column" : ""}`}>
              <h4>{this.state.serviceArea}</h4>
              <p className="text-muted mb-0 mr-2">
                <span>Staff Activities</span>{" "}
                {!isMobile && `/ ${this.state.serviceArea}`}
              </p>
            </div>
          ) : (
            <div className="d-flex w-100 align-items-start">
              <div className={`${isMobile ? "d-flex flex-column" : ""}`}>
                <h4>
                  <i className="icon-arrow-left mr-2" onClick={this.goBack}></i>
                  {this.state.activityDetail}
                </h4>
                <p className="text-muted mb-0 mr-2">
                  <span>Staff Activities</span>{" "}
                  {!isMobile &&
                    `/ ${this.state.serviceArea} / ${this.state.activityDetail}`}
                </p>
              </div>
              {this.hasRole() && this.state.formData && (
                <button
                  className="btn btn-primary rounded-0 py-2 ml-auto add-ticket-btn"
                  onClick={() => this.openSidePane()}
                >
                  <span className="d-md-inline d-none">Add Task</span>
                  <i className="icon-plus d-md-none d-inline"></i>
                </button>
              )}
            </div>
          )}
        </div>
        <SlidingPane
          className="some-custom-class"
          overlayClassName="some-custom-overlay-class"
          isOpen={this.state.isOpenSidePane}
          onRequestClose={() => this.closeSidePane()}
        >
          <AddTaskModal
            callback={() => this.closeSidePane()}
            formData={this.state.formData}
            updateFormCb={this.getFormVal}
            defaults={{
              ...this.state.formVal,
              ServiceArea: decodeURI(
                window.location.search.substring(1).split(";")[0]
              ),
              AptId: this.props.currentApartment,
            }}
            handleSubmitCb={this.addTask}
          ></AddTaskModal>
        </SlidingPane>
        {!this.state.activityDetail ? (
          <StaffActivityList navigate={this.navigate} />
        ) : (
          <StaffActivitiesDetails />
        )}
        <Modal
          centered
          show={this.state.isNotifModalOpen}
          onHide={() => this.setState({ isNotifModalOpen: false })}
        >
          <Modal.Header
            className="tb-modal-header pt-2 pb-0"
            closeButton
          ></Modal.Header>
          <Modal.Body className="pt-2 pb-0">
            Successfully added task.
          </Modal.Body>
          <Modal.Footer className="tb-modal-footer pt-2 pb-3">
            <button
              className="btn btn-sm btn-info"
              onClick={() => this.setState({ isNotifModalOpen: false })}
            >
              OK
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearFirestore: () => dispatch({ type: "@@reduxFirestore/CLEAR_DATA" }),
  setModule: (name) => dispatch({ type: "UPDATE_MODULE", payload: name }),
  openSideBar: (value) => dispatch({ type: "SIDEBAR_OPEN", payload: value }),
  addTaskImage: (files) => dispatch({ type: "ADD_IMAGE", payload: files }),
  setUploadStatus: (status) =>
    dispatch({ type: "UPLOAD_STATUS", payload: status }), // TO prevent multiple upload of same file
});

const mapStateToProps = (state) => ({
  updateModule: state.updateModule,
  user: state.firestore.ordered.Users,
  taskDetails: state.firestore.ordered.TaskDetails,
  userAccessInfo: state.firestore.ordered.UserAccessInfo,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  taskFiles: state.updateModule.taskFiles,
  uploadStatus: state.updateModule.uploading,
  currentApartment: state.apartment.currentApartment,
  ApartmentAccessInfo: state.apartment.ApartmentAccessInfo,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(),
  firebaseConnect(),
  withRouter
)(StaffActivities);
