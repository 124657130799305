import React, { Component, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import * as moment from 'moment';
import createHistory from "../../history";
import lastUpdatedTime from "../common/Timeago";
import { ContractModal } from './ContractIntervalModal';
import { IntervalModal } from './ServiceIntervalModal';
import { ListContracts, DetailsView } from '../Cards/ServiceIntervalList/ServiceIntervalList';
import Loading from '../common/Loading/Loading';
import SearchBarFilter from '../common/SearchBarFilter';
import './ServiceIntervals.css';


class ServiceIntervals extends Component {
  constructor() {
    super();
    this.state = { 
      isMobile: false,
      filterBy: '',
      showDetails: false,
      isCancelledInc: false,
      intervalId: '',
      searchValue: '',
      focusSearch: false,
    }
    this.ServiceDetailsOptions = { };
  }

  componentDidMount = () => {
    const { user, location, setModule, currentApartment } = this.props;
    const { PhotoURL } = user[0];
    const Category = decodeURI(location.search.substring(1));
    const width = this.getWidth();
    this.setState({ isMobile: width < 768, isMobileSet: true })
    window.addEventListener('resize', this.reportWindowSize);

    if (user.length !== 0 && currentApartment) {
      setModule(Category);
      this.fetchData(currentApartment);
    }

    if (user && PhotoURL) {
      const url = this.getPhotoURL(PhotoURL);
      this.setState({ photoURL: url });
    }
  }

  componentDidUpdate = (prevProps) => {
    const { user, currentApartment } = this.props;
    if (user.length !== 0) {
      if (prevProps.user.length !== 0) {
        if (
          currentApartment &&
          currentApartment !== prevProps.currentApartment
        ) {
          this.fetchData(currentApartment);
        }
      } else if (currentApartment) {
        this.fetchData(currentApartment);
      }
    }
  };

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    )
  };


  reportWindowSize = (e) => {
    const { isMobile } = this.state
    if (isMobile) {
      if (e.target.innerWidth > 768) {
        this.setState({ isMobile: false });
      }
    } else if (e.target.innerWidth < 769) {
      this.setState({ isMobile: true });
    }
  }
  
  fetchData = (currentApartment) => {
    const { firestore } = this.props;
    if (this.unsubscribeServiceIntervalCards) {
      this.unsubscribeServiceIntervalCards();
    }
    this.unsubscribeServiceIntervalCards = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "ServiceIntervalCards" }],
      storeAs: "ServiceIntervalCards",
    });
    if (this.unsubscribeServiceIntervalDetails) {
      this.unsubscribeServiceIntervalDetails();
    }
    this.unsubscribeServiceIntervalDetails = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "ServiceIntervalDetails" }],
      storeAs: "ServiceIntervalDetails",
    });
  };

  getPhotoURL = async path => {
    const { firebase } = this.props;
    const storage = firebase.storage();
    const url = await storage.ref().child(path).getDownloadURL();
    return url;
  }

  getIndicator = (color) => {
    switch (color) {
      case "Green":
        return <div className="sysGreen" />;
      case "Red":
        return <div className="sysRed" />;
      case "Amber":
        return <div className="sysAmber" />;
      default:
        break;
    }
  };

  getThemeColor = () => { 
    const { AppartmentDetails } = this.props;
    const { isMobile  } = this.state;
    if(isMobile && AppartmentDetails[0].Header_Theme_Color) {
      return AppartmentDetails[0].Header_Theme_Color
    }
    return null
  }

  filterBySearch = (value, intervals) => intervals.filter(i => i.ServiceTitle.match(new RegExp(`${value}`, 'i')) ||
             i.ServiceName.match(new RegExp(`^${value}`, 'i')) ||
             (i.Vendor && i.Vendor.match(new RegExp(`^${value}`, 'i'))))

  onHideContractModal = () => {
    const { showContractModal } = this.state;
    this.setState({showContractModal: false});
  }

  onHideIntervalModal = () => {
    const { showIntervalModal } = this.state;
    this.setState({showIntervalModal: false});
  }

  getHistoryFetchOptions = (ServiceDetailsOptions) => ({ ...ServiceDetailsOptions, subcollections: [{ ...ServiceDetailsOptions.subcollections[0],
      subcollections: [
        { collection: 'ServiceIntervalHistory', orderBy: ['UpdatedTime', 'desc'], limit: 25 }
      ]}],
      storeAs: 'ContractIntervalHistory'
    })
  
  getCommentFetchOptions = (ServiceDetailsOptions) => ({ ...ServiceDetailsOptions, subcollections: [{ ...ServiceDetailsOptions.subcollections[0],
      subcollections: [
        { collection: 'ServiceIntervalComments', orderBy: ['CommentDate', 'desc'] }
      ]}],
      storeAs: 'ContractIntervalComments'
    })

  getFileFetchOptions = (ServiceDetailsOptions) => ({ ...ServiceDetailsOptions, subcollections: [{ ...ServiceDetailsOptions.subcollections[0],
      subcollections: [
        { collection: 'ServiceIntervalFiles', orderBy: ['UploadedDate', 'desc'] }
      ]}],
      storeAs: 'ContractIntervalFiles'
    })

  getIntervalHistory = async (interval) => {
    const docId = interval.id;
    const { user, firestore, currentApartment } = this.props;
    
    this.setState({showDetails: true, sysHistoryLoading: true, intervalId: interval.id});
    this.ServiceDetailsOptions = {
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [{ 
        collection: 'ServiceIntervalDetails',
        doc: docId
      }],
    };
    const HistoryOptions = this.getHistoryFetchOptions(this.ServiceDetailsOptions);

    const CommentOptions = this.getCommentFetchOptions(this.ServiceDetailsOptions);
    const FileOptions = this.getFileFetchOptions(this.ServiceDetailsOptions);

    await Promise.all([
      firestore.get(CommentOptions),
      firestore.get(FileOptions),
      firestore.get(HistoryOptions),
    ]);    
    this.setState({sysHistoryLoading: false});
  }

  onClickRenew = (id, action) => {
    const { serviceIntervalsDetails } = this.props;
    const interval = serviceIntervalsDetails.find(i => i.id === id);
    this.setState({showContractModal: true, interval, action: 'Renew'});
  }

  onClickUpdate = (id, action) => {
    const { serviceIntervalsDetails, location } = this.props;
    const Category = decodeURI(location.search.substring(1));
    const interval = serviceIntervalsDetails.find(i => i.id === id);
    this.setState({
      [`${Category === 'Intervals' ? 'showIntervalModal' :'showContractModal'}`]: true, 
      interval, action: 'Update'
    });
  }

  onClickAdd = () => {
    this.setState({showContractModal: true, action: 'New', showDetails: false, intervalId: null});
  }

  onClickCancel = (id) => {
    const { serviceIntervalsDetails } = this.props;
    const interval = serviceIntervalsDetails.find(i => i.id === id);
    this.setState({showCancel: true, interval});
  }

  onSubmitData = async (data, action, id) => {
    const { user, firestore, location, currentApartment } = this.props;
    const { DisplayName } = user[0];
    const Category = decodeURI(location.search.substring(1));
    const comments = data.comments;
    const files = data.files;
    delete data.comments;
    delete data.files;
    const ServiceDetailsOptions = {
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [{ collection: 'ServiceIntervalDetails' }]
    };
    let firestoreService;
    if (id) {
      firestoreService = firestore.update;
      ServiceDetailsOptions.subcollections[0].doc = id;
    } else {
      firestoreService = firestore.add;
      this.setState({showDetails: false, intervalId: null});
    }
    let interval = await firestoreService(ServiceDetailsOptions, {
      ...data, Category, Action: action, LastUpdatedBy: DisplayName, LastUpdatedDate: new Date()
    });
    if (id) {
      interval = { id };
    }

    if (comments) await this.onComment(interval.id, comments);
    if (files) await this.onUpload(interval.id, files, action);

    return true;
  }

  onComment = async (docId, text) => {
    const { user, firestore, currentApartment } = this.props;
    const { DisplayName } = user[0];
    const ServiceDetailsOptions = {
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [{ 
        collection: 'ServiceIntervalDetails', doc: docId, subcollections: [{ collection: 'ServiceIntervalComments' }]
      }],
    }
    await firestore.add(ServiceDetailsOptions, { Comments: text, CommentBy: DisplayName, CommentDate: new Date() });
    const CommentFetchOptions = this.getCommentFetchOptions(ServiceDetailsOptions);
    await firestore.get(CommentFetchOptions);
  }

  onUpload = async (docId, files, action) => {
    const { user, firebase, firestore, currentApartment } = this.props;
    const { DisplayName } = user[0]; 
    await Promise.all(files.map(async file => {
      const fileName = `${action}-${moment(new Date()).format('DMMMYYYY')}-${file.name}`;
      const filesPath = `${currentApartment}/ServiceIntervalDetails/${docId}`;
      const ServiceDetailsOptions = {
        collection: 'apartments',
        doc: currentApartment,
        subcollections: [{ 
          collection: 'ServiceIntervalDetails', doc: docId, subcollections: [{ collection: 'ServiceIntervalFiles' }]
        }],
      }
      await firebase.uploadFile(filesPath, file);
      await firestore.add(ServiceDetailsOptions, { 
        DisplayFileName: fileName, FileName: file.name, UploadedDate: new Date(),
        FilePath: `${filesPath}/${file.name}`, UploadedBy: DisplayName
      });
      const FileFetchOptions = this.getFileFetchOptions(ServiceDetailsOptions);
      await firestore.get(FileFetchOptions);
    }));
  }

  cancelContract = async (docId) => {
    const { user, firestore, currentApartment } = this.props;
    const { DisplayName } = user[0];
    firestore.update({
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [{ collection: 'ServiceIntervalDetails', doc: docId }]
    }, { Status: 'Cancelled', Action: 'Cancel', LastUpdatedBy: DisplayName, LastUpdatedDate: new Date() });
    this.setState({showCancel: false});
  }

  renderHeaderControls = (serviceNames, isHeader) => {
    const { location } = this.props;
    const { isMobile, showDetails, focusSearch, searchValue, isCancelledInc, filterBy} = this.state;
    const Category = decodeURI(location.search.substring(1));

    return(<>
      { !showDetails && <div className={`d-flex ml-auto${isMobile && !isHeader ? ' justify-content-end' : ''}`}>
        { ((isMobile && !isHeader) || (!isMobile && isHeader)) && 
        <SearchBarFilter className="m-0"
          focusSearch={focusSearch} onTextChange={(e) => this.setState({ searchValue: e.target.value })}
          onFocus={() => this.setState({ focusSearch: true })} onBlur={() => this.setState({ focusSearch: false })}
          nameSort={searchValue} placeholder={`Enter Title, System${Category !== 'Intervals' ? ` or Vendor` : ``}`}
        /> }
        { ((isMobile && !isHeader) || (!isMobile && isHeader)) && Category !== 'Intervals' &&
        <label className="custom-checkbox ml-3" htmlFor="cancelled">
          <input type="checkbox" id="cancelled" name="cancelled" defaultChecked={isCancelledInc} 
            onChange={() => this.setState({isCancelledInc: !isCancelledInc})} />
          <label htmlFor="cancelled">{!isMobile && `Include `}Terminated</label>
        </label>}
        { isHeader && <div className="filter-btn ml-3">
          <button>
            <img alt="filter" src={require('../../assets/images/filter.svg')} style={{ width: 17, height: 12 }} />
          </button>
          <select onChange={(e) => this.setState({filterBy: e.target.value})} defaultValue={filterBy}>
            <option value="">All</option>
            { serviceNames.map(s => (
              <option key={`filter-option-${s}`} value={s}>{s}</option>
            ))}
          </select>
        </div>}
      </div> }
      { isHeader && Category !== 'Intervals' && <button className={`btn-add btn btn-primary ${showDetails ? `ml-auto` : `ml-2`}`} onClick={this.onClickAdd}>{isMobile ? '+' : 'Add Contract'}</button>}
    </>);
  }

  render() {
    const { 
      serviceIntervalsDetails, location, contractIntervalFiles, 
      contractIntervalHistory, contractIntervalComments 
    } = this.props;
    const { 
      isMobile, showContractModal, filterBy, isCancelledInc, action, intervalId, 
      showCancel, showDetails, sysHistoryLoading, searchValue, showIntervalModal
    } = this.state;
    const Category = decodeURI(location.search.substring(1));
    if (!serviceIntervalsDetails || !serviceIntervalsDetails.length) {
      return (<Loading showLogo="false" />)
    }
    let interval =  {};
    if (intervalId) {
      interval = serviceIntervalsDetails.find(s => s.id === intervalId);
    }

    const contractMaster = serviceIntervalsDetails.find(s => s.id === '_contracts_master');
    let serviceIntervals = serviceIntervalsDetails.filter(s => s.Category === Category);
    const serviceNames = [...new Set(serviceIntervals.map(s => s.ServiceName).sort())];
    if (filterBy) serviceIntervals = serviceIntervals.filter(s => s.ServiceName === filterBy);
    if (!isCancelledInc) serviceIntervals = serviceIntervals.filter(s => s.Status !== 'Cancelled');
    if (searchValue) serviceIntervals = this.filterBySearch(searchValue, serviceIntervals);
    let heading;
    if (Category === 'Contracts') {
      heading = 'Contracts and Compliance';
    } else if (Category === 'Intervals') {
      heading = 'Routine Maintainence'
    }

    return (
      <div id="service-interval">
        <div className={['system-header', isMobile ? 'd-flex flex-column' : ''].join(' ')} style={{backgroundColor: this.getThemeColor()}}>
          <div className="d-flex align-items-center">
            <div>
              <h4 className="order-2">{heading}</h4>
              <p className="text-muted mb-0 mr-2"><span>System Interval</span> {!isMobile && `/ ${heading}`}</p>
            </div>
            { this.renderHeaderControls(serviceNames, true) }
          </div>
          
        </div>
        <div className="system-body">
          { this.renderHeaderControls(serviceNames, false) }
          { !showDetails ? 
            <ListContracts serviceIntervals={serviceIntervals} onClickRow={this.getIntervalHistory} Category={Category} />
          : <DetailsView clickBack={() => this.setState({showDetails: false, intervalId: null})} isMobile={isMobile} Category={Category}
            {...{interval, contractIntervalHistory, contractIntervalComments, contractIntervalFiles, sysHistoryLoading}} getPhotoURL={this.getPhotoURL}            
            onRenew={this.onClickRenew} onUpdate={this.onClickUpdate} onCancel={this.onClickCancel} onComment={this.onComment} /> }
        </div>

        <ContractModal show={showContractModal} onHide={this.onHideContractModal} contractMaster={contractMaster} 
          Category={Category} action={action} interval={interval} onSubmitData={this.onSubmitData} />

        <IntervalModal show={showIntervalModal} onHide={this.onHideIntervalModal} contractMaster={contractMaster} 
          Category={Category} action={action} interval={interval} onSubmitData={this.onSubmitData} />

        <Modal className="profile service-interval-modal" show={showCancel} onHide={() => this.setState({showCancel: false})}>
          <Modal.Header className="tb-modal-header pb-0" closeButton>
          </Modal.Header>
          <Modal.Body className="tb-modal-body pb-4">
            Are you sure you want to Terminate <strong>{interval && interval.ServiceTitle}</strong> ?
          </Modal.Body>
          <Modal.Footer className="tb-modal-footer">
            <button className="btn btn-sm btn-outline-secondary" onClick={() => this.setState({showCancel: false})}>No</button>
            <button className="btn btn-sm btn-danger" onClick={() => this.cancelContract(interval.id)}>Yes</button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}



ServiceIntervals.defaultProps = {
  user: [{ Email: '' }]
};

const mapDispatchToProps = dispatch => ({
  clearFirestore: () => dispatch({ type: "@@reduxFirestore/CLEAR_DATA" }),
  setErrorMessage: (message, errorType) => dispatch({ type: "ERROR_VALUE", message, errorType }),
  setModule: (name) => dispatch({ type: 'UPDATE_MODULE', payload: name }),
  openSideBar: (value) => dispatch({ type: 'SIDEBAR_OPEN', payload: value })
});

const mapStateToProps = state => ({
  email: state.firebase.auth.email,
  user: state.firestore.ordered.Users,
  serviceIntervalsDetails: state.firestore.ordered.ServiceIntervalDetails,
  contractIntervalHistory: state.firestore.ordered.ContractIntervalHistory,
  contractIntervalComments: state.firestore.ordered.ContractIntervalComments,
  contractIntervalFiles: state.firestore.ordered.ContractIntervalFiles,
  updateModule: state.updateModule,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  currentApartment: state.apartment.currentApartment
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(),
  withRouter
)(ServiceIntervals);
