import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import createHistory from "../../history";
import './Adhoc.css'


class AdhocEditModal extends Component {
  constructor() {
    super();
    this.state = {
      saving: false,
      startDay: null,
      endDay: null,
      files: [],
      dbFilestoRemove: [],
    };
    this.taskType = null    
    this.Description = null;    
  }



  uploadData=async(status, index)=>{
    const { firestore, currentApartment, hideModal, user, firebase, data } = this.props;
    const { startDay, endDay, files } = this.state;
    if(startDay && endDay ){
      this.setState({saving: true, buttonPressed: index})
    
          if(files){
            const filesPath = `${currentApartment }/Ad-Hoc-Tasks/${data.id}`;
            await firebase.uploadFiles(filesPath, files).then();
          await firestore.collection("apartments").doc(currentApartment).collection('AdHocTaskManager').doc(data.id).update({
            TaskDescription: this.Description,
            TaskStartDate: new Date(startDay),
            TaskEndDate: new Date(endDay),
            TaskLastUpdatedBy: user[0].DisplayName,
            UserAction: status,
            TaskLastUpdatedByUID: user[0].UID,
          }).then(()=>{
            this.setState({saving: false, buttonPressed: -1})
            this.clearData()
            hideModal()
            if(status !== 'Save'){
              createHistory.goBack()
            }
          })
        }        
    }

  }

  clearData=()=>{
    this.taskType = null    
    this.Description = null;        
    this.setState({
      startDay: null,
      endDay: null,
      files: null,
    })
  }

  selectFile=(e)=>{
    const { files } = this.state;
    const newFiles = this.fileToArray(e.target.files);
    if(!files)  {
      this.setState({ files: newFiles});
      return;
    }
    const joined = this.fileToArray(files).concat(this.fileToArray(newFiles));
    this.setState({ files: joined})
  }

  removeItem=(index)=>{
    const { files } = this.state;
    const _files = files;
    _files.splice(index, 1)
    this.setState({ files: _files});
  }

  fileToArray = (fileList) => Array.prototype.slice.call(fileList)

  OnStartDayChange = (startDay) => {this.setState({ startDay }); this.startDay = startDay}

  OnEndDayChange = (endDay) => {this.setState({ endDay }); this.endDay = endDay}

  onTaskTypeChange=(e)=>{this.taskType = e.target.value}
  
  onCancel = () => {
    const { hideModal } = this.props;
    this.clearData()
    hideModal()
  }

  componentWillReceiveProps=()=>{
    const { data, } = this.props;
    this.startDay = data.TaskCreatedDate.toDate();
    this.endDay = data.TaskEndDate.toDate();
    this.setState({
        startDay : this.startDay,
        endDay: this.endDay,
    })
  }

  removeFileFromFirestore=async(file)=>{
    const { firestore, data, currentApartment } = this.props;
    await firestore
        .collection("apartments")
        .doc(currentApartment)
        .collection('AdHocTaskManager')
        .doc(data.id)
        .collection('FileList')
        .doc(file.id)
        .delete();
}

  fileToBeRemoved=(item,index)=>{
    const { dbFilestoRemove } = this.state;
    const _dbFilestoRemove = dbFilestoRemove;    
    this.setState({dbFilestoRemove: _dbFilestoRemove})
  }

  render() {
    const { startDay, endDay, files, buttonPressed } = this.state;
    const { modal, data, FileList } = this.props;
    this.Description = data.TaskDescription
    this.FileList =[]
    this.FileList = FileList;
    return (
      <Modal show={modal.show} onHide={()=>{}} size="lg" id="Adhoc">
        <div className="modalHeading">Edit task</div>

        <div className="modalSubHeadings">Title</div>
        <input className="adhocInputs" value={data.TaskTitle} disabled/>


        <div className="modalSubHeadings">Task Type</div>
        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <div id="Adhoc" className="modalDropdown" style={{paddingTop: 5}}>{data.TaskType}</div>
            <img alt="down" src={require('../../assets/images/down.png')} style={{ width: 10, height: 10, marginLeft: -20, pointerEvents: 'none' }} />
        </div>
        
        <div className="modalSubHeadings">Description</div>
        <input className="adhocInputs" onChange={(e)=>{ this.Description=e.target.value }} defaultValue={data.TaskDescription} />
        <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 50 }}>
          <div style={{zIndex:50}}>
            <div className="modalSubHeadings">Start Date</div>
            <DatePicker
              className="CalendarDatePicker"
              selected={startDay}
              maxDate={endDay && endDay}
              onChange={this.OnStartDayChange}
              style={{ fontSize: 10, }}
            />
          </div>
          <img src={require('../../assets/images/calendar.png')} alt="cal" style={{height: 14, width: 14, marginLeft: -35, marginTop: 20, zIndex:100, opacity: 0.4,}}/>
          <div style={{zIndex:50}}>
            <div className="modalSubHeadings">End Date </div>
            <DatePicker
              className="CalendarDatePicker"
              selected={endDay}
              minDate={startDay && startDay}
              onChange={this.OnEndDayChange}
              style={{ fontSize: 10, }}
            />
          </div>
          <img src={require('../../assets/images/calendar.png')} alt="cal" style={{height: 14, width: 14, marginLeft: -35, marginTop: 20, zIndex: 100, opacity: 0.4,}}/>
        </div>

          { this.FileList && this.FileList.map((item, index)=>(
            <div key={item.id} style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 15}}>
              <div className="fileItems">{item.DisplayFileName}</div>              
            </div>
          ))}
          {files && files.map((item, index)=>(
            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginBottom: 15}}>
              <div className="fileItems">{item.name}</div>
              <button type="submit" style={{color: 'red', border: 0, backgroundColor: 'transparent', outline: 'none'}} onClick={()=>this.removeItem(index)}>
                <img src={require('../../assets/images/close.png')} alt="cal" style={{height: 17, width: 17}}/>                
              </button>
            </div>
          ))}

          <div style={{display: 'flex',flexDirection: 'row', alignItems: 'center', marginBottom: 20}}>
            <div className="cabinet">
              <input 
                type="file"
                name="avatar"
                accept="*/*"
                onChange={this.selectFile}
                multiple
                />
              </div>
              <img src={require('../../assets/images/addFile.png')} alt="cal" className="addImage" style={{marginLeft: -162}}/>
              <div className="uploadFileLabel">Upload File</div>
          </div> 

        <button
            type="submit"
            id="cancel"
            className="AdhocButton"
            onClick={this.onCancel}
            style={{ color: '#3B3B3B', backgroundColor: 'white', marginTop: 25}}>
            Cancel
          </button>
        {data.NextState.map((item, index) => (
          <button key={`NextState-${index}`}
            type="submit"
            className="AdhocButton"
            onClick={()=>this.uploadData(item.ButtonName, index)}
            style={{ backgroundColor: item.ButtonBGColorCode, color: item.ButtonFontColorCode ,border: '0px solid #4A90E2'}}>
            { buttonPressed !== index ?
              item.ButtonName
                :(
                    <span
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                    aria-hidden="true"
                    />
                )
            }
          </button>
        ))}
        <button
            type="submit"
            id="cancel"
            className="AdhocButton"
            onClick={()=>this.uploadData('Save', data.NextState.length)}
            style={{ backgroundColor: '#4A90E2'}}>
              { buttonPressed !== data.NextState.length ?
              'Save'
                :(
                    <span
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                    aria-hidden="true"
                    />
                )
            }
          </button>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  modal: state.modal,
  AdHocTaskManager: state.firestore.data.AdHocTaskManager,
  currentApartment: state.firestore.ordered.Users && state.apartment.currentApartment,
  user: state.firestore.ordered.Users,
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch({
    type: "HIDE_MODAL",
  })
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(),firebaseConnect()
)(AdhocEditModal);
