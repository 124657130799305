import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';

import TicketDetails from '../TicketDetails/TicketDetails';
import AssignTicketModal from '../Incidents/AssignTicketModal/AssignTicketModal';

const IncidentDetails = (props) => {

    const [assignModalVisibility, setAssignModalVisibility] = useState(false);
    const [selectedAssignee, setSelectedAssignee] = useState(null);

    let ticketSubscription;

    useEffect(() => {
        fetchTicketDetails()
        return () => {
            if(ticketSubscription) ticketSubscription();
        }
    }, [])

    const fetchTicketDetails = async () => {
        const { user, firestore, currentApartment  } = props;
        ticketSubscription = firestore.onSnapshot({
            collection: 'apartments',
            doc: currentApartment,
            subcollections: [{
                collection: 'IncidentReports',
                doc: props.match.params.id
            }],
            storeAs: `ticketFetched`
        });
       
    }

    const backClickHandler = () => {
		window.history.back();
	}

	const assignModalTrigger = async (event, ticket, assignee = '') => {
		event.stopPropagation();
		setAssignModalVisibility(true);
		const { firestore, user, currentApartment } = props;
		if(ticket.PerformerRoles && ticket.PerformerRoles.length && isPrivilgedUser) {
			await firestore.get({
				collection: 'apartments',
				doc: currentApartment,
				subcollections: [{
					collection: 'Users', where: [
						['Roles', 'array-contains-any', ticket.PerformerRoles],
					]
				}],
				storeAs: `assigneeList`
			});
			if(assignee) {
				setSelectedAssignee(assignee)
				return
			}
			setSelectedAssignee(null);
		}
	}

	const assignHandler = async (assignee) => {
		const { firestore, user, currentApartment } = props;
		const { DisplayName } = user[0];
		
		const updateDoc = {
			IsAssigned: true,
			AssignedTo: assignee.DisplayName,
			AssignedDate: firestore.Timestamp.fromDate(new Date()),
			LastUpdatedDate: firestore.Timestamp.fromDate(new Date()),
			LastUpdatedBy: DisplayName,
		};
		
		await firestore.update({
			collection: 'apartments',
			doc: currentApartment,
			subcollections: [{collection: 'IncidentReports', doc: props.ticketFetched.id }]
		}, updateDoc)
		// this.update();
		setAssignModalVisibility(false)
	}

	const changePriority = async (priority, ticket) => {
		const { firestore, user, currentApartment } = props;
		const { DisplayName } = user[0];
		
		const updateDoc = {
			Priority: priority,
			LastUpdatedDate: firestore.Timestamp.fromDate(new Date()),
			LastUpdatedBy: DisplayName,
		};
		
		await firestore.update({
			collection: 'apartments',
			doc: currentApartment,
			subcollections: [{collection: 'IncidentReports', doc: ticket.id }]
		}, updateDoc)

	}

	const changeCategory = async (category, ticket) => {
		const { firestore, user, currentApartment } = props;
		const { DisplayName } = user[0];
		
		const updateDoc = {
			TicketCategory: category,
			LastUpdatedDate: firestore.Timestamp.fromDate(new Date()),
			LastUpdatedBy: DisplayName,
		};
		
		await firestore.update({
			collection: 'apartments',
			doc: currentApartment,
			subcollections: [{collection: 'IncidentReports', doc: ticket.id }]
		}, updateDoc)

	}

    let isPrivilgedUser = false;
    if(props.userAccessInfo) {
        const { is_manager, is_mc_member } = props.userAccessInfo[0];
		isPrivilgedUser = is_manager || is_mc_member
    }
    
    return (
        <div>
            {
                props.ticketFetched &&
                <TicketDetails
                    ticket={props.ticketFetched[0]}
                    isPrivilgedUser={isPrivilgedUser}
                    onBackClick={backClickHandler}
                    triggerAssign={assignModalTrigger}
                    onChangePriority={changePriority}
                    onChangeCategory={changeCategory}
                />
            }
            <AssignTicketModal
                show={assignModalVisibility}
                onHide={()=> { setAssignModalVisibility(false) }}
                assignees={props.assigneeList}
                value={selectedAssignee}
                onAssign={assignHandler}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
	user: state.firestore.ordered.Users && state.firestore.ordered.Users,
    ticketFetched: state.firestore.ordered.ticketFetched,
    userAccessInfo: state.firestore.ordered.UserAccessInfo,
	assigneeList: state.firestore.ordered.assigneeList,
	currentApartment: state.apartment.currentApartment,
});

const mapDispatchToProps = {

}


export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect(),
    firebaseConnect(),
    withRouter
)(IncidentDetails);

