import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import Switch from "@material-ui/core/Switch";
import StaffActivityCard from "../../Common/Cards/StaffActivityCard/StaffActivityCard";
import Button, { ViewAll } from "../../Common/WidgetHeader/Button/Button";
import WidgetHeader from "../../Common/WidgetHeader/WidgetHeader";
import createHistory from "../../../../../history";
import ReactSlider from "../../../../common/ReactSlider/ReactSlider";

const classNames = require("classnames");

const StaffActivities = ({
  classValue,
  editMode,
  consolidated,
  firestore,
  selectedZone,
  toggle = () => {},
  show = false,
  isLoading,
  order,
}) => {
  const slider = useRef(null);
  const [isVisible, setIsVisible] = useState(show);
  const [staffActivitySummary, setStaffActivitySummary] = useState([]);
  useEffect(() => {
    toggle("Staff Activities", show);
  }, []);
  useEffect(() => {
    if (consolidated && consolidated.length) {
      // Fetch config like Label for graph
      const Activities = consolidated[0].StaffActivities;
      let condition = [["ServiceArea", "in", Activities]];
      if (selectedZone && selectedZone !== "All") {
        condition = [...condition, ["Zone", "==", selectedZone]];
      }
      firestore
        .get({
          collection: "consolidated",
          doc: consolidated[0].id,
          subcollections: [
            {
              collection: "StaffActivities_ED",
              where: condition,
            },
          ],
          storeAs: "StaffActivities_ED",
        })
        .then((querySnapshot) => {
          let staffActivities = [];

          querySnapshot.forEach((doc) => {
            staffActivities = [...staffActivities, { ...doc.data() }];
          });
          // console.log(staffActivities)
          const staffActivity = _.chain(staffActivities)
            .groupBy("ServiceArea")
            .map((item) => {

              const totalDefaultedTask = item
              .map(items => items.IssuesCount)
              .reduce((prev, curr) => prev + curr, 0);
              
              const Red = item.filter(
                ({ StatusIndicator }) => StatusIndicator === "Red"
              ).length;
              const Amber = item.filter(
                ({ StatusIndicator }) => StatusIndicator === "Amber"
              ).length;
              const total = Red + Amber;
              const redRatio = (Red * 100) / total;
              const amberRatio = (Amber * 100) / total;
              return {
                name: item[0].ServiceArea,
                count: item.length,
                Red,
                Amber,
                status: "critical",
                redRatio,
                amberRatio,
                totalDefaultedTask
              };
            })
            .value();
         
          setStaffActivitySummary(staffActivity);
        });
    }
  }, [consolidated, selectedZone]);
  const handleChange = () => {
    setIsVisible(!isVisible);
    toggle("Staff Activities", !isVisible);
  };

  return (
    <>
      <div className={!editMode ? classValue : "edit-dashed"}>
        <div className="widget">
          {editMode && (
            <span style={{ marginLeft: 12, marginTop: 5 }}>#{order}</span>
          )}
          <div className="widget-header-container d-flex flex-row justify-content-between">
            <WidgetHeader
              label="Staff Activities"
              description="Properties with defaulted tasks"
              disabled={false}
            />
            {!editMode ? (
              <ViewAll onClick={() => createHistory.push(`/executive/widget/staff`)}>View All</ViewAll>
              
            ) : (
              <Switch
                checked={isVisible}
                color="primary"
                onChange={handleChange}
              />
            )}
          </div>
          {isLoading ? (
            <div className="d-flex w-100 h-100 justify-content-center align-items-center">
              <div className="text-secondary ">Loading...</div>
            </div>
          ) : null}
          {!editMode && !isLoading ? (
            <div className={classNames("p-4", { disabled: editMode })}>
              <ReactSlider myRef={slider}>
                {staffActivitySummary.map((item) => (
                  <StaffActivityCard key={item.name} data={item} />
                ))}
                <div />
              </ReactSlider>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default compose(
  connect(({ dashboard, firestore: { ordered: { consolidated } } }) => ({
    consolidated,
    selectedZone: dashboard.selectedZone,
  })),
  firestoreConnect()
)(StaffActivities);
