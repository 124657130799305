import React from 'react'
  
const moment = require('moment');
  
export default function CustomDateView(props) {
  const { onClick, value, monthPicker, small } = props;
  return (
    <button
      type="submit" className={small ? "example-custom-input-small" : "example-custom-input"}
      onClick={onClick}> {value ? moment(value).format(monthPicker? 'MMM YYYY' : 'DD MMM YYYY' ) :
        <span style={{height: '100%', width: '100%', textAlign: 'start'}}></span>
      }
    </button>
  )
}
  