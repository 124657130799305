import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';

const TaskImages = (props) => {
    const [taskImages, setTaskImages] = useState([]);

    const getPhotoURL = async path => {
        const { firebase } = props;
        const storage = firebase.storage();
        const url = await storage.ref().child(path).getDownloadURL();
        return url;
    }
    
    const downloadFiles = async () => {
        const urls = await Promise.all((props.imgUrls).map(f => getPhotoURL(f)));
        setTaskImages(urls);
    }

   useEffect(() => {
    downloadFiles();
   }, [props.imgUrls]);

   return (
        <div className="d-flex">
            {
                taskImages.map((img, i) => <img
                    key={img}
                    src={img}
                    className="mr-1 rounded-circle"
                    onClick={()=>props.onClickImage(taskImages, i)}
                    style={{height: 35,width: 35, objectFit: 'cover'}} 
                />)
            }
        </div>
   )
}

export default compose(
	firestoreConnect(),
	firebaseConnect(),
)(TaskImages);