/* eslint-disable camelcase */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import * as _ from "lodash";
import Loading from "../common/Loading/Loading";
import "./StaffActivityList.scss";

class StaffActivityList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      activeSystem: 0,
      photoURL: require("../../assets/images/default_dp.svg"),
      isOnline: false,
    };
    this.componentNameRef = React.createRef();
    this.subMenuRef = React.createRef();
    this.subMenuIconRef = React.createRef();
  }

  componentDidMount() {
    const { user, location, setModule, currentApartment } = this.props;
    const ServiceArea = decodeURI(location.search.substring(1));
    const width = this.getWidth();
    this.setState({ isMobile: width < 768 });
    window.addEventListener("resize", this.reportWindowSize);
    document.addEventListener("click", this.handleClickOutside);

    if (user.length !== 0 && currentApartment) {
      setModule(ServiceArea);
      this.fetchData(currentApartment);
    }
  }

  componentDidUpdate(prevProps) {
    const { user, clearFirestore, currentApartment } = this.props;
    if (user.length === 0) {
      clearFirestore();
      return;
    }

    if (currentApartment && currentApartment !== prevProps.currentApartment) {
      this.fetchData(currentApartment);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.reportWindowSize);
    document.removeEventListener("click", this.handleClickOutside);
  }

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  reportWindowSize = (e) => {
    const { isMobile } = this.state;
    if (isMobile) {
      if (e.target.innerWidth > 768) {
        this.setState({ isMobile: false });
      }
    } else if (e.target.innerWidth < 769) {
      this.setState({ isMobile: true });
    }
  };

  handleClickOutside = (event) => {
    const { isModuleSidebarOpen, isMobile } = this.state;
    if (
      isMobile &&
      this.subMenuRef.current &&
      !this.subMenuRef.current.contains(event.target) &&
      !this.subMenuIconRef.current.contains(event.target) &&
      isModuleSidebarOpen
    ) {
      this.setState({ isModuleSidebarOpen: false });
    }
  };

  fetchData = (currentApartment) => {
    const { taskDetails, firestore } = this.props;
    const TaskDetailsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "TaskDetails" }],
      storeAs: "TaskDetails",
    };
    if (!taskDetails || !this.taskDetailsSnapShot) {
      if (this.taskDetailsSnapShot) {
        this.taskDetailsSnapShot();
      }
      this.taskDetailsSnapShot = firestore.onSnapshot(TaskDetailsOptions);
    }
  };

  showSideNav = () => {
    setTimeout(() => this.props.openSideBar(false));
  };

  getTaskCountTotal(name) {
    const { taskDetails, location } = this.props;
    const ServiceArea = decodeURI(location.search.substring(1));
    const moduleTasks = taskDetails.filter(
      (t) => t.Module === name && t.ServiceArea === ServiceArea
    );
    return moduleTasks.length;
  }

  getTaskCountByState(name, state) {
    const { taskDetails, location } = this.props;
    const SearchString = decodeURI(location.search.substring(1));
    const moduleTasks = taskDetails.filter(
      (t) => t.Module === name && t.ServiceArea === SearchString
    );

    let tasks = 0;
    if (name) {
      moduleTasks.map((task, i) => {
        if (task.TaskState === state) {
          tasks++;
        }
      });
    }
    return tasks;
  }

  render() {
    const { taskDetails, location, navigate } = this.props;
    const SearchString = decodeURI(location.search.substring(1));
    const ServiceArea = SearchString.split(";")[0];

    if (!taskDetails || !taskDetails.length)
      return <Loading showLogo="false" />;

    const staffTaskDetails = taskDetails.filter(
      (d) => d.ServiceArea === ServiceArea
    );
    const groupedTaskDetails = _.groupBy(staffTaskDetails, (s) => s.Module);
    const moduleNames = Object.keys(groupedTaskDetails).map((c) => {
      if(c !== 'undefined'){
        return c;
      }
    });
    
    return (
      <div id="staff-activity-list" className="system-body">
        {(moduleNames && moduleNames.length === 0) && (
          <div className="m-5 text-center">No Tasks</div>
        )}
        {(moduleNames && moduleNames.length > 0) && (
          <div className="system-names d-flex w-100 align-items-center">
            <ul className="list-group w-100">
              {moduleNames.map((moduleName, i) => {
                const total = this.getTaskCountTotal(moduleName);
                const percentage =
                  (this.getTaskCountByState(moduleName, "COMPLETED") / total) *
                    100 +
                  "%";
                return moduleName ? (
                  <li
                    className={`list-group-item d-flex justify-content-start align-items-center`}
                    key={i}
                    onClick={() => navigate(moduleName)}
                  >
                    <span className="name">{moduleName}</span>
                    <div className="stats d-flex justify-content-start align-items-center">
                      <i className="icon-state icon-active mr-2"></i>
                      <span className="count mr-4">
                        {this.getTaskCountByState(moduleName, "ACTIVE")}/{total}
                      </span>
                      <i className="icon-state icon-done mr-2"></i>
                      <span className="count mr-4">
                        {this.getTaskCountByState(moduleName, "COMPLETED")}/
                        {total}
                      </span>
                      <i className="icon-state icon-exception mr-2"></i>
                      <span className="count mr-4">
                        {this.getTaskCountByState(moduleName, "EXCEPTION")}/
                        {total}
                      </span>
                    </div>
                    <span className="progress">
                      <span
                        className="progress-bar progress-indicator"
                        style={{ width: percentage }}
                      ></span>
                    </span>
                    <span className="icon-chevron-right ml-auto"></span>
                  </li>
                ) : null;
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearFirestore: () => dispatch({ type: "@@reduxFirestore/CLEAR_DATA" }),
  setModule: (name) => dispatch({ type: "UPDATE_MODULE", payload: name }),
  openSideBar: (value) => dispatch({ type: "SIDEBAR_OPEN", payload: value }),
  addTaskImage: (files) => dispatch({ type: "ADD_IMAGE", payload: files }),
  setUploadStatus: (status) =>
    dispatch({ type: "UPLOAD_STATUS", payload: status }), // TO prevent multiple upload of same file
});

const mapStateToProps = (state) => ({
  updateModule: state.updateModule,
  user: state.firestore.ordered.Users,
  taskDetails: state.firestore.ordered.TaskDetails,
  userAccessInfo: state.firestore.ordered.UserAccessInfo,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  taskFiles: state.updateModule.taskFiles,
  uploadStatus: state.updateModule.uploading,
  currentApartment: state.apartment.currentApartment,
  ApartmentAccessInfo: state.apartment.ApartmentAccessInfo,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(),
  firebaseConnect(),
  withRouter
)(StaffActivityList);
