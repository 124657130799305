import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import './Dropdown.css'

const Dropdown = ({data=[], onChange}) => {    
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(data);
    const drop = React.useRef(null);
    const handleClick = e => {        
        if(!e.target.closest(`.${drop.current.className}`) && open) {
            setOpen(false);
            setList(data);
        }
    }
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        }
    })

    useEffect(() => {
        setList(data);
    }, [data])

    const handleSearch = (evt) => {
        evt.persist();
        const handleChangeDebounce = _.debounce((e) => {
            const str = e.target.value;
            const filteredList = data.filter(item => item.ApartmentName.match(new RegExp(`${str}`, 'i')))
            setList(filteredList); 
        }, 750);
        handleChangeDebounce(evt);
    }

    return (
        <div className="drp-container" ref={drop}>
            <button type="button" className="drp-button" onClick={() => setOpen(isOpen => !isOpen)}>
                <i className="icon-chevron-down" style={{ fontSize: 8 }} />
            </button>
            {open && (
                <div className="drp-dropdown">
                    <div className="drp-search">
                        <input type="text" className="form-control" placeholder="Search" onChange={handleSearch}/>
                    </div>
                    <ul>
                        {list.map(item => (                            
                            <li key={item.AptId} onClick={() => {setOpen(false); onChange(item.AptId)}}>{item.ApartmentName}</li>
                        ))}
                    </ul>
                </div>
            )}            
        </div>
    )
}

export default Dropdown;