export const initialState = {
  currentDashboard: "local",
  redirectRoute: "", // action from Exectuvie detail view button  (->) action - Redirect to Home and Switch Apartment and show route
  redirectApartment: "", // action from Exectuvie detail view button  (->) action - Redirect to Home and Switch Apartment and show route
  selectedZone: "", // Executive dashboard navbar selected zone
  cardStatus: {}, // Executive dashboard sortable card on/off status
};

export function reducer(state = initialState, action) {
  if (action.type === "SET_DASHBOARD") {
    return {
      ...state,
      currentDashboard: action.payload,
      redirectRoute: "",
      cardStatus: {},
    };
  }
  if (action.type === "RESET_DASHBOARD") {
    return {
      currentDashboard: "local",
      redirectRoute: "",
    };
  }
  if (action.type === "REDIRECT_DASHBOARD_TO_ROUTE") {
    // const { apartment } = JSON.parse(localStorage.getItem("tb-state"));
    // console.log(apartment);
    return {
      currentDashboard: "local",
      redirectRoute: action.payload.route,
      redirectApartment: action.payload.apartment,
      // redirectApartment: "1002-LUS Mist Valley",
      // redirectApartment: "1001-TOTAL Windmills of Your Mind",
    };
  }
  if (action.type === "SET_SELECTED_ZONE") {
    // NAVBAR EXECUTIVE DASHBOARD
    return {
      ...state,
      selectedZone: action.payload,
    };
  }

  if (action.type === "SET_CARD_STATUS") {
    const { card, status } = action.payload;
    if (state.cardStatus[card] !== undefined) {
      const udpate = {
        ...state.cardStatus,
      };
      udpate[card] = status;
      return {
        ...state,
        cardStatus: udpate,
      };
    }
    const newCard = { ...state.cardStatus, [card]: status };
    return {
      ...state,
      cardStatus: newCard,
    };
  }
  if (action.type === "RESET_CARD_STATUS") {
    return {
      ...state,
      cardStatus: {},
    };
  }
  return state;
}
