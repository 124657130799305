import React, { Component } from 'react';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import * as moment from 'moment';

export class TimeInputWidget extends Component {
  constructor(props) {
    super(props);
    const { reading } = props;
    this.componentDestroyed = false;
    this.state = { 
      value: null,
      paramValue: 0,
      isValueSet: false 
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { reading, isButtonDisabled, edit } = this.props;
    const { reading: prevReading, edit: prevIsEdit } = prevProps;
    const { isValueSet } = this.state;    
    if (reading.isDeviceOn && !isValueSet) {
      if (!prevReading.isDeviceOn) {
        this.setState({paramValue: 0});  
      }
      this.setValue(reading.prevDeviceOnTime);
    } else if (prevReading.isDeviceOn && !reading.isDeviceOn) {
      this.setState({paramValue: 0, isValueSet: false});
    }

    if (edit && !prevIsEdit) {
      this.setState({value: null, isInvalid: false});
    }
  }

  componentWillUnmount() {
    this.componentDestroyed = true;
  }

  setValue(prevTime) {
    if (this.componentDestroyed) return;
    const paramValue = (new Date().getTime() - prevTime.toDate().getTime()) / (1000 * 60);
    this.setState({paramValue: Math.ceil(paramValue), isValueSet: true});
    const secondsTo60 = 60 - new Date().getSeconds();
    setTimeout(() => this.setValue(prevTime), secondsTo60 * 1000);
  }

  getParamName(name) {
    const match = name.match(/(.+)\s(\(.+\))/);
    if (match && match.length) return match[1];
    return name;
  }

  getHelperText(name) {
    const match = name.match(/(.+)\s(\(.+\))/);
    if (match && match.length) return match[2];
    return '';
  }
  

  onChangeVal = (val) => {
    const { reading, onChange, isButtonDisabled, firestore } = this.props;
    const min = new Date(reading.ParamValueRaw.toDate()).getTime();
    const max = new Date().getTime();
    const selected = new Date(val).getTime();
    if (min > selected) {
      this.setState({isInvalid: true});
      isButtonDisabled(true, reading.id);
    } else if (selected > max) {
      this.setState({isInvalid: true});
      isButtonDisabled(true, reading.id);
    } else {
      this.setState({isInvalid: false});
      const timestamp = firestore.Timestamp.fromDate(new Date(val));
      onChange({ id: reading.id, [`${reading.hasOwnProperty('ParamValueRaw') ? `ParamValueRaw` : `ParamValue`}`]: timestamp });
      isButtonDisabled(false, reading.id);
    }
    this.setState({value: moment(val).format('YYYY-MM-DDTHH:mm')});

  }

  getLastUpdate = (value) => {
    let lastUpdateDate = new Date(value.toDate());
    const period = moment(new Date()).diff(moment(lastUpdateDate), 'months');
    if (period < 1) lastUpdateDate = moment(lastUpdateDate).fromNow();
    else lastUpdateDate = moment(lastUpdateDate).format('D MMM YYYY');
    return lastUpdateDate;
  }

  onToggleSwitch = () => {
    this.setState({isInputEnabled: true})
  }

  render() {
    const { reading, edit, isMobile, openHistory  } = this.props;
    const { value, paramValue, isInputEnabled, isInvalid } = this.state;

    return (
      <div className={`time-widget${edit ? ' edit': ''}`}>
      <div className={['d-flex align-items-center', edit ? 'mb-2': ''].join(' ')}>
        <span className={['badge-indicator', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')} />
        <span style={{color:'black'}}>{ !edit ? this.getParamName(reading.ParamName) : reading.ParamNameUpd }</span>
        { reading.HasLocalHistory && <a onClick={openHistory} className="text-muted"><i className="icon-history ml-1" /></a> }
        { !edit || reading.UpdateLocked ? 
          <span className={['float-right', 'ml-auto', 'text-indicator', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')}>
            { reading.ParamUnitLeftAlign && <span className="mr-1">{reading.ParamUnit}</span> } 
            { !reading.isDeviceOn ? reading.ParamValue : paramValue }
            { !reading.ParamUnitLeftAlign && <span className="ml-1">{reading.ParamUnit}</span> }
            { reading.UpdateLocked && <a className="text-muted"><i className="icon-lock ml-3" /></a> }
          </span> :
          <span className="ml-auto edit-body">
            {/* <label className="switch">
              <input type="checkbox" defaultChecked={reading.isDeviceOn} onChange={this.onToggleSwitch} />
              <span className={`slider round ${isMobile && 'ml-auto'}`} />
            </label> */}
            <input type="datetime-local" className={['text-indicator', 'border-indicator', (isInvalid ? 'red': 'grey')].join(' ')} 
              onClick={e => this.onChangeVal(e.target.value)} onChange={e => this.onChangeVal(e.target.value)} value={value || moment(new Date()).format('YYYY-MM-DDTHH:mm')}
              min={moment(reading.ParamValueRaw.toDate()).format('YYYY-MM-DDTHH:mm')} max={moment(new Date()).format('YYYY-MM-DDTHH:mm')} />
          </span>
        }
      </div>
      { !edit && <div className="mb-2 sub">
      <span className="text-dark mb-0"><small>{this.getHelperText(reading.ParamName)}</small></span>
        <div className="d-flex justify-content-between align-items-center">
          <span className="mb-0 mr-2 d-flex flex-column">
            <span className="text-right">{moment(reading.prevDeviceOnTime.toDate()).format('D MMM HH:mm')}</span>
          </span>
          <span className="icon-converter">
            <i className="path1" />
            <i className="path2" />
          </span>
          <span className="mb-0 ml-2 d-flex flex-column">
          { !reading.isDeviceOn ?
            <span className="text-dark text-right">{moment(reading.ParamValueRaw.toDate()).format('D MMM HH:mm')}</span> :
            <span className="text-dark text-right">{moment(new Date()).format('D MMM HH:mm')}</span> 
          }
            {/* <small>{reading.LastUpdatedDate && moment(reading.LastUpdatedDate.toDate()).format('D MMM')}</small> */}
          </span>
        </div>
      </div> }
      </div>
    )
  }
}

export default compose(
	firestoreConnect(),
)(TimeInputWidget);