export const initialState = { name: null, taskFiles:{}, uploading: false, offlineTicketFiles: [] }

export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_MODULE':
      return { ...state, name: action.payload }
    case 'ADD_IMAGE':
      return { ...state, taskFiles: action.payload }
    case 'UPLOAD_STATUS':
      return { ...state, uploading: action.payload }
    case 'ADD_OFFLINE_TICKET_FILES':
      return { ...state, offlineTicketFiles: action.payload }  
    default:
      break;
  }
  return state
}