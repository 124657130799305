import React, { Component } from 'react'
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import Loading from './common/Loading/Loading';
import SystemStatusCard from './Cards/SystemStatusCards/SystemStatusCard';
import NavBar from './common/Navbar/NavBar';

class SystemStatus extends Component {
  constructor(){
      super()
      this.state={}
  }
    
  render() {
    const { SystemDetails } = this.props;
    return (
        <div style={{width: '100%'}} className="">
          <NavBar />
          {!SystemDetails ?
            <Loading showLogo="false" height="3rem" />
          :
            <div className='d-flex flex-wrap justify-content-center float-left'>
                {SystemDetails && SystemDetails.map(item=>(
                    <SystemStatusCard key={item.id} data={item} />
                ))}
            </div>}
        </div>
    )
  }
}

SystemStatus.defaultProps = {
    user: [{ Email: '' }]
  };

const mapStateToProps = state => ({
    user: state.firestore.ordered.Users,
    SystemDetails: state.firestore.ordered.SystemDetails,
    email: state.firebase.auth.email,
  });

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => {
      if (!props.email) return [];
      return [
        {
          collection: 'Users',
          where: [['Email', '==', props.email]]
        }
      ];
    })
  )(SystemStatus);