import React from 'react'

function RequestorPhoneNo({ id, className, questionNo, next, onAnswer, onSave, isRequired }) {

  const onChange = (e) => {
    onAnswer(e.target.value)
  }

  return (
    <div id={id} data-key="ResidentPhoneNo" data-required={isRequired ? 'true': 'false'} className={["p-3 h-100 d-flex flex-column justify-content-center q-block", className].join(' ')}>
      <p className="mb-3 question">Requestor Phone ?</p>
      <div className="">
        <input
          placeholder="Phone No"
          onChange={onChange}
          className="form-control tf-input"
          onKeyPress={(e) => { if(e.which === 13) next();}}
        />
      </div>
    </div>
  )
}

export default RequestorPhoneNo;