import React, {useState} from "react";
import "./ActivityDetailModal.scss";

export function ActivityDetailModal({ closeCallback, history, comments, uploadComment }) {

  const [currentComment, setCurrentComment] = useState("");

  const handleCommentClick = () => {
    uploadComment(currentComment);
    closeCallback();
  }

  return (
    <div className="activity-detail-modal">
      <div className="d-flex justify-content-between">
        <div className="addTaskHeading">Activity Detail</div>
        <button className="close" onClick={closeCallback} type="submit">
          <img
            src={require("../../assets/images/close.png")}
            alt="cal"
            style={{ height: 16, width: 16 }}
          />
        </button>
      </div>
      <div className="name mb-2 mt-4">History</div>
      {history && history.length ? (
        history.map((item, i) => {
          return (
            <div key={i} className="mt-3">
              <div className="his-comment mb-1">{item.Action}</div>
              <div className="his-time">
                {new Date(item.ActionTime.seconds * 1000).toLocaleTimeString(
                  "en-IN"
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div>No Audits</div>
      )}
      <div className="name mb-2 mt-4">Comments</div>
      {comments && comments.length ? (
        comments.map((item, i) => {
          return (
            <div key={i} className="d-flex flex-column">
              <span className="comment p-2">{item.Comment}</span>
              <span className="comment-summary mt-1 mb-3">
                {item.CommentBy} at{" "}
                {new Date(item.CommentDate.seconds * 1000).toLocaleTimeString(
                  "en-IN"
                )}
              </span>
            </div>
          );
        })
      ) : (
        <div>No Comments</div>
      )}
      <div className="pt-4 position-relative">
        <textarea
          className="adhocTextarea"
          placeholder="Add Comment"
          onChange={(e) => setCurrentComment(e.target.value)}
          value={currentComment}
        ></textarea>
        <button
          type="submit"
          className="position-absolute submitButton"
          onClick={handleCommentClick}
          disabled={!currentComment.length}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
