import React, { useState } from "react";
import Switch from "@material-ui/core/Switch";
import Donut from "../../Common/Charts/Donut/Donut";
import WidgetHeader from "../../Common/WidgetHeader/WidgetHeader";
// import * as d3 from "d3";
import Button from "../../Common/WidgetHeader/Button/Button";
// import createHistory from "../../../../../history";

const data = [
  { status: "good", percent: 70, color: "#01D09F" },
  { status: "average", percent: 20, color: "#FFDB1C" },
  { status: "poor", percent: 10, color: "#FF8D8D" },
];

const classNames = require("classnames");

const InventoryStatus = ({
  classValue,
  editMode,
  toggle,
  show = false,
  order,
}) => {
  const [isVisible, setIsVisible] = useState(show);
  React.useEffect(() => {
    toggle("Inventory Status", show);
  }, []);
  const handleChange = () => {
    setIsVisible(!isVisible);
    toggle("Inventory Status", !isVisible);
  };
  return (
    <>
      <div className={!editMode ? classValue : "edit-dashed"}>
        <div className={classNames("widget", { "full-h": !editMode })}>
          {editMode && (
            <span style={{ marginLeft: 12, marginTop: 5 }}>#{order}</span>
          )}
          <div className="widget-header-container d-flex flex-row justify-content-between">
            <WidgetHeader
              label="Inventory Status"
              description="Description to be defined"
              disabled={false}
            />
            {!editMode ? (
              <Button
                label="View All"
                click={() => {
                  // createHistory.push(`/executive/widget/inventory`);
                }}
              />
            ) : (
              <Switch
                checked={isVisible}
                color="primary"
                onChange={handleChange}
              />
            )}
          </div>

          {!editMode ? (
            <div className={classNames({ disabled: editMode })}>
              <Donut data={data} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default InventoryStatus;
