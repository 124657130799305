import React, { Component } from "react";
import QrReader from "react-qr-reader";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import "./StaffActivitites.css";

class StaffActivity extends Component {
  constructor() {
    super();
    this.state = { result: "No result" };
  }

  componentDidMount = () => {
    const { user, currentApartment } = this.props;
    if (user.length !== 0) {
      if (currentApartment) {
        this.fetchData(currentApartment);
      }
    }
  };

  componentDidUpdate = (prevProps) => {
    const { user, currentApartment } = this.props;
    if (user.length !== 0) {
      if (prevProps.user.length !== 0) {
        if (
          currentApartment &&
          currentApartment !== prevProps.currentApartment
        ) {
          this.fetchData(currentApartment);
        }
      } else if (currentApartment) {
        this.fetchData(currentApartment);
      }
    }
  };

  handleScan = (data) => {
    if (data) {
      this.setState({
        result: data,
      });
    }
  };

  handleError = (err) => {
    console.error(err);
  };

  fetchData = (currentApartment) => {
    const { firestore } = this.props;
    if (this.unsubscribeStaffActivitiesCard) {
      this.unsubscribeStaffActivitiesCard();
    }
    this.unsubscribeStaffActivitiesCard = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "StaffActivitiesCard" }],
      storeAs: "StaffActivitiesCard",
    });
    if (this.unsubscribeTaskDetails) {
      this.unsubscribeTaskDetails();
    }
    this.unsubscribeTaskDetails = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "TaskDetails" }],
      storeAs: "TaskDetails",
    });
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <QrReader
          delay={300}
          facingMode="environment"
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: "100%", height: "80%", paddingTop: "35%" }}
        />
        <div style={{ marginTop: 5 }}>
          <p>{this.state.result}</p>
          {this.state.result !== "No result" ? <p>Success</p> : null}
        </div>
      </div>
    );
  }
}

StaffActivity.defaultProps = {
  user: [{ Email: "" }],
};

const mapStateToProps = (state) => ({
  user: state.firestore.ordered.Users,
  StaffActivitiesCard: state.firestore.ordered.StaffActivitiesCard,
  currentApartment: state.apartment.currentApartment,
});

const mapDispatchToProps = (dispatch) => ({
  setValue: (name) =>
    dispatch({
      type: "SYSTEM_UPDATE",
      name,
    }),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect()
)(StaffActivity);
