import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect, isLoaded, isEmpty, firestoreConnect } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { Button, Container, Input, SubContainer, Links, InactiveWords, Error, Logo, LogoW } from "./SigninStyles";
import createHistory from "../../history";
class Signin extends Component {
  state = {
    email: "",
    password: "",
    loading: false
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    const { email, password } = this.state;
    const { firebase, setErrorMessage } = this.props;
    setErrorMessage(null);
    firebase
      .login({ email, password })
      .then(() => {
        this.setState({
          loading: false
        });
      })
      .catch(() => {
        this.setState({
          loading: false
        });
      });
  };

  Signup = () => {
    const { setErrorMessage } = this.props;
    createHistory.push('/signup1')
    setErrorMessage(null);
  }

  Forget = () => {
    const { setErrorMessage } = this.props;
    createHistory.push('/forget')
    setErrorMessage(null);
  }

  google = () => {
    const { firebase } = this.props;
    if(window.cordova)
    {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithRedirect(provider).then(() => firebase.auth().getRedirectResult()).then((result) => {
        const token = result.credential.accessToken;
        const user = result.user;
      }).catch((error) => {
       console.log("Error Google Signin",error)
      });
    }
    else {
      firebase
        .login({
          provider: 'google',
          type: 'popup',
        })
    }
  }

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.setState({
        loading: true
      });
      const { email, password } = this.state;
      const { firebase } = this.props;
      firebase
        .login({ email, password })
        .then(() => {
          this.setState({
            loading: false
          });
        })
        .catch(() => {
          this.setState({
            loading: false
          });
        });
    }
  }



  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { nextRoute, authError, auth, error, errorType } = this.props;
    if (!isLoaded(auth)) {
      return null;
    }
    if (!isEmpty(auth)) {
      return (
        <Redirect
          to={{
            pathname: nextRoute
          }}
        />
      );
    }
    if (errorType === 'Show Message') {
      createHistory.push('/message')
    }
    else if (errorType === 'Not Registered') {
      createHistory.push('/signup1')
    }



    const errors = authError && authError.toJSON();
    const { email, password, loading } = this.state;

    return (
      <Container className="d-flex flex-column align-items-center">
        <SubContainer className="d-flex flex-column align-items-center">
          <Logo src={require('../../assets/images/logoOnly.png')} alt="logo" />
          <LogoW src={require('../../assets/images/logoWordmarkBlack.png')} alt="logo" />
          <Input
            id="email"
            name="email"
            value={email}
            onChange={this.handleChange}
            placeholder="Email"
            className="mb-4"
            style={{ marginTop: 30 }}
          />
          <Input
            name="password"
            type="password"
            id="password"
            value={password}
            onChange={this.handleChange}
            placeholder="Password"
            onKeyDown={this.onKeyDown}
          />
          <div style={{ height: 50 }}>
            {errors && (
              <Error className="pl-4 pr-4 pt-2">Incorrect Username/Password</Error>
            )}
            {error && (
              <Error className="pl-4 pr-4 pt-2">{error}</Error>
            )}
          </div>

          <Button
            onClick={this.onSubmit}
            disabled={loading}
          >
            {!loading ? (
              <div style={{ color: 'white', fontWeight: 'bold', fontSize: 14, fontFamily: 'Nunito' }}>Login</div>
            ) : (
                <span
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                  aria-hidden="true"
                />
              )}
          </Button>
          <Links className="mt-3" onClick={this.Forget}>Forgot Password?</Links>
          <div className="d-flex mt-3 jus">
            <InactiveWords>New User?<Links onClick={this.Signup}>Register Here</Links></InactiveWords>
          </div>
        </SubContainer>
        <img src={require('../../assets/images/building.png')} className='col-12' alt="Logo" style={{ position: 'absolute', bottom: 0, zIndex: 100 }} />
      </Container>
    );
  }
}

Signin.propTypes = {
  auth: PropTypes.object.isRequired,
  authError: PropTypes.object.isRequired,
  firebase: PropTypes.shape({
    login: PropTypes.func.isRequired
  }).isRequired
};

const mapDispatchToProps = dispatch => ({
  setErrorMessage: (message) => dispatch({ type: "ERROR_VALUE", message })
});

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  email: state.firebase.auth.email,
  authError: state.firebase.authError,
  nextRoute: state.navigation.nextRoute,
  error: state.errorModule.errorMessage,
  errorType: state.errorModule.errorType
});


export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firebaseConnect(),
  firestoreConnect(props => {
    if (!props.email) return [];
    console.log(props)
    return [
      {
        collection: 'Users',
        where: [['Email', '==', props.email]]
      }
    ];
  })
)(Signin);
