import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import Loading from "../common/Loading/Loading";
import { ReadingCard } from "../Cards/ReadingCard/ReadingCard";
import { GraphCard } from "../Cards/GraphCard/GraphCard";
import createHistory from "../../history";
import "./Readings.css";
import * as _ from "lodash";

class Readings extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dailyReadings: [],
      isFirstTime: true,
      isMobile: false,
      isMobileSet: false,
      loading: false,
      collapseGraphs: true,
      activeReadingCard: -1,
      photoURL: require("../../assets/images/defaultDp.png"),
    };
  }

  componentDidMount = () => {
    const {user,location,updateModule,setModule,dailyReadings,dailyReadingCards,currentApartment} = this.props;
    const { PhotoURL } = user[0];
    const ServiceName = decodeURI(location.search.substring(1));
    const width = this.getWidth();
    this.setState({ isMobile: width < 768, isMobileSet: true });
    window.addEventListener("resize", this.reportWindowSize);

    if (user.length !== 0 && currentApartment) {
      setModule(ServiceName);
      this.fetchData(currentApartment);
    }
    if (dailyReadingCards && dailyReadingCards.length) {
      const readingCard =
        dailyReadingCards &&
        dailyReadingCards.filter((e) => e.Name === ServiceName)[0];
      if (readingCard.GraphSettings) this.fetchHistoricalData(currentApartment);
    }

    if (user && PhotoURL) {
      this.getPhotoURL(PhotoURL);
    }
  }

  componentDidUpdate = (prevProps) => {
    const {user, setErrorMessage, clearFirestore, firebase, updateModule,location, dailyReadings, dailyReadingCards,currentApartment} = this.props;
    const { isFirstTime,  dailyReadings: dailyReadingsState, activeReadingCard } = this.state;
    if (user.length === 0) return;
    
    const SearchString = decodeURI(location.search.substring(1));
    const ServiceName = updateModule.name || SearchString;

    if (
      !this.arraysEqual(dailyReadingCards, prevProps.dailyReadingCards) ||
      (dailyReadingCards && prevProps.updateModule.name !== updateModule.name)
    ) {
      const readingCard =
        dailyReadingCards &&
        dailyReadingCards.filter((e) => e.Name === ServiceName)[0];
      if (!readingCard.GraphSettings && activeReadingCard === -1) {
        this.setState({ activeReadingCard: 0 });
      }
      if (!activeReadingCard && activeReadingCard !== 0) {
        this.setState({
          activeReadingCard: readingCard.GraphSettings ? -1 : 0,
          collapseGraphs: true,
        });
      }
      if (readingCard.GraphSettings) this.fetchHistoricalData(currentApartment);
    }
    if (
      dailyReadingCards &&
      (updateModule.name != prevProps.updateModule.name || isFirstTime)
    ) {
      const readingCard =
        dailyReadingCards &&
        dailyReadingCards.filter((e) => e.Name === ServiceName)[0];
      this.setState({
        activeReadingCard: readingCard.GraphSettings ? -1 : 0,
        collapseGraphs: true,
        isFirstTime: false,
      });
    }
  };

  objectsEqual = (o1, o2) =>
    typeof (o1 === "object" && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => this.objectsEqual(o1[p], o2[p]))
      : o1 === o2);

  arraysEqual = (a1 = [], a2 = []) =>
    a1.length === a2.length &&
    a1.every((o, idx) => this.objectsEqual(o, a2[idx]));

  getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  reportWindowSize = (e) => {
    const { isMobile } = this.state;
    if (isMobile) {
      if (e.target.innerWidth > 768) {
        this.setState({ isMobile: false });
      }
    } else if (e.target.innerWidth < 769) {
      this.setState({ isMobile: true });
    }
  };

  fetchData = (currentApartment) => {
    const { firestore, dailyReadingCards, dailyReadings } = this.props;
    const DailyReadingCardsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "DailyReadingCards" }],
      storeAs: "DailyReadingCards"
    };
    const DailyReadingsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "DailyReadings" }],
      storeAs: "DailyReadings"
    };
    if (!dailyReadingCards) {
      if (this.unsubscribeDailyReadingCardsOptions) {
        this.unsubscribeDailyReadingCardsOptions();
      }
      this.unsubscribeDailyReadingCardsOptions = firestore.onSnapshot(
        DailyReadingCardsOptions
      );
    }
    if (!dailyReadings) {
      if (this.unsubscribeDailyReadingsOptions) {
        this.unsubscribeDailyReadingsOptions();
      }
      this.unsubscribeDailyReadingsOptions = firestore.onSnapshot(
        DailyReadingsOptions
      );
    }
  }

  fetchHistoricalData = (currentApartment) => {
    const { firestore, dailyReadingCards, historicalDailyReadings } = this.props;
    const since30Days = new Date(
      new Date(new Date().setDate(new Date().getDate() - 30)).setHours(0, 0, 0)
    );
    const HistoricalDailyReadingsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [
        {
          collection: "HistoricalDailyReadings",
          where: [
            ["UpdatedTime", ">=", firestore.Timestamp.fromDate(since30Days)],
          ],
        },
      ],
      orderBy: ["UpdatedTime"],
      storeAs: `HistoricalDailyReadings`
    };
    if (!historicalDailyReadings) {
      if (this.unsubscribeHistoricalDailyReadingsOptions) {
        this.unsubscribeHistoricalDailyReadingsOptions();
      }
      this.unsubscribeHistoricalDailyReadingsOptions = firestore.onSnapshot(
        HistoricalDailyReadingsOptions
      );
    }
  }

  onClickAccordian = (type, name) => {
    const { dailyReadings, location, updateModule } = this.props;
    const { collapseGraphs, activeReadingCard } = this.state;
    if (type === "readings") {
      const SearchString = decodeURI(location.search.substring(1));
      const ServiceName = updateModule.name || SearchString;
      const _readings = dailyReadings.filter(
        (e) => e.ServiceName === ServiceName
      );
      const readings = _.groupBy(_readings, (r) => r.ModuleName);
      const index = Object.keys(readings).findIndex((r) => r === name);
      this.setState({
        collapseGraphs: false,
        activeReadingCard: activeReadingCard === index ? -1 : index
      });
    }
    if (type === "graphs") {
      this.setState({ collapseGraphs: !collapseGraphs, activeReadingCard: -1 });
    }
  }

  getPhotoURL = async (path) => {
    const { firebase } = this.props;
    const storage = firebase.storage();
    const url = await storage.ref().child(path).getDownloadURL();
    this.setState({ photoURL: url });
  }

  showSideNav = () => {
    setTimeout(() => this.props.openSideBar(false));
  };

  showMobileNavbar() {
    const { isMobile, photoURL } = this.state;

    if (isMobile) {
      return (
        <nav className="navbar-mobile">
          <a className="menu-button mr-2" onClick={this.showSideNav}>
            <i className="icon-menu" />
          </a>
          <img
            alt="logo"
            src={require("../../assets/images/logoOnly.png")}
            style={{ height: "1.65rem" }}
          />
          <div className="float-right ml-auto d-flex align-items-center">
            <img
              alt="logo"
              src={photoURL}
              style={{ height: 30, width: 30, borderRadius: 30 }}
              onClick={() => createHistory.push(`/profile`)}
            />
          </div>
        </nav>
      );
    }
  }

  updateReadings = (cardId, data) => {
    const { firestore, user, currentApartment } = this.props;
    const { DisplayName } = user[0];
    data.map(async (doc) => {
      const updateDoc = {
        LastUpdatedBy: DisplayName,
        LastUpdatedDate: firestore.Timestamp.fromDate(new Date())
      };
      if (!doc.ParamValueRaw && !doc.ParamValue && doc.ParamValue !== 0 && doc.ParamValueRaw !== 0) return false;

      if (doc.hasOwnProperty('ParamValueRaw')) {
        updateDoc.ParamValueRaw = !doc.ParamValueRaw instanceof firestore.Timestamp ? doc.ParamValueRaw.toString() : doc.ParamValueRaw;
      } else {
        updateDoc.ParamValue = doc.ParamValue.toString();
      }
      
      if (doc.hasOwnProperty('Description')) updateDoc.Description = doc.Description; 

      firestore.update(
        {
          collection: "apartments",
          doc: currentApartment,
          subcollections: [{ collection: "DailyReadings", doc: doc.id }]
        },
        updateDoc);
      firestore.update(
        {
          collection: "apartments",
          doc: currentApartment,
          subcollections: [{ collection: "DailyReadingCards", doc: cardId }]
        }, { LastUpdatedBy: DisplayName });
    });
  }

  getReadingHistory = (id) => {
    const { firestore, user, currentApartment } = this.props;
    
    const since15Days = new Date(new Date(new Date().setDate(new Date().getDate() - 15)).setHours(0, 0, 0));
    return firestore.get({
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [
        { collection: 'DailyReadings', doc: id, subcollections: [
          { collection: 'DRLocalHistory', where: [
          ['LastUpdatedDate', '>=', firestore.Timestamp.fromDate(since15Days)]
          ]}
        ]}
      ],
      orderBy: ['LastUpdatedDate', 'desc'],
      limit: 30,
      storeAs: 'DRLocalHistory'
    });
  }

  savedrHistory = (rid, doc) => {
    const { firestore, user, currentApartment } = this.props;
    const { DisplayName } = user[0];
    firestore.update({
      collection: 'apartments',
      doc: currentApartment,
      subcollections: [
        { collection: 'DailyReadings', doc: rid, subcollections: [
          { collection: 'DRLocalHistory', doc: doc.id }
        ]}
      ]
    }, {
      Description: doc.Description, LastUpdatedBy: DisplayName, IsEditable: false
    });
  }

  getThemeColor = () => {
    const {AppartmentDetails } = this.props;
    const {isMobile  } = this.state;
    if(isMobile && AppartmentDetails[0].Header_Theme_Color)
    {
      return AppartmentDetails[0].Header_Theme_Color
    }
    return null;
  }

  render() {
    const { user, dailyReadingCards, dailyReadings, historicalDailyReadings, location, updateModule, drLocalHistory, AppartmentDetails } = this.props;
    const { loading, isMobile, activeReadingCard, collapseGraphs, isMobileSet } = this.state;
    if (loading) return <Loading showLogo="false" />

    const SearchString = decodeURI(location.search.substring(1));
    const ServiceName = updateModule.name || SearchString;
    const readingCard =
      dailyReadingCards &&
      dailyReadingCards.filter((e) => e.Name === ServiceName)[0];
    const _readings =
      dailyReadings &&
      dailyReadings.filter((e) => e.ServiceName === ServiceName);
    const readings = _.groupBy(_readings, (r) => r.ModuleName);
    // console.log(readings);

    return (
      <div id="readings">
        {isMobileSet &&
        readingCard &&
        readings &&
        Object.keys(readings).length ? (
          <>
            <div
              className={[
                "readings-header",
                isMobile ? "d-flex flex-column" : "",
              ].join(" ")}
              style={{ backgroundColor: this.getThemeColor() }}
            >
              <h4 className="order-2">{readingCard && readingCard.Name}</h4>
              <p className="text-muted mb-0 mr-2">
                <span>Daily Log</span>{" "}
                {!isMobile && `/ ${readingCard && readingCard.Name}`}
              </p>
            </div>
            <div className="row readings-body">
              {readingCard.GraphSettings && (
                <div className="col-lg-4">
                  <div
                    className="readings mb-4"
                    style={{
                      gridTemplateColumns:
                        "repeat(auto-fit, minmax(280px, 1fr))",
                    }}
                  >
                    <GraphCard
                      settings={readingCard && readingCard.GraphSettings}
                      historyReadings={historicalDailyReadings}
                      onClickCollapse={this.onClickAccordian} isCollapsed={collapseGraphs} />
                  </div>
                </div>)}
                <div className="col">
                  <div className="readings mb-4" style={{ gridTemplateColumns: `repeat(auto-fit, minmax(280px, ${readingCard.GraphSettings || isMobile ? '1fr' : '0.35fr'}))` }}>
                    {
                      Object.keys(readings).map((module, i) => (
                        <ReadingCard
                          readings={readings[module]} readingCard={readingCard} savedrHistory={this.savedrHistory}
                          onClickCollapse={this.onClickAccordian} getReadingHistory={this.getReadingHistory}
                          onUpdate={this.updateReadings} activeCard={activeReadingCard === i}
                          moduleName={module} isMobile={isMobile} key={i} drHistory={drLocalHistory} />
                      ))
                    }
                  </div>
                </div>
              {/* <div className="col">
                <div
                  className="readings mb-4"
                  style={{
                    gridTemplateColumns: `repeat(auto-fit, minmax(280px, ${
                      readingCard.GraphSettings || isMobile ? "1fr" : "0.35fr"
                    }))`,
                  }}
                >
                  {Object.keys(readings).map((module, i) => (
                    <ReadingCard
                      readings={readings[module]}
                      readingCard={readingCard}
                      onClickCollapse={this.onClickAccordian}
                      onUpdate={this.updateReadings}
                      activeCard={activeReadingCard === i}
                      moduleName={module}
                      isMobile={isMobile}
                      key={i}
                    />
                  ))}
                </div>
              </div> */}
            </div>
          </>
        ) : (
          <Loading showLogo="false" />
        )}
      </div>
    );
  };
}

Readings.propTypes = {
  user: PropTypes.arrayOf(
    PropTypes.shape({
      Email: PropTypes.string,
    })
  ),
};

Readings.defaultProps = {
  user: [{ Email: "" }],
};

const mapDispatchToProps = (dispatch) => ({
  clearFirestore: () => dispatch({ type: "@@reduxFirestore/CLEAR_DATA" }),
  setErrorMessage: (message, errorType) =>
    dispatch({ type: "ERROR_VALUE", message, errorType }),
  setModule: (name) => dispatch({ type: "UPDATE_MODULE", payload: name }),
  openSideBar: (value) => dispatch({ type: "SIDEBAR_OPEN", payload: value }),
});

const mapStateToProps = (state) => ({
  email: state.firebase.auth.email,
  user: state.firestore.ordered.Users && state.firestore.ordered.Users,
  dailyReadingCards: state.firestore.ordered.DailyReadingCards,
  dailyReadings: state.firestore.ordered.DailyReadings,
  historicalDailyReadings: state.firestore.ordered.HistoricalDailyReadings,
  drLocalHistory: state.firestore.ordered.DRLocalHistory,
  updateModule: state.updateModule,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  currentApartment: state.apartment.currentApartment,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(),
  firebaseConnect(),
  withRouter
)(Readings);
