import React, { Component, useState, useEffect, PureComponent } from 'react';
import Chips, { Chip } from 'react-chips';
import { Modal, Button } from 'react-bootstrap';
import SearchComponent from '../../common/SearchComponent/SearchComponent';

export default class UserListCard extends Component {
  state = {
    activeEditIndex: -1,
    modalShow: false,
    searchQuery: '',
    users: this.props.users
  }

  componentDidUpdate(prevProps) {
    const {users} = this.props;
    if(users.length !== prevProps.users.length)
    {
      this.setState({users})
    }
  }
  

  onChangeChips = chips => {
    this.setState({ chips });
  }

  _onUpdateUser = (userId, data) => {
    const { onUpdateUser } = this.props;
    const {users} = this.state
    const tmpUser = [...users];
    const index = tmpUser.findIndex(u => u.id === userId)
    if(index>=0) tmpUser.splice(index,1,{...tmpUser[index],...data, is_approved: true})
    this.setState({users: tmpUser})
    onUpdateUser(userId, data);
  }

  onClickDelete = (user) => {
    this.setState({modalShow: true, selectedUser: user});
  }

  onConfirmDelete = (id) =>{
    const { onDelete } = this.props;
    const {users} = this.state
    const tmpUser = [...users];
    const index = tmpUser.findIndex(u => u.id===id)
    if(index>=0) tmpUser.splice(index,1)
    this.setState({users: tmpUser, modalShow: false, selectedUser: null})
    onDelete(id);
  }

  render() {
    const {  roles, isManager, isMcMember, getPhotoURL, isMobile } = this.props;

    const { searchQuery,users } = this.state;
    let sortedUsers = [...users].sort((a, b) => a.DisplayName.localeCompare(b.DisplayName));
    sortedUsers = sortedUsers.sort((a, b) => (a.is_approved === b.is_approved) ? 0 : a.is_approved ? 1 : -1);
    if (searchQuery) {
      sortedUsers = sortedUsers.filter(u => u.DisplayName.search(new RegExp(searchQuery, 'i')) !== -1);
    }
    const roleNames = roles.map(r => r.RoleDisplayName).filter(r => r);

    return (
      <div className="settings-card mt-4 mt-md-0">
        <div className="settings-card-header">
          <h5 className="mb-0 font-weight-bold">Manage User{`${isMobile ? `s` :` Profiles`}`}
            <SearchComponent placeholder="Search user..." onChange={(value) => this.setState({searchQuery: value})} />
          </h5>
        </div>
        <div className="settings-card-body">
          { sortedUsers && sortedUsers.map((user, i) => (
            <UserCard user={user} userroles={user.Roles} roleNames={roleNames} key={user.id} onDelete={this.onClickDelete}
              isManager={isManager} isMcMember={isMcMember} onUpdateUser={this._onUpdateUser}
              staffPhoto={user.PhotoURL} getPhotoURL={getPhotoURL} onConfirmDelete={(id)=> this.onConfirmDelete(id)}  />
          ))}
        </div>
      </div>
    )
  }
}


const UserCard = ({ user, userroles, roleNames, onUpdateUser, onDelete, isManager, isMcMember, staffPhoto, getPhotoURL,onConfirmDelete }) => {
  const [editMode, setEditMode] = useState(false);
  const [roles, setRoles] = useState([]);
  const [photoURL, setPhotoURL] = useState(null);
  const [showConfirm, setConfirm] = useState(false)

  const getProfileURL = async (photo) => {
    if (photoURL) return;
    const url = await getPhotoURL(photo);
    setPhotoURL(url);
  }

  useEffect(() => {
    getProfileURL(staffPhoto);
  }, [staffPhoto]);

  useEffect(() => {
    if (user.is_approved) setRoles(userroles);
    else setRoles([]);
  }, [userroles]);

  const onChangeChips = (chips) => {
    setRoles(chips);
  }

  const save = (data) => {
    onUpdateUser(user.id, data);
    setEditMode(false)
  }

  const cancelEdit = () => {
    if (user.is_approved) setRoles(userroles);
    else setRoles([]);
    setEditMode(false);
  }

  const onClickDelete = () => {
    onDelete(user)
  }

  const checkUpdateAccess=(u)=>{
    if(isMcMember) return true
    if(isManager && !u.is_mc_member) return true
    return false
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center">
          { user.PhotoURL && photoURL ?
            <img alt="logo" src={photoURL}
              className="staffProfileLogo mr-2" style={{ height: 50, width: 50, borderRadius: '50%', objectFit: 'cover' }} />
            :
            <div className="mr-2">
              <div className="d-flex align-items-center justify-content-center altText">{user.AltText}</div>
            </div>
          }
          <div>
            <h6 className="font-weight-bold mb-0">{user.DisplayName ? user.DisplayName : '-'}</h6>
            <p className="card-subtitle">
              <span><a href={`mailto: ${user.Email}`}>{user.Email}</a></span>
              <span>{user.Email && user.PhoneNo ? ', ' : ''}</span>
              <span><a href={`tel: ${user.PhoneNo}`}>{user.PhoneNo}</a></span>
            </p>
          </div>
        </div>
        <hr />

        <div className="card-text roles">
          <span className={`${!user.is_approved ? ' text-danger font-weight-bold' : ''}`}>
            {user.is_approved ? roles.join(', ') : 'Pending Approval'}
          </span>
        </div>

        {checkUpdateAccess(user) && <div className="buttons">
          { !editMode && <button onClick={() => setEditMode(true)}><i className="icon-pencil" /></button> }
        </div>}

        <UserEditModal show={editMode} onHide={() => setEditMode(false)} onSave={save}
          onClickDelete={()=>setConfirm(true)} {...{user, photoURL, roleNames, userroles}} />

           <Modal centered show={showConfirm} onHide={() => setConfirm(false)}>
          <Modal.Header className="tb-modal-header" closeButton>
            <Modal.Title className="tb-modal-title" as="h5">Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body className="tb-modal-body">
            {user && <p>Do you really want to delete {user.DisplayName} ?</p>}
          </Modal.Body>
          <Modal.Footer className="tb-modal-footer">
            <Button size="sm" variant="light" onClick={() => setConfirm(false)}>No</Button>
            {user && <Button size="sm" onClick={()=>{
              setConfirm(false);
              setEditMode(false);
              onConfirmDelete(user.id)
            }}>Yes</Button>}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

const UserEditModal = (props) => {
  const { user, photoURL, roleNames, userroles } = props;
  const { show, onHide } = props;
  const [userData, setUserData] = useState({ });

  useEffect(() => {
    const userdata = {
      PhoneNo: user.PhoneNo, 
      Email: user.Email
    };
    if (user.is_approved) userdata.Roles = userroles;
    setUserData(userdata);
  }, [user]);

  return (
    <Modal className="profile" centered show={show} onHide={onHide}>
      <Modal.Header className="tb-modal-header flex-column align-items-center pb-3">
        <div className="profile-img d-flex justify-content-center">
          {user.PhotoURL && photoURL ?
            <img alt="logo" src={photoURL}
              className="userProfileLogo" style={{ height: 100, width: 100, borderRadius: '50%', objectFit: 'cover' }} />
            :
            <div className="d-flex align-items-center justify-content-center altText" style={{ height: 100, width: 100, fontSize: '2rem' }}>{user.AltText}</div>
          }
        </div>
        <h6 className="font-weight-bold mt-3 mb-0">{user.DisplayName}</h6>
      </Modal.Header>
      <Modal.Body className="tb-modal-body">
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="" className="col-form-label">Email</label>
            <p className="mb-0">{userData.Email}</p>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="" className="col-form-label">Phone</label>
            <input type="text" defaultValue={userData.PhoneNo} onChange={(e) => setUserData({...userData, PhoneNo: e.target.value })}/>
          </div>
          <div className="form-group col-md-12 roles">
            <label htmlFor="" className="col-form-label">Service Area <span className="text-color red">*</span></label>
            <Chips value={userData.Roles} onChange={(roles) => setUserData({...userData, Roles: roles })} suggestions={roleNames}
              chipTheme={{ chip: { background: '#3b3b3b', color: '#fff', borderRadius: '25rem', padding: '0.25rem 0.5rem' } }}
              fromSuggestionsOnly uniqueChips placeholder="Start typing the role"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="tb-modal-footer">
        <a className="text-danger mr-auto btn-del cursor-pointer" onClick={props.onClickDelete}>Delete</a>
        <Button size="sm" variant="light" onClick={props.onHide}>Cancel</Button>
        <Button size="sm" disabled={!userData.Roles || !userData.Roles.length} onClick={props.onSave.bind(this, userData)}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}
