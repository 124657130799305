/* eslint-disable camelcase */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import { withRouter } from "react-router-dom";
import lastUpdatedTime from "../common/Timeago";
import Loading from "../common/Loading/Loading";
import createHistory from "../../history";
import "./ExpandSection.css";
import DashboardCard from "../InitialScreen/DashboardCard";

class DailyLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
    const { user, match, currentApartment } = this.props;
    const width = this.getWidth();
    this.expanded = match.params.expand;
    if (width > 768) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    window.addEventListener("resize", this.reportWindowSize);
    this.setState({});
    if (user.length !== 0) {
      if (currentApartment) {
        this.fetchData(currentApartment);
      }
    }
  };

  componentDidUpdate = (prevProps) => {
    const { currentApartment,ApartmentAccessInfo } = this.props;
    if (currentApartment && currentApartment !== prevProps.currentApartment) {
      this.fetchData(currentApartment, true);
    }

    if(ApartmentAccessInfo && ApartmentAccessInfo !== prevProps.ApartmentAccessInfo){
      this.fetchData(currentApartment, true);
    }
  };

  getWidth = () =>
    Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );

  reportWindowSize = (e) => {
    if (this.isMobile) {
      if (e.target.innerWidth > 768) {
        this.isMobile = false;
        this.setState({ showButton: false });
      }
    } else if (e.target.innerWidth < 768) {
      this.isMobile = true;
      this.setState({ showButton: true });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.reportWindowSize);
  };

  onNavigate = (item) => {
    const { setModule } = this.props;
    createHistory.push(`${item.Routing}?${encodeURI(item.Name)}`);
    setModule(item.Name);
  };

  renderDailyLogCards = (dailyReadingCards) => (
      <div id="daily_reading">
        <div className="d-flex mt-3 flex-wrap">
          {dailyReadingCards.map((item, i) => (<div key={i} className="column">
              <DashboardCard item={item} onNavigate={this.onNavigate} className="py-md-4 pt-3 py-3 mb-3 mb-md-4">
                <span className="icon"><i className={["icon-default-daily", `icon-${item.IconName}`].join(" ")}/></span>
                <span className="label">{item.Name}</span>
                <span className="last_updated"> {lastUpdatedTime(item.LastUpdatedTime)}</span>
                {item.IndicatorInfo && (<span className="last_updated"
                    style={{ position: "absolute", marginTop: this.isMobile ? 74 : 100,
                    }}>{item.IndicatorInfo}</span>)}
              </DashboardCard>
            </div>))}
        </div>
      </div>
    );

  renderSystemCards = (systemDetails) => (
            <div id='system_health'>
                <div className="d-flex mt-3 flex-wrap">
                    { systemDetails.map((item, i) => ( <div key={i} className="column">
                        <DashboardCard item={item} onNavigate={this.onNavigate} className={`align-items-baseline py-md-4 pt-3 py-3 mb-3 mb-md-4 ${ this.isMobile ? 'pl-2': 'pl-3' }`}>
                            <span className="label text-left">{item.DisplayName || item.Name}</span>
                            {item.InspectionDue ? <i className="icon-audit" /> : null}
                            <div className='d-flex flex-column align-items-start'>
                                <span className="status text-left">{item.Status}</span>
                                <span className="last_updated text-left">{lastUpdatedTime(item.LastUpdatedTime)}</span>
                            </div>
                            <div className={ this.isMobile ? 'd-none m-0' : 'd-block updatedBy m-0'}>
                                <span>{item.LastUpdatedBy}</span>
                            </div>
                        </DashboardCard>
                    </div> )) }
                </div>
            </div>
        )  

  
  renderStaffActivtyCards = (staffActivities) => {
        const { currentApartment, ApartmentAccessInfo, user } = this.props;
        let accessInfo = null;
        if(ApartmentAccessInfo.length){
          const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
         if(selectedApartment){
           accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
         }
        }
        const { Roles, is_mc_member, is_manager } = accessInfo || {}
        
        return (
            <div id="staff_activities">
                <div className="d-flex mt-3 flex-wrap">
                    { staffActivities.map((item, i) => { 
                        if (item.CardType === 'Incidents') {
                            let route; let count = 0;
                            if (is_manager || is_mc_member) {
                                count = (item.OpenTickets && item.OpenTickets.Default) || 0;
                                route = (item.Routing && item.Routing.Default) || '#';
                            } else if ((Roles|| []).some(role => item.Roles && item.Roles.includes(role))) {
                                const index = Roles.findIndex(r => Object.keys(item.Routing || {}).includes(r));
                                if (index >= 0) {
                                    route = item.Routing[Roles[index]];
                                    count = Object.keys(item.OpenTickets || {}).reduce((cnt, key) => {
                                        if (Roles.includes(key)) cnt += item.OpenTickets[key];
                                        return cnt;
                                    }, 0);
                                } else {
                                    route = item.Routing.Default;
                                    count = item.OpenTickets.Default
                                }
                            } else {
                                route = (item.Routing && item.Routing.Default) || '#';
                                count = Object.keys(item.OpenTickets || {}).reduce((cnt, key) => {
                                    if (key !== 'Default') cnt += item.OpenTickets[key];
                                    return cnt;
                                }, 0);
                            }
                            return (
                                <DashboardCard item={item} onNavigate={() => createHistory.push(route)} className="py-3 px-md-4 px-3 mb-md-4 mb-3">
                                <div className='tasks w-100'>
                                    <span title={item.Name} className="d-block label text-left text-truncate">{item.Name}
                                        <span className="text-primary float-right"><i className={`icon-${item.IconName}`} /></span>
                                    </span>
                                    <div className="text-left">
                                        <span className="total d-block text-left mt-4">{count} Open Tickets</span>
                                    </div>
                                </div>
                            </DashboardCard>
                            );
                        }
                        if (item.CardType === 'Tasks') {
                            return (
                                <div key={i} className="column col-50">
                                    <DashboardCard item={item} onNavigate={this.onNavigate} className="py-3 px-md-4 px-3 mb-md-4 mb-3">
                                        <div className='tasks w-100'>
                                            <span title={item.Name} className="d-block label text-left text-truncate">{item.Name}
                                                <span className="text-primary float-right"><i className={`icon-${item.IconName}`} /></span>
                                            </span>
                                            <div className="text-left">
                                                <span className="total d-block text-left mt-4">{item['Total Tasks']} Tasks</span>
                                                <span className="completed d-block text-left">{item.Completed} Completed</span>
                                                <span className="completed d-block text-left" style={{fontSize:'0.7rem', color:'#ff6a6a'}}>{item.Rejected ? item.Rejected : 0} Defaulted</span>
                                            </div>
                                        </div>
                                    </DashboardCard>
                                </div>
                            );
                        }
                    }) }
                </div>
              </div>
        )
  }
  
  renderHeader = () => (
      <div className="section d-flex">
        <button type="submit" className="buttonrmstyle mb-1" onClick={() => window.history.back()} style={{ marginTop: -15 }}>
          <img
            className="back-close" alt="back" src={require("../../assets/images/prev-arrow.svg")}
          />
        </button>
        <div>
          <h5 className="section_header mb-0">
            {this.expanded === "daily_log" && "Daily Log"}
            {this.expanded === "system_health" && "System Health"}
            {this.expanded === "staff_activities" && "Staff Activities"}
          </h5>
          <h4 className="section_desc mb-0">
            {this.expanded === "daily_log" && "Check Listed Items Status"}
            {this.expanded === "system_health" && "Check Status and Send Notifications"}
            {this.expanded === "staff_activities" && "Check Status and Make Actions"}
          </h4>
        </div>
      </div>
    );

  renderCards = () => {
    const {
      dailyReadingCards,
      AppartmentDetails,
      systemDetails,
      staffActivities,
    } = this.props;
    const {
      has_daily_readings,
      has_system_status,
      has_staff_activities,
    } = AppartmentDetails[0];

    if (
      (this.expanded !== "daily_log" ||
        !has_daily_readings ||
        !dailyReadingCards) &&
      (this.expanded !== "system_health" ||
        !has_system_status ||
        !systemDetails) &&
      ((this.expanded !== "staff_activities" && !has_staff_activities) ||
        !staffActivities)
    ) {
      return <Loading showLogo="false" />;
    }

    if (this.expanded === "daily_log" && dailyReadingCards.length > 0) {
      return this.renderDailyLogCards(dailyReadingCards);
    } if (this.expanded === "system_health") {
      return this.renderSystemCards(systemDetails);
    } if (this.expanded === "staff_activities") {
      return this.renderStaffActivtyCards(staffActivities);
    }
  };

  showSideNav = () => {
    setTimeout(() => this.props.openSideBar(false));
  };

  getThemeColor = () => {
    const { AppartmentDetails } = this.props;
    if (this.isMobile && AppartmentDetails[0].Header_Theme_Color) {
      return AppartmentDetails[0].Header_Theme_Color;
    }
    return null;
  };

  showMobileNav = () => {
    const { user } = this.props;
    const { avatarUrl } = user[0];

    if (this.isMobile) {
      return (
        <>
          <nav className="navbar-mobile">
            <a className="menu-button mr-2" onClick={this.showSideNav}>
              <i className="icon-menu" />
            </a>
            <img
              alt="logo"
              src={require("../../assets/images/logoOnly.png")}
              style={{ height: "1.65rem" }}
            />
            <div className="float-right ml-auto d-flex align-items-center">
              <img
                alt="logo"
                src={avatarUrl || require("../../assets/images/default_dp.svg")}
                style={{ height: 30 }}
                onClick={() => createHistory.push(`/profile`)}
              />
            </div>
          </nav>
          <div
            id="viewall-nav"
            style={{ backgroundColor: this.getThemeColor() }}
          >
            <h5 className="my-0">
              {this.expanded === "daily_log" && "Daily Log"}
              {this.expanded === "system_health" && "System Health"}
              {this.expanded === "staff_activities" && "Staff Activities"}
            </h5>
            <p className="mb-0 text-muted">
              {this.expanded === "daily_log" && "Check Listed Items Status"}
              {this.expanded === "system_health" && "Check Status and Send Notifications"}
              {this.expanded === "staff_activities" && "Check Status and Make Actions"}
            </p>
          </div>
        </>
      );
    }
  };

  fetchData = (currentApartment, isApartmentChanged = false) => {
    const {
      firestore,
      AppartmentDetails,
      DailyReadingCards,
      systemDetails,
      staffActivities,
      ApartmentAccessInfo,
      user
    } = this.props;

    let accessInfo = null;
        if(ApartmentAccessInfo.length){
          const selectedApartment = ApartmentAccessInfo.find(({id})=> id === currentApartment)
         if(selectedApartment){
           accessInfo = selectedApartment.users.find(({Email}) => Email === user[0].Email)
         }
        }
    const { Roles, has_all_access } = accessInfo || { Roles:[]}
    
    const DailyReadingCardOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "DailyReadingCards" }],
      storeAs: "DailyReadingCards",
    };
    const SystemDetailsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "SystemDetails" }],
      storeAs: "SystemDetails",
    };
    const StaffActivitiesOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "StaffActivitiesCard" }],
      storeAs: "StaffActivities",
    };
    if (isApartmentChanged) {
      if (this.expanded === "daily_log") {
        if (this.unsubscribeDailyReadingCardOptions) {
          this.unsubscribeDailyReadingCardOptions();
        }
        this.unsubscribeDailyReadingCardOptions = firestore.onSnapshot(
          DailyReadingCardOptions
        );
      } else if (this.expanded === "system_health") {
        if (this.unsubscribeSystemDetailsOptions) {
          this.unsubscribeSystemDetailsOptions();
        }
        this.unsubscribeSystemDetailsOptions = firestore.onSnapshot(
          SystemDetailsOptions
        );
      } else if (this.expanded === "staff_activities") {
        if (this.unsubscribeStaffActivities) {
          this.unsubscribeStaffActivities();
        }
        this.unsubscribeStaffActivities = firestore.onSnapshot(
          StaffActivitiesOptions
        );
      }
    } else if (this.expanded === "daily_log") {
        if (AppartmentDetails && AppartmentDetails[0].has_daily_readings) {
          if (!has_all_access && Roles && Roles.length) {
            DailyReadingCardOptions.where = [
              ["Roles", "array-contains-any", Roles],
            ];
          }
          if (!DailyReadingCards) {
            if (this.unsubscribeDailyReadingCardOptions) {
              this.unsubscribeDailyReadingCardOptions();
            }
            this.unsubscribeDailyReadingCardOptions = firestore.onSnapshot(
              DailyReadingCardOptions
            );
          }
        }
      } else if (this.expanded === "system_health") {
        if (AppartmentDetails && AppartmentDetails[0].has_system_status) {
          if (!has_all_access && Roles && Roles.length) {
            SystemDetailsOptions.where = [
              ["Roles", "array-contains-any", Roles],
            ];
          }
          if (!systemDetails) {
            if (this.unsubscribeSystemDetailsOptions) {
              this.unsubscribeSystemDetailsOptions();
            }
            this.unsubscribeSystemDetailsOptions = firestore.onSnapshot(
              SystemDetailsOptions
            );
          }
        }
      } else if (this.expanded === "staff_activities") {
        if (AppartmentDetails && AppartmentDetails[0].has_staff_activities) {
          if (!has_all_access && Roles && Roles.length) {
            StaffActivitiesOptions.where = [
              ["Roles", "array-contains-any", Roles],
            ];
          }
          if (!staffActivities) {
            if (this.unsubscribeStaffActivities) {
              this.unsubscribeStaffActivities();
            }
            this.unsubscribeStaffActivities = firestore.onSnapshot(
              StaffActivitiesOptions
            );
          }
        }
      }
  };

  render() {
    return (
      <div className="expand_section" id="expand_section">
        {this.showMobileNav()}
        <div className="expand_body">
          {!this.isMobile && this.renderHeader()}
          {this.renderCards()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openSideBar: (value) => dispatch({ type: "SIDEBAR_OPEN", payload: value }),
  setModule: (name) => dispatch({ type: "UPDATE_MODULE", payload: name }),
});

const mapStateToProps = (state) => ({
  user: state.firestore.ordered.Users,
  dailyReadingCards: state.firestore.ordered.DailyReadingCards,
  // userAccessInfo: state.firestore.ordered.UserAccessInfo,
  systemDetails: state.firestore.ordered.SystemDetails,
  staffActivities: state.firestore.ordered.StaffActivities,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  currentApartment: state.apartment.currentApartment,
  ApartmentAccessInfo: state.apartment.ApartmentAccessInfo
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(),
  firebaseConnect(),
  withRouter
)(DailyLog);
