/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from "react";

import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

import VBarChart from "../../../Common/Charts/VBarChart/VBarChart";

const CommunityIncidents = (props) => {
  const {
    firestore,
    consolidated,
    community_incidents_summary_ed,
    selectedZone,
  } = props;

  const [communitySummary, setCommunitySummary] = useState([]);
  const [configCommunityIncidents, setConfigCommunityIncidents] = useState(
    null
  );
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    if (consolidated && consolidated.length) {
      firestore
        .get({
          collection: "consolidated",
          doc: consolidated[0].id,
          // subcollections: [
          //   {
          //     collection: "CommunityIncidents_ED",
          //     doc: "community_incidents_summary_ed",
          //   },
          // ],
          storeAs: "community_incidents_summary_ed",
        })
        .then((doc) => {
          // const { StatusToLabel } = doc.data()
          // let StatusToLabel = ''
          // if(doc.data().StatusToLabel.hasOwnProperty("CommunityIncidents_ED")){
          //    StatusToLabel = doc.data().StatusToLabel.CommunityIncidents_ED
          // }else if(doc.data().StatusToLabel.hasOwnProperty("Default")){
          //     StatusToLabel = doc.data().StatusToLabel.CommunityIncidents_ED
          //  }

          const {StatusToLabel : Label} = doc.data()
          let StatusToLabel = ''
          if(Label.hasOwnProperty("CommunityIncidents_ED")){
             StatusToLabel = Label.CommunityIncidents_ED
          } else if(Label.hasOwnProperty("Default")){
              StatusToLabel = Label.Default
          }

          if (isMounted.current) {
            setConfigCommunityIncidents({
              StatusToLabel,
              route: "/executive/widget/incident",
            });
          }
        });
    }
    return () => {
      isMounted.current = false;
    };
    // Fetch config like Label for graph
  }, [consolidated]);

  useEffect(() => {
    // Last 7 Days from yesterday
    const dateTo = moment().toDate();
    const dateFrom = moment().subtract(7, "d").toDate();
    const dateToString = moment();
    const dateFromString = moment().subtract(7, "d");
    const community = [];
    while (dateFromString.isSameOrBefore(dateToString)) {
      community.push({ date: dateFromString.format("DD/MM") });
      dateFromString.add(1, "days");
    }
    if (community_incidents_summary_ed && consolidated && consolidated.length) {
      firestore
        .get({
          collection: "consolidated",
          doc: consolidated[0].id,
          subcollections: [
            {
              collection: "CommunityIncidents_ED",
              doc: "community_incidents_summary_ed",
              subcollections: [
                {
                  collection: "community_incidents_summary_details_ed",
                  where: [
                    ["Date", ">=", dateFrom],
                    ["Date", "<=", dateTo],
                  ],
                },
              ],
            },
          ],
          storeAs: "community_summary_details_ed",
        })
        .then((querySnapshot) => {
          const {StatusToLabel : Label} = community_incidents_summary_ed[0] || {}
          let StatusToLabel = ''
          if(Label && Label.hasOwnProperty("CommunityIncidents_ED")){
             StatusToLabel = Label.CommunityIncidents_ED
          }else if(Label && Label.hasOwnProperty("Default")){
              StatusToLabel = Label.Default
          }
          const { Amber, Red } = StatusToLabel || {Amber: "N/A", Red: "N/A"}
          
          querySnapshot.forEach((doc) => {
            const findIndex = community.findIndex(
              ({ date }) =>
                date === moment(doc.data().Date.toDate()).format("DD/MM")
            );
            if (selectedZone && selectedZone !== "All") {
              community[findIndex] = {
                date: moment(doc.data().Date.toDate()).format("DD/MM"),
                [Amber]:
                  doc.data().ZoneIssues && doc.data().ZoneIssues[selectedZone]
                    ? doc.data().ZoneIssues[selectedZone].Amber
                    : 0,
                [Red]:
                  doc.data().ZoneIssues && doc.data().ZoneIssues[selectedZone]
                    ? doc.data().ZoneIssues[selectedZone].Red
                    : 0,
              };
            }

            if (!selectedZone || selectedZone === "All") {
              const { ZoneIssues } = doc.data();
              const zoneAll = Object.keys(ZoneIssues || {}).map((key) => ({
                  Amber: ZoneIssues[key].Amber,
                  Red: ZoneIssues[key].Red,
                }));

              const Ambers = zoneAll
                .map((n) => n.Amber)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
              const Reds = zoneAll
                .map((n) => n.Red)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

              community[findIndex] = {
                date: moment(doc.data().Date.toDate()).format("DD/MM"),
                [Amber]: Ambers,
                [Red]: Reds,
              };
            }
            // community[findIndex] = {
            //   date: moment(doc.data().Date.toDate()).format("DD/MM"),
            //   [Amber]: doc.data().Amber,
            //   [Red]: doc.data().Red,
            // };
          });
          setCommunitySummary(community);
        });
    }
  }, [community_incidents_summary_ed, selectedZone]);

  return (
    <VBarChart
      height={380}
      data={communitySummary}
      config={configCommunityIncidents}
    />
  );
};

export default compose(
  connect(
    ({
      dashboard,
      firestore: {
        ordered: { consolidated, community_incidents_summary_ed },
      },
    }) => ({
      consolidated,
      community_incidents_summary_ed,
      selectedZone: dashboard.selectedZone,
    })
  ),
  firestoreConnect()
)(CommunityIncidents);
