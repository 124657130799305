import React, { Component } from "react";
import Slider from "react-slick";
import './ReactSlider.css';

export default class ReactSlider extends Component {

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 350,
      accessibility: true,
      slidesToShow: 2,
      slidesToScrolarrows: 1,
      rows: 1,
      swipeToSlide: true,
      arrows: false,
      variableWidth: true,
      nextArrow: <Next />,
      prevArrow: <Prev />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            arrows: false,
            swipeToSlide: true,
          }
        },
        {
          breakpoint: 1149,
          settings: {
            slidesToShow: 2,
            arrows: true,
            swipeToSlide: true,
          }
        },
        {
          breakpoint: 1439,
          settings: {
            slidesToShow: 3,
            arrows: true,
          }
        },
        {
          breakpoint: 1719,
          settings: {
            slidesToShow: 4,
            arrows: true,
          }
        },
        {
          breakpoint: 2009,
          settings: {
            slidesToShow: 5,
            arrows: true,
          }
        },
        {
          breakpoint: 2289,
          settings: {
            slidesToShow: 6,
            arrows: true,
          }
        },
        {
          breakpoint: 2559,
          settings: {
            slidesToShow: 7,
            arrows: true,
          }
        }
      ]
    };
    const { myRef, children } = this.props
    return (
      <div>
        <Slider ref={ myRef } {...settings}>
          { children }
        </Slider>
      </div>
    );
  }
}

const Next = ({ className, style, onClick }) => (
  <button type="button" onClick={onClick} className={[className, 'slider-arrow', 'slider-next'].join(' ')} style={style}>
    <img alt="logo" src={require('../../../assets/images/next-arrow.svg')} style={{ height: 15, width: 20 }} />
  </button>
);

const Prev = ({ className, style, onClick }) => (
  <button type="button" onClick={onClick} className={[className, 'slider-arrow', 'slider-prev'].join(' ')} style={style}>
    <img alt="logo" src={require('../../../assets/images/prev-arrow.svg')} style={{ height: 15, width: 20 }} />
  </button>
);