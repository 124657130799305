import React from "react";
import Readings from "../Readings/Readings";
import DailyReadingUpdate from "../UpdateCards/DailyReadingUpdate";
import SystemStatusUpdate from "../UpdateCards/SystemStatusUpdate";
import SystemStatus from "../SystemStatus";
import SystemHealth from '../SystemHealth/SystemHealth';
import AdHocTasks from "../AdhocTasks/AdHocTasks";
import StaffActivities from "../Cards/StaffActivities/StaffActivities";
import Attendance from "../Attendance/Attendance";
import SingleTask from "../AdhocTasks/SingleTask";
import Settings from "../Settings/Settings";
import TaskDeatailPage from "../Cards/StaffActivities/TaskDeatailPage";
import ServiceIntervals from "../ServiceIntervals/ServiceIntervals";
import ServiceUpdate from "../ServiceIntervals/ServiceUpdate";
import Dashboard from "../InitialScreen/Dashboard";
import Profile from "../Profile/Profile";
import DailyLog from "../ExpandSection/DailyLog";
import {default as StaffAct} from "../StaffActivities/StaffActivities";
import Reports from "../Reports/Reports";
import GroupedTask from '../AdhocTasks/Grouped';
import Incidents from '../Incidents/Incidents';
import IncidentSummary from "../IncidentSummary/IncidentSummary";
import Executive from "../Executive/Executive"
import StaffDetails from "../Executive/Components/Details/StaffDetails/StaffDetails";
import IncidentDetails from "../IncidentDetails/IncidentDetails";

const routes = [
  {
    id: 0,
    path: "/dashboard",
    exact: true,
    main: () => <Dashboard />
  },
  {
    id: 1,
    path: "/reading",
    exact: true,
    main: () => <Readings />
  },
  {
    id: 2,
    path: "/system",
    exact: true,
    main: () => <SystemHealth />
  },
  {
    id: 3,
    path: "/task",
    exact: true,
    main: () => <div />
  },
  {
    id: 4,
    path: "/reading/update",
    exact: true,
    main: () => <DailyReadingUpdate />
  },
  {
    id: 5,
    path: "/system/update",
    exact: true,
    main: () => <SystemStatusUpdate />
  },
  {
    id: 6,
    path: "/taskmanager",
    exact: true,
    main: () => <AdHocTasks />
  },
  {
    id: 7,
    path: "/staff",
    exact: true,
    main: () => <StaffActivities />
  },
  {
    id: 8,
    path: "/staff/attendance",
    exact: true,
    main: () => <Attendance />
  },
  {
    id: 9,
    path: "/adhoc/task",
    exact: true,
    main: () => <SingleTask />
  },
  {
    id: 10,
    path: "/settings",
    exact: true,
    main: () => <Settings />
  },
  {
    id: 11,
    path: "/staff/task",
    exact: true,
    main: () => <StaffAct />
  },
  // {
  //   id: 12,
  //   path: "/services",
  //   exact: true,
  //   main: () => <ServiceIntervals />
  // },
  {
    id: 13,
    path: "/services/update",
    exact: true,
    main: () => <ServiceUpdate />
  },
  {
    id: 14,
    path: "/profile",
    exact: true,
    main: () => <Profile />
  },
  {
    id: 15,
    path: "/dashboard/:expand",
    exact: true,
    main: () => <DailyLog />
  },
  {
    id: 16,
    path: "/reports",
    exact: true,
    main: () => <Reports />
  },
  {
    id: 17,
    path: "/adhoc",
    exact: true,
    main: () => <GroupedTask />
  },
  {
    id: 18,
    path: "/serviceinterval",
    exact: true,
    main: () => <ServiceIntervals />
  }, {
    path: "/incidents",
    exact: true,
    main: () => <Incidents />
  },
  {
    id: 19,
    path: "/incident-summary",
    exact: true,
    main: () => <IncidentSummary />
  },
  {
    id: 20,
    path: "/incidents/:id",
    exact: true,
    main: () => <IncidentDetails />
  }
];

export default routes;