/* eslint-disable camelcase */
import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect, firebaseConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
import NavBar from "../common/Navbar/NavBar";
import Sidebar from "../common/Sidebar/Sidebar";
import routes from "../common/Routes";
import executiveRoutes from "../common/ExecutiveRoutes";
import createHistory from "../../history";
import Loading from "../common/Loading/Loading";
import QRScanner from "../common/QRScanner/QRScanner";
import Notification from "../common/Notification/Notification";

class Dashboard extends React.Component {
  state = {
    showOnlineNotification: false,
  };

  componentDidMount() {
    const {
      firebase,
      setCurrentApartment,
      auth,
      firestore,
      toggleDashboard,
      setApartmentAccessInfo
    } = this.props;

    let apartments = []
    firestore.get({
      collection: "apartments",
    }).then(async (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        apartments =[ ...apartments, { id: doc.id, name: doc.data().Name }]
      });
      
      let ApartmentAccessInfo = []
      await Promise.all(
        (apartments || []).map(async (apartment) =>
          firestore
            .get({
              collection: "apartments",
              doc: apartment.id,
              subcollections: [
                {
                  collection: "Users",
                  where: [["Email", "==", auth.email]],
                },
              ],
            })
            .then(async (doc) => {
              if (doc.empty) {
                return false;
              }

              let users = [];
              doc.forEach((doc) => {
                users = [...users, { UID: doc.id, ...doc.data() }];
              });
              ApartmentAccessInfo = [ ...ApartmentAccessInfo, { ...apartment, users }] 
            })
        )
      )
        .then(() => {
          
          setApartmentAccessInfo(ApartmentAccessInfo)
        })
        .catch(() => {});
    
    });

    firestore
      .get({
        collection: "Users",
        where: ["Email", "==", auth.email],
      })
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const { is_cons_user } = doc.data();
          if (is_cons_user) {
            toggleDashboard("executive");
          }
        });
      });
    const width = this.getWidth();
    if (width > 768) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    window.addEventListener("resize", this.reportWindowSize);
    const connectedRef = firebase.database().ref(".info/connected");
    connectedRef.on("value", (snap) => {
      if (snap.val() === true) {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }
        this.setState({ showOnlineNotification: false });
        this.uploadOfflineFiles();
      } else {
        this.timeout = setTimeout(() => {
          this.setState({ showOnlineNotification: true });
        }, 2000);
      }
    });
    this.setState({});
    if (localStorage.getItem("tb-state")) {
      const { apartment } = JSON.parse(localStorage.getItem("tb-state"));
      setCurrentApartment(apartment.currentApartment);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      firebase,
      clearFirestore,
      setErrorMessage,
      AppartmentDetails,
      location,
      profile,
      currentApartment,
      setCurrentApartment,
      currentDashboard,
      redirectRoute,
      redirectApartment,
    } = this.props;
    const { pathname } = location;
    if (profile) {
      const {
        DefaultApartment,
        is_approved: isApproved,
        email: Email,
      } = profile;
      if (DefaultApartment && !isApproved) {
        setErrorMessage(
          `Pending Approval from ${DefaultApartment} coordinator`
        );
        clearFirestore();
        firebase.logout();
        return null;
      }
      if (!DefaultApartment && !isApproved && Email) {
        createHistory.replace("/set-appartment");
      } else if (!currentApartment) {
        setCurrentApartment(DefaultApartment);
      } else if (currentApartment !== prevProps.currentApartment) {
        this.fetchData(currentApartment);
      } else if (profile !== prevProps.profile) {
        this.fetchData(currentApartment);
      }
    }

    // Details view from executive dashboard's widget action button (=>)
    if (
      currentDashboard !== prevProps.currentDashboard &&
      currentDashboard === "local" &&
      redirectRoute
    ) {
      createHistory.push("/dashboard");
      setTimeout(() => {
        createHistory.push(redirectRoute);
      }, 1000);
      // console.log("etting ----", redirectRoute, redirectApartment);
      setCurrentApartment(redirectApartment);
    }

    if (pathname === "/") {
      // initial check for routing..
      if (currentDashboard === "executive") {
        createHistory.push("/executive");
      } else if (AppartmentDetails) {
          createHistory.push("/dashboard");
        }
    }
  }

  getWidth = () =>
    Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );

  reportWindowSize = (e) => {
    if (this.isMobile) {
      if (e.target.innerWidth > 768) {
        this.isMobile = false;
        this.setState({});
      }
    } else if (e.target.innerWidth <= 768) {
      this.isMobile = true;
      this.setState({});
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.reportWindowSize);
  };

  // for uploading offline images (stored when no internet)
  uploadOfflineFiles = () => {
    // Firebase upload call
    const { taskFiles, setUploadStatus, uploadStatus } = this.props;
    // Uploading offline files
    if (Object.keys(taskFiles).length && !uploadStatus) {
      const clone = JSON.parse(JSON.stringify(taskFiles));
      Promise.all(
        Object.keys(clone).map(async (taskId) =>
          Promise.all(
            clone[taskId].map(async (f, index) => {
              const blob = await fetch(f).then((res) => res.blob());
              clone[taskId][index] = new File(
                [blob],
                `${new Date().getTime()}-${taskId}-${index}`,
                {
                  type: "image/jpeg",
                }
              );
            })
          )
        )
      ).then(() => {
        setUploadStatus(true);
        this.uploadTaskFiles(clone);
      });
    }
  };

  uploadTaskFiles = async (files) => {
    const {
      firebase,
      firestore,
      addTaskImage,
      setUploadStatus,
      currentApartment,
    } = this.props;
    if (files && Object.keys(files).length) {
      Promise.all(
        Object.keys(files).map(async (taskId) => {
          if (!files[taskId].length) return false;
          const filesPath = `${currentApartment}/TaskDetails/${taskId}`;
          await firebase.uploadFiles(filesPath, files[taskId]);
          await Promise.all(
            files[taskId].map(async (f) => {
              await firestore.update(
                {
                  collection: "apartments",
                  doc: currentApartment,
                  subcollections: [{ collection: "TaskDetails", doc: taskId }],
                },
                {
                  TaskFiles: firestore.FieldValue.arrayUnion(
                    `${filesPath}/${f.name}`
                  ),
                }
              );
            })
          );
        })
      ).then(() => {
        // Clear offline files when upload completed
        setUploadStatus(false);
        addTaskImage({});
      });
    }
  };

  fetchData = (currentApartment) => {

    const { firestore, user, auth } = this.props;
    firestore.get({
      collection: "Users",
      doc: auth.uid,
      subcollections: [{ collection: "ConsViews" }],
      storeAs: "ConsViews",
    });
    if (this.unsubscribeUserAccessInfo) {
      this.unsubscribeUserAccessInfo();
    }
    this.unsubscribeUserAccessInfo = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "Users" }],
      where: [["Email", "==", user[0].Email]],
      storeAs: "UserAccessInfo",
    });
    if (this.unsubscribeIconMap) {
      this.unsubscribeIconMap();
    }
    this.unsubscribeIconMap = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "IconMaster" }],
      storeAs: "IconMap",
    });
    if (this.unsubscribeAdHocTaskManager) {
      this.unsubscribeAdHocTaskManager();
    }
    this.unsubscribeAdHocTaskManager = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "AdHocTaskManager" }],
      storeAs: "AdHocTaskManager",
    });
    if (this.unsubscribeBucketDetails) {
      this.unsubscribeBucketDetails();
    }
    this.unsubscribeBucketDetails = firestore.onSnapshot({
      collection: "onboarding",
      doc: "system",
      storeAs: "BucketDetails",
    });
    if (this.unsubscribeAppartmentDetails) {
      this.unsubscribeAppartmentDetails();
    }
    this.unsubscribeAppartmentDetails = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      storeAs: "AppartmentDetails",
    });
    if (this.unsubscribeAlerts) {
      this.unsubscribeAlerts();
    }
    this.unsubscribeAlerts = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "AlertsAndSuggestions" }],
      where: ["CardType", "==", "Alerts"],
      storeAs: "Alerts",
    });
    if (this.unsubscribeSuggestions) {
      this.unsubscribeSuggestions();
    }
    this.unsubscribeSuggestions = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "AlertsAndSuggestions" }],
      where: ["CardType", "==", "Suggestions"],
      storeAs: "Suggestions",
    });
    /* if (this.unsubscribeDailyReadingCards) {
      this.unsubscribeDailyReadingCards();
    }
    this.unsubscribeDailyReadingCards = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "DailyReadingCards" }],
      storeAs: "DailyReadingCards",
    }); */
    if (this.unsubscribeDailyReadings) {
      this.unsubscribeDailyReadings();
    }
    this.unsubscribeDailyReadings = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "DailyReadings" }],
      storeAs: "DailyReadings",
    });
    const since30Days = new Date(
      new Date(new Date().setDate(new Date().getDate() - 30)).setHours(0, 0, 0)
    );
    if (this.unsubscribeHistoricalDailyReadings) {
      this.unsubscribeHistoricalDailyReadings();
    }
    this.unsubscribeHistoricalDailyReadings = firestore.onSnapshot({
      collection: "apartments",
      doc: currentApartment,
      subcollections: [
        {
          collection: "HistoricalDailyReadings",
          where: [
            ["UpdatedTime", ">=", firestore.Timestamp.fromDate(since30Days)],
          ],
        },
      ],
      orderBy: ["UpdatedTime"],
      storeAs: `HistoricalDailyReadings`,
    });
    const SystemDetailsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "SystemDetails" }],
      storeAs: "SystemDetails",
    };
    const SystemEquipmentDetailsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "SystemEquipmentDetails" }],
      storeAs: "SystemEquipmentDetails",
    };
    const SystemComponentDetailsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "SystemComponentDetails" }],
      storeAs: "SystemComponentDetails",
    };
    const ContactInfoOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "ContactInfo" }],
      storeAs: "ContactInfo",
    };
    if (this.unsubscribeSystemDetailsOptions) {
      this.unsubscribeSystemDetailsOptions();
    }
    this.unsubscribeSystemDetailsOptions = firestore.onSnapshot(
      SystemDetailsOptions
    );
    if (this.unsubscribeSystemEquipmentDetailsOptions) {
      this.unsubscribeSystemEquipmentDetailsOptions();
    }
    this.unsubscribeSystemEquipmentDetailsOptions = firestore.onSnapshot(
      SystemEquipmentDetailsOptions
    );
    if (this.unsubscribeSystemComponentDetailsOptions) {
      this.unsubscribeSystemComponentDetailsOptions();
    }
    this.unsubscribeSystemComponentDetailsOptions = firestore.onSnapshot(
      SystemComponentDetailsOptions
    );
    if (this.unsubscribeContactInfoOptions) {
      this.unsubscribeContactInfoOptions();
    }
    this.unsubscribeContactInfoOptions = firestore.onSnapshot(
      ContactInfoOptions
    );
    const TaskDetailsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "TaskDetails" }],
      storeAs: "TaskDetails",
    };
    if (this.unsubscribeTaskDetailsOptions) {
      this.unsubscribeTaskDetailsOptions();
    }
    this.unsubscribeTaskDetailsOptions = firestore.onSnapshot(
      TaskDetailsOptions
    );
    const DailyAttendanceNowOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "DailyAttendanceNow" }],
      storeAs: "DailyAttendanceNow",
    };
    // const DailyAttendanceHistoricalOptions = {
    //   collection: "apartments",
    //   doc: currentApartment,
    //   subcollections: [{ collection: "DailyAttendanceHistorical" }],
    //   storeAs: "DailyAttendanceHistorical",
    // };
    if (this.unsubscribeDailyAttendanceNowOptions) {
      this.unsubscribeDailyAttendanceNowOptions();
    }
    this.unsubscribeDailyAttendanceNowOptions = firestore.onSnapshot(
      DailyAttendanceNowOptions
    );
    // if (this.unsubscribeDailyAttendanceHistoricalOptions) {
    //   this.unsubscribeDailyAttendanceHistoricalOptions();
    // }
    // this.unsubscribeDailyAttendanceHistoricalOptions = firestore.onSnapshot(
    //   DailyAttendanceHistoricalOptions
    // );
    const ReportsOptions = {
      collection: "apartments",
      doc: currentApartment,
      subcollections: [{ collection: "Reports" }],
      storeAs: "Reports",
    };
    if (this.unsubscribeReportsOptions) {
      this.unsubscribeReportsOptions();
    }
    this.unsubscribeReportsOptions = firestore.onSnapshot(ReportsOptions);
  };

  showWebNav = () => <NavBar />;

  render() {
    const {
      AppartmentDetails,
      userAccessInfo,
      currentDashboard,
      history,
      is_cons_user
    } = this.props;
    const { showOnlineNotification } = this.state;
    if (!userAccessInfo || !userAccessInfo.length) return <Loading />;
    const currentPathname = history.location.pathname;

    /* commented out skip settings if is_cons_user
    const routesSkip = ['/settings']   // routes which has no access for is_cons_user

    let filteredRoute = [...routes]
    if(is_cons_user){
      filteredRoute = routes.filter(route=> !routesSkip.includes(route.path) )
    }
    */

    return (
      <>
        {this.showWebNav()}
        <div
          className="d-flex"
          style={{ overflowY: "hidden", height: "100vh" }}
        >
          <Sidebar onToggle={this.toggle} currentPathname={currentPathname} />

          <div id="main-block"
            style={{flex: 1, overflow: "auto", marginTop: this.isMobile ? 0 : 75}}>
            {currentDashboard === "executive" ? (
              <Switch>
                {executiveRoutes.map((route) => (
                  <Route
                    key={`${route.id}_${route.path}`}
                    path={route.path}
                    exact={route.exact}
                    component={route.main}
                  />
                ))}
                <Redirect to="/executive" />
              </Switch>
            ) : AppartmentDetails ? (
              <Switch>
                {routes.map((route) => (
                  <Route
                    key={`${route.id}_${route.path}`}
                    path={route.path}
                    exact={route.exact}
                    component={route.main}
                  />
                ))}
                <Redirect to="/dashboard" />
              </Switch>
            ) : (
              <Loading showLogo="true" />
            )}
          </div>

          <QRScanner />
          <Notification />

          {
            <div
              id="offline-text"
              className={`${showOnlineNotification ? "active" : ""}`}
            >
              <span className="text-center">
                You are currently in offline mode. Some features may not be available
              </span>
            </div>
          }
        </div>
      </>
    );
  }
}

Dashboard.propTypes = {
  user: PropTypes.arrayOf(
    PropTypes.shape({
      Email: PropTypes.string,
      DefaultApartment: PropTypes.string,
    })
  ),
};

Dashboard.defaultProps = {
  user: [{ Email: "", DefaultApartment: "", is_approved: true }],
};

const mapDispatchToProps = (dispatch) => ({
  clearFirestore: () => dispatch({ type: "@@reduxFirestore/CLEAR_DATA" }),
  setErrorMessage: (message) => dispatch({ type: "ERROR_VALUE", message }),
  addTaskImage: (files) => dispatch({ type: "ADD_IMAGE", payload: files }),
  setUploadStatus: (status) =>
    dispatch({ type: "UPLOAD_STATUS", payload: status }), // TO prevent multiple upload of same file
  setCurrentApartment: (apt) =>
    dispatch({ type: "SET_APARTMENT", payload: apt }), // TO set current apartment
  toggleDashboard: (val) => dispatch({ type: "SET_DASHBOARD", payload: val }),
  setApartmentAccessInfo: (val) => dispatch({ type: "SET_APARTMENT_ACCESS_INFO", payload: val }),

  
});

const mapStateToProps = (state) => ({
  profile: state.firebase.profile,
  user: state.firestore.ordered.Users,
  email: state.firebase.auth.email,
  auth: state.firebase.auth,
  AppartmentDetails: state.firestore.ordered.AppartmentDetails,
  userAccessInfo: state.firestore.ordered.UserAccessInfo,
  taskFiles: state.updateModule.taskFiles,
  uploadStatus: state.updateModule.uploading,
  currentApartment: state.apartment.currentApartment,
  currentDashboard: state.dashboard.currentDashboard,
  redirectRoute: state.dashboard.redirectRoute,
  redirectApartment: state.dashboard.redirectApartment,
  // eslint-disable-next-line no-prototype-builtins
  is_cons_user: state.firestore.ordered.ConsViews && state.firestore.ordered.ConsViews[0] 
    ? state.firestore.ordered.ConsViews[0].is_cons_user
    : null,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(({ auth, profile }) => {
    let listeners = [];
    if (!auth.email) return [...listeners];
    listeners = [
      ...listeners,
      {
        collection: "Users",
        where: [["Email", "==", auth.email]],
      },
    ];
    // if(!profile.is_consolidated_user) return [...listeners]
    listeners = [
      ...listeners,
      {
        collection: "Users",
        doc: auth.uid,
        subcollections: [{ collection: "ConsViews" }],
        storeAs: "ConsViews",
      },
    ];
    return [...listeners];
  }),
  firebaseConnect(),
  withRouter
)(Dashboard);
