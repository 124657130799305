import React from "react";
import "./StaffActivityCard.css";

const StaffActivityCard = ({ data }) => {
  const Red = data.Red || 0;
  const Amber = data.Amber || 0;
  const total = Red + Amber;
  const RedRatio = Red *100/total;
  const AmberRatio = Amber *100/total

  let cardStyle= ''
  if(RedRatio && AmberRatio){
    cardStyle = `linear-gradient(to right, #ff8d8d ${RedRatio.toFixed()}%, #ff8d8d ${RedRatio.toFixed()}% , #ffdb1c 0% )`
  }else if(RedRatio){
    cardStyle = `linear-gradient(to right, #ff8d8d ${RedRatio.toFixed()}%, #ff8d8d ${RedRatio.toFixed()}%)`
  }else if(AmberRatio){
    cardStyle = `linear-gradient(to right, #ffdb1c ${AmberRatio.toFixed()}%, #ffdb1c ${AmberRatio.toFixed()}%)`
  }
  
  return (
    <div className="staff-activity-card">
      <div className="row justify-content-between pl-3 pr-3">
      <div className="staff-activity-name">{data.name}</div> 
      <div className="">
      <div className="staff-activity-defaulted-count text-right">{data.totalDefaultedTask}</div>
      <div className="staff-activity-defaulted-name">Total Defaulted Task</div>
      </div>
      </div>
   
      <div className="row justify-content-between pl-3 pr-3 pt-2">
        {data.Red ? (
          
          <div className="staff-activity-count Red"><div>{data.Red}</div><div className='staff-activity-label'>Properties</div></div>
         
          
        ) : null}
        {data.Amber ? (
          <div className="staff-activity-count Amber"><div>{data.Amber}</div><div className='staff-activity-label'>Properties</div></div>
        ) : null}
      </div>
      
      <div style={{position: 'absolute',  background: cardStyle, bottom: 0, height: 4, left: 0, right: 0}}/>
    </div>
  );
};

export default StaffActivityCard;
