import React, { Component } from 'react';
import { useState } from 'react';
import { Accordion, Card, Modal } from 'react-bootstrap';
import moment from 'moment';
import * as _ from 'lodash';
import StaffDetails from './StaffDetails';

export default class AttendanceHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 0
    }
  }

  sortStaffs(list) {
    return  list.sort((a, b) => {
      return (a.Name || '').localeCompare(b.Name);
    }).sort((a, b) => {
      const colors = { green: 'a', grey: 'b', red: 'c' };

      return colors[a.Indicator.toLowerCase()].localeCompare(colors[b.Indicator.toLowerCase()])
    });
  }

  render() {
    const { attendance, getPhotoURL } = this.props;
    const { activeKey } = this.state;

    const staffs = this.sortStaffs(attendance);
    const grouped = _.groupBy(staffs, 'ServiceArea');

    const tableHeads = [
      {key: 'PunchInTime', value: 'In Time'}, {key: 'PunchOutTime', value: 'Out Time'}, 
      {key: 'HoursSpend', value: 'Hours Spend'}, {key: 'Status', value: 'Status'}
    ];

    return (
      <div className="card system-card border-0 mt-2 mt-md-0">
        <div className="card-body py-2">
          { attendance && attendance.length ? <>
          <table className="table">
            <thead>
              <tr>
                <th width="40%">Employee</th>
                { tableHeads.map((head, key) => (
                <th key={key}>{head.value}</th>
                )) }
              </tr>
            </thead>
          </table>
          <Accordion activeKey={activeKey}>
          { Object.keys(grouped).sort((a, b) => a.localeCompare(b)).map((attd, i) => (
            <Card key={'attendance-history-'+attd.replace(/\s/, '-').toLowerCase()}>
              <Accordion.Toggle as={Card.Header} eventKey={i}
                onClick={() => this.setState({activeKey: activeKey !== i ? i : '99'})}>
                <i className={`icon-chevron-down${activeKey === i ? ` open` : ``}`}></i>
                <span className="font-weight-bold ml-2">{attd}</span>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={i}>
                <Card.Body>
                  <table className="table">
                    <tbody>
                      {grouped[attd].map(item => (
                      <StaffRow key={item.id} {...{tableHeads, getPhotoURL}} attendance={item} />
                      ))}
                    </tbody>
                  </table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )) }
          </Accordion>
          </> : 
          <div className="py-5">
            <img src={require('../../../assets/images/attendanceEmpty.png')} className="mx-auto mb-3" width="200" alt=""/>
            <p className="text-center">No Attendance data here</p>
          </div>
          }
        </div>
      </div>
    )
  }
}

const StaffRow = ({attendance, tableHeads, getPhotoURL}) => {
  const [isModalOpen, toggleModal] = useState(false);

  const getDiffDays = (day1, day2) => {
    if (!day1 || !day2) return 0;

    const date1 = day1.toDate();
    const date2 = day2.toDate();
    const diffDays = Math.abs(date2.getDate() - date1.getDate());
    return diffDays;
  }

  return (
    <>
    <tr>
      <td width="40%">
        <StaffDetails staff={attendance} staffPhoto={attendance.ImageURL} getPhotoURL={getPhotoURL}/>
      </td>
      { tableHeads.map((head, i) => (
      <td key={i} data-attr={head.key !== 'Status' ? head.value : ''} 
        className={head.key === 'HoursSpend' ? `text-color ${attendance.Indicator.toLowerCase()}` : ``}>
        
        {attendance[head.key] && attendance[head.key].seconds ? 
        moment(attendance[head.key].toDate()).format('h:mm a') : (attendance[head.key] || '-')}

        {/* Show +<diff> if attendance date and punch out date is different */}
        { head.key === 'PunchOutTime' && getDiffDays(attendance.PunchInTime, attendance.PunchOutTime) > 0 && 
        <small className="text-danger ml-2">+{getDiffDays(attendance.PunchInTime, attendance.PunchOutTime)}d</small> }
        
        {/* Show alert icon if AttendanceAudits not zero */}
        { head.key === 'HoursSpend' && attendance.AttendanceAudits && attendance.AttendanceAudits.length > 1 && 
        <a className="cursor-pointer ml-2 text-primary" onClick={() => toggleModal(true)}>
          <i className="icon-2x icon-alert-circle"></i>
        </a>}
      </td>
      )) }
    </tr>
    <Modal centered show={isModalOpen} id="staff-attendance" onHide={() => toggleModal(false)}>
      <Modal.Header className="tb-modal-header pt-3 pb-0 px-4" closeButton>
        <StaffDetails staff={attendance} staffPhoto={attendance.ImageURL} getPhotoURL={getPhotoURL}/>
      </Modal.Header>
      <Modal.Body className="tb-modal-body pt-2 pb-0 px-4">
        { attendance.AttendanceAudits && < ul className="timeline">
          { [...attendance.AttendanceAudits].sort((a, b) => a.ActionTime.toDate() - b.ActionTime.toDate()).map((audit, i) => (
            <li key={`${attendance.id}-attd-audit-${i}`}
              data-date={moment(audit.ActionTime.toDate()).format('D MMM, h:mm a')}>
              <span className="badge-indicator primary rounded-sm"></span>
              {audit.Action}
            </li>
          )) }
        </ul>}
      </Modal.Body>
      <Modal.Footer className="tb-modal-footer pt-2 pb-3">
        <button className="btn btn-sm btn-primary" onClick={() => toggleModal(false)}>Close</button>
      </Modal.Footer>
    </Modal>
    </>
  )
}
