/* eslint-disable prefer-template */
/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-sparse-arrays */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { useTable, useExpanded, useGlobalFilter } from "react-table";
import {
  OverlayTrigger,
  Popover,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import * as _ from "lodash";
import { firestoreConnect } from "react-redux-firebase";
import ContactCard from "../../Common/ContactCard/ContactCard";
// import image from "../../../../../assets/images/avatar.png";
import DetailsHeader from "../../Common/DetailsHeader/DetailsHeader";
import Icon from "../../Common/Icon/Icon";
import "./SystemHealthDetails.css";
import { BackButton } from "../../Common/Buttons";
import {
  Search,
  SearchInput,
  IconSearch,
  ModalThead,
  ModalTitle,
  Divider,
  Image,
  Thead,
  SubRowTRow,
  ChildTableStyle,
  StylesGroup,
  DetailsModal,
  ModalTBody,
  ExportFile
} from "../styles";
import useWindowSize from "../../Hooks/useWindowSize";
import ModalHeader from "react-bootstrap/ModalHeader";

const { Parser } = require('json2csv');

function ParentTable({ columns: userColumns, data, renderRowSubComponent }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
    toggleAllRowsExpanded,
    toggleRowExpanded
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded // Use the useExpanded plugin hook
  );
  useEffect(() => {
    setTimeout(() => {
      toggleRowExpanded(0);
    }, 1000);
  }, []);
  return (
    <>
      <table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </Thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            const obj = row.getRowProps();
            delete obj.role;
            return (
              <React.Fragment {...obj}>
                <SubRowTRow>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} {...row.getToggleRowExpandedProps()}>{cell.render("Cell")}</td>
                  ))}
                </SubRowTRow>
                {/*
                      If the row is in an expanded state, render a row with a
                      column that fills the entire length of the table.
                    */}
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {/*
                              Inside it, call our renderRowSubComponent function. In reality,
                              you could pass whatever you want as props to
                              a component like this, including the entire
                              table instance. But for this example, we'll just
                              pass the row
                            */}
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

// Create a default prop getter
const defaultPropGetter = () => ({});

function ChildTable({
  columns: userColumns,
  data,
  getCellProps = defaultPropGetter,
  filterInput,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useGlobalFilter
  );
  useEffect(() => {
    setGlobalFilter(filterInput);
  }, [filterInput]);
  return (
    <>
      <ChildTableStyle {...getTableProps()} className="child-table-border-separate">
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr key={row.id}>
                {row.cells.map((cell, i) => (
                  <td {...cell.getCellProps(getCellProps(cell, i))}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </ChildTableStyle>
    </>
  );
}
const SystemHealthDetails = (props) => {
  const { width: WindowWidth } = useWindowSize();
  const {
    SystemHealth_ED,
    firestore,
    consolidated,
    ConsViews,
    managersList,
    selectedZone,
    viewDetail,
    setManagers
  } = props;
  const [systemHealthData, setSystemHealthData] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apartmentId, setApartmentId] = useState(null);
  const [apartmentName, setApartmentName] = useState(null);
  const [issue, setIssue] = useState(null);
  const [filterInput, setFilterInput] = React.useState("");
  const [exportCSV, setExportCSV] = React.useState("");
  useEffect(() => {
    if (apartmentId && consolidated && consolidated.length) {
      setIsLoading(true);
      setIssue(null);
      firestore
        .get({
          collection: "consolidated",
          doc: consolidated[0].id,
          subcollections: [
            {
              collection: "SystemHealth_ED",
              doc: apartmentId,
              subcollections: [{ collection: "issue_type_ed" }],
            },
          ],
          storeAs: "issue_type_ed",
        })
        .then(async (querySnapshot) => {
          setIsLoading(false);
          let issues = [];
          querySnapshot.forEach((doc) => {
            issues = [...issues, { ...doc.data() }];
          });
          // setIssue(issues);
          const issueGroup = _.chain(issues).groupBy("StatusIndicator").value();
          let issueGroupList = [];

          // eslint-disable-next-line no-prototype-builtins
          if (issueGroup.hasOwnProperty("Red")) {
            issueGroupList = [...issueGroupList, ...issueGroup.Red];
          }
          // eslint-disable-next-line no-prototype-builtins
          if (issueGroup.hasOwnProperty("Amber")) {
            issueGroupList = [...issueGroupList, ...issueGroup.Amber];
          }

          setIssue(issueGroupList);

          if(!issueGroupList.length){
            return false
          }
          const fileDownload = [...issueGroupList];
          const exportList = fileDownload.map(item => ({
              ...item,
              NotWorkingSince: item.NotWorkingSince.toDate().toDateString()
              // NotWorkingSince: item.NotWorkingSince.map(date => date.toDate().toDateString())
            }))
          

          const fields = [{
            label: 'System',
            value: 'SystemInternalName'
          },{
            label: 'Component',
            value: 'ComponentName'
          },{
            label: 'Issue',
            value: 'ParamName'
          },{
            label: 'Not Working Since',
            value: 'NotWorkingSince'
          },{
            label: 'Category',
            value: 'Category'
          }];


          const json2csvParser = new Parser({fields});
          const csv = json2csvParser.parse(exportList);

          window.URL = window.webkitURL || window.URL;
          const platform = window.navigator.platform
          const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
          const contentType = windowsPlatforms.indexOf(platform) !== -1 ? 'application/vnd.ms-excel':'text/csv';
          const csvFile = new Blob([csv], {type: contentType});
          const csvFileExport = window.URL.createObjectURL(csvFile)

          setExportCSV(csvFileExport)
         
        })
        .catch(() => setIsLoading(false));
    }
  }, [apartmentId]);

  useEffect(() => {
    async function fetchManagers() {
      let users = [];
      setIsLoading(true);
      firestore
        .get({
          collection: "Users",
          where: [["is_cons_user", "==", true]
            , ["is_approved", "==", true]],
          storeAs: "ManagersList",
        })
        .then(async (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            users = [...users, { id: doc.id, ...doc.data() }];
          });

          await Promise.all(
            (users || []).map(async (user) =>
              firestore
                .get({
                  collection: "Users",
                  doc: user.id,
                  subcollections: [
                    {
                      collection: "ConsViews",
                      where: [["ConsViewId", "==", ConsViews[0].ConsViewId]],
                    },
                  ],
                  storeAs: "ManagersListZone",
                })
                .then(async (doc) => {
                  if (doc.empty) {
                    return false;
                  }

                  let managers = {};
                  doc.forEach((doc) => {
                    // console.log(doc.data());
                    managers = { UID: doc.id, ...doc.data() };
                  });
                  return { ...user, ...managers };
                })
            )
          )
            .then((prop) => {
              setIsLoading(false);
              const filterEmptyManagerList = prop.filter((manager) => manager);
              setManagers(filterEmptyManagerList);
            })
            .catch(() => setIsLoading(false));
        });
    }
    if (!managersList.length && !isLoading) {

      fetchManagers();
    }
    if (!consolidated) {

      firestore.get({
        collection: "consolidated",
        where: [["ConsViewId", "==", ConsViews[0].ConsViewId]],
        storeAs: "consolidated",
      });
    }
  }, [consolidated])


  useEffect(() => {
    const groupData = async () => {
      const { Level: currentUserLevel } = ConsViews[0];
      const systemHealthEd = SystemHealth_ED.map((item) => ({
        ...item,
        managers: managersList.filter(
          ({ Zones, Level }) =>
            (Zones.includes(item.Zone) || Zones.includes("All")) &&
            Level > currentUserLevel &&
            Level <= currentUserLevel + 2
        ),
      }));
      const data = await _.chain(systemHealthEd)
        .groupBy("System")
        .map((value, key) => ({
          System: key,
          subrows: _.chain(value).groupBy("StatusIndicator").value(),
        }))
        .value();

      const sortedData = data.map(({ System, subrows }) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!subrows.hasOwnProperty("Red")) {
          // eslint-disable-next-line no-param-reassign
          subrows.Red = [];
        }
        // eslint-disable-next-line no-prototype-builtins
        if (!subrows.hasOwnProperty("Amber")) {
          // eslint-disable-next-line no-param-reassign
          subrows.Amber = [];
        }
        return {
          System,
          subrows: [...subrows.Red, ...subrows.Amber],
        };
      });
      // console.log(
      //   "sortedData :",

      // );
      setSystemHealthData(sortedData.filter(({ subrows }) => subrows.length));
    };
    if (SystemHealth_ED && SystemHealth_ED.length) {
      groupData();
    }
  }, [SystemHealth_ED]);

  useEffect(() => {
    const fetchData = async () => {
      const systems = consolidated[0].Systems;
      let condition = [["System", "in", systems]];
      if (selectedZone && selectedZone !== "All") {
        condition = [...condition, ["Zone", "==", selectedZone]];
      }
      firestore.get({
        collection: "consolidated",
        doc: consolidated[0].id,
        subcollections: [
          {
            collection: "SystemHealth_ED",
            where: condition,
          },
        ],
        storeAs: "SystemHealth_ED",
      });
    };
    if (consolidated && consolidated.length) fetchData();
  }, [consolidated, selectedZone, managersList]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Property Name",
        accessor: "System",
        className: "main-header",
      },
      {
        Header: "Contact Person",
        className: "main-header",
      },

      {
        Header: "Not Working Since",
        className: "main-header",
      },
      {
        Header: "Issues",
        className: "main-header",
      },
      // {
      //   Header: "Status",
      //   className: "main-header",
      // },
      {
        // Make an expander cell
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          // <span {...row.getToggleRowExpandedProps()}>
          <Icon icon="downArrow" fill="#fff" />
          // </span>
        ),
      },
    ],
    []
  );

  const subrowColumns = React.useMemo(
    () => [
      {
        accessor: "ApartmentName",
      },
      {
        accessor: "managers",
        Cell: ({ cell }) => {
          const { managers } = cell.row.original;

          if (!managers || !managers.length) return "";
          const { DisplayName, Role, PhoneNo } = managers[0] || {};

          return (
            // <div className="d-flex flex-row align-items-center">
            <div className="row ml-3">
              <ContactCard
                name={DisplayName}
                userRole={Role}
                contact={PhoneNo}
                hideUnderline
              />
              <OverlayTrigger
                rootClose
                trigger="click"
                // key="bottom"
                placement="right"
                overlay={
                  <Popover
                    // id="popover-positioned-bottom"
                    style={{ maxWidth: "350px" }}
                  >
                    <Popover.Content>
                      {managers.map((manager) => {
                        const { DisplayName, Role, PhoneNo } = manager;
                        return (
                          <ContactCard
                            key={PhoneNo}
                            name={DisplayName}
                            userRole={Role}
                            contact={PhoneNo}
                          />
                        );
                      })}
                    </Popover.Content>
                  </Popover>
                }
              >
                <button style={{ background: "none", border: "none" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                  >
                    <path
                      d="M1 1L4 4L7 1"
                      stroke="#B5B5B5"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </OverlayTrigger>
            </div>
          );
        },
      },
      ,
      {
        id: "notWorkingSince",
        accessor: (d) => (
          <div className="row justify-content-center">
            {d && d.NotWorkingSince ? d.NotWorkingSince.toDate().toDateString() : null}
          </div>
        ),
      },
      {
        accessor: "TotalOverDueIncidents",
        Cell: ({ cell }) => (
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div
              className="d-inline ml-5"
              onClick={() => {
                setShow(true);
                setApartmentName(cell.row.original.ApartmentName);
                setApartmentId(cell.row.original.id);
              }}
            >
              <u style={{ color: "#558DCA" }}>
                {cell.row.original.IssuesCount
                  ? cell.row.original.IssuesCount
                  : null}
              </u>
            </div>
            <button style={{ border: "none", background: "none" }} onClick={() => {
              viewDetail(
                "dashboard/system_health",
                cell.row.original.Apt_Id
              );
            }}>
              <Icon icon="rightArrow" fill="#558DCA" />
            </button>
          </div>
        ),
      },
      // {
      //   accessor: "StatusIndicator",
      //   Cell: ({ cell }) => (
      //     <div className="d-flex flex-row justify-content-between align-items-center">
      //       <div
      //         className={"status-" + cell.row.values.StatusIndicator}
      //         style={{
      //           width: "119px",
      //           height: "30px",
      //           borderRadius: "15px",
      //           display: "flex",
      //           justifyContent: "center",
      //           alignItems: "center",
      //         }}
      //       >
      //         <span
      //           style={{
      //             fontWeight: 500,
      //             fontSize: "12px",
      //             color: "#FFFFFF",
      //             textTransform: "uppercase",
      //           }}
      //         >
      //           {cell.row.values.StatusIndicator === "Green"
      //             ? "normal"
      //             : cell.row.values.StatusIndicator === "Amber"
      //             ? "warning"
      //             : "critical"}
      //         </span>
      //       </div>
      //       <button style={{ border: "none", background: "none" }}>
      //         <Icon icon="rightArrow" fill="#558DCA" />
      //       </button>
      //     </div>
      //   ),
      // },
    ],
    []
  );
  const data = React.useMemo(() => systemHealthData, [systemHealthData]);
  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    // setGlobalFilter(value);
    setFilterInput(value || "");
  };
  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row }, filterInput) => (
      <ChildTable
        columns={subrowColumns}
        data={row.original.subrows}
        filterInput={filterInput}
        getCellProps={(cell, i) => ({
          style: {
            borderLeft:
              i === 0
                ? cell.row.original.StatusIndicator === "Red"
                  ? "8px solid #FF8D8D"
                  : "8px solid #FFCB7E"
                : "none",
            width: "20%",
          },
        })}
      />
    ),
    []
  );

  return (
    <div className="d-flex flex-row flex-wrap m-4">
      <div className="col-lg-12 col-md-12 col-sm-12">
        {WindowWidth <= 768 ? (
          <div style={{ marginTop: "1.25rem" }}>System Health Details</div>
        ) : null}
        <div className="exe-details-header-container row justify-content-between mb-3">
          <div className="d-flex flex-row col align-items-center">
            <div className="back-button">
              <BackButton />
            </div>
            <DetailsHeader
              label="System Health"
              description="Overall system health status of managed properties"
            />
          </div>
          <div className="d-flex flex-row align-items-center col-12 col-md-4 m-0">
            {/* <ExportButton />
            <FilterButton /> */}
            <Search className="ml-0 ml-xl-4 ml-lg-4 flex-grow-1">
              <SearchInput
                type="text"
                className="w-100"
                placeholder="Search"
                value={filterInput}
                onChange={handleFilterChange}
              />
              <IconSearch className="icon-search" />
            </Search>
          </div>
        </div>

        <StylesGroup>
          <div>
            {!data.length ? (
              <div className="d-flex justify-content-center mt-5">
                <span className="mt-5">No Issues to Display</span>
              </div>
            ) : (
                <ParentTable
                  columns={columns}
                  data={data}
                  // We added this as a prop for our table component
                  // Remember, this is not part of the React Table API,
                  // it's merely a rendering option we created for
                  // ourselves
                  renderRowSubComponent={(row) =>
                    renderRowSubComponent(row, filterInput)
                  }
                />
              )}
          </div>
        </StylesGroup>
      </div>
      <DetailsModal
        show={show}
        size="lg"
        onHide={() => {
          setShow(false);
          setApartmentId(null);
          setApartmentName(null);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {isLoading ? (
          <div className="row justify-content-center">
            <Spinner
              animation="grow"
              variant="dark"
              className="align-items-center"
            />
          </div>
        ) : (
            <>
              <ModalHeader>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <ModalTitle>
                    Issue Details ({issue ? issue.length : null})
                </ModalTitle>
                <a href={exportCSV} download={`${apartmentName}.csv`}><ExportFile/></a>
                  <Image
                    onClick={() => {
                      setShow(false);
                      setApartmentId(null);
                      setApartmentName(null);
                    }}
                  />
                </div>
              </ModalHeader>
              <Modal.Body>
                <div className="tableFixHead">
                  <table style={{
                    borderCollapse: 'separate',
                    borderSpacing: '0 2px',
                    // width: '100%',
                    // display: 'block',
                    // overflowX: 'auto',
                    // whiteSpace: 'nowrap'
                  }}>
                    <thead>
                      <tr>
                        <th>System</th>
                        <th>Component</th>
                        <th>Issue</th>
                        <th>Not Working Since</th>
                        <th>Category</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(issue || []).map((item) => (
                        <tr key={item.NotWorkingSince.toDate().toDateString()} style={{
                          marginBottom: 1
                        }}>
                          <td style={{
                            borderLeft:
                              item.StatusIndicator === "Red"
                                ? "8px solid #FF8D8D"
                                : "8px solid #FFCB7E",
                          }}>{item.SystemInternalName}</td>
                          <td>{item.ComponentName}</td>
                          <td>
                            {item.ParamName} {item.Issue}
                          </td>
                          <td>{item.NotWorkingSince.toDate().toDateString()}</td>
                          <td>{item.Category}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* <Table className="mb-0">
                  <ModalThead>
                    <tr>
                      <th>System</th>
                      <th>Component</th>
                      <th>Issue</th>
                      <th>Not Working Since</th>
                    </tr>
                  </ModalThead>
                  <ModalTBody>
                    {(issue || []).map((item) => (
                      <tr key={item.NotWorkingSince.toDate().toDateString()}>
                        <td>{item.SystemInternalName}</td>
                        <td>{item.ComponentName}</td>
                        <td>
                          {item.ParamName} {item.Issue}
                        </td>
                        <td>{item.NotWorkingSince.toDate().toDateString()}</td>
                      </tr>
                    ))}
                  </ModalTBody>
                </Table> */}
              </Modal.Body>
            </>
          )}
      </DetailsModal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setManagers: (manegers) =>
    dispatch({ type: "SET_MANAGERS_LIST", payload: manegers }),
  viewDetail: (route, apartment) =>
    dispatch({
      type: "REDIRECT_DASHBOARD_TO_ROUTE",
      payload: { route, apartment },
    }),
});
export default compose(
  connect(
    ({
      dashboard,
      apartment,
      firestore: {
        ordered: { SystemHealth_ED, consolidated, ConsViews },
      },
    }) => ({
      SystemHealth_ED,
      consolidated,
      managersList: apartment.managersList,
      ConsViews,
      selectedZone: dashboard.selectedZone,
    }),
    mapDispatchToProps
  ),
  firestoreConnect()
)(SystemHealthDetails);
