import React, { Component, useState, useRef } from 'react';
import { Decimal } from 'decimal.js';
import { Modal, Button } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';

export const TransientEdit = (props) => {
  const { 
    reading, active, isMobile, getLastUpdate, setSingleEdit, saveTransient
  } = props;
  const mask = s => Array.from(s).map(() => /((-)|[0-9]|\.)/i);
  const [value, setValue] = useState(reading.ParamValueRaw || reading.MinValue);
  const [description, setDescription] = useState('');
  const [saveClicked, setSaveClicked] = useState(false);
  const [showInput, setShowInput] = useState(false);

  const convertToString = (val) => {
    if (val === null || val === undefined) return '';
    return parseFloat((+val).toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: reading.DecimalPrecision || 1,
      style: 'decimal'
    }).replace(/,/g, ''));
  }

  const increment = () => {
    const max = reading.MaxValue ? reading.MaxValue : 999999;
    if(value < max){
      const num = new Decimal(value); 
      const incrementor = new Decimal(reading.incrementBy || 1);
      const val = num.plus(incrementor).toString();
      setValue(convertToString(val));
    }
  }

  const decrement = () => {
    const min = reading.MinValue ? reading.MinValue : 0;
    if(value > min){
      const num = new Decimal(value); 
      const incrementor = new Decimal(reading.incrementBy || 1);
      const val = num.sub(incrementor).toString();
      setValue(convertToString(val));
    }
  }

  const onChangeVal = (val) => {
    if (val !== null && val !== '') {
      setValue(convertToString(val));
    }
  }

  const onChange = (val) => {
    if (val.slice(-1) === '.' && (val.match(/\./g)).length > 1) {
      setValue(val.substring(0, val.length - 1));
    }
  }

  const onKeyDown = (e) => {
    const min = reading.MinValue ? reading.MinValue : 0;
    const max = reading.MaxValue ? reading.MaxValue : 999999;
    const nextVal = e.target.value + e.key;
    
    let count = 0;
    if (nextVal && e.key === '.' ) count = (nextVal.match(/\./g)).length;
    if (count > 1){
      e.preventDefault();
      return false;
    }
    if (nextVal > max || nextVal < min) {
      e.preventDefault();
      return false;
    }
    if ((nextVal > 100 || nextVal < 0) && reading.updateWidget === 'PERCENTAGE') {
      e.preventDefault();
      return false;
    }
  }

  const editValue = (val) => {
    if ((val === null || val === '') && val !== 0) {
      setValue('');
    } else {
      setValue(val);
    }
  }

  const cancel = () => {
    setValue(reading.ParamValueRaw || reading.MinValue);
    setDescription('');
    setSaveClicked(false);
    setSingleEdit(false);
    setShowInput(false);
  }

  const save = () => {
    setSaveClicked(true);
    if (value === '' || value === null || value < reading.MinValue || value > reading.MaxValue) {
      return false;
    }
    if (reading.HasDescription && description === '') {
      return false;
    }

    saveTransient({
      id: reading.id, ParamValueRaw: value, Description: description
    });
    setValue(reading.ParamValueRaw || reading.MinValue);
    setDescription('');
    setSaveClicked(false);
    setSingleEdit(false);
    setShowInput(false);
  }

  const chooseDescription = (e) => {
    if (e.target.value !== 'Others') {
      setDescription(e.target.value);
      setShowInput(false);
    } else {
      setDescription('');
      setShowInput(true);
    }
  }

  const isSaveDisabled = () => {
    if (value === '' || value === null) {
      return true;
    }
    if (reading.HasDescription && description === '') {
      return true;
    }
  }
  
  return (
    <Modal centered show={active} onHide={cancel}>
      <Modal.Header className="tb-modal-header" closeButton>
        <Modal.Title className="tb-modal-title" as="h5">{reading.ParamNameUpd}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="tb-modal-body reading-card">
        <span className="ml-auto edit-body">
          <span className="helper" onClick={decrement}>-</span>
          <MaskedInput className={['text-indicator', 'border-indicatorr', reading.Indicator && reading.Indicator.toLowerCase()].join(' ')}
            mask={mask} guide={false} inputMode="decimal" value={value} 
            onBlur={e => onChangeVal(e.target.value)}
            onChange={e => onChange(e.target.value)}
            size={(value.length > 6) ? (value.length + 1 > 12 ? 12 : value.length + 1) : 7} 
            onKeyDown={onKeyDown} 
            onKeyUp={e => editValue(e.target.value)}
            style={{borderBottomColor: saveClicked && (value === '' || value === 0) && 'red'}} />
          <span className="helper" onClick={increment}>+</span>
        </span> 
        <div className="history-descriptions mt-3">
          { reading.DescValueDictionary && reading.DescValueDictionary.length ? reading.DescValueDictionary.map((description, i) => (
            <div class="button-radio" key={`history-edit-description-${i}`}>
              <input type="radio" id={`history-edit-${description}-${i}`} name="description-radio"
                value={description} onChange={chooseDescription} />
              <label for={`history-edit-${description}-${i}`}>{description}</label>
            </div>
          )): ``}
          { (!reading.DescValueDictionary || !reading.DescValueDictionary.length || showInput) && 
            <input type="text" className="form-control mt-2" placeholder="Enter description" 
              onChange={(e) => setDescription(e.target.value)} />
          }
        </div>
      </Modal.Body>
      <Modal.Footer className="tb-modal-footer">
        <Button size="sm" variant="light" onClick={cancel}>Cancel</Button>
        <Button size="sm" disabled={isSaveDisabled()} onClick={save}>Save</Button>
      </Modal.Footer>
    </Modal>
  
  );
}