import React from 'react';
import SelectBox from 'react-select';

import './Select.scss';

// interface Props {
//   options?: any;
//   isMulti?: boolean;
//   placeHolder?: string;
//   onChange?: (selectedOption: any) => any;
//   value?: any;
//   getOptionLabel?: (option: any) => string;
//   defaultValue?: any;
//   disabled?: boolean;
//   invalid?: boolean;
//   name?: string;
// }

const Select = (props) => {
  const {
    placeholder,
    options,
    value,
    defaultValue,
    isMulti,
    name,
    disabled,
    invalid,
    onChange,
    getOptionLabel,
    className,
    height
  } = props;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,

      color: state.isSelected ? 'white' : 'black',
      padding: 4,
      paddingLeft: 10,
      textAlign: 'left',
      fontSize: 13,
      borderRadius: 0,
      // fontWeight: 500,
    }),

    control: (provided, state) => ({
      opacity: state.isDisabled ? 0.5 : 1,
      display: 'flex',
      border:  invalid ? '1px solid #dc3545' : '1px solid #d7d7d7',
      color: '#000',
      borderRadius: 0,
      height: height,
      // padding: 5,
      fontSize: 13,
      background: 'white',
     
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition, color: '#000', };
    },
    indicatorSeparator: () => ({})
  };

  return (
    <div className={className || ''}>
      <SelectBox
        styles={customStyles}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        getOptionLabel={getOptionLabel}
        defaultValue={defaultValue}
        isMulti={isMulti}
        isDisabled={disabled}
        
      />
    </div>
  );
}

export default Select;
