import React, { Component, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import SearchComponent from '../../common/SearchComponent/SearchComponent';

export default class StaffListCard extends Component {
  state = {
    activeEditIndex: -1,
    modalShow: false,
    searchQuery: ''
  }

  onUpdateStaff = (userId, data) => {
    const { onUpdateStaff } = this.props;
    onUpdateStaff(userId, data);
  }

  onClickDelete = (staff) => {
    this.setState({modalShow: true, selectedStaff: staff});
  }

  onConfirmDelete(id) {
    const { onDelete } = this.props;
    onDelete(id);
    this.setState({modalShow: false, selectedStaff: null});
  }

  onFileSelect = (e, staff) => {
    const { onSelectPhoto } = this.props;
    onSelectPhoto(e.target.files[0], staff.id)
  }

  render() {
    const { staffs, services, isManager, isMcMember, getPhotoURL, isMobile } = this.props;
    const { modalShow, selectedStaff, searchQuery } = this.state;
    let sortedStaffs = [...staffs].sort((a, b) => a.Name.localeCompare(b.Name));
    sortedStaffs = sortedStaffs.sort((a, b) => (a.IsUserReady === b.IsUserReady) ? 0 : a.IsUserReady ? 1 : -1);
    if (searchQuery) {
      sortedStaffs = sortedStaffs.filter(u => u.Name.search(new RegExp(searchQuery, 'i')) !== -1);
    }

    return (
      <div className="settings-card mt-4 mt-md-0">
        <div className="settings-card-header">
          <h5 className="mb-0 font-weight-bold">Manage Staff{`${isMobile ? `s` :` Attendance`}`}
            <SearchComponent placeholder="Search staff..." onChange={(value) => this.setState({searchQuery: value})} />
          </h5>
        </div>
        <div className="settings-card-body">
          { sortedStaffs && sortedStaffs.map((staff, i) => (
            <StaffCard staff={staff} staffService={staff.ServiceArea}
              staffPhoto={staff.PhotoURL} services={services}
              key={staff.id} isMcMember={isMcMember} isManager={isManager}
              onUpdateStaff={this.onUpdateStaff} onDelete={this.onClickDelete}
              onSelectFile={this.onFileSelect} getPhotoURL={getPhotoURL} />
          ))}
        </div>
        <Modal size="sm" centered show={modalShow} onHide={() => this.setState({modalShow: false})}>
          <Modal.Header className="tb-modal-header" closeButton>
            <Modal.Title className="tb-modal-title" as="h5">Confirm</Modal.Title>
          </Modal.Header>
          <Modal.Body className="tb-modal-body">
            {selectedStaff && <p>Do you really want to delete {selectedStaff.Name} ?</p>}
          </Modal.Body>
          <Modal.Footer className="tb-modal-footer">
            <Button size="sm" variant="light" onClick={() => this.setState({modalShow: false})}>No</Button>
            {selectedStaff && <Button size="sm" onClick={this.onConfirmDelete.bind(this, selectedStaff.id)}>Yes</Button>}
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}


const StaffCard = ({
  staff, staffService, staffPhoto, services, onUpdateStaff,
  onDelete, onSelectFile, isManager, isMcMember, getPhotoURL
}) => {
  const [editMode, setEditMode] = useState(false);
  const [serviceArea, setService] = useState([]);
  const [photoURL, setPhotoURL] = useState(null);

  const getProfileURL = async () => {
    const url = await getPhotoURL(staff.PhotoURL);
    setPhotoURL(url);
  }

  useEffect(() => {
    getProfileURL()
  }, [staffPhoto]);

  useEffect(() => {
    if (staff.IsUserReady) setService(staffService);
    else setService('');
  }, [staffService]);

  const save = (data) => {
    onUpdateStaff(staff.id, data);
    setEditMode(false);
  }

  const onClickDelete = () => {
    onDelete(staff);
    setEditMode(false);
  }

  const selectFile = async (e) => {
    onSelectFile(e, staff)
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center">
          <div className="profile-img mr-2">
            {staff.PhotoURL && photoURL ?
              <img alt="logo" src={photoURL}
                className="staffProfileLogo" style={{ height: 50, width: 50, borderRadius: '50%', objectFit: 'cover' }} />
              :
              <div className="d-flex align-items-center justify-content-center altText">{staff.AltText}</div>
            }
            <label htmlFor={`avatar-${staff.id}`}>
              <input type="file" id={`avatar-${staff.id}`} name={`avatar-${staff.id}`}
                accept="image/*" onChange={selectFile} className="d-none" />
            </label>
          </div>
          <div className="">
            <h6 className="font-weight-bold mb-0">{staff.Name}</h6>
            <p className="card-subtitle text-capitalize">{staff.Gender ? staff.Gender.toLowerCase() : ''}</p>
            <p className="card-subtitle" style={{ fontSize: '0.75rem' }}>
              <span><a href={`mailto: ${staff.Email}`}>{staff.Email}</a></span>
              <span>{staff.Email && staff.Phone ? ', ' : ''}</span>
              <span><a href={`tel: ${staff.Phone}`}>{staff.Phone}</a></span>
            </p>
          </div>
        </div>

        <hr />

        <div className="card-text roles">
          <span className={`${!staff.IsUserReady ? ' text-danger font-weight-bold' : ''}`}>
            {staff.IsUserReady ? (staff.ServiceArea + `${staff.ServiceSubArea ? ` - ${staff.ServiceSubArea}` : ``}`) : 'Pending Approval'}
          </span>
        </div>

        { (isManager || isMcMember) && <div className="buttons">
          { !editMode && <button onClick={() => setEditMode(true)}><i className="icon-pencil" /></button>}
        </div>}

        <StaffEditModal show={editMode} onHide={() => setEditMode(false)} selectFile={selectFile} onSave={save}
          onClickDelete={onClickDelete} {...{staff, photoURL, services}} />
      </div>
    </div>
  )
}

const StaffEditModal = (props) => {
  const { staff, photoURL, services } = props;
  const { show, onHide } = props;
  const [staffData, setStaffData] = useState({ });
  useEffect(() => {
    setStaffData({
      Phone: staff.Phone, 
      Email: staff.Email, 
      ServiceArea: staff.ServiceArea,
      Gender: staff.Gender,
      ServiceSubArea: staff.ServiceSubArea || staff.ServiceArea
    });
  }, []);

  return (
    <Modal className="profile" centered show={show} onHide={onHide}>
      <Modal.Header className="tb-modal-header flex-column align-items-center pb-3">
        <div className="profile-img d-flex justify-content-center">
          {staff.PhotoURL && photoURL ?
            <img alt="logo" src={photoURL}
              className="staffProfileLogo" style={{ height: 100, width: 100, borderRadius: '50%', objectFit: 'cover' }} />
            :
            <div className="d-flex align-items-center justify-content-center altText" style={{ height: 100, width: 100, fontSize: '2rem' }}>{staff.AltText}</div>
          }
          <label htmlFor={`avatar-lg-${staff.id}`}>
            <input type="file" id={`avatar-lg-${staff.id}`} name={`avatar-lg-${staff.id}`}
              accept="image/*" onChange={props.selectFile} className="d-none" />
          </label>
        </div>
        <h6 className="font-weight-bold mt-3 mb-0">{staff.Name}</h6>
      </Modal.Header>
      <Modal.Body className="tb-modal-body">
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="" className="col-form-label">Employee Id</label>
            <p>{staff.EmployeeDeviceId}</p>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="" className="col-form-label">Gender</label>              
            <select defaultValue={staffData.Gender} onChange={(e) => setStaffData({...staffData, Gender: e.target.value })}>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="" className="col-form-label">Email</label>
            <input type="text" defaultValue={staffData.Email} onChange={(e) => setStaffData({...staffData, Email: e.target.value })}/>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="" className="col-form-label">Phone</label>
            <input type="text" defaultValue={staffData.Phone} onChange={(e) => setStaffData({...staffData, Phone: e.target.value })}/>
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="" className="col-form-label">Service Area <span className="text-color red">*</span></label>
            <select defaultValue={staffData.ServiceSubArea || staffData.ServiceArea} 
              onChange={e => setStaffData({
                ...staffData, 
                ServiceArea: e.target.options[e.target.selectedIndex].closest('optgroup').getAttribute('label'), 
                ServiceSubArea: e.target.value 
              })}>
              <option value=""> - Service Area - </option>
              {services && Object.keys(services).map((s, i) => (
                <optgroup key={'service-' + s + '-' + i} label={s}>
                  {services[s].length && services[s].map((ser, idx) => (
                    <option key={'srvsub-' + ser + '-' + i} value={ser}>{ser}</option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="tb-modal-footer">
        <a className="cursor-pointer text-danger mr-auto btn-del" onClick={props.onClickDelete}>Delete</a>
        <Button size="sm" variant="light" onClick={props.onHide}>Cancel</Button>
        <Button size="sm" disabled={!staffData.ServiceSubArea} onClick={props.onSave.bind(this, staffData)}>Save</Button>
      </Modal.Footer>
    </Modal>
  )
}
