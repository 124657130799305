import React from "react";
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import store from "./redux/store";
import history from "./history";
import { UserIsAuthenticated, UserIsNotAuthenticated } from "./ProtectedRoute";
import Home from "./components/Home";
import NotFoundPage from "./components/common/NotFoundPage";
import Signin from "./components/Auth/Signin";
import Signup from "./components/Auth/Signup";
import Appartment from "./components/Auth/Appartment";
import ForgetPass from "./components/Auth/Forget";
import Message from "./components/Auth/Message";
import GoogleUser from "./components/Auth/GoogleUser";

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/signin" component={UserIsNotAuthenticated(Signin)} />
        <Route path="/signup1" component={UserIsNotAuthenticated(Appartment)} />
        <Route path="/signup2" component={UserIsNotAuthenticated(Signup)} />
        <Route path="/forget" component={UserIsNotAuthenticated(ForgetPass)} />
        <Route path="/message" component={UserIsNotAuthenticated(Message)} />
        <Route path="/set-appartment" component={UserIsAuthenticated(GoogleUser)} />
        <Route path="/" component={UserIsAuthenticated(Home)} />
        <Route path="*" component={(NotFoundPage)} />
      </Switch>
    </Router>
  </Provider>
);

export default App;
