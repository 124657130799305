import styled from "styled-components";
import { NavLink } from "react-router-dom";
import closeModla from "../../../../../assets/images/modal_close.png";

export const Navbar = styled.nav`
  border-bottom: 0.0625rem solid #e5e5e5;
  width: "100px";
  margin-bottom: 0.9375rem;
`;

export const NavItem = styled.li`
  width: auto !important;
  margin-right: 3rem;
  &:hover {
    background-color: #fafbfd !important;
  }
`;

export const Link = styled(NavLink)`
  border-left: 0px !important;
  color: #373737 !important;
  opacity: 0.3;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  /* margin-right: 3rem; */
  font-family: Inter;
  font-weight: 500;
  &:hover {
    color: #558dca;
    background-color: #ffffff !important;
  }

  &.active {
    color: #558dca !important;
    opacity: 1;
  }
  &.active:after {
    position: absolute;
    bottom: -0.125rem;
    left: 0;
    right: 0;
    width: "100%";
    height: 0.25rem;
    vertical-align: middle;
    content: " ";
    color: #000;
    background-color: #558dca;
    border-radius: 0.125rem;
  }
  @media (max-width:769px){
    &.active {
      background-color : #eff3fa !important;
    }
  }
`;
export const Heading = styled.div`
  /* padding: 0px !important;
  margin: 1.75rem 0px 0px 0rem;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: #3a3a3a; */
  font-family: Inter;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #3a3a3a;
`;
export const SubHeading = styled.div`
  padding: 0px !important;
  margin: 0px 0px 1.5rem 0rem;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  color: #a4a4a4;
`;

export const ModalThead = styled.thead`
  tr {
    background: rgba(85, 141, 202, 0.2);
    th {
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */

      color: #434343;
    }
  }
`;
export const ModalTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #373737;
`;

export const ModalTBody = styled.tbody`
  tr,
  th {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #4f4f4f;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e8e8e8;
`;

export const Image = styled.div`
  background-image: url(${closeModla});
  width: 28px;
  height: 28px;
  position: absolute;
  right: -10px;
  top: -15px;
  border-radius: 20px;
  background-color: white;
`;
