export const initialState = { 
  nextRoute: '/', 
  sideBarOpen: true, 
  isQrModalOpen: false, 
  qrData: null,
  isNotifyOpen: false
}

export function reducer(state = initialState, action) {
  if (action.type === "setNextRoute") {
    return { ...state, nextRoute: action.nextRoute }
  }
  if (action.type === "SIDEBAR_OPEN") {
    return { ...state, sideBarOpen: action.payload }
  }
  if (action.type === "TOGGLE_QR_MODAL") {
    return { ...state, isQrModalOpen: action.payload.val, qrData:action.payload.data }
  }
  if (action.type === "TOGGLE_NOTIFY_MODAL") {
    return { ...state, isNotifyOpen: action.payload }
  }
  return state
}