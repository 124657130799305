import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, firebaseConnect } from 'react-redux-firebase';
import { Modal } from 'react-bootstrap';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import Compress from 'compress.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt, faCamera } from '@fortawesome/free-solid-svg-icons'

import uploadIcon from '../../../assets/images/upload.png'

function TicketFileUpload({ id, className, questionNo, onAnswer, onSave, ...props }) {

  const fileUpload = useRef();
  const [isMobile, setIsMobile] = useState(false);
  const [cameraView, openCameraView] = useState(false);
  const [isWarningModalOpen, showWarningModal] = useState(false);
  const [warningMsg, setWarningMsg] = useState('Please use our mobile app to use this feature');

  useEffect(() => {
    const width = getWidth();
    setIsMobile(width < 768);
  }, [])

  const getWidth = () => {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    )
  };

  const uploadFileHandler = async (e) => {
    const { files } = e.target;
    onAnswer(files)
  }

  const onClickCamera = () => {
    if (!window.cordova && !isMobile) {
      setWarningMsg("Please use our mobile app to use this feature")
      showWarningModal(true);
      return
    }
    if (window.cordova) {
      navigator.camera.getPicture(async(uri)=>{
        const url = `data:image/jpeg;base64,${uri}`;
        onConfirm(url);
      }, (err)=>{
        setWarningMsg(err);
        showWarningModal(true);
      }, { destinationType : window.Camera.DestinationType.DATA_URL });
    } else {
      openCameraView(true)
    }
  }

  const onConfirm = async (image) => {
    const url = image;
    let blob = await fetch(url).then(res => res.blob());
    let file = new File([blob], `incident-${id}`, {type: 'image/jpeg'});
    const compress = new Compress();
    const data = await compress.compress([file], { size: 1, resize: false });
    blob = await fetch(data[0].prefix + data[0].data).then(res => res.blob());
    file = new File([blob], `${new Date().getTime()}`, {type: 'image/jpeg'});
    openCameraView(false);
    onAnswer([file])
  }

  return (
    <>
    <div id={id + ' incident-file-upload'} className={["p-3 h-100 d-flex flex-column justify-content-center q-block", className].join(' ')}>
      <p className="mb-3 question">{questionNo}. Upload Files ?</p>
      <div className="d-flex">
        <label className="tf-file-upload mr-3"  onClick={onClickCamera}>
          <FontAwesomeIcon  icon={faCamera} size="2x" />
        </label>
        <label htmlFor="ticket-file-upload" className="tf-file-upload">
          <FontAwesomeIcon  icon={faCloudUploadAlt} size="2x" />
        </label>
        <input
          ref={fileUpload}
          type="file"
          onChange={uploadFileHandler}
          className="form-control tf-input d-none"
          id="ticket-file-upload"
        />
      </div>
      <div className="mt-5">
        <button className="btn btn-light mt-4" onClick={onSave}>
          Create
        </button>
      </div>
    </div>
    <Modal centered show={isWarningModalOpen} onHide={() => showWarningModal(false)}>
      <Modal.Header className="tb-modal-header pt-2 pb-0" closeButton>
      </Modal.Header>
      <Modal.Body className="pt-2 pb-0">
      {warningMsg}
      </Modal.Body>
      <Modal.Footer className="tb-modal-footer pt-2 pb-3">
        <button className="btn btn-sm btn-danger" onClick={() => showWarningModal(false)}>OK</button>
      </Modal.Footer>
    </Modal>
    { cameraView && <TaskCamera onCancel={() => openCameraView(false) } onConfirm={onConfirm} />}
    </>
  )
}

const TaskCamera = ({onCancel, onConfirm}) => {
  const [preview, showPreview] = useState(false);
  const [prwImage, setPrwImage] = useState(null);

  useEffect(() => {
    showPreview(false);
  }, [])

  const capture = (imgSrc) => {
    setPrwImage(imgSrc);
    showPreview(true);
  }
  
  const onApprove = async () => {
    onConfirm(prwImage);
    showPreview(true);
  }
  
  const onReject = () => {
    onCancel();
    showPreview(true);
  }

  return (
    <div className="tb-camera">
      { !preview && <button className="btn btn-sm btn-close" onClick={() => onCancel()}><i className="icon-close" /></button> }
      { !preview ? <Camera idealFacingMode={FACING_MODES.ENVIRONMENT} idealResolution={{width: 640, height: 480}}
        imageType={IMAGE_TYPES.JPG} onTakePhoto={ (dataUri) => { capture(dataUri); } } isSilentMode
        isMaxResolution isImageMirror={false} sizeFactor={1} isFullscreen/> :
      <img src={prwImage} alt=""/> }
      { preview && <>
        <button className="btn btn-sm btn-close btn-cancel" onClick={() => onReject()}><i className="icon-close" /></button>
        <button className="btn btn-sm btn-close btn-confirm" onClick={() => onApprove()}><i className="icon-tick" /></button>
      </> }
    </div>
  )
}

const mapStateToProps = (state) => ({
	user: state.firestore.ordered.Users && state.firestore.ordered.Users,
})

const mapDispatchToProps = {

}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firestoreConnect(),
	firebaseConnect(),
)(TicketFileUpload);