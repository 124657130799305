import React, { Component, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Loading from '../../common/Loading/Loading';
import * as moment from 'moment';

export const DRLocalHostoryComponent = (props) => {
  const { drhShow, hidedrHistory, drhLoading, drhReading, drHistory, savedrHistory } = props;
  const [showFullHistory, setShowFullHistory] = useState(false);

  useEffect(() => {
    setShowFullHistory(false);
  }, [drhReading])

  const saveDRH = (data) => {
    savedrHistory(drhReading.id, data);
  }

  return (
    <Modal centered show={drhShow} onHide={hidedrHistory}>
      <Modal.Header className="tb-modal-header" closeButton>
        <Modal.Title className="tb-modal-title" as="h5">{drhReading && drhReading.ParamName}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="tb-modal-body">
        { 
        drhLoading ? 
        <Loading showLogo="false" height="9rem" /> 
        :
        <div>
        { drHistory && drHistory.length ? <>
          { drHistory.slice(0, drHistory.length).length ? 
          <div className="history-block">
            <ul className="timeline extra">
              { drHistory.slice(0, showFullHistory ? drHistory.length : 3).map((history, i) => (
                <HistoryItem dataDate={moment(history.LastUpdatedDate.toDate()).format('D MMM YYYY hh:mm a')} key={i}
                  item={history} reading={drhReading} saveDetails={saveDRH} />
              ))}
            </ul>
            { drHistory.slice(3, drHistory.length).length ? 
            <a className="helper" onClick={() => setShowFullHistory(!showFullHistory)}>
              <u>{`${!showFullHistory ? 'show' : 'hide'} full history`}</u>
            </a> : '' }
          </div> : '' } </>  : 
          <p className="text-secondary"><small>No history</small></p> 
        }
        </div>
        }
      </Modal.Body>
      <Modal.Footer className="tb-modal-footer"></Modal.Footer>
    </Modal>
  )
}

export const HistoryItem = (props) => {
  const { item, dataDate, saveDetails, reading } = props;
  const [isEditing, setIsEditing] = useState(false);

  const cancelEdit = () => {
    setIsEditing(false);
  }

  const save = (description) => {
    saveDetails({id: item.id, Description: description});
    cancelEdit();
  }

  return (
    <li className="text-nowrap">
      <div className="timeline-left">
        <small className="text-dark">{dataDate}</small>
        <small>{item.LastUpdatedBy}</small>
      </div>
      <span className={['badge-indicator', item.Indicator && item.Indicator.toLowerCase()].join(' ')}></span>
      <div className="d-inline-flex flex-column">
        <span className="text-dark font-weight-bold">
          { reading.ParamUnitLeftAlign && item.ParamUnit } { item.ParamValue } { !reading.ParamUnitLeftAlign && item.ParamUnit }
          { item.IsEditable && !isEditing && <a onClick={() => setIsEditing(true)}><i className="icon-pencil ml-1" /></a> }
        </span>
        <small className="text-wrap">{item.Description}</small>
      </div>
      <HistoryEditModal {...{reading, item, save}} isEditing={isEditing} hideEditHistory={() => setIsEditing(false)} />
    </li>
  )
}

export const HistoryEditModal = (props) => {
  const { isEditing: show, hideEditHistory: onHide, save, reading, item} = props;
  const [selectedValue, setSelectedValue] = useState('');
  const [description, setDescription] = useState('');

  const chooseDescription = (e) => {
    setDescription('');
    if (e.target.value !== 'Others') {
      setSelectedValue(e.target.value);
    } else {
      setSelectedValue('');
    }
  }

  const onSave = () => {
    save(selectedValue && description ? `${selectedValue} ${description}` : (selectedValue || description));
    setDescription('');
  }

  const onCancel = () => {
    onHide();
    setDescription('');
  }
  
  return (
    <Modal size="md" centered show={show} onHide={onCancel}>
      <Modal.Header className="tb-modal-header" closeButton>
        <Modal.Title className="tb-modal-title" as="h5">
          { reading.ParamUnitLeftAlign && item.ParamUnit } { item.ParamValue } { !reading.ParamUnitLeftAlign && item.ParamUnit }
          <small className="text-muted"> - {moment(item.LastUpdatedDate.toDate()).format('D MMM YYYY hh:mm a')}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{minHeight: '200px'}} className="tb-modal-body d-flex align-items-center">
        <div className="history-descriptions">
          { reading.DescValueDictionary && reading.DescValueDictionary.length && reading.DescValueDictionary.map((description, i) => (
            <div class="button-radio" key={`history-edit-description-${i}`}>
              <input type="radio" id={`history-edit-${description}-${i}`} name="description-radio" value={description} onChange={chooseDescription} />
              <label for={`history-edit-${description}-${i}`}>{description}</label>
            </div>
          ))}
          <input type="text" className="form-control mt-2" placeholder="Enter description" 
            onChange={(e) => setDescription(e.target.value)} value={description}  />
        </div>
      </Modal.Body>
      <Modal.Footer className="tb-modal-footer">
        <Button size="sm" variant="light" onClick={onCancel}>Cancel</Button>
        <Button size="sm" disabled={!selectedValue && !description} onClick={() => onSave(description)}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DRLocalHostoryComponent;