import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
`;

export const AddPropery = styled.button`
  display: none !important;
  background: #558dca;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.625rem;
  border: 0.09375rem solid #ffffff;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
`;
export const Property = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Styles = styled.div`
  background: #ffffff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  width: 100%;
  height: calc(100vh - 135px);
  table {
    width: inherit;
    th {
      font-family: Inter;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: #434343;
      padding: 0.625rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      background-color: rgba(85, 141, 202, 0.1);
    }
    td {
      padding: 0.625rem;
      border-bottom: solid 0.0625rem #e8e8e8;
      font-size: 0.875rem;
      /*background: #fff;
            
        }
        /* tbody {
            tr {
                td {
                    font-family: Inter;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.875rem;
                    line-height: 1.0625rem;
                    color: #4F4F4F;
                    &:first-child {
                        font-family: Inter;
                        font-style: normal;
                        font-weight: 500;
                        color: #4F4F4F;
                    }
                }
                &:hover td {
                        background-color: rgba(85, 141, 202, 0.1) !important;
                }
                &:hover td ${AddPropery} {
                    display: flex !important;
                }
            }
        }  */
    }
    tr {
      td {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 1.0625rem;
        color: #4f4f4f;
        &:first-child {
          font-family: Inter;
          font-style: normal;
          font-weight: 500;
          color: #4f4f4f;
        }
      }
    }
  }
`;
export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #5a5a5a;
`;

export const ProperyCount = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  text-decoration-line: underline;
  color: #558dca;
  margin-right: 1rem;
`;

export const AddZone = styled.button`
  background: #558dca;
  border: 0.0625rem solid #558dca;
  box-sizing: border-box;
  border-radius: 0.1875rem;
  padding: 0.6875rem 1rem 0.6875rem 0.8125rem;
  color: #fff;
`;
export const ButtonText = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #ffffff;
`;
export const Action = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #558dca;
`;
export const Avatar = styled.img`
  height: 1.625rem;
  width: 1.625rem;
  margin-right: 0.8125rem;
`;

export const Search = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
  /* margin-right: 1rem; */
  padding: 0.4rem;
  i {
    color: #b6b6b6;
  }
`;

export const SearchInput = styled.input`
  border: 0;
  outline: none;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #b6b6b6;
  }
`;
export const ModalTitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  /* identical to box height */
  color: #5a5a5a;
`;

export const ModalLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #4f4f4f;
`;
export const ModalInput = styled.input`
  outline: none;
  background: #ffffff;
  border: 0.0625rem solid #cccccc;
  box-sizing: border-box;
  border-radius: 0.1875rem;
`;
export const ModalClose = styled.button`
  width: 1.75rem;
  height: 1.75rem;
  background: #ff8d8d;
  border: none;
  color: #fff;
`;

export const ContinueContainer = styled.button`
  background: #558dca;
  border: 0.0625rem solid #558dca;
  box-sizing: border-box;
  border-radius: 0.1875rem;
  padding: 0.6875rem 1.5rem;
`;
export const Continue = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #ffffff;
`;

export const HoverButton = styled.button`
  font-family: Inter;
  font-style: normal;
  font-size: 0.8rem;
`;

/* Auto Select */
const brandColor = "#CCCCCC";

export const customStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: state.isFocused ? 0 : 0,
    borderColor: state.isFocused ? brandColor : base.borderColor,
    "&:hover": {
      borderColor: state.isFocused ? brandColor : base.borderColor,
    },
  }),
};
