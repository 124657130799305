/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-multi-comp */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
/* eslint-disable arrow-body-style */
import React, { useEffect } from "react";
import {
  Button,
  Dropdown,
  Modal,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import Select from "react-select";
import { useTable, useGlobalFilter } from "react-table";

import {
  Header,
  Styles,
  Title,
  Search,
  SearchInput,
  ModalTitle,
  ModalLabel,
  ModalClose,
  ContinueContainer,
  Continue,
  customStyles,
  HoverButton,
} from "./styles";
import ZoneCard from "../Components/ZoneCard/ZoneCard";
import "./reactTags.css";
import { InputCheckBox } from "../../../../../Auth/SigninStyles";

const Table = ({ columns, data, getRowProps }) => {
  const [filterInput, setFilterInput] = React.useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable({ columns, data }, useGlobalFilter);

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
    setFilterInput(value || "");
  };
  return (
    <>
      <Header className="row">
        <Title className="col-md col-12 p-0">Users</Title>
        <Search className="col-md-3 col-12 mt-2 mt-md-0">
          <SearchInput
            type="text"
            className="w-100"
            value={filterInput}
            onChange={handleFilterChange}
            placeholder="Search"
          />
          <i className="icon-search" />
        </Search>
      </Header>
      <div className="overflow-auto w-100">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell) => {
                    return (
                      <td style={{ padding: 20 }} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const Users = ({ firestore, levelInfo, consolidated, ConsViews }) => {
  const [data, setData] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchUsers = () => {
    if (!levelInfo) return;
    let users = [];
    setIsLoading(true);
    firestore
      .get({
        collection: "Users",
        where: [["is_cons_user", "==", true]],
        storeAs: "ConsUsers",
      })
      .then(async (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          users = [...users, { ...doc.data() }];
        });

        users = users.filter((user) => user.UID !== levelInfo.UID);
        // console.log('levelInfo.MaxLevel :',levelInfo.MaxLevel)
        // console.log('users  :',users)
        await Promise.all(
          (users || []).map(async (user) => {
            return firestore
              .get({
                collection: "Users",
                doc: user.UID,
                subcollections: [
                  {
                    collection: "ConsViews",
                    where: [
                      ["ConsViewId", "==", levelInfo.ConsViewId],
                      // ["Level", "<", levelInfo.MaxLevel],
                    ],
                  },
                ],
                storeAs: "AdminUsersList",
              })
              .then(async (doc) => {
                if (doc.empty) {
                  return false;
                }
                const index = users.findIndex((x) => x.UID === user.UID);
                let ConsViews = {};
                doc.forEach((doc) => {
                  ConsViews = { UID: doc.id, ...doc.data() };
                });
                users[index].ConsViews = ConsViews;
              });
          })
        ).then(() => {
          setIsLoading(false);
          const usersList = (users || [])
            .filter((user) => user.ConsViews && !user.ConsViews.is_deleted)
            .map((user) => {
              return {
                UID: user.UID,
                user_name: user.DisplayName,
                contact: user.PhoneNo,
                email: user.Email,
                zone: user.ConsViews ? user.ConsViews.Zones : [],
                level: user.ConsViews ? user.ConsViews.Level : null,
                role: user.ConsViews ? user.ConsViews.Role : null,

                ConsViewDocId: user.ConsViews ? user.ConsViews.UID : null,
                is_approved: user.is_approved,
                is_cons_admin: user.ConsViews? user.ConsViews.is_cons_admin:false,
                showAction: ConsViews[0].is_cons_admin,
              };
            });

          setData(usersList);
        });
      })
      .catch(() => setIsLoading(false));
  };
  React.useEffect(() => {
    if (consolidated && consolidated.length) fetchUsers();
  }, [JSON.stringify(levelInfo), consolidated]);

  const [edit, setEdit] = React.useState(false);
  const [remove, setRemove] = React.useState(false);
  const [levels, setLevels] = React.useState(null);
  const [zoneError, setZoneError] = React.useState(null);
  const [levelError, setLevelError] = React.useState(null);
  const zones = React.useMemo(
    () =>
      ((levelInfo && levelInfo.Zones) || []).map((zone) => ({
        value: zone,
        label: zone,
      })),
    [levelInfo]
  );

  const [selectedZones, setSelectedZones] = React.useState(null);
  const [selectedLevel, setSelectedLevel] = React.useState(null);
  const [adminUser, setAdminUser] = React.useState(false);

  const handleClose = () => {
    setSelectedUser(null);
    setSelectedLevel("");
    setSelectedZones("");
    setEdit(false);
    setRemove(false);
    setAdminUser(false)
  };

  const deleteUser = () => {
    const { UID, ConsViewDocId } = selectedUser;

    firestore
      .update(
        {
          collection: "Users",
          doc: UID,
          subcollections: [{ collection: "ConsViews", doc: ConsViewDocId }],
        },
        { is_deleted: true }
      )
      .then(async () => {
        setRemove(false);
        setSelectedUser(null);
        fetchUsers();
      });
  };

  const onEdit = (user) => {
 
    const { zone, role, is_cons_admin } = user;
    if(is_cons_admin){
      setAdminUser(true)
    }
    setSelectedUser(user);
    setEdit(true);
    setZoneError(null);
    setLevelError(null);
    const currentZone = (zone||[]).map((zne) => ({ value: zne, label: zne }));

    let roleList = [];
    const roleLevel = consolidated && consolidated[0].RoleToLevel;
    if (roleLevel) {
      roleList = Object.keys(roleLevel).map((key) => ({
        label: key,
        value: key,
        role: roleLevel[key],
      }));
    }
    // console.log('roleList :',roleList)

    // const filteredRoleBasedOnMaxLevel = roleList.filter(
    //   ({ role }) => role < levelInfo.MaxLevel
    // );
    setLevels(roleList);

    setSelectedZones(currentZone);
    setSelectedLevel({ value: role, label: role });
  };

  const onDelete = (user) => {
    setRemove(true);
    setSelectedUser(user);
  };

  const saveUser = () => {
    setZoneError(null);
    setLevelError(null);
    const { UID, ConsViewDocId, is_approved } = selectedUser;
    const roleLevel = consolidated && consolidated[0].RoleToLevel;
    const selectedZone = (selectedZones || []).map(({ value }) => value);
    if (!selectedZone.length) {
      setZoneError("Please select a Zone");
      return false;
    }
    const role = selectedLevel.value;
    if (!role) {
      setLevelError("Please select a Level");
      return false;
    }

    
    const level = roleLevel[role];

    if(!is_approved){
      // console.log('Not a approved user',is_approved,UID)
      
      firestore
      .update(
        {
          collection: "Users",
          doc: UID
        },
        { is_approved: true }
      )
      .then(() => {
        // console.log("Updated ");
  
      })
      .catch((err) => console.log(err));
    }

    firestore
      .update(
        {
          collection: "Users",
          doc: UID,
          subcollections: [
            {
              collection: "ConsViews",
              doc: ConsViewDocId,
            },
          ],
        },
        { Role: role, Level: level, Zones: selectedZone, is_cons_admin: adminUser }
      )
      .then(() => {
        // console.log("Updated ");
        fetchUsers();
        handleClose();
      })
      .catch((err) => console.log(err));
  };

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value] = React.useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-group">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));
  const columns = React.useMemo(
    () => [
      {
        Header: "User Name",
        accessor: "user_name",
      },
      {
        Header: "Phone Number",
        accessor: "contact",
      },
      {
        Header: "Email id",
        accessor: "email",
      },
      {
        Header: "Zones",
        accessor: "zone",
        Cell: ({
          cell: {
            row: {
              original: { zone },
            },
          },
        }) => {
          const _zone = zone?zone[0] : '';
          if (!_zone) return "";
          const popover = (
            <Popover id="popover-basic">
              {/* <Popover.Title as="h3">Popover right</Popover.Title> */}
              <Popover.Content>
                <ul className="list-group list-group-flush">
                  {zone.map((_zone, index) => {
                    return (
                      <ZoneCard
                        key={_zone}
                        zone={_zone}
                        hideUnderline={zone.length === index + 1}
                      />
                    );
                  })}
                </ul>
              </Popover.Content>
            </Popover>
          );
          return (
            <OverlayTrigger trigger="hover" placement="right" overlay={popover}>
              <HoverButton className="btn btn-outline-secondary">
                {_zone}
                {zone.length > 1 ? (
                  <>
                    &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="8"
                      height="5"
                      viewBox="0 0 8 5"
                      fill="none"
                    >
                      <path
                        d="M1 1L4 4L7 1"
                        stroke="#B5B5B5"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </>
                ) : null}
              </HoverButton>
            </OverlayTrigger>
            // <div className="row">
            //   <ZoneCard zone={_zone} hideUnderline />
            //   <OverlayTrigger
            //     rootClose
            //     trigger="click"
            //     // key="bottom"
            //     placement="right"
            //     overlay={
            //       <Popover
            //         // id="popover-positioned-bottom"
            //         style={{ maxWidth: "350px" }}
            //       >
            //         <Popover.Content>
            //           {zone.map((_zone, index) => {
            //             return (
            //               <ZoneCard
            //                 key={_zone}
            //                 zone={_zone}
            //                 hideUnderline={zone.length === index + 1}
            //               />
            //             );
            //           })}
            //         </Popover.Content>
            //       </Popover>
            //     }
            //   >
            //     {zone.length > 1 ? (
            //       <button style={{ background: "none", border: "none" }}>
            //         <svg
            //           xmlns="http://www.w3.org/2000/svg"
            //           width="8"
            //           height="5"
            //           viewBox="0 0 8 5"
            //           fill="none"
            //         >
            //           <path
            //             d="M1 1L4 4L7 1"
            //             stroke="#B5B5B5"
            //             strokeWidth="1.5"
            //             strokeLinecap="round"
            //             strokeLinejoin="round"
            //           />
            //         </svg>
            //       </button>
            //     ) : (
            //       <div />
            //     )}
            //   </OverlayTrigger>
            // </div>
          );
        },
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "",
        accessor: "action",
        Cell: ({ cell }) => {
          if (!cell.row.original.showAction) {
            return null;
          }
          return (
            <Dropdown drop="left">
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                ...
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                <Dropdown.Item
                  className="text-right"
                  onClick={() => onEdit(cell.row.original)}
                  eventKey="1"
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-right"
                  onClick={() => onDelete(cell.row.original)}
                  eventKey="2"
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    firestore.get({
      collection: "consolidated",
      where: [["ConsViewId", "==", ConsViews[0].ConsViewId]],
      storeAs: "consolidated",
    });
  }, [ConsViews]);

  if (isLoading) {
    return <div className="text-secondary mt-5">Loading...</div>;
  }
  return (
    <>
      <Styles>
        <Table
          columns={columns}
          data={data}
          getRowProps={(row) => {
            return {
              style: {
                background: row.original.is_approved ? "#fff" : "#FFBF00",
              },
            };
          }}
        />
      </Styles>
      <Modal show={edit} onHide={handleClose}>
        <Modal.Header>
          <ModalTitle>Edit User</ModalTitle>
          <ModalClose onClick={handleClose} className="rounded-circle">
            X
          </ModalClose>
        </Modal.Header>
        <Modal.Body>
          <ModalLabel className="mb-1">Zone</ModalLabel>
          <Select
            styles={customStyles}
            placeholder="Zone Name"
            defaultValue={selectedZones}
            onChange={setSelectedZones}
            isMulti
            options={zones}
          />
          {zoneError}
          <ModalLabel className="mb-1 mt-4">Level</ModalLabel>
          <Select
            styles={customStyles}
            placeholder="Role"
            defaultValue={selectedLevel}
            onChange={setSelectedLevel}
            options={levels}
          />
           {levelError}

           <InputCheckBox
                type="checkbox"
                className="mr-1 mb-2 mt-4"
                id="admin_user"
                checked={adminUser}
                onChange={() => {
                  setAdminUser(!adminUser)
                  // this.setState((prevState) => ({
                  //   is_consolidated_user: !prevState.is_consolidated_user,
                  //   consolidatedViewId: !prevState.is_consolidated_user
                  //     ? Object.keys(managedBy)[0]
                  //     : null,
                  // }));
                }}
              />
               <label className="form-check-label pl-1" htmlFor="admin_user">
                Is Admin user
              </label>
        </Modal.Body>
        <Modal.Footer>
          <ContinueContainer onClick={() => saveUser()}>
            <Continue>Save</Continue>
          </ContinueContainer>
        </Modal.Footer>
      </Modal>
      <Modal show={remove} onHide={handleClose}>
        <Modal.Header>
          <ModalTitle>Remove</ModalTitle>
          <ModalClose onClick={handleClose} className="rounded-circle">
            X
          </ModalClose>
        </Modal.Header>
        <Modal.Body>Do you want to delete this user?</Modal.Body>
        <Modal.Footer onClick={deleteUser}>
          <Button variant="danger">Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default compose(
  firestoreConnect(),
  connect(
    ({
      firestore: {
        ordered: { consolidated, ConsViews },
      },
    }) => {
      return {
        consolidated,
        ConsViews,
      };
    }
  )
)(Users);
