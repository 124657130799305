import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglassStart, faHourglassEnd } from '@fortawesome/free-solid-svg-icons'
import createHistory from "../../history";
import SystemUpdateModal from '../UpdateCards/SystemUpdateModal';
import SearchBarFilter from '../common/SearchBarFilter';
import Loading from '../common/Loading/Loading';
import '../../App.css';

class ServiceUpdate extends Component {
    constructor() {
        super()
        this.state = {
            focusSearch: false,
            searchValue: '',
        }
    }

    componentDidMount = () => {
        const { location, ServiceIntervalDetails } = this.props;
        const { name } = location.state;        
        if (!name || name === 'NA' || !ServiceIntervalDetails) {
            createHistory.replace('/services')
        }
    }

    getIndicator = (color) => {
        switch (color) {
            case "Green":
                return <div className="mr-2 sysgreen" />;
            case "Red":
                return <div className="mr-2 sysred" />;
            case "Amber":
                return <div className="mr-2 sysamber" />;
            default:
                break;
        }
    };

    openModal = (EquipmentName, EquipmentStatus, id, data) => {
        const { showModal, location } = this.props;
        const { name } = location.state;
        this.setState({ selectedData: data })
        showModal(name, EquipmentName, EquipmentStatus, id)
    }

    onTextChange = (e) => {
        this.setState({ searchValue: e.target.value })
    }

    filterData = () => {
        const { searchValue } = this.state;
        const fileList = this.finalData.filter((file) => {
            if (file.ServiceTitle !== null) {
                if (file.ServiceTitle.toLowerCase().includes(searchValue.toLowerCase())) {
                    return file;
                }
            }
        });
        this.data = fileList;
    }


    render() {
        const { ServiceIntervalDetails, location } = this.props;
        const { selectedData, focusSearch, searchValue } = this.state;
        const { name } = location.state;
        this.finalData = ServiceIntervalDetails;

        const components = [];
        if (ServiceIntervalDetails) {
            this.data = []
            this.filterData();
            this.data.forEach(item => {
                if (item.ServiceName === name) {
                    const index = components.findIndex((a) => a === item.ComponentName)
                    if (index === -1) { components.push(item.ComponentName) }
                }
            })
        }

        return (
            <div className="col-12 d-flex flex-column align-items-center p-0">
                <SystemUpdateModal doubleSide serviceData={selectedData} />
                <div className="d-flex align-items-center nav_container" >
                    <button type="submit" className="buttonrmstyle" onClick={() => createHistory.goBack()}>
                        <img className="back-arrow" alt="back" src={require('../../assets/images/left-arrow.png')} />
                    </button>
                    <span className="heading">
                        {name}
                    </span>
                </div>
                <div className="col-11">
                    <SearchBarFilter
                        focusSearch={focusSearch}
                        onTextChange={this.onTextChange}
                        onFocus={() => this.setState({ focusSearch: true })}
                        onBlur={() => this.setState({ focusSearch: false })}
                        nameSort={searchValue}
                        placeholder="Enter Service Title"
                    />
                </div>                
                <div className='d-flex col-11 flex-wrap mb-5 p-0'>
                    {components.length !== 0 && components.map(item => (
                        <div className="ComponentCard">
                            <div className="ComponentHead">{item}</div>
                            <div className="d-flex flex-wrap">
                                {this.data && this.data.map(data => (
                                    item === data.ComponentName && (
                                        <div className="mr-5 p-2 pt-2">                                            
                                            <div className="d-flex">
                                                {this.getIndicator(data.Indicator)}
                                                <div className="EquipmentStatus mt-1">{data.ServiceTitle}</div>
                                                <button type='submit' className="pencilButton" onClick={() => this.openModal(data.EquipmentName, data.EquipmentStatus, data.id, data)}>
                                                    <img className="pencil" alt="back" src={require('../../assets/images/pencil.png')} />
                                                </button>
                                            </div>
                                            <div className="adhocTaskDate">                                                
                                                <div className="LastUpdatedDate" style={{ marginTop: 2, }}>Next Service :</div>                                                
                                                <div className="d-flex align-items-center">
                                                    <FontAwesomeIcon icon={faHourglassEnd} color="#A6A6A6" />
                                                    <div className="LastUpdatedDate" style={{ marginTop: 2, marginLeft: 10 }}>{data.UpcomingServiceOn}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        )
    }
}

ServiceUpdate.defaultProps = {
    user: [{ Email: '' }],
};

const mapStateToProps = state => ({
    user: state.firestore.ordered.Users,
    ServiceIntervalDetails: state.firestore.ordered.ServiceIntervalDetails,    
});


const mapDispatchToProps = dispatch => ({
    showModal: (moduleName, equipment, condition, document) => dispatch({
        type: "SHOW_MODAL",
        moduleName,
        equipment,
        condition,
        document
    })
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(), withRouter
)(ServiceUpdate);