/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { Form } from "react-bootstrap";
import {
  Button,
  Container,
  SubContainer,
  Input,
  Line,
  Buttons,
  Logo,
  LogoW,
  GoogleLabel,
  InactiveWords,
  Links,
  InputCheckBox,
} from "./SigninStyles";
// import google from '../../assets/images/google-icon.png'
import createHistory from "../../history";

const axios = require("axios");

class Signup extends Component {
  state = {
    username: "",
    mobile: "",
    email: "",
    password: "",
    is_consolidated_user: false,
    consolidatedViewId: null,
    loading: false,
  };

  componentDidMount() {
    const { location } = this.props;
    if (!location.state) {
      createHistory.push("/signup1");
    }
  }

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      [`${e.target.name}Error`]: false,
    });

  onSubmit = (e) => {
    e.preventDefault();
    if (this.checkError()) {
      return null;
    }
    this.setState({
      loading: true,
    });
    const { email, password, username, mobile } = this.state;
    const { firebase, clearFirestore, setErrorMessage } = this.props;

    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((data) => {
        this.setState({
          loading: false,
        });
        clearFirestore();
        firebase.logout();
        setErrorMessage("", "Show Message");
        this.writeUserData(data.user.uid, username, mobile);
      })
      .catch((er) => {
        setErrorMessage(er.message, "Show Message");
        createHistory.push("/message");
        this.setState({
          loading: false,
        });
      });
  };

  writeUserData = (uid, username, mobile) => {
    const { is_consolidated_user, consolidatedViewId } = this.state;
    const { location, setErrorMessage } = this.props;
    axios
      .post(
        "https://asia-east2-trackbot-prod.cloudfunctions.net/assignUserToAptAfterSignUp",
        {
          uid,
          aptname: location.state.appartment,
          displayName: username,
          phoneNo: mobile,
          consolidatedViewId: is_consolidated_user ? consolidatedViewId : null,
        }
      )
      .then((res) => {
        setErrorMessage(res.data, "Show Message");
      });
  };

  checkError = () => {
    const { email, password, username, mobile } = this.state;
    if (email.length === 0 || password.length === 0 || username.length === 0 || mobile.length === 0) {
      if (email.length === 0) {
        this.setState({ emailError: true });
      }
      if (password.length === 0) {
        this.setState({ passwordError: true });
      }
      if (username.length === 0) {
        this.setState({ usernameError: true });
      }
      if (mobile.length === 0) {
        this.setState({ mobileError: true });
      }
      return true;
    }
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) === false) {
      this.setState({ emailError: true });
      return true;
    }
    return false;
  };

  login = () => {
    const { setErrorMessage } = this.props;
    createHistory.push("/signin");
    setErrorMessage(null);
  };

  google = () => {
    const { firebase, setErrorMessage, clearFirestore } = this.props;

    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const { user } = result;
        clearFirestore();
        firebase.logout();
        setErrorMessage("", "Show Message");
        this.writeUserData(user.uid, user.displayName, user.phoneNumber);
      });
  };

  render() {
    const { location } = this.props;
    const {
      email,
      password,
      loading,
      username,
      mobile,
      is_consolidated_user,
      emailError,
      usernameError,
      passwordError,
      mobileError
    } = this.state;
    const { managedBy } = location.state;

    return (
      <Container className="d-flex flex-column align-items-center">
        <SubContainer className="d-flex flex-column align-items-center">
          <Logo src={require("../../assets/images/logoOnly.png")} alt="logo" />
          <LogoW
            src={require("../../assets/images/logoWordmarkBlack.png")}
            alt="logo"
          />
          <Links className="mt-4 mb-0">
            Signup for {location.state.appartment}
          </Links>
          <Input
            id="username"
            name="username"
            value={username}
            onChange={this.handleChange}
            placeholder="Full Name"
            className="mb-4"
            style={{ marginTop: 30 }}
            error={usernameError}
          />
          <Input
            id="email"
            name="email"
            type="email"
            value={email}
            onChange={this.handleChange}
            placeholder="Email Address"
            className="mb-4"
            error={emailError}
          />
          <Input
            name="password"
            type="password"
            id="password"
            value={password}
            onChange={this.handleChange}
            placeholder="Password"
            className=" mb-4"
            error={passwordError}
          />
          <Input
            name="mobile"
            id="mobile"
            type="number"
            pattern="\d*"
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "-" || evt.key === "+") &&
              evt.preventDefault()
            }
            value={mobile}
            onChange={this.handleChange}
            placeholder="Mobile number"
            className="mb-4"
            error={mobileError}
          />

          {managedBy ? (
            <div className="form-check mb-4 pl-0">
              <InputCheckBox
                type="checkbox"
                className="mr-1 mb-4"
                id="exampleCheck1"
                checked={is_consolidated_user}
                onChange={() => {
                  this.setState((prevState) => ({
                    is_consolidated_user: !prevState.is_consolidated_user,
                    consolidatedViewId: !prevState.is_consolidated_user
                      ? Object.keys(managedBy)[0]
                      : null,
                  }));
                }}
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                Select If a Backoffice User
              </label>

              {is_consolidated_user ? (
                <Form.Group controlId="exampleForm.ControlSelect1">
                  {/* <Form.Label>Example select</Form.Label> */}
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      this.setState({ consolidatedViewId: e.target.value });
                    }}
                  >
                    {Object.keys(managedBy).map((key) => (
                      <option key={key} value={key}>
                        {managedBy[key]}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              ) : null}
            </div>
          ) : null}

          <Button disabled={loading} onClick={this.onSubmit}>
            {!loading ? (
              <div
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: 14,
                  fontFamily: "Nunito",
                }}
              >
                Signup
              </div>
            ) : (
              <span
                className="spinner-border spinner-border-sm text-light"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
          <div className="d-flex mt-3">
            <InactiveWords>
              <Links
                onClick={() => {
                  createHistory.goBack();
                }}
              >
                Choose another Apartment
              </Links>
            </InactiveWords>
          </div>
          <div className="d-flex mt-3">
            <InactiveWords>
              Already a User?<Links onClick={this.login}>Login Here</Links>
            </InactiveWords>
          </div>
        </SubContainer>
        <img
          src={require("../../assets/images/building.png")}
          className="col-12"
          alt="Logo"
          style={{ position: "absolute", bottom: 0, zIndex: 100 }}
        />
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearFirestore: () => dispatch({ type: "@@reduxFirestore/CLEAR_DATA" }),
  setErrorMessage: (message, errorType) =>
    dispatch({ type: "ERROR_VALUE", message, errorType }),
});

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  authError: state.firebase.authError,
  nextRoute: state.navigation.nextRoute,
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect()
)(Signup);
