/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import * as _ from "lodash";
import { firestoreConnect } from "react-redux-firebase";
import { useTable, useExpanded, useGlobalFilter } from "react-table";
import {
  OverlayTrigger,
  Popover,
  Modal,
  Spinner,
  Button,
  Table,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import DetailsHeader from "../../Common/DetailsHeader/DetailsHeader";
import Icon from "../../Common/Icon/Icon";
import ContactCard from "../../Common/ContactCard/ContactCard";
import "./ComplianceDetails.css";
import { BackButton } from "../../Common/Buttons";
import {
  Search,
  SearchInput,
  IconSearch,
  ModalThead,
  ModalTitle,
  ModalTBody,
  Divider,
  Image,
  Thead,
  Styles,
  SubRowTRow,
  ChildTableStyle,
  StylesGroup,
  DetailsModal,
  ExportFile,
} from "../styles";
import useWindowSize from "../../Hooks/useWindowSize";

const { Parser } = require('json2csv');

function ParentTable({ columns: userColumns, data, renderRowSubComponent }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
    toggleAllRowsExpanded,
    toggleRowExpanded
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded // Use the useExpanded plugin hook
  );
  useEffect(() => {
    toggleRowExpanded(0);
  }, []);
  return (
    <>
      <table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </Thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            const obj = row.getRowProps();
            delete obj.role;
            return (
              <React.Fragment {...obj}>
                <SubRowTRow>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} {...row.getToggleRowExpandedProps()}>{cell.render("Cell")}</td>
                  ))}
                </SubRowTRow>
                {/*
                      If the row is in an expanded state, render a row with a
                      column that fills the entire length of the table.
                    */}
                {row.isExpanded ? (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {/*
                              Inside it, call our renderRowSubComponent function. In reality,
                              you could pass whatever you want as props to
                              a component like this, including the entire
                              table instance. But for this example, we'll just
                              pass the row
                            */}
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

// Create a default prop getter
const defaultPropGetter = () => ({});

function ChildTable({
  columns: userColumns,
  data,
  getCellProps = defaultPropGetter,
  filterInput,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useGlobalFilter
  );
  useEffect(() => {
    setGlobalFilter(filterInput);
  }, [filterInput]);
  return (
    <>
      <ChildTableStyle {...getTableProps()}>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr>
                {row.cells.map((cell, i) => (
                  <td {...cell.getCellProps(getCellProps(cell, i))}>
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </ChildTableStyle>
    </>
  );
}
const ComplianceDetails = ({
  firestore,
  consolidated,
  ConsViews,
  managersList,
  viewDetail,
  setManagers,
  selectedZone
}) => {
  
  const { width: WindowWidth } = useWindowSize();
  const { Contracts } = consolidated ? consolidated[0] : [{}];
  const [compliance, setCompliance] = useState([]);
  const [filterInput, setFilterInput] = React.useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apartmentId, setApartmentId] = useState(null);
  const [apartmentName, setApartmentName] = useState(null);
  const [issue, setIssue] = useState(null);
  const [exportCSV, setExportCSV] = React.useState("");

  useEffect(() => {
    if (apartmentId && consolidated && consolidated.length) {
      setIsLoading(true);
      setIssue(null);
      firestore
        .get({
          collection: "consolidated",
          doc: consolidated[0].id,
          subcollections: [
            {
              collection: "Contracts_ED",
              doc: apartmentId,
              subcollections: [{ collection: "contracts_details_ed" }],
            },
          ],
          storeAs: "contracts_details_ed",
        })
        .then(async (querySnapshot) => {
          setIsLoading(false);
          let issues = [];
          querySnapshot.forEach((doc) => {
            issues = [...issues, { ...doc.data() }];
          });

          const issueGroup = _.chain(issues).groupBy("StatusIndicator").value();
          // console.log('issueGroup :',issueGroup)
          let issueGroupList = [];

          // eslint-disable-next-line no-prototype-builtins
          if (issueGroup.hasOwnProperty("Red")) {
            issueGroupList = [...issueGroupList, ...issueGroup.Red];
          }
          // eslint-disable-next-line no-prototype-builtins
          if (issueGroup.hasOwnProperty("Amber")) {
            issueGroupList = [...issueGroupList, ...issueGroup.Amber];
          }
          // console.log('issueGroupList :',issueGroupList)
          setIssue(issueGroupList);


          if(!issueGroupList.length){
            return false
          }
          const fileDownload = [...issueGroupList];
          const exportList = fileDownload.map(item => ({
              ...item,
              DueDate: item.DueDate.toDate().toDateString()
            }))

          const fields = [{
            label: 'Title',
            value: 'Title'
          },{
            label: 'Vendor',
            value: 'Vendor'
          },{
            label: 'System',
            value: 'SystemInternalName'
          },{
            label: 'Due Date',
            value: 'DueDate'
          }];
          

          const json2csvParser = new Parser({fields});
          const csv = json2csvParser.parse(exportList);

          window.URL = window.webkitURL || window.URL;
          const platform = window.navigator.platform
          const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
          const contentType = windowsPlatforms.indexOf(platform) !== -1 ? 'application/vnd.ms-excel':'text/csv';
          const csvFile = new Blob([csv], {type: contentType});
          const csvFileExport = window.URL.createObjectURL(csvFile)

          setExportCSV(csvFileExport)
          // setIssue(issues);
        })
        .catch(() => setIsLoading(false));
    }
  }, [apartmentId]);

  useEffect(() => {
    async function fetchManagers() {
      let users = [];
      setIsLoading(true);
      firestore
        .get({
          collection: "Users",
          where: [["is_cons_user", "==", true]
            , ["is_approved", "==", true]],
          storeAs: "ManagersList",
        })
        .then(async (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            users = [...users, { id: doc.id, ...doc.data() }];
          });

          await Promise.all(
            (users || []).map(async (user) =>
              firestore
                .get({
                  collection: "Users",
                  doc: user.id,
                  subcollections: [
                    {
                      collection: "ConsViews",
                      where: [["ConsViewId", "==", ConsViews[0].ConsViewId]],
                    },
                  ],
                  storeAs: "ManagersListZone",
                })
                .then(async (doc) => {
                  if (doc.empty) {
                    return false;
                  }

                  let managers = {};
                  doc.forEach((doc) => {
                    // console.log(doc.data());
                    managers = { UID: doc.id, ...doc.data() };
                  });
                  return { ...user, ...managers };
                })
            )
          )
            .then((prop) => {
              setIsLoading(false);
              const filterEmptyManagerList = prop.filter((manager) => manager);
              setManagers(filterEmptyManagerList);
            })
            .catch(() => setIsLoading(false));
        });
    }
    if (!managersList.length && !isLoading) {

      fetchManagers();
    }
    if (!consolidated) {

      firestore.get({
        collection: "consolidated",
        where: [["ConsViewId", "==", ConsViews[0].ConsViewId]],
        storeAs: "consolidated",
      });
    }
  }, [consolidated])


  useEffect(() => {
    if (!consolidated || !consolidated.length) {
      return;
    }
    // console.log('selectedZone :',selectedZone)
    let condition = [["System", "in", Contracts]];
    if (selectedZone && selectedZone !== "All") {
      condition = [...condition, ["Zone", "==", selectedZone]];
    }
    firestore
      .get({
        collection: "consolidated",
        doc: consolidated[0].id,
        subcollections: [
          {
            collection: "Contracts_ED",
            where: [condition],
          },
        ],
        storeAs: "Contracts_ED",
      })
      .then((querySnapshot) => {
        let compliance = [];
        const { Level: currentUserLevel } = ConsViews[0];
        // console.log('currentUserLevel :',currentUserLevel)
        querySnapshot.forEach((doc) => {
          const managers = managersList.filter(
            ({ Zones, Level }) => 
              // console.log('Zones :',Zones)
              // console.log('doc.data().Zone :',doc.data().Zone)
              // console.log('Level :',Level)
              // console.log(1,Zones.includes(doc.data().Zone) || Zones.includes("All"))
              // console.log(2, Level > currentUserLevel && Level <= currentUserLevel + 2)
                  (Zones.includes(doc.data().Zone) || Zones.includes("All")) &&
                Level > currentUserLevel &&
                Level <= currentUserLevel + 2
            
             
          );

          compliance = [
            ...compliance,
            {
              id: doc.id,
              ...doc.data(),
              managers,
            },
          ];
        });

        const complianceDetails = _.chain(compliance).groupBy("System").value();

        if (Object.keys(complianceDetails).length) {
          const groupedCompliance = Object.keys(complianceDetails).map(
            (key) => {
              const statusRed = complianceDetails[key].filter(
                (item) => item.StatusIndicator === "Red"
              );
              const statusAmber = complianceDetails[key].filter(
                (item) => item.StatusIndicator === "Amber"
              );
              // const statusGreen = complianceDetails[key].filter(
              //   (item) => item.StatusIndicator === "Green"
              // );
              return {
                id: key,
                serviceArea: key,
                subrows: [...statusRed, ...statusAmber],
              };
            }
          );
          // console.log(groupedCompliance)
          setCompliance(groupedCompliance);
        }else {
          setCompliance([]);
        }
      });
  }, [selectedZone, consolidated]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Property Name",
        accessor: "serviceArea",
        className: "main-header",
      },
      {
        Header: "Contact Person",
        className: "main-header",
      },

      {
        Header: "Issues",
        className: "main-header",
      },
      {
        Header: "Category",
        className: "main-header",
      },
      {
        Header: "Status",
        className: "main-header",
      },
      {
        // Make an expander cell
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            <Icon icon="downArrow" fill="#fff" />
          </span>
        ),
      },
    ],
    []
  );
  const subrowColumns = React.useMemo(
    () => [
      {
        accessor: "ApartmentName",
      },
      {
        accessor: "managers",
        Cell: ({ cell }) => {
          // console.log(cell)
          const managers = cell.row.original.managers;

          const { DisplayName, Role, PhoneNo } = managers[0] || {};
          if (!managers.length) return "";
          return (
            // <div className="d-flex flex-row align-items-center">
            <div className="row">
              <ContactCard
                name={DisplayName}
                userRole={Role}
                contact={PhoneNo}
                hideUnderline
              />
              <OverlayTrigger
                rootClose
                trigger="click"
                // key="bottom"
                placement="right"
                overlay={
                  <Popover
                    // id="popover-positioned-bottom"
                    style={{ maxWidth: "350px" }}
                  >
                    <Popover.Content>
                      {managers.map((manager) => {
                        const { DisplayName, Role, PhoneNo } = manager;
                        return (
                          <ContactCard
                            key={PhoneNo}
                            name={DisplayName}
                            userRole={Role}
                            contact={PhoneNo}
                          />
                        );
                      })}
                    </Popover.Content>
                  </Popover>
                }
              >
                <button style={{ background: "none", border: "none" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="5"
                    viewBox="0 0 8 5"
                    fill="none"
                  >
                    <path
                      d="M1 1L4 4L7 1"
                      stroke="#B5B5B5"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </OverlayTrigger>
            </div>
          );
        },
      },
      {
        accessor: "TotalDueCount",
        Cell: ({ cell }) => (
          <div className="row justify-content-center">
            <div
              className="d-inline mr-1"
              onClick={() => {
                setShow(true);
                setApartmentId(cell.row.original.id);
                setApartmentName(cell.row.original.ApartmentName);
              }}
            >
              <u style={{ color: "#558DCA" }}>
                {cell.row.original.TotalDueCount
                  ? cell.row.original.TotalDueCount
                  : null}
              </u>
            </div>
          </div>
        ),
      },
      {
        accessor: "Category",
        Cell: ({ cell }) => (
            <div className="row justify-content-center">
              <div className="d-inline mr-5">{cell.row.original.IssueType}</div>
            </div>
          ),
      },
      {
        accessor: "status",
        Cell: ({ cell }) => (
          <div className="d-flex flex-row justify-content-between align-items-center">
            <div
              className={"status-" + cell.row.original.StatusIndicator}
              style={{
                width: "119px",
                minHeight: "30px",
                borderRadius: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontWeight: 500,
                  fontSize: "12px",
                  color: "#FFFFFF",
                  textTransform: "uppercase",
                  textAlign: "center",
                }}
              >
                {cell.row.original.StatusIndicator === "Red"
                  ? "Not Compliant"
                  : cell.row.original.StatusIndicator === "Amber"
                  ? "Contracts Overdue"
                  : "No Overdue"}
              </span>
            </div>
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => {
                viewDetail(
                  "dashboard/system_health",
                  cell.row.original.Apt_Id
                );
              }}
            >
              <Icon icon="rightArrow" fill="#558DCA" />
            </button>
          </div>
        ),
      },
    ],
    []
  );
  const data = React.useMemo(() => compliance.filter(data=>data.subrows.length), [compliance]);

  const handleFilterChange = (e) => {
    const value = e.target.value || undefined;

    setFilterInput(value || "");
  };
  // Create a function that will render our row sub components
  const renderRowSubComponent = React.useCallback(
    ({ row }, filterInput) => (
      <ChildTable
        columns={subrowColumns}
        data={row.original.subrows}
        filterInput={filterInput}
        getCellProps={(cell, i) => ({
          style: {
            borderLeft:
              i === 0
                ? cell.row.original.StatusIndicator === "Red"
                  ? "8px solid #FF8D8D"
                  : "8px solid #FFCB7E"
                : "none",
            width: "20%",
          },
        })}
      />
    ),
    []
  );

  return (
    <div className="d-flex flex-row flex-wrap m-4">
      <div className="col-lg-12 col-md-12 col-sm-12">
        {WindowWidth <= 768 ? (
          <div style={{ marginTop: "1.25rem" }}>Compliance Details</div>
        ) : null}
        <div className="exe-details-header-container d-flex flex-row justify-content-between mb-3">
          <div className="d-flex flex-row align-items-center">
            <BackButton />
            <DetailsHeader
              label="Compliance and Contracts"
              description="Compliance and Contracts renewal status of managed properties"
            />
          </div>
          <div className="d-flex flex-row align-items-center col-4 m-0 p-0">
            <Search className="ml-4 flex-grow-1">
              <SearchInput
                type="text"
                className="w-100"
                placeholder="Search"
                value={filterInput}
                onChange={handleFilterChange}
              />
              <IconSearch className="icon-search" />
            </Search>
          </div>
        </div>
        <StylesGroup>
          {!data.length ? (
            <div className="d-flex justify-content-center mt-5">
              <span className="mt-5">No Issues to Display</span>
            </div>
          ) : (
            <ParentTable
              columns={columns}
              data={data}
              // We added this as a prop for our table component
              // Remember, this is not part of the React Table API,
              // it's merely a rendering option we created for
              // ourselves
              renderRowSubComponent={(row) =>
                renderRowSubComponent(row, filterInput)
              }
            />
          )}
        </StylesGroup>
      </div>
      <DetailsModal
        show={show}
        size="lg"
        onHide={() => {
          setShow(false);
          setApartmentId(null);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {isLoading ? (
          <div className="row justify-content-center">
            <Spinner
              animation="grow"
              variant="dark"
              className="align-items-center"
            />
          </div>
        ) : (
          <>
            <ModalHeader>
              <div className="d-flex justify-content-between align-items-center w-100">
                <ModalTitle>
                  Compliance and Contracts - {apartmentName}
                </ModalTitle>
                <a href={exportCSV} download={`${apartmentName}.csv`}><ExportFile/></a>
                <Image
                  onClick={() => {
                    setShow(false);
                    setApartmentId(null);
                  }}
                />
              </div>
            </ModalHeader>

            <Modal.Body>
              <div className="tableFixHead">
                <table style={{
                    borderCollapse: 'separate',
                    borderSpacing: '0 2px',
                    // display: 'block',
                    // overflowX: 'auto',
                    // // whiteSpace: 'nowrap'
                  }}>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Vendor</th>
                      {/* <th>System Name</th> */}
                      <th>System</th>
                      <th>Due Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(issue || []).map((item, index) => (
                      <tr key={index}>
                        <td style={{
                            borderLeft:
                              item.StatusIndicator === "Red"
                                ? "8px solid #FF8D8D"
                                : "8px solid #FFCB7E",
                          }}>{item.Title}</td>
                        <td>{item.Vendor}</td>
                        {/* <td>{item.SystemName}</td> */}
                        <td>{item.SystemInternalName}</td>
                        <td>{item.DueDate?item.DueDate.toDate().toDateString():null}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* <Table className="mb-0">
                <ModalThead>
                  <tr>
                    <th>Title</th>
                    <th>System Name</th>
                    <th>System Internal Name</th>
                    <th>Due Date</th>
                  </tr>
                </ModalThead>
                <ModalTBody>
                  {(issue || []).map((item, index) => (
                    <tr key={index}>
                      <td>{item.Title}</td>
                      <td>{item.SystemName}</td>
                      <td>{item.SystemInternalName}</td>
                      <td>{item.DueDate.toDate().toDateString()}</td>
                    </tr>
                  ))}
                </ModalTBody>
              </Table> */}
            </Modal.Body>
          </>
        )}
      </DetailsModal>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  setManagers: (manegers) =>
  dispatch({ type: "SET_MANAGERS_LIST", payload: manegers }),
  viewDetail: (route, apartment) =>
    dispatch({
      type: "REDIRECT_DASHBOARD_TO_ROUTE",
      payload: { route, apartment },
    }),
});
export default compose(
  connect(
    ({
      dashboard,
      apartment,
      firestore: {
        ordered: { consolidated, ConsViews },
      },
    }) => ({
      ConsViews,
      consolidated,
      selectedZone: dashboard.selectedZone,
      managersList: apartment.managersList,
    }),
    mapDispatchToProps
  ),
  firestoreConnect()
)(ComplianceDetails);
