import { createStore, compose } from 'redux'
import { reactReduxFirebase } from 'react-redux-firebase'
import { reduxFirestore } from 'redux-firestore'


import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage';

import firebaseConfig from '../firebaseConfig';
import { initialState, rootReducer } from './reducers'

firebase.initializeApp(firebaseConfig)
firebase.firestore()

const enhancers = [
  reduxFirestore(firebase),
  reactReduxFirebase(firebase, {
    userProfile: 'Users',
    useFirestoreForProfile: true,
  })
]

const reduxDevToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
if (
  process.env.NODE_ENV === "development" &&
  typeof reduxDevToolsExtension === "function"
) {
  enhancers.push(reduxDevToolsExtension())
}

const composedEnhancers = compose(
    ...enhancers
)
  
const store = createStore(rootReducer, initialState, composedEnhancers)
  
export default store